import * as Types from '../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserProfileQuestionnaireQueryVariables = Types.Exact<{
  userId: Types.Scalars['UUID'];
}>;


export type UserProfileQuestionnaireQuery = { __typename?: 'Query', userProfileQuestionnaireByUserId: { __typename?: 'UserProfileQuestionnaire', id: string } | null };


export const UserProfileQuestionnaireDocument = gql`
    query UserProfileQuestionnaire($userId: UUID!) {
  userProfileQuestionnaireByUserId(userId: $userId) {
    id
  }
}
    `;

/**
 * __useUserProfileQuestionnaireQuery__
 *
 * To run a query within a React component, call `useUserProfileQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProfileQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProfileQuestionnaireQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserProfileQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<UserProfileQuestionnaireQuery, UserProfileQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserProfileQuestionnaireQuery, UserProfileQuestionnaireQueryVariables>(UserProfileQuestionnaireDocument, options);
      }
export function useUserProfileQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserProfileQuestionnaireQuery, UserProfileQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserProfileQuestionnaireQuery, UserProfileQuestionnaireQueryVariables>(UserProfileQuestionnaireDocument, options);
        }
export type UserProfileQuestionnaireQueryHookResult = ReturnType<typeof useUserProfileQuestionnaireQuery>;
export type UserProfileQuestionnaireLazyQueryHookResult = ReturnType<typeof useUserProfileQuestionnaireLazyQuery>;
export type UserProfileQuestionnaireQueryResult = Apollo.QueryResult<UserProfileQuestionnaireQuery, UserProfileQuestionnaireQueryVariables>;