export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: string;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: string;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: string;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  Email: string;
  FullText: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: string | number | boolean | Record<string, unknown> | unknown[] | null;
  /**
   * A JSON Web Token defined by [RFC 7519](https://tools.ietf.org/html/rfc7519)
   * which securely represents claims between two parties.
   */
  Jwt: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
};

/** Accounts - clients of TMG / NPT */
export type Account = Node & {
  __typename?: 'Account';
  /** Address line 1 */
  address1: Maybe<Scalars['String']>;
  /** Address line 2 */
  address2: Maybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage: Maybe<Scalars['String']>;
  /** City */
  city: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Class`. */
  classes: ClassesConnection;
  /** Reads and enables pagination through a set of `Class`. */
  classesList: Array<Class>;
  /** Reads and enables pagination through a set of `Connection`. */
  connections: ConnectionsConnection;
  /** Reads and enables pagination through a set of `Connection`. */
  connectionsList: Array<Connection>;
  /** Reads and enables pagination through a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccesses: ConsultantOrganizationAccessesConnection;
  /** Reads and enables pagination through a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccessesList: Array<ConsultantOrganizationAccess>;
  /** Contact Email */
  contactEmail: Maybe<Scalars['String']>;
  /** Contact Name */
  contactName: Maybe<Scalars['String']>;
  /** Contact Title */
  contactTitle: Maybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId: Maybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `Customer`. */
  customers: CustomersConnection;
  /** Reads and enables pagination through a set of `Customer`. */
  customersList: Array<Customer>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso: Maybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName: Maybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId: Maybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived: Scalars['Boolean'];
  /** Indicates if the account users can access Learning Videos */
  isLms: Scalars['Boolean'];
  /** Indicates if the account users can access LV1 Game */
  isLv1: Scalars['Boolean'];
  /** Indicates if the account users can access NPT functionality */
  isNpt: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Lv1GameSubgroup`. */
  lv1GameSubgroups: Lv1GameSubgroupsConnection;
  /** Reads and enables pagination through a set of `Lv1GameSubgroup`. */
  lv1GameSubgroupsList: Array<Lv1GameSubgroup>;
  /** Name of the account */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `OrganizationCoachingTime` that is related to this `Account`. */
  organizationCoachingTime: Maybe<OrganizationCoachingTime>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistories: OrganizationCoachingTimeHistoriesConnection;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistoriesList: Array<OrganizationCoachingTimeHistory>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogs: OrganizationCoachingTimeLogsConnection;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogsList: Array<OrganizationCoachingTimeLog>;
  /** Account phone number */
  phone: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsList: Array<Project>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest: Maybe<Scalars['Boolean']>;
  /** State */
  state: Maybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone: Maybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `User`. */
  users: UsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersList: Array<User>;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClasses: VariableClassesConnection;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClassesList: Array<VariableClass>;
  /** Website URL */
  websiteUrl: Maybe<Scalars['String']>;
  /** Count of workshops for the account */
  workshopsCount: Maybe<Scalars['Int']>;
  /** Total workshop participants count for the organization taken from v1 data */
  wsParticipantsCount: Maybe<Scalars['Int']>;
  /** Zip code */
  zipCode: Maybe<Scalars['String']>;
};


/** Accounts - clients of TMG / NPT */
export type AccountClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountClassesListArgs = {
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountConnectionsListArgs = {
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountConsultantOrganizationAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountConsultantOrganizationAccessesListArgs = {
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountCustomersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CustomerCondition>;
  filter?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountCustomersListArgs = {
  condition?: InputMaybe<CustomerCondition>;
  filter?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountLv1GameSubgroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountLv1GameSubgroupsListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountOrganizationCoachingTimeHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountOrganizationCoachingTimeHistoriesListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountOrganizationCoachingTimeLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountOrganizationCoachingTimeLogsListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountProjectsListArgs = {
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountUsersListArgs = {
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountVariableClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** Accounts - clients of TMG / NPT */
export type AccountVariableClassesListArgs = {
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};

/** The fields on `account` to look up the row to connect. */
export type AccountAccountPkeyConnect = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
};

/** The fields on `account` to look up the row to delete. */
export type AccountAccountPkeyDelete = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
};

/** A condition to be used against `Account` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AccountCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `wsParticipantsCount` field. */
  wsParticipantsCount?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Account` object types. All fields are combined with a logical ‘and.’ */
export type AccountFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `classes` relation. */
  classes?: InputMaybe<AccountToManyClassFilter>;
  /** Some related `classes` exist. */
  classesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `connections` relation. */
  connections?: InputMaybe<AccountToManyConnectionFilter>;
  /** Some related `connections` exist. */
  connectionsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `consultantOrganizationAccesses` relation. */
  consultantOrganizationAccesses?: InputMaybe<AccountToManyConsultantOrganizationAccessFilter>;
  /** Some related `consultantOrganizationAccesses` exist. */
  consultantOrganizationAccessesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `customers` relation. */
  customers?: InputMaybe<AccountToManyCustomerFilter>;
  /** Some related `customers` exist. */
  customersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lv1GameSubgroups` relation. */
  lv1GameSubgroups?: InputMaybe<AccountToManyLv1GameSubgroupFilter>;
  /** Some related `lv1GameSubgroups` exist. */
  lv1GameSubgroupsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AccountFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AccountFilter>>;
  /** Filter by the object’s `organizationCoachingTime` relation. */
  organizationCoachingTime?: InputMaybe<OrganizationCoachingTimeFilter>;
  /** A related `organizationCoachingTime` exists. */
  organizationCoachingTimeExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `organizationCoachingTimeHistories` relation. */
  organizationCoachingTimeHistories?: InputMaybe<AccountToManyOrganizationCoachingTimeHistoryFilter>;
  /** Some related `organizationCoachingTimeHistories` exist. */
  organizationCoachingTimeHistoriesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `organizationCoachingTimeLogs` relation. */
  organizationCoachingTimeLogs?: InputMaybe<AccountToManyOrganizationCoachingTimeLogFilter>;
  /** Some related `organizationCoachingTimeLogs` exist. */
  organizationCoachingTimeLogsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projects` relation. */
  projects?: InputMaybe<AccountToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `users` relation. */
  users?: InputMaybe<AccountToManyUserFilter>;
  /** Some related `users` exist. */
  usersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `variableClasses` relation. */
  variableClasses?: InputMaybe<AccountToManyVariableClassFilter>;
  /** Some related `variableClasses` exist. */
  variableClassesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `workshopsCount` field. */
  workshopsCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `wsParticipantsCount` field. */
  wsParticipantsCount?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Account` */
export type AccountInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AccountNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type AccountNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `account` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnClassForClassAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnClassForClassAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnClassForClassAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnClassForClassAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnClassForClassAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnConnectionForConnectionAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `connection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: ConnectionPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnConnectionForConnectionAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnConnectionForConnectionAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnConnectionForConnectionAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnConnectionForConnectionAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `consultantOrganizationAccess` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
  patch: ConsultantOrganizationAccessPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnCustomerForCustomerAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `customer` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `customer` being updated. */
  patch: CustomerPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnCustomerForCustomerAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnCustomerForCustomerAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnCustomerForCustomerAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnCustomerForCustomerAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTime` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organizationCoachingTime` being updated. */
  patch: OrganizationCoachingTimePatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTimeLog` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: OrganizationCoachingTimeLogPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnProjectForProjectAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnProjectForProjectAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnProjectForProjectAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnProjectForProjectAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnProjectForProjectAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnUserForUserAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnUserForUserAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnUserForUserAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnUserForUserAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnUserForUserAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AccountOnVariableClassForVariableAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `variableClass` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: VariableClassPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnVariableClassForVariableAccountIdFkeyUsingAccountPkeyUpdate = {
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnVariableClassForVariableAccountIdFkeyPatch;
};

/** The fields on `account` to look up the row to update. */
export type AccountOnVariableClassForVariableAccountIdFkeyUsingUniqAccountNameUpdate = {
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: UpdateAccountOnVariableClassForVariableAccountIdFkeyPatch;
};

/** Represents an update to a `Account`. Fields that are set will be updated. */
export type AccountPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

export type AccountSubscriptionPayload = {
  __typename?: 'AccountSubscriptionPayload';
  account: Maybe<Account>;
  event: Maybe<Scalars['String']>;
};

/** A filter to be used against many `Class` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyClassFilter = {
  /** Every related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassFilter>;
  /** No related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassFilter>;
  /** Some related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassFilter>;
};

/** A filter to be used against many `Connection` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyConnectionFilter = {
  /** Every related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConnectionFilter>;
  /** No related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConnectionFilter>;
  /** Some related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConnectionFilter>;
};

/** A filter to be used against many `ConsultantOrganizationAccess` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyConsultantOrganizationAccessFilter = {
  /** Every related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConsultantOrganizationAccessFilter>;
  /** No related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConsultantOrganizationAccessFilter>;
  /** Some related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConsultantOrganizationAccessFilter>;
};

/** A filter to be used against many `Customer` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyCustomerFilter = {
  /** Every related `Customer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<CustomerFilter>;
  /** No related `Customer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<CustomerFilter>;
  /** Some related `Customer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<CustomerFilter>;
};

/** A filter to be used against many `Lv1GameSubgroup` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyLv1GameSubgroupFilter = {
  /** Every related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<Lv1GameSubgroupFilter>;
  /** No related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<Lv1GameSubgroupFilter>;
  /** Some related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<Lv1GameSubgroupFilter>;
};

/** A filter to be used against many `OrganizationCoachingTimeHistory` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyOrganizationCoachingTimeHistoryFilter = {
  /** Every related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  /** No related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  /** Some related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
};

/** A filter to be used against many `OrganizationCoachingTimeLog` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyOrganizationCoachingTimeLogFilter = {
  /** Every related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** No related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** Some related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationCoachingTimeLogFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `User` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyUserFilter = {
  /** Every related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserFilter>;
  /** No related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserFilter>;
  /** Some related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserFilter>;
};

/** A filter to be used against many `VariableClass` object types. All fields are combined with a logical ‘and.’ */
export type AccountToManyVariableClassFilter = {
  /** Every related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VariableClassFilter>;
  /** No related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VariableClassFilter>;
  /** Some related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VariableClassFilter>;
};

/** The fields on `account` to look up the row to connect. */
export type AccountUniqAccountNameConnect = {
  /** Name of the account */
  name: Scalars['String'];
};

/** The fields on `account` to look up the row to delete. */
export type AccountUniqAccountNameDelete = {
  /** Name of the account */
  name: Scalars['String'];
};

/** A connection to a list of `Account` values. */
export type AccountsConnection = {
  __typename?: 'AccountsConnection';
  /** A list of edges which contains the `Account` and cursor to aid in pagination. */
  edges: Array<AccountsEdge>;
  /** A list of `Account` objects. */
  nodes: Array<Account>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Account` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Account` edge in the connection. */
export type AccountsEdge = {
  __typename?: 'AccountsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Account` at the end of the edge. */
  node: Account;
};

/** Methods to use when ordering `Account`. */
export enum AccountsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `ActiveCountryRegionRecord` values. */
export type ActiveCountryRegionConnection = {
  __typename?: 'ActiveCountryRegionConnection';
  /** A list of edges which contains the `ActiveCountryRegionRecord` and cursor to aid in pagination. */
  edges: Array<ActiveCountryRegionEdge>;
  /** A list of `ActiveCountryRegionRecord` objects. */
  nodes: Array<ActiveCountryRegionRecord>;
  /** The count of *all* `ActiveCountryRegionRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ActiveCountryRegionRecord` edge in the connection. */
export type ActiveCountryRegionEdge = {
  __typename?: 'ActiveCountryRegionEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ActiveCountryRegionRecord` at the end of the edge. */
  node: ActiveCountryRegionRecord;
};

/** The return type of our `activeCountryRegion` query. */
export type ActiveCountryRegionRecord = {
  __typename?: 'ActiveCountryRegionRecord';
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
};

/** A filter to be used against `ActiveCountryRegionRecord` object types. All fields are combined with a logical ‘and.’ */
export type ActiveCountryRegionRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActiveCountryRegionRecordFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActiveCountryRegionRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActiveCountryRegionRecordFilter>>;
};

/** A connection to a list of `ActiveIndustriesRecord` values. */
export type ActiveIndustriesConnection = {
  __typename?: 'ActiveIndustriesConnection';
  /** A list of edges which contains the `ActiveIndustriesRecord` and cursor to aid in pagination. */
  edges: Array<ActiveIndustryEdge>;
  /** A list of `ActiveIndustriesRecord` objects. */
  nodes: Array<ActiveIndustriesRecord>;
  /** The count of *all* `ActiveIndustriesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `activeIndustries` query. */
export type ActiveIndustriesRecord = {
  __typename?: 'ActiveIndustriesRecord';
  id: Maybe<Scalars['UUID']>;
  name: Maybe<Scalars['String']>;
};

/** A filter to be used against `ActiveIndustriesRecord` object types. All fields are combined with a logical ‘and.’ */
export type ActiveIndustriesRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActiveIndustriesRecordFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActiveIndustriesRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActiveIndustriesRecordFilter>>;
};

/** A `ActiveIndustriesRecord` edge in the connection. */
export type ActiveIndustryEdge = {
  __typename?: 'ActiveIndustryEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ActiveIndustriesRecord` at the end of the edge. */
  node: ActiveIndustriesRecord;
};

/** A connection to a list of `Activity` values. */
export type ActivitiesConnection = {
  __typename?: 'ActivitiesConnection';
  /** A list of edges which contains the `Activity` and cursor to aid in pagination. */
  edges: Array<ActivitiesEdge>;
  /** A list of `Activity` objects. */
  nodes: Array<Activity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Activity` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Activity` edge in the connection. */
export type ActivitiesEdge = {
  __typename?: 'ActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Activity` at the end of the edge. */
  node: Activity;
};

/** Methods to use when ordering `Activity`. */
export enum ActivitiesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC'
}

/** Workshop Activity */
export type Activity = Node & {
  __typename?: 'Activity';
  /** Reads and enables pagination through a set of `ActivityLevel`. */
  activityLevels: ActivityLevelsConnection;
  /** Reads and enables pagination through a set of `ActivityLevel`. */
  activityLevelsList: Array<ActivityLevel>;
  /** Brief Group Type */
  briefGroupType: BriefGroupType;
  /** Reads and enables pagination through a set of `BriefGroup`. */
  briefGroups: BriefGroupsConnection;
  /** Reads and enables pagination through a set of `BriefGroup`. */
  briefGroupsList: Array<BriefGroup>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate: Maybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables: Maybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id: Scalars['Int'];
  /** Intro text of the activity */
  intro: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Activity rank (order) */
  rank: Maybe<Scalars['Int']>;
  /** Title of the activity */
  title: Scalars['String'];
};


/** Workshop Activity */
export type ActivityActivityLevelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityLevelCondition>;
  filter?: InputMaybe<ActivityLevelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityLevelsOrderBy>>;
};


/** Workshop Activity */
export type ActivityActivityLevelsListArgs = {
  condition?: InputMaybe<ActivityLevelCondition>;
  filter?: InputMaybe<ActivityLevelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityLevelsOrderBy>>;
};


/** Workshop Activity */
export type ActivityBriefGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};


/** Workshop Activity */
export type ActivityBriefGroupsListArgs = {
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};

/** The fields on `activity` to look up the row to connect. */
export type ActivityActivityPkeyConnect = {
  /** Primary ID of the activity */
  id: Scalars['Int'];
};

/** The fields on `activity` to look up the row to delete. */
export type ActivityActivityPkeyDelete = {
  /** Primary ID of the activity */
  id: Scalars['Int'];
};

/**
 * A condition to be used against `Activity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ActivityCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rank` field. */
  rank?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Activity` object types. All fields are combined with a logical ‘and.’ */
export type ActivityFilter = {
  /** Filter by the object’s `activityLevels` relation. */
  activityLevels?: InputMaybe<ActivityToManyActivityLevelFilter>;
  /** Some related `activityLevels` exist. */
  activityLevelsExist?: InputMaybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActivityFilter>>;
  /** Filter by the object’s `briefGroups` relation. */
  briefGroups?: InputMaybe<ActivityToManyBriefGroupFilter>;
  /** Some related `briefGroups` exist. */
  briefGroupsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActivityFilter>>;
  /** Filter by the object’s `rank` field. */
  rank?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `Activity` */
export type ActivityInput = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title: Scalars['String'];
};

/** Activity Level */
export type ActivityLevel = {
  __typename?: 'ActivityLevel';
  /** Reads a single `Activity` that is related to this `ActivityLevel`. */
  activity: Maybe<Activity>;
  /** Foreign key to Activity */
  activityId: Scalars['Int'];
  /** Workshop Level */
  level: WorkshopLevel;
};

/** The `activity` to be created by this mutation. */
export type ActivityLevelActivityIdFkeyActivityCreateInput = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title: Scalars['String'];
};

/** The `activityLevel` to be created by this mutation. */
export type ActivityLevelActivityIdFkeyActivityLevelCreateInput = {
  activityToActivityId?: InputMaybe<ActivityLevelActivityIdFkeyInput>;
  /** Workshop Level */
  level: WorkshopLevel;
};

/** Input for the nested mutation of `activity` in the `ActivityLevelInput` mutation. */
export type ActivityLevelActivityIdFkeyInput = {
  /** The primary key(s) for `activity` for the far side of the relationship. */
  connectById?: InputMaybe<ActivityActivityPkeyConnect>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ActivityNodeIdConnect>;
  /** A `ActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<ActivityLevelActivityIdFkeyActivityCreateInput>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  deleteById?: InputMaybe<ActivityActivityPkeyDelete>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ActivityNodeIdDelete>;
  /** The primary key(s) and patch data for `activity` for the far side of the relationship. */
  updateById?: InputMaybe<ActivityOnActivityLevelForActivityLevelActivityIdFkeyUsingActivityPkeyUpdate>;
  /** The primary key(s) and patch data for `activity` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ActivityLevelOnActivityLevelForActivityLevelActivityIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `activityLevel` in the `ActivityInput` mutation. */
export type ActivityLevelActivityIdFkeyInverseInput = {
  /** A `ActivityLevelInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ActivityLevelActivityIdFkeyActivityLevelCreateInput>>;
};

/**
 * A condition to be used against `ActivityLevel` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ActivityLevelCondition = {
  /** Checks for equality with the object’s `activityId` field. */
  activityId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ActivityLevel` object types. All fields are combined with a logical ‘and.’ */
export type ActivityLevelFilter = {
  /** Filter by the object’s `activity` relation. */
  activity?: InputMaybe<ActivityFilter>;
  /** Filter by the object’s `activityId` field. */
  activityId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActivityLevelFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ActivityLevelFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActivityLevelFilter>>;
};

/** An input for mutations affecting `ActivityLevel` */
export type ActivityLevelInput = {
  /** Foreign key to Activity */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<ActivityLevelActivityIdFkeyInput>;
  /** Workshop Level */
  level: WorkshopLevel;
};

/** The globally unique `ID` look up for the row to update. */
export type ActivityLevelOnActivityLevelForActivityLevelActivityIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `activity` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `activity` being updated. */
  patch: ActivityPatch;
};

/** A connection to a list of `ActivityLevel` values. */
export type ActivityLevelsConnection = {
  __typename?: 'ActivityLevelsConnection';
  /** A list of edges which contains the `ActivityLevel` and cursor to aid in pagination. */
  edges: Array<ActivityLevelsEdge>;
  /** A list of `ActivityLevel` objects. */
  nodes: Array<ActivityLevel>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActivityLevel` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ActivityLevel` edge in the connection. */
export type ActivityLevelsEdge = {
  __typename?: 'ActivityLevelsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ActivityLevel` at the end of the edge. */
  node: ActivityLevel;
};

/** Methods to use when ordering `ActivityLevel`. */
export enum ActivityLevelsOrderBy {
  ActivityIdAsc = 'ACTIVITY_ID_ASC',
  ActivityIdDesc = 'ACTIVITY_ID_DESC',
  Natural = 'NATURAL'
}

/** The globally unique `ID` look up for the row to connect. */
export type ActivityNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `activity` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ActivityNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `activity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The fields on `activity` to look up the row to update. */
export type ActivityOnActivityLevelForActivityLevelActivityIdFkeyUsingActivityPkeyUpdate = {
  /** Primary ID of the activity */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `activity` being updated. */
  patch: UpdateActivityOnActivityLevelForActivityLevelActivityIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ActivityOnBriefGroupForBriefGroupActivityIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** The fields on `activity` to look up the row to update. */
export type ActivityOnBriefGroupForBriefGroupActivityIdFkeyUsingActivityPkeyUpdate = {
  /** Primary ID of the activity */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `activity` being updated. */
  patch: UpdateActivityOnBriefGroupForBriefGroupActivityIdFkeyPatch;
};

/** Represents an update to a `Activity`. Fields that are set will be updated. */
export type ActivityPatch = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against many `ActivityLevel` object types. All fields are combined with a logical ‘and.’ */
export type ActivityToManyActivityLevelFilter = {
  /** Every related `ActivityLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ActivityLevelFilter>;
  /** No related `ActivityLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ActivityLevelFilter>;
  /** Some related `ActivityLevel` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ActivityLevelFilter>;
};

/** A filter to be used against many `BriefGroup` object types. All fields are combined with a logical ‘and.’ */
export type ActivityToManyBriefGroupFilter = {
  /** Every related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefGroupFilter>;
  /** No related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefGroupFilter>;
  /** Some related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefGroupFilter>;
};

/** All input for the `addOrganizationCoachingTime` mutation. */
export type AddOrganizationCoachingTimeInput = {
  accountId: Scalars['UUID'];
  amount: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractDate?: InputMaybe<Scalars['Datetime']>;
};

/** All input for the `addOrganizationCoachingTimeLog` mutation. */
export type AddOrganizationCoachingTimeLogInput = {
  accountId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consultantId: Scalars['UUID'];
  duration: Scalars['Int'];
  happenedAt: Scalars['Datetime'];
  notes?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  teamMembers: Array<InputMaybe<Scalars['UUID']>>;
};

/** The output of our `addOrganizationCoachingTimeLog` mutation. */
export type AddOrganizationCoachingTimeLogPayload = {
  __typename?: 'AddOrganizationCoachingTimeLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** The output of our `addOrganizationCoachingTime` mutation. */
export type AddOrganizationCoachingTimePayload = {
  __typename?: 'AddOrganizationCoachingTimePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** All input for the `addUserToAccountClasses` mutation. */
export type AddUserToAccountClassesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** The output of our `addUserToAccountClasses` mutation. */
export type AddUserToAccountClassesPayload = {
  __typename?: 'AddUserToAccountClassesPayload';
  boolean: Maybe<Scalars['Boolean']>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `batchProcessWorkshopFeedbackDataAndInsert` mutation. */
export type BatchProcessWorkshopFeedbackDataAndInsertInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  nTimes: Scalars['Int'];
};

/** The output of our `batchProcessWorkshopFeedbackDataAndInsert` mutation. */
export type BatchProcessWorkshopFeedbackDataAndInsertPayload = {
  __typename?: 'BatchProcessWorkshopFeedbackDataAndInsertPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigFloat']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigFloat']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigFloat']>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Brief (instructions for a single role in a scenario or a plot) */
export type Brief = Node & {
  __typename?: 'Brief';
  /** Reads a single `BriefGroup` that is related to this `Brief`. */
  briefGroup: Maybe<BriefGroup>;
  /** Foreign key to Brief Group (scenario) where this brief belongs */
  briefGroupId: Scalars['Int'];
  /** Variant of the brief, e.g. Buyer or Player 1 */
  briefVariant: BriefVariant;
  /** Instructions for the person getting the brief. If present, should be shown in activities instead of the PDF. */
  content: Maybe<Scalars['String']>;
  /** When was the brief created */
  createdAt: Scalars['Datetime'];
  /** Textual representation of the file size, passed by the frontend */
  fileSize: Maybe<Scalars['String']>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** Name of the brief, e.g. School */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The URL of an uploaded PDF document. If it exists, it should always be used when printing/downloading */
  pathUrl: Maybe<Scalars['String']>;
  /** When was the brief last updated */
  updatedAt: Scalars['Datetime'];
};

/** The `brief` to be created by this mutation. */
export type BriefBriefGroupIdFkeyBriefCreateInput = {
  briefGroupToBriefGroupId?: InputMaybe<BriefBriefGroupIdFkeyInput>;
  /** Variant of the brief, e.g. Buyer or Player 1 */
  briefVariant: BriefVariant;
  /** Instructions for the person getting the brief. If present, should be shown in activities instead of the PDF. */
  content?: InputMaybe<Scalars['String']>;
  /** When was the brief created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief, e.g. School */
  name: Scalars['String'];
  /** The URL of an uploaded PDF document. If it exists, it should always be used when printing/downloading */
  pathUrl?: InputMaybe<Scalars['String']>;
  /** When was the brief last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `briefGroup` to be created by this mutation. */
export type BriefBriefGroupIdFkeyBriefGroupCreateInput = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name: Scalars['String'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** Input for the nested mutation of `briefGroup` in the `BriefInput` mutation. */
export type BriefBriefGroupIdFkeyInput = {
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectById?: InputMaybe<BriefGroupBriefGroupPkeyConnect>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BriefGroupNodeIdConnect>;
  /** A `BriefGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefBriefGroupIdFkeyBriefGroupCreateInput>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<BriefGroupBriefGroupPkeyDelete>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<BriefGroupNodeIdDelete>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateById?: InputMaybe<BriefGroupOnBriefForBriefBriefGroupIdFkeyUsingBriefGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BriefOnBriefForBriefBriefGroupIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `brief` in the `BriefGroupInput` mutation. */
export type BriefBriefGroupIdFkeyInverseInput = {
  /** The primary key(s) for `brief` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefBriefPkeyConnect>>;
  /** The primary key(s) for `brief` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefNodeIdConnect>>;
  /** A `BriefInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BriefBriefGroupIdFkeyBriefCreateInput>>;
  /** The primary key(s) for `brief` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<BriefBriefPkeyDelete>>;
  /** The primary key(s) for `brief` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<BriefNodeIdDelete>>;
  /** Flag indicating whether all other `brief` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `brief` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefOnBriefForBriefBriefGroupIdFkeyUsingBriefPkeyUpdate>>;
  /** The primary key(s) and patch data for `brief` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<BriefGroupOnBriefForBriefBriefGroupIdFkeyNodeIdUpdate>>;
};

/** The fields on `brief` to look up the row to connect. */
export type BriefBriefPkeyConnect = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/** The fields on `brief` to look up the row to delete. */
export type BriefBriefPkeyDelete = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/** A condition to be used against `Brief` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BriefCondition = {
  /** Checks for equality with the object’s `briefGroupId` field. */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `Brief` object types. All fields are combined with a logical ‘and.’ */
export type BriefFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefFilter>>;
  /** Filter by the object’s `briefGroup` relation. */
  briefGroup?: InputMaybe<BriefGroupFilter>;
  /** Filter by the object’s `briefGroupId` field. */
  briefGroupId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefFilter>>;
};

/** Brief Group (like a scenario or a plot) */
export type BriefGroup = Node & {
  __typename?: 'BriefGroup';
  /** Reads a single `Activity` that is related to this `BriefGroup`. */
  activity: Maybe<Activity>;
  /** Foreign key to Activity where this brief group belongs */
  activityId: Scalars['Int'];
  /** Reads and enables pagination through a set of `BriefGroupVariable`. */
  briefGroupVariables: BriefGroupVariablesConnection;
  /** Reads and enables pagination through a set of `BriefGroupVariable`. */
  briefGroupVariablesList: Array<BriefGroupVariable>;
  /** Reads and enables pagination through a set of `Brief`. */
  briefs: BriefsConnection;
  /** Reads and enables pagination through a set of `Brief`. */
  briefsList: Array<Brief>;
  /** When was the brief group created */
  createdAt: Scalars['Datetime'];
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** Language of the Brief Group */
  languageCode: Scalars['String'];
  /** Name of the brief group */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers: Maybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UserLanguage` that is related to this `BriefGroup`. */
  userLanguageByLanguageCode: Maybe<UserLanguage>;
  /** Retrieve valid Brief Variants for the given Brief Group */
  validBriefVariants: Maybe<Array<Maybe<BriefVariant>>>;
};


/** Brief Group (like a scenario or a plot) */
export type BriefGroupBriefGroupVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupVariableCondition>;
  filter?: InputMaybe<BriefGroupVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};


/** Brief Group (like a scenario or a plot) */
export type BriefGroupBriefGroupVariablesListArgs = {
  condition?: InputMaybe<BriefGroupVariableCondition>;
  filter?: InputMaybe<BriefGroupVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};


/** Brief Group (like a scenario or a plot) */
export type BriefGroupBriefsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefCondition>;
  filter?: InputMaybe<BriefFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};


/** Brief Group (like a scenario or a plot) */
export type BriefGroupBriefsListArgs = {
  condition?: InputMaybe<BriefCondition>;
  filter?: InputMaybe<BriefFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};

/** The `activity` to be created by this mutation. */
export type BriefGroupActivityIdFkeyActivityCreateInput = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title: Scalars['String'];
};

/** The `briefGroup` to be created by this mutation. */
export type BriefGroupActivityIdFkeyBriefGroupCreateInput = {
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name: Scalars['String'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** Input for the nested mutation of `activity` in the `BriefGroupInput` mutation. */
export type BriefGroupActivityIdFkeyInput = {
  /** The primary key(s) for `activity` for the far side of the relationship. */
  connectById?: InputMaybe<ActivityActivityPkeyConnect>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ActivityNodeIdConnect>;
  /** A `ActivityInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefGroupActivityIdFkeyActivityCreateInput>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  deleteById?: InputMaybe<ActivityActivityPkeyDelete>;
  /** The primary key(s) for `activity` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ActivityNodeIdDelete>;
  /** The primary key(s) and patch data for `activity` for the far side of the relationship. */
  updateById?: InputMaybe<ActivityOnBriefGroupForBriefGroupActivityIdFkeyUsingActivityPkeyUpdate>;
  /** The primary key(s) and patch data for `activity` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BriefGroupOnBriefGroupForBriefGroupActivityIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `briefGroup` in the `ActivityInput` mutation. */
export type BriefGroupActivityIdFkeyInverseInput = {
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefGroupBriefGroupPkeyConnect>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefGroupNodeIdConnect>>;
  /** A `BriefGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BriefGroupActivityIdFkeyBriefGroupCreateInput>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<BriefGroupBriefGroupPkeyDelete>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<BriefGroupNodeIdDelete>>;
  /** Flag indicating whether all other `briefGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefGroupOnBriefGroupForBriefGroupActivityIdFkeyUsingBriefGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ActivityOnBriefGroupForBriefGroupActivityIdFkeyNodeIdUpdate>>;
};

/** The fields on `briefGroup` to look up the row to connect. */
export type BriefGroupBriefGroupPkeyConnect = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
};

/** The fields on `briefGroup` to look up the row to delete. */
export type BriefGroupBriefGroupPkeyDelete = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
};

/**
 * A condition to be used against `BriefGroup` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BriefGroupCondition = {
  /** Checks for equality with the object’s `activityId` field. */
  activityId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `languageCode` field. */
  languageCode?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `BriefGroup` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupFilter = {
  /** Filter by the object’s `activity` relation. */
  activity?: InputMaybe<ActivityFilter>;
  /** Filter by the object’s `activityId` field. */
  activityId?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefGroupFilter>>;
  /** Filter by the object’s `briefGroupVariables` relation. */
  briefGroupVariables?: InputMaybe<BriefGroupToManyBriefGroupVariableFilter>;
  /** Some related `briefGroupVariables` exist. */
  briefGroupVariablesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `briefs` relation. */
  briefs?: InputMaybe<BriefGroupToManyBriefFilter>;
  /** Some related `briefs` exist. */
  briefsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `languageCode` field. */
  languageCode?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefGroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefGroupFilter>>;
  /** Filter by the object’s `userLanguageByLanguageCode` relation. */
  userLanguageByLanguageCode?: InputMaybe<UserLanguageFilter>;
  /** Filter by the object’s `validBriefVariants` field. */
  validBriefVariants?: InputMaybe<BriefVariantListFilter>;
};

/** An input for mutations affecting `BriefGroup` */
export type BriefGroupInput = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name: Scalars['String'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** The `briefGroup` to be created by this mutation. */
export type BriefGroupLanguageCodeFkeyBriefGroupCreateInput = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group */
  name: Scalars['String'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** Input for the nested mutation of `userLanguage` in the `BriefGroupInput` mutation. */
export type BriefGroupLanguageCodeFkeyInput = {
  /** The primary key(s) for `userLanguage` for the far side of the relationship. */
  connectByLanguageCode?: InputMaybe<UserLanguageUniqLanguageCodeConnect>;
  /** The primary key(s) for `userLanguage` for the far side of the relationship. */
  connectByName?: InputMaybe<UserLanguageUniqUserLanguageConnect>;
  /** A `UserLanguageInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefGroupLanguageCodeFkeyUserLanguageCreateInput>;
  /** The primary key(s) and patch data for `userLanguage` for the far side of the relationship. */
  updateByLanguageCode?: InputMaybe<UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyUsingUniqLanguageCodeUpdate>;
  /** The primary key(s) and patch data for `userLanguage` for the far side of the relationship. */
  updateByName?: InputMaybe<UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyUsingUniqUserLanguageUpdate>;
};

/** Input for the nested mutation of `briefGroup` in the `UserLanguageInput` mutation. */
export type BriefGroupLanguageCodeFkeyInverseInput = {
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefGroupBriefGroupPkeyConnect>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefGroupNodeIdConnect>>;
  /** A `BriefGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BriefGroupLanguageCodeFkeyBriefGroupCreateInput>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<BriefGroupBriefGroupPkeyDelete>>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<BriefGroupNodeIdDelete>>;
  /** Flag indicating whether all other `briefGroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefGroupOnBriefGroupForBriefGroupLanguageCodeFkeyUsingBriefGroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyNodeIdUpdate>>;
};

/** The `userLanguage` to be created by this mutation. */
export type BriefGroupLanguageCodeFkeyUserLanguageCreateInput = {
  briefGroupsUsingLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInverseInput>;
  /** The name of the language in the corresponding languge, customer facing */
  displayName?: InputMaybe<Scalars['String']>;
  /** ISO 639-1 Language Code, can be used with Weglot and similar services */
  isoLangCode?: InputMaybe<Scalars['String']>;
  /** The name of the language in English */
  name?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BriefGroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BriefGroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupOnBriefForBriefBriefGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `brief` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `brief` being updated. */
  patch: BriefPatch;
};

/** The fields on `briefGroup` to look up the row to update. */
export type BriefGroupOnBriefForBriefBriefGroupIdFkeyUsingBriefGroupPkeyUpdate = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: UpdateBriefGroupOnBriefForBriefBriefGroupIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupOnBriefGroupForBriefGroupActivityIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `activity` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `activity` being updated. */
  patch: ActivityPatch;
};

/** The fields on `briefGroup` to look up the row to update. */
export type BriefGroupOnBriefGroupForBriefGroupActivityIdFkeyUsingBriefGroupPkeyUpdate = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: UpdateBriefGroupOnBriefGroupForBriefGroupActivityIdFkeyPatch;
};

/** The fields on `briefGroup` to look up the row to update. */
export type BriefGroupOnBriefGroupForBriefGroupLanguageCodeFkeyUsingBriefGroupPkeyUpdate = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: UpdateBriefGroupOnBriefGroupForBriefGroupLanguageCodeFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroupVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
  patch: BriefGroupVariablePatch;
};

/** The fields on `briefGroup` to look up the row to update. */
export type BriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyUsingBriefGroupPkeyUpdate = {
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: UpdateBriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyPatch;
};

/** Represents an update to a `BriefGroup`. Fields that are set will be updated. */
export type BriefGroupPatch = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name?: InputMaybe<Scalars['String']>;
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** A filter to be used against many `Brief` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupToManyBriefFilter = {
  /** Every related `Brief` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefFilter>;
  /** No related `Brief` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefFilter>;
  /** Some related `Brief` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefFilter>;
};

/** A filter to be used against many `BriefGroupVariable` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupToManyBriefGroupVariableFilter = {
  /** Every related `BriefGroupVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefGroupVariableFilter>;
  /** No related `BriefGroupVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefGroupVariableFilter>;
  /** Some related `BriefGroupVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefGroupVariableFilter>;
};

export enum BriefGroupType {
  BuyerSeller = 'BUYER_SELLER',
  Common = 'COMMON',
  MultipleVariants = 'MULTIPLE_VARIANTS',
  None = 'NONE',
  Player1Player2 = 'PLAYER1_PLAYER2',
  ThreeVariants = 'THREE_VARIANTS'
}

/** A filter to be used against BriefGroupType fields. All fields are combined with a logical ‘and.’ */
export type BriefGroupTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<BriefGroupType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<BriefGroupType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<BriefGroupType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<BriefGroupType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<BriefGroupType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<BriefGroupType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<BriefGroupType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<BriefGroupType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<BriefGroupType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<BriefGroupType>>;
};

/** Mapping the relationship between Brief Group Type and Brief Variant */
export type BriefGroupTypeMapping = Node & {
  __typename?: 'BriefGroupTypeMapping';
  /** Related Brief Group Type */
  briefGroupType: BriefGroupType;
  /** Related Brief Variant */
  briefVariant: BriefVariant;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `BriefGroupTypeMapping` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BriefGroupTypeMappingCondition = {
  /** Checks for equality with the object’s `briefGroupType` field. */
  briefGroupType?: InputMaybe<BriefGroupType>;
};

/** A filter to be used against `BriefGroupTypeMapping` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupTypeMappingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefGroupTypeMappingFilter>>;
  /** Filter by the object’s `briefGroupType` field. */
  briefGroupType?: InputMaybe<BriefGroupTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefGroupTypeMappingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefGroupTypeMappingFilter>>;
};

/** An input for mutations affecting `BriefGroupTypeMapping` */
export type BriefGroupTypeMappingInput = {
  /** Related Brief Group Type */
  briefGroupType: BriefGroupType;
  /** Related Brief Variant */
  briefVariant: BriefVariant;
};

/** Represents an update to a `BriefGroupTypeMapping`. Fields that are set will be updated. */
export type BriefGroupTypeMappingPatch = {
  /** Related Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  /** Related Brief Variant */
  briefVariant?: InputMaybe<BriefVariant>;
};

/** A connection to a list of `BriefGroupTypeMapping` values. */
export type BriefGroupTypeMappingsConnection = {
  __typename?: 'BriefGroupTypeMappingsConnection';
  /** A list of edges which contains the `BriefGroupTypeMapping` and cursor to aid in pagination. */
  edges: Array<BriefGroupTypeMappingsEdge>;
  /** A list of `BriefGroupTypeMapping` objects. */
  nodes: Array<BriefGroupTypeMapping>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BriefGroupTypeMapping` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BriefGroupTypeMapping` edge in the connection. */
export type BriefGroupTypeMappingsEdge = {
  __typename?: 'BriefGroupTypeMappingsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `BriefGroupTypeMapping` at the end of the edge. */
  node: BriefGroupTypeMapping;
};

/** Methods to use when ordering `BriefGroupTypeMapping`. */
export enum BriefGroupTypeMappingsOrderBy {
  BriefGroupTypeAsc = 'BRIEF_GROUP_TYPE_ASC',
  BriefGroupTypeDesc = 'BRIEF_GROUP_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Brief Group Variable (for Trading) */
export type BriefGroupVariable = Node & {
  __typename?: 'BriefGroupVariable';
  /** Reads a single `BriefGroup` that is related to this `BriefGroupVariable`. */
  briefGroup: Maybe<BriefGroup>;
  /** Foreign key to Brief Group where this variable belongs */
  briefGroupId: Scalars['Int'];
  /** Reads and enables pagination through a set of `BriefGroupVariableOption`. */
  briefGroupVariableOptionsByVariableId: BriefGroupVariableOptionsConnection;
  /** Reads and enables pagination through a set of `BriefGroupVariableOption`. */
  briefGroupVariableOptionsByVariableIdList: Array<BriefGroupVariableOption>;
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** Name of the brief group Variable */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};


/** Brief Group Variable (for Trading) */
export type BriefGroupVariableBriefGroupVariableOptionsByVariableIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupVariableOptionCondition>;
  filter?: InputMaybe<BriefGroupVariableOptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariableOptionsOrderBy>>;
};


/** Brief Group Variable (for Trading) */
export type BriefGroupVariableBriefGroupVariableOptionsByVariableIdListArgs = {
  condition?: InputMaybe<BriefGroupVariableOptionCondition>;
  filter?: InputMaybe<BriefGroupVariableOptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariableOptionsOrderBy>>;
};

/** The `briefGroup` to be created by this mutation. */
export type BriefGroupVariableBriefGroupIdFkeyBriefGroupCreateInput = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name: Scalars['String'];
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** The `briefGroupVariable` to be created by this mutation. */
export type BriefGroupVariableBriefGroupIdFkeyBriefGroupVariableCreateInput = {
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name: Scalars['String'];
};

/** Input for the nested mutation of `briefGroup` in the `BriefGroupVariableInput` mutation. */
export type BriefGroupVariableBriefGroupIdFkeyInput = {
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectById?: InputMaybe<BriefGroupBriefGroupPkeyConnect>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BriefGroupNodeIdConnect>;
  /** A `BriefGroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyBriefGroupCreateInput>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteById?: InputMaybe<BriefGroupBriefGroupPkeyDelete>;
  /** The primary key(s) for `briefGroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<BriefGroupNodeIdDelete>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateById?: InputMaybe<BriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyUsingBriefGroupPkeyUpdate>;
  /** The primary key(s) and patch data for `briefGroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `briefGroupVariable` in the `BriefGroupInput` mutation. */
export type BriefGroupVariableBriefGroupIdFkeyInverseInput = {
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefGroupVariableBriefGroupVariablePkeyConnect>>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefGroupVariableNodeIdConnect>>;
  /** A `BriefGroupVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BriefGroupVariableBriefGroupIdFkeyBriefGroupVariableCreateInput>>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<BriefGroupVariableBriefGroupVariablePkeyDelete>>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<BriefGroupVariableNodeIdDelete>>;
  /** Flag indicating whether all other `briefGroupVariable` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `briefGroupVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyUsingBriefGroupVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `briefGroupVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<BriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyNodeIdUpdate>>;
};

/** The fields on `briefGroupVariable` to look up the row to connect. */
export type BriefGroupVariableBriefGroupVariablePkeyConnect = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
};

/** The fields on `briefGroupVariable` to look up the row to delete. */
export type BriefGroupVariableBriefGroupVariablePkeyDelete = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
};

/**
 * A condition to be used against `BriefGroupVariable` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BriefGroupVariableCondition = {
  /** Checks for equality with the object’s `briefGroupId` field. */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BriefGroupVariable` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupVariableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefGroupVariableFilter>>;
  /** Filter by the object’s `briefGroup` relation. */
  briefGroup?: InputMaybe<BriefGroupFilter>;
  /** Filter by the object’s `briefGroupId` field. */
  briefGroupId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `briefGroupVariableOptionsByVariableId` relation. */
  briefGroupVariableOptionsByVariableId?: InputMaybe<BriefGroupVariableToManyBriefGroupVariableOptionFilter>;
  /** Some related `briefGroupVariableOptionsByVariableId` exist. */
  briefGroupVariableOptionsByVariableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefGroupVariableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefGroupVariableFilter>>;
};

/** An input for mutations affecting `BriefGroupVariable` */
export type BriefGroupVariableInput = {
  /** Foreign key to Brief Group where this variable belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name: Scalars['String'];
};

/** The globally unique `ID` look up for the row to connect. */
export type BriefGroupVariableNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `briefGroupVariable` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BriefGroupVariableNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `briefGroupVariable` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** The fields on `briefGroupVariable` to look up the row to update. */
export type BriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyUsingBriefGroupVariablePkeyUpdate = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
  patch: UpdateBriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroupVariableOption` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroupVariableOption` being updated. */
  patch: BriefGroupVariableOptionPatch;
};

/** The fields on `briefGroupVariable` to look up the row to update. */
export type BriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyUsingBriefGroupVariablePkeyUpdate = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
  patch: UpdateBriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyPatch;
};

/** Brief Group Variable Options */
export type BriefGroupVariableOption = Node & {
  __typename?: 'BriefGroupVariableOption';
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** Variable Option Label */
  label: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Points for Buyers */
  pointsBuyers: Maybe<Scalars['Int']>;
  /** Points for Sellers */
  pointsSellers: Maybe<Scalars['Int']>;
  /** Used for sorting (order) of the Variable Option */
  rank: Maybe<Scalars['Int']>;
  /** Reads a single `BriefGroupVariable` that is related to this `BriefGroupVariableOption`. */
  variable: Maybe<BriefGroupVariable>;
  /** Foreign key to Brief Group Variable where this option belongs */
  variableId: Scalars['Int'];
};

/** The fields on `briefGroupVariableOption` to look up the row to connect. */
export type BriefGroupVariableOptionBriefGroupVariableOptionPkeyConnect = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
};

/**
 * A condition to be used against `BriefGroupVariableOption` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BriefGroupVariableOptionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `variableId` field. */
  variableId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BriefGroupVariableOption` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupVariableOptionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefGroupVariableOptionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefGroupVariableOptionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefGroupVariableOptionFilter>>;
  /** Filter by the object’s `variable` relation. */
  variable?: InputMaybe<BriefGroupVariableFilter>;
  /** Filter by the object’s `variableId` field. */
  variableId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `BriefGroupVariableOptionInputRecord` */
export type BriefGroupVariableOptionInputRecordInput = {
  label?: InputMaybe<Scalars['String']>;
  pointsBuyers?: InputMaybe<Scalars['String']>;
  pointsSellers?: InputMaybe<Scalars['String']>;
  rank?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BriefGroupVariableOptionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `briefGroupVariableOption` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroupVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
  patch: BriefGroupVariablePatch;
};

/** The fields on `briefGroupVariableOption` to look up the row to update. */
export type BriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyUsingBriefGroupVariableOptionPkeyUpdate = {
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefGroupVariableOption` being updated. */
  patch: UpdateBriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyPatch;
};

/** Represents an update to a `BriefGroupVariableOption`. Fields that are set will be updated. */
export type BriefGroupVariableOptionPatch = {
  briefGroupVariableToVariableId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Variable Option Label */
  label?: InputMaybe<Scalars['String']>;
  /** Points for Buyers */
  pointsBuyers?: InputMaybe<Scalars['Int']>;
  /** Points for Sellers */
  pointsSellers?: InputMaybe<Scalars['Int']>;
  /** Used for sorting (order) of the Variable Option */
  rank?: InputMaybe<Scalars['Int']>;
  /** Foreign key to Brief Group Variable where this option belongs */
  variableId?: InputMaybe<Scalars['Int']>;
};

/** The `briefGroupVariable` to be created by this mutation. */
export type BriefGroupVariableOptionVariableIdFkeyBriefGroupVariableCreateInput = {
  /** Foreign key to Brief Group where this variable belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name: Scalars['String'];
};

/** Input for the nested mutation of `briefGroupVariable` in the `BriefGroupVariableOptionInput` mutation. */
export type BriefGroupVariableOptionVariableIdFkeyInput = {
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  connectById?: InputMaybe<BriefGroupVariableBriefGroupVariablePkeyConnect>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BriefGroupVariableNodeIdConnect>;
  /** A `BriefGroupVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyBriefGroupVariableCreateInput>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  deleteById?: InputMaybe<BriefGroupVariableBriefGroupVariablePkeyDelete>;
  /** The primary key(s) for `briefGroupVariable` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<BriefGroupVariableNodeIdDelete>;
  /** The primary key(s) and patch data for `briefGroupVariable` for the far side of the relationship. */
  updateById?: InputMaybe<BriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyUsingBriefGroupVariablePkeyUpdate>;
  /** The primary key(s) and patch data for `briefGroupVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `briefGroupVariableOption` in the `BriefGroupVariableInput` mutation. */
export type BriefGroupVariableOptionVariableIdFkeyInverseInput = {
  /** The primary key(s) for `briefGroupVariableOption` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefGroupVariableOptionBriefGroupVariableOptionPkeyConnect>>;
  /** The primary key(s) for `briefGroupVariableOption` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefGroupVariableOptionNodeIdConnect>>;
  /** The primary key(s) and patch data for `briefGroupVariableOption` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyUsingBriefGroupVariableOptionPkeyUpdate>>;
  /** The primary key(s) and patch data for `briefGroupVariableOption` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<BriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `BriefGroupVariableOption` values. */
export type BriefGroupVariableOptionsConnection = {
  __typename?: 'BriefGroupVariableOptionsConnection';
  /** A list of edges which contains the `BriefGroupVariableOption` and cursor to aid in pagination. */
  edges: Array<BriefGroupVariableOptionsEdge>;
  /** A list of `BriefGroupVariableOption` objects. */
  nodes: Array<BriefGroupVariableOption>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BriefGroupVariableOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BriefGroupVariableOption` edge in the connection. */
export type BriefGroupVariableOptionsEdge = {
  __typename?: 'BriefGroupVariableOptionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `BriefGroupVariableOption` at the end of the edge. */
  node: BriefGroupVariableOption;
};

/** Methods to use when ordering `BriefGroupVariableOption`. */
export enum BriefGroupVariableOptionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  VariableIdAsc = 'VARIABLE_ID_ASC',
  VariableIdDesc = 'VARIABLE_ID_DESC'
}

/** Represents an update to a `BriefGroupVariable`. Fields that are set will be updated. */
export type BriefGroupVariablePatch = {
  /** Foreign key to Brief Group where this variable belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against many `BriefGroupVariableOption` object types. All fields are combined with a logical ‘and.’ */
export type BriefGroupVariableToManyBriefGroupVariableOptionFilter = {
  /** Every related `BriefGroupVariableOption` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefGroupVariableOptionFilter>;
  /** No related `BriefGroupVariableOption` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefGroupVariableOptionFilter>;
  /** Some related `BriefGroupVariableOption` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefGroupVariableOptionFilter>;
};

/** A connection to a list of `BriefGroupVariable` values. */
export type BriefGroupVariablesConnection = {
  __typename?: 'BriefGroupVariablesConnection';
  /** A list of edges which contains the `BriefGroupVariable` and cursor to aid in pagination. */
  edges: Array<BriefGroupVariablesEdge>;
  /** A list of `BriefGroupVariable` objects. */
  nodes: Array<BriefGroupVariable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BriefGroupVariable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BriefGroupVariable` edge in the connection. */
export type BriefGroupVariablesEdge = {
  __typename?: 'BriefGroupVariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `BriefGroupVariable` at the end of the edge. */
  node: BriefGroupVariable;
};

/** Methods to use when ordering `BriefGroupVariable`. */
export enum BriefGroupVariablesOrderBy {
  BriefGroupIdAsc = 'BRIEF_GROUP_ID_ASC',
  BriefGroupIdDesc = 'BRIEF_GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `BriefGroup` values. */
export type BriefGroupsConnection = {
  __typename?: 'BriefGroupsConnection';
  /** A list of edges which contains the `BriefGroup` and cursor to aid in pagination. */
  edges: Array<BriefGroupsEdge>;
  /** A list of `BriefGroup` objects. */
  nodes: Array<BriefGroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BriefGroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BriefGroup` edge in the connection. */
export type BriefGroupsEdge = {
  __typename?: 'BriefGroupsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `BriefGroup` at the end of the edge. */
  node: BriefGroup;
};

/** Methods to use when ordering `BriefGroup`. */
export enum BriefGroupsOrderBy {
  ActivityIdAsc = 'ACTIVITY_ID_ASC',
  ActivityIdDesc = 'ACTIVITY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LanguageCodeAsc = 'LANGUAGE_CODE_ASC',
  LanguageCodeDesc = 'LANGUAGE_CODE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** An input for mutations affecting `Brief` */
export type BriefInput = {
  /** Foreign key to Brief Group (scenario) where this brief belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefBriefGroupIdFkeyInput>;
  /** Variant of the brief, e.g. Buyer or Player 1 */
  briefVariant: BriefVariant;
  /** Instructions for the person getting the brief. If present, should be shown in activities instead of the PDF. */
  content?: InputMaybe<Scalars['String']>;
  /** When was the brief created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief, e.g. School */
  name: Scalars['String'];
  /** The URL of an uploaded PDF document. If it exists, it should always be used when printing/downloading */
  pathUrl?: InputMaybe<Scalars['String']>;
  /** When was the brief last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BriefNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `brief` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BriefNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `brief` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BriefOnBriefForBriefBriefGroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** The fields on `brief` to look up the row to update. */
export type BriefOnBriefForBriefBriefGroupIdFkeyUsingBriefPkeyUpdate = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `brief` being updated. */
  patch: UpdateBriefOnBriefForBriefBriefGroupIdFkeyPatch;
};

/** Represents an update to a `Brief`. Fields that are set will be updated. */
export type BriefPatch = {
  /** Foreign key to Brief Group (scenario) where this brief belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefBriefGroupIdFkeyInput>;
  /** Variant of the brief, e.g. Buyer or Player 1 */
  briefVariant?: InputMaybe<BriefVariant>;
  /** Instructions for the person getting the brief. If present, should be shown in activities instead of the PDF. */
  content?: InputMaybe<Scalars['String']>;
  /** When was the brief created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief, e.g. School */
  name?: InputMaybe<Scalars['String']>;
  /** The URL of an uploaded PDF document. If it exists, it should always be used when printing/downloading */
  pathUrl?: InputMaybe<Scalars['String']>;
  /** When was the brief last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Brief Preset */
export type BriefPreset = Node & {
  __typename?: 'BriefPreset';
  /** An ordered array of foreign keys to Brief Groups related to the corresponding Workshop Preset Activity */
  briefGroups: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Title of the brief preset */
  title: Scalars['String'];
  /** Reads a single `WorkshopPreset` that is related to this `BriefPreset`. */
  workshopPreset: Maybe<WorkshopPreset>;
  /** Foreign key to a Workshop Preset */
  workshopPresetId: Scalars['Int'];
};

/** The fields on `briefPreset` to look up the row to connect. */
export type BriefPresetBriefPresetPkeyConnect = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/** The fields on `briefPreset` to look up the row to delete. */
export type BriefPresetBriefPresetPkeyDelete = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/**
 * A condition to be used against `BriefPreset` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BriefPresetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `workshopPresetId` field. */
  workshopPresetId?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `BriefPreset` object types. All fields are combined with a logical ‘and.’ */
export type BriefPresetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BriefPresetFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BriefPresetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BriefPresetFilter>>;
  /** Filter by the object’s `workshopPreset` relation. */
  workshopPreset?: InputMaybe<WorkshopPresetFilter>;
  /** Filter by the object’s `workshopPresetId` field. */
  workshopPresetId?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `BriefPreset` */
export type BriefPresetInput = {
  /** An ordered array of foreign keys to Brief Groups related to the corresponding Workshop Preset Activity */
  briefGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Title of the brief preset */
  title: Scalars['String'];
  /** Foreign key to a Workshop Preset */
  workshopPresetId?: InputMaybe<Scalars['Int']>;
  workshopPresetToWorkshopPresetId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BriefPresetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `briefPreset` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type BriefPresetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `briefPreset` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type BriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `workshopPreset` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `workshopPreset` being updated. */
  patch: WorkshopPresetPatch;
};

/** The fields on `briefPreset` to look up the row to update. */
export type BriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyUsingBriefPresetPkeyUpdate = {
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `briefPreset` being updated. */
  patch: UpdateBriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyPatch;
};

/** Represents an update to a `BriefPreset`. Fields that are set will be updated. */
export type BriefPresetPatch = {
  /** An ordered array of foreign keys to Brief Groups related to the corresponding Workshop Preset Activity */
  briefGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Title of the brief preset */
  title?: InputMaybe<Scalars['String']>;
  /** Foreign key to a Workshop Preset */
  workshopPresetId?: InputMaybe<Scalars['Int']>;
  workshopPresetToWorkshopPresetId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInput>;
};

/** The `briefPreset` to be created by this mutation. */
export type BriefPresetWorkshopPresetIdFkeyBriefPresetCreateInput = {
  /** An ordered array of foreign keys to Brief Groups related to the corresponding Workshop Preset Activity */
  briefGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Title of the brief preset */
  title: Scalars['String'];
  workshopPresetToWorkshopPresetId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInput>;
};

/** Input for the nested mutation of `workshopPreset` in the `BriefPresetInput` mutation. */
export type BriefPresetWorkshopPresetIdFkeyInput = {
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  connectById?: InputMaybe<WorkshopPresetWorkshopPresetPkeyConnect>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<WorkshopPresetNodeIdConnect>;
  /** A `WorkshopPresetInput` object that will be created and connected to this object. */
  create?: InputMaybe<BriefPresetWorkshopPresetIdFkeyWorkshopPresetCreateInput>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkshopPresetWorkshopPresetPkeyDelete>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<WorkshopPresetNodeIdDelete>;
  /** The primary key(s) and patch data for `workshopPreset` for the far side of the relationship. */
  updateById?: InputMaybe<WorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyUsingWorkshopPresetPkeyUpdate>;
  /** The primary key(s) and patch data for `workshopPreset` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `briefPreset` in the `WorkshopPresetInput` mutation. */
export type BriefPresetWorkshopPresetIdFkeyInverseInput = {
  /** The primary key(s) for `briefPreset` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BriefPresetBriefPresetPkeyConnect>>;
  /** The primary key(s) for `briefPreset` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BriefPresetNodeIdConnect>>;
  /** A `BriefPresetInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BriefPresetWorkshopPresetIdFkeyBriefPresetCreateInput>>;
  /** The primary key(s) for `briefPreset` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<BriefPresetBriefPresetPkeyDelete>>;
  /** The primary key(s) for `briefPreset` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<BriefPresetNodeIdDelete>>;
  /** Flag indicating whether all other `briefPreset` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `briefPreset` for the far side of the relationship. */
  updateById?: InputMaybe<Array<BriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyUsingBriefPresetPkeyUpdate>>;
  /** The primary key(s) and patch data for `briefPreset` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<WorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyNodeIdUpdate>>;
};

/** The `workshopPreset` to be created by this mutation. */
export type BriefPresetWorkshopPresetIdFkeyWorkshopPresetCreateInput = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode: Scalars['String'];
  /** Workshop Level */
  level: WorkshopLevel;
  /** Title of the workshop preset */
  title: Scalars['String'];
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};

/** A connection to a list of `BriefPreset` values. */
export type BriefPresetsConnection = {
  __typename?: 'BriefPresetsConnection';
  /** A list of edges which contains the `BriefPreset` and cursor to aid in pagination. */
  edges: Array<BriefPresetsEdge>;
  /** A list of `BriefPreset` objects. */
  nodes: Array<BriefPreset>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BriefPreset` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BriefPreset` edge in the connection. */
export type BriefPresetsEdge = {
  __typename?: 'BriefPresetsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `BriefPreset` at the end of the edge. */
  node: BriefPreset;
};

/** Methods to use when ordering `BriefPreset`. */
export enum BriefPresetsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  WorkshopPresetIdAsc = 'WORKSHOP_PRESET_ID_ASC',
  WorkshopPresetIdDesc = 'WORKSHOP_PRESET_ID_DESC'
}

export enum BriefVariant {
  Buyer = 'BUYER',
  Common = 'COMMON',
  Player_1 = 'PLAYER_1',
  Player_2 = 'PLAYER_2',
  Player_3 = 'PLAYER_3',
  Player_4 = 'PLAYER_4',
  Player_5 = 'PLAYER_5',
  Player_6 = 'PLAYER_6',
  Player_7 = 'PLAYER_7',
  Player_8 = 'PLAYER_8',
  Player_9 = 'PLAYER_9',
  Player_10 = 'PLAYER_10',
  Player_11 = 'PLAYER_11',
  Player_12 = 'PLAYER_12',
  Player_13 = 'PLAYER_13',
  Player_14 = 'PLAYER_14',
  Player_15 = 'PLAYER_15',
  Player_16 = 'PLAYER_16',
  Player_17 = 'PLAYER_17',
  Player_18 = 'PLAYER_18',
  Player_19 = 'PLAYER_19',
  Player_20 = 'PLAYER_20',
  Seller = 'SELLER'
}

/** A filter to be used against BriefVariant List fields. All fields are combined with a logical ‘and.’ */
export type BriefVariantListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<BriefVariant>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<BriefVariant>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<BriefVariant>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<BriefVariant>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<BriefVariant>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<BriefVariant>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<BriefVariant>>>;
};

/** A connection to a list of `Brief` values. */
export type BriefsConnection = {
  __typename?: 'BriefsConnection';
  /** A list of edges which contains the `Brief` and cursor to aid in pagination. */
  edges: Array<BriefsEdge>;
  /** A list of `Brief` objects. */
  nodes: Array<Brief>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Brief` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Brief` edge in the connection. */
export type BriefsEdge = {
  __typename?: 'BriefsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Brief` at the end of the edge. */
  node: Brief;
};

/** Methods to use when ordering `Brief`. */
export enum BriefsOrderBy {
  BriefGroupIdAsc = 'BRIEF_GROUP_ID_ASC',
  BriefGroupIdDesc = 'BRIEF_GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `changePassword` mutation. */
export type ChangePasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

/** The output of our `changePassword` mutation. */
export type ChangePasswordPayload = {
  __typename?: 'ChangePasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

export type Class = Node & {
  __typename?: 'Class';
  /** Reads a single `Account` that is related to this `Class`. */
  account: Maybe<Account>;
  /** Company that the class is created for */
  accountId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ClassDocument`. */
  classDocuments: ClassDocumentsConnection;
  /** Reads and enables pagination through a set of `ClassDocument`. */
  classDocumentsList: Array<ClassDocument>;
  /** Reads and enables pagination through a set of `ClassStar`. */
  classStars: ClassStarsConnection;
  /** Reads and enables pagination through a set of `ClassStar`. */
  classStarsList: Array<ClassStar>;
  /** Reads and enables pagination through a set of `ClassUser`. */
  classUsers: ClassUsersConnection;
  /** Reads and enables pagination through a set of `ClassUser`. */
  classUsersList: Array<ClassUser>;
  /** Reads and enables pagination through a set of `ClassVideo`. */
  classVideos: ClassVideosConnection;
  /** Reads and enables pagination through a set of `ClassVideo`. */
  classVideosList: Array<ClassVideo>;
  createdAt: Scalars['Datetime'];
  /** Creator of the class, related to the users model */
  createdBy: Maybe<Scalars['UUID']>;
  description: Maybe<Scalars['String']>;
  durationWatched: Maybe<Scalars['Int']>;
  /** How often are videos released in the class relative to the start date */
  frequency: Maybe<ClassFrequency>;
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished: Scalars['Boolean'];
  isStarred: Maybe<Scalars['Boolean']>;
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Start date of the class */
  startAt: Scalars['Date'];
  thumbnailUrl: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Class`. */
  userByCreatedBy: Maybe<User>;
};


export type ClassClassDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


export type ClassClassDocumentsListArgs = {
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


export type ClassClassStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassStarCondition>;
  filter?: InputMaybe<ClassStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};


export type ClassClassStarsListArgs = {
  condition?: InputMaybe<ClassStarCondition>;
  filter?: InputMaybe<ClassStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};


export type ClassClassUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


export type ClassClassUsersListArgs = {
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


export type ClassClassVideosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};


export type ClassClassVideosListArgs = {
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};

/** The `account` to be created by this mutation. */
export type ClassAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** The `class` to be created by this mutation. */
export type ClassAccountIdFkeyClassCreateInput = {
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** Input for the nested mutation of `account` in the `ClassInput` mutation. */
export type ClassAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnClassForClassAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnClassForClassAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassOnClassForClassAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `class` in the `AccountInput` mutation. */
export type ClassAccountIdFkeyInverseInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassClassPkeyConnect>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassNodeIdConnect>>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassAccountIdFkeyClassCreateInput>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassClassPkeyDelete>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassNodeIdDelete>>;
  /** Flag indicating whether all other `class` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassOnClassForClassAccountIdFkeyUsingClassPkeyUpdate>>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnClassForClassAccountIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `ClassAssetCategory` values. */
export type ClassAssetCategoriesConnection = {
  __typename?: 'ClassAssetCategoriesConnection';
  /** A list of edges which contains the `ClassAssetCategory` and cursor to aid in pagination. */
  edges: Array<ClassAssetCategoriesEdge>;
  /** A list of `ClassAssetCategory` objects. */
  nodes: Array<ClassAssetCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassAssetCategory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassAssetCategory` edge in the connection. */
export type ClassAssetCategoriesEdge = {
  __typename?: 'ClassAssetCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassAssetCategory` at the end of the edge. */
  node: ClassAssetCategory;
};

/** Methods to use when ordering `ClassAssetCategory`. */
export enum ClassAssetCategoriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ClassAssetCategory = Node & {
  __typename?: 'ClassAssetCategory';
  /** Reads and enables pagination through a set of `Document`. */
  documentsByCategoryId: DocumentsConnection;
  /** Reads and enables pagination through a set of `Document`. */
  documentsByCategoryIdList: Array<Document>;
  id: Scalars['UUID'];
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Video`. */
  videosByCategoryId: VideosConnection;
  /** Reads and enables pagination through a set of `Video`. */
  videosByCategoryIdList: Array<Video>;
};


export type ClassAssetCategoryDocumentsByCategoryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


export type ClassAssetCategoryDocumentsByCategoryIdListArgs = {
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


export type ClassAssetCategoryVideosByCategoryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};


export type ClassAssetCategoryVideosByCategoryIdListArgs = {
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};

/** The fields on `classAssetCategory` to look up the row to connect. */
export type ClassAssetCategoryClassAssetCategoryPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classAssetCategory` to look up the row to delete. */
export type ClassAssetCategoryClassAssetCategoryPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `ClassAssetCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClassAssetCategoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ClassAssetCategory` object types. All fields are combined with a logical ‘and.’ */
export type ClassAssetCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassAssetCategoryFilter>>;
  /** Filter by the object’s `documentsByCategoryId` relation. */
  documentsByCategoryId?: InputMaybe<ClassAssetCategoryToManyDocumentFilter>;
  /** Some related `documentsByCategoryId` exist. */
  documentsByCategoryIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassAssetCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassAssetCategoryFilter>>;
  /** Filter by the object’s `videosByCategoryId` relation. */
  videosByCategoryId?: InputMaybe<ClassAssetCategoryToManyVideoFilter>;
  /** Some related `videosByCategoryId` exist. */
  videosByCategoryIdExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `ClassAssetCategory` */
export type ClassAssetCategoryInput = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassAssetCategoryNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classAssetCategory` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassAssetCategoryNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classAssetCategory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `document` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: DocumentPatch;
};

/** The fields on `classAssetCategory` to look up the row to update. */
export type ClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyUsingClassAssetCategoryPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classAssetCategory` being updated. */
  patch: UpdateClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassAssetCategoryOnVideoForVideoCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `video` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: VideoPatch;
};

/** The fields on `classAssetCategory` to look up the row to update. */
export type ClassAssetCategoryOnVideoForVideoCategoryIdFkeyUsingClassAssetCategoryPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classAssetCategory` being updated. */
  patch: UpdateClassAssetCategoryOnVideoForVideoCategoryIdFkeyPatch;
};

/** Represents an update to a `ClassAssetCategory`. Fields that are set will be updated. */
export type ClassAssetCategoryPatch = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** A filter to be used against many `Document` object types. All fields are combined with a logical ‘and.’ */
export type ClassAssetCategoryToManyDocumentFilter = {
  /** Every related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentFilter>;
  /** No related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentFilter>;
  /** Some related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentFilter>;
};

/** A filter to be used against many `Video` object types. All fields are combined with a logical ‘and.’ */
export type ClassAssetCategoryToManyVideoFilter = {
  /** Every related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VideoFilter>;
  /** No related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VideoFilter>;
  /** Some related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VideoFilter>;
};

/** The fields on `class` to look up the row to connect. */
export type ClassClassPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `class` to look up the row to delete. */
export type ClassClassPkeyDelete = {
  id: Scalars['UUID'];
};

/** A condition to be used against `Class` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ClassCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `frequency` field. */
  frequency?: InputMaybe<ClassFrequency>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isPublished` field. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: InputMaybe<Scalars['Date']>;
};

/** The `class` to be created by this mutation. */
export type ClassCreatedByFkeyClassCreateInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** Input for the nested mutation of `user` in the `ClassInput` mutation. */
export type ClassCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnClassForClassCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnClassForClassCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassOnClassForClassCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `class` in the `UserInput` mutation. */
export type ClassCreatedByFkeyInverseInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassClassPkeyConnect>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassNodeIdConnect>>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassCreatedByFkeyClassCreateInput>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassClassPkeyDelete>>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassNodeIdDelete>>;
  /** Flag indicating whether all other `class` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassOnClassForClassCreatedByFkeyUsingClassPkeyUpdate>>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnClassForClassCreatedByFkeyNodeIdUpdate>>;
};

export type ClassDocument = Node & {
  __typename?: 'ClassDocument';
  /** Reads a single `Class` that is related to this `ClassDocument`. */
  class: Maybe<Class>;
  classId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Document` that is related to this `ClassDocument`. */
  document: Maybe<Document>;
  documentId: Scalars['UUID'];
  id: Scalars['UUID'];
  isLocked: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
};

/** The fields on `classDocument` to look up the row to connect. */
export type ClassDocumentClassDocumentPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classDocument` to look up the row to delete. */
export type ClassDocumentClassDocumentPkeyDelete = {
  id: Scalars['UUID'];
};

/** The `class` to be created by this mutation. */
export type ClassDocumentClassIdFkeyClassCreateInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** The `classDocument` to be created by this mutation. */
export type ClassDocumentClassIdFkeyClassDocumentCreateInput = {
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `class` in the `ClassDocumentInput` mutation. */
export type ClassDocumentClassIdFkeyInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<ClassClassPkeyConnect>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassNodeIdConnect>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassDocumentClassIdFkeyClassCreateInput>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassClassPkeyDelete>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassNodeIdDelete>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<ClassOnClassDocumentForClassDocumentClassIdFkeyUsingClassPkeyUpdate>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classDocument` in the `ClassInput` mutation. */
export type ClassDocumentClassIdFkeyInverseInput = {
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentUniqClassDocumentConnect>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassDocumentClassDocumentPkeyConnect>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassDocumentNodeIdConnect>>;
  /** A `ClassDocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassDocumentClassIdFkeyClassDocumentCreateInput>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentUniqClassDocumentDelete>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassDocumentClassDocumentPkeyDelete>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassDocumentNodeIdDelete>>;
  /** Flag indicating whether all other `classDocument` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyUsingUniqClassDocumentUpdate>>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyUsingClassDocumentPkeyUpdate>>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassOnClassDocumentForClassDocumentClassIdFkeyNodeIdUpdate>>;
};

/**
 * A condition to be used against `ClassDocument` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClassDocumentCondition = {
  /** Checks for equality with the object’s `classId` field. */
  classId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `documentId` field. */
  documentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `rank` field. */
  rank?: InputMaybe<Scalars['Int']>;
};

/** The `classDocument` to be created by this mutation. */
export type ClassDocumentDocumentIdFkeyClassDocumentCreateInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `document` to be created by this mutation. */
export type ClassDocumentDocumentIdFkeyDocumentCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** Input for the nested mutation of `document` in the `ClassDocumentInput` mutation. */
export type ClassDocumentDocumentIdFkeyInput = {
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectById?: InputMaybe<DocumentDocumentPkeyConnect>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<DocumentNodeIdConnect>;
  /** A `DocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassDocumentDocumentIdFkeyDocumentCreateInput>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteById?: InputMaybe<DocumentDocumentPkeyDelete>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<DocumentNodeIdDelete>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateById?: InputMaybe<DocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingDocumentPkeyUpdate>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classDocument` in the `DocumentInput` mutation. */
export type ClassDocumentDocumentIdFkeyInverseInput = {
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentUniqClassDocumentConnect>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassDocumentClassDocumentPkeyConnect>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassDocumentNodeIdConnect>>;
  /** A `ClassDocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassDocumentDocumentIdFkeyClassDocumentCreateInput>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentUniqClassDocumentDelete>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassDocumentClassDocumentPkeyDelete>>;
  /** The primary key(s) for `classDocument` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassDocumentNodeIdDelete>>;
  /** Flag indicating whether all other `classDocument` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateByClassIdAndDocumentId?: InputMaybe<Array<ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingUniqClassDocumentUpdate>>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingClassDocumentPkeyUpdate>>;
  /** The primary key(s) and patch data for `classDocument` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<DocumentOnClassDocumentForClassDocumentDocumentIdFkeyNodeIdUpdate>>;
};

/** A filter to be used against `ClassDocument` object types. All fields are combined with a logical ‘and.’ */
export type ClassDocumentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassDocumentFilter>>;
  /** Filter by the object’s `class` relation. */
  class?: InputMaybe<ClassFilter>;
  /** Filter by the object’s `classId` field. */
  classId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `document` relation. */
  document?: InputMaybe<DocumentFilter>;
  /** Filter by the object’s `documentId` field. */
  documentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassDocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassDocumentFilter>>;
  /** Filter by the object’s `rank` field. */
  rank?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ClassDocument` */
export type ClassDocumentInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassDocumentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classDocument` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassDocumentNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classDocument` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `classDocument` to look up the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyUsingClassDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: UpdateClassDocumentOnClassDocumentForClassDocumentClassIdFkeyPatch;
};

/** The fields on `classDocument` to look up the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentClassIdFkeyUsingUniqClassDocumentUpdate = {
  classId: Scalars['UUID'];
  documentId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: UpdateClassDocumentOnClassDocumentForClassDocumentClassIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `document` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: DocumentPatch;
};

/** The fields on `classDocument` to look up the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingClassDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: UpdateClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyPatch;
};

/** The fields on `classDocument` to look up the row to update. */
export type ClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingUniqClassDocumentUpdate = {
  classId: Scalars['UUID'];
  documentId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: UpdateClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyPatch;
};

/** Represents an update to a `ClassDocument`. Fields that are set will be updated. */
export type ClassDocumentPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `classDocument` to look up the row to connect. */
export type ClassDocumentUniqClassDocumentConnect = {
  classId: Scalars['UUID'];
  documentId: Scalars['UUID'];
};

/** The fields on `classDocument` to look up the row to delete. */
export type ClassDocumentUniqClassDocumentDelete = {
  classId: Scalars['UUID'];
  documentId: Scalars['UUID'];
};

/** A connection to a list of `ClassDocument` values. */
export type ClassDocumentsConnection = {
  __typename?: 'ClassDocumentsConnection';
  /** A list of edges which contains the `ClassDocument` and cursor to aid in pagination. */
  edges: Array<ClassDocumentsEdge>;
  /** A list of `ClassDocument` objects. */
  nodes: Array<ClassDocument>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassDocument` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassDocument` edge in the connection. */
export type ClassDocumentsEdge = {
  __typename?: 'ClassDocumentsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassDocument` at the end of the edge. */
  node: ClassDocument;
};

/** Methods to use when ordering `ClassDocument`. */
export enum ClassDocumentsOrderBy {
  ClassIdAsc = 'CLASS_ID_ASC',
  ClassIdDesc = 'CLASS_ID_DESC',
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC'
}

/** A filter to be used against `Class` object types. All fields are combined with a logical ‘and.’ */
export type ClassFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassFilter>>;
  /** Filter by the object’s `classDocuments` relation. */
  classDocuments?: InputMaybe<ClassToManyClassDocumentFilter>;
  /** Some related `classDocuments` exist. */
  classDocumentsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classStars` relation. */
  classStars?: InputMaybe<ClassToManyClassStarFilter>;
  /** Some related `classStars` exist. */
  classStarsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classUsers` relation. */
  classUsers?: InputMaybe<ClassToManyClassUserFilter>;
  /** Some related `classUsers` exist. */
  classUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classVideos` relation. */
  classVideos?: InputMaybe<ClassToManyClassVideoFilter>;
  /** Some related `classVideos` exist. */
  classVideosExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `durationWatched` field. */
  durationWatched?: InputMaybe<IntFilter>;
  /** Filter by the object’s `frequency` field. */
  frequency?: InputMaybe<ClassFrequencyFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isPublished` field. */
  isPublished?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isStarred` field. */
  isStarred?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassFilter>>;
  /** Filter by the object’s `startAt` field. */
  startAt?: InputMaybe<DateFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: InputMaybe<Scalars['Boolean']>;
};

/** The frequency at which videos are released in a class */
export enum ClassFrequency {
  AllAtOnce = 'ALL_AT_ONCE',
  BiWeekly = 'BI_WEEKLY',
  Weekly = 'WEEKLY'
}

/** A filter to be used against ClassFrequency fields. All fields are combined with a logical ‘and.’ */
export type ClassFrequencyFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ClassFrequency>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ClassFrequency>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ClassFrequency>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ClassFrequency>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ClassFrequency>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ClassFrequency>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ClassFrequency>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ClassFrequency>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ClassFrequency>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ClassFrequency>>;
};

/** An input for mutations affecting `Class` */
export type ClassInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `class` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassDocumentForClassDocumentClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classDocument` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: ClassDocumentPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassDocumentForClassDocumentClassIdFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassDocumentForClassDocumentClassIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassForClassAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassForClassAccountIdFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassForClassAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassForClassCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassForClassCreatedByFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassForClassCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassStarForClassStarClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classStar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classStar` being updated. */
  patch: ClassStarPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassStarForClassStarClassIdFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassStarForClassStarClassIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassUserForClassUserClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: ClassUserPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassUserForClassUserClassIdFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassUserForClassUserClassIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ClassOnClassVideoForClassVideoClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classVideo` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: ClassVideoPatch;
};

/** The fields on `class` to look up the row to update. */
export type ClassOnClassVideoForClassVideoClassIdFkeyUsingClassPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: UpdateClassOnClassVideoForClassVideoClassIdFkeyPatch;
};

/** Represents an update to a `Class`. Fields that are set will be updated. */
export type ClassPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** All input for the `classSetIsStarred` mutation. */
export type ClassSetIsStarredInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
};

/** The output of our `classSetIsStarred` mutation. */
export type ClassSetIsStarredPayload = {
  __typename?: 'ClassSetIsStarredPayload';
  /** Reads a single `Class` that is related to this `ClassStar`. */
  class: Maybe<Class>;
  classStar: Maybe<ClassStar>;
  /** An edge for our `ClassStar`. May be used by Relay 1. */
  classStarEdge: Maybe<ClassStarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our `classSetIsStarred` mutation. */
export type ClassSetIsStarredPayloadClassStarEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};

/** Used for the admin starring functionality on classes */
export type ClassStar = Node & {
  __typename?: 'ClassStar';
  /** Reads a single `Class` that is related to this `ClassStar`. */
  class: Maybe<Class>;
  classId: Scalars['UUID'];
  id: Scalars['UUID'];
  isStarred: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['UUID'];
};

/** The `class` to be created by this mutation. */
export type ClassStarClassIdFkeyClassCreateInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** The `classStar` to be created by this mutation. */
export type ClassStarClassIdFkeyClassStarCreateInput = {
  classToClassId?: InputMaybe<ClassStarClassIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `class` in the `ClassStarInput` mutation. */
export type ClassStarClassIdFkeyInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<ClassClassPkeyConnect>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassNodeIdConnect>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassStarClassIdFkeyClassCreateInput>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassClassPkeyDelete>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassNodeIdDelete>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<ClassOnClassStarForClassStarClassIdFkeyUsingClassPkeyUpdate>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassStarOnClassStarForClassStarClassIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classStar` in the `ClassInput` mutation. */
export type ClassStarClassIdFkeyInverseInput = {
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  connectByClassIdAndUserId?: InputMaybe<Array<ClassStarUniqClassStarConnect>>;
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassStarClassStarPkeyConnect>>;
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassStarNodeIdConnect>>;
  /** A `ClassStarInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassStarClassIdFkeyClassStarCreateInput>>;
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  deleteByClassIdAndUserId?: InputMaybe<Array<ClassStarUniqClassStarDelete>>;
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassStarClassStarPkeyDelete>>;
  /** The primary key(s) for `classStar` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassStarNodeIdDelete>>;
  /** Flag indicating whether all other `classStar` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classStar` for the far side of the relationship. */
  updateByClassIdAndUserId?: InputMaybe<Array<ClassStarOnClassStarForClassStarClassIdFkeyUsingUniqClassStarUpdate>>;
  /** The primary key(s) and patch data for `classStar` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassStarOnClassStarForClassStarClassIdFkeyUsingClassStarPkeyUpdate>>;
  /** The primary key(s) and patch data for `classStar` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassOnClassStarForClassStarClassIdFkeyNodeIdUpdate>>;
};

/** The fields on `classStar` to look up the row to connect. */
export type ClassStarClassStarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classStar` to look up the row to delete. */
export type ClassStarClassStarPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `ClassStar` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ClassStarCondition = {
  /** Checks for equality with the object’s `classId` field. */
  classId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ClassStar` object types. All fields are combined with a logical ‘and.’ */
export type ClassStarFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassStarFilter>>;
  /** Filter by the object’s `class` relation. */
  class?: InputMaybe<ClassFilter>;
  /** Filter by the object’s `classId` field. */
  classId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassStarFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassStarFilter>>;
};

/** An input for mutations affecting `ClassStar` */
export type ClassStarInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassStarClassIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassStarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classStar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassStarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassStarOnClassStarForClassStarClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `classStar` to look up the row to update. */
export type ClassStarOnClassStarForClassStarClassIdFkeyUsingClassStarPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classStar` being updated. */
  patch: UpdateClassStarOnClassStarForClassStarClassIdFkeyPatch;
};

/** The fields on `classStar` to look up the row to update. */
export type ClassStarOnClassStarForClassStarClassIdFkeyUsingUniqClassStarUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classStar` being updated. */
  patch: UpdateClassStarOnClassStarForClassStarClassIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `ClassStar`. Fields that are set will be updated. */
export type ClassStarPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassStarClassIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The fields on `classStar` to look up the row to connect. */
export type ClassStarUniqClassStarConnect = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The fields on `classStar` to look up the row to delete. */
export type ClassStarUniqClassStarDelete = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** A connection to a list of `ClassStar` values. */
export type ClassStarsConnection = {
  __typename?: 'ClassStarsConnection';
  /** A list of edges which contains the `ClassStar` and cursor to aid in pagination. */
  edges: Array<ClassStarsEdge>;
  /** A list of `ClassStar` objects. */
  nodes: Array<ClassStar>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassStar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassStar` edge in the connection. */
export type ClassStarsEdge = {
  __typename?: 'ClassStarsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassStar` at the end of the edge. */
  node: ClassStar;
};

/** Methods to use when ordering `ClassStar`. */
export enum ClassStarsOrderBy {
  ClassIdAsc = 'CLASS_ID_ASC',
  ClassIdDesc = 'CLASS_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `ClassDocument` object types. All fields are combined with a logical ‘and.’ */
export type ClassToManyClassDocumentFilter = {
  /** Every related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassDocumentFilter>;
  /** No related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassDocumentFilter>;
  /** Some related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassDocumentFilter>;
};

/** A filter to be used against many `ClassStar` object types. All fields are combined with a logical ‘and.’ */
export type ClassToManyClassStarFilter = {
  /** Every related `ClassStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassStarFilter>;
  /** No related `ClassStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassStarFilter>;
  /** Some related `ClassStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassStarFilter>;
};

/** A filter to be used against many `ClassUser` object types. All fields are combined with a logical ‘and.’ */
export type ClassToManyClassUserFilter = {
  /** Every related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassUserFilter>;
  /** No related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassUserFilter>;
  /** Some related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassUserFilter>;
};

/** A filter to be used against many `ClassVideo` object types. All fields are combined with a logical ‘and.’ */
export type ClassToManyClassVideoFilter = {
  /** Every related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassVideoFilter>;
  /** No related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassVideoFilter>;
  /** Some related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassVideoFilter>;
};

/** Users that are assigned to a particular class */
export type ClassUser = Node & {
  __typename?: 'ClassUser';
  /** Reads a single `Class` that is related to this `ClassUser`. */
  class: Maybe<Class>;
  classId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  lastViewedAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ClassUser`. */
  user: Maybe<User>;
  userId: Scalars['UUID'];
};

/** The `class` to be created by this mutation. */
export type ClassUserClassIdFkeyClassCreateInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** The `classUser` to be created by this mutation. */
export type ClassUserClassIdFkeyClassUserCreateInput = {
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `class` in the `ClassUserInput` mutation. */
export type ClassUserClassIdFkeyInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<ClassClassPkeyConnect>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassNodeIdConnect>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassUserClassIdFkeyClassCreateInput>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassClassPkeyDelete>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassNodeIdDelete>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<ClassOnClassUserForClassUserClassIdFkeyUsingClassPkeyUpdate>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassUserOnClassUserForClassUserClassIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classUser` in the `ClassInput` mutation. */
export type ClassUserClassIdFkeyInverseInput = {
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectByClassIdAndUserId?: InputMaybe<Array<ClassUserUniqClassUserConnect>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassUserClassUserPkeyConnect>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassUserNodeIdConnect>>;
  /** A `ClassUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassUserClassIdFkeyClassUserCreateInput>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteByClassIdAndUserId?: InputMaybe<Array<ClassUserUniqClassUserDelete>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassUserClassUserPkeyDelete>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassUserNodeIdDelete>>;
  /** Flag indicating whether all other `classUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateByClassIdAndUserId?: InputMaybe<Array<ClassUserOnClassUserForClassUserClassIdFkeyUsingUniqClassUserUpdate>>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassUserOnClassUserForClassUserClassIdFkeyUsingClassUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassOnClassUserForClassUserClassIdFkeyNodeIdUpdate>>;
};

/** The fields on `classUser` to look up the row to connect. */
export type ClassUserClassUserPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classUser` to look up the row to delete. */
export type ClassUserClassUserPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `ClassUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ClassUserCondition = {
  /** Checks for equality with the object’s `classId` field. */
  classId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ClassUser` object types. All fields are combined with a logical ‘and.’ */
export type ClassUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassUserFilter>>;
  /** Filter by the object’s `class` relation. */
  class?: InputMaybe<ClassFilter>;
  /** Filter by the object’s `classId` field. */
  classId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassUserFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ClassUser` */
export type ClassUserInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassUserOnClassUserForClassUserClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `classUser` to look up the row to update. */
export type ClassUserOnClassUserForClassUserClassIdFkeyUsingClassUserPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: UpdateClassUserOnClassUserForClassUserClassIdFkeyPatch;
};

/** The fields on `classUser` to look up the row to update. */
export type ClassUserOnClassUserForClassUserClassIdFkeyUsingUniqClassUserUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: UpdateClassUserOnClassUserForClassUserClassIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassUserOnClassUserForClassUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `classUser` to look up the row to update. */
export type ClassUserOnClassUserForClassUserUserIdFkeyUsingClassUserPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: UpdateClassUserOnClassUserForClassUserUserIdFkeyPatch;
};

/** The fields on `classUser` to look up the row to update. */
export type ClassUserOnClassUserForClassUserUserIdFkeyUsingUniqClassUserUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: UpdateClassUserOnClassUserForClassUserUserIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `ClassUser`. Fields that are set will be updated. */
export type ClassUserPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** The fields on `classUser` to look up the row to connect. */
export type ClassUserUniqClassUserConnect = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The fields on `classUser` to look up the row to delete. */
export type ClassUserUniqClassUserDelete = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The `classUser` to be created by this mutation. */
export type ClassUserUserIdFkeyClassUserCreateInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `ClassUserInput` mutation. */
export type ClassUserUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnClassUserForClassUserUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnClassUserForClassUserUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassUserOnClassUserForClassUserUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classUser` in the `UserInput` mutation. */
export type ClassUserUserIdFkeyInverseInput = {
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectByClassIdAndUserId?: InputMaybe<Array<ClassUserUniqClassUserConnect>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassUserClassUserPkeyConnect>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassUserNodeIdConnect>>;
  /** A `ClassUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassUserUserIdFkeyClassUserCreateInput>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteByClassIdAndUserId?: InputMaybe<Array<ClassUserUniqClassUserDelete>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassUserClassUserPkeyDelete>>;
  /** The primary key(s) for `classUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassUserNodeIdDelete>>;
  /** Flag indicating whether all other `classUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateByClassIdAndUserId?: InputMaybe<Array<ClassUserOnClassUserForClassUserUserIdFkeyUsingUniqClassUserUpdate>>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassUserOnClassUserForClassUserUserIdFkeyUsingClassUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `classUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnClassUserForClassUserUserIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `ClassUser` values. */
export type ClassUsersConnection = {
  __typename?: 'ClassUsersConnection';
  /** A list of edges which contains the `ClassUser` and cursor to aid in pagination. */
  edges: Array<ClassUsersEdge>;
  /** A list of `ClassUser` objects. */
  nodes: Array<ClassUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassUser` edge in the connection. */
export type ClassUsersEdge = {
  __typename?: 'ClassUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassUser` at the end of the edge. */
  node: ClassUser;
};

/** Methods to use when ordering `ClassUser`. */
export enum ClassUsersOrderBy {
  ClassIdAsc = 'CLASS_ID_ASC',
  ClassIdDesc = 'CLASS_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type ClassVideo = Node & {
  __typename?: 'ClassVideo';
  /** Reads a single `Class` that is related to this `ClassVideo`. */
  class: Maybe<Class>;
  classId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ClassVideoUser`. */
  classVideoUsers: ClassVideoUsersConnection;
  /** Reads and enables pagination through a set of `ClassVideoUser`. */
  classVideoUsersList: Array<ClassVideoUser>;
  createdAt: Scalars['Datetime'];
  durationWatched: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The date on which the video becomes available to watch for users */
  premiereAt: Maybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank: Scalars['Int'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Video` that is related to this `ClassVideo`. */
  video: Maybe<Video>;
  videoId: Scalars['UUID'];
};


export type ClassVideoClassVideoUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};


export type ClassVideoClassVideoUsersListArgs = {
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};

/** The `class` to be created by this mutation. */
export type ClassVideoClassIdFkeyClassCreateInput = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** The `classVideo` to be created by this mutation. */
export type ClassVideoClassIdFkeyClassVideoCreateInput = {
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** Input for the nested mutation of `class` in the `ClassVideoInput` mutation. */
export type ClassVideoClassIdFkeyInput = {
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectById?: InputMaybe<ClassClassPkeyConnect>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassNodeIdConnect>;
  /** A `ClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassVideoClassIdFkeyClassCreateInput>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassClassPkeyDelete>;
  /** The primary key(s) for `class` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassNodeIdDelete>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateById?: InputMaybe<ClassOnClassVideoForClassVideoClassIdFkeyUsingClassPkeyUpdate>;
  /** The primary key(s) and patch data for `class` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassVideoOnClassVideoForClassVideoClassIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classVideo` in the `ClassInput` mutation. */
export type ClassVideoClassIdFkeyInverseInput = {
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByClassIdAndVideoId?: InputMaybe<Array<ClassVideoUniqClassVideoConnect>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassVideoClassVideoPkeyConnect>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassVideoNodeIdConnect>>;
  /** A `ClassVideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassVideoClassIdFkeyClassVideoCreateInput>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByClassIdAndVideoId?: InputMaybe<Array<ClassVideoUniqClassVideoDelete>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassVideoClassVideoPkeyDelete>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassVideoNodeIdDelete>>;
  /** Flag indicating whether all other `classVideo` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByClassIdAndVideoId?: InputMaybe<Array<ClassVideoOnClassVideoForClassVideoClassIdFkeyUsingUniqClassVideoUpdate>>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassVideoOnClassVideoForClassVideoClassIdFkeyUsingClassVideoPkeyUpdate>>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassOnClassVideoForClassVideoClassIdFkeyNodeIdUpdate>>;
};

/** The fields on `classVideo` to look up the row to connect. */
export type ClassVideoClassVideoPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classVideo` to look up the row to delete. */
export type ClassVideoClassVideoPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `ClassVideo` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ClassVideoCondition = {
  /** Checks for equality with the object’s `classId` field. */
  classId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `premiereAt` field. */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Checks for equality with the object’s `rank` field. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `videoId` field. */
  videoId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ClassVideo` object types. All fields are combined with a logical ‘and.’ */
export type ClassVideoFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassVideoFilter>>;
  /** Filter by the object’s `class` relation. */
  class?: InputMaybe<ClassFilter>;
  /** Filter by the object’s `classId` field. */
  classId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `classVideoUsers` relation. */
  classVideoUsers?: InputMaybe<ClassVideoToManyClassVideoUserFilter>;
  /** Some related `classVideoUsers` exist. */
  classVideoUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `durationWatched` field. */
  durationWatched?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassVideoFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassVideoFilter>>;
  /** Filter by the object’s `premiereAt` field. */
  premiereAt?: InputMaybe<DateFilter>;
  /** Filter by the object’s `rank` field. */
  rank?: InputMaybe<IntFilter>;
  /** Filter by the object’s `video` relation. */
  video?: InputMaybe<VideoFilter>;
  /** Filter by the object’s `videoId` field. */
  videoId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ClassVideo` */
export type ClassVideoInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassVideoNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classVideo` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassVideoNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classVideo` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassVideoOnClassVideoForClassVideoClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoForClassVideoClassIdFkeyUsingClassVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoForClassVideoClassIdFkeyPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoForClassVideoClassIdFkeyUsingUniqClassVideoUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoForClassVideoClassIdFkeyPatch;
  videoId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassVideoOnClassVideoForClassVideoVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `video` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: VideoPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoForClassVideoVideoIdFkeyUsingClassVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoForClassVideoVideoIdFkeyPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoForClassVideoVideoIdFkeyUsingUniqClassVideoUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoForClassVideoVideoIdFkeyPatch;
  videoId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classVideoUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: ClassVideoUserPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingClassVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch;
};

/** The fields on `classVideo` to look up the row to update. */
export type ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingUniqClassVideoUpdate = {
  classId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: UpdateClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch;
  videoId: Scalars['UUID'];
};

/** Represents an update to a `ClassVideo`. Fields that are set will be updated. */
export type ClassVideoPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** All input for the `classVideoSetDurationWatched` mutation. */
export type ClassVideoSetDurationWatchedInput = {
  classVideoId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  durationWatched: Scalars['Int'];
};

/** The output of our `classVideoSetDurationWatched` mutation. */
export type ClassVideoSetDurationWatchedPayload = {
  __typename?: 'ClassVideoSetDurationWatchedPayload';
  /** Reads a single `ClassVideo` that is related to this `ClassVideoUser`. */
  classVideo: Maybe<ClassVideo>;
  classVideoUser: Maybe<ClassVideoUser>;
  /** An edge for our `ClassVideoUser`. May be used by Relay 1. */
  classVideoUserEdge: Maybe<ClassVideoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassVideoUser`. */
  user: Maybe<User>;
};


/** The output of our `classVideoSetDurationWatched` mutation. */
export type ClassVideoSetDurationWatchedPayloadClassVideoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};

/** A filter to be used against many `ClassVideoUser` object types. All fields are combined with a logical ‘and.’ */
export type ClassVideoToManyClassVideoUserFilter = {
  /** Every related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassVideoUserFilter>;
  /** No related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassVideoUserFilter>;
  /** Some related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassVideoUserFilter>;
};

/** The fields on `classVideo` to look up the row to connect. */
export type ClassVideoUniqClassVideoConnect = {
  classId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** The fields on `classVideo` to look up the row to delete. */
export type ClassVideoUniqClassVideoDelete = {
  classId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

export type ClassVideoUser = Node & {
  __typename?: 'ClassVideoUser';
  /** Reads a single `ClassVideo` that is related to this `ClassVideoUser`. */
  classVideo: Maybe<ClassVideo>;
  classVideoId: Scalars['UUID'];
  createdAt: Scalars['Datetime'];
  /** The duration watched in seconds */
  durationWatched: Maybe<Scalars['Int']>;
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  notifiedAt: Maybe<Scalars['Datetime']>;
  premiereAt: Maybe<Scalars['Date']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ClassVideoUser`. */
  user: Maybe<User>;
  userId: Scalars['UUID'];
};

/** The `classVideo` to be created by this mutation. */
export type ClassVideoUserClassVideoIdFkeyClassVideoCreateInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** The `classVideoUser` to be created by this mutation. */
export type ClassVideoUserClassVideoIdFkeyClassVideoUserCreateInput = {
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `classVideo` in the `ClassVideoUserInput` mutation. */
export type ClassVideoUserClassVideoIdFkeyInput = {
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByClassIdAndVideoId?: InputMaybe<ClassVideoUniqClassVideoConnect>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectById?: InputMaybe<ClassVideoClassVideoPkeyConnect>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassVideoNodeIdConnect>;
  /** A `ClassVideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassVideoUserClassVideoIdFkeyClassVideoCreateInput>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByClassIdAndVideoId?: InputMaybe<ClassVideoUniqClassVideoDelete>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassVideoClassVideoPkeyDelete>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassVideoNodeIdDelete>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByClassIdAndVideoId?: InputMaybe<ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingUniqClassVideoUpdate>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateById?: InputMaybe<ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingClassVideoPkeyUpdate>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classVideoUser` in the `ClassVideoInput` mutation. */
export type ClassVideoUserClassVideoIdFkeyInverseInput = {
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserUniqClassVideoUserConnect>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassVideoUserClassVideoUserPkeyConnect>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassVideoUserNodeIdConnect>>;
  /** A `ClassVideoUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassVideoUserClassVideoIdFkeyClassVideoUserCreateInput>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserUniqClassVideoUserDelete>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassVideoUserClassVideoUserPkeyDelete>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassVideoUserNodeIdDelete>>;
  /** Flag indicating whether all other `classVideoUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingUniqClassVideoUserUpdate>>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingClassVideoUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyNodeIdUpdate>>;
};

/** The fields on `classVideoUser` to look up the row to connect. */
export type ClassVideoUserClassVideoUserPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `classVideoUser` to look up the row to delete. */
export type ClassVideoUserClassVideoUserPkeyDelete = {
  id: Scalars['UUID'];
};

/**
 * A condition to be used against `ClassVideoUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ClassVideoUserCondition = {
  /** Checks for equality with the object’s `classVideoId` field. */
  classVideoId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ClassVideoUser` object types. All fields are combined with a logical ‘and.’ */
export type ClassVideoUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClassVideoUserFilter>>;
  /** Filter by the object’s `classVideo` relation. */
  classVideo?: InputMaybe<ClassVideoFilter>;
  /** Filter by the object’s `classVideoId` field. */
  classVideoId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClassVideoUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClassVideoUserFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ClassVideoUser` */
export type ClassVideoUserInput = {
  classVideoId?: InputMaybe<Scalars['UUID']>;
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClassVideoUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `classVideoUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ClassVideoUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `classVideoUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classVideo` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: ClassVideoPatch;
};

/** The fields on `classVideoUser` to look up the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingClassVideoUserPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: UpdateClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch;
};

/** The fields on `classVideoUser` to look up the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyUsingUniqClassVideoUserUpdate = {
  classVideoId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: UpdateClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `classVideoUser` to look up the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyUsingClassVideoUserPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: UpdateClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch;
};

/** The fields on `classVideoUser` to look up the row to update. */
export type ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUniqClassVideoUserUpdate = {
  classVideoId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: UpdateClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `ClassVideoUser`. Fields that are set will be updated. */
export type ClassVideoUserPatch = {
  classVideoId?: InputMaybe<Scalars['UUID']>;
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** The fields on `classVideoUser` to look up the row to connect. */
export type ClassVideoUserUniqClassVideoUserConnect = {
  classVideoId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The fields on `classVideoUser` to look up the row to delete. */
export type ClassVideoUserUniqClassVideoUserDelete = {
  classVideoId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The `classVideoUser` to be created by this mutation. */
export type ClassVideoUserUserIdFkeyClassVideoUserCreateInput = {
  classVideoId?: InputMaybe<Scalars['UUID']>;
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `ClassVideoUserInput` mutation. */
export type ClassVideoUserUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classVideoUser` in the `UserInput` mutation. */
export type ClassVideoUserUserIdFkeyInverseInput = {
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserUniqClassVideoUserConnect>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassVideoUserClassVideoUserPkeyConnect>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassVideoUserNodeIdConnect>>;
  /** A `ClassVideoUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassVideoUserUserIdFkeyClassVideoUserCreateInput>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserUniqClassVideoUserDelete>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassVideoUserClassVideoUserPkeyDelete>>;
  /** The primary key(s) for `classVideoUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassVideoUserNodeIdDelete>>;
  /** Flag indicating whether all other `classVideoUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateByClassVideoIdAndUserId?: InputMaybe<Array<ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUniqClassVideoUserUpdate>>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyUsingClassVideoUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `classVideoUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnClassVideoUserForClassVideoUserUserIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `ClassVideoUser` values. */
export type ClassVideoUsersConnection = {
  __typename?: 'ClassVideoUsersConnection';
  /** A list of edges which contains the `ClassVideoUser` and cursor to aid in pagination. */
  edges: Array<ClassVideoUsersEdge>;
  /** A list of `ClassVideoUser` objects. */
  nodes: Array<ClassVideoUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassVideoUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassVideoUser` edge in the connection. */
export type ClassVideoUsersEdge = {
  __typename?: 'ClassVideoUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassVideoUser` at the end of the edge. */
  node: ClassVideoUser;
};

/** Methods to use when ordering `ClassVideoUser`. */
export enum ClassVideoUsersOrderBy {
  ClassVideoIdAsc = 'CLASS_VIDEO_ID_ASC',
  ClassVideoIdDesc = 'CLASS_VIDEO_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The `classVideo` to be created by this mutation. */
export type ClassVideoVideoIdFkeyClassVideoCreateInput = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** Input for the nested mutation of `video` in the `ClassVideoInput` mutation. */
export type ClassVideoVideoIdFkeyInput = {
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectById?: InputMaybe<VideoVideoPkeyConnect>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<VideoNodeIdConnect>;
  /** A `VideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<ClassVideoVideoIdFkeyVideoCreateInput>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteById?: InputMaybe<VideoVideoPkeyDelete>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<VideoNodeIdDelete>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateById?: InputMaybe<VideoOnClassVideoForClassVideoVideoIdFkeyUsingVideoPkeyUpdate>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClassVideoOnClassVideoForClassVideoVideoIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `classVideo` in the `VideoInput` mutation. */
export type ClassVideoVideoIdFkeyInverseInput = {
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByClassIdAndVideoId?: InputMaybe<Array<ClassVideoUniqClassVideoConnect>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ClassVideoClassVideoPkeyConnect>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClassVideoNodeIdConnect>>;
  /** A `ClassVideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ClassVideoVideoIdFkeyClassVideoCreateInput>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByClassIdAndVideoId?: InputMaybe<Array<ClassVideoUniqClassVideoDelete>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ClassVideoClassVideoPkeyDelete>>;
  /** The primary key(s) for `classVideo` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ClassVideoNodeIdDelete>>;
  /** Flag indicating whether all other `classVideo` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByClassIdAndVideoId?: InputMaybe<Array<ClassVideoOnClassVideoForClassVideoVideoIdFkeyUsingUniqClassVideoUpdate>>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ClassVideoOnClassVideoForClassVideoVideoIdFkeyUsingClassVideoPkeyUpdate>>;
  /** The primary key(s) and patch data for `classVideo` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<VideoOnClassVideoForClassVideoVideoIdFkeyNodeIdUpdate>>;
};

/** The `video` to be created by this mutation. */
export type ClassVideoVideoIdFkeyVideoCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** A connection to a list of `ClassVideo` values. */
export type ClassVideosConnection = {
  __typename?: 'ClassVideosConnection';
  /** A list of edges which contains the `ClassVideo` and cursor to aid in pagination. */
  edges: Array<ClassVideosEdge>;
  /** A list of `ClassVideo` objects. */
  nodes: Array<ClassVideo>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClassVideo` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ClassVideo` edge in the connection. */
export type ClassVideosEdge = {
  __typename?: 'ClassVideosEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ClassVideo` at the end of the edge. */
  node: ClassVideo;
};

/** Methods to use when ordering `ClassVideo`. */
export enum ClassVideosOrderBy {
  ClassIdAsc = 'CLASS_ID_ASC',
  ClassIdDesc = 'CLASS_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PremiereAtAsc = 'PREMIERE_AT_ASC',
  PremiereAtDesc = 'PREMIERE_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC',
  VideoIdAsc = 'VIDEO_ID_ASC',
  VideoIdDesc = 'VIDEO_ID_DESC'
}

/** A connection to a list of `Class` values. */
export type ClassesConnection = {
  __typename?: 'ClassesConnection';
  /** A list of edges which contains the `Class` and cursor to aid in pagination. */
  edges: Array<ClassesEdge>;
  /** A list of `Class` objects. */
  nodes: Array<Class>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Class` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Class` edge in the connection. */
export type ClassesEdge = {
  __typename?: 'ClassesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Class` at the end of the edge. */
  node: Class;
};

/** Methods to use when ordering `Class`. */
export enum ClassesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FrequencyAsc = 'FREQUENCY_ASC',
  FrequencyDesc = 'FREQUENCY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsPublishedAsc = 'IS_PUBLISHED_ASC',
  IsPublishedDesc = 'IS_PUBLISHED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC'
}

export enum CommsMessageChannel {
  Email = 'EMAIL',
  InPerson = 'IN_PERSON',
  Messenger = 'MESSENGER',
  None = 'NONE',
  PhoneCall = 'PHONE_CALL',
  VirtualCall = 'VIRTUAL_CALL'
}

/** A filter to be used against CommsMessageChannel fields. All fields are combined with a logical ‘and.’ */
export type CommsMessageChannelFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CommsMessageChannel>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CommsMessageChannel>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CommsMessageChannel>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CommsMessageChannel>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CommsMessageChannel>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CommsMessageChannel>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CommsMessageChannel>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CommsMessageChannel>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CommsMessageChannel>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CommsMessageChannel>>;
};

export enum CommsMessageType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL',
  Key = 'KEY'
}

/** A filter to be used against CommsMessageType fields. All fields are combined with a logical ‘and.’ */
export type CommsMessageTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CommsMessageType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CommsMessageType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CommsMessageType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CommsMessageType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CommsMessageType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CommsMessageType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CommsMessageType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CommsMessageType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CommsMessageType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CommsMessageType>>;
};

export enum CommsQuestionType {
  Their = 'THEIR',
  Your = 'YOUR'
}

/** A filter to be used against CommsQuestionType fields. All fields are combined with a logical ‘and.’ */
export type CommsQuestionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<CommsQuestionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<CommsQuestionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<CommsQuestionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<CommsQuestionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<CommsQuestionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<CommsQuestionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<CommsQuestionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<CommsQuestionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<CommsQuestionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<CommsQuestionType>>;
};

export enum ComplianceStatus {
  Done = 'DONE',
  None = 'NONE',
  Reviewing = 'REVIEWING',
  Stuck = 'STUCK'
}

/** A filter to be used against ComplianceStatus fields. All fields are combined with a logical ‘and.’ */
export type ComplianceStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ComplianceStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ComplianceStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ComplianceStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ComplianceStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ComplianceStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ComplianceStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ComplianceStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ComplianceStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ComplianceStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ComplianceStatus>>;
};

export type Connection = Node & {
  __typename?: 'Connection';
  /** Reads a single `Account` that is related to this `Connection`. */
  account: Maybe<Account>;
  /** Related Account ID */
  accountId: Scalars['UUID'];
  /** Avatar image URL */
  avatarUrl: Maybe<Scalars['String']>;
  /** Company name */
  companyName: Maybe<Scalars['String']>;
  /** Connection type */
  connectionType: ConnectionType;
  /** When was this project connection created? */
  createdAt: Scalars['Datetime'];
  email: Maybe<Scalars['Email']>;
  /** Connection ID */
  id: Scalars['UUID'];
  /** Is this project connection deleted? */
  isDeleted: Scalars['Boolean'];
  /** LinkedIn URL */
  linkedinUrl: Maybe<Scalars['String']>;
  /** Full name of the person */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ProjectConnection`. */
  projectConnections: ProjectConnectionsConnection;
  /** Reads and enables pagination through a set of `ProjectConnection`. */
  projectConnectionsList: Array<ProjectConnection>;
  /** Title of the person */
  title: Maybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType: ConnectionTkiType;
  /** When was this project connection last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Connection`. */
  user: Maybe<User>;
  /** Related User ID */
  userId: Maybe<Scalars['UUID']>;
};


export type ConnectionProjectConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};


export type ConnectionProjectConnectionsListArgs = {
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};

/** The `account` to be created by this mutation. */
export type ConnectionAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** The `connection` to be created by this mutation. */
export type ConnectionAccountIdFkeyConnectionCreateInput = {
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType: ConnectionType;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** Input for the nested mutation of `account` in the `ConnectionInput` mutation. */
export type ConnectionAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<ConnectionAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnConnectionForConnectionAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnConnectionForConnectionAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ConnectionOnConnectionForConnectionAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `connection` in the `AccountInput` mutation. */
export type ConnectionAccountIdFkeyInverseInput = {
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ConnectionConnectionPkeyConnect>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ConnectionNodeIdConnect>>;
  /** A `ConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ConnectionAccountIdFkeyConnectionCreateInput>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ConnectionConnectionPkeyDelete>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ConnectionNodeIdDelete>>;
  /** Flag indicating whether all other `connection` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ConnectionOnConnectionForConnectionAccountIdFkeyUsingConnectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnConnectionForConnectionAccountIdFkeyNodeIdUpdate>>;
};

/**
 * A condition to be used against `Connection` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ConnectionCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `connectionType` field. */
  connectionType?: InputMaybe<ConnectionType>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The fields on `connection` to look up the row to connect. */
export type ConnectionConnectionPkeyConnect = {
  /** Connection ID */
  id: Scalars['UUID'];
};

/** The fields on `connection` to look up the row to delete. */
export type ConnectionConnectionPkeyDelete = {
  /** Connection ID */
  id: Scalars['UUID'];
};

export enum ConnectionDecisionMaker {
  Bull = 'BULL',
  Fox = 'FOX',
  Mouse = 'MOUSE',
  None = 'NONE',
  Owl = 'OWL'
}

/** A filter to be used against `Connection` object types. All fields are combined with a logical ‘and.’ */
export type ConnectionFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ConnectionFilter>>;
  /** Filter by the object’s `connectionType` field. */
  connectionType?: InputMaybe<ConnectionTypeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<EmailFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ConnectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ConnectionFilter>>;
  /** Filter by the object’s `projectConnections` relation. */
  projectConnections?: InputMaybe<ConnectionToManyProjectConnectionFilter>;
  /** Some related `projectConnections` exist. */
  projectConnectionsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ConnectionForm` */
export type ConnectionFormInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  avatarUrl?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  connectionType?: InputMaybe<ConnectionType>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  linkedinUrl?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  tkiType?: InputMaybe<ConnectionTkiType>;
};

/** An input for mutations affecting `Connection` */
export type ConnectionInput = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType: ConnectionType;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

export enum ConnectionLinkType {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY'
}

/** A filter to be used against ConnectionLinkType fields. All fields are combined with a logical ‘and.’ */
export type ConnectionLinkTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ConnectionLinkType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ConnectionLinkType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ConnectionLinkType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ConnectionLinkType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ConnectionLinkType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ConnectionLinkType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ConnectionLinkType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ConnectionLinkType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ConnectionLinkType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ConnectionLinkType>>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ConnectionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `connection` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ConnectionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `connection` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ConnectionOnConnectionForConnectionAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `connection` to look up the row to update. */
export type ConnectionOnConnectionForConnectionAccountIdFkeyUsingConnectionPkeyUpdate = {
  /** Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: UpdateConnectionOnConnectionForConnectionAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ConnectionOnConnectionForConnectionUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `connection` to look up the row to update. */
export type ConnectionOnConnectionForConnectionUserIdFkeyUsingConnectionPkeyUpdate = {
  /** Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: UpdateConnectionOnConnectionForConnectionUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `connection` to look up the row to update. */
export type ConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingConnectionPkeyUpdate = {
  /** Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: UpdateConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyPatch;
};

/** Represents an update to a `Connection`. Fields that are set will be updated. */
export type ConnectionPatch = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType?: InputMaybe<ConnectionType>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

export enum ConnectionRole {
  Ancillary = 'ANCILLARY',
  Escalation = 'ESCALATION',
  Negotiation = 'NEGOTIATION',
  None = 'NONE'
}

export enum ConnectionTkiType {
  Accommodating = 'ACCOMMODATING',
  Avoiding = 'AVOIDING',
  Collaborating = 'COLLABORATING',
  Competing = 'COMPETING',
  Compromising = 'COMPROMISING',
  None = 'NONE'
}

/** A filter to be used against many `ProjectConnection` object types. All fields are combined with a logical ‘and.’ */
export type ConnectionToManyProjectConnectionFilter = {
  /** Every related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectConnectionFilter>;
  /** No related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectConnectionFilter>;
  /** Some related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectConnectionFilter>;
};

export enum ConnectionTrustStatus {
  Green = 'GREEN',
  Na = 'NA',
  None = 'NONE',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export enum ConnectionType {
  External = 'EXTERNAL',
  Internal = 'INTERNAL'
}

/** A filter to be used against ConnectionType fields. All fields are combined with a logical ‘and.’ */
export type ConnectionTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ConnectionType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ConnectionType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ConnectionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ConnectionType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ConnectionType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ConnectionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ConnectionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ConnectionType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ConnectionType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ConnectionType>>;
};

/** The `connection` to be created by this mutation. */
export type ConnectionUserIdFkeyConnectionCreateInput = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType: ConnectionType;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `ConnectionInput` mutation. */
export type ConnectionUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnConnectionForConnectionUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnConnectionForConnectionUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ConnectionOnConnectionForConnectionUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `connection` in the `UserInput` mutation. */
export type ConnectionUserIdFkeyInverseInput = {
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ConnectionConnectionPkeyConnect>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ConnectionNodeIdConnect>>;
  /** A `ConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ConnectionUserIdFkeyConnectionCreateInput>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ConnectionConnectionPkeyDelete>>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ConnectionNodeIdDelete>>;
  /** Flag indicating whether all other `connection` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ConnectionOnConnectionForConnectionUserIdFkeyUsingConnectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnConnectionForConnectionUserIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `Connection` values. */
export type ConnectionsConnection = {
  __typename?: 'ConnectionsConnection';
  /** A list of edges which contains the `Connection` and cursor to aid in pagination. */
  edges: Array<ConnectionsEdge>;
  /** A list of `Connection` objects. */
  nodes: Array<Connection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Connection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Connection` edge in the connection. */
export type ConnectionsEdge = {
  __typename?: 'ConnectionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Connection` at the end of the edge. */
  node: Connection;
};

/** Methods to use when ordering `Connection`. */
export enum ConnectionsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ConnectionTypeAsc = 'CONNECTION_TYPE_ASC',
  ConnectionTypeDesc = 'CONNECTION_TYPE_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Which consultant has access to which organizations */
export type ConsultantOrganizationAccess = Node & {
  __typename?: 'ConsultantOrganizationAccess';
  /** Reads a single `Account` that is related to this `ConsultantOrganizationAccess`. */
  account: Maybe<Account>;
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `ConsultantOrganizationAccess`. */
  user: Maybe<User>;
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** The `account` to be created by this mutation. */
export type ConsultantOrganizationAccessAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** The `consultantOrganizationAccess` to be created by this mutation. */
export type ConsultantOrganizationAccessAccountIdFkeyConsultantOrganizationAccessCreateInput = {
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  /** User ID - which consultant it is */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** Input for the nested mutation of `account` in the `ConsultantOrganizationAccessInput` mutation. */
export type ConsultantOrganizationAccessAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `consultantOrganizationAccess` in the `AccountInput` mutation. */
export type ConsultantOrganizationAccessAccountIdFkeyInverseInput = {
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ConsultantOrganizationAccessNodeIdConnect>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  connectByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessConsultantOrganizationAccessPkeyConnect>>;
  /** A `ConsultantOrganizationAccessInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ConsultantOrganizationAccessAccountIdFkeyConsultantOrganizationAccessCreateInput>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ConsultantOrganizationAccessNodeIdDelete>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  deleteByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessConsultantOrganizationAccessPkeyDelete>>;
  /** Flag indicating whether all other `consultantOrganizationAccess` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `consultantOrganizationAccess` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `consultantOrganizationAccess` for the far side of the relationship. */
  updateByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingConsultantOrganizationAccessPkeyUpdate>>;
};

/**
 * A condition to be used against `ConsultantOrganizationAccess` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ConsultantOrganizationAccessCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The fields on `consultantOrganizationAccess` to look up the row to connect. */
export type ConsultantOrganizationAccessConsultantOrganizationAccessPkeyConnect = {
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** The fields on `consultantOrganizationAccess` to look up the row to delete. */
export type ConsultantOrganizationAccessConsultantOrganizationAccessPkeyDelete = {
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** A filter to be used against `ConsultantOrganizationAccess` object types. All fields are combined with a logical ‘and.’ */
export type ConsultantOrganizationAccessFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ConsultantOrganizationAccessFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ConsultantOrganizationAccessFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ConsultantOrganizationAccessFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ConsultantOrganizationAccess` */
export type ConsultantOrganizationAccessInput = {
  /** Account ID - which organization they have access to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  /** User ID - which consultant it is */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ConsultantOrganizationAccessNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `consultantOrganizationAccess` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ConsultantOrganizationAccessNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `consultantOrganizationAccess` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `consultantOrganizationAccess` to look up the row to update. */
export type ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyUsingConsultantOrganizationAccessPkeyUpdate = {
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
  patch: UpdateConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyPatch;
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `consultantOrganizationAccess` to look up the row to update. */
export type ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingConsultantOrganizationAccessPkeyUpdate = {
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
  patch: UpdateConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyPatch;
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** Represents an update to a `ConsultantOrganizationAccess`. Fields that are set will be updated. */
export type ConsultantOrganizationAccessPatch = {
  /** Account ID - which organization they have access to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  /** User ID - which consultant it is */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** The `consultantOrganizationAccess` to be created by this mutation. */
export type ConsultantOrganizationAccessUserIdFkeyConsultantOrganizationAccessCreateInput = {
  /** Account ID - which organization they have access to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `ConsultantOrganizationAccessInput` mutation. */
export type ConsultantOrganizationAccessUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `consultantOrganizationAccess` in the `UserInput` mutation. */
export type ConsultantOrganizationAccessUserIdFkeyInverseInput = {
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ConsultantOrganizationAccessNodeIdConnect>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  connectByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessConsultantOrganizationAccessPkeyConnect>>;
  /** A `ConsultantOrganizationAccessInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ConsultantOrganizationAccessUserIdFkeyConsultantOrganizationAccessCreateInput>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ConsultantOrganizationAccessNodeIdDelete>>;
  /** The primary key(s) for `consultantOrganizationAccess` for the far side of the relationship. */
  deleteByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessConsultantOrganizationAccessPkeyDelete>>;
  /** Flag indicating whether all other `consultantOrganizationAccess` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `consultantOrganizationAccess` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `consultantOrganizationAccess` for the far side of the relationship. */
  updateByUserIdAndAccountId?: InputMaybe<Array<ConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingConsultantOrganizationAccessPkeyUpdate>>;
};

/** A connection to a list of `ConsultantOrganizationAccess` values. */
export type ConsultantOrganizationAccessesConnection = {
  __typename?: 'ConsultantOrganizationAccessesConnection';
  /** A list of edges which contains the `ConsultantOrganizationAccess` and cursor to aid in pagination. */
  edges: Array<ConsultantOrganizationAccessesEdge>;
  /** A list of `ConsultantOrganizationAccess` objects. */
  nodes: Array<ConsultantOrganizationAccess>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ConsultantOrganizationAccess` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ConsultantOrganizationAccess` edge in the connection. */
export type ConsultantOrganizationAccessesEdge = {
  __typename?: 'ConsultantOrganizationAccessesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ConsultantOrganizationAccess` at the end of the edge. */
  node: ConsultantOrganizationAccess;
};

/** Methods to use when ordering `ConsultantOrganizationAccess`. */
export enum ConsultantOrganizationAccessesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type CountryRegion = Node & {
  __typename?: 'CountryRegion';
  id: Scalars['UUID'];
  /** The name of the Country/Region */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/**
 * A condition to be used against `CountryRegion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CountryRegionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `CountryRegion` object types. All fields are combined with a logical ‘and.’ */
export type CountryRegionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CountryRegionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CountryRegionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CountryRegionFilter>>;
};

/** An input for mutations affecting `CountryRegion` */
export type CountryRegionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the Country/Region */
  name?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `CountryRegion`. Fields that are set will be updated. */
export type CountryRegionPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the Country/Region */
  name?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `CountryRegion` values. */
export type CountryRegionsConnection = {
  __typename?: 'CountryRegionsConnection';
  /** A list of edges which contains the `CountryRegion` and cursor to aid in pagination. */
  edges: Array<CountryRegionsEdge>;
  /** A list of `CountryRegion` objects. */
  nodes: Array<CountryRegion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CountryRegion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CountryRegion` edge in the connection. */
export type CountryRegionsEdge = {
  __typename?: 'CountryRegionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `CountryRegion` at the end of the edge. */
  node: CountryRegion;
};

/** Methods to use when ordering `CountryRegion`. */
export enum CountryRegionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the create `Account` mutation. */
export type CreateAccountInput = {
  /** The `Account` to be created by this mutation. */
  account: AccountInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Account` mutation. */
export type CreateAccountPayload = {
  __typename?: 'CreateAccountPayload';
  /** The `Account` that was created by this mutation. */
  account: Maybe<Account>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge: Maybe<AccountsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Account` mutation. */
export type CreateAccountPayloadAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** All input for the create `Activity` mutation. */
export type CreateActivityInput = {
  /** The `Activity` to be created by this mutation. */
  activity: ActivityInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the create `ActivityLevel` mutation. */
export type CreateActivityLevelInput = {
  /** The `ActivityLevel` to be created by this mutation. */
  activityLevel: ActivityLevelInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ActivityLevel` mutation. */
export type CreateActivityLevelPayload = {
  __typename?: 'CreateActivityLevelPayload';
  /** Reads a single `Activity` that is related to this `ActivityLevel`. */
  activity: Maybe<Activity>;
  /** The `ActivityLevel` that was created by this mutation. */
  activityLevel: Maybe<ActivityLevel>;
  /** An edge for our `ActivityLevel`. May be used by Relay 1. */
  activityLevelEdge: Maybe<ActivityLevelsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ActivityLevel` mutation. */
export type CreateActivityLevelPayloadActivityLevelEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivityLevelsOrderBy>>;
};

/** The output of our create `Activity` mutation. */
export type CreateActivityPayload = {
  __typename?: 'CreateActivityPayload';
  /** The `Activity` that was created by this mutation. */
  activity: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge: Maybe<ActivitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Activity` mutation. */
export type CreateActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the create `BriefGroup` mutation. */
export type CreateBriefGroupInput = {
  /** The `BriefGroup` to be created by this mutation. */
  briefGroup: BriefGroupInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BriefGroup` mutation. */
export type CreateBriefGroupPayload = {
  __typename?: 'CreateBriefGroupPayload';
  /** Reads a single `Activity` that is related to this `BriefGroup`. */
  activity: Maybe<Activity>;
  /** The `BriefGroup` that was created by this mutation. */
  briefGroup: Maybe<BriefGroup>;
  /** An edge for our `BriefGroup`. May be used by Relay 1. */
  briefGroupEdge: Maybe<BriefGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `UserLanguage` that is related to this `BriefGroup`. */
  userLanguageByLanguageCode: Maybe<UserLanguage>;
};


/** The output of our create `BriefGroup` mutation. */
export type CreateBriefGroupPayloadBriefGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};

/** All input for the create `BriefGroupTypeMapping` mutation. */
export type CreateBriefGroupTypeMappingInput = {
  /** The `BriefGroupTypeMapping` to be created by this mutation. */
  briefGroupTypeMapping: BriefGroupTypeMappingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BriefGroupTypeMapping` mutation. */
export type CreateBriefGroupTypeMappingPayload = {
  __typename?: 'CreateBriefGroupTypeMappingPayload';
  /** The `BriefGroupTypeMapping` that was created by this mutation. */
  briefGroupTypeMapping: Maybe<BriefGroupTypeMapping>;
  /** An edge for our `BriefGroupTypeMapping`. May be used by Relay 1. */
  briefGroupTypeMappingEdge: Maybe<BriefGroupTypeMappingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `BriefGroupTypeMapping` mutation. */
export type CreateBriefGroupTypeMappingPayloadBriefGroupTypeMappingEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupTypeMappingsOrderBy>>;
};

/** All input for the create `BriefGroupVariable` mutation. */
export type CreateBriefGroupVariableInput = {
  /** The `BriefGroupVariable` to be created by this mutation. */
  briefGroupVariable: BriefGroupVariableInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** All input for the `createBriefGroupVariableOptions` mutation. */
export type CreateBriefGroupVariableOptionsInput = {
  briefGroupVariableId: Scalars['Int'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  options: Array<InputMaybe<BriefGroupVariableOptionInputRecordInput>>;
};

/** The output of our `createBriefGroupVariableOptions` mutation. */
export type CreateBriefGroupVariableOptionsPayload = {
  __typename?: 'CreateBriefGroupVariableOptionsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  variableOptions: Maybe<Array<Maybe<Scalars['Int']>>>;
};

/** The output of our create `BriefGroupVariable` mutation. */
export type CreateBriefGroupVariablePayload = {
  __typename?: 'CreateBriefGroupVariablePayload';
  /** Reads a single `BriefGroup` that is related to this `BriefGroupVariable`. */
  briefGroup: Maybe<BriefGroup>;
  /** The `BriefGroupVariable` that was created by this mutation. */
  briefGroupVariable: Maybe<BriefGroupVariable>;
  /** An edge for our `BriefGroupVariable`. May be used by Relay 1. */
  briefGroupVariableEdge: Maybe<BriefGroupVariablesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `BriefGroupVariable` mutation. */
export type CreateBriefGroupVariablePayloadBriefGroupVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};

/** All input for the create `Brief` mutation. */
export type CreateBriefInput = {
  /** The `Brief` to be created by this mutation. */
  brief: BriefInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Brief` mutation. */
export type CreateBriefPayload = {
  __typename?: 'CreateBriefPayload';
  /** The `Brief` that was created by this mutation. */
  brief: Maybe<Brief>;
  /** An edge for our `Brief`. May be used by Relay 1. */
  briefEdge: Maybe<BriefsEdge>;
  /** Reads a single `BriefGroup` that is related to this `Brief`. */
  briefGroup: Maybe<BriefGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Brief` mutation. */
export type CreateBriefPayloadBriefEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};

/** All input for the create `BriefPreset` mutation. */
export type CreateBriefPresetInput = {
  /** The `BriefPreset` to be created by this mutation. */
  briefPreset: BriefPresetInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `BriefPreset` mutation. */
export type CreateBriefPresetPayload = {
  __typename?: 'CreateBriefPresetPayload';
  /** The `BriefPreset` that was created by this mutation. */
  briefPreset: Maybe<BriefPreset>;
  /** An edge for our `BriefPreset`. May be used by Relay 1. */
  briefPresetEdge: Maybe<BriefPresetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkshopPreset` that is related to this `BriefPreset`. */
  workshopPreset: Maybe<WorkshopPreset>;
};


/** The output of our create `BriefPreset` mutation. */
export type CreateBriefPresetPayloadBriefPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};

/** All input for the create `ClassAssetCategory` mutation. */
export type CreateClassAssetCategoryInput = {
  /** The `ClassAssetCategory` to be created by this mutation. */
  classAssetCategory: ClassAssetCategoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassAssetCategory` mutation. */
export type CreateClassAssetCategoryPayload = {
  __typename?: 'CreateClassAssetCategoryPayload';
  /** The `ClassAssetCategory` that was created by this mutation. */
  classAssetCategory: Maybe<ClassAssetCategory>;
  /** An edge for our `ClassAssetCategory`. May be used by Relay 1. */
  classAssetCategoryEdge: Maybe<ClassAssetCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ClassAssetCategory` mutation. */
export type CreateClassAssetCategoryPayloadClassAssetCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassAssetCategoriesOrderBy>>;
};

/** All input for the create `ClassDocument` mutation. */
export type CreateClassDocumentInput = {
  /** The `ClassDocument` to be created by this mutation. */
  classDocument: ClassDocumentInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassDocument` mutation. */
export type CreateClassDocumentPayload = {
  __typename?: 'CreateClassDocumentPayload';
  /** Reads a single `Class` that is related to this `ClassDocument`. */
  class: Maybe<Class>;
  /** The `ClassDocument` that was created by this mutation. */
  classDocument: Maybe<ClassDocument>;
  /** An edge for our `ClassDocument`. May be used by Relay 1. */
  classDocumentEdge: Maybe<ClassDocumentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Document` that is related to this `ClassDocument`. */
  document: Maybe<Document>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ClassDocument` mutation. */
export type CreateClassDocumentPayloadClassDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};

/** All input for the create `Class` mutation. */
export type CreateClassInput = {
  /** The `Class` to be created by this mutation. */
  class: ClassInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `Class` mutation. */
export type CreateClassPayload = {
  __typename?: 'CreateClassPayload';
  /** Reads a single `Account` that is related to this `Class`. */
  account: Maybe<Account>;
  /** The `Class` that was created by this mutation. */
  class: Maybe<Class>;
  /** An edge for our `Class`. May be used by Relay 1. */
  classEdge: Maybe<ClassesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Class`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our create `Class` mutation. */
export type CreateClassPayloadClassEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};

/** All input for the create `ClassStar` mutation. */
export type CreateClassStarInput = {
  /** The `ClassStar` to be created by this mutation. */
  classStar: ClassStarInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassStar` mutation. */
export type CreateClassStarPayload = {
  __typename?: 'CreateClassStarPayload';
  /** Reads a single `Class` that is related to this `ClassStar`. */
  class: Maybe<Class>;
  /** The `ClassStar` that was created by this mutation. */
  classStar: Maybe<ClassStar>;
  /** An edge for our `ClassStar`. May be used by Relay 1. */
  classStarEdge: Maybe<ClassStarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ClassStar` mutation. */
export type CreateClassStarPayloadClassStarEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};

/** All input for the create `ClassUser` mutation. */
export type CreateClassUserInput = {
  /** The `ClassUser` to be created by this mutation. */
  classUser: ClassUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassUser` mutation. */
export type CreateClassUserPayload = {
  __typename?: 'CreateClassUserPayload';
  /** Reads a single `Class` that is related to this `ClassUser`. */
  class: Maybe<Class>;
  /** The `ClassUser` that was created by this mutation. */
  classUser: Maybe<ClassUser>;
  /** An edge for our `ClassUser`. May be used by Relay 1. */
  classUserEdge: Maybe<ClassUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassUser`. */
  user: Maybe<User>;
};


/** The output of our create `ClassUser` mutation. */
export type CreateClassUserPayloadClassUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};

/** All input for the create `ClassVideo` mutation. */
export type CreateClassVideoInput = {
  /** The `ClassVideo` to be created by this mutation. */
  classVideo: ClassVideoInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassVideo` mutation. */
export type CreateClassVideoPayload = {
  __typename?: 'CreateClassVideoPayload';
  /** Reads a single `Class` that is related to this `ClassVideo`. */
  class: Maybe<Class>;
  /** The `ClassVideo` that was created by this mutation. */
  classVideo: Maybe<ClassVideo>;
  /** An edge for our `ClassVideo`. May be used by Relay 1. */
  classVideoEdge: Maybe<ClassVideosEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `ClassVideo`. */
  video: Maybe<Video>;
};


/** The output of our create `ClassVideo` mutation. */
export type CreateClassVideoPayloadClassVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};

/** All input for the create `ClassVideoUser` mutation. */
export type CreateClassVideoUserInput = {
  /** The `ClassVideoUser` to be created by this mutation. */
  classVideoUser: ClassVideoUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our create `ClassVideoUser` mutation. */
export type CreateClassVideoUserPayload = {
  __typename?: 'CreateClassVideoUserPayload';
  /** Reads a single `ClassVideo` that is related to this `ClassVideoUser`. */
  classVideo: Maybe<ClassVideo>;
  /** The `ClassVideoUser` that was created by this mutation. */
  classVideoUser: Maybe<ClassVideoUser>;
  /** An edge for our `ClassVideoUser`. May be used by Relay 1. */
  classVideoUserEdge: Maybe<ClassVideoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassVideoUser`. */
  user: Maybe<User>;
};


/** The output of our create `ClassVideoUser` mutation. */
export type CreateClassVideoUserPayloadClassVideoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};

/** All input for the create `Connection` mutation. */
export type CreateConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Connection` to be created by this mutation. */
  connection: ConnectionInput;
};

/** The output of our create `Connection` mutation. */
export type CreateConnectionPayload = {
  __typename?: 'CreateConnectionPayload';
  /** Reads a single `Account` that is related to this `Connection`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Connection` that was created by this mutation. */
  connection: Maybe<Connection>;
  /** An edge for our `Connection`. May be used by Relay 1. */
  connectionEdge: Maybe<ConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Connection`. */
  user: Maybe<User>;
};


/** The output of our create `Connection` mutation. */
export type CreateConnectionPayloadConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};

/** All input for the create `ConsultantOrganizationAccess` mutation. */
export type CreateConsultantOrganizationAccessInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ConsultantOrganizationAccess` to be created by this mutation. */
  consultantOrganizationAccess: ConsultantOrganizationAccessInput;
};

/** The output of our create `ConsultantOrganizationAccess` mutation. */
export type CreateConsultantOrganizationAccessPayload = {
  __typename?: 'CreateConsultantOrganizationAccessPayload';
  /** Reads a single `Account` that is related to this `ConsultantOrganizationAccess`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `ConsultantOrganizationAccess` that was created by this mutation. */
  consultantOrganizationAccess: Maybe<ConsultantOrganizationAccess>;
  /** An edge for our `ConsultantOrganizationAccess`. May be used by Relay 1. */
  consultantOrganizationAccessEdge: Maybe<ConsultantOrganizationAccessesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ConsultantOrganizationAccess`. */
  user: Maybe<User>;
};


/** The output of our create `ConsultantOrganizationAccess` mutation. */
export type CreateConsultantOrganizationAccessPayloadConsultantOrganizationAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};

/** All input for the create `CountryRegion` mutation. */
export type CreateCountryRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `CountryRegion` to be created by this mutation. */
  countryRegion: CountryRegionInput;
};

/** The output of our create `CountryRegion` mutation. */
export type CreateCountryRegionPayload = {
  __typename?: 'CreateCountryRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `CountryRegion` that was created by this mutation. */
  countryRegion: Maybe<CountryRegion>;
  /** An edge for our `CountryRegion`. May be used by Relay 1. */
  countryRegionEdge: Maybe<CountryRegionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `CountryRegion` mutation. */
export type CreateCountryRegionPayloadCountryRegionEdgeArgs = {
  orderBy?: InputMaybe<Array<CountryRegionsOrderBy>>;
};

/** All input for the create `Customer` mutation. */
export type CreateCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Customer` to be created by this mutation. */
  customer: CustomerInput;
};

/** The output of our create `Customer` mutation. */
export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  /** Reads a single `Account` that is related to this `Customer`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Customer` that was created by this mutation. */
  customer: Maybe<Customer>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge: Maybe<CustomersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Customer` mutation. */
export type CreateCustomerPayloadCustomerEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};

/** All input for the create `Division` mutation. */
export type CreateDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Division` to be created by this mutation. */
  division: DivisionInput;
};

/** The output of our create `Division` mutation. */
export type CreateDivisionPayload = {
  __typename?: 'CreateDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Division` that was created by this mutation. */
  division: Maybe<Division>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge: Maybe<DivisionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Division` mutation. */
export type CreateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

/** All input for the create `Document` mutation. */
export type CreateDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Document` to be created by this mutation. */
  document: DocumentInput;
};

/** The output of our create `Document` mutation. */
export type CreateDocumentPayload = {
  __typename?: 'CreateDocumentPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Document`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Document` that was created by this mutation. */
  document: Maybe<Document>;
  /** An edge for our `Document`. May be used by Relay 1. */
  documentEdge: Maybe<DocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Document`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our create `Document` mutation. */
export type CreateDocumentPayloadDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};

/** All input for the create `DocumentStar` mutation. */
export type CreateDocumentStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `DocumentStar` to be created by this mutation. */
  documentStar: DocumentStarInput;
};

/** The output of our create `DocumentStar` mutation. */
export type CreateDocumentStarPayload = {
  __typename?: 'CreateDocumentStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Document` that is related to this `DocumentStar`. */
  document: Maybe<Document>;
  /** The `DocumentStar` that was created by this mutation. */
  documentStar: Maybe<DocumentStar>;
  /** An edge for our `DocumentStar`. May be used by Relay 1. */
  documentStarEdge: Maybe<DocumentStarsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `DocumentStar` mutation. */
export type CreateDocumentStarPayloadDocumentStarEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};

/** All input for the create `EventType` mutation. */
export type CreateEventTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `EventType` to be created by this mutation. */
  eventType: EventTypeInput;
};

/** The output of our create `EventType` mutation. */
export type CreateEventTypePayload = {
  __typename?: 'CreateEventTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `EventType` that was created by this mutation. */
  eventType: Maybe<EventType>;
  /** An edge for our `EventType`. May be used by Relay 1. */
  eventTypeEdge: Maybe<EventTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `EventType` mutation. */
export type CreateEventTypePayloadEventTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};

/** All input for the create `FeedbackQuestion` mutation. */
export type CreateFeedbackQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `FeedbackQuestion` to be created by this mutation. */
  feedbackQuestion: FeedbackQuestionInput;
};

/** The output of our create `FeedbackQuestion` mutation. */
export type CreateFeedbackQuestionPayload = {
  __typename?: 'CreateFeedbackQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `FeedbackQuestion` that was created by this mutation. */
  feedbackQuestion: Maybe<FeedbackQuestion>;
  /** An edge for our `FeedbackQuestion`. May be used by Relay 1. */
  feedbackQuestionEdge: Maybe<FeedbackQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `FeedbackQuestion` mutation. */
export type CreateFeedbackQuestionPayloadFeedbackQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbackQuestionsOrderBy>>;
};

/** All input for the create `ForgottenEmailSubmission` mutation. */
export type CreateForgottenEmailSubmissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ForgottenEmailSubmission` to be created by this mutation. */
  forgottenEmailSubmission: ForgottenEmailSubmissionInput;
};

/** The output of our create `ForgottenEmailSubmission` mutation. */
export type CreateForgottenEmailSubmissionPayload = {
  __typename?: 'CreateForgottenEmailSubmissionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `ForgottenEmailSubmission` that was created by this mutation. */
  forgottenEmailSubmission: Maybe<ForgottenEmailSubmission>;
  /** An edge for our `ForgottenEmailSubmission`. May be used by Relay 1. */
  forgottenEmailSubmissionEdge: Maybe<ForgottenEmailSubmissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ForgottenEmailSubmission` mutation. */
export type CreateForgottenEmailSubmissionPayloadForgottenEmailSubmissionEdgeArgs = {
  orderBy?: InputMaybe<Array<ForgottenEmailSubmissionsOrderBy>>;
};

/** All input for the create `GoogleAccountUser` mutation. */
export type CreateGoogleAccountUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GoogleAccountUser` to be created by this mutation. */
  googleAccountUser: GoogleAccountUserInput;
};

/** The output of our create `GoogleAccountUser` mutation. */
export type CreateGoogleAccountUserPayload = {
  __typename?: 'CreateGoogleAccountUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `GoogleAccountUser` that was created by this mutation. */
  googleAccountUser: Maybe<GoogleAccountUser>;
  /** An edge for our `GoogleAccountUser`. May be used by Relay 1. */
  googleAccountUserEdge: Maybe<GoogleAccountUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleAccountUser`. */
  user: Maybe<User>;
};


/** The output of our create `GoogleAccountUser` mutation. */
export type CreateGoogleAccountUserPayloadGoogleAccountUserEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};

/** All input for the create `GoogleCalendarEvent` mutation. */
export type CreateGoogleCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `GoogleCalendarEvent` to be created by this mutation. */
  googleCalendarEvent: GoogleCalendarEventInput;
};

/** The output of our create `GoogleCalendarEvent` mutation. */
export type CreateGoogleCalendarEventPayload = {
  __typename?: 'CreateGoogleCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectEvent` that is related to this `GoogleCalendarEvent`. */
  event: Maybe<ProjectEvent>;
  /** The `GoogleCalendarEvent` that was created by this mutation. */
  googleCalendarEvent: Maybe<GoogleCalendarEvent>;
  /** An edge for our `GoogleCalendarEvent`. May be used by Relay 1. */
  googleCalendarEventEdge: Maybe<GoogleCalendarEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleCalendarEvent`. */
  user: Maybe<User>;
};


/** The output of our create `GoogleCalendarEvent` mutation. */
export type CreateGoogleCalendarEventPayloadGoogleCalendarEventEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};

/** All input for the create `Industry` mutation. */
export type CreateIndustryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Industry` to be created by this mutation. */
  industry: IndustryInput;
};

/** The output of our create `Industry` mutation. */
export type CreateIndustryPayload = {
  __typename?: 'CreateIndustryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Industry` that was created by this mutation. */
  industry: Maybe<Industry>;
  /** An edge for our `Industry`. May be used by Relay 1. */
  industryEdge: Maybe<IndustriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `Industry` mutation. */
export type CreateIndustryPayloadIndustryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndustriesOrderBy>>;
};

/** All input for the create `Lv1GameScore` mutation. */
export type CreateLv1GameScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Lv1GameScore` to be created by this mutation. */
  lv1GameScore: Lv1GameScoreInput;
};

/** The output of our create `Lv1GameScore` mutation. */
export type CreateLv1GameScorePayload = {
  __typename?: 'CreateLv1GameScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Lv1GameScore` that was created by this mutation. */
  lv1GameScore: Maybe<Lv1GameScore>;
  /** An edge for our `Lv1GameScore`. May be used by Relay 1. */
  lv1GameScoreEdge: Maybe<Lv1GameScoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameScore`. */
  user: Maybe<User>;
};


/** The output of our create `Lv1GameScore` mutation. */
export type CreateLv1GameScorePayloadLv1GameScoreEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameScoresOrderBy>>;
};

/** All input for the create `Lv1GameSubgroup` mutation. */
export type CreateLv1GameSubgroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Lv1GameSubgroup` to be created by this mutation. */
  lv1GameSubgroup: Lv1GameSubgroupInput;
};

/** The output of our create `Lv1GameSubgroup` mutation. */
export type CreateLv1GameSubgroupPayload = {
  __typename?: 'CreateLv1GameSubgroupPayload';
  /** Reads a single `Account` that is related to this `Lv1GameSubgroup`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Lv1GameSubgroup` that was created by this mutation. */
  lv1GameSubgroup: Maybe<Lv1GameSubgroup>;
  /** An edge for our `Lv1GameSubgroup`. May be used by Relay 1. */
  lv1GameSubgroupEdge: Maybe<Lv1GameSubgroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroup`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our create `Lv1GameSubgroup` mutation. */
export type CreateLv1GameSubgroupPayloadLv1GameSubgroupEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};

/** All input for the create `Lv1GameSubgroupUser` mutation. */
export type CreateLv1GameSubgroupUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Lv1GameSubgroupUser` to be created by this mutation. */
  lv1GameSubgroupUser: Lv1GameSubgroupUserInput;
};

/** The output of our create `Lv1GameSubgroupUser` mutation. */
export type CreateLv1GameSubgroupUserPayload = {
  __typename?: 'CreateLv1GameSubgroupUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Lv1GameSubgroup` that is related to this `Lv1GameSubgroupUser`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** The `Lv1GameSubgroupUser` that was created by this mutation. */
  lv1GameSubgroupUser: Maybe<Lv1GameSubgroupUser>;
  /** An edge for our `Lv1GameSubgroupUser`. May be used by Relay 1. */
  lv1GameSubgroupUserEdge: Maybe<Lv1GameSubgroupUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroupUser`. */
  user: Maybe<User>;
};


/** The output of our create `Lv1GameSubgroupUser` mutation. */
export type CreateLv1GameSubgroupUserPayloadLv1GameSubgroupUserEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};

/** All input for the create `MassUserUpload` mutation. */
export type CreateMassUserUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `MassUserUpload` to be created by this mutation. */
  massUserUpload: MassUserUploadInput;
};

/** The output of our create `MassUserUpload` mutation. */
export type CreateMassUserUploadPayload = {
  __typename?: 'CreateMassUserUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `MassUserUpload` that was created by this mutation. */
  massUserUpload: Maybe<MassUserUpload>;
  /** An edge for our `MassUserUpload`. May be used by Relay 1. */
  massUserUploadEdge: Maybe<MassUserUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `MassUserUpload`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our create `MassUserUpload` mutation. */
export type CreateMassUserUploadPayloadMassUserUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};

/** All input for the create `ProjectComment` mutation. */
export type CreateProjectCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectComment` to be created by this mutation. */
  projectComment: ProjectCommentInput;
};

/** The output of our create `ProjectComment` mutation. */
export type CreateProjectCommentPayload = {
  __typename?: 'CreateProjectCommentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectComment` that is related to this `ProjectComment`. */
  parent: Maybe<ProjectComment>;
  /** Reads a single `Project` that is related to this `ProjectComment`. */
  project: Maybe<Project>;
  /** The `ProjectComment` that was created by this mutation. */
  projectComment: Maybe<ProjectComment>;
  /** An edge for our `ProjectComment`. May be used by Relay 1. */
  projectCommentEdge: Maybe<ProjectCommentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectComment`. */
  user: Maybe<User>;
};


/** The output of our create `ProjectComment` mutation. */
export type CreateProjectCommentPayloadProjectCommentEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};

/** All input for the create `ProjectCommunicationMessage` mutation. */
export type CreateProjectCommunicationMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectCommunicationMessage` to be created by this mutation. */
  projectCommunicationMessage: ProjectCommunicationMessageInput;
};

/** The output of our create `ProjectCommunicationMessage` mutation. */
export type CreateProjectCommunicationMessagePayload = {
  __typename?: 'CreateProjectCommunicationMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCommunicationMessage`. */
  project: Maybe<Project>;
  /** The `ProjectCommunicationMessage` that was created by this mutation. */
  projectCommunicationMessage: Maybe<ProjectCommunicationMessage>;
  /** An edge for our `ProjectCommunicationMessage`. May be used by Relay 1. */
  projectCommunicationMessageEdge: Maybe<ProjectCommunicationMessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  recipient: Maybe<ProjectConnection>;
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  sender: Maybe<ProjectConnection>;
};


/** The output of our create `ProjectCommunicationMessage` mutation. */
export type CreateProjectCommunicationMessagePayloadProjectCommunicationMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};

/** All input for the create `ProjectCommunicationQuestion` mutation. */
export type CreateProjectCommunicationQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectCommunicationQuestion` to be created by this mutation. */
  projectCommunicationQuestion: ProjectCommunicationQuestionInput;
};

/** The output of our create `ProjectCommunicationQuestion` mutation. */
export type CreateProjectCommunicationQuestionPayload = {
  __typename?: 'CreateProjectCommunicationQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCommunicationQuestion`. */
  project: Maybe<Project>;
  /** The `ProjectCommunicationQuestion` that was created by this mutation. */
  projectCommunicationQuestion: Maybe<ProjectCommunicationQuestion>;
  /** An edge for our `ProjectCommunicationQuestion`. May be used by Relay 1. */
  projectCommunicationQuestionEdge: Maybe<ProjectCommunicationQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectCommunicationQuestion` mutation. */
export type CreateProjectCommunicationQuestionPayloadProjectCommunicationQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};

/** All input for the create `ProjectCompliance` mutation. */
export type CreateProjectComplianceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectCompliance` to be created by this mutation. */
  projectCompliance: ProjectComplianceInput;
};

/** The output of our create `ProjectCompliance` mutation. */
export type CreateProjectCompliancePayload = {
  __typename?: 'CreateProjectCompliancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCompliance`. */
  project: Maybe<Project>;
  /** The `ProjectCompliance` that was created by this mutation. */
  projectCompliance: Maybe<ProjectCompliance>;
  /** An edge for our `ProjectCompliance`. May be used by Relay 1. */
  projectComplianceEdge: Maybe<ProjectCompliancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectCompliance` mutation. */
export type CreateProjectCompliancePayloadProjectComplianceEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};

/** All input for the create `ProjectConnection` mutation. */
export type CreateProjectConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectConnection` to be created by this mutation. */
  projectConnection: ProjectConnectionInput;
};

/** All input for the `createProjectConnectionLinkShort` mutation. */
export type CreateProjectConnectionLinkShortInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  extProjectConnectionId: Scalars['UUID'];
  links: Array<InputMaybe<ProjectConnectionLinkConnectionInput>>;
};

/** The output of our `createProjectConnectionLinkShort` mutation. */
export type CreateProjectConnectionLinkShortPayload = {
  __typename?: 'CreateProjectConnectionLinkShortPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  projectConnectionLinks: Maybe<Array<ProjectConnectionLink>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The output of our create `ProjectConnection` mutation. */
export type CreateProjectConnectionPayload = {
  __typename?: 'CreateProjectConnectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Connection` that is related to this `ProjectConnection`. */
  connection: Maybe<Connection>;
  /** Reads a single `Project` that is related to this `ProjectConnection`. */
  project: Maybe<Project>;
  /** The `ProjectConnection` that was created by this mutation. */
  projectConnection: Maybe<ProjectConnection>;
  /** An edge for our `ProjectConnection`. May be used by Relay 1. */
  projectConnectionEdge: Maybe<ProjectConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectConnection` mutation. */
export type CreateProjectConnectionPayloadProjectConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};

/** All input for the create `ProjectEvent` mutation. */
export type CreateProjectEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectEvent` to be created by this mutation. */
  projectEvent: ProjectEventInput;
};

/** The output of our create `ProjectEvent` mutation. */
export type CreateProjectEventPayload = {
  __typename?: 'CreateProjectEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `EventType` that is related to this `ProjectEvent`. */
  eventTypeByEventType: Maybe<EventType>;
  /** Reads a single `Project` that is related to this `ProjectEvent`. */
  project: Maybe<Project>;
  /** The `ProjectEvent` that was created by this mutation. */
  projectEvent: Maybe<ProjectEvent>;
  /** An edge for our `ProjectEvent`. May be used by Relay 1. */
  projectEventEdge: Maybe<ProjectEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectEvent` mutation. */
export type CreateProjectEventPayloadProjectEventEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};

/** All input for the create `Project` mutation. */
export type CreateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Project` to be created by this mutation. */
  project: ProjectInput;
};

/** All input for the create `ProjectOffer` mutation. */
export type CreateProjectOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectOffer` to be created by this mutation. */
  projectOffer: ProjectOfferInput;
};

/** The output of our create `ProjectOffer` mutation. */
export type CreateProjectOfferPayload = {
  __typename?: 'CreateProjectOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectOffer`. */
  project: Maybe<Project>;
  /** The `ProjectOffer` that was created by this mutation. */
  projectOffer: Maybe<ProjectOffer>;
  /** An edge for our `ProjectOffer`. May be used by Relay 1. */
  projectOfferEdge: Maybe<ProjectOffersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectOffer` mutation. */
export type CreateProjectOfferPayloadProjectOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};

/** All input for the create `ProjectOfferVariable` mutation. */
export type CreateProjectOfferVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectOfferVariable` to be created by this mutation. */
  projectOfferVariable: ProjectOfferVariableInput;
};

/** The output of our create `ProjectOfferVariable` mutation. */
export type CreateProjectOfferVariablePayload = {
  __typename?: 'CreateProjectOfferVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectOffer` that is related to this `ProjectOfferVariable`. */
  projectOffer: Maybe<ProjectOffer>;
  /** The `ProjectOfferVariable` that was created by this mutation. */
  projectOfferVariable: Maybe<ProjectOfferVariable>;
  /** An edge for our `ProjectOfferVariable`. May be used by Relay 1. */
  projectOfferVariableEdge: Maybe<ProjectOfferVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectVariable` that is related to this `ProjectOfferVariable`. */
  variable: Maybe<ProjectVariable>;
};


/** The output of our create `ProjectOfferVariable` mutation. */
export type CreateProjectOfferVariablePayloadProjectOfferVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};

/** The output of our create `Project` mutation. */
export type CreateProjectPayload = {
  __typename?: 'CreateProjectPayload';
  /** Reads a single `Account` that is related to this `Project`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Customer` that is related to this `Project`. */
  customer: Maybe<Customer>;
  /** Reads a single `Project` that is related to this `Project`. */
  parent: Maybe<Project>;
  /** The `Project` that was created by this mutation. */
  project: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge: Maybe<ProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Project`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our create `Project` mutation. */
export type CreateProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the create `ProjectReminder` mutation. */
export type CreateProjectReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectReminder` to be created by this mutation. */
  projectReminder: ProjectReminderInput;
};

/** The output of our create `ProjectReminder` mutation. */
export type CreateProjectReminderPayload = {
  __typename?: 'CreateProjectReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `ProjectReminder` that was created by this mutation. */
  projectReminder: Maybe<ProjectReminder>;
  /** An edge for our `ProjectReminder`. May be used by Relay 1. */
  projectReminderEdge: Maybe<ProjectRemindersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectReminder` mutation. */
export type CreateProjectReminderPayloadProjectReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectRemindersOrderBy>>;
};

/** All input for the create `ProjectRisk` mutation. */
export type CreateProjectRiskInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectRisk` to be created by this mutation. */
  projectRisk: ProjectRiskInput;
};

/** The output of our create `ProjectRisk` mutation. */
export type CreateProjectRiskPayload = {
  __typename?: 'CreateProjectRiskPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectRisk`. */
  project: Maybe<Project>;
  /** The `ProjectRisk` that was created by this mutation. */
  projectRisk: Maybe<ProjectRisk>;
  /** An edge for our `ProjectRisk`. May be used by Relay 1. */
  projectRiskEdge: Maybe<ProjectRisksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectRisk` mutation. */
export type CreateProjectRiskPayloadProjectRiskEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};

/** All input for the create `ProjectScenario` mutation. */
export type CreateProjectScenarioInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectScenario` to be created by this mutation. */
  projectScenario: ProjectScenarioInput;
};

/** The output of our create `ProjectScenario` mutation. */
export type CreateProjectScenarioPayload = {
  __typename?: 'CreateProjectScenarioPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectScenario`. */
  project: Maybe<Project>;
  /** The `ProjectScenario` that was created by this mutation. */
  projectScenario: Maybe<ProjectScenario>;
  /** An edge for our `ProjectScenario`. May be used by Relay 1. */
  projectScenarioEdge: Maybe<ProjectScenariosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our create `ProjectScenario` mutation. */
export type CreateProjectScenarioPayloadProjectScenarioEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};

/** All input for the create `ProjectScenarioVariable` mutation. */
export type CreateProjectScenarioVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectScenarioVariable` to be created by this mutation. */
  projectScenarioVariable: ProjectScenarioVariableInput;
};

/** The output of our create `ProjectScenarioVariable` mutation. */
export type CreateProjectScenarioVariablePayload = {
  __typename?: 'CreateProjectScenarioVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectScenario` that is related to this `ProjectScenarioVariable`. */
  projectScenario: Maybe<ProjectScenario>;
  /** The `ProjectScenarioVariable` that was created by this mutation. */
  projectScenarioVariable: Maybe<ProjectScenarioVariable>;
  /** An edge for our `ProjectScenarioVariable`. May be used by Relay 1. */
  projectScenarioVariableEdge: Maybe<ProjectScenarioVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectVariable` that is related to this `ProjectScenarioVariable`. */
  variable: Maybe<ProjectVariable>;
};


/** The output of our create `ProjectScenarioVariable` mutation. */
export type CreateProjectScenarioVariablePayloadProjectScenarioVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};

/** All input for the create `ProjectVariable` mutation. */
export type CreateProjectVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `ProjectVariable` to be created by this mutation. */
  projectVariable: ProjectVariableInput;
};

/** The output of our create `ProjectVariable` mutation. */
export type CreateProjectVariablePayload = {
  __typename?: 'CreateProjectVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectVariable`. */
  project: Maybe<Project>;
  /** The `ProjectVariable` that was created by this mutation. */
  projectVariable: Maybe<ProjectVariable>;
  /** An edge for our `ProjectVariable`. May be used by Relay 1. */
  projectVariableEdge: Maybe<ProjectVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `VariableClass` that is related to this `ProjectVariable`. */
  variableClass: Maybe<VariableClass>;
};


/** The output of our create `ProjectVariable` mutation. */
export type CreateProjectVariablePayloadProjectVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};

/** All input for the create `SurveyBucket` mutation. */
export type CreateSurveyBucketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SurveyBucket` to be created by this mutation. */
  surveyBucket: SurveyBucketInput;
};

/** The output of our create `SurveyBucket` mutation. */
export type CreateSurveyBucketPayload = {
  __typename?: 'CreateSurveyBucketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyBucket` that was created by this mutation. */
  surveyBucket: Maybe<SurveyBucket>;
  /** An edge for our `SurveyBucket`. May be used by Relay 1. */
  surveyBucketEdge: Maybe<SurveyBucketsEdge>;
};


/** The output of our create `SurveyBucket` mutation. */
export type CreateSurveyBucketPayloadSurveyBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyBucketsOrderBy>>;
};

/** All input for the create `SurveyQuestionAnswer` mutation. */
export type CreateSurveyQuestionAnswerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SurveyQuestionAnswer` to be created by this mutation. */
  surveyQuestionAnswer: SurveyQuestionAnswerInput;
};

/** The output of our create `SurveyQuestionAnswer` mutation. */
export type CreateSurveyQuestionAnswerPayload = {
  __typename?: 'CreateSurveyQuestionAnswerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestionAnswer` that was created by this mutation. */
  surveyQuestionAnswer: Maybe<SurveyQuestionAnswer>;
  /** An edge for our `SurveyQuestionAnswer`. May be used by Relay 1. */
  surveyQuestionAnswerEdge: Maybe<SurveyQuestionAnswersEdge>;
};


/** The output of our create `SurveyQuestionAnswer` mutation. */
export type CreateSurveyQuestionAnswerPayloadSurveyQuestionAnswerEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionAnswersOrderBy>>;
};

/** All input for the create `SurveyQuestion` mutation. */
export type CreateSurveyQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SurveyQuestion` to be created by this mutation. */
  surveyQuestion: SurveyQuestionInput;
};

/** The output of our create `SurveyQuestion` mutation. */
export type CreateSurveyQuestionPayload = {
  __typename?: 'CreateSurveyQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestion` that was created by this mutation. */
  surveyQuestion: Maybe<SurveyQuestion>;
  /** An edge for our `SurveyQuestion`. May be used by Relay 1. */
  surveyQuestionEdge: Maybe<SurveyQuestionsEdge>;
};


/** The output of our create `SurveyQuestion` mutation. */
export type CreateSurveyQuestionPayloadSurveyQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionsOrderBy>>;
};

/** All input for the create `SurveyReminder` mutation. */
export type CreateSurveyReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SurveyReminder` to be created by this mutation. */
  surveyReminder: SurveyReminderInput;
};

/** The output of our create `SurveyReminder` mutation. */
export type CreateSurveyReminderPayload = {
  __typename?: 'CreateSurveyReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyReminder` that was created by this mutation. */
  surveyReminder: Maybe<SurveyReminder>;
  /** An edge for our `SurveyReminder`. May be used by Relay 1. */
  surveyReminderEdge: Maybe<SurveyRemindersEdge>;
};


/** The output of our create `SurveyReminder` mutation. */
export type CreateSurveyReminderPayloadSurveyReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyRemindersOrderBy>>;
};

/** All input for the create `SurveyResponse` mutation. */
export type CreateSurveyResponseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `SurveyResponse` to be created by this mutation. */
  surveyResponse: SurveyResponseInput;
};

/** The output of our create `SurveyResponse` mutation. */
export type CreateSurveyResponsePayload = {
  __typename?: 'CreateSurveyResponsePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyResponse` that was created by this mutation. */
  surveyResponse: Maybe<SurveyResponse>;
  /** An edge for our `SurveyResponse`. May be used by Relay 1. */
  surveyResponseEdge: Maybe<SurveyResponsesEdge>;
  /** Reads a single `User` that is related to this `SurveyResponse`. */
  user: Maybe<User>;
};


/** The output of our create `SurveyResponse` mutation. */
export type CreateSurveyResponsePayloadSurveyResponseEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};

/** All input for the `createUser` mutation. */
export type CreateUserInput = {
  accountId: Scalars['UUID'];
  adminOf?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  divisionId?: InputMaybe<Scalars['UUID']>;
  email: Scalars['String'];
  fullname: Scalars['String'];
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  gender?: InputMaybe<Gender>;
  isLms: Scalars['Boolean'];
  isLv1: Scalars['Boolean'];
  isNpt: Scalars['Boolean'];
  languageCode?: InputMaybe<Scalars['String']>;
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  password: Scalars['String'];
  phoneCell: Scalars['String'];
  phoneWork: Scalars['String'];
  role: Scalars['String'];
  title: Scalars['String'];
  v1RoleId: Scalars['Int'];
  workshopFeedbackReportsPermission: Scalars['Boolean'];
};

/** All input for the create `UserLanguage` mutation. */
export type CreateUserLanguageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserLanguage` to be created by this mutation. */
  userLanguage: UserLanguageInput;
};

/** The output of our create `UserLanguage` mutation. */
export type CreateUserLanguagePayload = {
  __typename?: 'CreateUserLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `UserLanguage` that was created by this mutation. */
  userLanguage: Maybe<UserLanguage>;
  /** An edge for our `UserLanguage`. May be used by Relay 1. */
  userLanguageEdge: Maybe<UserLanguagesEdge>;
};


/** The output of our create `UserLanguage` mutation. */
export type CreateUserLanguagePayloadUserLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLanguagesOrderBy>>;
};

/** The output of our `createUser` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** Reads a single `Account` that is related to this `User`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Lv1GameSubgroup` that is related to this `User`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  user: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};


/** The output of our `createUser` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserProfileQuestionnaire` mutation. */
export type CreateUserProfileQuestionnaireInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `UserProfileQuestionnaire` to be created by this mutation. */
  userProfileQuestionnaire: UserProfileQuestionnaireInput;
};

/** The output of our create `UserProfileQuestionnaire` mutation. */
export type CreateUserProfileQuestionnairePayload = {
  __typename?: 'CreateUserProfileQuestionnairePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfileQuestionnaire`. */
  user: Maybe<User>;
  /** The `UserProfileQuestionnaire` that was created by this mutation. */
  userProfileQuestionnaire: Maybe<UserProfileQuestionnaire>;
  /** An edge for our `UserProfileQuestionnaire`. May be used by Relay 1. */
  userProfileQuestionnaireEdge: Maybe<UserProfileQuestionnairesEdge>;
};


/** The output of our create `UserProfileQuestionnaire` mutation. */
export type CreateUserProfileQuestionnairePayloadUserProfileQuestionnaireEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfileQuestionnairesOrderBy>>;
};

/** All input for the create `V1Role` mutation. */
export type CreateV1RoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `V1Role` to be created by this mutation. */
  v1Role: V1RoleInput;
};

/** The output of our create `V1Role` mutation. */
export type CreateV1RolePayload = {
  __typename?: 'CreateV1RolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `V1Role` that was created by this mutation. */
  v1Role: Maybe<V1Role>;
  /** An edge for our `V1Role`. May be used by Relay 1. */
  v1RoleEdge: Maybe<V1RolesEdge>;
};


/** The output of our create `V1Role` mutation. */
export type CreateV1RolePayloadV1RoleEdgeArgs = {
  orderBy?: InputMaybe<Array<V1RolesOrderBy>>;
};

/** All input for the create `VariableClass` mutation. */
export type CreateVariableClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VariableClass` to be created by this mutation. */
  variableClass: VariableClassInput;
};

/** The output of our create `VariableClass` mutation. */
export type CreateVariableClassPayload = {
  __typename?: 'CreateVariableClassPayload';
  /** Reads a single `Account` that is related to this `VariableClass`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Project` that is related to this `VariableClass`. */
  relatedProject: Maybe<Project>;
  /** Reads a single `User` that is related to this `VariableClass`. */
  userByCreatedBy: Maybe<User>;
  /** The `VariableClass` that was created by this mutation. */
  variableClass: Maybe<VariableClass>;
  /** An edge for our `VariableClass`. May be used by Relay 1. */
  variableClassEdge: Maybe<VariableClassesEdge>;
};


/** The output of our create `VariableClass` mutation. */
export type CreateVariableClassPayloadVariableClassEdgeArgs = {
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};

/** All input for the create `Video` mutation. */
export type CreateVideoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Video` to be created by this mutation. */
  video: VideoInput;
};

/** The output of our create `Video` mutation. */
export type CreateVideoPayload = {
  __typename?: 'CreateVideoPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Video`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Video`. */
  userByCreatedBy: Maybe<User>;
  /** The `Video` that was created by this mutation. */
  video: Maybe<Video>;
  /** An edge for our `Video`. May be used by Relay 1. */
  videoEdge: Maybe<VideosEdge>;
};


/** The output of our create `Video` mutation. */
export type CreateVideoPayloadVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};

/** All input for the create `VideoStar` mutation. */
export type CreateVideoStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `VideoStar` to be created by this mutation. */
  videoStar: VideoStarInput;
};

/** The output of our create `VideoStar` mutation. */
export type CreateVideoStarPayload = {
  __typename?: 'CreateVideoStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `VideoStar`. */
  video: Maybe<Video>;
  /** The `VideoStar` that was created by this mutation. */
  videoStar: Maybe<VideoStar>;
  /** An edge for our `VideoStar`. May be used by Relay 1. */
  videoStarEdge: Maybe<VideoStarsEdge>;
};


/** The output of our create `VideoStar` mutation. */
export type CreateVideoStarPayloadVideoStarEdgeArgs = {
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};

/** All input for the create `Workshop` mutation. */
export type CreateWorkshopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `Workshop` to be created by this mutation. */
  workshop: WorkshopInput;
};

/** The output of our create `Workshop` mutation. */
export type CreateWorkshopPayload = {
  __typename?: 'CreateWorkshopPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `WorkshopPreset` that is related to this `Workshop`. */
  preset: Maybe<WorkshopPreset>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Workshop`. */
  userByCreatedBy: Maybe<User>;
  /** The `Workshop` that was created by this mutation. */
  workshop: Maybe<Workshop>;
  /** An edge for our `Workshop`. May be used by Relay 1. */
  workshopEdge: Maybe<WorkshopsEdge>;
};


/** The output of our create `Workshop` mutation. */
export type CreateWorkshopPayloadWorkshopEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

/** All input for the create `WorkshopPreset` mutation. */
export type CreateWorkshopPresetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The `WorkshopPreset` to be created by this mutation. */
  workshopPreset: WorkshopPresetInput;
};

/** The output of our create `WorkshopPreset` mutation. */
export type CreateWorkshopPresetPayload = {
  __typename?: 'CreateWorkshopPresetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkshopPreset` that was created by this mutation. */
  workshopPreset: Maybe<WorkshopPreset>;
  /** An edge for our `WorkshopPreset`. May be used by Relay 1. */
  workshopPresetEdge: Maybe<WorkshopPresetsEdge>;
};


/** The output of our create `WorkshopPreset` mutation. */
export type CreateWorkshopPresetPayloadWorkshopPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopPresetsOrderBy>>;
};

export type CurrentProjectOfferVariable = {
  __typename?: 'CurrentProjectOfferVariable';
  amount: Maybe<Scalars['BigFloat']>;
  isAligned: Maybe<Scalars['Boolean']>;
  ourSelected: Maybe<Scalars['String']>;
  theirSelected: Maybe<Scalars['String']>;
  /** Reads a single `ProjectVariable` that is related to this `CurrentProjectOfferVariable`. */
  variable: Maybe<ProjectVariable>;
  variableId: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `CurrentProjectOfferVariable` object types. All fields are combined with a logical ‘and.’ */
export type CurrentProjectOfferVariableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CurrentProjectOfferVariableFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<CurrentProjectOfferVariableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CurrentProjectOfferVariableFilter>>;
  /** Filter by the object’s `variable` relation. */
  variable?: InputMaybe<ProjectVariableFilter>;
  /** A related `variable` exists. */
  variableExists?: InputMaybe<Scalars['Boolean']>;
};

/** A connection to a list of `CurrentProjectOfferVariable` values. */
export type CurrentProjectOfferVariablesConnection = {
  __typename?: 'CurrentProjectOfferVariablesConnection';
  /** A list of edges which contains the `CurrentProjectOfferVariable` and cursor to aid in pagination. */
  edges: Array<CurrentProjectOfferVariablesEdge>;
  /** A list of `CurrentProjectOfferVariable` objects. */
  nodes: Array<CurrentProjectOfferVariable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CurrentProjectOfferVariable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CurrentProjectOfferVariable` edge in the connection. */
export type CurrentProjectOfferVariablesEdge = {
  __typename?: 'CurrentProjectOfferVariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `CurrentProjectOfferVariable` at the end of the edge. */
  node: CurrentProjectOfferVariable;
};

/** A `UUID` edge in the connection. */
export type CurrentUserInvitedProjectIdEdge = {
  __typename?: 'CurrentUserInvitedProjectIdEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `UUID` at the end of the edge. */
  node: Maybe<Scalars['UUID']>;
};

/** A connection to a list of `UUID` values. */
export type CurrentUserInvitedProjectIdsConnection = {
  __typename?: 'CurrentUserInvitedProjectIdsConnection';
  /** A list of edges which contains the `UUID` and cursor to aid in pagination. */
  edges: Array<CurrentUserInvitedProjectIdEdge>;
  /** A list of `UUID` objects. */
  nodes: Array<Maybe<Scalars['UUID']>>;
  /** The count of *all* `UUID` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** Customers - the clients of NPT clients (accounts) */
export type Customer = Node & {
  __typename?: 'Customer';
  /** Reads a single `Account` that is related to this `Customer`. */
  account: Maybe<Account>;
  /** The account this customer belongs to */
  accountId: Scalars['UUID'];
  /** When was this customer created? */
  createdAt: Scalars['Datetime'];
  /** Unique ID for this customer */
  id: Scalars['UUID'];
  /** Name of the customer */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `Project`. */
  projects: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsList: Array<Project>;
  /** When was this customer last updated? */
  updatedAt: Scalars['Datetime'];
};


/** Customers - the clients of NPT clients (accounts) */
export type CustomerProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Customers - the clients of NPT clients (accounts) */
export type CustomerProjectsListArgs = {
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** The `account` to be created by this mutation. */
export type CustomerAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** The `customer` to be created by this mutation. */
export type CustomerAccountIdFkeyCustomerCreateInput = {
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `account` in the `CustomerInput` mutation. */
export type CustomerAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<CustomerAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnCustomerForCustomerAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnCustomerForCustomerAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<CustomerOnCustomerForCustomerAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `customer` in the `AccountInput` mutation. */
export type CustomerAccountIdFkeyInverseInput = {
  /** The primary key(s) for `customer` for the far side of the relationship. */
  connectById?: InputMaybe<Array<CustomerCustomerPkeyConnect>>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<CustomerNodeIdConnect>>;
  /** A `CustomerInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<CustomerAccountIdFkeyCustomerCreateInput>>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<CustomerCustomerPkeyDelete>>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<CustomerNodeIdDelete>>;
  /** Flag indicating whether all other `customer` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `customer` for the far side of the relationship. */
  updateById?: InputMaybe<Array<CustomerOnCustomerForCustomerAccountIdFkeyUsingCustomerPkeyUpdate>>;
  /** The primary key(s) and patch data for `customer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnCustomerForCustomerAccountIdFkeyNodeIdUpdate>>;
};

/**
 * A condition to be used against `Customer` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type CustomerCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** The fields on `customer` to look up the row to connect. */
export type CustomerCustomerPkeyConnect = {
  /** Unique ID for this customer */
  id: Scalars['UUID'];
};

/** The fields on `customer` to look up the row to delete. */
export type CustomerCustomerPkeyDelete = {
  /** Unique ID for this customer */
  id: Scalars['UUID'];
};

/** A filter to be used against `Customer` object types. All fields are combined with a logical ‘and.’ */
export type CustomerFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<CustomerFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<CustomerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<CustomerFilter>>;
  /** Filter by the object’s `projects` relation. */
  projects?: InputMaybe<CustomerToManyProjectFilter>;
  /** Some related `projects` exist. */
  projectsExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `Customer` */
export type CustomerInput = {
  /** The account this customer belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type CustomerNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `customer` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type CustomerNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `customer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type CustomerOnCustomerForCustomerAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `customer` to look up the row to update. */
export type CustomerOnCustomerForCustomerAccountIdFkeyUsingCustomerPkeyUpdate = {
  /** Unique ID for this customer */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `customer` being updated. */
  patch: UpdateCustomerOnCustomerForCustomerAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type CustomerOnProjectForProjectCustomerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `customer` to look up the row to update. */
export type CustomerOnProjectForProjectCustomerIdFkeyUsingCustomerPkeyUpdate = {
  /** Unique ID for this customer */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `customer` being updated. */
  patch: UpdateCustomerOnProjectForProjectCustomerIdFkeyPatch;
};

/** Represents an update to a `Customer`. Fields that are set will be updated. */
export type CustomerPatch = {
  /** The account this customer belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type CustomerToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A connection to a list of `Customer` values. */
export type CustomersConnection = {
  __typename?: 'CustomersConnection';
  /** A list of edges which contains the `Customer` and cursor to aid in pagination. */
  edges: Array<CustomersEdge>;
  /** A list of `Customer` objects. */
  nodes: Array<Customer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Customer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Customer` edge in the connection. */
export type CustomersEdge = {
  __typename?: 'CustomersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Customer` at the end of the edge. */
  node: Customer;
};

/** Methods to use when ordering `Customer`. */
export enum CustomersOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Date']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Date']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Date']>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']>>;
};

/** All input for the `deleteAccountByName` mutation. */
export type DeleteAccountByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Name of the account */
  name: Scalars['String'];
};

/** All input for the `deleteAccountByNodeId` mutation. */
export type DeleteAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Account` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteAccount` mutation. */
export type DeleteAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for this account */
  id: Scalars['UUID'];
};

/** The output of our delete `Account` mutation. */
export type DeleteAccountPayload = {
  __typename?: 'DeleteAccountPayload';
  /** The `Account` that was deleted by this mutation. */
  account: Maybe<Account>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge: Maybe<AccountsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedAccountNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Account` mutation. */
export type DeleteAccountPayloadAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** All input for the `deleteActivityByNodeId` mutation. */
export type DeleteActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Activity` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteActivity` mutation. */
export type DeleteActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the activity */
  id: Scalars['Int'];
};

/** The output of our delete `Activity` mutation. */
export type DeleteActivityPayload = {
  __typename?: 'DeleteActivityPayload';
  /** The `Activity` that was deleted by this mutation. */
  activity: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge: Maybe<ActivitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedActivityNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Activity` mutation. */
export type DeleteActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the `deleteBriefByNodeId` mutation. */
export type DeleteBriefByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Brief` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBriefGroupByNodeId` mutation. */
export type DeleteBriefGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBriefGroup` mutation. */
export type DeleteBriefGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief group */
  id: Scalars['Int'];
};

/** The output of our delete `BriefGroup` mutation. */
export type DeleteBriefGroupPayload = {
  __typename?: 'DeleteBriefGroupPayload';
  /** Reads a single `Activity` that is related to this `BriefGroup`. */
  activity: Maybe<Activity>;
  /** The `BriefGroup` that was deleted by this mutation. */
  briefGroup: Maybe<BriefGroup>;
  /** An edge for our `BriefGroup`. May be used by Relay 1. */
  briefGroupEdge: Maybe<BriefGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedBriefGroupNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `UserLanguage` that is related to this `BriefGroup`. */
  userLanguageByLanguageCode: Maybe<UserLanguage>;
};


/** The output of our delete `BriefGroup` mutation. */
export type DeleteBriefGroupPayloadBriefGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};

/** All input for the `deleteBriefGroupTypeMappingByNodeId` mutation. */
export type DeleteBriefGroupTypeMappingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroupTypeMapping` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBriefGroupTypeMapping` mutation. */
export type DeleteBriefGroupTypeMappingInput = {
  /** Related Brief Group Type */
  briefGroupType: BriefGroupType;
  /** Related Brief Variant */
  briefVariant: BriefVariant;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our delete `BriefGroupTypeMapping` mutation. */
export type DeleteBriefGroupTypeMappingPayload = {
  __typename?: 'DeleteBriefGroupTypeMappingPayload';
  /** The `BriefGroupTypeMapping` that was deleted by this mutation. */
  briefGroupTypeMapping: Maybe<BriefGroupTypeMapping>;
  /** An edge for our `BriefGroupTypeMapping`. May be used by Relay 1. */
  briefGroupTypeMappingEdge: Maybe<BriefGroupTypeMappingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedBriefGroupTypeMappingNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `BriefGroupTypeMapping` mutation. */
export type DeleteBriefGroupTypeMappingPayloadBriefGroupTypeMappingEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupTypeMappingsOrderBy>>;
};

/** All input for the `deleteBriefGroupVariableByNodeId` mutation. */
export type DeleteBriefGroupVariableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroupVariable` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBriefGroupVariable` mutation. */
export type DeleteBriefGroupVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
};

/** The output of our delete `BriefGroupVariable` mutation. */
export type DeleteBriefGroupVariablePayload = {
  __typename?: 'DeleteBriefGroupVariablePayload';
  /** Reads a single `BriefGroup` that is related to this `BriefGroupVariable`. */
  briefGroup: Maybe<BriefGroup>;
  /** The `BriefGroupVariable` that was deleted by this mutation. */
  briefGroupVariable: Maybe<BriefGroupVariable>;
  /** An edge for our `BriefGroupVariable`. May be used by Relay 1. */
  briefGroupVariableEdge: Maybe<BriefGroupVariablesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedBriefGroupVariableNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `BriefGroupVariable` mutation. */
export type DeleteBriefGroupVariablePayloadBriefGroupVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};

/** All input for the `deleteBrief` mutation. */
export type DeleteBriefInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/** The output of our delete `Brief` mutation. */
export type DeleteBriefPayload = {
  __typename?: 'DeleteBriefPayload';
  /** The `Brief` that was deleted by this mutation. */
  brief: Maybe<Brief>;
  /** An edge for our `Brief`. May be used by Relay 1. */
  briefEdge: Maybe<BriefsEdge>;
  /** Reads a single `BriefGroup` that is related to this `Brief`. */
  briefGroup: Maybe<BriefGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedBriefNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Brief` mutation. */
export type DeleteBriefPayloadBriefEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};

/** All input for the `deleteBriefPresetByNodeId` mutation. */
export type DeleteBriefPresetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefPreset` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteBriefPreset` mutation. */
export type DeleteBriefPresetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
};

/** The output of our delete `BriefPreset` mutation. */
export type DeleteBriefPresetPayload = {
  __typename?: 'DeleteBriefPresetPayload';
  /** The `BriefPreset` that was deleted by this mutation. */
  briefPreset: Maybe<BriefPreset>;
  /** An edge for our `BriefPreset`. May be used by Relay 1. */
  briefPresetEdge: Maybe<BriefPresetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedBriefPresetNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkshopPreset` that is related to this `BriefPreset`. */
  workshopPreset: Maybe<WorkshopPreset>;
};


/** The output of our delete `BriefPreset` mutation. */
export type DeleteBriefPresetPayloadBriefPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};

/** All input for the `deleteClassAssetCategoryByNodeId` mutation. */
export type DeleteClassAssetCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassAssetCategory` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassAssetCategory` mutation. */
export type DeleteClassAssetCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassAssetCategory` mutation. */
export type DeleteClassAssetCategoryPayload = {
  __typename?: 'DeleteClassAssetCategoryPayload';
  /** The `ClassAssetCategory` that was deleted by this mutation. */
  classAssetCategory: Maybe<ClassAssetCategory>;
  /** An edge for our `ClassAssetCategory`. May be used by Relay 1. */
  classAssetCategoryEdge: Maybe<ClassAssetCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassAssetCategoryNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `ClassAssetCategory` mutation. */
export type DeleteClassAssetCategoryPayloadClassAssetCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassAssetCategoriesOrderBy>>;
};

/** All input for the `deleteClassByNodeId` mutation. */
export type DeleteClassByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Class` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassDocumentByClassIdAndDocumentId` mutation. */
export type DeleteClassDocumentByClassIdAndDocumentIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['UUID'];
};

/** All input for the `deleteClassDocumentByNodeId` mutation. */
export type DeleteClassDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassDocument` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassDocument` mutation. */
export type DeleteClassDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassDocument` mutation. */
export type DeleteClassDocumentPayload = {
  __typename?: 'DeleteClassDocumentPayload';
  /** Reads a single `Class` that is related to this `ClassDocument`. */
  class: Maybe<Class>;
  /** The `ClassDocument` that was deleted by this mutation. */
  classDocument: Maybe<ClassDocument>;
  /** An edge for our `ClassDocument`. May be used by Relay 1. */
  classDocumentEdge: Maybe<ClassDocumentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassDocumentNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Document` that is related to this `ClassDocument`. */
  document: Maybe<Document>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `ClassDocument` mutation. */
export type DeleteClassDocumentPayloadClassDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};

/** All input for the `deleteClass` mutation. */
export type DeleteClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Class` mutation. */
export type DeleteClassPayload = {
  __typename?: 'DeleteClassPayload';
  /** Reads a single `Account` that is related to this `Class`. */
  account: Maybe<Account>;
  /** The `Class` that was deleted by this mutation. */
  class: Maybe<Class>;
  /** An edge for our `Class`. May be used by Relay 1. */
  classEdge: Maybe<ClassesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Class`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our delete `Class` mutation. */
export type DeleteClassPayloadClassEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};

/** All input for the `deleteClassStarByClassIdAndUserId` mutation. */
export type DeleteClassStarByClassIdAndUserIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** All input for the `deleteClassStarByNodeId` mutation. */
export type DeleteClassStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassStar` mutation. */
export type DeleteClassStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassStar` mutation. */
export type DeleteClassStarPayload = {
  __typename?: 'DeleteClassStarPayload';
  /** Reads a single `Class` that is related to this `ClassStar`. */
  class: Maybe<Class>;
  /** The `ClassStar` that was deleted by this mutation. */
  classStar: Maybe<ClassStar>;
  /** An edge for our `ClassStar`. May be used by Relay 1. */
  classStarEdge: Maybe<ClassStarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassStarNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `ClassStar` mutation. */
export type DeleteClassStarPayloadClassStarEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};

/** All input for the `deleteClassUserByClassIdAndUserId` mutation. */
export type DeleteClassUserByClassIdAndUserIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** All input for the `deleteClassUserByNodeId` mutation. */
export type DeleteClassUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassUser` mutation. */
export type DeleteClassUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassUser` mutation. */
export type DeleteClassUserPayload = {
  __typename?: 'DeleteClassUserPayload';
  /** Reads a single `Class` that is related to this `ClassUser`. */
  class: Maybe<Class>;
  /** The `ClassUser` that was deleted by this mutation. */
  classUser: Maybe<ClassUser>;
  /** An edge for our `ClassUser`. May be used by Relay 1. */
  classUserEdge: Maybe<ClassUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassUserNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassUser`. */
  user: Maybe<User>;
};


/** The output of our delete `ClassUser` mutation. */
export type DeleteClassUserPayloadClassUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};

/** All input for the `deleteClassVideoByClassIdAndVideoId` mutation. */
export type DeleteClassVideoByClassIdAndVideoIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  videoId: Scalars['UUID'];
};

/** All input for the `deleteClassVideoByNodeId` mutation. */
export type DeleteClassVideoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassVideo` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassVideo` mutation. */
export type DeleteClassVideoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassVideo` mutation. */
export type DeleteClassVideoPayload = {
  __typename?: 'DeleteClassVideoPayload';
  /** Reads a single `Class` that is related to this `ClassVideo`. */
  class: Maybe<Class>;
  /** The `ClassVideo` that was deleted by this mutation. */
  classVideo: Maybe<ClassVideo>;
  /** An edge for our `ClassVideo`. May be used by Relay 1. */
  classVideoEdge: Maybe<ClassVideosEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassVideoNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `ClassVideo`. */
  video: Maybe<Video>;
};


/** The output of our delete `ClassVideo` mutation. */
export type DeleteClassVideoPayloadClassVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};

/** All input for the `deleteClassVideoUserByClassVideoIdAndUserId` mutation. */
export type DeleteClassVideoUserByClassVideoIdAndUserIdInput = {
  classVideoId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** All input for the `deleteClassVideoUserByNodeId` mutation. */
export type DeleteClassVideoUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassVideoUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteClassVideoUser` mutation. */
export type DeleteClassVideoUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ClassVideoUser` mutation. */
export type DeleteClassVideoUserPayload = {
  __typename?: 'DeleteClassVideoUserPayload';
  /** Reads a single `ClassVideo` that is related to this `ClassVideoUser`. */
  classVideo: Maybe<ClassVideo>;
  /** The `ClassVideoUser` that was deleted by this mutation. */
  classVideoUser: Maybe<ClassVideoUser>;
  /** An edge for our `ClassVideoUser`. May be used by Relay 1. */
  classVideoUserEdge: Maybe<ClassVideoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedClassVideoUserNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassVideoUser`. */
  user: Maybe<User>;
};


/** The output of our delete `ClassVideoUser` mutation. */
export type DeleteClassVideoUserPayloadClassVideoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};

/** All input for the `deleteConnectionByNodeId` mutation. */
export type DeleteConnectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Connection` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteConnection` mutation. */
export type DeleteConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Connection ID */
  id: Scalars['UUID'];
};

/** The output of our delete `Connection` mutation. */
export type DeleteConnectionPayload = {
  __typename?: 'DeleteConnectionPayload';
  /** Reads a single `Account` that is related to this `Connection`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Connection` that was deleted by this mutation. */
  connection: Maybe<Connection>;
  /** An edge for our `Connection`. May be used by Relay 1. */
  connectionEdge: Maybe<ConnectionsEdge>;
  deletedConnectionNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Connection`. */
  user: Maybe<User>;
};


/** The output of our delete `Connection` mutation. */
export type DeleteConnectionPayloadConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};

/** All input for the `deleteConsultantOrganizationAccessByNodeId` mutation. */
export type DeleteConsultantOrganizationAccessByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ConsultantOrganizationAccess` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteConsultantOrganizationAccess` mutation. */
export type DeleteConsultantOrganizationAccessInput = {
  /** Account ID - which organization they have access to */
  accountId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User ID - which consultant it is */
  userId: Scalars['UUID'];
};

/** The output of our delete `ConsultantOrganizationAccess` mutation. */
export type DeleteConsultantOrganizationAccessPayload = {
  __typename?: 'DeleteConsultantOrganizationAccessPayload';
  /** Reads a single `Account` that is related to this `ConsultantOrganizationAccess`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `ConsultantOrganizationAccess` that was deleted by this mutation. */
  consultantOrganizationAccess: Maybe<ConsultantOrganizationAccess>;
  /** An edge for our `ConsultantOrganizationAccess`. May be used by Relay 1. */
  consultantOrganizationAccessEdge: Maybe<ConsultantOrganizationAccessesEdge>;
  deletedConsultantOrganizationAccessNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ConsultantOrganizationAccess`. */
  user: Maybe<User>;
};


/** The output of our delete `ConsultantOrganizationAccess` mutation. */
export type DeleteConsultantOrganizationAccessPayloadConsultantOrganizationAccessEdgeArgs = {
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};

/** All input for the `deleteCountryRegionByName` mutation. */
export type DeleteCountryRegionByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the Country/Region */
  name: Scalars['String'];
};

/** All input for the `deleteCountryRegionByNodeId` mutation. */
export type DeleteCountryRegionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CountryRegion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCountryRegion` mutation. */
export type DeleteCountryRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `CountryRegion` mutation. */
export type DeleteCountryRegionPayload = {
  __typename?: 'DeleteCountryRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `CountryRegion` that was deleted by this mutation. */
  countryRegion: Maybe<CountryRegion>;
  /** An edge for our `CountryRegion`. May be used by Relay 1. */
  countryRegionEdge: Maybe<CountryRegionsEdge>;
  deletedCountryRegionNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `CountryRegion` mutation. */
export type DeleteCountryRegionPayloadCountryRegionEdgeArgs = {
  orderBy?: InputMaybe<Array<CountryRegionsOrderBy>>;
};

/** All input for the `deleteCustomerByNodeId` mutation. */
export type DeleteCustomerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Customer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteCustomer` mutation. */
export type DeleteCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for this customer */
  id: Scalars['UUID'];
};

/** The output of our delete `Customer` mutation. */
export type DeleteCustomerPayload = {
  __typename?: 'DeleteCustomerPayload';
  /** Reads a single `Account` that is related to this `Customer`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Customer` that was deleted by this mutation. */
  customer: Maybe<Customer>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge: Maybe<CustomersEdge>;
  deletedCustomerNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Customer` mutation. */
export type DeleteCustomerPayloadCustomerEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};

/** All input for the `deleteDivisionByNameAndV1Id` mutation. */
export type DeleteDivisionByNameAndV1IdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the division */
  name: Scalars['String'];
  /** The ID of the division in v1 */
  v1Id: Scalars['Int'];
};

/** All input for the `deleteDivisionByNodeId` mutation. */
export type DeleteDivisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Division` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDivision` mutation. */
export type DeleteDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Division` mutation. */
export type DeleteDivisionPayload = {
  __typename?: 'DeleteDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedDivisionNodeId: Maybe<Scalars['ID']>;
  /** The `Division` that was deleted by this mutation. */
  division: Maybe<Division>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge: Maybe<DivisionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Division` mutation. */
export type DeleteDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

/** All input for the `deleteDocumentByNodeId` mutation. */
export type DeleteDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Document` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDocument` mutation. */
export type DeleteDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Document` mutation. */
export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Document`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedDocumentNodeId: Maybe<Scalars['ID']>;
  /** The `Document` that was deleted by this mutation. */
  document: Maybe<Document>;
  /** An edge for our `Document`. May be used by Relay 1. */
  documentEdge: Maybe<DocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Document`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our delete `Document` mutation. */
export type DeleteDocumentPayloadDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};

/** All input for the `deleteDocumentStarByDocumentIdAndUserId` mutation. */
export type DeleteDocumentStarByDocumentIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** All input for the `deleteDocumentStarByNodeId` mutation. */
export type DeleteDocumentStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DocumentStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteDocumentStar` mutation. */
export type DeleteDocumentStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `DocumentStar` mutation. */
export type DeleteDocumentStarPayload = {
  __typename?: 'DeleteDocumentStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedDocumentStarNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Document` that is related to this `DocumentStar`. */
  document: Maybe<Document>;
  /** The `DocumentStar` that was deleted by this mutation. */
  documentStar: Maybe<DocumentStar>;
  /** An edge for our `DocumentStar`. May be used by Relay 1. */
  documentStarEdge: Maybe<DocumentStarsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `DocumentStar` mutation. */
export type DeleteDocumentStarPayloadDocumentStarEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};

/** All input for the `deleteEventTypeByNodeId` mutation. */
export type DeleteEventTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EventType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteEventType` mutation. */
export type DeleteEventTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Event type title */
  title: Scalars['String'];
};

/** The output of our delete `EventType` mutation. */
export type DeleteEventTypePayload = {
  __typename?: 'DeleteEventTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedEventTypeNodeId: Maybe<Scalars['ID']>;
  /** The `EventType` that was deleted by this mutation. */
  eventType: Maybe<EventType>;
  /** An edge for our `EventType`. May be used by Relay 1. */
  eventTypeEdge: Maybe<EventTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `EventType` mutation. */
export type DeleteEventTypePayloadEventTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};

/** All input for the `deleteFeedbackQuestionByNodeId` mutation. */
export type DeleteFeedbackQuestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FeedbackQuestion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteFeedbackQuestionByNumberAndQuestion` mutation. */
export type DeleteFeedbackQuestionByNumberAndQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The number of the question as it appears for the user, lower number goes first */
  number: Scalars['Int'];
  /** The text of the question */
  question: Scalars['String'];
};

/** All input for the `deleteFeedbackQuestion` mutation. */
export type DeleteFeedbackQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `FeedbackQuestion` mutation. */
export type DeleteFeedbackQuestionPayload = {
  __typename?: 'DeleteFeedbackQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedFeedbackQuestionNodeId: Maybe<Scalars['ID']>;
  /** The `FeedbackQuestion` that was deleted by this mutation. */
  feedbackQuestion: Maybe<FeedbackQuestion>;
  /** An edge for our `FeedbackQuestion`. May be used by Relay 1. */
  feedbackQuestionEdge: Maybe<FeedbackQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `FeedbackQuestion` mutation. */
export type DeleteFeedbackQuestionPayloadFeedbackQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbackQuestionsOrderBy>>;
};

/** All input for the `deleteGoogleAccountUserByNodeId` mutation. */
export type DeleteGoogleAccountUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleAccountUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGoogleAccountUser` mutation. */
export type DeleteGoogleAccountUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id: Scalars['UUID'];
};

/** The output of our delete `GoogleAccountUser` mutation. */
export type DeleteGoogleAccountUserPayload = {
  __typename?: 'DeleteGoogleAccountUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedGoogleAccountUserNodeId: Maybe<Scalars['ID']>;
  /** The `GoogleAccountUser` that was deleted by this mutation. */
  googleAccountUser: Maybe<GoogleAccountUser>;
  /** An edge for our `GoogleAccountUser`. May be used by Relay 1. */
  googleAccountUserEdge: Maybe<GoogleAccountUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleAccountUser`. */
  user: Maybe<User>;
};


/** The output of our delete `GoogleAccountUser` mutation. */
export type DeleteGoogleAccountUserPayloadGoogleAccountUserEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};

/** All input for the `deleteGoogleCalendarEventByNodeId` mutation. */
export type DeleteGoogleCalendarEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteGoogleCalendarEvent` mutation. */
export type DeleteGoogleCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
};

/** The output of our delete `GoogleCalendarEvent` mutation. */
export type DeleteGoogleCalendarEventPayload = {
  __typename?: 'DeleteGoogleCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedGoogleCalendarEventNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `ProjectEvent` that is related to this `GoogleCalendarEvent`. */
  event: Maybe<ProjectEvent>;
  /** The `GoogleCalendarEvent` that was deleted by this mutation. */
  googleCalendarEvent: Maybe<GoogleCalendarEvent>;
  /** An edge for our `GoogleCalendarEvent`. May be used by Relay 1. */
  googleCalendarEventEdge: Maybe<GoogleCalendarEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleCalendarEvent`. */
  user: Maybe<User>;
};


/** The output of our delete `GoogleCalendarEvent` mutation. */
export type DeleteGoogleCalendarEventPayloadGoogleCalendarEventEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};

/** All input for the `deleteIndustryByNameAndV1Id` mutation. */
export type DeleteIndustryByNameAndV1IdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the Industry */
  name: Scalars['String'];
  /** The ID of the Industry in v1 */
  v1Id: Scalars['Int'];
};

/** All input for the `deleteIndustryByNodeId` mutation. */
export type DeleteIndustryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Industry` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteIndustry` mutation. */
export type DeleteIndustryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Industry` mutation. */
export type DeleteIndustryPayload = {
  __typename?: 'DeleteIndustryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedIndustryNodeId: Maybe<Scalars['ID']>;
  /** The `Industry` that was deleted by this mutation. */
  industry: Maybe<Industry>;
  /** An edge for our `Industry`. May be used by Relay 1. */
  industryEdge: Maybe<IndustriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `Industry` mutation. */
export type DeleteIndustryPayloadIndustryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndustriesOrderBy>>;
};

/** All input for the `deleteLv1GameScoreByNodeId` mutation. */
export type DeleteLv1GameScoreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameScore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLv1GameScore` mutation. */
export type DeleteLv1GameScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The output of our delete `Lv1GameScore` mutation. */
export type DeleteLv1GameScorePayload = {
  __typename?: 'DeleteLv1GameScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedLv1GameScoreNodeId: Maybe<Scalars['ID']>;
  /** The `Lv1GameScore` that was deleted by this mutation. */
  lv1GameScore: Maybe<Lv1GameScore>;
  /** An edge for our `Lv1GameScore`. May be used by Relay 1. */
  lv1GameScoreEdge: Maybe<Lv1GameScoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameScore`. */
  user: Maybe<User>;
};


/** The output of our delete `Lv1GameScore` mutation. */
export type DeleteLv1GameScorePayloadLv1GameScoreEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameScoresOrderBy>>;
};

/** All input for the `deleteLv1GameStateByNodeId` mutation. */
export type DeleteLv1GameStateByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameState` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLv1GameState` mutation. */
export type DeleteLv1GameStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The output of our delete `Lv1GameState` mutation. */
export type DeleteLv1GameStatePayload = {
  __typename?: 'DeleteLv1GameStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedLv1GameStateNodeId: Maybe<Scalars['ID']>;
  /** The `Lv1GameState` that was deleted by this mutation. */
  lv1GameState: Maybe<Lv1GameState>;
  /** An edge for our `Lv1GameState`. May be used by Relay 1. */
  lv1GameStateEdge: Maybe<Lv1GameStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameState`. */
  user: Maybe<User>;
};


/** The output of our delete `Lv1GameState` mutation. */
export type DeleteLv1GameStatePayloadLv1GameStateEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameStatesOrderBy>>;
};

/** All input for the `deleteLv1GameSubgroupByNodeId` mutation. */
export type DeleteLv1GameSubgroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameSubgroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLv1GameSubgroup` mutation. */
export type DeleteLv1GameSubgroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Subgroup ID */
  id: Scalars['UUID'];
};

/** The output of our delete `Lv1GameSubgroup` mutation. */
export type DeleteLv1GameSubgroupPayload = {
  __typename?: 'DeleteLv1GameSubgroupPayload';
  /** Reads a single `Account` that is related to this `Lv1GameSubgroup`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedLv1GameSubgroupNodeId: Maybe<Scalars['ID']>;
  /** The `Lv1GameSubgroup` that was deleted by this mutation. */
  lv1GameSubgroup: Maybe<Lv1GameSubgroup>;
  /** An edge for our `Lv1GameSubgroup`. May be used by Relay 1. */
  lv1GameSubgroupEdge: Maybe<Lv1GameSubgroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroup`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our delete `Lv1GameSubgroup` mutation. */
export type DeleteLv1GameSubgroupPayloadLv1GameSubgroupEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};

/** All input for the `deleteLv1GameSubgroupUserByNodeId` mutation. */
export type DeleteLv1GameSubgroupUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameSubgroupUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteLv1GameSubgroupUser` mutation. */
export type DeleteLv1GameSubgroupUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The output of our delete `Lv1GameSubgroupUser` mutation. */
export type DeleteLv1GameSubgroupUserPayload = {
  __typename?: 'DeleteLv1GameSubgroupUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedLv1GameSubgroupUserNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Lv1GameSubgroup` that is related to this `Lv1GameSubgroupUser`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** The `Lv1GameSubgroupUser` that was deleted by this mutation. */
  lv1GameSubgroupUser: Maybe<Lv1GameSubgroupUser>;
  /** An edge for our `Lv1GameSubgroupUser`. May be used by Relay 1. */
  lv1GameSubgroupUserEdge: Maybe<Lv1GameSubgroupUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroupUser`. */
  user: Maybe<User>;
};


/** The output of our delete `Lv1GameSubgroupUser` mutation. */
export type DeleteLv1GameSubgroupUserPayloadLv1GameSubgroupUserEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};

/** All input for the `deleteMassUserUploadByNodeId` mutation. */
export type DeleteMassUserUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MassUserUpload` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteMassUserUpload` mutation. */
export type DeleteMassUserUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `MassUserUpload` mutation. */
export type DeleteMassUserUploadPayload = {
  __typename?: 'DeleteMassUserUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedMassUserUploadNodeId: Maybe<Scalars['ID']>;
  /** The `MassUserUpload` that was deleted by this mutation. */
  massUserUpload: Maybe<MassUserUpload>;
  /** An edge for our `MassUserUpload`. May be used by Relay 1. */
  massUserUploadEdge: Maybe<MassUserUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `MassUserUpload`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our delete `MassUserUpload` mutation. */
export type DeleteMassUserUploadPayloadMassUserUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};

/** All input for the `deleteOrganizationCoachingTimeLog` mutation. */
export type DeleteOrganizationCoachingTimeLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `deleteOrganizationCoachingTimeLog` mutation. */
export type DeleteOrganizationCoachingTimeLogPayload = {
  __typename?: 'DeleteOrganizationCoachingTimeLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** All input for the `deleteProjectByNodeId` mutation. */
export type DeleteProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Project` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProject` mutation. */
export type DeleteProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id: Scalars['UUID'];
};

/** All input for the `deleteProjectInvitationByNodeId` mutation. */
export type DeleteProjectInvitationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectInvitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectInvitationByProjectIdAndUserId` mutation. */
export type DeleteProjectInvitationByProjectIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID to invite */
  userId: Scalars['UUID'];
};

/** All input for the `deleteProjectInvitation` mutation. */
export type DeleteProjectInvitationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The output of our delete `ProjectInvitation` mutation. */
export type DeleteProjectInvitationPayload = {
  __typename?: 'DeleteProjectInvitationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedProjectInvitationNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Project` that is related to this `ProjectInvitation`. */
  project: Maybe<Project>;
  /** The `ProjectInvitation` that was deleted by this mutation. */
  projectInvitation: Maybe<ProjectInvitation>;
  /** An edge for our `ProjectInvitation`. May be used by Relay 1. */
  projectInvitationEdge: Maybe<ProjectInvitationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectInvitation`. */
  user: Maybe<User>;
};


/** The output of our delete `ProjectInvitation` mutation. */
export type DeleteProjectInvitationPayloadProjectInvitationEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};

/** All input for the `deleteProjectMembershipByNodeId` mutation. */
export type DeleteProjectMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectMembership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectMembershipByProjectIdAndUserId` mutation. */
export type DeleteProjectMembershipByProjectIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** All input for the `deleteProjectMembership` mutation. */
export type DeleteProjectMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The output of our delete `ProjectMembership` mutation. */
export type DeleteProjectMembershipPayload = {
  __typename?: 'DeleteProjectMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  createdByUser: Maybe<User>;
  deletedProjectMembershipNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Project` that is related to this `ProjectMembership`. */
  project: Maybe<Project>;
  /** The `ProjectMembership` that was deleted by this mutation. */
  projectMembership: Maybe<ProjectMembership>;
  /** An edge for our `ProjectMembership`. May be used by Relay 1. */
  projectMembershipEdge: Maybe<ProjectMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  user: Maybe<User>;
};


/** The output of our delete `ProjectMembership` mutation. */
export type DeleteProjectMembershipPayloadProjectMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};

/** The output of our delete `Project` mutation. */
export type DeleteProjectPayload = {
  __typename?: 'DeleteProjectPayload';
  /** Reads a single `Account` that is related to this `Project`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Customer` that is related to this `Project`. */
  customer: Maybe<Customer>;
  deletedProjectNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `Project` that is related to this `Project`. */
  parent: Maybe<Project>;
  /** The `Project` that was deleted by this mutation. */
  project: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge: Maybe<ProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Project`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our delete `Project` mutation. */
export type DeleteProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the `deleteProjectReminderByNodeId` mutation. */
export type DeleteProjectReminderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectReminder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteProjectReminder` mutation. */
export type DeleteProjectReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `ProjectReminder` mutation. */
export type DeleteProjectReminderPayload = {
  __typename?: 'DeleteProjectReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedProjectReminderNodeId: Maybe<Scalars['ID']>;
  /** The `ProjectReminder` that was deleted by this mutation. */
  projectReminder: Maybe<ProjectReminder>;
  /** An edge for our `ProjectReminder`. May be used by Relay 1. */
  projectReminderEdge: Maybe<ProjectRemindersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our delete `ProjectReminder` mutation. */
export type DeleteProjectReminderPayloadProjectReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectRemindersOrderBy>>;
};

/** All input for the `deleteSurveyBucketByName` mutation. */
export type DeleteSurveyBucketByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the survey bucket */
  name: Scalars['String'];
};

/** All input for the `deleteSurveyBucketByNodeId` mutation. */
export type DeleteSurveyBucketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyBucket` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSurveyBucket` mutation. */
export type DeleteSurveyBucketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SurveyBucket` mutation. */
export type DeleteSurveyBucketPayload = {
  __typename?: 'DeleteSurveyBucketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedSurveyBucketNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyBucket` that was deleted by this mutation. */
  surveyBucket: Maybe<SurveyBucket>;
  /** An edge for our `SurveyBucket`. May be used by Relay 1. */
  surveyBucketEdge: Maybe<SurveyBucketsEdge>;
};


/** The output of our delete `SurveyBucket` mutation. */
export type DeleteSurveyBucketPayloadSurveyBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyBucketsOrderBy>>;
};

/** All input for the `deleteSurveyQuestionAnswerByNodeId` mutation. */
export type DeleteSurveyQuestionAnswerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyQuestionAnswer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSurveyQuestionAnswer` mutation. */
export type DeleteSurveyQuestionAnswerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of this answer */
  id: Scalars['UUID'];
};

/** The output of our delete `SurveyQuestionAnswer` mutation. */
export type DeleteSurveyQuestionAnswerPayload = {
  __typename?: 'DeleteSurveyQuestionAnswerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedSurveyQuestionAnswerNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestionAnswer` that was deleted by this mutation. */
  surveyQuestionAnswer: Maybe<SurveyQuestionAnswer>;
  /** An edge for our `SurveyQuestionAnswer`. May be used by Relay 1. */
  surveyQuestionAnswerEdge: Maybe<SurveyQuestionAnswersEdge>;
};


/** The output of our delete `SurveyQuestionAnswer` mutation. */
export type DeleteSurveyQuestionAnswerPayloadSurveyQuestionAnswerEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionAnswersOrderBy>>;
};

/** All input for the `deleteSurveyQuestionByNodeId` mutation. */
export type DeleteSurveyQuestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyQuestion` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSurveyQuestionByQuestionAndSurveyType` mutation. */
export type DeleteSurveyQuestionByQuestionAndSurveyTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The text of the question */
  question: Scalars['String'];
  /** Which survey the question belongs to */
  surveyType: SurveyType;
};

/** All input for the `deleteSurveyQuestion` mutation. */
export type DeleteSurveyQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SurveyQuestion` mutation. */
export type DeleteSurveyQuestionPayload = {
  __typename?: 'DeleteSurveyQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedSurveyQuestionNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestion` that was deleted by this mutation. */
  surveyQuestion: Maybe<SurveyQuestion>;
  /** An edge for our `SurveyQuestion`. May be used by Relay 1. */
  surveyQuestionEdge: Maybe<SurveyQuestionsEdge>;
};


/** The output of our delete `SurveyQuestion` mutation. */
export type DeleteSurveyQuestionPayloadSurveyQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionsOrderBy>>;
};

/** All input for the `deleteSurveyReminderByNodeId` mutation. */
export type DeleteSurveyReminderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyReminder` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSurveyReminder` mutation. */
export type DeleteSurveyReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SurveyReminder` mutation. */
export type DeleteSurveyReminderPayload = {
  __typename?: 'DeleteSurveyReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedSurveyReminderNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyReminder` that was deleted by this mutation. */
  surveyReminder: Maybe<SurveyReminder>;
  /** An edge for our `SurveyReminder`. May be used by Relay 1. */
  surveyReminderEdge: Maybe<SurveyRemindersEdge>;
};


/** The output of our delete `SurveyReminder` mutation. */
export type DeleteSurveyReminderPayloadSurveyReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyRemindersOrderBy>>;
};

/** All input for the `deleteSurveyResponseByNodeId` mutation. */
export type DeleteSurveyResponseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyResponse` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteSurveyResponseByUserIdAndRecipientIdAndSurveyType` mutation. */
export type DeleteSurveyResponseByUserIdAndRecipientIdAndSurveyTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Scalars['UUID'];
  /** Which survey type the response is for */
  surveyType: SurveyType;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
};

/** All input for the `deleteSurveyResponse` mutation. */
export type DeleteSurveyResponseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `SurveyResponse` mutation. */
export type DeleteSurveyResponsePayload = {
  __typename?: 'DeleteSurveyResponsePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedSurveyResponseNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyResponse` that was deleted by this mutation. */
  surveyResponse: Maybe<SurveyResponse>;
  /** An edge for our `SurveyResponse`. May be used by Relay 1. */
  surveyResponseEdge: Maybe<SurveyResponsesEdge>;
  /** Reads a single `User` that is related to this `SurveyResponse`. */
  user: Maybe<User>;
};


/** The output of our delete `SurveyResponse` mutation. */
export type DeleteSurveyResponsePayloadSurveyResponseEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};

/** All input for the `deleteUserLanguageByLanguageCode` mutation. */
export type DeleteUserLanguageByLanguageCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
};

/** All input for the `deleteUserLanguageByName` mutation. */
export type DeleteUserLanguageByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the language in English */
  name: Scalars['String'];
};

/** The output of our delete `UserLanguage` mutation. */
export type DeleteUserLanguagePayload = {
  __typename?: 'DeleteUserLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedUserLanguageNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `UserLanguage` that was deleted by this mutation. */
  userLanguage: Maybe<UserLanguage>;
  /** An edge for our `UserLanguage`. May be used by Relay 1. */
  userLanguageEdge: Maybe<UserLanguagesEdge>;
};


/** The output of our delete `UserLanguage` mutation. */
export type DeleteUserLanguagePayloadUserLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLanguagesOrderBy>>;
};

/** All input for the `deleteUserProfileQuestionnaireByNodeId` mutation. */
export type DeleteUserProfileQuestionnaireByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserProfileQuestionnaire` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteUserProfileQuestionnaireByUserId` mutation. */
export type DeleteUserProfileQuestionnaireByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** All input for the `deleteUserProfileQuestionnaire` mutation. */
export type DeleteUserProfileQuestionnaireInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `UserProfileQuestionnaire` mutation. */
export type DeleteUserProfileQuestionnairePayload = {
  __typename?: 'DeleteUserProfileQuestionnairePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedUserProfileQuestionnaireNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfileQuestionnaire`. */
  user: Maybe<User>;
  /** The `UserProfileQuestionnaire` that was deleted by this mutation. */
  userProfileQuestionnaire: Maybe<UserProfileQuestionnaire>;
  /** An edge for our `UserProfileQuestionnaire`. May be used by Relay 1. */
  userProfileQuestionnaireEdge: Maybe<UserProfileQuestionnairesEdge>;
};


/** The output of our delete `UserProfileQuestionnaire` mutation. */
export type DeleteUserProfileQuestionnairePayloadUserProfileQuestionnaireEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfileQuestionnairesOrderBy>>;
};

/** All input for the `deleteV1RoleByIdAndName` mutation. */
export type DeleteV1RoleByIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the role in v1 */
  id: Scalars['Int'];
  /** The name of the v1 role */
  name: Scalars['String'];
};

/** All input for the `deleteV1RoleByNodeId` mutation. */
export type DeleteV1RoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `V1Role` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteV1Role` mutation. */
export type DeleteV1RoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the role in v1 */
  id: Scalars['Int'];
};

/** The output of our delete `V1Role` mutation. */
export type DeleteV1RolePayload = {
  __typename?: 'DeleteV1RolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedV1RoleNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `V1Role` that was deleted by this mutation. */
  v1Role: Maybe<V1Role>;
  /** An edge for our `V1Role`. May be used by Relay 1. */
  v1RoleEdge: Maybe<V1RolesEdge>;
};


/** The output of our delete `V1Role` mutation. */
export type DeleteV1RolePayloadV1RoleEdgeArgs = {
  orderBy?: InputMaybe<Array<V1RolesOrderBy>>;
};

/** All input for the `deleteVideoByNodeId` mutation. */
export type DeleteVideoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Video` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVideo` mutation. */
export type DeleteVideoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `Video` mutation. */
export type DeleteVideoPayload = {
  __typename?: 'DeleteVideoPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Video`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedVideoNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Video`. */
  userByCreatedBy: Maybe<User>;
  /** The `Video` that was deleted by this mutation. */
  video: Maybe<Video>;
  /** An edge for our `Video`. May be used by Relay 1. */
  videoEdge: Maybe<VideosEdge>;
};


/** The output of our delete `Video` mutation. */
export type DeleteVideoPayloadVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};

/** All input for the `deleteVideoStarByNodeId` mutation. */
export type DeleteVideoStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VideoStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteVideoStarByVideoIdAndUserId` mutation. */
export type DeleteVideoStarByVideoIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** All input for the `deleteVideoStar` mutation. */
export type DeleteVideoStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** The output of our delete `VideoStar` mutation. */
export type DeleteVideoStarPayload = {
  __typename?: 'DeleteVideoStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedVideoStarNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `VideoStar`. */
  video: Maybe<Video>;
  /** The `VideoStar` that was deleted by this mutation. */
  videoStar: Maybe<VideoStar>;
  /** An edge for our `VideoStar`. May be used by Relay 1. */
  videoStarEdge: Maybe<VideoStarsEdge>;
};


/** The output of our delete `VideoStar` mutation. */
export type DeleteVideoStarPayloadVideoStarEdgeArgs = {
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};

/** All input for the `deleteWorkshopByNodeId` mutation. */
export type DeleteWorkshopByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Workshop` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWorkshop` mutation. */
export type DeleteWorkshopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
};

/** The output of our delete `Workshop` mutation. */
export type DeleteWorkshopPayload = {
  __typename?: 'DeleteWorkshopPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedWorkshopNodeId: Maybe<Scalars['ID']>;
  /** Reads a single `WorkshopPreset` that is related to this `Workshop`. */
  preset: Maybe<WorkshopPreset>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Workshop`. */
  userByCreatedBy: Maybe<User>;
  /** The `Workshop` that was deleted by this mutation. */
  workshop: Maybe<Workshop>;
  /** An edge for our `Workshop`. May be used by Relay 1. */
  workshopEdge: Maybe<WorkshopsEdge>;
};


/** The output of our delete `Workshop` mutation. */
export type DeleteWorkshopPayloadWorkshopEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

/** All input for the `deleteWorkshopPresetByNodeId` mutation. */
export type DeleteWorkshopPresetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WorkshopPreset` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deleteWorkshopPreset` mutation. */
export type DeleteWorkshopPresetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
};

/** The output of our delete `WorkshopPreset` mutation. */
export type DeleteWorkshopPresetPayload = {
  __typename?: 'DeleteWorkshopPresetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  deletedWorkshopPresetNodeId: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkshopPreset` that was deleted by this mutation. */
  workshopPreset: Maybe<WorkshopPreset>;
  /** An edge for our `WorkshopPreset`. May be used by Relay 1. */
  workshopPresetEdge: Maybe<WorkshopPresetsEdge>;
};


/** The output of our delete `WorkshopPreset` mutation. */
export type DeleteWorkshopPresetPayloadWorkshopPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopPresetsOrderBy>>;
};

export type Division = Node & {
  __typename?: 'Division';
  id: Scalars['UUID'];
  /** The name of the division */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The ID of the division in v1 */
  v1Id: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `Division` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DivisionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Division` object types. All fields are combined with a logical ‘and.’ */
export type DivisionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DivisionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DivisionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DivisionFilter>>;
};

/** An input for mutations affecting `Division` */
export type DivisionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the division */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the division in v1 */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Division`. Fields that are set will be updated. */
export type DivisionPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the division */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the division in v1 */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `Division` values. */
export type DivisionsConnection = {
  __typename?: 'DivisionsConnection';
  /** A list of edges which contains the `Division` and cursor to aid in pagination. */
  edges: Array<DivisionsEdge>;
  /** A list of `Division` objects. */
  nodes: Array<Division>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Division` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Division` edge in the connection. */
export type DivisionsEdge = {
  __typename?: 'DivisionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Division` at the end of the edge. */
  node: Division;
};

/** Methods to use when ordering `Division`. */
export enum DivisionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Document = Node & {
  __typename?: 'Document';
  /** Reads a single `ClassAssetCategory` that is related to this `Document`. */
  category: Maybe<ClassAssetCategory>;
  categoryId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ClassDocument`. */
  classDocuments: ClassDocumentsConnection;
  /** Reads and enables pagination through a set of `ClassDocument`. */
  classDocumentsList: Array<ClassDocument>;
  classes: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  /** Creator of the document, related to the users model */
  createdBy: Scalars['UUID'];
  /** Reads and enables pagination through a set of `DocumentStar`. */
  documentStars: DocumentStarsConnection;
  /** Reads and enables pagination through a set of `DocumentStar`. */
  documentStarsList: Array<DocumentStar>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize: Maybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isStarred: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  pathUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Document`. */
  userByCreatedBy: Maybe<User>;
};


export type DocumentClassDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


export type DocumentClassDocumentsListArgs = {
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


export type DocumentDocumentStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentStarCondition>;
  filter?: InputMaybe<DocumentStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};


export type DocumentDocumentStarsListArgs = {
  condition?: InputMaybe<DocumentStarCondition>;
  filter?: InputMaybe<DocumentStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};

/** The `classAssetCategory` to be created by this mutation. */
export type DocumentCategoryIdFkeyClassAssetCategoryCreateInput = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** The `document` to be created by this mutation. */
export type DocumentCategoryIdFkeyDocumentCreateInput = {
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** Input for the nested mutation of `classAssetCategory` in the `DocumentInput` mutation. */
export type DocumentCategoryIdFkeyInput = {
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  connectById?: InputMaybe<ClassAssetCategoryClassAssetCategoryPkeyConnect>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassAssetCategoryNodeIdConnect>;
  /** A `ClassAssetCategoryInput` object that will be created and connected to this object. */
  create?: InputMaybe<DocumentCategoryIdFkeyClassAssetCategoryCreateInput>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassAssetCategoryClassAssetCategoryPkeyDelete>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassAssetCategoryNodeIdDelete>;
  /** The primary key(s) and patch data for `classAssetCategory` for the far side of the relationship. */
  updateById?: InputMaybe<ClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyUsingClassAssetCategoryPkeyUpdate>;
  /** The primary key(s) and patch data for `classAssetCategory` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentOnDocumentForDocumentCategoryIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `document` in the `ClassAssetCategoryInput` mutation. */
export type DocumentCategoryIdFkeyInverseInput = {
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DocumentDocumentPkeyConnect>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentNodeIdConnect>>;
  /** A `DocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<DocumentCategoryIdFkeyDocumentCreateInput>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DocumentDocumentPkeyDelete>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<DocumentNodeIdDelete>>;
  /** Flag indicating whether all other `document` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateById?: InputMaybe<Array<DocumentOnDocumentForDocumentCategoryIdFkeyUsingDocumentPkeyUpdate>>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyNodeIdUpdate>>;
};

/**
 * A condition to be used against `Document` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DocumentCondition = {
  /** Checks for equality with the object’s `categoryId` field. */
  categoryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fileType` field. */
  fileType?: InputMaybe<DocumentFileType>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** The `document` to be created by this mutation. */
export type DocumentCreatedByFkeyDocumentCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** Input for the nested mutation of `user` in the `DocumentInput` mutation. */
export type DocumentCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnDocumentForDocumentCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnDocumentForDocumentCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentOnDocumentForDocumentCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `document` in the `UserInput` mutation. */
export type DocumentCreatedByFkeyInverseInput = {
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DocumentDocumentPkeyConnect>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentNodeIdConnect>>;
  /** A `DocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<DocumentCreatedByFkeyDocumentCreateInput>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DocumentDocumentPkeyDelete>>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<DocumentNodeIdDelete>>;
  /** Flag indicating whether all other `document` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateById?: InputMaybe<Array<DocumentOnDocumentForDocumentCreatedByFkeyUsingDocumentPkeyUpdate>>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnDocumentForDocumentCreatedByFkeyNodeIdUpdate>>;
};

/** The fields on `document` to look up the row to connect. */
export type DocumentDocumentPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `document` to look up the row to delete. */
export type DocumentDocumentPkeyDelete = {
  id: Scalars['UUID'];
};

export enum DocumentFileType {
  Pdf = 'PDF',
  Ppt = 'PPT',
  Pptx = 'PPTX'
}

/** A filter to be used against DocumentFileType fields. All fields are combined with a logical ‘and.’ */
export type DocumentFileTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DocumentFileType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DocumentFileType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DocumentFileType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DocumentFileType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DocumentFileType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DocumentFileType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DocumentFileType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DocumentFileType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DocumentFileType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DocumentFileType>>;
};

/** A filter to be used against `Document` object types. All fields are combined with a logical ‘and.’ */
export type DocumentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentFilter>>;
  /** Filter by the object’s `category` relation. */
  category?: InputMaybe<ClassAssetCategoryFilter>;
  /** Filter by the object’s `categoryId` field. */
  categoryId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `classDocuments` relation. */
  classDocuments?: InputMaybe<DocumentToManyClassDocumentFilter>;
  /** Some related `classDocuments` exist. */
  classDocumentsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classes` field. */
  classes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `documentStars` relation. */
  documentStars?: InputMaybe<DocumentToManyDocumentStarFilter>;
  /** Some related `documentStars` exist. */
  documentStarsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `fileType` field. */
  fileType?: InputMaybe<DocumentFileTypeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isStarred` field. */
  isStarred?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentFilter>>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
};

/** An input for mutations affecting `Document` */
export type DocumentInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DocumentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `document` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DocumentNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `document` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentOnClassDocumentForClassDocumentDocumentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classDocument` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classDocument` being updated. */
  patch: ClassDocumentPatch;
};

/** The fields on `document` to look up the row to update. */
export type DocumentOnClassDocumentForClassDocumentDocumentIdFkeyUsingDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: UpdateDocumentOnClassDocumentForClassDocumentDocumentIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentOnDocumentForDocumentCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classAssetCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classAssetCategory` being updated. */
  patch: ClassAssetCategoryPatch;
};

/** The fields on `document` to look up the row to update. */
export type DocumentOnDocumentForDocumentCategoryIdFkeyUsingDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: UpdateDocumentOnDocumentForDocumentCategoryIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentOnDocumentForDocumentCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `document` to look up the row to update. */
export type DocumentOnDocumentForDocumentCreatedByFkeyUsingDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: UpdateDocumentOnDocumentForDocumentCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentOnDocumentStarForDocumentStarDocumentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `documentStar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `documentStar` being updated. */
  patch: DocumentStarPatch;
};

/** The fields on `document` to look up the row to update. */
export type DocumentOnDocumentStarForDocumentStarDocumentIdFkeyUsingDocumentPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: UpdateDocumentOnDocumentStarForDocumentStarDocumentIdFkeyPatch;
};

/** Represents an update to a `Document`. Fields that are set will be updated. */
export type DocumentPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType?: InputMaybe<DocumentFileType>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** All input for the `documentSetIsStarred` mutation. */
export type DocumentSetIsStarredInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['UUID'];
  isStarred: Scalars['Boolean'];
};

/** The output of our `documentSetIsStarred` mutation. */
export type DocumentSetIsStarredPayload = {
  __typename?: 'DocumentSetIsStarredPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Document` that is related to this `DocumentStar`. */
  document: Maybe<Document>;
  documentStar: Maybe<DocumentStar>;
  /** An edge for our `DocumentStar`. May be used by Relay 1. */
  documentStarEdge: Maybe<DocumentStarsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our `documentSetIsStarred` mutation. */
export type DocumentSetIsStarredPayloadDocumentStarEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};

/** Used for the admin starring functionality on documents */
export type DocumentStar = Node & {
  __typename?: 'DocumentStar';
  /** Reads a single `Document` that is related to this `DocumentStar`. */
  document: Maybe<Document>;
  documentId: Scalars['UUID'];
  id: Scalars['UUID'];
  isStarred: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `DocumentStar` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DocumentStarCondition = {
  /** Checks for equality with the object’s `documentId` field. */
  documentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** The `document` to be created by this mutation. */
export type DocumentStarDocumentIdFkeyDocumentCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType: DocumentFileType;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** The `documentStar` to be created by this mutation. */
export type DocumentStarDocumentIdFkeyDocumentStarCreateInput = {
  documentToDocumentId?: InputMaybe<DocumentStarDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `document` in the `DocumentStarInput` mutation. */
export type DocumentStarDocumentIdFkeyInput = {
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectById?: InputMaybe<DocumentDocumentPkeyConnect>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<DocumentNodeIdConnect>;
  /** A `DocumentInput` object that will be created and connected to this object. */
  create?: InputMaybe<DocumentStarDocumentIdFkeyDocumentCreateInput>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteById?: InputMaybe<DocumentDocumentPkeyDelete>;
  /** The primary key(s) for `document` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<DocumentNodeIdDelete>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateById?: InputMaybe<DocumentOnDocumentStarForDocumentStarDocumentIdFkeyUsingDocumentPkeyUpdate>;
  /** The primary key(s) and patch data for `document` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `documentStar` in the `DocumentInput` mutation. */
export type DocumentStarDocumentIdFkeyInverseInput = {
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  connectByDocumentIdAndUserId?: InputMaybe<Array<DocumentStarUniqDocumentStarConnect>>;
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DocumentStarDocumentStarPkeyConnect>>;
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentStarNodeIdConnect>>;
  /** A `DocumentStarInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<DocumentStarDocumentIdFkeyDocumentStarCreateInput>>;
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  deleteByDocumentIdAndUserId?: InputMaybe<Array<DocumentStarUniqDocumentStarDelete>>;
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DocumentStarDocumentStarPkeyDelete>>;
  /** The primary key(s) for `documentStar` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<DocumentStarNodeIdDelete>>;
  /** Flag indicating whether all other `documentStar` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `documentStar` for the far side of the relationship. */
  updateByDocumentIdAndUserId?: InputMaybe<Array<DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyUsingUniqDocumentStarUpdate>>;
  /** The primary key(s) and patch data for `documentStar` for the far side of the relationship. */
  updateById?: InputMaybe<Array<DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyUsingDocumentStarPkeyUpdate>>;
  /** The primary key(s) and patch data for `documentStar` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<DocumentOnDocumentStarForDocumentStarDocumentIdFkeyNodeIdUpdate>>;
};

/** The fields on `documentStar` to look up the row to connect. */
export type DocumentStarDocumentStarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `documentStar` to look up the row to delete. */
export type DocumentStarDocumentStarPkeyDelete = {
  id: Scalars['UUID'];
};

/** A filter to be used against `DocumentStar` object types. All fields are combined with a logical ‘and.’ */
export type DocumentStarFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentStarFilter>>;
  /** Filter by the object’s `document` relation. */
  document?: InputMaybe<DocumentFilter>;
  /** Filter by the object’s `documentId` field. */
  documentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentStarFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentStarFilter>>;
};

/** An input for mutations affecting `DocumentStar` */
export type DocumentStarInput = {
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<DocumentStarDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DocumentStarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `documentStar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type DocumentStarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `documentStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `document` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: DocumentPatch;
};

/** The fields on `documentStar` to look up the row to update. */
export type DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyUsingDocumentStarPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `documentStar` being updated. */
  patch: UpdateDocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyPatch;
};

/** The fields on `documentStar` to look up the row to update. */
export type DocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyUsingUniqDocumentStarUpdate = {
  documentId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `documentStar` being updated. */
  patch: UpdateDocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `DocumentStar`. Fields that are set will be updated. */
export type DocumentStarPatch = {
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<DocumentStarDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The fields on `documentStar` to look up the row to connect. */
export type DocumentStarUniqDocumentStarConnect = {
  documentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** The fields on `documentStar` to look up the row to delete. */
export type DocumentStarUniqDocumentStarDelete = {
  documentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};

/** A connection to a list of `DocumentStar` values. */
export type DocumentStarsConnection = {
  __typename?: 'DocumentStarsConnection';
  /** A list of edges which contains the `DocumentStar` and cursor to aid in pagination. */
  edges: Array<DocumentStarsEdge>;
  /** A list of `DocumentStar` objects. */
  nodes: Array<DocumentStar>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DocumentStar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `DocumentStar` edge in the connection. */
export type DocumentStarsEdge = {
  __typename?: 'DocumentStarsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `DocumentStar` at the end of the edge. */
  node: DocumentStar;
};

/** Methods to use when ordering `DocumentStar`. */
export enum DocumentStarsOrderBy {
  DocumentIdAsc = 'DOCUMENT_ID_ASC',
  DocumentIdDesc = 'DOCUMENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `ClassDocument` object types. All fields are combined with a logical ‘and.’ */
export type DocumentToManyClassDocumentFilter = {
  /** Every related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassDocumentFilter>;
  /** No related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassDocumentFilter>;
  /** Some related `ClassDocument` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassDocumentFilter>;
};

/** A filter to be used against many `DocumentStar` object types. All fields are combined with a logical ‘and.’ */
export type DocumentToManyDocumentStarFilter = {
  /** Every related `DocumentStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentStarFilter>;
  /** No related `DocumentStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentStarFilter>;
  /** Some related `DocumentStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentStarFilter>;
};

/** A connection to a list of `Document` values. */
export type DocumentsConnection = {
  __typename?: 'DocumentsConnection';
  /** A list of edges which contains the `Document` and cursor to aid in pagination. */
  edges: Array<DocumentsEdge>;
  /** A list of `Document` objects. */
  nodes: Array<Document>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Document` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Document` edge in the connection. */
export type DocumentsEdge = {
  __typename?: 'DocumentsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Document` at the end of the edge. */
  node: Document;
};

/** Methods to use when ordering `Document`. */
export enum DocumentsOrderBy {
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  FileTypeAsc = 'FILE_TYPE_ASC',
  FileTypeDesc = 'FILE_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `duplicateProject` mutation. */
export type DuplicateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newProjectName: Scalars['String'];
  originalProjectId: Scalars['UUID'];
};

/** The output of our `duplicateProject` mutation. */
export type DuplicateProjectPayload = {
  __typename?: 'DuplicateProjectPayload';
  /** Reads a single `Account` that is related to this `Project`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Customer` that is related to this `Project`. */
  customer: Maybe<Customer>;
  /** Reads a single `Project` that is related to this `Project`. */
  parent: Maybe<Project>;
  project: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge: Maybe<ProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Project`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our `duplicateProject` mutation. */
export type DuplicateProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the `editOrganizationCoachingTimeLog` mutation. */
export type EditOrganizationCoachingTimeLogInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  timeLog: OrganizationCoachingTimeLogPatch;
};

/** The output of our `editOrganizationCoachingTimeLog` mutation. */
export type EditOrganizationCoachingTimeLogPayload = {
  __typename?: 'EditOrganizationCoachingTimeLogPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against Email fields. All fields are combined with a logical ‘and.’ */
export type EmailFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Email']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['Email']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['Email']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['Email']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Email']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['Email']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Email']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['Email']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Email']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['Email']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Email']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['Email']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['Email']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['Email']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Email']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['Email']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Email']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['Email']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['Email']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['Email']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Email']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['Email']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['Email']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['Email']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Email']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['Email']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Email']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['Email']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['Email']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['Email']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['Email']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['Email']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['Email']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['Email']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['Email']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['Email']>;
};

/** Project event types */
export type EventType = Node & {
  __typename?: 'EventType';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ProjectEvent`. */
  projectEventsByEventType: ProjectEventsConnection;
  /** Reads and enables pagination through a set of `ProjectEvent`. */
  projectEventsByEventTypeList: Array<ProjectEvent>;
  /** Event type title */
  title: Scalars['String'];
  /** Event type weight */
  weight: Scalars['Int'];
};


/** Project event types */
export type EventTypeProjectEventsByEventTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};


/** Project event types */
export type EventTypeProjectEventsByEventTypeListArgs = {
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};

/**
 * A condition to be used against `EventType` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type EventTypeCondition = {
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']>;
};

/** The fields on `eventType` to look up the row to connect. */
export type EventTypeEventTypePkeyConnect = {
  /** Event type title */
  title: Scalars['String'];
};

/** The fields on `eventType` to look up the row to delete. */
export type EventTypeEventTypePkeyDelete = {
  /** Event type title */
  title: Scalars['String'];
};

/** A filter to be used against `EventType` object types. All fields are combined with a logical ‘and.’ */
export type EventTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<EventTypeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<EventTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<EventTypeFilter>>;
  /** Filter by the object’s `projectEventsByEventType` relation. */
  projectEventsByEventType?: InputMaybe<EventTypeToManyProjectEventFilter>;
  /** Some related `projectEventsByEventType` exist. */
  projectEventsByEventTypeExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `EventType` */
export type EventTypeInput = {
  projectEventsUsingTitle?: InputMaybe<ProjectEventEventTypeFkeyInverseInput>;
  /** Event type title */
  title: Scalars['String'];
  /** Event type weight */
  weight?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type EventTypeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `eventType` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type EventTypeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `eventType` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type EventTypeOnProjectEventForProjectEventEventTypeFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: ProjectEventPatch;
};

/** The fields on `eventType` to look up the row to update. */
export type EventTypeOnProjectEventForProjectEventEventTypeFkeyUsingEventTypePkeyUpdate = {
  /** An object where the defined keys will be set on the `eventType` being updated. */
  patch: UpdateEventTypeOnProjectEventForProjectEventEventTypeFkeyPatch;
  /** Event type title */
  title: Scalars['String'];
};

/** Represents an update to a `EventType`. Fields that are set will be updated. */
export type EventTypePatch = {
  projectEventsUsingTitle?: InputMaybe<ProjectEventEventTypeFkeyInverseInput>;
  /** Event type title */
  title?: InputMaybe<Scalars['String']>;
  /** Event type weight */
  weight?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against many `ProjectEvent` object types. All fields are combined with a logical ‘and.’ */
export type EventTypeToManyProjectEventFilter = {
  /** Every related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectEventFilter>;
  /** No related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectEventFilter>;
  /** Some related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectEventFilter>;
};

/** A connection to a list of `EventType` values. */
export type EventTypesConnection = {
  __typename?: 'EventTypesConnection';
  /** A list of edges which contains the `EventType` and cursor to aid in pagination. */
  edges: Array<EventTypesEdge>;
  /** A list of `EventType` objects. */
  nodes: Array<EventType>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EventType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EventType` edge in the connection. */
export type EventTypesEdge = {
  __typename?: 'EventTypesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `EventType` at the end of the edge. */
  node: EventType;
};

/** Methods to use when ordering `EventType`. */
export enum EventTypesOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC',
  WeightAsc = 'WEIGHT_ASC',
  WeightDesc = 'WEIGHT_DESC'
}

/** All input for the `exportUsersCsv` mutation. */
export type ExportUsersCsvInput = {
  accountId?: InputMaybe<Scalars['UUID']>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `exportUsersCsv` mutation. */
export type ExportUsersCsvPayload = {
  __typename?: 'ExportUsersCsvPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  password: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The `currentProjectOfferVariable` to be created by this mutation. */
export type FakeNptCurrentProjectOfferVariableForeignKey0CurrentProjectOfferVariableCreateInput = {
  amount?: InputMaybe<Scalars['BigFloat']>;
  isAligned?: InputMaybe<Scalars['Boolean']>;
  ourSelected?: InputMaybe<Scalars['String']>;
  theirSelected?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `currentProjectOfferVariable` in the `ProjectVariableInput` mutation. */
export type FakeNptCurrentProjectOfferVariableForeignKey0InverseInput = {
  /** A `CurrentProjectOfferVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<FakeNptCurrentProjectOfferVariableForeignKey0CurrentProjectOfferVariableCreateInput>>;
};

/** Feedback questions */
export type FeedbackQuestion = Node & {
  __typename?: 'FeedbackQuestion';
  id: Scalars['UUID'];
  /** The number of the question in our internal mapping, used mainly for backwads sync with v1 */
  internalNumber: Maybe<Scalars['Int']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The number of the question as it appears for the user, lower number goes first */
  number: Maybe<Scalars['Int']>;
  /** The text of the question */
  question: Maybe<Scalars['String']>;
  /** What is the type of this particular question */
  questionType: FeedbackQuestionType;
  /** Is the question required or not */
  required: Maybe<Scalars['Boolean']>;
};

/** Workshop Feedback Answers */
export type FeedbackQuestionAnswer = Node & {
  __typename?: 'FeedbackQuestionAnswer';
  /** The question this answer is for */
  feedbackQuestionId: Scalars['UUID'];
  /** The response this answer is part of */
  feedbackResponseId: Scalars['UUID'];
  /** Id of this answer */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Should be used for questions of type Numerical5 and Numerical10 */
  numerical: Maybe<Scalars['Int']>;
  /** Should be used for questions of type Open */
  open: Maybe<Scalars['String']>;
  /** Should be used for questions of type YesNo */
  yesNo: Maybe<YesNoOption>;
};

/**
 * A condition to be used against `FeedbackQuestionAnswer` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type FeedbackQuestionAnswerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `FeedbackQuestionAnswer` object types. All fields are combined with a logical ‘and.’ */
export type FeedbackQuestionAnswerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedbackQuestionAnswerFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedbackQuestionAnswerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedbackQuestionAnswerFilter>>;
};

/** A connection to a list of `FeedbackQuestionAnswer` values. */
export type FeedbackQuestionAnswersConnection = {
  __typename?: 'FeedbackQuestionAnswersConnection';
  /** A list of edges which contains the `FeedbackQuestionAnswer` and cursor to aid in pagination. */
  edges: Array<FeedbackQuestionAnswersEdge>;
  /** A list of `FeedbackQuestionAnswer` objects. */
  nodes: Array<FeedbackQuestionAnswer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedbackQuestionAnswer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeedbackQuestionAnswer` edge in the connection. */
export type FeedbackQuestionAnswersEdge = {
  __typename?: 'FeedbackQuestionAnswersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `FeedbackQuestionAnswer` at the end of the edge. */
  node: FeedbackQuestionAnswer;
};

/** Methods to use when ordering `FeedbackQuestionAnswer`. */
export enum FeedbackQuestionAnswersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `FeedbackQuestion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedbackQuestionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `internalNumber` field. */
  internalNumber?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `number` field. */
  number?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `FeedbackQuestion` object types. All fields are combined with a logical ‘and.’ */
export type FeedbackQuestionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedbackQuestionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `internalNumber` field. */
  internalNumber?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedbackQuestionFilter>;
  /** Filter by the object’s `number` field. */
  number?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedbackQuestionFilter>>;
};

/** An input for mutations affecting `FeedbackQuestion` */
export type FeedbackQuestionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The number of the question in our internal mapping, used mainly for backwads sync with v1 */
  internalNumber?: InputMaybe<Scalars['Int']>;
  /** The number of the question as it appears for the user, lower number goes first */
  number?: InputMaybe<Scalars['Int']>;
  /** The text of the question */
  question?: InputMaybe<Scalars['String']>;
  /** What is the type of this particular question */
  questionType: FeedbackQuestionType;
  /** Is the question required or not */
  required?: InputMaybe<Scalars['Boolean']>;
};

/** Represents an update to a `FeedbackQuestion`. Fields that are set will be updated. */
export type FeedbackQuestionPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The number of the question in our internal mapping, used mainly for backwads sync with v1 */
  internalNumber?: InputMaybe<Scalars['Int']>;
  /** The number of the question as it appears for the user, lower number goes first */
  number?: InputMaybe<Scalars['Int']>;
  /** The text of the question */
  question?: InputMaybe<Scalars['String']>;
  /** What is the type of this particular question */
  questionType?: InputMaybe<FeedbackQuestionType>;
  /** Is the question required or not */
  required?: InputMaybe<Scalars['Boolean']>;
};

/** Holds the different types of questions a Workshop Feedback can have */
export enum FeedbackQuestionType {
  Numerical5 = 'NUMERICAL5',
  Numerical10 = 'NUMERICAL10',
  Open = 'OPEN',
  YesNo = 'YES_NO'
}

/** A connection to a list of `FeedbackQuestion` values. */
export type FeedbackQuestionsConnection = {
  __typename?: 'FeedbackQuestionsConnection';
  /** A list of edges which contains the `FeedbackQuestion` and cursor to aid in pagination. */
  edges: Array<FeedbackQuestionsEdge>;
  /** A list of `FeedbackQuestion` objects. */
  nodes: Array<FeedbackQuestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedbackQuestion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeedbackQuestion` edge in the connection. */
export type FeedbackQuestionsEdge = {
  __typename?: 'FeedbackQuestionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `FeedbackQuestion` at the end of the edge. */
  node: FeedbackQuestion;
};

/** Methods to use when ordering `FeedbackQuestion`. */
export enum FeedbackQuestionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalNumberAsc = 'INTERNAL_NUMBER_ASC',
  InternalNumberDesc = 'INTERNAL_NUMBER_DESC',
  Natural = 'NATURAL',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Workshop Feedback responses from participants */
export type FeedbackResponse = Node & {
  __typename?: 'FeedbackResponse';
  /** When was this response created */
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** When was this response last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `FeedbackResponse`. */
  user: Maybe<User>;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
  /** The corresponding id to v1 workshops. Used to validate workshop participation */
  v1WorkshopId: Maybe<Scalars['Int']>;
};

/** An input for mutations affecting `FeedbackResponseAnswer` */
export type FeedbackResponseAnswerInput = {
  feedbackQuestionId?: InputMaybe<Scalars['UUID']>;
  numerical?: InputMaybe<Scalars['Int']>;
  open?: InputMaybe<Scalars['String']>;
  yesNo?: InputMaybe<YesNoOption>;
};

/**
 * A condition to be used against `FeedbackResponse` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type FeedbackResponseCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `v1WorkshopId` field. */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** The fields on `feedbackResponse` to look up the row to connect. */
export type FeedbackResponseFeedbackResponsePkeyConnect = {
  id: Scalars['UUID'];
};

/** A filter to be used against `FeedbackResponse` object types. All fields are combined with a logical ‘and.’ */
export type FeedbackResponseFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<FeedbackResponseFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<FeedbackResponseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<FeedbackResponseFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `v1WorkshopId` field. */
  v1WorkshopId?: InputMaybe<IntFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type FeedbackResponseNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `feedbackResponse` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `feedbackResponse` to look up the row to update. */
export type FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingFeedbackResponsePkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `feedbackResponse` being updated. */
  patch: UpdateFeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch;
};

/** The fields on `feedbackResponse` to look up the row to update. */
export type FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUniqFeedbackResponseUpdate = {
  /** An object where the defined keys will be set on the `feedbackResponse` being updated. */
  patch: UpdateFeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
  /** The corresponding id to v1 workshops. Used to validate workshop participation */
  v1WorkshopId: Scalars['Int'];
};

/** Represents an update to a `FeedbackResponse`. Fields that are set will be updated. */
export type FeedbackResponsePatch = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The user that submitted the response, can only be the current logged-in user */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<FeedbackResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate workshop participation */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** The fields on `feedbackResponse` to look up the row to connect. */
export type FeedbackResponseUniqFeedbackResponseConnect = {
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
  /** The corresponding id to v1 workshops. Used to validate workshop participation */
  v1WorkshopId: Scalars['Int'];
};

/** Input for the nested mutation of `user` in the `FeedbackResponseInput` mutation. */
export type FeedbackResponseUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `feedbackResponse` in the `UserInput` mutation. */
export type FeedbackResponseUserIdFkeyInverseInput = {
  /** The primary key(s) for `feedbackResponse` for the far side of the relationship. */
  connectById?: InputMaybe<Array<FeedbackResponseFeedbackResponsePkeyConnect>>;
  /** The primary key(s) for `feedbackResponse` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<FeedbackResponseNodeIdConnect>>;
  /** The primary key(s) for `feedbackResponse` for the far side of the relationship. */
  connectByV1WorkshopIdAndUserId?: InputMaybe<Array<FeedbackResponseUniqFeedbackResponseConnect>>;
  /** The primary key(s) and patch data for `feedbackResponse` for the far side of the relationship. */
  updateById?: InputMaybe<Array<FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingFeedbackResponsePkeyUpdate>>;
  /** The primary key(s) and patch data for `feedbackResponse` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnFeedbackResponseForFeedbackResponseUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `feedbackResponse` for the far side of the relationship. */
  updateByV1WorkshopIdAndUserId?: InputMaybe<Array<FeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUniqFeedbackResponseUpdate>>;
};

/** A connection to a list of `FeedbackResponse` values. */
export type FeedbackResponsesConnection = {
  __typename?: 'FeedbackResponsesConnection';
  /** A list of edges which contains the `FeedbackResponse` and cursor to aid in pagination. */
  edges: Array<FeedbackResponsesEdge>;
  /** A list of `FeedbackResponse` objects. */
  nodes: Array<FeedbackResponse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `FeedbackResponse` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `FeedbackResponse` edge in the connection. */
export type FeedbackResponsesEdge = {
  __typename?: 'FeedbackResponsesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `FeedbackResponse` at the end of the edge. */
  node: FeedbackResponse;
};

/** Methods to use when ordering `FeedbackResponse`. */
export enum FeedbackResponsesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  V1WorkshopIdAsc = 'V1_WORKSHOP_ID_ASC',
  V1WorkshopIdDesc = 'V1_WORKSHOP_ID_DESC'
}

/** All input for the `forgotPassword` mutation. */
export type ForgotPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
};

/** The output of our `forgotPassword` mutation. */
export type ForgotPasswordPayload = {
  __typename?: 'ForgotPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Form submissions from the forgotten email screen */
export type ForgottenEmailSubmission = Node & {
  __typename?: 'ForgottenEmailSubmission';
  /** When was this entry created? */
  createdAt: Scalars['Datetime'];
  /** Submission ID */
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Submission payload */
  payload: Maybe<Scalars['JSON']>;
  /** When was this entry last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ForgottenEmailSubmission` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ForgottenEmailSubmissionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
};

/** A filter to be used against `ForgottenEmailSubmission` object types. All fields are combined with a logical ‘and.’ */
export type ForgottenEmailSubmissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ForgottenEmailSubmissionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ForgottenEmailSubmissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ForgottenEmailSubmissionFilter>>;
};

/** An input for mutations affecting `ForgottenEmailSubmission` */
export type ForgottenEmailSubmissionInput = {
  /** When was this entry created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Submission ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Submission payload */
  payload?: InputMaybe<Scalars['JSON']>;
  /** When was this entry last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ForgottenEmailSubmission` values. */
export type ForgottenEmailSubmissionsConnection = {
  __typename?: 'ForgottenEmailSubmissionsConnection';
  /** A list of edges which contains the `ForgottenEmailSubmission` and cursor to aid in pagination. */
  edges: Array<ForgottenEmailSubmissionsEdge>;
  /** A list of `ForgottenEmailSubmission` objects. */
  nodes: Array<ForgottenEmailSubmission>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ForgottenEmailSubmission` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ForgottenEmailSubmission` edge in the connection. */
export type ForgottenEmailSubmissionsEdge = {
  __typename?: 'ForgottenEmailSubmissionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ForgottenEmailSubmission` at the end of the edge. */
  node: ForgottenEmailSubmission;
};

/** Methods to use when ordering `ForgottenEmailSubmission`. */
export enum ForgottenEmailSubmissionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against FullText fields. All fields are combined with a logical ‘and.’ */
export type FullTextFilter = {
  /** Performs a full text search on the field. */
  matches?: InputMaybe<Scalars['String']>;
};

/** All input for the `gameLv1SaveState` mutation. */
export type GameLv1SaveStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  state: Scalars['JSON'];
};

/** The output of our `gameLv1SaveState` mutation. */
export type GameLv1SaveStatePayload = {
  __typename?: 'GameLv1SaveStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  lv1GameState: Maybe<Lv1GameState>;
  /** An edge for our `Lv1GameState`. May be used by Relay 1. */
  lv1GameStateEdge: Maybe<Lv1GameStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameState`. */
  user: Maybe<User>;
};


/** The output of our `gameLv1SaveState` mutation. */
export type GameLv1SaveStatePayloadLv1GameStateEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameStatesOrderBy>>;
};

/** Input for the nested mutation of `user` in the `Lv1GameScoreInput` mutation. */
export type GameLv1ScoreUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameScore` in the `UserInput` mutation. */
export type GameLv1ScoreUserIdFkeyInverseInput = {
  /** The primary key(s) for `lv1GameScore` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Lv1GameScoreNodeIdConnect>;
  /** The primary key(s) for `lv1GameScore` for the far side of the relationship. */
  connectByUserId?: InputMaybe<Lv1GameScoreGameLv1ScorePkeyConnect>;
  /** A `Lv1GameScoreInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GameLv1ScoreUserIdFkeyLv1GameScoreCreateInput>>;
  /** The primary key(s) for `lv1GameScore` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Lv1GameScoreNodeIdDelete>;
  /** The primary key(s) for `lv1GameScore` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<Lv1GameScoreGameLv1ScorePkeyDelete>;
  /** Flag indicating whether all other `lv1GameScore` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameScore` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `lv1GameScore` for the far side of the relationship. */
  updateByUserId?: InputMaybe<Lv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingGameLv1ScorePkeyUpdate>;
};

/** The `lv1GameScore` to be created by this mutation. */
export type GameLv1ScoreUserIdFkeyLv1GameScoreCreateInput = {
  /** When was this score added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The scores payload (module, scene, question, type, etc.) */
  payload: Scalars['JSON'];
  /** When was this score updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<GameLv1ScoreUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `Lv1GameStateInput` mutation. */
export type GameLv1StateUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnLv1GameStateForGameLv1StateUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnLv1GameStateForGameLv1StateUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameState` in the `UserInput` mutation. */
export type GameLv1StateUserIdFkeyInverseInput = {
  /** The primary key(s) for `lv1GameState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Lv1GameStateNodeIdConnect>;
  /** The primary key(s) for `lv1GameState` for the far side of the relationship. */
  connectByUserId?: InputMaybe<Lv1GameStateGameLv1StatePkeyConnect>;
  /** The primary key(s) for `lv1GameState` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Lv1GameStateNodeIdDelete>;
  /** The primary key(s) for `lv1GameState` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<Lv1GameStateGameLv1StatePkeyDelete>;
  /** Flag indicating whether all other `lv1GameState` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnLv1GameStateForGameLv1StateUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `lv1GameState` for the far side of the relationship. */
  updateByUserId?: InputMaybe<Lv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyUsingGameLv1StatePkeyUpdate>;
};

/** The `account` to be created by this mutation. */
export type GameLv1SubgroupAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `Lv1GameSubgroupInput` mutation. */
export type GameLv1SubgroupAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<GameLv1SubgroupAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameSubgroup` in the `AccountInput` mutation. */
export type GameLv1SubgroupAccountIdFkeyInverseInput = {
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<Lv1GameSubgroupGameLv1SubgroupPkeyConnect>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<Lv1GameSubgroupNodeIdConnect>>;
  /** A `Lv1GameSubgroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GameLv1SubgroupAccountIdFkeyLv1GameSubgroupCreateInput>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<Lv1GameSubgroupGameLv1SubgroupPkeyDelete>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<Lv1GameSubgroupNodeIdDelete>>;
  /** Flag indicating whether all other `lv1GameSubgroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateById?: InputMaybe<Array<Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingGameLv1SubgroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyNodeIdUpdate>>;
};

/** The `lv1GameSubgroup` to be created by this mutation. */
export type GameLv1SubgroupAccountIdFkeyLv1GameSubgroupCreateInput = {
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `Lv1GameSubgroupInput` mutation. */
export type GameLv1SubgroupCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameSubgroup` in the `UserInput` mutation. */
export type GameLv1SubgroupCreatedByFkeyInverseInput = {
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectById?: InputMaybe<Array<Lv1GameSubgroupGameLv1SubgroupPkeyConnect>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<Lv1GameSubgroupNodeIdConnect>>;
  /** A `Lv1GameSubgroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GameLv1SubgroupCreatedByFkeyLv1GameSubgroupCreateInput>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<Lv1GameSubgroupGameLv1SubgroupPkeyDelete>>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<Lv1GameSubgroupNodeIdDelete>>;
  /** Flag indicating whether all other `lv1GameSubgroup` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateById?: InputMaybe<Array<Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingGameLv1SubgroupPkeyUpdate>>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyNodeIdUpdate>>;
};

/** The `lv1GameSubgroup` to be created by this mutation. */
export type GameLv1SubgroupCreatedByFkeyLv1GameSubgroupCreateInput = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** To be used in filters for by all the possible user genders */
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

/** All input for the `generateTempLink` mutation. */
export type GenerateTempLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  referenceId: Scalars['UUID'];
  referenceType: Scalars['String'];
};

/** The output of our `generateTempLink` mutation. */
export type GenerateTempLinkPayload = {
  __typename?: 'GenerateTempLinkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  token: Maybe<Scalars['String']>;
};

/** Information for calling Google APIs on behalf of user */
export type GoogleAccountUser = Node & {
  __typename?: 'GoogleAccountUser';
  /** Access token is a short-lived per user credential issued by Google that is used to securely call Google APIs and access user data. */
  accessToken: Maybe<Scalars['String']>;
  /** The timestamp for the expiration of the access token. */
  accessTokenExpiryDateUnixTimestamp: Maybe<Scalars['BigInt']>;
  /** Auth code is a temporary code issued by Google to securely identify individual users who sign-in to their Google Account from a browser. Your backend platform exchanges this code for access and refresh tokens. */
  authCode: Scalars['String'];
  /** The primary Google email of the user account */
  email: Maybe<Scalars['String']>;
  /** Google Account User ID */
  id: Scalars['UUID'];
  /** JSON Web Tokens (JWTs) that conform to the OpenID Connect (OIDC) specification. */
  idToken: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Refresh token is a long-lived per user credential issued by Google that is securely stored on your platform and can be used to obtain a new, valid access token even when the user is not present. */
  refreshToken: Maybe<Scalars['String']>;
  /** String with all the OAuth scopes. Scopes express the permissions you request users to authorize for your app and allow your project to access specific types of private user data from their Google Account. */
  scope: Maybe<Scalars['String']>;
  /** String with the type of token. Usually "Bearer". */
  tokenType: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `GoogleAccountUser`. */
  user: Maybe<User>;
  /** Related User ID */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `GoogleAccountUser` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GoogleAccountUserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `GoogleAccountUser` object types. All fields are combined with a logical ‘and.’ */
export type GoogleAccountUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GoogleAccountUserFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GoogleAccountUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GoogleAccountUserFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `googleAccountUser` to look up the row to connect. */
export type GoogleAccountUserGoogleAccountUserPkeyConnect = {
  /** Google Account User ID */
  id: Scalars['UUID'];
};

/** The fields on `googleAccountUser` to look up the row to delete. */
export type GoogleAccountUserGoogleAccountUserPkeyDelete = {
  /** Google Account User ID */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GoogleAccountUser` */
export type GoogleAccountUserInput = {
  /** Access token is a short-lived per user credential issued by Google that is used to securely call Google APIs and access user data. */
  accessToken?: InputMaybe<Scalars['String']>;
  /** The timestamp for the expiration of the access token. */
  accessTokenExpiryDateUnixTimestamp?: InputMaybe<Scalars['BigInt']>;
  /** Auth code is a temporary code issued by Google to securely identify individual users who sign-in to their Google Account from a browser. Your backend platform exchanges this code for access and refresh tokens. */
  authCode: Scalars['String'];
  /** The primary Google email of the user account */
  email?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** JSON Web Tokens (JWTs) that conform to the OpenID Connect (OIDC) specification. */
  idToken?: InputMaybe<Scalars['String']>;
  /** Refresh token is a long-lived per user credential issued by Google that is securely stored on your platform and can be used to obtain a new, valid access token even when the user is not present. */
  refreshToken?: InputMaybe<Scalars['String']>;
  /** String with all the OAuth scopes. Scopes express the permissions you request users to authorize for your app and allow your project to access specific types of private user data from their Google Account. */
  scope?: InputMaybe<Scalars['String']>;
  /** String with the type of token. Usually "Bearer". */
  tokenType?: InputMaybe<Scalars['String']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleAccountUserUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GoogleAccountUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googleAccountUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GoogleAccountUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googleAccountUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `googleAccountUser` to look up the row to update. */
export type GoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingGoogleAccountUserPkeyUpdate = {
  /** Google Account User ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `googleAccountUser` being updated. */
  patch: UpdateGoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyPatch;
};

/** Represents an update to a `GoogleAccountUser`. Fields that are set will be updated. */
export type GoogleAccountUserPatch = {
  /** Access token is a short-lived per user credential issued by Google that is used to securely call Google APIs and access user data. */
  accessToken?: InputMaybe<Scalars['String']>;
  /** The timestamp for the expiration of the access token. */
  accessTokenExpiryDateUnixTimestamp?: InputMaybe<Scalars['BigInt']>;
  /** Auth code is a temporary code issued by Google to securely identify individual users who sign-in to their Google Account from a browser. Your backend platform exchanges this code for access and refresh tokens. */
  authCode?: InputMaybe<Scalars['String']>;
  /** The primary Google email of the user account */
  email?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** JSON Web Tokens (JWTs) that conform to the OpenID Connect (OIDC) specification. */
  idToken?: InputMaybe<Scalars['String']>;
  /** Refresh token is a long-lived per user credential issued by Google that is securely stored on your platform and can be used to obtain a new, valid access token even when the user is not present. */
  refreshToken?: InputMaybe<Scalars['String']>;
  /** String with all the OAuth scopes. Scopes express the permissions you request users to authorize for your app and allow your project to access specific types of private user data from their Google Account. */
  scope?: InputMaybe<Scalars['String']>;
  /** String with the type of token. Usually "Bearer". */
  tokenType?: InputMaybe<Scalars['String']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleAccountUserUserIdFkeyInput>;
};

/** The `googleAccountUser` to be created by this mutation. */
export type GoogleAccountUserUserIdFkeyGoogleAccountUserCreateInput = {
  /** Access token is a short-lived per user credential issued by Google that is used to securely call Google APIs and access user data. */
  accessToken?: InputMaybe<Scalars['String']>;
  /** The timestamp for the expiration of the access token. */
  accessTokenExpiryDateUnixTimestamp?: InputMaybe<Scalars['BigInt']>;
  /** Auth code is a temporary code issued by Google to securely identify individual users who sign-in to their Google Account from a browser. Your backend platform exchanges this code for access and refresh tokens. */
  authCode: Scalars['String'];
  /** The primary Google email of the user account */
  email?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** JSON Web Tokens (JWTs) that conform to the OpenID Connect (OIDC) specification. */
  idToken?: InputMaybe<Scalars['String']>;
  /** Refresh token is a long-lived per user credential issued by Google that is securely stored on your platform and can be used to obtain a new, valid access token even when the user is not present. */
  refreshToken?: InputMaybe<Scalars['String']>;
  /** String with all the OAuth scopes. Scopes express the permissions you request users to authorize for your app and allow your project to access specific types of private user data from their Google Account. */
  scope?: InputMaybe<Scalars['String']>;
  /** String with the type of token. Usually "Bearer". */
  tokenType?: InputMaybe<Scalars['String']>;
  userToUserId?: InputMaybe<GoogleAccountUserUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `GoogleAccountUserInput` mutation. */
export type GoogleAccountUserUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<GoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `googleAccountUser` in the `UserInput` mutation. */
export type GoogleAccountUserUserIdFkeyInverseInput = {
  /** The primary key(s) for `googleAccountUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<GoogleAccountUserGoogleAccountUserPkeyConnect>>;
  /** The primary key(s) for `googleAccountUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<GoogleAccountUserNodeIdConnect>>;
  /** A `GoogleAccountUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GoogleAccountUserUserIdFkeyGoogleAccountUserCreateInput>>;
  /** The primary key(s) for `googleAccountUser` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<GoogleAccountUserGoogleAccountUserPkeyDelete>>;
  /** The primary key(s) for `googleAccountUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<GoogleAccountUserNodeIdDelete>>;
  /** Flag indicating whether all other `googleAccountUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `googleAccountUser` for the far side of the relationship. */
  updateById?: InputMaybe<Array<GoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingGoogleAccountUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleAccountUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `GoogleAccountUser` values. */
export type GoogleAccountUsersConnection = {
  __typename?: 'GoogleAccountUsersConnection';
  /** A list of edges which contains the `GoogleAccountUser` and cursor to aid in pagination. */
  edges: Array<GoogleAccountUsersEdge>;
  /** A list of `GoogleAccountUser` objects. */
  nodes: Array<GoogleAccountUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleAccountUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleAccountUser` edge in the connection. */
export type GoogleAccountUsersEdge = {
  __typename?: 'GoogleAccountUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `GoogleAccountUser` at the end of the edge. */
  node: GoogleAccountUser;
};

/** Methods to use when ordering `GoogleAccountUser`. */
export enum GoogleAccountUsersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Connects a npt.project_event with a Google Calendar Event */
export type GoogleCalendarEvent = Node & {
  __typename?: 'GoogleCalendarEvent';
  /** Reads a single `ProjectEvent` that is related to this `GoogleCalendarEvent`. */
  event: Maybe<ProjectEvent>;
  /** Related npt.project_event ID */
  eventId: Scalars['UUID'];
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId: Maybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId: Maybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId: Maybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink: Maybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `User` that is related to this `GoogleCalendarEvent`. */
  user: Maybe<User>;
  /** Related User ID */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `GoogleCalendarEvent` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type GoogleCalendarEventCondition = {
  /** Checks for equality with the object’s `eventId` field. */
  eventId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** The `googleCalendarEvent` to be created by this mutation. */
export type GoogleCalendarEventEventIdFkeyGoogleCalendarEventCreateInput = {
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** Input for the nested mutation of `projectEvent` in the `GoogleCalendarEventInput` mutation. */
export type GoogleCalendarEventEventIdFkeyInput = {
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectEventProjectEventPkeyConnect>;
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectEventNodeIdConnect>;
  /** A `ProjectEventInput` object that will be created and connected to this object. */
  create?: InputMaybe<GoogleCalendarEventEventIdFkeyProjectEventCreateInput>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyUsingProjectEventPkeyUpdate>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `googleCalendarEvent` in the `ProjectEventInput` mutation. */
export type GoogleCalendarEventEventIdFkeyInverseInput = {
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<GoogleCalendarEventGoogleCalendarEventPkeyConnect>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<GoogleCalendarEventNodeIdConnect>>;
  /** A `GoogleCalendarEventInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GoogleCalendarEventEventIdFkeyGoogleCalendarEventCreateInput>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<GoogleCalendarEventGoogleCalendarEventPkeyDelete>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<GoogleCalendarEventNodeIdDelete>>;
  /** Flag indicating whether all other `googleCalendarEvent` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `googleCalendarEvent` for the far side of the relationship. */
  updateById?: InputMaybe<Array<GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyUsingGoogleCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarEvent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyNodeIdUpdate>>;
};

/** The `projectEvent` to be created by this mutation. */
export type GoogleCalendarEventEventIdFkeyProjectEventCreateInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime: Scalars['Datetime'];
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime: Scalars['Datetime'];
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `GoogleCalendarEvent` object types. All fields are combined with a logical ‘and.’ */
export type GoogleCalendarEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GoogleCalendarEventFilter>>;
  /** Filter by the object’s `event` relation. */
  event?: InputMaybe<ProjectEventFilter>;
  /** Filter by the object’s `eventId` field. */
  eventId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GoogleCalendarEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GoogleCalendarEventFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `googleCalendarEvent` to look up the row to connect. */
export type GoogleCalendarEventGoogleCalendarEventPkeyConnect = {
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
};

/** The fields on `googleCalendarEvent` to look up the row to delete. */
export type GoogleCalendarEventGoogleCalendarEventPkeyDelete = {
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `GoogleCalendarEvent` */
export type GoogleCalendarEventInput = {
  /** Related npt.project_event ID */
  eventId?: InputMaybe<Scalars['UUID']>;
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type GoogleCalendarEventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `googleCalendarEvent` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type GoogleCalendarEventNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `googleCalendarEvent` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: ProjectEventPatch;
};

/** The fields on `googleCalendarEvent` to look up the row to update. */
export type GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyUsingGoogleCalendarEventPkeyUpdate = {
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
  patch: UpdateGoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `googleCalendarEvent` to look up the row to update. */
export type GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingGoogleCalendarEventPkeyUpdate = {
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
  patch: UpdateGoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyPatch;
};

/** Represents an update to a `GoogleCalendarEvent`. Fields that are set will be updated. */
export type GoogleCalendarEventPatch = {
  /** Related npt.project_event ID */
  eventId?: InputMaybe<Scalars['UUID']>;
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** The `googleCalendarEvent` to be created by this mutation. */
export type GoogleCalendarEventUserIdFkeyGoogleCalendarEventCreateInput = {
  /** Related npt.project_event ID */
  eventId?: InputMaybe<Scalars['UUID']>;
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** Input for the nested mutation of `user` in the `GoogleCalendarEventInput` mutation. */
export type GoogleCalendarEventUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `googleCalendarEvent` in the `UserInput` mutation. */
export type GoogleCalendarEventUserIdFkeyInverseInput = {
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<GoogleCalendarEventGoogleCalendarEventPkeyConnect>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<GoogleCalendarEventNodeIdConnect>>;
  /** A `GoogleCalendarEventInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<GoogleCalendarEventUserIdFkeyGoogleCalendarEventCreateInput>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<GoogleCalendarEventGoogleCalendarEventPkeyDelete>>;
  /** The primary key(s) for `googleCalendarEvent` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<GoogleCalendarEventNodeIdDelete>>;
  /** Flag indicating whether all other `googleCalendarEvent` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `googleCalendarEvent` for the far side of the relationship. */
  updateById?: InputMaybe<Array<GoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingGoogleCalendarEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `googleCalendarEvent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyNodeIdUpdate>>;
};

/** A connection to a list of `GoogleCalendarEvent` values. */
export type GoogleCalendarEventsConnection = {
  __typename?: 'GoogleCalendarEventsConnection';
  /** A list of edges which contains the `GoogleCalendarEvent` and cursor to aid in pagination. */
  edges: Array<GoogleCalendarEventsEdge>;
  /** A list of `GoogleCalendarEvent` objects. */
  nodes: Array<GoogleCalendarEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GoogleCalendarEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GoogleCalendarEvent` edge in the connection. */
export type GoogleCalendarEventsEdge = {
  __typename?: 'GoogleCalendarEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `GoogleCalendarEvent` at the end of the edge. */
  node: GoogleCalendarEvent;
};

/** Methods to use when ordering `GoogleCalendarEvent`. */
export enum GoogleCalendarEventsOrderBy {
  EventIdAsc = 'EVENT_ID_ASC',
  EventIdDesc = 'EVENT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** All input for the `impersonateUser` mutation. */
export type ImpersonateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** The output of our `impersonateUser` mutation. */
export type ImpersonateUserPayload = {
  __typename?: 'ImpersonateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Indicates whether archived items should be included in the results or not. */
export enum IncludeArchivedOption {
  /** Only include archived items (i.e. exclude non-archived items). */
  Exclusively = 'EXCLUSIVELY',
  /** If there is a parent GraphQL record and it is archived then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = 'INHERIT',
  /** Exclude archived items. */
  No = 'NO',
  /** Include archived items. */
  Yes = 'YES'
}

/** Indicates whether deleted items should be included in the results or not. */
export enum IncludeDeletedOption {
  /** Only include deleted items (i.e. exclude non-deleted items). */
  Exclusively = 'EXCLUSIVELY',
  /** If there is a parent GraphQL record and it is deleted then this is equivalent to YES, in all other cases this is equivalent to NO. */
  Inherit = 'INHERIT',
  /** Exclude deleted items. */
  No = 'NO',
  /** Include deleted items. */
  Yes = 'YES'
}

/** A connection to a list of `Industry` values. */
export type IndustriesConnection = {
  __typename?: 'IndustriesConnection';
  /** A list of edges which contains the `Industry` and cursor to aid in pagination. */
  edges: Array<IndustriesEdge>;
  /** A list of `Industry` objects. */
  nodes: Array<Industry>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Industry` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Industry` edge in the connection. */
export type IndustriesEdge = {
  __typename?: 'IndustriesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Industry` at the end of the edge. */
  node: Industry;
};

/** Methods to use when ordering `Industry`. */
export enum IndustriesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Industry = Node & {
  __typename?: 'Industry';
  id: Scalars['UUID'];
  /** The name of the Industry */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The ID of the Industry in v1 */
  v1Id: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `Industry` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type IndustryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `Industry` object types. All fields are combined with a logical ‘and.’ */
export type IndustryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<IndustryFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<IndustryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<IndustryFilter>>;
};

/** An input for mutations affecting `Industry` */
export type IndustryInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the Industry */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the Industry in v1 */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `Industry`. Fields that are set will be updated. */
export type IndustryPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the Industry */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the Industry in v1 */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

/** All input for the `inviteToProject` mutation. */
export type InviteToProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  invitedUsers: Array<InputMaybe<ProjectInvitedMemberInput>>;
  message?: InputMaybe<Scalars['String']>;
  projectId: Scalars['UUID'];
  sendNotification?: InputMaybe<Scalars['Boolean']>;
};

/** The output of our `inviteToProject` mutation. */
export type InviteToProjectPayload = {
  __typename?: 'InviteToProjectPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Lv1 video game scores per user */
export type Lv1GameScore = Node & {
  __typename?: 'Lv1GameScore';
  /** When was this score added? */
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The scores payload (module, scene, question, type, etc.) */
  payload: Scalars['JSON'];
  /** When was this score updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Lv1GameScore`. */
  user: Maybe<User>;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `Lv1GameScore` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Lv1GameScoreCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Lv1GameScore` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Lv1GameScoreFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<Lv1GameScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Lv1GameScoreFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `lv1GameScore` to look up the row to connect. */
export type Lv1GameScoreGameLv1ScorePkeyConnect = {
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The fields on `lv1GameScore` to look up the row to delete. */
export type Lv1GameScoreGameLv1ScorePkeyDelete = {
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** An input for mutations affecting `Lv1GameScore` */
export type Lv1GameScoreInput = {
  /** When was this score added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The scores payload (module, scene, question, type, etc.) */
  payload: Scalars['JSON'];
  /** When was this score updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the player */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GameLv1ScoreUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type Lv1GameScoreNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lv1GameScore` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type Lv1GameScoreNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lv1GameScore` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `lv1GameScore` to look up the row to update. */
export type Lv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingGameLv1ScorePkeyUpdate = {
  /** An object where the defined keys will be set on the `lv1GameScore` being updated. */
  patch: UpdateLv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyPatch;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** Represents an update to a `Lv1GameScore`. Fields that are set will be updated. */
export type Lv1GameScorePatch = {
  /** When was this score added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The scores payload (module, scene, question, type, etc.) */
  payload?: InputMaybe<Scalars['JSON']>;
  /** When was this score updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the player */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GameLv1ScoreUserIdFkeyInput>;
};

/** A connection to a list of `Lv1GameScore` values. */
export type Lv1GameScoresConnection = {
  __typename?: 'Lv1GameScoresConnection';
  /** A list of edges which contains the `Lv1GameScore` and cursor to aid in pagination. */
  edges: Array<Lv1GameScoresEdge>;
  /** A list of `Lv1GameScore` objects. */
  nodes: Array<Lv1GameScore>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lv1GameScore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Lv1GameScore` edge in the connection. */
export type Lv1GameScoresEdge = {
  __typename?: 'Lv1GameScoresEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Lv1GameScore` at the end of the edge. */
  node: Lv1GameScore;
};

/** Methods to use when ordering `Lv1GameScore`. */
export enum Lv1GameScoresOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Lv1 video game state per user */
export type Lv1GameState = Node & {
  __typename?: 'Lv1GameState';
  /** When was the state added? */
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The game state payload */
  state: Scalars['JSON'];
  /** When was the state last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Lv1GameState`. */
  user: Maybe<User>;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `Lv1GameState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Lv1GameStateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Lv1GameState` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Lv1GameStateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Lv1GameStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Lv1GameStateFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The fields on `lv1GameState` to look up the row to connect. */
export type Lv1GameStateGameLv1StatePkeyConnect = {
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The fields on `lv1GameState` to look up the row to delete. */
export type Lv1GameStateGameLv1StatePkeyDelete = {
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type Lv1GameStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lv1GameState` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type Lv1GameStateNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lv1GameState` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `lv1GameState` to look up the row to update. */
export type Lv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyUsingGameLv1StatePkeyUpdate = {
  /** An object where the defined keys will be set on the `lv1GameState` being updated. */
  patch: UpdateLv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyPatch;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** Represents an update to a `Lv1GameState`. Fields that are set will be updated. */
export type Lv1GameStatePatch = {
  /** When was the state added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The game state payload */
  state?: InputMaybe<Scalars['JSON']>;
  /** When was the state last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the player */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GameLv1StateUserIdFkeyInput>;
};

/** A connection to a list of `Lv1GameState` values. */
export type Lv1GameStatesConnection = {
  __typename?: 'Lv1GameStatesConnection';
  /** A list of edges which contains the `Lv1GameState` and cursor to aid in pagination. */
  edges: Array<Lv1GameStatesEdge>;
  /** A list of `Lv1GameState` objects. */
  nodes: Array<Lv1GameState>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lv1GameState` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Lv1GameState` edge in the connection. */
export type Lv1GameStatesEdge = {
  __typename?: 'Lv1GameStatesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Lv1GameState` at the end of the edge. */
  node: Lv1GameState;
};

/** Methods to use when ordering `Lv1GameState`. */
export enum Lv1GameStatesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** LV1 game subgroups */
export type Lv1GameSubgroup = Node & {
  __typename?: 'Lv1GameSubgroup';
  /** Reads a single `Account` that is related to this `Lv1GameSubgroup`. */
  account: Maybe<Account>;
  /** Account this subgroup belongs to */
  accountId: Maybe<Scalars['UUID']>;
  adminNames: Maybe<Scalars['String']>;
  /** When was this record created */
  createdAt: Scalars['Datetime'];
  /** Who created this subgroup */
  createdBy: Maybe<Scalars['UUID']>;
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** Is this subgroup deleted */
  isDeleted: Scalars['Boolean'];
  /** Reads and enables pagination through a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsersByGameLv1SubgroupId: Lv1GameSubgroupUsersConnection;
  /** Reads and enables pagination through a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsersByGameLv1SubgroupIdList: Array<Lv1GameSubgroupUser>;
  /** Subgroup name */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** When was this record last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Lv1GameSubgroup`. */
  userByCreatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `User`. */
  usersByGameLv1SubgroupId: UsersConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByGameLv1SubgroupIdList: Array<User>;
};


/** LV1 game subgroups */
export type Lv1GameSubgroupLv1GameSubgroupUsersByGameLv1SubgroupIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** LV1 game subgroups */
export type Lv1GameSubgroupLv1GameSubgroupUsersByGameLv1SubgroupIdListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** LV1 game subgroups */
export type Lv1GameSubgroupUsersByGameLv1SubgroupIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** LV1 game subgroups */
export type Lv1GameSubgroupUsersByGameLv1SubgroupIdListArgs = {
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/**
 * A condition to be used against `Lv1GameSubgroup` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type Lv1GameSubgroupCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Lv1GameSubgroup` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameSubgroupFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `adminNames` field. */
  adminNames?: InputMaybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Lv1GameSubgroupFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `lv1GameSubgroupUsersByGameLv1SubgroupId` relation. */
  lv1GameSubgroupUsersByGameLv1SubgroupId?: InputMaybe<Lv1GameSubgroupToManyLv1GameSubgroupUserFilter>;
  /** Some related `lv1GameSubgroupUsersByGameLv1SubgroupId` exist. */
  lv1GameSubgroupUsersByGameLv1SubgroupIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Negates the expression. */
  not?: InputMaybe<Lv1GameSubgroupFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Lv1GameSubgroupFilter>>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `usersByGameLv1SubgroupId` relation. */
  usersByGameLv1SubgroupId?: InputMaybe<Lv1GameSubgroupToManyUserFilter>;
  /** Some related `usersByGameLv1SubgroupId` exist. */
  usersByGameLv1SubgroupIdExist?: InputMaybe<Scalars['Boolean']>;
};

/** The fields on `lv1GameSubgroup` to look up the row to connect. */
export type Lv1GameSubgroupGameLv1SubgroupPkeyConnect = {
  /** Subgroup ID */
  id: Scalars['UUID'];
};

/** The fields on `lv1GameSubgroup` to look up the row to delete. */
export type Lv1GameSubgroupGameLv1SubgroupPkeyDelete = {
  /** Subgroup ID */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `Lv1GameSubgroup` */
export type Lv1GameSubgroupInput = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type Lv1GameSubgroupNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type Lv1GameSubgroupNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `lv1GameSubgroup` to look up the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyUsingGameLv1SubgroupPkeyUpdate = {
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: UpdateLv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `lv1GameSubgroup` to look up the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingGameLv1SubgroupPkeyUpdate = {
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: UpdateLv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroupUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
  patch: Lv1GameSubgroupUserPatch;
};

/** The fields on `lv1GameSubgroup` to look up the row to update. */
export type Lv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyUsingGameLv1SubgroupPkeyUpdate = {
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: UpdateLv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `lv1GameSubgroup` to look up the row to update. */
export type Lv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyUsingGameLv1SubgroupPkeyUpdate = {
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: UpdateLv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyPatch;
};

/** Represents an update to a `Lv1GameSubgroup`. Fields that are set will be updated. */
export type Lv1GameSubgroupPatch = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** A filter to be used against many `Lv1GameSubgroupUser` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameSubgroupToManyLv1GameSubgroupUserFilter = {
  /** Every related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<Lv1GameSubgroupUserFilter>;
  /** No related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<Lv1GameSubgroupUserFilter>;
  /** Some related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<Lv1GameSubgroupUserFilter>;
};

/** A filter to be used against many `User` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameSubgroupToManyUserFilter = {
  /** Every related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserFilter>;
  /** No related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserFilter>;
  /** Some related `User` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserFilter>;
};

/** Relation between users and the subgroups they administer */
export type Lv1GameSubgroupUser = Node & {
  __typename?: 'Lv1GameSubgroupUser';
  /** When was this record created */
  createdAt: Scalars['Datetime'];
  /** Reads a single `Lv1GameSubgroup` that is related to this `Lv1GameSubgroupUser`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** When was this record last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Lv1GameSubgroupUser`. */
  user: Maybe<User>;
  /** User ID */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `Lv1GameSubgroupUser` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type Lv1GameSubgroupUserCondition = {
  /** Checks for equality with the object’s `gameLv1SubgroupId` field. */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `Lv1GameSubgroupUser` object types. All fields are combined with a logical ‘and.’ */
export type Lv1GameSubgroupUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<Lv1GameSubgroupUserFilter>>;
  /** Filter by the object’s `gameLv1Subgroup` relation. */
  gameLv1Subgroup?: InputMaybe<Lv1GameSubgroupFilter>;
  /** Filter by the object’s `gameLv1SubgroupId` field. */
  gameLv1SubgroupId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<Lv1GameSubgroupUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<Lv1GameSubgroupUserFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `Lv1GameSubgroupUser` */
export type Lv1GameSubgroupUserInput = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type Lv1GameSubgroupUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroupUser` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type Lv1GameSubgroupUserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroupUser` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** The fields on `lv1GameSubgroupUser` to look up the row to update. */
export type Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyUsingUniqUserGameLv1SubgroupUpdate = {
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
  patch: UpdateLv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyPatch;
  /** User ID */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `lv1GameSubgroupUser` to look up the row to update. */
export type Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUniqUserGameLv1SubgroupUpdate = {
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
  patch: UpdateLv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyPatch;
  /** User ID */
  userId: Scalars['UUID'];
};

/** Represents an update to a `Lv1GameSubgroupUser`. Fields that are set will be updated. */
export type Lv1GameSubgroupUserPatch = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** The fields on `lv1GameSubgroupUser` to look up the row to connect. */
export type Lv1GameSubgroupUserUniqUserGameLv1SubgroupConnect = {
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The fields on `lv1GameSubgroupUser` to look up the row to delete. */
export type Lv1GameSubgroupUserUniqUserGameLv1SubgroupDelete = {
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** A connection to a list of `Lv1GameSubgroupUser` values. */
export type Lv1GameSubgroupUsersConnection = {
  __typename?: 'Lv1GameSubgroupUsersConnection';
  /** A list of edges which contains the `Lv1GameSubgroupUser` and cursor to aid in pagination. */
  edges: Array<Lv1GameSubgroupUsersEdge>;
  /** A list of `Lv1GameSubgroupUser` objects. */
  nodes: Array<Lv1GameSubgroupUser>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lv1GameSubgroupUser` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Lv1GameSubgroupUser` edge in the connection. */
export type Lv1GameSubgroupUsersEdge = {
  __typename?: 'Lv1GameSubgroupUsersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Lv1GameSubgroupUser` at the end of the edge. */
  node: Lv1GameSubgroupUser;
};

/** Methods to use when ordering `Lv1GameSubgroupUser`. */
export enum Lv1GameSubgroupUsersOrderBy {
  GameLv1SubgroupIdAsc = 'GAME_LV1_SUBGROUP_ID_ASC',
  GameLv1SubgroupIdDesc = 'GAME_LV1_SUBGROUP_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** A connection to a list of `Lv1GameSubgroup` values. */
export type Lv1GameSubgroupsConnection = {
  __typename?: 'Lv1GameSubgroupsConnection';
  /** A list of edges which contains the `Lv1GameSubgroup` and cursor to aid in pagination. */
  edges: Array<Lv1GameSubgroupsEdge>;
  /** A list of `Lv1GameSubgroup` objects. */
  nodes: Array<Lv1GameSubgroup>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lv1GameSubgroup` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Lv1GameSubgroup` edge in the connection. */
export type Lv1GameSubgroupsEdge = {
  __typename?: 'Lv1GameSubgroupsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Lv1GameSubgroup` at the end of the edge. */
  node: Lv1GameSubgroup;
};

/** Methods to use when ordering `Lv1GameSubgroup`. */
export enum Lv1GameSubgroupsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type MassUserUpload = Node & {
  __typename?: 'MassUserUpload';
  createdAt: Scalars['Datetime'];
  createdBy: Scalars['UUID'];
  /** The name of the file as input by the user, used by the FE only */
  filename: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  /** When the job has been processed this will be set to true */
  isProcessed: Scalars['Boolean'];
  log: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Path to a CSV file with user data */
  pathUrl: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `MassUserUpload`. */
  userByCreatedBy: Maybe<User>;
};

/**
 * A condition to be used against `MassUserUpload` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MassUserUploadCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `user` in the `MassUserUploadInput` mutation. */
export type MassUserUploadCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnMassUserUploadForMassUserUploadCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnMassUserUploadForMassUserUploadCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `massUserUpload` in the `UserInput` mutation. */
export type MassUserUploadCreatedByFkeyInverseInput = {
  /** The primary key(s) for `massUserUpload` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MassUserUploadMassUserUploadPkeyConnect>>;
  /** The primary key(s) for `massUserUpload` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MassUserUploadNodeIdConnect>>;
  /** A `MassUserUploadInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<MassUserUploadCreatedByFkeyMassUserUploadCreateInput>>;
  /** The primary key(s) for `massUserUpload` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<MassUserUploadMassUserUploadPkeyDelete>>;
  /** The primary key(s) for `massUserUpload` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<MassUserUploadNodeIdDelete>>;
  /** Flag indicating whether all other `massUserUpload` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `massUserUpload` for the far side of the relationship. */
  updateById?: InputMaybe<Array<MassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyUsingMassUserUploadPkeyUpdate>>;
  /** The primary key(s) and patch data for `massUserUpload` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnMassUserUploadForMassUserUploadCreatedByFkeyNodeIdUpdate>>;
};

/** The `massUserUpload` to be created by this mutation. */
export type MassUserUploadCreatedByFkeyMassUserUploadCreateInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The name of the file as input by the user, used by the FE only */
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** When the job has been processed this will be set to true */
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  log?: InputMaybe<Scalars['String']>;
  /** Path to a CSV file with user data */
  pathUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<MassUserUploadCreatedByFkeyInput>;
};

/** A filter to be used against `MassUserUpload` object types. All fields are combined with a logical ‘and.’ */
export type MassUserUploadFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MassUserUploadFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<MassUserUploadFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MassUserUploadFilter>>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
};

/** An input for mutations affecting `MassUserUpload` */
export type MassUserUploadInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** The name of the file as input by the user, used by the FE only */
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** When the job has been processed this will be set to true */
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  log?: InputMaybe<Scalars['String']>;
  /** Path to a CSV file with user data */
  pathUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<MassUserUploadCreatedByFkeyInput>;
};

/** The fields on `massUserUpload` to look up the row to connect. */
export type MassUserUploadMassUserUploadPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `massUserUpload` to look up the row to delete. */
export type MassUserUploadMassUserUploadPkeyDelete = {
  id: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to connect. */
export type MassUserUploadNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `massUserUpload` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type MassUserUploadNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `massUserUpload` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type MassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `massUserUpload` to look up the row to update. */
export type MassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyUsingMassUserUploadPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `massUserUpload` being updated. */
  patch: UpdateMassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyPatch;
};

/** Represents an update to a `MassUserUpload`. Fields that are set will be updated. */
export type MassUserUploadPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** The name of the file as input by the user, used by the FE only */
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** When the job has been processed this will be set to true */
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  log?: InputMaybe<Scalars['String']>;
  /** Path to a CSV file with user data */
  pathUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<MassUserUploadCreatedByFkeyInput>;
};

export type MassUserUploadSubscriptionPayload = {
  __typename?: 'MassUserUploadSubscriptionPayload';
  event: Maybe<Scalars['String']>;
  massUserUpload: Maybe<MassUserUpload>;
};

/** A connection to a list of `MassUserUpload` values. */
export type MassUserUploadsConnection = {
  __typename?: 'MassUserUploadsConnection';
  /** A list of edges which contains the `MassUserUpload` and cursor to aid in pagination. */
  edges: Array<MassUserUploadsEdge>;
  /** A list of `MassUserUpload` objects. */
  nodes: Array<MassUserUpload>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MassUserUpload` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MassUserUpload` edge in the connection. */
export type MassUserUploadsEdge = {
  __typename?: 'MassUserUploadsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `MassUserUpload` at the end of the edge. */
  node: MassUserUpload;
};

/** Methods to use when ordering `MassUserUpload`. */
export enum MassUserUploadsOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `moveProjectToFolder` mutation. */
export type MoveProjectToFolderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  folderId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};

/** The output of our `moveProjectToFolder` mutation. */
export type MoveProjectToFolderPayload = {
  __typename?: 'MoveProjectToFolderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectFolder`. */
  folder: Maybe<Project>;
  /** Reads a single `Project` that is related to this `ProjectFolder`. */
  project: Maybe<Project>;
  projectFolder: Maybe<ProjectFolder>;
  /** An edge for our `ProjectFolder`. May be used by Relay 1. */
  projectFolderEdge: Maybe<ProjectFoldersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectFolder`. */
  user: Maybe<User>;
};


/** The output of our `moveProjectToFolder` mutation. */
export type MoveProjectToFolderPayloadProjectFolderEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Adds time to an Organization. Removes any old (leftover) time they had before. Resets used, remaining, etc. */
  addOrganizationCoachingTime: Maybe<AddOrganizationCoachingTimePayload>;
  /** Adds a coaching time log entry to an Organization. */
  addOrganizationCoachingTimeLog: Maybe<AddOrganizationCoachingTimeLogPayload>;
  /** Adds the user to all of their account classes */
  addUserToAccountClasses: Maybe<AddUserToAccountClassesPayload>;
  batchProcessWorkshopFeedbackDataAndInsert: Maybe<BatchProcessWorkshopFeedbackDataAndInsertPayload>;
  /** Update your password. Provide your current and new passwords. */
  changePassword: Maybe<ChangePasswordPayload>;
  classSetIsStarred: Maybe<ClassSetIsStarredPayload>;
  classVideoSetDurationWatched: Maybe<ClassVideoSetDurationWatchedPayload>;
  /** Creates a single `Account`. */
  createAccount: Maybe<CreateAccountPayload>;
  /** Creates a single `Activity`. */
  createActivity: Maybe<CreateActivityPayload>;
  /** Creates a single `ActivityLevel`. */
  createActivityLevel: Maybe<CreateActivityLevelPayload>;
  /** Creates a single `Brief`. */
  createBrief: Maybe<CreateBriefPayload>;
  /** Creates a single `BriefGroup`. */
  createBriefGroup: Maybe<CreateBriefGroupPayload>;
  /** Creates a single `BriefGroupTypeMapping`. */
  createBriefGroupTypeMapping: Maybe<CreateBriefGroupTypeMappingPayload>;
  /** Creates a single `BriefGroupVariable`. */
  createBriefGroupVariable: Maybe<CreateBriefGroupVariablePayload>;
  /** Create a set of 5 options for a given variable. */
  createBriefGroupVariableOptions: Maybe<CreateBriefGroupVariableOptionsPayload>;
  /** Creates a single `BriefPreset`. */
  createBriefPreset: Maybe<CreateBriefPresetPayload>;
  /** Creates a single `Class`. */
  createClass: Maybe<CreateClassPayload>;
  /** Creates a single `ClassAssetCategory`. */
  createClassAssetCategory: Maybe<CreateClassAssetCategoryPayload>;
  /** Creates a single `ClassDocument`. */
  createClassDocument: Maybe<CreateClassDocumentPayload>;
  /** Creates a single `ClassStar`. */
  createClassStar: Maybe<CreateClassStarPayload>;
  /** Creates a single `ClassUser`. */
  createClassUser: Maybe<CreateClassUserPayload>;
  /** Creates a single `ClassVideo`. */
  createClassVideo: Maybe<CreateClassVideoPayload>;
  /** Creates a single `ClassVideoUser`. */
  createClassVideoUser: Maybe<CreateClassVideoUserPayload>;
  /** Creates a single `Connection`. */
  createConnection: Maybe<CreateConnectionPayload>;
  /** Creates a single `ConsultantOrganizationAccess`. */
  createConsultantOrganizationAccess: Maybe<CreateConsultantOrganizationAccessPayload>;
  /** Creates a single `CountryRegion`. */
  createCountryRegion: Maybe<CreateCountryRegionPayload>;
  /** Creates a single `Customer`. */
  createCustomer: Maybe<CreateCustomerPayload>;
  /** Creates a single `Division`. */
  createDivision: Maybe<CreateDivisionPayload>;
  /** Creates a single `Document`. */
  createDocument: Maybe<CreateDocumentPayload>;
  /** Creates a single `DocumentStar`. */
  createDocumentStar: Maybe<CreateDocumentStarPayload>;
  /** Creates a single `EventType`. */
  createEventType: Maybe<CreateEventTypePayload>;
  /** Creates a single `FeedbackQuestion`. */
  createFeedbackQuestion: Maybe<CreateFeedbackQuestionPayload>;
  /** Creates a single `ForgottenEmailSubmission`. */
  createForgottenEmailSubmission: Maybe<CreateForgottenEmailSubmissionPayload>;
  /** Creates a single `GoogleAccountUser`. */
  createGoogleAccountUser: Maybe<CreateGoogleAccountUserPayload>;
  /** Creates a single `GoogleCalendarEvent`. */
  createGoogleCalendarEvent: Maybe<CreateGoogleCalendarEventPayload>;
  /** Creates a single `Industry`. */
  createIndustry: Maybe<CreateIndustryPayload>;
  /** Creates a single `Lv1GameScore`. */
  createLv1GameScore: Maybe<CreateLv1GameScorePayload>;
  /** Creates a single `Lv1GameSubgroup`. */
  createLv1GameSubgroup: Maybe<CreateLv1GameSubgroupPayload>;
  /** Creates a single `Lv1GameSubgroupUser`. */
  createLv1GameSubgroupUser: Maybe<CreateLv1GameSubgroupUserPayload>;
  /** Creates a single `MassUserUpload`. */
  createMassUserUpload: Maybe<CreateMassUserUploadPayload>;
  /** Creates a single `Project`. */
  createProject: Maybe<CreateProjectPayload>;
  /** Creates a single `ProjectComment`. */
  createProjectComment: Maybe<CreateProjectCommentPayload>;
  /** Creates a single `ProjectCommunicationMessage`. */
  createProjectCommunicationMessage: Maybe<CreateProjectCommunicationMessagePayload>;
  /** Creates a single `ProjectCommunicationQuestion`. */
  createProjectCommunicationQuestion: Maybe<CreateProjectCommunicationQuestionPayload>;
  /** Creates a single `ProjectCompliance`. */
  createProjectCompliance: Maybe<CreateProjectCompliancePayload>;
  /** Creates a single `ProjectConnection`. */
  createProjectConnection: Maybe<CreateProjectConnectionPayload>;
  createProjectConnectionLinkShort: Maybe<CreateProjectConnectionLinkShortPayload>;
  /** Creates a single `ProjectEvent`. */
  createProjectEvent: Maybe<CreateProjectEventPayload>;
  /** Creates a single `ProjectOffer`. */
  createProjectOffer: Maybe<CreateProjectOfferPayload>;
  /** Creates a single `ProjectOfferVariable`. */
  createProjectOfferVariable: Maybe<CreateProjectOfferVariablePayload>;
  /** Creates a single `ProjectReminder`. */
  createProjectReminder: Maybe<CreateProjectReminderPayload>;
  /** Creates a single `ProjectRisk`. */
  createProjectRisk: Maybe<CreateProjectRiskPayload>;
  /** Creates a single `ProjectScenario`. */
  createProjectScenario: Maybe<CreateProjectScenarioPayload>;
  /** Creates a single `ProjectScenarioVariable`. */
  createProjectScenarioVariable: Maybe<CreateProjectScenarioVariablePayload>;
  /** Creates a single `ProjectVariable`. */
  createProjectVariable: Maybe<CreateProjectVariablePayload>;
  /** Creates a single `SurveyBucket`. */
  createSurveyBucket: Maybe<CreateSurveyBucketPayload>;
  /** Creates a single `SurveyQuestion`. */
  createSurveyQuestion: Maybe<CreateSurveyQuestionPayload>;
  /** Creates a single `SurveyQuestionAnswer`. */
  createSurveyQuestionAnswer: Maybe<CreateSurveyQuestionAnswerPayload>;
  /** Creates a single `SurveyReminder`. */
  createSurveyReminder: Maybe<CreateSurveyReminderPayload>;
  /** Creates a single `SurveyResponse`. */
  createSurveyResponse: Maybe<CreateSurveyResponsePayload>;
  /** Enables admins and superadmins to create users. */
  createUser: Maybe<CreateUserPayload>;
  /** Creates a single `UserLanguage`. */
  createUserLanguage: Maybe<CreateUserLanguagePayload>;
  /** Creates a single `UserProfileQuestionnaire`. */
  createUserProfileQuestionnaire: Maybe<CreateUserProfileQuestionnairePayload>;
  /** Creates a single `V1Role`. */
  createV1Role: Maybe<CreateV1RolePayload>;
  /** Creates a single `VariableClass`. */
  createVariableClass: Maybe<CreateVariableClassPayload>;
  /** Creates a single `Video`. */
  createVideo: Maybe<CreateVideoPayload>;
  /** Creates a single `VideoStar`. */
  createVideoStar: Maybe<CreateVideoStarPayload>;
  /** Creates a single `Workshop`. */
  createWorkshop: Maybe<CreateWorkshopPayload>;
  /** Creates a single `WorkshopPreset`. */
  createWorkshopPreset: Maybe<CreateWorkshopPresetPayload>;
  /** Deletes a single `Account` using a unique key. */
  deleteAccount: Maybe<DeleteAccountPayload>;
  /** Deletes a single `Account` using a unique key. */
  deleteAccountByName: Maybe<DeleteAccountPayload>;
  /** Deletes a single `Account` using its globally unique id. */
  deleteAccountByNodeId: Maybe<DeleteAccountPayload>;
  /** Deletes a single `Activity` using a unique key. */
  deleteActivity: Maybe<DeleteActivityPayload>;
  /** Deletes a single `Activity` using its globally unique id. */
  deleteActivityByNodeId: Maybe<DeleteActivityPayload>;
  /** Deletes a single `Brief` using a unique key. */
  deleteBrief: Maybe<DeleteBriefPayload>;
  /** Deletes a single `Brief` using its globally unique id. */
  deleteBriefByNodeId: Maybe<DeleteBriefPayload>;
  /** Deletes a single `BriefGroup` using a unique key. */
  deleteBriefGroup: Maybe<DeleteBriefGroupPayload>;
  /** Deletes a single `BriefGroup` using its globally unique id. */
  deleteBriefGroupByNodeId: Maybe<DeleteBriefGroupPayload>;
  /** Deletes a single `BriefGroupTypeMapping` using a unique key. */
  deleteBriefGroupTypeMapping: Maybe<DeleteBriefGroupTypeMappingPayload>;
  /** Deletes a single `BriefGroupTypeMapping` using its globally unique id. */
  deleteBriefGroupTypeMappingByNodeId: Maybe<DeleteBriefGroupTypeMappingPayload>;
  /** Deletes a single `BriefGroupVariable` using a unique key. */
  deleteBriefGroupVariable: Maybe<DeleteBriefGroupVariablePayload>;
  /** Deletes a single `BriefGroupVariable` using its globally unique id. */
  deleteBriefGroupVariableByNodeId: Maybe<DeleteBriefGroupVariablePayload>;
  /** Deletes a single `BriefPreset` using a unique key. */
  deleteBriefPreset: Maybe<DeleteBriefPresetPayload>;
  /** Deletes a single `BriefPreset` using its globally unique id. */
  deleteBriefPresetByNodeId: Maybe<DeleteBriefPresetPayload>;
  /** Deletes a single `Class` using a unique key. */
  deleteClass: Maybe<DeleteClassPayload>;
  /** Deletes a single `ClassAssetCategory` using a unique key. */
  deleteClassAssetCategory: Maybe<DeleteClassAssetCategoryPayload>;
  /** Deletes a single `ClassAssetCategory` using its globally unique id. */
  deleteClassAssetCategoryByNodeId: Maybe<DeleteClassAssetCategoryPayload>;
  /** Deletes a single `Class` using its globally unique id. */
  deleteClassByNodeId: Maybe<DeleteClassPayload>;
  /** Deletes a single `ClassDocument` using a unique key. */
  deleteClassDocument: Maybe<DeleteClassDocumentPayload>;
  /** Deletes a single `ClassDocument` using a unique key. */
  deleteClassDocumentByClassIdAndDocumentId: Maybe<DeleteClassDocumentPayload>;
  /** Deletes a single `ClassDocument` using its globally unique id. */
  deleteClassDocumentByNodeId: Maybe<DeleteClassDocumentPayload>;
  /** Deletes a single `ClassStar` using a unique key. */
  deleteClassStar: Maybe<DeleteClassStarPayload>;
  /** Deletes a single `ClassStar` using a unique key. */
  deleteClassStarByClassIdAndUserId: Maybe<DeleteClassStarPayload>;
  /** Deletes a single `ClassStar` using its globally unique id. */
  deleteClassStarByNodeId: Maybe<DeleteClassStarPayload>;
  /** Deletes a single `ClassUser` using a unique key. */
  deleteClassUser: Maybe<DeleteClassUserPayload>;
  /** Deletes a single `ClassUser` using a unique key. */
  deleteClassUserByClassIdAndUserId: Maybe<DeleteClassUserPayload>;
  /** Deletes a single `ClassUser` using its globally unique id. */
  deleteClassUserByNodeId: Maybe<DeleteClassUserPayload>;
  /** Deletes a single `ClassVideo` using a unique key. */
  deleteClassVideo: Maybe<DeleteClassVideoPayload>;
  /** Deletes a single `ClassVideo` using a unique key. */
  deleteClassVideoByClassIdAndVideoId: Maybe<DeleteClassVideoPayload>;
  /** Deletes a single `ClassVideo` using its globally unique id. */
  deleteClassVideoByNodeId: Maybe<DeleteClassVideoPayload>;
  /** Deletes a single `ClassVideoUser` using a unique key. */
  deleteClassVideoUser: Maybe<DeleteClassVideoUserPayload>;
  /** Deletes a single `ClassVideoUser` using a unique key. */
  deleteClassVideoUserByClassVideoIdAndUserId: Maybe<DeleteClassVideoUserPayload>;
  /** Deletes a single `ClassVideoUser` using its globally unique id. */
  deleteClassVideoUserByNodeId: Maybe<DeleteClassVideoUserPayload>;
  /** Deletes a single `Connection` using a unique key. */
  deleteConnection: Maybe<DeleteConnectionPayload>;
  /** Deletes a single `Connection` using its globally unique id. */
  deleteConnectionByNodeId: Maybe<DeleteConnectionPayload>;
  /** Deletes a single `ConsultantOrganizationAccess` using a unique key. */
  deleteConsultantOrganizationAccess: Maybe<DeleteConsultantOrganizationAccessPayload>;
  /** Deletes a single `ConsultantOrganizationAccess` using its globally unique id. */
  deleteConsultantOrganizationAccessByNodeId: Maybe<DeleteConsultantOrganizationAccessPayload>;
  /** Deletes a single `CountryRegion` using a unique key. */
  deleteCountryRegion: Maybe<DeleteCountryRegionPayload>;
  /** Deletes a single `CountryRegion` using a unique key. */
  deleteCountryRegionByName: Maybe<DeleteCountryRegionPayload>;
  /** Deletes a single `CountryRegion` using its globally unique id. */
  deleteCountryRegionByNodeId: Maybe<DeleteCountryRegionPayload>;
  /** Deletes a single `Customer` using a unique key. */
  deleteCustomer: Maybe<DeleteCustomerPayload>;
  /** Deletes a single `Customer` using its globally unique id. */
  deleteCustomerByNodeId: Maybe<DeleteCustomerPayload>;
  /** Deletes a single `Division` using a unique key. */
  deleteDivision: Maybe<DeleteDivisionPayload>;
  /** Deletes a single `Division` using a unique key. */
  deleteDivisionByNameAndV1Id: Maybe<DeleteDivisionPayload>;
  /** Deletes a single `Division` using its globally unique id. */
  deleteDivisionByNodeId: Maybe<DeleteDivisionPayload>;
  /** Deletes a single `Document` using a unique key. */
  deleteDocument: Maybe<DeleteDocumentPayload>;
  /** Deletes a single `Document` using its globally unique id. */
  deleteDocumentByNodeId: Maybe<DeleteDocumentPayload>;
  /** Deletes a single `DocumentStar` using a unique key. */
  deleteDocumentStar: Maybe<DeleteDocumentStarPayload>;
  /** Deletes a single `DocumentStar` using a unique key. */
  deleteDocumentStarByDocumentIdAndUserId: Maybe<DeleteDocumentStarPayload>;
  /** Deletes a single `DocumentStar` using its globally unique id. */
  deleteDocumentStarByNodeId: Maybe<DeleteDocumentStarPayload>;
  /** Deletes a single `EventType` using a unique key. */
  deleteEventType: Maybe<DeleteEventTypePayload>;
  /** Deletes a single `EventType` using its globally unique id. */
  deleteEventTypeByNodeId: Maybe<DeleteEventTypePayload>;
  /** Deletes a single `FeedbackQuestion` using a unique key. */
  deleteFeedbackQuestion: Maybe<DeleteFeedbackQuestionPayload>;
  /** Deletes a single `FeedbackQuestion` using its globally unique id. */
  deleteFeedbackQuestionByNodeId: Maybe<DeleteFeedbackQuestionPayload>;
  /** Deletes a single `FeedbackQuestion` using a unique key. */
  deleteFeedbackQuestionByNumberAndQuestion: Maybe<DeleteFeedbackQuestionPayload>;
  /** Deletes a single `GoogleAccountUser` using a unique key. */
  deleteGoogleAccountUser: Maybe<DeleteGoogleAccountUserPayload>;
  /** Deletes a single `GoogleAccountUser` using its globally unique id. */
  deleteGoogleAccountUserByNodeId: Maybe<DeleteGoogleAccountUserPayload>;
  /** Deletes a single `GoogleCalendarEvent` using a unique key. */
  deleteGoogleCalendarEvent: Maybe<DeleteGoogleCalendarEventPayload>;
  /** Deletes a single `GoogleCalendarEvent` using its globally unique id. */
  deleteGoogleCalendarEventByNodeId: Maybe<DeleteGoogleCalendarEventPayload>;
  /** Deletes a single `Industry` using a unique key. */
  deleteIndustry: Maybe<DeleteIndustryPayload>;
  /** Deletes a single `Industry` using a unique key. */
  deleteIndustryByNameAndV1Id: Maybe<DeleteIndustryPayload>;
  /** Deletes a single `Industry` using its globally unique id. */
  deleteIndustryByNodeId: Maybe<DeleteIndustryPayload>;
  /** Deletes a single `Lv1GameScore` using a unique key. */
  deleteLv1GameScore: Maybe<DeleteLv1GameScorePayload>;
  /** Deletes a single `Lv1GameScore` using its globally unique id. */
  deleteLv1GameScoreByNodeId: Maybe<DeleteLv1GameScorePayload>;
  /** Deletes a single `Lv1GameState` using a unique key. */
  deleteLv1GameState: Maybe<DeleteLv1GameStatePayload>;
  /** Deletes a single `Lv1GameState` using its globally unique id. */
  deleteLv1GameStateByNodeId: Maybe<DeleteLv1GameStatePayload>;
  /** Deletes a single `Lv1GameSubgroup` using a unique key. */
  deleteLv1GameSubgroup: Maybe<DeleteLv1GameSubgroupPayload>;
  /** Deletes a single `Lv1GameSubgroup` using its globally unique id. */
  deleteLv1GameSubgroupByNodeId: Maybe<DeleteLv1GameSubgroupPayload>;
  /** Deletes a single `Lv1GameSubgroupUser` using a unique key. */
  deleteLv1GameSubgroupUser: Maybe<DeleteLv1GameSubgroupUserPayload>;
  /** Deletes a single `Lv1GameSubgroupUser` using its globally unique id. */
  deleteLv1GameSubgroupUserByNodeId: Maybe<DeleteLv1GameSubgroupUserPayload>;
  /** Deletes a single `MassUserUpload` using a unique key. */
  deleteMassUserUpload: Maybe<DeleteMassUserUploadPayload>;
  /** Deletes a single `MassUserUpload` using its globally unique id. */
  deleteMassUserUploadByNodeId: Maybe<DeleteMassUserUploadPayload>;
  /** Deletes a coaching time log entry from an Organization. */
  deleteOrganizationCoachingTimeLog: Maybe<DeleteOrganizationCoachingTimeLogPayload>;
  /** Deletes a single `Project` using a unique key. */
  deleteProject: Maybe<DeleteProjectPayload>;
  /** Deletes a single `Project` using its globally unique id. */
  deleteProjectByNodeId: Maybe<DeleteProjectPayload>;
  /** Deletes a single `ProjectInvitation` using a unique key. */
  deleteProjectInvitation: Maybe<DeleteProjectInvitationPayload>;
  /** Deletes a single `ProjectInvitation` using its globally unique id. */
  deleteProjectInvitationByNodeId: Maybe<DeleteProjectInvitationPayload>;
  /** Deletes a single `ProjectInvitation` using a unique key. */
  deleteProjectInvitationByProjectIdAndUserId: Maybe<DeleteProjectInvitationPayload>;
  /** Deletes a single `ProjectMembership` using a unique key. */
  deleteProjectMembership: Maybe<DeleteProjectMembershipPayload>;
  /** Deletes a single `ProjectMembership` using its globally unique id. */
  deleteProjectMembershipByNodeId: Maybe<DeleteProjectMembershipPayload>;
  /** Deletes a single `ProjectMembership` using a unique key. */
  deleteProjectMembershipByProjectIdAndUserId: Maybe<DeleteProjectMembershipPayload>;
  /** Deletes a single `ProjectReminder` using a unique key. */
  deleteProjectReminder: Maybe<DeleteProjectReminderPayload>;
  /** Deletes a single `ProjectReminder` using its globally unique id. */
  deleteProjectReminderByNodeId: Maybe<DeleteProjectReminderPayload>;
  /** Deletes a single `SurveyBucket` using a unique key. */
  deleteSurveyBucket: Maybe<DeleteSurveyBucketPayload>;
  /** Deletes a single `SurveyBucket` using a unique key. */
  deleteSurveyBucketByName: Maybe<DeleteSurveyBucketPayload>;
  /** Deletes a single `SurveyBucket` using its globally unique id. */
  deleteSurveyBucketByNodeId: Maybe<DeleteSurveyBucketPayload>;
  /** Deletes a single `SurveyQuestion` using a unique key. */
  deleteSurveyQuestion: Maybe<DeleteSurveyQuestionPayload>;
  /** Deletes a single `SurveyQuestionAnswer` using a unique key. */
  deleteSurveyQuestionAnswer: Maybe<DeleteSurveyQuestionAnswerPayload>;
  /** Deletes a single `SurveyQuestionAnswer` using its globally unique id. */
  deleteSurveyQuestionAnswerByNodeId: Maybe<DeleteSurveyQuestionAnswerPayload>;
  /** Deletes a single `SurveyQuestion` using its globally unique id. */
  deleteSurveyQuestionByNodeId: Maybe<DeleteSurveyQuestionPayload>;
  /** Deletes a single `SurveyQuestion` using a unique key. */
  deleteSurveyQuestionByQuestionAndSurveyType: Maybe<DeleteSurveyQuestionPayload>;
  /** Deletes a single `SurveyReminder` using a unique key. */
  deleteSurveyReminder: Maybe<DeleteSurveyReminderPayload>;
  /** Deletes a single `SurveyReminder` using its globally unique id. */
  deleteSurveyReminderByNodeId: Maybe<DeleteSurveyReminderPayload>;
  /** Deletes a single `SurveyResponse` using a unique key. */
  deleteSurveyResponse: Maybe<DeleteSurveyResponsePayload>;
  /** Deletes a single `SurveyResponse` using its globally unique id. */
  deleteSurveyResponseByNodeId: Maybe<DeleteSurveyResponsePayload>;
  /** Deletes a single `SurveyResponse` using a unique key. */
  deleteSurveyResponseByUserIdAndRecipientIdAndSurveyType: Maybe<DeleteSurveyResponsePayload>;
  /** Deletes a single `UserLanguage` using a unique key. */
  deleteUserLanguageByLanguageCode: Maybe<DeleteUserLanguagePayload>;
  /** Deletes a single `UserLanguage` using a unique key. */
  deleteUserLanguageByName: Maybe<DeleteUserLanguagePayload>;
  /** Deletes a single `UserProfileQuestionnaire` using a unique key. */
  deleteUserProfileQuestionnaire: Maybe<DeleteUserProfileQuestionnairePayload>;
  /** Deletes a single `UserProfileQuestionnaire` using its globally unique id. */
  deleteUserProfileQuestionnaireByNodeId: Maybe<DeleteUserProfileQuestionnairePayload>;
  /** Deletes a single `UserProfileQuestionnaire` using a unique key. */
  deleteUserProfileQuestionnaireByUserId: Maybe<DeleteUserProfileQuestionnairePayload>;
  /** Deletes a single `V1Role` using a unique key. */
  deleteV1Role: Maybe<DeleteV1RolePayload>;
  /** Deletes a single `V1Role` using a unique key. */
  deleteV1RoleByIdAndName: Maybe<DeleteV1RolePayload>;
  /** Deletes a single `V1Role` using its globally unique id. */
  deleteV1RoleByNodeId: Maybe<DeleteV1RolePayload>;
  /** Deletes a single `Video` using a unique key. */
  deleteVideo: Maybe<DeleteVideoPayload>;
  /** Deletes a single `Video` using its globally unique id. */
  deleteVideoByNodeId: Maybe<DeleteVideoPayload>;
  /** Deletes a single `VideoStar` using a unique key. */
  deleteVideoStar: Maybe<DeleteVideoStarPayload>;
  /** Deletes a single `VideoStar` using its globally unique id. */
  deleteVideoStarByNodeId: Maybe<DeleteVideoStarPayload>;
  /** Deletes a single `VideoStar` using a unique key. */
  deleteVideoStarByVideoIdAndUserId: Maybe<DeleteVideoStarPayload>;
  /** Deletes a single `Workshop` using a unique key. */
  deleteWorkshop: Maybe<DeleteWorkshopPayload>;
  /** Deletes a single `Workshop` using its globally unique id. */
  deleteWorkshopByNodeId: Maybe<DeleteWorkshopPayload>;
  /** Deletes a single `WorkshopPreset` using a unique key. */
  deleteWorkshopPreset: Maybe<DeleteWorkshopPresetPayload>;
  /** Deletes a single `WorkshopPreset` using its globally unique id. */
  deleteWorkshopPresetByNodeId: Maybe<DeleteWorkshopPresetPayload>;
  documentSetIsStarred: Maybe<DocumentSetIsStarredPayload>;
  /** Used to duplicate (copy) a project by carrying over everything that was entered in the original */
  duplicateProject: Maybe<DuplicateProjectPayload>;
  /** Edits a coaching time log entry of an Organization. */
  editOrganizationCoachingTimeLog: Maybe<EditOrganizationCoachingTimeLogPayload>;
  /** Create a CSV file with all users from an organization and send an email to the super admin who triggered it */
  exportUsersCsv: Maybe<ExportUsersCsvPayload>;
  /** If you've forgotten your password, give us your email address and we'll send you a reset token. */
  forgotPassword: Maybe<ForgotPasswordPayload>;
  /** Insert or update state for a given LV1 game user. */
  gameLv1SaveState: Maybe<GameLv1SaveStatePayload>;
  /** Allows members to create temporary login links to be used for internal services. */
  generateTempLink: Maybe<GenerateTempLinkPayload>;
  /** As a superadmin you can impersonate (log in as) another user. */
  impersonateUser: Maybe<ImpersonateUserPayload>;
  /** Used for project sharing - invites a list of users to a project. */
  inviteToProject: Maybe<InviteToProjectPayload>;
  /** Move a project or a folder to another folder. */
  moveProjectToFolder: Maybe<MoveProjectToFolderPayload>;
  /** Find the next video in a given class that is premiered and is not yet fully watched by current user */
  nextClassVideoId: Maybe<NextClassVideoIdPayload>;
  /** Used to keep track of the last open time of a project for a given user. */
  projectAccessLogOpen: Maybe<ProjectAccessLogOpenPayload>;
  /** Used to keep track of the last modification time of a project per user. */
  projectAccessLogUpdate: Maybe<ProjectAccessLogUpdatePayload>;
  /** Given folder ID, return all children */
  projectFolderGetChildren: Maybe<ProjectFolderGetChildrenPayload>;
  /** Set the project status to archived or deleted for all. */
  projectSetIsArchived: Maybe<ProjectSetIsArchivedPayload>;
  /** Resets user password when they have a valid token (from forgot password) */
  resetPassword: Maybe<ResetPasswordPayload>;
  saveProjectConnection: Maybe<SaveProjectConnectionPayload>;
  /** Allow superadmins and consultants to send magic login links to users having trouble logging in. */
  sendMagicLink: Maybe<SendMagicLinkPayload>;
  /** Enables anonymous users to login. SSO-enabled companies cannot use this method. */
  signin: Maybe<SigninPayload>;
  /** Enables anonymous users to login with magic link. */
  signinWithMagicLink: Maybe<SigninWithMagicLinkPayload>;
  /** Enables anonymous users to login with temp link. */
  signinWithTempLink: Maybe<SigninWithTempLinkPayload>;
  /** Enables anonymous users to register (only for testing purposes) */
  signup: Maybe<SignupPayload>;
  /** Validate and submit answers to a Workshop Feedback. */
  submitFeedbackAnswers: Maybe<SubmitFeedbackAnswersPayload>;
  /** Validate and submit answers to a Workshop Survey. */
  submitSurveyAnswers: Maybe<SubmitSurveyAnswersPayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  updateAccount: Maybe<UpdateAccountPayload>;
  /** Updates a single `Account` using a unique key and a patch. */
  updateAccountByName: Maybe<UpdateAccountPayload>;
  /** Updates a single `Account` using its globally unique id and a patch. */
  updateAccountByNodeId: Maybe<UpdateAccountPayload>;
  /** Updates a single `Activity` using a unique key and a patch. */
  updateActivity: Maybe<UpdateActivityPayload>;
  /** Updates a single `Activity` using its globally unique id and a patch. */
  updateActivityByNodeId: Maybe<UpdateActivityPayload>;
  /** Updates a single `Brief` using a unique key and a patch. */
  updateBrief: Maybe<UpdateBriefPayload>;
  /** Updates a single `Brief` using its globally unique id and a patch. */
  updateBriefByNodeId: Maybe<UpdateBriefPayload>;
  /** Updates a single `BriefGroup` using a unique key and a patch. */
  updateBriefGroup: Maybe<UpdateBriefGroupPayload>;
  /** Updates a single `BriefGroup` using its globally unique id and a patch. */
  updateBriefGroupByNodeId: Maybe<UpdateBriefGroupPayload>;
  /** Updates a single `BriefGroupTypeMapping` using a unique key and a patch. */
  updateBriefGroupTypeMapping: Maybe<UpdateBriefGroupTypeMappingPayload>;
  /** Updates a single `BriefGroupTypeMapping` using its globally unique id and a patch. */
  updateBriefGroupTypeMappingByNodeId: Maybe<UpdateBriefGroupTypeMappingPayload>;
  /** Updates a single `BriefGroupVariable` using a unique key and a patch. */
  updateBriefGroupVariable: Maybe<UpdateBriefGroupVariablePayload>;
  /** Updates a single `BriefGroupVariable` using its globally unique id and a patch. */
  updateBriefGroupVariableByNodeId: Maybe<UpdateBriefGroupVariablePayload>;
  /** Updates a single `BriefGroupVariableOption` using a unique key and a patch. */
  updateBriefGroupVariableOption: Maybe<UpdateBriefGroupVariableOptionPayload>;
  /** Updates a single `BriefGroupVariableOption` using its globally unique id and a patch. */
  updateBriefGroupVariableOptionByNodeId: Maybe<UpdateBriefGroupVariableOptionPayload>;
  /** Updates a single `BriefPreset` using a unique key and a patch. */
  updateBriefPreset: Maybe<UpdateBriefPresetPayload>;
  /** Updates a single `BriefPreset` using its globally unique id and a patch. */
  updateBriefPresetByNodeId: Maybe<UpdateBriefPresetPayload>;
  /** Updates a single `Class` using a unique key and a patch. */
  updateClass: Maybe<UpdateClassPayload>;
  /** Updates a single `ClassAssetCategory` using a unique key and a patch. */
  updateClassAssetCategory: Maybe<UpdateClassAssetCategoryPayload>;
  /** Updates a single `ClassAssetCategory` using its globally unique id and a patch. */
  updateClassAssetCategoryByNodeId: Maybe<UpdateClassAssetCategoryPayload>;
  /** Updates a single `Class` using its globally unique id and a patch. */
  updateClassByNodeId: Maybe<UpdateClassPayload>;
  /** Updates a single `ClassDocument` using a unique key and a patch. */
  updateClassDocument: Maybe<UpdateClassDocumentPayload>;
  /** Updates a single `ClassDocument` using a unique key and a patch. */
  updateClassDocumentByClassIdAndDocumentId: Maybe<UpdateClassDocumentPayload>;
  /** Updates a single `ClassDocument` using its globally unique id and a patch. */
  updateClassDocumentByNodeId: Maybe<UpdateClassDocumentPayload>;
  /** Updates a single `ClassStar` using a unique key and a patch. */
  updateClassStar: Maybe<UpdateClassStarPayload>;
  /** Updates a single `ClassStar` using a unique key and a patch. */
  updateClassStarByClassIdAndUserId: Maybe<UpdateClassStarPayload>;
  /** Updates a single `ClassStar` using its globally unique id and a patch. */
  updateClassStarByNodeId: Maybe<UpdateClassStarPayload>;
  /** Updates a single `ClassUser` using a unique key and a patch. */
  updateClassUser: Maybe<UpdateClassUserPayload>;
  /** Updates a single `ClassUser` using a unique key and a patch. */
  updateClassUserByClassIdAndUserId: Maybe<UpdateClassUserPayload>;
  /** Updates a single `ClassUser` using its globally unique id and a patch. */
  updateClassUserByNodeId: Maybe<UpdateClassUserPayload>;
  /** Updates a single `ClassVideo` using a unique key and a patch. */
  updateClassVideo: Maybe<UpdateClassVideoPayload>;
  /** Updates a single `ClassVideo` using a unique key and a patch. */
  updateClassVideoByClassIdAndVideoId: Maybe<UpdateClassVideoPayload>;
  /** Updates a single `ClassVideo` using its globally unique id and a patch. */
  updateClassVideoByNodeId: Maybe<UpdateClassVideoPayload>;
  /** Updates a single `ClassVideoUser` using a unique key and a patch. */
  updateClassVideoUser: Maybe<UpdateClassVideoUserPayload>;
  /** Updates a single `ClassVideoUser` using a unique key and a patch. */
  updateClassVideoUserByClassVideoIdAndUserId: Maybe<UpdateClassVideoUserPayload>;
  /** Updates a single `ClassVideoUser` using its globally unique id and a patch. */
  updateClassVideoUserByNodeId: Maybe<UpdateClassVideoUserPayload>;
  /** Updates a single `Connection` using a unique key and a patch. */
  updateConnection: Maybe<UpdateConnectionPayload>;
  /** Updates a single `Connection` using its globally unique id and a patch. */
  updateConnectionByNodeId: Maybe<UpdateConnectionPayload>;
  /** Updates a single `CountryRegion` using a unique key and a patch. */
  updateCountryRegion: Maybe<UpdateCountryRegionPayload>;
  /** Updates a single `CountryRegion` using a unique key and a patch. */
  updateCountryRegionByName: Maybe<UpdateCountryRegionPayload>;
  /** Updates a single `CountryRegion` using its globally unique id and a patch. */
  updateCountryRegionByNodeId: Maybe<UpdateCountryRegionPayload>;
  /** Updates a single `Customer` using a unique key and a patch. */
  updateCustomer: Maybe<UpdateCustomerPayload>;
  /** Updates a single `Customer` using its globally unique id and a patch. */
  updateCustomerByNodeId: Maybe<UpdateCustomerPayload>;
  /** Updates a single `Division` using a unique key and a patch. */
  updateDivision: Maybe<UpdateDivisionPayload>;
  /** Updates a single `Division` using a unique key and a patch. */
  updateDivisionByNameAndV1Id: Maybe<UpdateDivisionPayload>;
  /** Updates a single `Division` using its globally unique id and a patch. */
  updateDivisionByNodeId: Maybe<UpdateDivisionPayload>;
  /** Updates a single `Document` using a unique key and a patch. */
  updateDocument: Maybe<UpdateDocumentPayload>;
  /** Updates a single `Document` using its globally unique id and a patch. */
  updateDocumentByNodeId: Maybe<UpdateDocumentPayload>;
  /** Updates a single `DocumentStar` using a unique key and a patch. */
  updateDocumentStar: Maybe<UpdateDocumentStarPayload>;
  /** Updates a single `DocumentStar` using a unique key and a patch. */
  updateDocumentStarByDocumentIdAndUserId: Maybe<UpdateDocumentStarPayload>;
  /** Updates a single `DocumentStar` using its globally unique id and a patch. */
  updateDocumentStarByNodeId: Maybe<UpdateDocumentStarPayload>;
  /** Updates a single `EventType` using a unique key and a patch. */
  updateEventType: Maybe<UpdateEventTypePayload>;
  /** Updates a single `EventType` using its globally unique id and a patch. */
  updateEventTypeByNodeId: Maybe<UpdateEventTypePayload>;
  /** Updates a single `FeedbackQuestion` using a unique key and a patch. */
  updateFeedbackQuestion: Maybe<UpdateFeedbackQuestionPayload>;
  /** Updates a single `FeedbackQuestion` using its globally unique id and a patch. */
  updateFeedbackQuestionByNodeId: Maybe<UpdateFeedbackQuestionPayload>;
  /** Updates a single `FeedbackQuestion` using a unique key and a patch. */
  updateFeedbackQuestionByNumberAndQuestion: Maybe<UpdateFeedbackQuestionPayload>;
  /** Updates a single `GoogleAccountUser` using a unique key and a patch. */
  updateGoogleAccountUser: Maybe<UpdateGoogleAccountUserPayload>;
  /** Updates a single `GoogleAccountUser` using its globally unique id and a patch. */
  updateGoogleAccountUserByNodeId: Maybe<UpdateGoogleAccountUserPayload>;
  /** Updates a single `GoogleCalendarEvent` using a unique key and a patch. */
  updateGoogleCalendarEvent: Maybe<UpdateGoogleCalendarEventPayload>;
  /** Updates a single `GoogleCalendarEvent` using its globally unique id and a patch. */
  updateGoogleCalendarEventByNodeId: Maybe<UpdateGoogleCalendarEventPayload>;
  /** Updates a single `Industry` using a unique key and a patch. */
  updateIndustry: Maybe<UpdateIndustryPayload>;
  /** Updates a single `Industry` using a unique key and a patch. */
  updateIndustryByNameAndV1Id: Maybe<UpdateIndustryPayload>;
  /** Updates a single `Industry` using its globally unique id and a patch. */
  updateIndustryByNodeId: Maybe<UpdateIndustryPayload>;
  /** Updates a single `Lv1GameScore` using a unique key and a patch. */
  updateLv1GameScore: Maybe<UpdateLv1GameScorePayload>;
  /** Updates a single `Lv1GameScore` using its globally unique id and a patch. */
  updateLv1GameScoreByNodeId: Maybe<UpdateLv1GameScorePayload>;
  /** Updates a single `Lv1GameSubgroup` using a unique key and a patch. */
  updateLv1GameSubgroup: Maybe<UpdateLv1GameSubgroupPayload>;
  /** Updates a single `Lv1GameSubgroup` using its globally unique id and a patch. */
  updateLv1GameSubgroupByNodeId: Maybe<UpdateLv1GameSubgroupPayload>;
  /** Updates a single `Lv1GameSubgroupUser` using a unique key and a patch. */
  updateLv1GameSubgroupUser: Maybe<UpdateLv1GameSubgroupUserPayload>;
  /** Updates a single `Lv1GameSubgroupUser` using its globally unique id and a patch. */
  updateLv1GameSubgroupUserByNodeId: Maybe<UpdateLv1GameSubgroupUserPayload>;
  /** Updates a single `MassUserUpload` using a unique key and a patch. */
  updateMassUserUpload: Maybe<UpdateMassUserUploadPayload>;
  /** Updates a single `MassUserUpload` using its globally unique id and a patch. */
  updateMassUserUploadByNodeId: Maybe<UpdateMassUserUploadPayload>;
  updateObjectiveByProjectIdAndName: Maybe<UpdateObjectiveByProjectIdAndNamePayload>;
  /** Updates a single `Project` using a unique key and a patch. */
  updateProject: Maybe<UpdateProjectPayload>;
  /** Updates a single `ProjectAlignSurvey` using a unique key and a patch. */
  updateProjectAlignSurvey: Maybe<UpdateProjectAlignSurveyPayload>;
  /** Updates a single `ProjectAlignSurvey` using its globally unique id and a patch. */
  updateProjectAlignSurveyByNodeId: Maybe<UpdateProjectAlignSurveyPayload>;
  /** Updates a single `Project` using its globally unique id and a patch. */
  updateProjectByNodeId: Maybe<UpdateProjectPayload>;
  /** Updates a single `ProjectComment` using a unique key and a patch. */
  updateProjectComment: Maybe<UpdateProjectCommentPayload>;
  /** Updates a single `ProjectComment` using its globally unique id and a patch. */
  updateProjectCommentByNodeId: Maybe<UpdateProjectCommentPayload>;
  /** Updates a single `ProjectCommunicationMessage` using a unique key and a patch. */
  updateProjectCommunicationMessage: Maybe<UpdateProjectCommunicationMessagePayload>;
  /** Updates a single `ProjectCommunicationMessage` using its globally unique id and a patch. */
  updateProjectCommunicationMessageByNodeId: Maybe<UpdateProjectCommunicationMessagePayload>;
  /** Updates a single `ProjectCommunicationQuestion` using a unique key and a patch. */
  updateProjectCommunicationQuestion: Maybe<UpdateProjectCommunicationQuestionPayload>;
  /** Updates a single `ProjectCommunicationQuestion` using its globally unique id and a patch. */
  updateProjectCommunicationQuestionByNodeId: Maybe<UpdateProjectCommunicationQuestionPayload>;
  /** Updates a single `ProjectCommunicationQuestion` using a unique key and a patch. */
  updateProjectCommunicationQuestionByProjectIdAndQuestionType: Maybe<UpdateProjectCommunicationQuestionPayload>;
  /** Updates a single `ProjectCompliance` using a unique key and a patch. */
  updateProjectCompliance: Maybe<UpdateProjectCompliancePayload>;
  /** Updates a single `ProjectCompliance` using its globally unique id and a patch. */
  updateProjectComplianceByNodeId: Maybe<UpdateProjectCompliancePayload>;
  /** Updates a single `ProjectConnection` using a unique key and a patch. */
  updateProjectConnection: Maybe<UpdateProjectConnectionPayload>;
  /** Updates a single `ProjectConnection` using its globally unique id and a patch. */
  updateProjectConnectionByNodeId: Maybe<UpdateProjectConnectionPayload>;
  /** Updates a single `ProjectConnection` using a unique key and a patch. */
  updateProjectConnectionByProjectIdAndConnectionId: Maybe<UpdateProjectConnectionPayload>;
  /** Updates a single `ProjectEvent` using a unique key and a patch. */
  updateProjectEvent: Maybe<UpdateProjectEventPayload>;
  /** Updates a single `ProjectEvent` using its globally unique id and a patch. */
  updateProjectEventByNodeId: Maybe<UpdateProjectEventPayload>;
  /** Updates a single `ProjectMembership` using a unique key and a patch. */
  updateProjectMembership: Maybe<UpdateProjectMembershipPayload>;
  /** Updates a single `ProjectMembership` using its globally unique id and a patch. */
  updateProjectMembershipByNodeId: Maybe<UpdateProjectMembershipPayload>;
  /** Updates a single `ProjectMembership` using a unique key and a patch. */
  updateProjectMembershipByProjectIdAndUserId: Maybe<UpdateProjectMembershipPayload>;
  /** Updates a single `ProjectOffer` using a unique key and a patch. */
  updateProjectOffer: Maybe<UpdateProjectOfferPayload>;
  /** Updates a single `ProjectOffer` using its globally unique id and a patch. */
  updateProjectOfferByNodeId: Maybe<UpdateProjectOfferPayload>;
  /** Updates a single `ProjectOfferVariable` using a unique key and a patch. */
  updateProjectOfferVariable: Maybe<UpdateProjectOfferVariablePayload>;
  /** Updates a single `ProjectOfferVariable` using its globally unique id and a patch. */
  updateProjectOfferVariableByNodeId: Maybe<UpdateProjectOfferVariablePayload>;
  /** Updates a single `ProjectPower` using a unique key and a patch. */
  updateProjectPower: Maybe<UpdateProjectPowerPayload>;
  /** Updates a single `ProjectPower` using its globally unique id and a patch. */
  updateProjectPowerByNodeId: Maybe<UpdateProjectPowerPayload>;
  /** Updates a single `ProjectReminder` using a unique key and a patch. */
  updateProjectReminder: Maybe<UpdateProjectReminderPayload>;
  /** Updates a single `ProjectReminder` using its globally unique id and a patch. */
  updateProjectReminderByNodeId: Maybe<UpdateProjectReminderPayload>;
  /** Updates a single `ProjectRisk` using a unique key and a patch. */
  updateProjectRisk: Maybe<UpdateProjectRiskPayload>;
  /** Updates a single `ProjectRisk` using its globally unique id and a patch. */
  updateProjectRiskByNodeId: Maybe<UpdateProjectRiskPayload>;
  /** Updates a single `ProjectScenario` using a unique key and a patch. */
  updateProjectScenario: Maybe<UpdateProjectScenarioPayload>;
  /** Updates a single `ProjectScenario` using its globally unique id and a patch. */
  updateProjectScenarioByNodeId: Maybe<UpdateProjectScenarioPayload>;
  /** Updates a single `ProjectScenarioVariable` using a unique key and a patch. */
  updateProjectScenarioVariable: Maybe<UpdateProjectScenarioVariablePayload>;
  /** Updates a single `ProjectScenarioVariable` using its globally unique id and a patch. */
  updateProjectScenarioVariableByNodeId: Maybe<UpdateProjectScenarioVariablePayload>;
  /** Updates a single `ProjectSurvey` using a unique key and a patch. */
  updateProjectSurvey: Maybe<UpdateProjectSurveyPayload>;
  /** Updates a single `ProjectSurvey` using its globally unique id and a patch. */
  updateProjectSurveyByNodeId: Maybe<UpdateProjectSurveyPayload>;
  /** Updates a single `ProjectVariable` using a unique key and a patch. */
  updateProjectVariable: Maybe<UpdateProjectVariablePayload>;
  /** Updates a single `ProjectVariable` using its globally unique id and a patch. */
  updateProjectVariableByNodeId: Maybe<UpdateProjectVariablePayload>;
  /** Updates the rank for a given lits of variables. */
  updateProjectVariablesRank: Maybe<UpdateProjectVariablesRankPayload>;
  updateQuestionByProjectIdAndType: Maybe<UpdateQuestionByProjectIdAndTypePayload>;
  /** Updates a single `SurveyBucket` using a unique key and a patch. */
  updateSurveyBucket: Maybe<UpdateSurveyBucketPayload>;
  /** Updates a single `SurveyBucket` using a unique key and a patch. */
  updateSurveyBucketByName: Maybe<UpdateSurveyBucketPayload>;
  /** Updates a single `SurveyBucket` using its globally unique id and a patch. */
  updateSurveyBucketByNodeId: Maybe<UpdateSurveyBucketPayload>;
  /** Updates a single `SurveyQuestion` using a unique key and a patch. */
  updateSurveyQuestion: Maybe<UpdateSurveyQuestionPayload>;
  /** Updates a single `SurveyQuestionAnswer` using a unique key and a patch. */
  updateSurveyQuestionAnswer: Maybe<UpdateSurveyQuestionAnswerPayload>;
  /** Updates a single `SurveyQuestionAnswer` using its globally unique id and a patch. */
  updateSurveyQuestionAnswerByNodeId: Maybe<UpdateSurveyQuestionAnswerPayload>;
  /** Updates a single `SurveyQuestion` using its globally unique id and a patch. */
  updateSurveyQuestionByNodeId: Maybe<UpdateSurveyQuestionPayload>;
  /** Updates a single `SurveyQuestion` using a unique key and a patch. */
  updateSurveyQuestionByQuestionAndSurveyType: Maybe<UpdateSurveyQuestionPayload>;
  /** Updates a single `SurveyReminder` using a unique key and a patch. */
  updateSurveyReminder: Maybe<UpdateSurveyReminderPayload>;
  /** Updates a single `SurveyReminder` using its globally unique id and a patch. */
  updateSurveyReminderByNodeId: Maybe<UpdateSurveyReminderPayload>;
  /** Updates a single `SurveyResponse` using a unique key and a patch. */
  updateSurveyResponse: Maybe<UpdateSurveyResponsePayload>;
  /** Updates a single `SurveyResponse` using its globally unique id and a patch. */
  updateSurveyResponseByNodeId: Maybe<UpdateSurveyResponsePayload>;
  /** Updates a single `SurveyResponse` using a unique key and a patch. */
  updateSurveyResponseByUserIdAndRecipientIdAndSurveyType: Maybe<UpdateSurveyResponsePayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByEmail: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserByNodeId: Maybe<UpdateUserPayload>;
  /** Updates a single `UserLanguage` using a unique key and a patch. */
  updateUserLanguageByLanguageCode: Maybe<UpdateUserLanguagePayload>;
  /** Updates a single `UserLanguage` using a unique key and a patch. */
  updateUserLanguageByName: Maybe<UpdateUserLanguagePayload>;
  /** Admins are allowed to change passwords of users in their own account. */
  updateUserPassword: Maybe<UpdateUserPasswordPayload>;
  /** Updates a single `UserProfileQuestionnaire` using a unique key and a patch. */
  updateUserProfileQuestionnaire: Maybe<UpdateUserProfileQuestionnairePayload>;
  /** Updates a single `UserProfileQuestionnaire` using its globally unique id and a patch. */
  updateUserProfileQuestionnaireByNodeId: Maybe<UpdateUserProfileQuestionnairePayload>;
  /** Updates a single `UserProfileQuestionnaire` using a unique key and a patch. */
  updateUserProfileQuestionnaireByUserId: Maybe<UpdateUserProfileQuestionnairePayload>;
  /** Updates a single `V1Role` using a unique key and a patch. */
  updateV1Role: Maybe<UpdateV1RolePayload>;
  /** Updates a single `V1Role` using a unique key and a patch. */
  updateV1RoleByIdAndName: Maybe<UpdateV1RolePayload>;
  /** Updates a single `V1Role` using its globally unique id and a patch. */
  updateV1RoleByNodeId: Maybe<UpdateV1RolePayload>;
  /** Updates a single `VariableClass` using a unique key and a patch. */
  updateVariableClass: Maybe<UpdateVariableClassPayload>;
  /** Updates a single `VariableClass` using its globally unique id and a patch. */
  updateVariableClassByNodeId: Maybe<UpdateVariableClassPayload>;
  /** Updates a single `Video` using a unique key and a patch. */
  updateVideo: Maybe<UpdateVideoPayload>;
  /** Updates a single `Video` using its globally unique id and a patch. */
  updateVideoByNodeId: Maybe<UpdateVideoPayload>;
  /** Updates a single `VideoStar` using a unique key and a patch. */
  updateVideoStar: Maybe<UpdateVideoStarPayload>;
  /** Updates a single `VideoStar` using its globally unique id and a patch. */
  updateVideoStarByNodeId: Maybe<UpdateVideoStarPayload>;
  /** Updates a single `VideoStar` using a unique key and a patch. */
  updateVideoStarByVideoIdAndUserId: Maybe<UpdateVideoStarPayload>;
  /** Updates a single `Workshop` using a unique key and a patch. */
  updateWorkshop: Maybe<UpdateWorkshopPayload>;
  /** Updates a single `Workshop` using its globally unique id and a patch. */
  updateWorkshopByNodeId: Maybe<UpdateWorkshopPayload>;
  /** Updates a single `WorkshopPreset` using a unique key and a patch. */
  updateWorkshopPreset: Maybe<UpdateWorkshopPresetPayload>;
  /** Updates a single `WorkshopPreset` using its globally unique id and a patch. */
  updateWorkshopPresetByNodeId: Maybe<UpdateWorkshopPresetPayload>;
  /** Enables v1 users to login. */
  v1SsoSignin: Maybe<V1SsoSigninPayload>;
  /** Merge two variable classes. */
  variableClassMergeVariables: Maybe<VariableClassMergeVariablesPayload>;
  videoSetIsStarred: Maybe<VideoSetIsStarredPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddOrganizationCoachingTimeArgs = {
  input: AddOrganizationCoachingTimeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddOrganizationCoachingTimeLogArgs = {
  input: AddOrganizationCoachingTimeLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddUserToAccountClassesArgs = {
  input: AddUserToAccountClassesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBatchProcessWorkshopFeedbackDataAndInsertArgs = {
  input: BatchProcessWorkshopFeedbackDataAndInsertInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClassSetIsStarredArgs = {
  input: ClassSetIsStarredInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClassVideoSetDurationWatchedArgs = {
  input: ClassVideoSetDurationWatchedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateActivityArgs = {
  input: CreateActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateActivityLevelArgs = {
  input: CreateActivityLevelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefArgs = {
  input: CreateBriefInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefGroupArgs = {
  input: CreateBriefGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefGroupTypeMappingArgs = {
  input: CreateBriefGroupTypeMappingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefGroupVariableArgs = {
  input: CreateBriefGroupVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefGroupVariableOptionsArgs = {
  input: CreateBriefGroupVariableOptionsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBriefPresetArgs = {
  input: CreateBriefPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassAssetCategoryArgs = {
  input: CreateClassAssetCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassDocumentArgs = {
  input: CreateClassDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassStarArgs = {
  input: CreateClassStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassUserArgs = {
  input: CreateClassUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassVideoArgs = {
  input: CreateClassVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateClassVideoUserArgs = {
  input: CreateClassVideoUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateConnectionArgs = {
  input: CreateConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateConsultantOrganizationAccessArgs = {
  input: CreateConsultantOrganizationAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCountryRegionArgs = {
  input: CreateCountryRegionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDivisionArgs = {
  input: CreateDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDocumentArgs = {
  input: CreateDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDocumentStarArgs = {
  input: CreateDocumentStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEventTypeArgs = {
  input: CreateEventTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateFeedbackQuestionArgs = {
  input: CreateFeedbackQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateForgottenEmailSubmissionArgs = {
  input: CreateForgottenEmailSubmissionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleAccountUserArgs = {
  input: CreateGoogleAccountUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGoogleCalendarEventArgs = {
  input: CreateGoogleCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateIndustryArgs = {
  input: CreateIndustryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLv1GameScoreArgs = {
  input: CreateLv1GameScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLv1GameSubgroupArgs = {
  input: CreateLv1GameSubgroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLv1GameSubgroupUserArgs = {
  input: CreateLv1GameSubgroupUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateMassUserUploadArgs = {
  input: CreateMassUserUploadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectArgs = {
  input: CreateProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectCommentArgs = {
  input: CreateProjectCommentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectCommunicationMessageArgs = {
  input: CreateProjectCommunicationMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectCommunicationQuestionArgs = {
  input: CreateProjectCommunicationQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectComplianceArgs = {
  input: CreateProjectComplianceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectConnectionArgs = {
  input: CreateProjectConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectConnectionLinkShortArgs = {
  input: CreateProjectConnectionLinkShortInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectEventArgs = {
  input: CreateProjectEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectOfferArgs = {
  input: CreateProjectOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectOfferVariableArgs = {
  input: CreateProjectOfferVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectReminderArgs = {
  input: CreateProjectReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectRiskArgs = {
  input: CreateProjectRiskInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectScenarioArgs = {
  input: CreateProjectScenarioInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectScenarioVariableArgs = {
  input: CreateProjectScenarioVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateProjectVariableArgs = {
  input: CreateProjectVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSurveyBucketArgs = {
  input: CreateSurveyBucketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSurveyQuestionArgs = {
  input: CreateSurveyQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSurveyQuestionAnswerArgs = {
  input: CreateSurveyQuestionAnswerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSurveyReminderArgs = {
  input: CreateSurveyReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSurveyResponseArgs = {
  input: CreateSurveyResponseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserLanguageArgs = {
  input: CreateUserLanguageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserProfileQuestionnaireArgs = {
  input: CreateUserProfileQuestionnaireInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateV1RoleArgs = {
  input: CreateV1RoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVariableClassArgs = {
  input: CreateVariableClassInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVideoArgs = {
  input: CreateVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateVideoStarArgs = {
  input: CreateVideoStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkshopArgs = {
  input: CreateWorkshopInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateWorkshopPresetArgs = {
  input: CreateWorkshopPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArgs = {
  input: DeleteAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountByNameArgs = {
  input: DeleteAccountByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountByNodeIdArgs = {
  input: DeleteAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteActivityArgs = {
  input: DeleteActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteActivityByNodeIdArgs = {
  input: DeleteActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefArgs = {
  input: DeleteBriefInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefByNodeIdArgs = {
  input: DeleteBriefByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupArgs = {
  input: DeleteBriefGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupByNodeIdArgs = {
  input: DeleteBriefGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupTypeMappingArgs = {
  input: DeleteBriefGroupTypeMappingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupTypeMappingByNodeIdArgs = {
  input: DeleteBriefGroupTypeMappingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupVariableArgs = {
  input: DeleteBriefGroupVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefGroupVariableByNodeIdArgs = {
  input: DeleteBriefGroupVariableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefPresetArgs = {
  input: DeleteBriefPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteBriefPresetByNodeIdArgs = {
  input: DeleteBriefPresetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassArgs = {
  input: DeleteClassInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassAssetCategoryArgs = {
  input: DeleteClassAssetCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassAssetCategoryByNodeIdArgs = {
  input: DeleteClassAssetCategoryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassByNodeIdArgs = {
  input: DeleteClassByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassDocumentArgs = {
  input: DeleteClassDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassDocumentByClassIdAndDocumentIdArgs = {
  input: DeleteClassDocumentByClassIdAndDocumentIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassDocumentByNodeIdArgs = {
  input: DeleteClassDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassStarArgs = {
  input: DeleteClassStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassStarByClassIdAndUserIdArgs = {
  input: DeleteClassStarByClassIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassStarByNodeIdArgs = {
  input: DeleteClassStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassUserArgs = {
  input: DeleteClassUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassUserByClassIdAndUserIdArgs = {
  input: DeleteClassUserByClassIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassUserByNodeIdArgs = {
  input: DeleteClassUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoArgs = {
  input: DeleteClassVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoByClassIdAndVideoIdArgs = {
  input: DeleteClassVideoByClassIdAndVideoIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoByNodeIdArgs = {
  input: DeleteClassVideoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoUserArgs = {
  input: DeleteClassVideoUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoUserByClassVideoIdAndUserIdArgs = {
  input: DeleteClassVideoUserByClassVideoIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteClassVideoUserByNodeIdArgs = {
  input: DeleteClassVideoUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConnectionArgs = {
  input: DeleteConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConnectionByNodeIdArgs = {
  input: DeleteConnectionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConsultantOrganizationAccessArgs = {
  input: DeleteConsultantOrganizationAccessInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConsultantOrganizationAccessByNodeIdArgs = {
  input: DeleteConsultantOrganizationAccessByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountryRegionArgs = {
  input: DeleteCountryRegionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountryRegionByNameArgs = {
  input: DeleteCountryRegionByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCountryRegionByNodeIdArgs = {
  input: DeleteCountryRegionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerByNodeIdArgs = {
  input: DeleteCustomerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDivisionArgs = {
  input: DeleteDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDivisionByNameAndV1IdArgs = {
  input: DeleteDivisionByNameAndV1IdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDivisionByNodeIdArgs = {
  input: DeleteDivisionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDocumentArgs = {
  input: DeleteDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDocumentByNodeIdArgs = {
  input: DeleteDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDocumentStarArgs = {
  input: DeleteDocumentStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDocumentStarByDocumentIdAndUserIdArgs = {
  input: DeleteDocumentStarByDocumentIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDocumentStarByNodeIdArgs = {
  input: DeleteDocumentStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventTypeArgs = {
  input: DeleteEventTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteEventTypeByNodeIdArgs = {
  input: DeleteEventTypeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackQuestionArgs = {
  input: DeleteFeedbackQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackQuestionByNodeIdArgs = {
  input: DeleteFeedbackQuestionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteFeedbackQuestionByNumberAndQuestionArgs = {
  input: DeleteFeedbackQuestionByNumberAndQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleAccountUserArgs = {
  input: DeleteGoogleAccountUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleAccountUserByNodeIdArgs = {
  input: DeleteGoogleAccountUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarEventArgs = {
  input: DeleteGoogleCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteGoogleCalendarEventByNodeIdArgs = {
  input: DeleteGoogleCalendarEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndustryArgs = {
  input: DeleteIndustryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndustryByNameAndV1IdArgs = {
  input: DeleteIndustryByNameAndV1IdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteIndustryByNodeIdArgs = {
  input: DeleteIndustryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameScoreArgs = {
  input: DeleteLv1GameScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameScoreByNodeIdArgs = {
  input: DeleteLv1GameScoreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameStateArgs = {
  input: DeleteLv1GameStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameStateByNodeIdArgs = {
  input: DeleteLv1GameStateByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameSubgroupArgs = {
  input: DeleteLv1GameSubgroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameSubgroupByNodeIdArgs = {
  input: DeleteLv1GameSubgroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameSubgroupUserArgs = {
  input: DeleteLv1GameSubgroupUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLv1GameSubgroupUserByNodeIdArgs = {
  input: DeleteLv1GameSubgroupUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMassUserUploadArgs = {
  input: DeleteMassUserUploadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteMassUserUploadByNodeIdArgs = {
  input: DeleteMassUserUploadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationCoachingTimeLogArgs = {
  input: DeleteOrganizationCoachingTimeLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectArgs = {
  input: DeleteProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectByNodeIdArgs = {
  input: DeleteProjectByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectInvitationArgs = {
  input: DeleteProjectInvitationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectInvitationByNodeIdArgs = {
  input: DeleteProjectInvitationByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectInvitationByProjectIdAndUserIdArgs = {
  input: DeleteProjectInvitationByProjectIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectMembershipArgs = {
  input: DeleteProjectMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectMembershipByNodeIdArgs = {
  input: DeleteProjectMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectMembershipByProjectIdAndUserIdArgs = {
  input: DeleteProjectMembershipByProjectIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectReminderArgs = {
  input: DeleteProjectReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteProjectReminderByNodeIdArgs = {
  input: DeleteProjectReminderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyBucketArgs = {
  input: DeleteSurveyBucketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyBucketByNameArgs = {
  input: DeleteSurveyBucketByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyBucketByNodeIdArgs = {
  input: DeleteSurveyBucketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyQuestionArgs = {
  input: DeleteSurveyQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyQuestionAnswerArgs = {
  input: DeleteSurveyQuestionAnswerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyQuestionAnswerByNodeIdArgs = {
  input: DeleteSurveyQuestionAnswerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyQuestionByNodeIdArgs = {
  input: DeleteSurveyQuestionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyQuestionByQuestionAndSurveyTypeArgs = {
  input: DeleteSurveyQuestionByQuestionAndSurveyTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyReminderArgs = {
  input: DeleteSurveyReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyReminderByNodeIdArgs = {
  input: DeleteSurveyReminderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyResponseArgs = {
  input: DeleteSurveyResponseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyResponseByNodeIdArgs = {
  input: DeleteSurveyResponseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSurveyResponseByUserIdAndRecipientIdAndSurveyTypeArgs = {
  input: DeleteSurveyResponseByUserIdAndRecipientIdAndSurveyTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserLanguageByLanguageCodeArgs = {
  input: DeleteUserLanguageByLanguageCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserLanguageByNameArgs = {
  input: DeleteUserLanguageByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileQuestionnaireArgs = {
  input: DeleteUserProfileQuestionnaireInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileQuestionnaireByNodeIdArgs = {
  input: DeleteUserProfileQuestionnaireByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserProfileQuestionnaireByUserIdArgs = {
  input: DeleteUserProfileQuestionnaireByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteV1RoleArgs = {
  input: DeleteV1RoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteV1RoleByIdAndNameArgs = {
  input: DeleteV1RoleByIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteV1RoleByNodeIdArgs = {
  input: DeleteV1RoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVideoArgs = {
  input: DeleteVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVideoByNodeIdArgs = {
  input: DeleteVideoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVideoStarArgs = {
  input: DeleteVideoStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVideoStarByNodeIdArgs = {
  input: DeleteVideoStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteVideoStarByVideoIdAndUserIdArgs = {
  input: DeleteVideoStarByVideoIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkshopArgs = {
  input: DeleteWorkshopInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkshopByNodeIdArgs = {
  input: DeleteWorkshopByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkshopPresetArgs = {
  input: DeleteWorkshopPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteWorkshopPresetByNodeIdArgs = {
  input: DeleteWorkshopPresetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDocumentSetIsStarredArgs = {
  input: DocumentSetIsStarredInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDuplicateProjectArgs = {
  input: DuplicateProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEditOrganizationCoachingTimeLogArgs = {
  input: EditOrganizationCoachingTimeLogInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationExportUsersCsvArgs = {
  input: ExportUsersCsvInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGameLv1SaveStateArgs = {
  input: GameLv1SaveStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateTempLinkArgs = {
  input: GenerateTempLinkInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationImpersonateUserArgs = {
  input: ImpersonateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInviteToProjectArgs = {
  input: InviteToProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMoveProjectToFolderArgs = {
  input: MoveProjectToFolderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationNextClassVideoIdArgs = {
  input: NextClassVideoIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProjectAccessLogOpenArgs = {
  input: ProjectAccessLogOpenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProjectAccessLogUpdateArgs = {
  input: ProjectAccessLogUpdateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProjectFolderGetChildrenArgs = {
  input: ProjectFolderGetChildrenInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProjectSetIsArchivedArgs = {
  input: ProjectSetIsArchivedInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSaveProjectConnectionArgs = {
  input: SaveProjectConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSendMagicLinkArgs = {
  input: SendMagicLinkInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSigninArgs = {
  input: SigninInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSigninWithMagicLinkArgs = {
  input: SigninWithMagicLinkInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSigninWithTempLinkArgs = {
  input: SigninWithTempLinkInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSignupArgs = {
  input: SignupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitFeedbackAnswersArgs = {
  input: SubmitFeedbackAnswersInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSubmitSurveyAnswersArgs = {
  input: SubmitSurveyAnswersInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountArgs = {
  input: UpdateAccountInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountByNameArgs = {
  input: UpdateAccountByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAccountByNodeIdArgs = {
  input: UpdateAccountByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateActivityArgs = {
  input: UpdateActivityInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateActivityByNodeIdArgs = {
  input: UpdateActivityByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefArgs = {
  input: UpdateBriefInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefByNodeIdArgs = {
  input: UpdateBriefByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupArgs = {
  input: UpdateBriefGroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupByNodeIdArgs = {
  input: UpdateBriefGroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupTypeMappingArgs = {
  input: UpdateBriefGroupTypeMappingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupTypeMappingByNodeIdArgs = {
  input: UpdateBriefGroupTypeMappingByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupVariableArgs = {
  input: UpdateBriefGroupVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupVariableByNodeIdArgs = {
  input: UpdateBriefGroupVariableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupVariableOptionArgs = {
  input: UpdateBriefGroupVariableOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefGroupVariableOptionByNodeIdArgs = {
  input: UpdateBriefGroupVariableOptionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefPresetArgs = {
  input: UpdateBriefPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBriefPresetByNodeIdArgs = {
  input: UpdateBriefPresetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassArgs = {
  input: UpdateClassInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassAssetCategoryArgs = {
  input: UpdateClassAssetCategoryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassAssetCategoryByNodeIdArgs = {
  input: UpdateClassAssetCategoryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassByNodeIdArgs = {
  input: UpdateClassByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassDocumentArgs = {
  input: UpdateClassDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassDocumentByClassIdAndDocumentIdArgs = {
  input: UpdateClassDocumentByClassIdAndDocumentIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassDocumentByNodeIdArgs = {
  input: UpdateClassDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassStarArgs = {
  input: UpdateClassStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassStarByClassIdAndUserIdArgs = {
  input: UpdateClassStarByClassIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassStarByNodeIdArgs = {
  input: UpdateClassStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassUserArgs = {
  input: UpdateClassUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassUserByClassIdAndUserIdArgs = {
  input: UpdateClassUserByClassIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassUserByNodeIdArgs = {
  input: UpdateClassUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoArgs = {
  input: UpdateClassVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoByClassIdAndVideoIdArgs = {
  input: UpdateClassVideoByClassIdAndVideoIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoByNodeIdArgs = {
  input: UpdateClassVideoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoUserArgs = {
  input: UpdateClassVideoUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoUserByClassVideoIdAndUserIdArgs = {
  input: UpdateClassVideoUserByClassVideoIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateClassVideoUserByNodeIdArgs = {
  input: UpdateClassVideoUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConnectionArgs = {
  input: UpdateConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConnectionByNodeIdArgs = {
  input: UpdateConnectionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCountryRegionArgs = {
  input: UpdateCountryRegionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCountryRegionByNameArgs = {
  input: UpdateCountryRegionByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCountryRegionByNodeIdArgs = {
  input: UpdateCountryRegionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerByNodeIdArgs = {
  input: UpdateCustomerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDivisionArgs = {
  input: UpdateDivisionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDivisionByNameAndV1IdArgs = {
  input: UpdateDivisionByNameAndV1IdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDivisionByNodeIdArgs = {
  input: UpdateDivisionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDocumentArgs = {
  input: UpdateDocumentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDocumentByNodeIdArgs = {
  input: UpdateDocumentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDocumentStarArgs = {
  input: UpdateDocumentStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDocumentStarByDocumentIdAndUserIdArgs = {
  input: UpdateDocumentStarByDocumentIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDocumentStarByNodeIdArgs = {
  input: UpdateDocumentStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventTypeArgs = {
  input: UpdateEventTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateEventTypeByNodeIdArgs = {
  input: UpdateEventTypeByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedbackQuestionArgs = {
  input: UpdateFeedbackQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedbackQuestionByNodeIdArgs = {
  input: UpdateFeedbackQuestionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateFeedbackQuestionByNumberAndQuestionArgs = {
  input: UpdateFeedbackQuestionByNumberAndQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleAccountUserArgs = {
  input: UpdateGoogleAccountUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleAccountUserByNodeIdArgs = {
  input: UpdateGoogleAccountUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarEventArgs = {
  input: UpdateGoogleCalendarEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateGoogleCalendarEventByNodeIdArgs = {
  input: UpdateGoogleCalendarEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndustryArgs = {
  input: UpdateIndustryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndustryByNameAndV1IdArgs = {
  input: UpdateIndustryByNameAndV1IdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateIndustryByNodeIdArgs = {
  input: UpdateIndustryByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameScoreArgs = {
  input: UpdateLv1GameScoreInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameScoreByNodeIdArgs = {
  input: UpdateLv1GameScoreByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameSubgroupArgs = {
  input: UpdateLv1GameSubgroupInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameSubgroupByNodeIdArgs = {
  input: UpdateLv1GameSubgroupByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameSubgroupUserArgs = {
  input: UpdateLv1GameSubgroupUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLv1GameSubgroupUserByNodeIdArgs = {
  input: UpdateLv1GameSubgroupUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMassUserUploadArgs = {
  input: UpdateMassUserUploadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateMassUserUploadByNodeIdArgs = {
  input: UpdateMassUserUploadByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateObjectiveByProjectIdAndNameArgs = {
  input: UpdateObjectiveByProjectIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectArgs = {
  input: UpdateProjectInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectAlignSurveyArgs = {
  input: UpdateProjectAlignSurveyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectAlignSurveyByNodeIdArgs = {
  input: UpdateProjectAlignSurveyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectByNodeIdArgs = {
  input: UpdateProjectByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommentArgs = {
  input: UpdateProjectCommentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommentByNodeIdArgs = {
  input: UpdateProjectCommentByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommunicationMessageArgs = {
  input: UpdateProjectCommunicationMessageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommunicationMessageByNodeIdArgs = {
  input: UpdateProjectCommunicationMessageByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommunicationQuestionArgs = {
  input: UpdateProjectCommunicationQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommunicationQuestionByNodeIdArgs = {
  input: UpdateProjectCommunicationQuestionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectCommunicationQuestionByProjectIdAndQuestionTypeArgs = {
  input: UpdateProjectCommunicationQuestionByProjectIdAndQuestionTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectComplianceArgs = {
  input: UpdateProjectComplianceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectComplianceByNodeIdArgs = {
  input: UpdateProjectComplianceByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectConnectionArgs = {
  input: UpdateProjectConnectionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectConnectionByNodeIdArgs = {
  input: UpdateProjectConnectionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectConnectionByProjectIdAndConnectionIdArgs = {
  input: UpdateProjectConnectionByProjectIdAndConnectionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectEventArgs = {
  input: UpdateProjectEventInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectEventByNodeIdArgs = {
  input: UpdateProjectEventByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectMembershipArgs = {
  input: UpdateProjectMembershipInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectMembershipByNodeIdArgs = {
  input: UpdateProjectMembershipByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectMembershipByProjectIdAndUserIdArgs = {
  input: UpdateProjectMembershipByProjectIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectOfferArgs = {
  input: UpdateProjectOfferInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectOfferByNodeIdArgs = {
  input: UpdateProjectOfferByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectOfferVariableArgs = {
  input: UpdateProjectOfferVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectOfferVariableByNodeIdArgs = {
  input: UpdateProjectOfferVariableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectPowerArgs = {
  input: UpdateProjectPowerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectPowerByNodeIdArgs = {
  input: UpdateProjectPowerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectReminderArgs = {
  input: UpdateProjectReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectReminderByNodeIdArgs = {
  input: UpdateProjectReminderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectRiskArgs = {
  input: UpdateProjectRiskInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectRiskByNodeIdArgs = {
  input: UpdateProjectRiskByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectScenarioArgs = {
  input: UpdateProjectScenarioInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectScenarioByNodeIdArgs = {
  input: UpdateProjectScenarioByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectScenarioVariableArgs = {
  input: UpdateProjectScenarioVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectScenarioVariableByNodeIdArgs = {
  input: UpdateProjectScenarioVariableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectSurveyArgs = {
  input: UpdateProjectSurveyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectSurveyByNodeIdArgs = {
  input: UpdateProjectSurveyByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectVariableArgs = {
  input: UpdateProjectVariableInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectVariableByNodeIdArgs = {
  input: UpdateProjectVariableByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateProjectVariablesRankArgs = {
  input: UpdateProjectVariablesRankInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateQuestionByProjectIdAndTypeArgs = {
  input: UpdateQuestionByProjectIdAndTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyBucketArgs = {
  input: UpdateSurveyBucketInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyBucketByNameArgs = {
  input: UpdateSurveyBucketByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyBucketByNodeIdArgs = {
  input: UpdateSurveyBucketByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyQuestionArgs = {
  input: UpdateSurveyQuestionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyQuestionAnswerArgs = {
  input: UpdateSurveyQuestionAnswerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyQuestionAnswerByNodeIdArgs = {
  input: UpdateSurveyQuestionAnswerByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyQuestionByNodeIdArgs = {
  input: UpdateSurveyQuestionByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyQuestionByQuestionAndSurveyTypeArgs = {
  input: UpdateSurveyQuestionByQuestionAndSurveyTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyReminderArgs = {
  input: UpdateSurveyReminderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyReminderByNodeIdArgs = {
  input: UpdateSurveyReminderByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyResponseArgs = {
  input: UpdateSurveyResponseInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyResponseByNodeIdArgs = {
  input: UpdateSurveyResponseByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSurveyResponseByUserIdAndRecipientIdAndSurveyTypeArgs = {
  input: UpdateSurveyResponseByUserIdAndRecipientIdAndSurveyTypeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByEmailArgs = {
  input: UpdateUserByEmailInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByNodeIdArgs = {
  input: UpdateUserByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserLanguageByLanguageCodeArgs = {
  input: UpdateUserLanguageByLanguageCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserLanguageByNameArgs = {
  input: UpdateUserLanguageByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPasswordArgs = {
  input: UpdateUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileQuestionnaireArgs = {
  input: UpdateUserProfileQuestionnaireInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileQuestionnaireByNodeIdArgs = {
  input: UpdateUserProfileQuestionnaireByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserProfileQuestionnaireByUserIdArgs = {
  input: UpdateUserProfileQuestionnaireByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateV1RoleArgs = {
  input: UpdateV1RoleInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateV1RoleByIdAndNameArgs = {
  input: UpdateV1RoleByIdAndNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateV1RoleByNodeIdArgs = {
  input: UpdateV1RoleByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVariableClassArgs = {
  input: UpdateVariableClassInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVariableClassByNodeIdArgs = {
  input: UpdateVariableClassByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVideoArgs = {
  input: UpdateVideoInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVideoByNodeIdArgs = {
  input: UpdateVideoByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVideoStarArgs = {
  input: UpdateVideoStarInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVideoStarByNodeIdArgs = {
  input: UpdateVideoStarByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateVideoStarByVideoIdAndUserIdArgs = {
  input: UpdateVideoStarByVideoIdAndUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkshopArgs = {
  input: UpdateWorkshopInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkshopByNodeIdArgs = {
  input: UpdateWorkshopByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkshopPresetArgs = {
  input: UpdateWorkshopPresetInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateWorkshopPresetByNodeIdArgs = {
  input: UpdateWorkshopPresetByNodeIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationV1SsoSigninArgs = {
  input: V1SsoSigninInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVariableClassMergeVariablesArgs = {
  input: VariableClassMergeVariablesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVideoSetIsStarredArgs = {
  input: VideoSetIsStarredInput;
};

export enum NegotiationType {
  Bargaining = 'BARGAINING',
  BargainingDistributive = 'BARGAINING_DISTRIBUTIVE',
  BargainingIntegrative = 'BARGAINING_INTEGRATIVE',
  Distributive = 'DISTRIBUTIVE',
  DistributiveBargaining = 'DISTRIBUTIVE_BARGAINING',
  DistributiveTrading = 'DISTRIBUTIVE_TRADING',
  Integrative = 'INTEGRATIVE',
  IntegrativeBargaining = 'INTEGRATIVE_BARGAINING',
  IntegrativeTrading = 'INTEGRATIVE_TRADING',
  Trading = 'TRADING',
  TradingDistributive = 'TRADING_DISTRIBUTIVE',
  TradingIntegrative = 'TRADING_INTEGRATIVE'
}

export enum NegotiatorStyle {
  Accommodating = 'ACCOMMODATING',
  Avoiding = 'AVOIDING',
  Collaborating = 'COLLABORATING',
  Competing = 'COMPETING',
  Compromising = 'COMPROMISING',
  Select = 'SELECT'
}

/** All input for the `nextClassVideoId` mutation. */
export type NextClassVideoIdInput = {
  c: ClassInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
};

/** The output of our `nextClassVideoId` mutation. */
export type NextClassVideoIdPayload = {
  __typename?: 'NextClassVideoIdPayload';
  /** Reads a single `Class` that is related to this `ClassVideo`. */
  class: Maybe<Class>;
  classVideo: Maybe<ClassVideo>;
  /** An edge for our `ClassVideo`. May be used by Relay 1. */
  classVideoEdge: Maybe<ClassVideosEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `ClassVideo`. */
  video: Maybe<Video>;
};


/** The output of our `nextClassVideoId` mutation. */
export type NextClassVideoIdPayloadClassVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

export enum ObjectivesType {
  CustomerAsk = 'CUSTOMER_ASK',
  Standard = 'STANDARD'
}

export enum OfferVariableList {
  Gets = 'GETS',
  Gives = 'GIVES',
  None = 'NONE'
}

/** A filter to be used against OfferVariableList fields. All fields are combined with a logical ‘and.’ */
export type OfferVariableListFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<OfferVariableList>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<OfferVariableList>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<OfferVariableList>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<OfferVariableList>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<OfferVariableList>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<OfferVariableList>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<OfferVariableList>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<OfferVariableList>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<OfferVariableList>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<OfferVariableList>>;
};

/** Coaching times of an organization - total, used, remaining, etc. */
export type OrganizationCoachingTime = Node & {
  __typename?: 'OrganizationCoachingTime';
  /** Reads a single `Account` that is related to this `OrganizationCoachingTime`. */
  account: Maybe<Account>;
  /** Which organization it belongs to */
  accountId: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** How much time is remaining (in minutes) */
  remaining: Scalars['Int'];
  /** How much time is assigned (in minutes) */
  total: Scalars['Int'];
  /** How much time is already used total (in minutes) */
  used: Scalars['Int'];
  /** How much time has been used in the current month (in minutes) */
  usedMonth: Scalars['Int'];
  /** How much time has been used in the current year (in minutes) */
  usedYear: Scalars['Int'];
};

/** The `account` to be created by this mutation. */
export type OrganizationCoachingTimeAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `OrganizationCoachingTimeInput` mutation. */
export type OrganizationCoachingTimeAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `organizationCoachingTime` in the `AccountInput` mutation. */
export type OrganizationCoachingTimeAccountIdFkeyInverseInput = {
  /** The primary key(s) for `organizationCoachingTime` for the far side of the relationship. */
  connectByAccountId?: InputMaybe<OrganizationCoachingTimeOrganizationCoachingTimePkeyConnect>;
  /** The primary key(s) for `organizationCoachingTime` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<OrganizationCoachingTimeNodeIdConnect>;
  /** The primary key(s) and patch data for `organizationCoachingTime` for the far side of the relationship. */
  updateByAccountId?: InputMaybe<OrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingOrganizationCoachingTimePkeyUpdate>;
  /** The primary key(s) and patch data for `organizationCoachingTime` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyNodeIdUpdate>;
};

/**
 * A condition to be used against `OrganizationCoachingTime` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCoachingTimeCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `OrganizationCoachingTime` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationCoachingTimeFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationCoachingTimeFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationCoachingTimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationCoachingTimeFilter>>;
};

/** A connection to a list of `OrganizationCoachingTimeHistory` values. */
export type OrganizationCoachingTimeHistoriesConnection = {
  __typename?: 'OrganizationCoachingTimeHistoriesConnection';
  /** A list of edges which contains the `OrganizationCoachingTimeHistory` and cursor to aid in pagination. */
  edges: Array<OrganizationCoachingTimeHistoriesEdge>;
  /** A list of `OrganizationCoachingTimeHistory` objects. */
  nodes: Array<OrganizationCoachingTimeHistory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationCoachingTimeHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationCoachingTimeHistory` edge in the connection. */
export type OrganizationCoachingTimeHistoriesEdge = {
  __typename?: 'OrganizationCoachingTimeHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `OrganizationCoachingTimeHistory` at the end of the edge. */
  node: OrganizationCoachingTimeHistory;
};

/** Methods to use when ordering `OrganizationCoachingTimeHistory`. */
export enum OrganizationCoachingTimeHistoriesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  Natural = 'NATURAL'
}

/** History of addition and subtraction of coaching time per organization. */
export type OrganizationCoachingTimeHistory = {
  __typename?: 'OrganizationCoachingTimeHistory';
  /** Reads a single `Account` that is related to this `OrganizationCoachingTimeHistory`. */
  account: Maybe<Account>;
  /** Which organization it belongs to */
  accountId: Scalars['UUID'];
  /** How much time (in minutes) was added (positive values) or removed (negative values) */
  amount: Scalars['Int'];
  /** When did it happen? */
  createdAt: Scalars['Datetime'];
  /** How did it happen? */
  createdBy: Scalars['UUID'];
  operation: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `OrganizationCoachingTimeHistory`. */
  userByCreatedBy: Maybe<User>;
};

/**
 * A condition to be used against `OrganizationCoachingTimeHistory` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCoachingTimeHistoryCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `OrganizationCoachingTimeHistory` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationCoachingTimeHistoryFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationCoachingTimeHistoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationCoachingTimeHistoryFilter>>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
};

/** A list of coaching call logs per organization */
export type OrganizationCoachingTimeLog = Node & {
  __typename?: 'OrganizationCoachingTimeLog';
  /** Reads a single `Account` that is related to this `OrganizationCoachingTimeLog`. */
  account: Maybe<Account>;
  /** Which organization it belongs to */
  accountId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `OrganizationCoachingTimeLog`. */
  consultant: Maybe<User>;
  /** Who was the coaching consultant */
  consultantId: Scalars['UUID'];
  /** When was this entry added */
  createdAt: Scalars['Datetime'];
  /** How long did it take (in minutes) */
  duration: Scalars['Int'];
  /** Date when the coaching happened */
  happenedAt: Scalars['Datetime'];
  /** Primary ID for the log */
  id: Scalars['Int'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Any notes that were added */
  notes: Maybe<Scalars['String']>;
  /** Returns team_members array as a setof mc_public.user records to be used in GraphQL queries (Client Contact) */
  participants: UsersConnection;
  /** Returns team_members array as a setof mc_public.user records to be used in GraphQL queries (Client Contact) */
  participantsList: Maybe<Array<User>>;
  /** Reads a single `Project` that is related to this `OrganizationCoachingTimeLog`. */
  project: Maybe<Project>;
  /** Project related to this coaching time log */
  projectId: Maybe<Scalars['UUID']>;
  /** Who were the participating team members */
  teamMembers: Array<Maybe<Scalars['UUID']>>;
};


/** A list of coaching call logs per organization */
export type OrganizationCoachingTimeLogParticipantsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** A list of coaching call logs per organization */
export type OrganizationCoachingTimeLogParticipantsListArgs = {
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

/** The `account` to be created by this mutation. */
export type OrganizationCoachingTimeLogAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `OrganizationCoachingTimeLogInput` mutation. */
export type OrganizationCoachingTimeLogAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `organizationCoachingTimeLog` in the `AccountInput` mutation. */
export type OrganizationCoachingTimeLogAccountIdFkeyInverseInput = {
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OrganizationCoachingTimeLogOrganizationCoachingTimeLogPkeyConnect>>;
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OrganizationCoachingTimeLogNodeIdConnect>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateById?: InputMaybe<Array<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyNodeIdUpdate>>;
};

/**
 * A condition to be used against `OrganizationCoachingTimeLog` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCoachingTimeLogCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `consultantId` field. */
  consultantId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `user` in the `OrganizationCoachingTimeLogInput` mutation. */
export type OrganizationCoachingTimeLogConsultantIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `organizationCoachingTimeLog` in the `UserInput` mutation. */
export type OrganizationCoachingTimeLogConsultantIdFkeyInverseInput = {
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OrganizationCoachingTimeLogOrganizationCoachingTimeLogPkeyConnect>>;
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OrganizationCoachingTimeLogNodeIdConnect>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateById?: InputMaybe<Array<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyNodeIdUpdate>>;
};

/** A filter to be used against `OrganizationCoachingTimeLog` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationCoachingTimeLogFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationCoachingTimeLogFilter>>;
  /** Filter by the object’s `consultant` relation. */
  consultant?: InputMaybe<UserFilter>;
  /** Filter by the object’s `consultantId` field. */
  consultantId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationCoachingTimeLogFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** A related `project` exists. */
  projectExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationCoachingTimeLogNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTimeLog` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `organizationCoachingTimeLog` to look up the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate = {
  /** Primary ID for the log */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `organizationCoachingTimeLog` to look up the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate = {
  /** Primary ID for the log */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `organizationCoachingTimeLog` to look up the row to update. */
export type OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate = {
  /** Primary ID for the log */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyPatch;
};

/** The fields on `organizationCoachingTimeLog` to look up the row to connect. */
export type OrganizationCoachingTimeLogOrganizationCoachingTimeLogPkeyConnect = {
  /** Primary ID for the log */
  id: Scalars['Int'];
};

/** Represents an update to a `OrganizationCoachingTimeLog`. Fields that are set will be updated. */
export type OrganizationCoachingTimeLogPatch = {
  /** Which organization it belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInput>;
  /** Who was the coaching consultant */
  consultantId?: InputMaybe<Scalars['UUID']>;
  /** When was this entry added */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** How long did it take (in minutes) */
  duration?: InputMaybe<Scalars['Int']>;
  /** Date when the coaching happened */
  happenedAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID for the log */
  id?: InputMaybe<Scalars['Int']>;
  /** Any notes that were added */
  notes?: InputMaybe<Scalars['String']>;
  /** Project related to this coaching time log */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInput>;
  /** Who were the participating team members */
  teamMembers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userToConsultantId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInput>;
};

/** Input for the nested mutation of `project` in the `OrganizationCoachingTimeLogInput` mutation. */
export type OrganizationCoachingTimeLogProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `organizationCoachingTimeLog` in the `ProjectInput` mutation. */
export type OrganizationCoachingTimeLogProjectIdFkeyInverseInput = {
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OrganizationCoachingTimeLogOrganizationCoachingTimeLogPkeyConnect>>;
  /** The primary key(s) for `organizationCoachingTimeLog` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OrganizationCoachingTimeLogNodeIdConnect>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateById?: InputMaybe<Array<OrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyUsingOrganizationCoachingTimeLogPkeyUpdate>>;
  /** The primary key(s) and patch data for `organizationCoachingTimeLog` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type OrganizationCoachingTimeLogProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** A connection to a list of `OrganizationCoachingTimeLog` values. */
export type OrganizationCoachingTimeLogsConnection = {
  __typename?: 'OrganizationCoachingTimeLogsConnection';
  /** A list of edges which contains the `OrganizationCoachingTimeLog` and cursor to aid in pagination. */
  edges: Array<OrganizationCoachingTimeLogsEdge>;
  /** A list of `OrganizationCoachingTimeLog` objects. */
  nodes: Array<OrganizationCoachingTimeLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationCoachingTimeLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationCoachingTimeLog` edge in the connection. */
export type OrganizationCoachingTimeLogsEdge = {
  __typename?: 'OrganizationCoachingTimeLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `OrganizationCoachingTimeLog` at the end of the edge. */
  node: OrganizationCoachingTimeLog;
};

/** Methods to use when ordering `OrganizationCoachingTimeLog`. */
export enum OrganizationCoachingTimeLogsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  ConsultantIdAsc = 'CONSULTANT_ID_ASC',
  ConsultantIdDesc = 'CONSULTANT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationCoachingTimeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTime` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `organizationCoachingTime` to look up the row to update. */
export type OrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyUsingOrganizationCoachingTimePkeyUpdate = {
  /** Which organization it belongs to */
  accountId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `organizationCoachingTime` being updated. */
  patch: UpdateOrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyPatch;
};

/** The fields on `organizationCoachingTime` to look up the row to connect. */
export type OrganizationCoachingTimeOrganizationCoachingTimePkeyConnect = {
  /** Which organization it belongs to */
  accountId: Scalars['UUID'];
};

/** Represents an update to a `OrganizationCoachingTime`. Fields that are set will be updated. */
export type OrganizationCoachingTimePatch = {
  /** Which organization it belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInput>;
  /** How much time is remaining (in minutes) */
  remaining?: InputMaybe<Scalars['Int']>;
  /** How much time is assigned (in minutes) */
  total?: InputMaybe<Scalars['Int']>;
  /** How much time is already used total (in minutes) */
  used?: InputMaybe<Scalars['Int']>;
  /** How much time has been used in the current month (in minutes) */
  usedMonth?: InputMaybe<Scalars['Int']>;
  /** How much time has been used in the current year (in minutes) */
  usedYear?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `OrganizationCoachingTime` values. */
export type OrganizationCoachingTimesConnection = {
  __typename?: 'OrganizationCoachingTimesConnection';
  /** A list of edges which contains the `OrganizationCoachingTime` and cursor to aid in pagination. */
  edges: Array<OrganizationCoachingTimesEdge>;
  /** A list of `OrganizationCoachingTime` objects. */
  nodes: Array<OrganizationCoachingTime>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationCoachingTime` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `OrganizationCoachingTime` edge in the connection. */
export type OrganizationCoachingTimesEdge = {
  __typename?: 'OrganizationCoachingTimesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `OrganizationCoachingTime` at the end of the edge. */
  node: OrganizationCoachingTime;
};

/** Methods to use when ordering `OrganizationCoachingTime`. */
export enum OrganizationCoachingTimesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars['Cursor']>;
};

export enum PeriodName {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

/** Projects, categorized in folders. */
export type Project = Node & {
  __typename?: 'Project';
  /** Reads a single `Account` that is related to this `Project`. */
  account: Maybe<Account>;
  /** Account ID - which organization this project belongs to */
  accountId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `Project`. */
  childProjects: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  childProjectsList: Array<Project>;
  /** When was this folder creatd? */
  createdAt: Scalars['Datetime'];
  /** User who created this folder */
  createdBy: Scalars['UUID'];
  /** Reads and enables pagination through a set of `CurrentProjectOfferVariable`. */
  currentOfferVariables: CurrentProjectOfferVariablesConnection;
  /** Reads and enables pagination through a set of `CurrentProjectOfferVariable`. */
  currentOfferVariablesList: Maybe<Array<CurrentProjectOfferVariable>>;
  /** Reads a single `Customer` that is related to this `Project`. */
  customer: Maybe<Customer>;
  /** Customer related to the project */
  customerId: Maybe<Scalars['UUID']>;
  /** File type: p for project or f for folder */
  fileType: Scalars['String'];
  /** Given project's Folder ID for for the current user */
  folderId: Maybe<Scalars['UUID']>;
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** A computed column isActive that shows if the project has been modified in the past 30 days */
  isActive: Maybe<Scalars['Boolean']>;
  isArchived: Maybe<Scalars['Boolean']>;
  /** Is this folder deleted? */
  isDeleted: Scalars['Boolean'];
  /** Has the negotiation been closed already? */
  isNegotiationClosed: Maybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen: Maybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen: Maybe<Scalars['Boolean']>;
  /** Is the current project shared with others */
  isShared: Maybe<Scalars['Boolean']>;
  /** Last time when the project was modified */
  lastModify: Maybe<Scalars['Datetime']>;
  /** Last time when the currently logged in user modified the project */
  lastModifyByMe: Maybe<Scalars['Datetime']>;
  /** Last time when the currently logged in user accessed the project */
  lastOpenByMe: Maybe<Scalars['Datetime']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Determines what type of objectives form we will display */
  objectivesType: ObjectivesType;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogs: OrganizationCoachingTimeLogsConnection;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogsList: Array<OrganizationCoachingTimeLog>;
  /** Reads a single `Project` that is related to this `Project`. */
  parent: Maybe<Project>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `ProjectAccessLog`. */
  projectAccessLogs: ProjectAccessLogsConnection;
  /** Reads and enables pagination through a set of `ProjectAccessLog`. */
  projectAccessLogsList: Array<ProjectAccessLog>;
  /** Reads a single `ProjectAlignSurvey` that is related to this `Project`. */
  projectAlignSurvey: Maybe<ProjectAlignSurvey>;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  projectComments: ProjectCommentsConnection;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  projectCommentsList: Array<ProjectComment>;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessages: ProjectCommunicationMessagesConnection;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesList: Array<ProjectCommunicationMessage>;
  /** Reads and enables pagination through a set of `ProjectCommunicationQuestion`. */
  projectCommunicationQuestions: ProjectCommunicationQuestionsConnection;
  /** Reads and enables pagination through a set of `ProjectCommunicationQuestion`. */
  projectCommunicationQuestionsList: Array<ProjectCommunicationQuestion>;
  /** Reads and enables pagination through a set of `ProjectCompliance`. */
  projectCompliances: ProjectCompliancesConnection;
  /** Reads and enables pagination through a set of `ProjectCompliance`. */
  projectCompliancesList: Array<ProjectCompliance>;
  /** Reads and enables pagination through a set of `ProjectConnection`. */
  projectConnections: ProjectConnectionsConnection;
  /** Reads and enables pagination through a set of `ProjectConnection`. */
  projectConnectionsList: Array<ProjectConnection>;
  /** Reads and enables pagination through a set of `ProjectEvent`. */
  projectEvents: ProjectEventsConnection;
  /** Reads and enables pagination through a set of `ProjectEvent`. */
  projectEventsList: Array<ProjectEvent>;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFolders: ProjectFoldersConnection;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFoldersByFolderId: ProjectFoldersConnection;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFoldersByFolderIdList: Array<ProjectFolder>;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFoldersList: Array<ProjectFolder>;
  /** Reads and enables pagination through a set of `ProjectInvitation`. */
  projectInvitations: ProjectInvitationsConnection;
  /** Reads and enables pagination through a set of `ProjectInvitation`. */
  projectInvitationsList: Array<ProjectInvitation>;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMemberships: ProjectMembershipsConnection;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMembershipsList: Array<ProjectMembership>;
  /** Reads and enables pagination through a set of `ProjectObjective`. */
  projectObjectives: ProjectObjectivesConnection;
  /** Reads and enables pagination through a set of `ProjectObjective`. */
  projectObjectivesList: Array<ProjectObjective>;
  /** Reads and enables pagination through a set of `ProjectOffer`. */
  projectOffers: ProjectOffersConnection;
  /** Reads and enables pagination through a set of `ProjectOffer`. */
  projectOffersList: Array<ProjectOffer>;
  /** Reads a single `ProjectPower` that is related to this `Project`. */
  projectPower: Maybe<ProjectPower>;
  /** Reads and enables pagination through a set of `ProjectRisk`. */
  projectRisks: ProjectRisksConnection;
  /** Reads and enables pagination through a set of `ProjectRisk`. */
  projectRisksList: Array<ProjectRisk>;
  /** Reads and enables pagination through a set of `ProjectScenario`. */
  projectScenarios: ProjectScenariosConnection;
  /** Reads and enables pagination through a set of `ProjectScenario`. */
  projectScenariosList: Array<ProjectScenario>;
  /** Reads and enables pagination through a set of `ProjectScore`. */
  projectScores: ProjectScoresConnection;
  /** Reads and enables pagination through a set of `ProjectScore`. */
  projectScoresList: Array<ProjectScore>;
  /** Reads a single `ProjectSearch` that is related to this `Project`. */
  projectSearch: Maybe<ProjectSearch>;
  /** Reads a single `ProjectSurvey` that is related to this `Project`. */
  projectSurvey: Maybe<ProjectSurvey>;
  /** Reads and enables pagination through a set of `ProjectVariable`. */
  projectVariables: ProjectVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectVariable`. */
  projectVariablesList: Array<ProjectVariable>;
  /** Summary of project scores */
  scoreSummary: ProjectScoreSummariesConnection;
  /** Summary of project scores */
  scoreSummaryList: Maybe<Array<ProjectScoreSummary>>;
  /** Text search vector for the project title */
  titleTsv: Maybe<Scalars['FullText']>;
  /** Full-text search ranking when filtered by `titleTsv`. */
  titleTsvRank: Maybe<Scalars['Float']>;
  /** When was this folder last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Project`. */
  userByCreatedBy: Maybe<User>;
  /** Is the currently logged in user owner of this project */
  userIsOwner: Maybe<Scalars['Boolean']>;
  /** Project value */
  value: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClassesByRelatedProjectId: VariableClassesConnection;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClassesByRelatedProjectIdList: Array<VariableClass>;
};


/** Projects, categorized in folders. */
export type ProjectChildProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectChildProjectsListArgs = {
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectCurrentOfferVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<CurrentProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Projects, categorized in folders. */
export type ProjectCurrentOfferVariablesListArgs = {
  filter?: InputMaybe<CurrentProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Projects, categorized in folders. */
export type ProjectOrganizationCoachingTimeLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectOrganizationCoachingTimeLogsListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectAccessLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectAccessLogsListArgs = {
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommentsListArgs = {
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommunicationMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommunicationMessagesListArgs = {
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommunicationQuestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationQuestionCondition>;
  filter?: InputMaybe<ProjectCommunicationQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCommunicationQuestionsListArgs = {
  condition?: InputMaybe<ProjectCommunicationQuestionCondition>;
  filter?: InputMaybe<ProjectCommunicationQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCompliancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectComplianceCondition>;
  filter?: InputMaybe<ProjectComplianceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectCompliancesListArgs = {
  condition?: InputMaybe<ProjectComplianceCondition>;
  filter?: InputMaybe<ProjectComplianceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectConnectionsListArgs = {
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectEventsListArgs = {
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectFoldersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectFoldersByFolderIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectFoldersByFolderIdListArgs = {
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectFoldersListArgs = {
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectInvitationsListArgs = {
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectMembershipsListArgs = {
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectObjectivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectObjectiveCondition>;
  filter?: InputMaybe<ProjectObjectiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectObjectivesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectObjectivesListArgs = {
  condition?: InputMaybe<ProjectObjectiveCondition>;
  filter?: InputMaybe<ProjectObjectiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectObjectivesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectOfferCondition>;
  filter?: InputMaybe<ProjectOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectOffersListArgs = {
  condition?: InputMaybe<ProjectOfferCondition>;
  filter?: InputMaybe<ProjectOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectRisksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectRiskCondition>;
  filter?: InputMaybe<ProjectRiskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectRisksListArgs = {
  condition?: InputMaybe<ProjectRiskCondition>;
  filter?: InputMaybe<ProjectRiskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScenarioCondition>;
  filter?: InputMaybe<ProjectScenarioFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectScenariosListArgs = {
  condition?: InputMaybe<ProjectScenarioCondition>;
  filter?: InputMaybe<ProjectScenarioFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScoreCondition>;
  filter?: InputMaybe<ProjectScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScoresOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectScoresListArgs = {
  condition?: InputMaybe<ProjectScoreCondition>;
  filter?: InputMaybe<ProjectScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScoresOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectProjectVariablesListArgs = {
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectScoreSummaryArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Projects, categorized in folders. */
export type ProjectScoreSummaryListArgs = {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** Projects, categorized in folders. */
export type ProjectVariableClassesByRelatedProjectIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** Projects, categorized in folders. */
export type ProjectVariableClassesByRelatedProjectIdListArgs = {
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};

/** When was a project accessed by a given user. */
export type ProjectAccessLog = {
  __typename?: 'ProjectAccessLog';
  /** When was the project opened? */
  accessAt: Scalars['Datetime'];
  /** When was the project modified by this user? */
  modifiedAt: Scalars['Datetime'];
  /** Reads a single `Project` that is related to this `ProjectAccessLog`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `ProjectAccessLog`. */
  user: Maybe<User>;
  /** User ID */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProjectAccessLog` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectAccessLogCondition = {
  /** Checks for equality with the object’s `accessAt` field. */
  accessAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectAccessLog` object types. All fields are combined with a logical ‘and.’ */
export type ProjectAccessLogFilter = {
  /** Filter by the object’s `accessAt` field. */
  accessAt?: InputMaybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectAccessLogFilter>>;
  /** Filter by the object’s `modifiedAt` field. */
  modifiedAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectAccessLogFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectAccessLogFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectAccessLog` to look up the row to update. */
export type ProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyUsingUniqProjectUserUpdate = {
  /** An object where the defined keys will be set on the `projectAccessLog` being updated. */
  patch: UpdateProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectAccessLog` to look up the row to update. */
export type ProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUniqProjectUserUpdate = {
  /** An object where the defined keys will be set on the `projectAccessLog` being updated. */
  patch: UpdateProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** All input for the `projectAccessLogOpen` mutation. */
export type ProjectAccessLogOpenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['UUID'];
};

/** The output of our `projectAccessLogOpen` mutation. */
export type ProjectAccessLogOpenPayload = {
  __typename?: 'ProjectAccessLogOpenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Input for the nested mutation of `project` in the `ProjectAccessLogInput` mutation. */
export type ProjectAccessLogProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectAccessLogProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectAccessLogForProjectAccessLogProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectAccessLog` in the `ProjectInput` mutation. */
export type ProjectAccessLogProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectAccessLog` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectAccessLogUniqProjectUserConnect>>;
  /** The primary key(s) and patch data for `projectAccessLog` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyUsingUniqProjectUserUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectAccessLogProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectAccessLog` to look up the row to connect. */
export type ProjectAccessLogUniqProjectUserConnect = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** All input for the `projectAccessLogUpdate` mutation. */
export type ProjectAccessLogUpdateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectId: Scalars['UUID'];
};

/** The output of our `projectAccessLogUpdate` mutation. */
export type ProjectAccessLogUpdatePayload = {
  __typename?: 'ProjectAccessLogUpdatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** Input for the nested mutation of `user` in the `ProjectAccessLogInput` mutation. */
export type ProjectAccessLogUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectAccessLog` in the `UserInput` mutation. */
export type ProjectAccessLogUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectAccessLog` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectAccessLogUniqProjectUserConnect>>;
  /** The primary key(s) and patch data for `projectAccessLog` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUniqProjectUserUpdate>>;
};

/** A connection to a list of `ProjectAccessLog` values. */
export type ProjectAccessLogsConnection = {
  __typename?: 'ProjectAccessLogsConnection';
  /** A list of edges which contains the `ProjectAccessLog` and cursor to aid in pagination. */
  edges: Array<ProjectAccessLogsEdge>;
  /** A list of `ProjectAccessLog` objects. */
  nodes: Array<ProjectAccessLog>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectAccessLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectAccessLog` edge in the connection. */
export type ProjectAccessLogsEdge = {
  __typename?: 'ProjectAccessLogsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectAccessLog` at the end of the edge. */
  node: ProjectAccessLog;
};

/** Methods to use when ordering `ProjectAccessLog`. */
export enum ProjectAccessLogsOrderBy {
  AccessAtAsc = 'ACCESS_AT_ASC',
  AccessAtDesc = 'ACCESS_AT_DESC',
  ModifiedAtAsc = 'MODIFIED_AT_ASC',
  ModifiedAtDesc = 'MODIFIED_AT_DESC',
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The `account` to be created by this mutation. */
export type ProjectAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `ProjectInput` mutation. */
export type ProjectAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnProjectForProjectAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnProjectForProjectAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectForProjectAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `project` in the `AccountInput` mutation. */
export type ProjectAccountIdFkeyInverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectProjectPkeyConnect>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectNodeIdConnect>>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectAccountIdFkeyProjectCreateInput>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectProjectPkeyDelete>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectNodeIdDelete>>;
  /** Flag indicating whether all other `project` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOnProjectForProjectAccountIdFkeyUsingProjectPkeyUpdate>>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnProjectForProjectAccountIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectAccountIdFkeyProjectCreateInput = {
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Project Align Survey */
export type ProjectAlignSurvey = Node & {
  __typename?: 'ProjectAlignSurvey';
  /** JSON with all the answers */
  answers: Maybe<Scalars['JSON']>;
  /** When was this project align survey created? */
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectAlignSurvey`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** When was this project align survey last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectAlignSurvey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectAlignSurveyCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectAlignSurvey` object types. All fields are combined with a logical ‘and.’ */
export type ProjectAlignSurveyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectAlignSurveyFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectAlignSurveyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectAlignSurveyFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectAlignSurveyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectAlignSurvey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectAlignSurvey` to look up the row to update. */
export type ProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyUsingProjectAlignSurveyPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectAlignSurvey` being updated. */
  patch: UpdateProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectAlignSurvey`. Fields that are set will be updated. */
export type ProjectAlignSurveyPatch = {
  /** JSON with all the answers */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project align survey created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInput>;
  /** When was this project align survey last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectAlignSurvey` to look up the row to connect. */
export type ProjectAlignSurveyProjectAlignSurveyPkeyConnect = {
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectAlignSurveyInput` mutation. */
export type ProjectAlignSurveyProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectAlignSurveyProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectAlignSurvey` in the `ProjectInput` mutation. */
export type ProjectAlignSurveyProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectAlignSurvey` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectAlignSurveyNodeIdConnect>;
  /** The primary key(s) for `projectAlignSurvey` for the far side of the relationship. */
  connectByProjectId?: InputMaybe<ProjectAlignSurveyProjectAlignSurveyPkeyConnect>;
  /** The primary key(s) and patch data for `projectAlignSurvey` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectAlignSurvey` for the far side of the relationship. */
  updateByProjectId?: InputMaybe<ProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyUsingProjectAlignSurveyPkeyUpdate>;
};

/** The `project` to be created by this mutation. */
export type ProjectAlignSurveyProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** A connection to a list of `ProjectAlignSurvey` values. */
export type ProjectAlignSurveysConnection = {
  __typename?: 'ProjectAlignSurveysConnection';
  /** A list of edges which contains the `ProjectAlignSurvey` and cursor to aid in pagination. */
  edges: Array<ProjectAlignSurveysEdge>;
  /** A list of `ProjectAlignSurvey` objects. */
  nodes: Array<ProjectAlignSurvey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectAlignSurvey` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectAlignSurvey` edge in the connection. */
export type ProjectAlignSurveysEdge = {
  __typename?: 'ProjectAlignSurveysEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectAlignSurvey` at the end of the edge. */
  node: ProjectAlignSurvey;
};

/** Methods to use when ordering `ProjectAlignSurvey`. */
export enum ProjectAlignSurveysOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** Project comments */
export type ProjectComment = Node & {
  __typename?: 'ProjectComment';
  /** Reads and enables pagination through a set of `ProjectComment`. */
  childProjectComments: ProjectCommentsConnection;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  childProjectCommentsList: Array<ProjectComment>;
  /** Comment body content */
  content: Scalars['JSON'];
  /** When was this comment added? */
  createdAt: Scalars['Datetime'];
  /** Unique ID for the record */
  id: Scalars['BigInt'];
  /** Is this comment resolved (archived)? */
  isArchived: Scalars['Boolean'];
  /** Is this comment deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `ProjectComment` that is related to this `ProjectComment`. */
  parent: Maybe<ProjectComment>;
  /** If this is a reply, we store the parent comment id */
  parentId: Maybe<Scalars['BigInt']>;
  /** Reads a single `Project` that is related to this `ProjectComment`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** Project section - variables, negotiate, etc. */
  section: ProjectSection;
  /** When was this comment updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ProjectComment`. */
  user: Maybe<User>;
  /** User ID of the comment author */
  userId: Scalars['UUID'];
};


/** Project comments */
export type ProjectCommentChildProjectCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** Project comments */
export type ProjectCommentChildProjectCommentsListArgs = {
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};

/**
 * A condition to be used against `ProjectComment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectCommentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectComment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectCommentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectCommentFilter>>;
  /** Filter by the object’s `childProjectComments` relation. */
  childProjectComments?: InputMaybe<ProjectCommentToManyProjectCommentFilter>;
  /** Some related `childProjectComments` exist. */
  childProjectCommentsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectCommentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectCommentFilter>>;
  /** Filter by the object’s `parent` relation. */
  parent?: InputMaybe<ProjectCommentFilter>;
  /** A related `parent` exists. */
  parentExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ProjectComment` */
export type ProjectCommentInput = {
  /** Comment body content */
  content: Scalars['JSON'];
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section: ProjectSection;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectCommentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectComment` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentParentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectComment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: ProjectCommentPatch;
};

/** The fields on `projectComment` to look up the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentParentIdFkeyUsingProjectCommentPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: UpdateProjectCommentOnProjectCommentForProjectCommentParentIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectComment` to look up the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyUsingProjectCommentPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: UpdateProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectComment` to look up the row to update. */
export type ProjectCommentOnProjectCommentForProjectCommentUserIdFkeyUsingProjectCommentPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: UpdateProjectCommentOnProjectCommentForProjectCommentUserIdFkeyPatch;
};

/** Input for the nested mutation of `projectComment` in the `ProjectCommentInput` mutation. */
export type ProjectCommentParentIdFkeyInput = {
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectCommentProjectCommentPkeyConnect>;
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectCommentNodeIdConnect>;
  /** A `ProjectCommentInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommentParentIdFkeyProjectCommentCreateInput>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectCommentOnProjectCommentForProjectCommentParentIdFkeyUsingProjectCommentPkeyUpdate>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommentOnProjectCommentForProjectCommentParentIdFkeyNodeIdUpdate>;
};

/** The `projectComment` to be created by this mutation. */
export type ProjectCommentParentIdFkeyProjectCommentCreateInput = {
  /** Comment body content */
  content: Scalars['JSON'];
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section: ProjectSection;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** Represents an update to a `ProjectComment`. Fields that are set will be updated. */
export type ProjectCommentPatch = {
  /** Comment body content */
  content?: InputMaybe<Scalars['JSON']>;
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section?: InputMaybe<ProjectSection>;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** The fields on `projectComment` to look up the row to connect. */
export type ProjectCommentProjectCommentPkeyConnect = {
  /** Unique ID for the record */
  id: Scalars['BigInt'];
};

/** Input for the nested mutation of `project` in the `ProjectCommentInput` mutation. */
export type ProjectCommentProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommentProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectCommentForProjectCommentProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectComment` in the `ProjectInput` mutation. */
export type ProjectCommentProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommentProjectCommentPkeyConnect>>;
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommentNodeIdConnect>>;
  /** A `ProjectCommentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommentProjectIdFkeyProjectCommentCreateInput>>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyUsingProjectCommentPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectCommentForProjectCommentProjectIdFkeyNodeIdUpdate>>;
};

/** The `projectComment` to be created by this mutation. */
export type ProjectCommentProjectIdFkeyProjectCommentCreateInput = {
  /** Comment body content */
  content: Scalars['JSON'];
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section: ProjectSection;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** The `project` to be created by this mutation. */
export type ProjectCommentProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

export type ProjectCommentSubscriptionPayload = {
  __typename?: 'ProjectCommentSubscriptionPayload';
  event: Maybe<Scalars['String']>;
  projectComment: Maybe<ProjectComment>;
};

/** A filter to be used against many `ProjectComment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectCommentToManyProjectCommentFilter = {
  /** Every related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommentFilter>;
  /** No related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommentFilter>;
  /** Some related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommentFilter>;
};

/** Input for the nested mutation of `user` in the `ProjectCommentInput` mutation. */
export type ProjectCommentUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectCommentForProjectCommentUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectCommentForProjectCommentUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommentOnProjectCommentForProjectCommentUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectComment` in the `UserInput` mutation. */
export type ProjectCommentUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommentProjectCommentPkeyConnect>>;
  /** The primary key(s) for `projectComment` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommentNodeIdConnect>>;
  /** A `ProjectCommentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommentUserIdFkeyProjectCommentCreateInput>>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommentOnProjectCommentForProjectCommentUserIdFkeyUsingProjectCommentPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectComment` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnProjectCommentForProjectCommentUserIdFkeyNodeIdUpdate>>;
};

/** The `projectComment` to be created by this mutation. */
export type ProjectCommentUserIdFkeyProjectCommentCreateInput = {
  /** Comment body content */
  content: Scalars['JSON'];
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section: ProjectSection;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** A connection to a list of `ProjectComment` values. */
export type ProjectCommentsConnection = {
  __typename?: 'ProjectCommentsConnection';
  /** A list of edges which contains the `ProjectComment` and cursor to aid in pagination. */
  edges: Array<ProjectCommentsEdge>;
  /** A list of `ProjectComment` objects. */
  nodes: Array<ProjectComment>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectComment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectComment` edge in the connection. */
export type ProjectCommentsEdge = {
  __typename?: 'ProjectCommentsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectComment` at the end of the edge. */
  node: ProjectComment;
};

/** Methods to use when ordering `ProjectComment`. */
export enum ProjectCommentsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** Project Communcations Messages */
export type ProjectCommunicationMessage = Node & {
  __typename?: 'ProjectCommunicationMessage';
  /** Channel for delivery */
  channel: CommsMessageChannel;
  /** When was this project message created? */
  createdAt: Scalars['Datetime'];
  /** Description of the message */
  description: Scalars['String'];
  /** Message ID */
  id: Scalars['UUID'];
  /** Is this project message deleted? */
  isDeleted: Scalars['Boolean'];
  /** Message type - key, internal or external */
  messageType: CommsMessageType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectCommunicationMessage`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  recipient: Maybe<ProjectConnection>;
  recipientId: Maybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt: Maybe<Scalars['Datetime']>;
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  sender: Maybe<ProjectConnection>;
  senderId: Maybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectCommunicationMessage` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectCommunicationMessageCondition = {
  /** Checks for equality with the object’s `channel` field. */
  channel?: InputMaybe<CommsMessageChannel>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `messageType` field. */
  messageType?: InputMaybe<CommsMessageType>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `recipientId` field. */
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `senderId` field. */
  senderId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectCommunicationMessage` object types. All fields are combined with a logical ‘and.’ */
export type ProjectCommunicationMessageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectCommunicationMessageFilter>>;
  /** Filter by the object’s `channel` field. */
  channel?: InputMaybe<CommsMessageChannelFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messageType` field. */
  messageType?: InputMaybe<CommsMessageTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectCommunicationMessageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectCommunicationMessageFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `recipient` relation. */
  recipient?: InputMaybe<ProjectConnectionFilter>;
  /** A related `recipient` exists. */
  recipientExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `recipientId` field. */
  recipientId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `sender` relation. */
  sender?: InputMaybe<ProjectConnectionFilter>;
  /** A related `sender` exists. */
  senderExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `senderId` field. */
  senderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ProjectCommunicationMessage` */
export type ProjectCommunicationMessageInput = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description: Scalars['String'];
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType: CommsMessageType;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectCommunicationMessageNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectCommunicationMessage` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectCommunicationMessage` to look up the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyUsingProjectCommunicationMessagePkeyUpdate = {
  /** Message ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `projectCommunicationMessage` to look up the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingProjectCommunicationMessagePkeyUpdate = {
  /** Message ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1Patch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `projectCommunicationMessage` to look up the row to update. */
export type ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingProjectCommunicationMessagePkeyUpdate = {
  /** Message ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1Patch;
};

/** Represents an update to a `ProjectCommunicationMessage`. Fields that are set will be updated. */
export type ProjectCommunicationMessagePatch = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description?: InputMaybe<Scalars['String']>;
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType?: InputMaybe<CommsMessageType>;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectCommunicationMessage` to look up the row to connect. */
export type ProjectCommunicationMessageProjectCommunicationMessagePkeyConnect = {
  /** Message ID */
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectCommunicationMessageInput` mutation. */
export type ProjectCommunicationMessageProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectCommunicationMessage` in the `ProjectInput` mutation. */
export type ProjectCommunicationMessageProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommunicationMessageProjectCommunicationMessagePkeyConnect>>;
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommunicationMessageNodeIdConnect>>;
  /** A `ProjectCommunicationMessageInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommunicationMessageProjectIdFkeyProjectCommunicationMessageCreateInput>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyUsingProjectCommunicationMessagePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyNodeIdUpdate>>;
};

/** The `projectCommunicationMessage` to be created by this mutation. */
export type ProjectCommunicationMessageProjectIdFkeyProjectCommunicationMessageCreateInput = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description: Scalars['String'];
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType: CommsMessageType;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `project` to be created by this mutation. */
export type ProjectCommunicationMessageProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Input for the nested mutation of `projectConnection` in the `ProjectCommunicationMessageInput` mutation. */
export type ProjectCommunicationMessageRecipientIdFkey1Input = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectConnectionProjectConnectionPkeyConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectConnectionNodeIdConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionUniqConnectionProjectConnect>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1ProjectConnectionCreateInput>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingProjectConnectionPkeyUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1NodeIdUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingUniqConnectionProjectUpdate>;
};

/** Input for the nested mutation of `projectCommunicationMessage` in the `ProjectConnectionInput` mutation. */
export type ProjectCommunicationMessageRecipientIdFkey1InverseInput = {
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommunicationMessageProjectCommunicationMessagePkeyConnect>>;
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommunicationMessageNodeIdConnect>>;
  /** A `ProjectCommunicationMessageInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommunicationMessageRecipientIdFkey1ProjectCommunicationMessageCreateInput>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingProjectCommunicationMessagePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1NodeIdUpdate>>;
};

/** The `projectCommunicationMessage` to be created by this mutation. */
export type ProjectCommunicationMessageRecipientIdFkey1ProjectCommunicationMessageCreateInput = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description: Scalars['String'];
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType: CommsMessageType;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectCommunicationMessageRecipientIdFkey1ProjectConnectionCreateInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `projectConnection` in the `ProjectCommunicationMessageInput` mutation. */
export type ProjectCommunicationMessageSenderIdFkey1Input = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectConnectionProjectConnectionPkeyConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectConnectionNodeIdConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionUniqConnectionProjectConnect>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1ProjectConnectionCreateInput>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingProjectConnectionPkeyUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1NodeIdUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingUniqConnectionProjectUpdate>;
};

/** Input for the nested mutation of `projectCommunicationMessage` in the `ProjectConnectionInput` mutation. */
export type ProjectCommunicationMessageSenderIdFkey1InverseInput = {
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommunicationMessageProjectCommunicationMessagePkeyConnect>>;
  /** The primary key(s) for `projectCommunicationMessage` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommunicationMessageNodeIdConnect>>;
  /** A `ProjectCommunicationMessageInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommunicationMessageSenderIdFkey1ProjectCommunicationMessageCreateInput>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingProjectCommunicationMessagePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectCommunicationMessage` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1NodeIdUpdate>>;
};

/** The `projectCommunicationMessage` to be created by this mutation. */
export type ProjectCommunicationMessageSenderIdFkey1ProjectCommunicationMessageCreateInput = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description: Scalars['String'];
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType: CommsMessageType;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectCommunicationMessageSenderIdFkey1ProjectConnectionCreateInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A connection to a list of `ProjectCommunicationMessage` values. */
export type ProjectCommunicationMessagesConnection = {
  __typename?: 'ProjectCommunicationMessagesConnection';
  /** A list of edges which contains the `ProjectCommunicationMessage` and cursor to aid in pagination. */
  edges: Array<ProjectCommunicationMessagesEdge>;
  /** A list of `ProjectCommunicationMessage` objects. */
  nodes: Array<ProjectCommunicationMessage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectCommunicationMessage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectCommunicationMessage` edge in the connection. */
export type ProjectCommunicationMessagesEdge = {
  __typename?: 'ProjectCommunicationMessagesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectCommunicationMessage` at the end of the edge. */
  node: ProjectCommunicationMessage;
};

/** Methods to use when ordering `ProjectCommunicationMessage`. */
export enum ProjectCommunicationMessagesOrderBy {
  ChannelAsc = 'CHANNEL_ASC',
  ChannelDesc = 'CHANNEL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  MessageTypeAsc = 'MESSAGE_TYPE_ASC',
  MessageTypeDesc = 'MESSAGE_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  RecipientIdAsc = 'RECIPIENT_ID_ASC',
  RecipientIdDesc = 'RECIPIENT_ID_DESC',
  SenderIdAsc = 'SENDER_ID_ASC',
  SenderIdDesc = 'SENDER_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Project Questions */
export type ProjectCommunicationQuestion = Node & {
  __typename?: 'ProjectCommunicationQuestion';
  /** When was this project question created? */
  createdAt: Scalars['Datetime'];
  /** Question ID */
  id: Scalars['UUID'];
  /** Is this project question deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectCommunicationQuestion`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Question type - your or their */
  questionType: CommsQuestionType;
  /** When was this project question last updated? */
  updatedAt: Scalars['Datetime'];
  /** Question fields */
  value: Maybe<Scalars['JSON']>;
};

/**
 * A condition to be used against `ProjectCommunicationQuestion` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectCommunicationQuestionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `questionType` field. */
  questionType?: InputMaybe<CommsQuestionType>;
};

/** A filter to be used against `ProjectCommunicationQuestion` object types. All fields are combined with a logical ‘and.’ */
export type ProjectCommunicationQuestionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectCommunicationQuestionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectCommunicationQuestionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectCommunicationQuestionFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `questionType` field. */
  questionType?: InputMaybe<CommsQuestionTypeFilter>;
};

/** An input for mutations affecting `ProjectCommunicationQuestion` */
export type ProjectCommunicationQuestionInput = {
  /** When was this project question created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Question ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project question deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInput>;
  /** Question type - your or their */
  questionType: CommsQuestionType;
  /** When was this project question last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Question fields */
  value?: InputMaybe<Scalars['JSON']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectCommunicationQuestionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectCommunicationQuestion` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectCommunicationQuestion` to look up the row to update. */
export type ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingProjectCommunicationQuestionPkeyUpdate = {
  /** Question ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectCommunicationQuestion` being updated. */
  patch: UpdateProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyPatch;
};

/** The fields on `projectCommunicationQuestion` to look up the row to update. */
export type ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingUniqProjectQuestionTypeUpdate = {
  /** An object where the defined keys will be set on the `projectCommunicationQuestion` being updated. */
  patch: UpdateProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Question type - your or their */
  questionType: CommsQuestionType;
};

/** Represents an update to a `ProjectCommunicationQuestion`. Fields that are set will be updated. */
export type ProjectCommunicationQuestionPatch = {
  /** When was this project question created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Question ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project question deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInput>;
  /** Question type - your or their */
  questionType?: InputMaybe<CommsQuestionType>;
  /** When was this project question last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Question fields */
  value?: InputMaybe<Scalars['JSON']>;
};

/** The fields on `projectCommunicationQuestion` to look up the row to connect. */
export type ProjectCommunicationQuestionProjectCommunicationQuestionPkeyConnect = {
  /** Question ID */
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectCommunicationQuestionInput` mutation. */
export type ProjectCommunicationQuestionProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectCommunicationQuestion` in the `ProjectInput` mutation. */
export type ProjectCommunicationQuestionProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectCommunicationQuestion` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectCommunicationQuestionProjectCommunicationQuestionPkeyConnect>>;
  /** The primary key(s) for `projectCommunicationQuestion` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectCommunicationQuestionNodeIdConnect>>;
  /** The primary key(s) for `projectCommunicationQuestion` for the far side of the relationship. */
  connectByProjectIdAndQuestionType?: InputMaybe<Array<ProjectCommunicationQuestionUniqProjectQuestionTypeConnect>>;
  /** A `ProjectCommunicationQuestionInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCommunicationQuestionProjectIdFkeyProjectCommunicationQuestionCreateInput>>;
  /** The primary key(s) and patch data for `projectCommunicationQuestion` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingProjectCommunicationQuestionPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectCommunicationQuestion` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectCommunicationQuestion` for the far side of the relationship. */
  updateByProjectIdAndQuestionType?: InputMaybe<Array<ProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingUniqProjectQuestionTypeUpdate>>;
};

/** The `projectCommunicationQuestion` to be created by this mutation. */
export type ProjectCommunicationQuestionProjectIdFkeyProjectCommunicationQuestionCreateInput = {
  /** When was this project question created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Question ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project question deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInput>;
  /** Question type - your or their */
  questionType: CommsQuestionType;
  /** When was this project question last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Question fields */
  value?: InputMaybe<Scalars['JSON']>;
};

/** The `project` to be created by this mutation. */
export type ProjectCommunicationQuestionProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectCommunicationQuestion` to look up the row to connect. */
export type ProjectCommunicationQuestionUniqProjectQuestionTypeConnect = {
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Question type - your or their */
  questionType: CommsQuestionType;
};

/** A connection to a list of `ProjectCommunicationQuestion` values. */
export type ProjectCommunicationQuestionsConnection = {
  __typename?: 'ProjectCommunicationQuestionsConnection';
  /** A list of edges which contains the `ProjectCommunicationQuestion` and cursor to aid in pagination. */
  edges: Array<ProjectCommunicationQuestionsEdge>;
  /** A list of `ProjectCommunicationQuestion` objects. */
  nodes: Array<ProjectCommunicationQuestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectCommunicationQuestion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectCommunicationQuestion` edge in the connection. */
export type ProjectCommunicationQuestionsEdge = {
  __typename?: 'ProjectCommunicationQuestionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectCommunicationQuestion` at the end of the edge. */
  node: ProjectCommunicationQuestion;
};

/** Methods to use when ordering `ProjectCommunicationQuestion`. */
export enum ProjectCommunicationQuestionsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  QuestionTypeAsc = 'QUESTION_TYPE_ASC',
  QuestionTypeDesc = 'QUESTION_TYPE_DESC'
}

/** Project Compliance */
export type ProjectCompliance = Node & {
  __typename?: 'ProjectCompliance';
  /** When was this compliance created? */
  createdAt: Scalars['Datetime'];
  /** Date of the event */
  eventAt: Maybe<Scalars['Datetime']>;
  /** Compliance ID */
  id: Scalars['UUID'];
  /** Is this compliance archived? */
  isArchived: Scalars['Boolean'];
  /** Is this compliance deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Is this event recurring and how often? */
  occurrence: RecurringInterval;
  /** Reads a single `Project` that is related to this `ProjectCompliance`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** What is the compliance status? */
  status: ComplianceStatus;
  /** When was this compliance last updated? */
  updatedAt: Scalars['Datetime'];
  /** What needs to be completed? */
  what: Maybe<Scalars['String']>;
  /** Who is the contact? */
  who: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ProjectCompliance` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectComplianceCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `eventAt` field. */
  eventAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `occurrence` field. */
  occurrence?: InputMaybe<RecurringInterval>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<ComplianceStatus>;
};

/** A filter to be used against `ProjectCompliance` object types. All fields are combined with a logical ‘and.’ */
export type ProjectComplianceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectComplianceFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `eventAt` field. */
  eventAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectComplianceFilter>;
  /** Filter by the object’s `occurrence` field. */
  occurrence?: InputMaybe<RecurringIntervalFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectComplianceFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<ComplianceStatusFilter>;
};

/** An input for mutations affecting `ProjectCompliance` */
export type ProjectComplianceInput = {
  /** When was this compliance created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date of the event */
  eventAt?: InputMaybe<Scalars['Datetime']>;
  /** Compliance ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this compliance archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this compliance deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this event recurring and how often? */
  occurrence?: InputMaybe<RecurringInterval>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectComplianceProjectIdFkeyInput>;
  /** What is the compliance status? */
  status?: InputMaybe<ComplianceStatus>;
  /** When was this compliance last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What needs to be completed? */
  what?: InputMaybe<Scalars['String']>;
  /** Who is the contact? */
  who?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectComplianceNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectCompliance` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectCompliance` to look up the row to update. */
export type ProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyUsingProjectCompliancePkeyUpdate = {
  /** Compliance ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectCompliance` being updated. */
  patch: UpdateProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyPatch;
};

/** Represents an update to a `ProjectCompliance`. Fields that are set will be updated. */
export type ProjectCompliancePatch = {
  /** When was this compliance created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date of the event */
  eventAt?: InputMaybe<Scalars['Datetime']>;
  /** Compliance ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this compliance archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this compliance deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this event recurring and how often? */
  occurrence?: InputMaybe<RecurringInterval>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectComplianceProjectIdFkeyInput>;
  /** What is the compliance status? */
  status?: InputMaybe<ComplianceStatus>;
  /** When was this compliance last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What needs to be completed? */
  what?: InputMaybe<Scalars['String']>;
  /** Who is the contact? */
  who?: InputMaybe<Scalars['String']>;
};

/** The fields on `projectCompliance` to look up the row to connect. */
export type ProjectComplianceProjectCompliancePkeyConnect = {
  /** Compliance ID */
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectComplianceInput` mutation. */
export type ProjectComplianceProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectComplianceProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectComplianceForProjectComplianceProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectCompliance` in the `ProjectInput` mutation. */
export type ProjectComplianceProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectCompliance` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectComplianceProjectCompliancePkeyConnect>>;
  /** The primary key(s) for `projectCompliance` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectComplianceNodeIdConnect>>;
  /** A `ProjectComplianceInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectComplianceProjectIdFkeyProjectComplianceCreateInput>>;
  /** The primary key(s) and patch data for `projectCompliance` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyUsingProjectCompliancePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectCompliance` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectComplianceForProjectComplianceProjectIdFkeyNodeIdUpdate>>;
};

/** The `projectCompliance` to be created by this mutation. */
export type ProjectComplianceProjectIdFkeyProjectComplianceCreateInput = {
  /** When was this compliance created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date of the event */
  eventAt?: InputMaybe<Scalars['Datetime']>;
  /** Compliance ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this compliance archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this compliance deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this event recurring and how often? */
  occurrence?: InputMaybe<RecurringInterval>;
  projectToProjectId?: InputMaybe<ProjectComplianceProjectIdFkeyInput>;
  /** What is the compliance status? */
  status?: InputMaybe<ComplianceStatus>;
  /** When was this compliance last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What needs to be completed? */
  what?: InputMaybe<Scalars['String']>;
  /** Who is the contact? */
  who?: InputMaybe<Scalars['String']>;
};

/** The `project` to be created by this mutation. */
export type ProjectComplianceProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** A connection to a list of `ProjectCompliance` values. */
export type ProjectCompliancesConnection = {
  __typename?: 'ProjectCompliancesConnection';
  /** A list of edges which contains the `ProjectCompliance` and cursor to aid in pagination. */
  edges: Array<ProjectCompliancesEdge>;
  /** A list of `ProjectCompliance` objects. */
  nodes: Array<ProjectCompliance>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectCompliance` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectCompliance` edge in the connection. */
export type ProjectCompliancesEdge = {
  __typename?: 'ProjectCompliancesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectCompliance` at the end of the edge. */
  node: ProjectCompliance;
};

/** Methods to use when ordering `ProjectCompliance`. */
export enum ProjectCompliancesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EventAtAsc = 'EVENT_AT_ASC',
  EventAtDesc = 'EVENT_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  OccurrenceAsc = 'OCCURRENCE_ASC',
  OccurrenceDesc = 'OCCURRENCE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC'
}

/** A condition to be used against `Project` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ProjectCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `fileType` field. */
  fileType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isNegotiationClosed` field. */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isNegotiationOpen` field. */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isOpen` field. */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `parentId` field. */
  parentId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `titleTsv` field. */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `value` field. */
  value?: InputMaybe<Scalars['String']>;
};

/** Project Connections */
export type ProjectConnection = Node & {
  __typename?: 'ProjectConnection';
  /** Reads a single `Connection` that is related to this `ProjectConnection`. */
  connection: Maybe<Connection>;
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** What is the role of this connection? */
  connectionRole: ConnectionRole;
  /** When was this project connection created? */
  createdAt: Scalars['Datetime'];
  /** What is the decision maker type of this connection? */
  decisionMaker: ConnectionDecisionMaker;
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** Is this project connection deleted? */
  isDeleted: Scalars['Boolean'];
  /** Is this a key decision maker? */
  keyDecisionMaker: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectConnection`. */
  project: Maybe<Project>;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesByRecipientId: ProjectCommunicationMessagesConnection;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesByRecipientIdList: Array<ProjectCommunicationMessage>;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesBySenderId: ProjectCommunicationMessagesConnection;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesBySenderIdList: Array<ProjectCommunicationMessage>;
  /** Reads and enables pagination through a set of `ProjectConnectionLink`. */
  projectConnectionLinksByExternalProjectConnectionId: ProjectConnectionLinksConnection;
  /** Reads and enables pagination through a set of `ProjectConnectionLink`. */
  projectConnectionLinksByExternalProjectConnectionIdList: Array<ProjectConnectionLink>;
  /** Reads and enables pagination through a set of `ProjectConnectionLink`. */
  projectConnectionLinksByInternalProjectConnectionId: ProjectConnectionLinksConnection;
  /** Reads and enables pagination through a set of `ProjectConnectionLink`. */
  projectConnectionLinksByInternalProjectConnectionIdList: Array<ProjectConnectionLink>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** When was this project connection last updated? */
  updatedAt: Scalars['Datetime'];
};


/** Project Connections */
export type ProjectConnectionProjectCommunicationMessagesByRecipientIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectCommunicationMessagesByRecipientIdListArgs = {
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectCommunicationMessagesBySenderIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectCommunicationMessagesBySenderIdListArgs = {
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectConnectionLinksByExternalProjectConnectionIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectConnectionLinksByExternalProjectConnectionIdListArgs = {
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectConnectionLinksByInternalProjectConnectionIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};


/** Project Connections */
export type ProjectConnectionProjectConnectionLinksByInternalProjectConnectionIdListArgs = {
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};

/**
 * A condition to be used against `ProjectConnection` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectConnectionCondition = {
  /** Checks for equality with the object’s `connectionId` field. */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** The `connection` to be created by this mutation. */
export type ProjectConnectionConnectionIdFkeyConnectionCreateInput = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType: ConnectionType;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** Input for the nested mutation of `connection` in the `ProjectConnectionInput` mutation. */
export type ProjectConnectionConnectionIdFkeyInput = {
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectById?: InputMaybe<ConnectionConnectionPkeyConnect>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ConnectionNodeIdConnect>;
  /** A `ConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectConnectionConnectionIdFkeyConnectionCreateInput>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteById?: InputMaybe<ConnectionConnectionPkeyDelete>;
  /** The primary key(s) for `connection` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ConnectionNodeIdDelete>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateById?: InputMaybe<ConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingConnectionPkeyUpdate>;
  /** The primary key(s) and patch data for `connection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectConnection` in the `ConnectionInput` mutation. */
export type ProjectConnectionConnectionIdFkeyInverseInput = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectConnectionProjectConnectionPkeyConnect>>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectConnectionNodeIdConnect>>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<Array<ProjectConnectionUniqConnectionProjectConnect>>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectConnectionConnectionIdFkeyProjectConnectionCreateInput>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingProjectConnectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<Array<ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingUniqConnectionProjectUpdate>>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectConnectionConnectionIdFkeyProjectConnectionCreateInput = {
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectConnection` object types. All fields are combined with a logical ‘and.’ */
export type ProjectConnectionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectConnectionFilter>>;
  /** Filter by the object’s `connection` relation. */
  connection?: InputMaybe<ConnectionFilter>;
  /** Filter by the object’s `connectionId` field. */
  connectionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectConnectionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectConnectionFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectCommunicationMessagesByRecipientId` relation. */
  projectCommunicationMessagesByRecipientId?: InputMaybe<ProjectConnectionToManyProjectCommunicationMessageFilter>;
  /** Some related `projectCommunicationMessagesByRecipientId` exist. */
  projectCommunicationMessagesByRecipientIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectCommunicationMessagesBySenderId` relation. */
  projectCommunicationMessagesBySenderId?: InputMaybe<ProjectConnectionToManyProjectCommunicationMessageFilter>;
  /** Some related `projectCommunicationMessagesBySenderId` exist. */
  projectCommunicationMessagesBySenderIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectConnectionLinksByExternalProjectConnectionId` relation. */
  projectConnectionLinksByExternalProjectConnectionId?: InputMaybe<ProjectConnectionToManyProjectConnectionLinkFilter>;
  /** Some related `projectConnectionLinksByExternalProjectConnectionId` exist. */
  projectConnectionLinksByExternalProjectConnectionIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectConnectionLinksByInternalProjectConnectionId` relation. */
  projectConnectionLinksByInternalProjectConnectionId?: InputMaybe<ProjectConnectionToManyProjectConnectionLinkFilter>;
  /** Some related `projectConnectionLinksByInternalProjectConnectionId` exist. */
  projectConnectionLinksByInternalProjectConnectionIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ProjectConnection` */
export type ProjectConnectionInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Project Connection Links */
export type ProjectConnectionLink = Node & {
  __typename?: 'ProjectConnectionLink';
  /** Reads a single `ProjectConnection` that is related to this `ProjectConnectionLink`. */
  externalProjectConnection: Maybe<ProjectConnection>;
  /** External Project Connection ID */
  externalProjectConnectionId: Scalars['UUID'];
  /** Link ID - needed by Apollo */
  id: Scalars['UUID'];
  /** Reads a single `ProjectConnection` that is related to this `ProjectConnectionLink`. */
  internalProjectConnection: Maybe<ProjectConnection>;
  /** Internal Project Connection ID */
  internalProjectConnectionId: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  /** Primary or secondary link between the two persons */
  linkType: ConnectionLinkType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Trust status between the two persons */
  trustStatus: ConnectionTrustStatus;
};

/**
 * A condition to be used against `ProjectConnectionLink` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectConnectionLinkCondition = {
  /** Checks for equality with the object’s `externalProjectConnectionId` field. */
  externalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `internalProjectConnectionId` field. */
  internalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `linkType` field. */
  linkType?: InputMaybe<ConnectionLinkType>;
};

/** An input for mutations affecting `ProjectConnectionLinkConnection` */
export type ProjectConnectionLinkConnectionInput = {
  intProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  linkType?: InputMaybe<ConnectionLinkType>;
  trustStatus?: InputMaybe<ConnectionTrustStatus>;
};

/** Input for the nested mutation of `projectConnection` in the `ProjectConnectionLinkInput` mutation. */
export type ProjectConnectionLinkExternalProjectConnectionIdFkeyInput = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectConnectionProjectConnectionPkeyConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectConnectionNodeIdConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionUniqConnectionProjectConnect>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyProjectConnectionCreateInput>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingProjectConnectionPkeyUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingUniqConnectionProjectUpdate>;
};

/** Input for the nested mutation of `projectConnectionLink` in the `ProjectConnectionInput` mutation. */
export type ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput = {
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectConnectionLinkProjectConnectionLinkPkeyConnect>>;
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectByInternalProjectConnectionIdAndExternalProjectConnectionId?: InputMaybe<Array<ProjectConnectionLinkUniqConnectionLinkConnect>>;
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectConnectionLinkNodeIdConnect>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingProjectConnectionLinkPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateByInternalProjectConnectionIdAndExternalProjectConnectionId?: InputMaybe<Array<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingUniqConnectionLinkUpdate>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyNodeIdUpdate>>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectConnectionLinkExternalProjectConnectionIdFkeyProjectConnectionCreateInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectConnectionLink` object types. All fields are combined with a logical ‘and.’ */
export type ProjectConnectionLinkFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectConnectionLinkFilter>>;
  /** Filter by the object’s `externalProjectConnection` relation. */
  externalProjectConnection?: InputMaybe<ProjectConnectionFilter>;
  /** Filter by the object’s `externalProjectConnectionId` field. */
  externalProjectConnectionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `internalProjectConnection` relation. */
  internalProjectConnection?: InputMaybe<ProjectConnectionFilter>;
  /** Filter by the object’s `internalProjectConnectionId` field. */
  internalProjectConnectionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `linkType` field. */
  linkType?: InputMaybe<ConnectionLinkTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectConnectionLinkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectConnectionLinkFilter>>;
};

/** Input for the nested mutation of `projectConnection` in the `ProjectConnectionLinkInput` mutation. */
export type ProjectConnectionLinkInternalProjectConnectionIdFkeyInput = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectConnectionProjectConnectionPkeyConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectConnectionNodeIdConnect>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionUniqConnectionProjectConnect>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyProjectConnectionCreateInput>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingProjectConnectionPkeyUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingUniqConnectionProjectUpdate>;
};

/** Input for the nested mutation of `projectConnectionLink` in the `ProjectConnectionInput` mutation. */
export type ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput = {
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectConnectionLinkProjectConnectionLinkPkeyConnect>>;
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectByInternalProjectConnectionIdAndExternalProjectConnectionId?: InputMaybe<Array<ProjectConnectionLinkUniqConnectionLinkConnect>>;
  /** The primary key(s) for `projectConnectionLink` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectConnectionLinkNodeIdConnect>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingProjectConnectionLinkPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateByInternalProjectConnectionIdAndExternalProjectConnectionId?: InputMaybe<Array<ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingUniqConnectionLinkUpdate>>;
  /** The primary key(s) and patch data for `projectConnectionLink` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyNodeIdUpdate>>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectConnectionLinkInternalProjectConnectionIdFkeyProjectConnectionCreateInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectConnectionLinkNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectConnectionLink` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `projectConnectionLink` to look up the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingProjectConnectionLinkPkeyUpdate = {
  /** Link ID - needed by Apollo */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch;
};

/** The fields on `projectConnectionLink` to look up the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingUniqConnectionLinkUpdate = {
  /** External Project Connection ID */
  externalProjectConnectionId: Scalars['UUID'];
  /** Internal Project Connection ID */
  internalProjectConnectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `projectConnectionLink` to look up the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingProjectConnectionLinkPkeyUpdate = {
  /** Link ID - needed by Apollo */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch;
};

/** The fields on `projectConnectionLink` to look up the row to update. */
export type ProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingUniqConnectionLinkUpdate = {
  /** External Project Connection ID */
  externalProjectConnectionId: Scalars['UUID'];
  /** Internal Project Connection ID */
  internalProjectConnectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch;
};

/** Represents an update to a `ProjectConnectionLink`. Fields that are set will be updated. */
export type ProjectConnectionLinkPatch = {
  /** External Project Connection ID */
  externalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  /** Link ID - needed by Apollo */
  id?: InputMaybe<Scalars['UUID']>;
  /** Internal Project Connection ID */
  internalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Primary or secondary link between the two persons */
  linkType?: InputMaybe<ConnectionLinkType>;
  projectConnectionToExternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInput>;
  projectConnectionToInternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInput>;
  /** Trust status between the two persons */
  trustStatus?: InputMaybe<ConnectionTrustStatus>;
};

/** The fields on `projectConnectionLink` to look up the row to connect. */
export type ProjectConnectionLinkProjectConnectionLinkPkeyConnect = {
  /** Link ID - needed by Apollo */
  id: Scalars['UUID'];
};

/** The fields on `projectConnectionLink` to look up the row to connect. */
export type ProjectConnectionLinkUniqConnectionLinkConnect = {
  /** External Project Connection ID */
  externalProjectConnectionId: Scalars['UUID'];
  /** Internal Project Connection ID */
  internalProjectConnectionId: Scalars['UUID'];
};

/** A connection to a list of `ProjectConnectionLink` values. */
export type ProjectConnectionLinksConnection = {
  __typename?: 'ProjectConnectionLinksConnection';
  /** A list of edges which contains the `ProjectConnectionLink` and cursor to aid in pagination. */
  edges: Array<ProjectConnectionLinksEdge>;
  /** A list of `ProjectConnectionLink` objects. */
  nodes: Array<ProjectConnectionLink>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectConnectionLink` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectConnectionLink` edge in the connection. */
export type ProjectConnectionLinksEdge = {
  __typename?: 'ProjectConnectionLinksEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectConnectionLink` at the end of the edge. */
  node: ProjectConnectionLink;
};

/** Methods to use when ordering `ProjectConnectionLink`. */
export enum ProjectConnectionLinksOrderBy {
  ExternalProjectConnectionIdAsc = 'EXTERNAL_PROJECT_CONNECTION_ID_ASC',
  ExternalProjectConnectionIdDesc = 'EXTERNAL_PROJECT_CONNECTION_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalProjectConnectionIdAsc = 'INTERNAL_PROJECT_CONNECTION_ID_ASC',
  InternalProjectConnectionIdDesc = 'INTERNAL_PROJECT_CONNECTION_ID_DESC',
  LinkTypeAsc = 'LINK_TYPE_ASC',
  LinkTypeDesc = 'LINK_TYPE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type ProjectConnectionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectCommunicationMessage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: ProjectCommunicationMessagePatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1Patch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1UsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1Patch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1NodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectCommunicationMessage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: ProjectCommunicationMessagePatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1Patch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1UsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1Patch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `connection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: ConnectionPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyUsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyUsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyUsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnectionLink` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: ProjectConnectionLinkPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyUsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnectionLink` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
  patch: ProjectConnectionLinkPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingProjectConnectionPkeyUpdate = {
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch;
};

/** The fields on `projectConnection` to look up the row to update. */
export type ProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyUsingUniqConnectionProjectUpdate = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectConnection`. Fields that are set will be updated. */
export type ProjectConnectionPatch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectConnection` to look up the row to connect. */
export type ProjectConnectionProjectConnectionPkeyConnect = {
  /** Project Connection ID */
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectConnectionInput` mutation. */
export type ProjectConnectionProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectConnectionProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectConnectionForProjectConnectionProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectConnection` in the `ProjectInput` mutation. */
export type ProjectConnectionProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectConnectionProjectConnectionPkeyConnect>>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectConnectionNodeIdConnect>>;
  /** The primary key(s) for `projectConnection` for the far side of the relationship. */
  connectByProjectIdAndConnectionId?: InputMaybe<Array<ProjectConnectionUniqConnectionProjectConnect>>;
  /** A `ProjectConnectionInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectConnectionProjectIdFkeyProjectConnectionCreateInput>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyUsingProjectConnectionPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectConnectionForProjectConnectionProjectIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectConnection` for the far side of the relationship. */
  updateByProjectIdAndConnectionId?: InputMaybe<Array<ProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyUsingUniqConnectionProjectUpdate>>;
};

/** The `projectConnection` to be created by this mutation. */
export type ProjectConnectionProjectIdFkeyProjectConnectionCreateInput = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `project` to be created by this mutation. */
export type ProjectConnectionProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** A filter to be used against many `ProjectCommunicationMessage` object types. All fields are combined with a logical ‘and.’ */
export type ProjectConnectionToManyProjectCommunicationMessageFilter = {
  /** Every related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommunicationMessageFilter>;
  /** No related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommunicationMessageFilter>;
  /** Some related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommunicationMessageFilter>;
};

/** A filter to be used against many `ProjectConnectionLink` object types. All fields are combined with a logical ‘and.’ */
export type ProjectConnectionToManyProjectConnectionLinkFilter = {
  /** Every related `ProjectConnectionLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectConnectionLinkFilter>;
  /** No related `ProjectConnectionLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectConnectionLinkFilter>;
  /** Some related `ProjectConnectionLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectConnectionLinkFilter>;
};

/** The fields on `projectConnection` to look up the row to connect. */
export type ProjectConnectionUniqConnectionProjectConnect = {
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** A connection to a list of `ProjectConnection` values. */
export type ProjectConnectionsConnection = {
  __typename?: 'ProjectConnectionsConnection';
  /** A list of edges which contains the `ProjectConnection` and cursor to aid in pagination. */
  edges: Array<ProjectConnectionsEdge>;
  /** A list of `ProjectConnection` objects. */
  nodes: Array<ProjectConnection>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectConnection` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectConnection` edge in the connection. */
export type ProjectConnectionsEdge = {
  __typename?: 'ProjectConnectionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectConnection` at the end of the edge. */
  node: ProjectConnection;
};

/** Methods to use when ordering `ProjectConnection`. */
export enum ProjectConnectionsOrderBy {
  ConnectionIdAsc = 'CONNECTION_ID_ASC',
  ConnectionIdDesc = 'CONNECTION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** Input for the nested mutation of `user` in the `ProjectInput` mutation. */
export type ProjectCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectForProjectCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectForProjectCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectForProjectCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `project` in the `UserInput` mutation. */
export type ProjectCreatedByFkeyInverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectProjectPkeyConnect>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectNodeIdConnect>>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCreatedByFkeyProjectCreateInput>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectProjectPkeyDelete>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectNodeIdDelete>>;
  /** Flag indicating whether all other `project` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOnProjectForProjectCreatedByFkeyUsingProjectPkeyUpdate>>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnProjectForProjectCreatedByFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectCreatedByFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `customer` to be created by this mutation. */
export type ProjectCustomerIdFkeyCustomerCreateInput = {
  /** The account this customer belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `customer` in the `ProjectInput` mutation. */
export type ProjectCustomerIdFkeyInput = {
  /** The primary key(s) for `customer` for the far side of the relationship. */
  connectById?: InputMaybe<CustomerCustomerPkeyConnect>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<CustomerNodeIdConnect>;
  /** A `CustomerInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectCustomerIdFkeyCustomerCreateInput>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  deleteById?: InputMaybe<CustomerCustomerPkeyDelete>;
  /** The primary key(s) for `customer` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<CustomerNodeIdDelete>;
  /** The primary key(s) and patch data for `customer` for the far side of the relationship. */
  updateById?: InputMaybe<CustomerOnProjectForProjectCustomerIdFkeyUsingCustomerPkeyUpdate>;
  /** The primary key(s) and patch data for `customer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectForProjectCustomerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `project` in the `CustomerInput` mutation. */
export type ProjectCustomerIdFkeyInverseInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectProjectPkeyConnect>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectNodeIdConnect>>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectCustomerIdFkeyProjectCreateInput>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectProjectPkeyDelete>>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectNodeIdDelete>>;
  /** Flag indicating whether all other `project` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOnProjectForProjectCustomerIdFkeyUsingProjectPkeyUpdate>>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<CustomerOnProjectForProjectCustomerIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectCustomerIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Project Events */
export type ProjectEvent = Node & {
  __typename?: 'ProjectEvent';
  /** When was this event created? */
  createdAt: Scalars['Datetime'];
  /** Event description */
  description: Maybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime: Scalars['Datetime'];
  /** What type of event it is */
  eventType: Scalars['String'];
  /** Reads a single `EventType` that is related to this `ProjectEvent`. */
  eventTypeByEventType: Maybe<EventType>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `GoogleCalendarEvent`. */
  googleCalendarEventsByEventId: GoogleCalendarEventsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarEvent`. */
  googleCalendarEventsByEventIdList: Array<GoogleCalendarEvent>;
  /** Event ID */
  id: Scalars['UUID'];
  /** Is this event archived? */
  isArchived: Scalars['Boolean'];
  /** Is this event deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectEvent`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Date and time of the event */
  startTime: Scalars['Datetime'];
  /** When was this event last updated? */
  updatedAt: Scalars['Datetime'];
};


/** Project Events */
export type ProjectEventGoogleCalendarEventsByEventIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};


/** Project Events */
export type ProjectEventGoogleCalendarEventsByEventIdListArgs = {
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};

/**
 * A condition to be used against `ProjectEvent` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectEventCondition = {
  /** Checks for equality with the object’s `eventType` field. */
  eventType?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `startTime` field. */
  startTime?: InputMaybe<Scalars['Datetime']>;
};

/** The `eventType` to be created by this mutation. */
export type ProjectEventEventTypeFkeyEventTypeCreateInput = {
  projectEventsUsingTitle?: InputMaybe<ProjectEventEventTypeFkeyInverseInput>;
  /** Event type title */
  title: Scalars['String'];
  /** Event type weight */
  weight?: InputMaybe<Scalars['Int']>;
};

/** Input for the nested mutation of `eventType` in the `ProjectEventInput` mutation. */
export type ProjectEventEventTypeFkeyInput = {
  /** The primary key(s) for `eventType` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<EventTypeNodeIdConnect>;
  /** The primary key(s) for `eventType` for the far side of the relationship. */
  connectByTitle?: InputMaybe<EventTypeEventTypePkeyConnect>;
  /** A `EventTypeInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectEventEventTypeFkeyEventTypeCreateInput>;
  /** The primary key(s) for `eventType` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<EventTypeNodeIdDelete>;
  /** The primary key(s) for `eventType` for the far side of the relationship. */
  deleteByTitle?: InputMaybe<EventTypeEventTypePkeyDelete>;
  /** The primary key(s) and patch data for `eventType` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectEventOnProjectEventForProjectEventEventTypeFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `eventType` for the far side of the relationship. */
  updateByTitle?: InputMaybe<EventTypeOnProjectEventForProjectEventEventTypeFkeyUsingEventTypePkeyUpdate>;
};

/** Input for the nested mutation of `projectEvent` in the `EventTypeInput` mutation. */
export type ProjectEventEventTypeFkeyInverseInput = {
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectEventProjectEventPkeyConnect>>;
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectEventNodeIdConnect>>;
  /** A `ProjectEventInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectEventEventTypeFkeyProjectEventCreateInput>>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectEventOnProjectEventForProjectEventEventTypeFkeyUsingProjectEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<EventTypeOnProjectEventForProjectEventEventTypeFkeyNodeIdUpdate>>;
};

/** The `projectEvent` to be created by this mutation. */
export type ProjectEventEventTypeFkeyProjectEventCreateInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime: Scalars['Datetime'];
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime: Scalars['Datetime'];
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectEvent` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectEventFilter>>;
  /** Filter by the object’s `eventType` field. */
  eventType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `eventTypeByEventType` relation. */
  eventTypeByEventType?: InputMaybe<EventTypeFilter>;
  /** Filter by the object’s `googleCalendarEventsByEventId` relation. */
  googleCalendarEventsByEventId?: InputMaybe<ProjectEventToManyGoogleCalendarEventFilter>;
  /** Some related `googleCalendarEventsByEventId` exist. */
  googleCalendarEventsByEventIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectEventFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ProjectEvent` */
export type ProjectEventInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime: Scalars['Datetime'];
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime: Scalars['Datetime'];
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectEventNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectEvent` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
  patch: GoogleCalendarEventPatch;
};

/** The fields on `projectEvent` to look up the row to update. */
export type ProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyUsingProjectEventPkeyUpdate = {
  /** Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: UpdateProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectEventOnProjectEventForProjectEventEventTypeFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `eventType` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `eventType` being updated. */
  patch: EventTypePatch;
};

/** The fields on `projectEvent` to look up the row to update. */
export type ProjectEventOnProjectEventForProjectEventEventTypeFkeyUsingProjectEventPkeyUpdate = {
  /** Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: UpdateProjectEventOnProjectEventForProjectEventEventTypeFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectEventOnProjectEventForProjectEventProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectEvent` to look up the row to update. */
export type ProjectEventOnProjectEventForProjectEventProjectIdFkeyUsingProjectEventPkeyUpdate = {
  /** Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: UpdateProjectEventOnProjectEventForProjectEventProjectIdFkeyPatch;
};

/** Represents an update to a `ProjectEvent`. Fields that are set will be updated. */
export type ProjectEventPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectEvent` to look up the row to connect. */
export type ProjectEventProjectEventPkeyConnect = {
  /** Event ID */
  id: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectEventInput` mutation. */
export type ProjectEventProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectEventProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectEventForProjectEventProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectEventOnProjectEventForProjectEventProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectEvent` in the `ProjectInput` mutation. */
export type ProjectEventProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectEventProjectEventPkeyConnect>>;
  /** The primary key(s) for `projectEvent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectEventNodeIdConnect>>;
  /** A `ProjectEventInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectEventProjectIdFkeyProjectEventCreateInput>>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectEventOnProjectEventForProjectEventProjectIdFkeyUsingProjectEventPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectEvent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectEventForProjectEventProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectEventProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `projectEvent` to be created by this mutation. */
export type ProjectEventProjectIdFkeyProjectEventCreateInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime: Scalars['Datetime'];
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime: Scalars['Datetime'];
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against many `GoogleCalendarEvent` object types. All fields are combined with a logical ‘and.’ */
export type ProjectEventToManyGoogleCalendarEventFilter = {
  /** Every related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GoogleCalendarEventFilter>;
  /** No related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GoogleCalendarEventFilter>;
  /** Some related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GoogleCalendarEventFilter>;
};

/** A connection to a list of `ProjectEvent` values. */
export type ProjectEventsConnection = {
  __typename?: 'ProjectEventsConnection';
  /** A list of edges which contains the `ProjectEvent` and cursor to aid in pagination. */
  edges: Array<ProjectEventsEdge>;
  /** A list of `ProjectEvent` objects. */
  nodes: Array<ProjectEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectEvent` edge in the connection. */
export type ProjectEventsEdge = {
  __typename?: 'ProjectEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectEvent` at the end of the edge. */
  node: ProjectEvent;
};

/** Methods to use when ordering `ProjectEvent`. */
export enum ProjectEventsOrderBy {
  EventTypeAsc = 'EVENT_TYPE_ASC',
  EventTypeDesc = 'EVENT_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC'
}

/** A filter to be used against `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `childProjects` relation. */
  childProjects?: InputMaybe<ProjectToManyProjectFilter>;
  /** Some related `childProjects` exist. */
  childProjectsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `customer` relation. */
  customer?: InputMaybe<CustomerFilter>;
  /** A related `customer` exists. */
  customerExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerId` field. */
  customerId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `fileType` field. */
  fileType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNegotiationClosed` field. */
  isNegotiationClosed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNegotiationOpen` field. */
  isNegotiationOpen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOpen` field. */
  isOpen?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isShared` field. */
  isShared?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastModify` field. */
  lastModify?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastModifyByMe` field. */
  lastModifyByMe?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastOpenByMe` field. */
  lastOpenByMe?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectFilter>>;
  /** Filter by the object’s `organizationCoachingTimeLogs` relation. */
  organizationCoachingTimeLogs?: InputMaybe<ProjectToManyOrganizationCoachingTimeLogFilter>;
  /** Some related `organizationCoachingTimeLogs` exist. */
  organizationCoachingTimeLogsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `parent` relation. */
  parent?: InputMaybe<ProjectFilter>;
  /** A related `parent` exists. */
  parentExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `parentId` field. */
  parentId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `projectAccessLogs` relation. */
  projectAccessLogs?: InputMaybe<ProjectToManyProjectAccessLogFilter>;
  /** Some related `projectAccessLogs` exist. */
  projectAccessLogsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectAlignSurvey` relation. */
  projectAlignSurvey?: InputMaybe<ProjectAlignSurveyFilter>;
  /** A related `projectAlignSurvey` exists. */
  projectAlignSurveyExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectComments` relation. */
  projectComments?: InputMaybe<ProjectToManyProjectCommentFilter>;
  /** Some related `projectComments` exist. */
  projectCommentsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectCommunicationMessages` relation. */
  projectCommunicationMessages?: InputMaybe<ProjectToManyProjectCommunicationMessageFilter>;
  /** Some related `projectCommunicationMessages` exist. */
  projectCommunicationMessagesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectCommunicationQuestions` relation. */
  projectCommunicationQuestions?: InputMaybe<ProjectToManyProjectCommunicationQuestionFilter>;
  /** Some related `projectCommunicationQuestions` exist. */
  projectCommunicationQuestionsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectCompliances` relation. */
  projectCompliances?: InputMaybe<ProjectToManyProjectComplianceFilter>;
  /** Some related `projectCompliances` exist. */
  projectCompliancesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectConnections` relation. */
  projectConnections?: InputMaybe<ProjectToManyProjectConnectionFilter>;
  /** Some related `projectConnections` exist. */
  projectConnectionsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectEvents` relation. */
  projectEvents?: InputMaybe<ProjectToManyProjectEventFilter>;
  /** Some related `projectEvents` exist. */
  projectEventsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectFolders` relation. */
  projectFolders?: InputMaybe<ProjectToManyProjectFolderFilter>;
  /** Filter by the object’s `projectFoldersByFolderId` relation. */
  projectFoldersByFolderId?: InputMaybe<ProjectToManyProjectFolderFilter>;
  /** Some related `projectFoldersByFolderId` exist. */
  projectFoldersByFolderIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Some related `projectFolders` exist. */
  projectFoldersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectInvitations` relation. */
  projectInvitations?: InputMaybe<ProjectToManyProjectInvitationFilter>;
  /** Some related `projectInvitations` exist. */
  projectInvitationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectMemberships` relation. */
  projectMemberships?: InputMaybe<ProjectToManyProjectMembershipFilter>;
  /** Some related `projectMemberships` exist. */
  projectMembershipsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectObjectives` relation. */
  projectObjectives?: InputMaybe<ProjectToManyProjectObjectiveFilter>;
  /** Some related `projectObjectives` exist. */
  projectObjectivesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectOffers` relation. */
  projectOffers?: InputMaybe<ProjectToManyProjectOfferFilter>;
  /** Some related `projectOffers` exist. */
  projectOffersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectPower` relation. */
  projectPower?: InputMaybe<ProjectPowerFilter>;
  /** A related `projectPower` exists. */
  projectPowerExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectRisks` relation. */
  projectRisks?: InputMaybe<ProjectToManyProjectRiskFilter>;
  /** Some related `projectRisks` exist. */
  projectRisksExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectScenarios` relation. */
  projectScenarios?: InputMaybe<ProjectToManyProjectScenarioFilter>;
  /** Some related `projectScenarios` exist. */
  projectScenariosExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectScores` relation. */
  projectScores?: InputMaybe<ProjectToManyProjectScoreFilter>;
  /** Some related `projectScores` exist. */
  projectScoresExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectSearch` relation. */
  projectSearch?: InputMaybe<ProjectSearchFilter>;
  /** A related `projectSearch` exists. */
  projectSearchExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectSurvey` relation. */
  projectSurvey?: InputMaybe<ProjectSurveyFilter>;
  /** A related `projectSurvey` exists. */
  projectSurveyExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectVariables` relation. */
  projectVariables?: InputMaybe<ProjectToManyProjectVariableFilter>;
  /** Some related `projectVariables` exist. */
  projectVariablesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `titleTsv` field. */
  titleTsv?: InputMaybe<FullTextFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userIsOwner` field. */
  userIsOwner?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `value` field. */
  value?: InputMaybe<StringFilter>;
  /** Filter by the object’s `variableClassesByRelatedProjectId` relation. */
  variableClassesByRelatedProjectId?: InputMaybe<ProjectToManyVariableClassFilter>;
  /** Some related `variableClassesByRelatedProjectId` exist. */
  variableClassesByRelatedProjectIdExist?: InputMaybe<Scalars['Boolean']>;
};

/** Folders for categorizing Projects per user. */
export type ProjectFolder = {
  __typename?: 'ProjectFolder';
  /** Reads a single `Project` that is related to this `ProjectFolder`. */
  folder: Maybe<Project>;
  /** Folder ID */
  folderId: Scalars['UUID'];
  /** Reads a single `Project` that is related to this `ProjectFolder`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `ProjectFolder`. */
  user: Maybe<User>;
  /** User */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProjectFolder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectFolderCondition = {
  /** Checks for equality with the object’s `folderId` field. */
  folderId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectFolder` object types. All fields are combined with a logical ‘and.’ */
export type ProjectFolderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectFolderFilter>>;
  /** Filter by the object’s `folder` relation. */
  folder?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `folderId` field. */
  folderId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectFolderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectFolderFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `project` in the `ProjectFolderInput` mutation. */
export type ProjectFolderFolderIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectFolderFolderIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectFolderForProjectFolderFolderIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectFolder` in the `ProjectInput` mutation. */
export type ProjectFolderFolderIdFkeyInverseInput = {
  /** The primary key(s) for `projectFolder` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderUniqProjectFolderUserConnect>>;
  /** The primary key(s) and patch data for `projectFolder` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyUsingUniqProjectFolderUserUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectFolderFolderIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** All input for the `projectFolderGetChildren` mutation. */
export type ProjectFolderGetChildrenInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pProjectId: Scalars['UUID'];
};

/** The output of our `projectFolderGetChildren` mutation. */
export type ProjectFolderGetChildrenPayload = {
  __typename?: 'ProjectFolderGetChildrenPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  ids: Maybe<Array<Maybe<Scalars['UUID']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectFolder` to look up the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyUsingUniqProjectFolderUserUpdate = {
  /** An object where the defined keys will be set on the `projectFolder` being updated. */
  patch: UpdateProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectFolder` to look up the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyUsingUniqProjectFolderUserUpdate = {
  /** An object where the defined keys will be set on the `projectFolder` being updated. */
  patch: UpdateProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectFolder` to look up the row to update. */
export type ProjectFolderOnProjectFolderForProjectFolderUserIdFkeyUsingUniqProjectFolderUserUpdate = {
  /** An object where the defined keys will be set on the `projectFolder` being updated. */
  patch: UpdateProjectFolderOnProjectFolderForProjectFolderUserIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User */
  userId: Scalars['UUID'];
};

/** Input for the nested mutation of `project` in the `ProjectFolderInput` mutation. */
export type ProjectFolderProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectFolderProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectFolderForProjectFolderProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectFolder` in the `ProjectInput` mutation. */
export type ProjectFolderProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectFolder` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderUniqProjectFolderUserConnect>>;
  /** The primary key(s) and patch data for `projectFolder` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyUsingUniqProjectFolderUserUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectFolderProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectFolder` to look up the row to connect. */
export type ProjectFolderUniqProjectFolderUserConnect = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User */
  userId: Scalars['UUID'];
};

/** Input for the nested mutation of `user` in the `ProjectFolderInput` mutation. */
export type ProjectFolderUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectFolderForProjectFolderUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectFolderForProjectFolderUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectFolderOnProjectFolderForProjectFolderUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectFolder` in the `UserInput` mutation. */
export type ProjectFolderUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectFolder` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderUniqProjectFolderUserConnect>>;
  /** The primary key(s) and patch data for `projectFolder` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectFolderOnProjectFolderForProjectFolderUserIdFkeyUsingUniqProjectFolderUserUpdate>>;
};

/** A connection to a list of `ProjectFolder` values. */
export type ProjectFoldersConnection = {
  __typename?: 'ProjectFoldersConnection';
  /** A list of edges which contains the `ProjectFolder` and cursor to aid in pagination. */
  edges: Array<ProjectFoldersEdge>;
  /** A list of `ProjectFolder` objects. */
  nodes: Array<ProjectFolder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectFolder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectFolder` edge in the connection. */
export type ProjectFoldersEdge = {
  __typename?: 'ProjectFoldersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectFolder` at the end of the edge. */
  node: ProjectFolder;
};

/** Methods to use when ordering `ProjectFolder`. */
export enum ProjectFoldersOrderBy {
  FolderIdAsc = 'FOLDER_ID_ASC',
  FolderIdDesc = 'FOLDER_ID_DESC',
  Natural = 'NATURAL',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** An input for mutations affecting `Project` */
export type ProjectInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Project invitations (sharing) */
export type ProjectInvitation = Node & {
  __typename?: 'ProjectInvitation';
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** Are we giving them viewer, editor or owner access to the project? */
  membershipType: ProjectMembershipType;
  /** Invitation message (optional) */
  message: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectInvitation`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** Should we notify the user about this project being shared with them? */
  sendNotification: Scalars['Boolean'];
  /** Reads a single `User` that is related to this `ProjectInvitation`. */
  user: Maybe<User>;
  /** User ID to invite */
  userId: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `ProjectInvitation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectInvitationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectInvitation` object types. All fields are combined with a logical ‘and.’ */
export type ProjectInvitationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectInvitationFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectInvitationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectInvitationFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** A related `user` exists. */
  userExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectInvitationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectInvitation` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ProjectInvitationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `projectInvitation` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectInvitation` to look up the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectInvitationPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: UpdateProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyPatch;
};

/** The fields on `projectInvitation` to look up the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectInvitationProjectIdUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: UpdateProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID to invite */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectInvitation` to look up the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyUsingProjectInvitationPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: UpdateProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyPatch;
};

/** The fields on `projectInvitation` to look up the row to update. */
export type ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyUsingProjectInvitationProjectIdUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: UpdateProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID to invite */
  userId: Scalars['UUID'];
};

/** Represents an update to a `ProjectInvitation`. Fields that are set will be updated. */
export type ProjectInvitationPatch = {
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Are we giving them viewer, editor or owner access to the project? */
  membershipType?: InputMaybe<ProjectMembershipType>;
  /** Invitation message (optional) */
  message?: InputMaybe<Scalars['String']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectInvitationProjectIdFkeyInput>;
  /** Should we notify the user about this project being shared with them? */
  sendNotification?: InputMaybe<Scalars['Boolean']>;
  /** User ID to invite */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectInvitationUserIdFkeyInput>;
};

/** Input for the nested mutation of `project` in the `ProjectInvitationInput` mutation. */
export type ProjectInvitationProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectInvitationProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectInvitation` in the `ProjectInput` mutation. */
export type ProjectInvitationProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectInvitationProjectInvitationPkeyConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectInvitationNodeIdConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationProjectInvitationProjectIdUserIdKeyConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectInvitationProjectInvitationPkeyDelete>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectInvitationNodeIdDelete>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationProjectInvitationProjectIdUserIdKeyDelete>>;
  /** Flag indicating whether all other `projectInvitation` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectInvitationForProjectInvitationProjectIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectInvitationProjectIdUserIdKeyUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectInvitationProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectInvitation` to look up the row to connect. */
export type ProjectInvitationProjectInvitationPkeyConnect = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The fields on `projectInvitation` to look up the row to delete. */
export type ProjectInvitationProjectInvitationPkeyDelete = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The fields on `projectInvitation` to look up the row to connect. */
export type ProjectInvitationProjectInvitationProjectIdUserIdKeyConnect = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID to invite */
  userId: Scalars['UUID'];
};

/** The fields on `projectInvitation` to look up the row to delete. */
export type ProjectInvitationProjectInvitationProjectIdUserIdKeyDelete = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID to invite */
  userId: Scalars['UUID'];
};

/** Input for the nested mutation of `user` in the `ProjectInvitationInput` mutation. */
export type ProjectInvitationUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectInvitationForProjectInvitationUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectInvitationForProjectInvitationUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectInvitation` in the `UserInput` mutation. */
export type ProjectInvitationUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectInvitationProjectInvitationPkeyConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectInvitationNodeIdConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationProjectInvitationProjectIdUserIdKeyConnect>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectInvitationProjectInvitationPkeyDelete>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectInvitationNodeIdDelete>>;
  /** The primary key(s) for `projectInvitation` for the far side of the relationship. */
  deleteByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationProjectInvitationProjectIdUserIdKeyDelete>>;
  /** Flag indicating whether all other `projectInvitation` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyUsingProjectInvitationPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnProjectInvitationForProjectInvitationUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectInvitation` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyUsingProjectInvitationProjectIdUserIdKeyUpdate>>;
};

/** A connection to a list of `ProjectInvitation` values. */
export type ProjectInvitationsConnection = {
  __typename?: 'ProjectInvitationsConnection';
  /** A list of edges which contains the `ProjectInvitation` and cursor to aid in pagination. */
  edges: Array<ProjectInvitationsEdge>;
  /** A list of `ProjectInvitation` objects. */
  nodes: Array<ProjectInvitation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectInvitation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectInvitation` edge in the connection. */
export type ProjectInvitationsEdge = {
  __typename?: 'ProjectInvitationsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectInvitation` at the end of the edge. */
  node: ProjectInvitation;
};

/** Methods to use when ordering `ProjectInvitation`. */
export enum ProjectInvitationsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** An input for mutations affecting `ProjectInvitedMember` */
export type ProjectInvitedMemberInput = {
  membershipType?: InputMaybe<ProjectMembershipType>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Project membership (sharing) */
export type ProjectMembership = Node & {
  __typename?: 'ProjectMembership';
  /** When was this member added to the project? */
  createdAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  createdByUser: Maybe<User>;
  /** User who invited this member */
  createdByUserId: Scalars['UUID'];
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** Is this project archived for this particular user? */
  isArchived: Scalars['Boolean'];
  /** Is this person an editor for the project? */
  isEditor: Scalars['Boolean'];
  /** Is this person a project owner (can share)? */
  isOwner: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectMembership`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  user: Maybe<User>;
  /** User ID */
  userId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProjectMembership` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectMembershipCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdByUserId` field. */
  createdByUserId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isOwner` field. */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `user` in the `ProjectMembershipInput` mutation. */
export type ProjectMembershipCreatedByUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectMembership` in the `UserInput` mutation. */
export type ProjectMembershipCreatedByUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyDelete>>;
  /** Flag indicating whether all other `projectMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingProjectMembershipPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate>>;
};

/** A filter to be used against `ProjectMembership` object types. All fields are combined with a logical ‘and.’ */
export type ProjectMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectMembershipFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByUser` relation. */
  createdByUser?: InputMaybe<UserFilter>;
  /** Filter by the object’s `createdByUserId` field. */
  createdByUserId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOwner` field. */
  isOwner?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectMembershipFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectMembershipNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectMembership` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ProjectMembershipNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `projectMembership` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingProjectMembershipPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectMembershipPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyUsingProjectMembershipPkeyUpdate = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyPatch;
};

/** The fields on `projectMembership` to look up the row to update. */
export type ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: UpdateProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** Represents an update to a `ProjectMembership`. Fields that are set will be updated. */
export type ProjectMembershipPatch = {
  /** When was this member added to the project? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who invited this member */
  createdByUserId?: InputMaybe<Scalars['UUID']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project archived for this particular user? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this person an editor for the project? */
  isEditor?: InputMaybe<Scalars['Boolean']>;
  /** Is this person a project owner (can share)? */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectMembershipProjectIdFkeyInput>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToCreatedByUserId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectMembershipUserIdFkeyInput>;
};

/** Input for the nested mutation of `project` in the `ProjectMembershipInput` mutation. */
export type ProjectMembershipProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectMembershipProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectMembership` in the `ProjectInput` mutation. */
export type ProjectMembershipProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyDelete>>;
  /** Flag indicating whether all other `projectMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectMembershipPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectMembershipForProjectMembershipProjectIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectMembershipProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectMembership` to look up the row to connect. */
export type ProjectMembershipProjectMembershipPkeyConnect = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The fields on `projectMembership` to look up the row to delete. */
export type ProjectMembershipProjectMembershipPkeyDelete = {
  /** Unique ID for the record */
  id: Scalars['UUID'];
};

/** The fields on `projectMembership` to look up the row to connect. */
export type ProjectMembershipProjectMembershipProjectIdUserIdKeyConnect = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** The fields on `projectMembership` to look up the row to delete. */
export type ProjectMembershipProjectMembershipProjectIdUserIdKeyDelete = {
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** Membership type - are we giving them viewer, editor or owner access to the project. */
export enum ProjectMembershipType {
  Editor = 'EDITOR',
  Owner = 'OWNER',
  Viewer = 'VIEWER'
}

/** Input for the nested mutation of `user` in the `ProjectMembershipInput` mutation. */
export type ProjectMembershipUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnProjectMembershipForProjectMembershipUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnProjectMembershipForProjectMembershipUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectMembership` in the `UserInput` mutation. */
export type ProjectMembershipUserIdFkeyInverseInput = {
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  connectByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyConnect>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ProjectMembershipProjectMembershipPkeyDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<ProjectMembershipNodeIdDelete>>;
  /** The primary key(s) for `projectMembership` for the far side of the relationship. */
  deleteByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipProjectMembershipProjectIdUserIdKeyDelete>>;
  /** Flag indicating whether all other `projectMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyUsingProjectMembershipPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnProjectMembershipForProjectMembershipUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectMembership` for the far side of the relationship. */
  updateByProjectIdAndUserId?: InputMaybe<Array<ProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyUsingProjectMembershipProjectIdUserIdKeyUpdate>>;
};

/** A connection to a list of `ProjectMembership` values. */
export type ProjectMembershipsConnection = {
  __typename?: 'ProjectMembershipsConnection';
  /** A list of edges which contains the `ProjectMembership` and cursor to aid in pagination. */
  edges: Array<ProjectMembershipsEdge>;
  /** A list of `ProjectMembership` objects. */
  nodes: Array<ProjectMembership>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectMembership` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectMembership` edge in the connection. */
export type ProjectMembershipsEdge = {
  __typename?: 'ProjectMembershipsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectMembership` at the end of the edge. */
  node: ProjectMembership;
};

/** Methods to use when ordering `ProjectMembership`. */
export enum ProjectMembershipsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByUserIdAsc = 'CREATED_BY_USER_ID_ASC',
  CreatedByUserIdDesc = 'CREATED_BY_USER_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsOwnerAsc = 'IS_OWNER_ASC',
  IsOwnerDesc = 'IS_OWNER_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** The globally unique `ID` look up for the row to connect. */
export type ProjectNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type ProjectNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `project` to be deleted. */
  nodeId: Scalars['ID'];
};

/** Project Objectives */
export type ProjectObjective = Node & {
  __typename?: 'ProjectObjective';
  /** Objective ID */
  id: Scalars['UUID'];
  /** Is this objective deleted? */
  isDeleted: Scalars['Boolean'];
  /** Possible values: opportunity, dynamics, smart, their, restrictions_int, restrictions_ext */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectObjective`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Value is JSON with answers provided by users. */
  value: Maybe<Scalars['JSON']>;
};

/**
 * A condition to be used against `ProjectObjective` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectObjectiveCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectObjective` object types. All fields are combined with a logical ‘and.’ */
export type ProjectObjectiveFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectObjectiveFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectObjectiveFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectObjectiveFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectObjectiveNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectObjective` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectObjective` to look up the row to update. */
export type ProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyUsingProjectObjectivePkeyUpdate = {
  /** Objective ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectObjective` being updated. */
  patch: UpdateProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyPatch;
};

/** Represents an update to a `ProjectObjective`. Fields that are set will be updated. */
export type ProjectObjectivePatch = {
  /** Objective ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this objective deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Possible values: opportunity, dynamics, smart, their, restrictions_int, restrictions_ext */
  name?: InputMaybe<Scalars['String']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectObjectiveProjectIdFkeyInput>;
  /** Value is JSON with answers provided by users. */
  value?: InputMaybe<Scalars['JSON']>;
};

/** Input for the nested mutation of `project` in the `ProjectObjectiveInput` mutation. */
export type ProjectObjectiveProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectObjectiveProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectObjective` in the `ProjectInput` mutation. */
export type ProjectObjectiveProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectObjective` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectObjectiveProjectObjectivePkeyConnect>>;
  /** The primary key(s) for `projectObjective` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectObjectiveNodeIdConnect>>;
  /** The primary key(s) and patch data for `projectObjective` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyUsingProjectObjectivePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectObjective` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectObjectiveProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectObjective` to look up the row to connect. */
export type ProjectObjectiveProjectObjectivePkeyConnect = {
  /** Objective ID */
  id: Scalars['UUID'];
};

/** A connection to a list of `ProjectObjective` values. */
export type ProjectObjectivesConnection = {
  __typename?: 'ProjectObjectivesConnection';
  /** A list of edges which contains the `ProjectObjective` and cursor to aid in pagination. */
  edges: Array<ProjectObjectivesEdge>;
  /** A list of `ProjectObjective` objects. */
  nodes: Array<ProjectObjective>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectObjective` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectObjective` edge in the connection. */
export type ProjectObjectivesEdge = {
  __typename?: 'ProjectObjectivesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectObjective` at the end of the edge. */
  node: ProjectObjective;
};

/** Methods to use when ordering `ProjectObjective`. */
export enum ProjectObjectivesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** Project Offers */
export type ProjectOffer = Node & {
  __typename?: 'ProjectOffer';
  /** When was this project offer created? */
  createdAt: Scalars['Datetime'];
  /** Offer ID */
  id: Scalars['BigInt'];
  /** Is this project offer deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectOffer`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ProjectOfferVariable`. */
  projectOfferVariables: ProjectOfferVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectOfferVariable`. */
  projectOfferVariablesList: Array<ProjectOfferVariable>;
  /** When was this project offer last updated? */
  updatedAt: Scalars['Datetime'];
};


/** Project Offers */
export type ProjectOfferProjectOfferVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};


/** Project Offers */
export type ProjectOfferProjectOfferVariablesListArgs = {
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};

/**
 * A condition to be used against `ProjectOffer` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectOfferCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectOffer` object types. All fields are combined with a logical ‘and.’ */
export type ProjectOfferFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectOfferFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectOfferFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectOfferFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `projectOfferVariables` relation. */
  projectOfferVariables?: InputMaybe<ProjectOfferToManyProjectOfferVariableFilter>;
  /** Some related `projectOfferVariables` exist. */
  projectOfferVariablesExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `ProjectOffer` */
export type ProjectOfferInput = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectOfferNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectOffer` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectOffer` to look up the row to update. */
export type ProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyUsingProjectOfferPkeyUpdate = {
  /** Offer ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectOffer` being updated. */
  patch: UpdateProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectOfferVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
  patch: ProjectOfferVariablePatch;
};

/** The fields on `projectOffer` to look up the row to update. */
export type ProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyUsingProjectOfferPkeyUpdate = {
  /** Offer ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectOffer` being updated. */
  patch: UpdateProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyPatch;
};

/** Represents an update to a `ProjectOffer`. Fields that are set will be updated. */
export type ProjectOfferPatch = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectOfferInput` mutation. */
export type ProjectOfferProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectOfferProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectOfferForProjectOfferProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectOffer` in the `ProjectInput` mutation. */
export type ProjectOfferProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectOffer` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectOfferProjectOfferPkeyConnect>>;
  /** The primary key(s) for `projectOffer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectOfferNodeIdConnect>>;
  /** A `ProjectOfferInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectOfferProjectIdFkeyProjectOfferCreateInput>>;
  /** The primary key(s) and patch data for `projectOffer` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyUsingProjectOfferPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectOffer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectOfferForProjectOfferProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectOfferProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `projectOffer` to be created by this mutation. */
export type ProjectOfferProjectIdFkeyProjectOfferCreateInput = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectOffer` to look up the row to connect. */
export type ProjectOfferProjectOfferPkeyConnect = {
  /** Offer ID */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `ProjectOfferVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectOfferToManyProjectOfferVariableFilter = {
  /** Every related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectOfferVariableFilter>;
  /** No related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectOfferVariableFilter>;
  /** Some related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectOfferVariableFilter>;
};

/** Project Offer Variables */
export type ProjectOfferVariable = Node & {
  __typename?: 'ProjectOfferVariable';
  /** Variable amount (value) */
  amount: Maybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt: Scalars['Datetime'];
  /** Offer Variable ID */
  id: Scalars['BigInt'];
  /** Are we aligned with the other side? */
  isAligned: Scalars['Boolean'];
  /** Is the related project offer deleted? */
  isDeleted: Maybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list: OfferVariableList;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Value selected by us */
  ourSelected: Maybe<Scalars['String']>;
  /** Reads a single `ProjectOffer` that is related to this `ProjectOfferVariable`. */
  projectOffer: Maybe<ProjectOffer>;
  /** Related Project Offer ID */
  projectOfferId: Scalars['BigInt'];
  /** Value selected by them */
  theirSelected: Maybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `ProjectVariable` that is related to this `ProjectOfferVariable`. */
  variable: Maybe<ProjectVariable>;
  /** Related Project Variable ID */
  variableId: Scalars['UUID'];
  /** How to order this variable? */
  weight: Scalars['Int'];
};

/**
 * A condition to be used against `ProjectOfferVariable` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProjectOfferVariableCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `list` field. */
  list?: InputMaybe<OfferVariableList>;
  /** Checks for equality with the object’s `projectOfferId` field. */
  projectOfferId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `variableId` field. */
  variableId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `weight` field. */
  weight?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `ProjectOfferVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectOfferVariableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectOfferVariableFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `list` field. */
  list?: InputMaybe<OfferVariableListFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectOfferVariableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectOfferVariableFilter>>;
  /** Filter by the object’s `projectOffer` relation. */
  projectOffer?: InputMaybe<ProjectOfferFilter>;
  /** Filter by the object’s `projectOfferId` field. */
  projectOfferId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `variable` relation. */
  variable?: InputMaybe<ProjectVariableFilter>;
  /** Filter by the object’s `variableId` field. */
  variableId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `weight` field. */
  weight?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `ProjectOfferVariable` */
export type ProjectOfferVariableInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  /** Related Project Offer ID */
  projectOfferId?: InputMaybe<Scalars['BigInt']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectOfferVariableNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectOfferVariable` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectOffer` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectOffer` being updated. */
  patch: ProjectOfferPatch;
};

/** The fields on `projectOfferVariable` to look up the row to update. */
export type ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyUsingProjectOfferVariablePkeyUpdate = {
  /** Offer Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
  patch: UpdateProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** The fields on `projectOfferVariable` to look up the row to update. */
export type ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyUsingProjectOfferVariablePkeyUpdate = {
  /** Offer Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
  patch: UpdateProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyPatch;
};

/** Represents an update to a `ProjectOfferVariable`. Fields that are set will be updated. */
export type ProjectOfferVariablePatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  /** Related Project Offer ID */
  projectOfferId?: InputMaybe<Scalars['BigInt']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** Input for the nested mutation of `projectOffer` in the `ProjectOfferVariableInput` mutation. */
export type ProjectOfferVariableProjectOfferIdFkeyInput = {
  /** The primary key(s) for `projectOffer` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectOfferProjectOfferPkeyConnect>;
  /** The primary key(s) for `projectOffer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectOfferNodeIdConnect>;
  /** A `ProjectOfferInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyProjectOfferCreateInput>;
  /** The primary key(s) and patch data for `projectOffer` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyUsingProjectOfferPkeyUpdate>;
  /** The primary key(s) and patch data for `projectOffer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectOfferVariable` in the `ProjectOfferInput` mutation. */
export type ProjectOfferVariableProjectOfferIdFkeyInverseInput = {
  /** The primary key(s) for `projectOfferVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectOfferVariableProjectOfferVariablePkeyConnect>>;
  /** The primary key(s) for `projectOfferVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectOfferVariableNodeIdConnect>>;
  /** A `ProjectOfferVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectOfferVariableProjectOfferIdFkeyProjectOfferVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectOfferVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyUsingProjectOfferVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectOfferVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyNodeIdUpdate>>;
};

/** The `projectOffer` to be created by this mutation. */
export type ProjectOfferVariableProjectOfferIdFkeyProjectOfferCreateInput = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `projectOfferVariable` to be created by this mutation. */
export type ProjectOfferVariableProjectOfferIdFkeyProjectOfferVariableCreateInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** The fields on `projectOfferVariable` to look up the row to connect. */
export type ProjectOfferVariableProjectOfferVariablePkeyConnect = {
  /** Offer Variable ID */
  id: Scalars['BigInt'];
};

/** Input for the nested mutation of `projectVariable` in the `ProjectOfferVariableInput` mutation. */
export type ProjectOfferVariableVariableIdFkeyInput = {
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectVariableProjectVariablePkeyConnect>;
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectVariableNodeIdConnect>;
  /** A `ProjectVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectOfferVariableVariableIdFkeyProjectVariableCreateInput>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyUsingProjectVariablePkeyUpdate>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectOfferVariable` in the `ProjectVariableInput` mutation. */
export type ProjectOfferVariableVariableIdFkeyInverseInput = {
  /** The primary key(s) for `projectOfferVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectOfferVariableProjectOfferVariablePkeyConnect>>;
  /** The primary key(s) for `projectOfferVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectOfferVariableNodeIdConnect>>;
  /** A `ProjectOfferVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectOfferVariableVariableIdFkeyProjectOfferVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectOfferVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyUsingProjectOfferVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectOfferVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyNodeIdUpdate>>;
};

/** The `projectOfferVariable` to be created by this mutation. */
export type ProjectOfferVariableVariableIdFkeyProjectOfferVariableCreateInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  /** Related Project Offer ID */
  projectOfferId?: InputMaybe<Scalars['BigInt']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** The `projectVariable` to be created by this mutation. */
export type ProjectOfferVariableVariableIdFkeyProjectVariableCreateInput = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** A connection to a list of `ProjectOfferVariable` values. */
export type ProjectOfferVariablesConnection = {
  __typename?: 'ProjectOfferVariablesConnection';
  /** A list of edges which contains the `ProjectOfferVariable` and cursor to aid in pagination. */
  edges: Array<ProjectOfferVariablesEdge>;
  /** A list of `ProjectOfferVariable` objects. */
  nodes: Array<ProjectOfferVariable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectOfferVariable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectOfferVariable` edge in the connection. */
export type ProjectOfferVariablesEdge = {
  __typename?: 'ProjectOfferVariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectOfferVariable` at the end of the edge. */
  node: ProjectOfferVariable;
};

/** Methods to use when ordering `ProjectOfferVariable`. */
export enum ProjectOfferVariablesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ListAsc = 'LIST_ASC',
  ListDesc = 'LIST_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectOfferIdAsc = 'PROJECT_OFFER_ID_ASC',
  ProjectOfferIdDesc = 'PROJECT_OFFER_ID_DESC',
  VariableIdAsc = 'VARIABLE_ID_ASC',
  VariableIdDesc = 'VARIABLE_ID_DESC',
  WeightAsc = 'WEIGHT_ASC',
  WeightDesc = 'WEIGHT_DESC'
}

/** A connection to a list of `ProjectOffer` values. */
export type ProjectOffersConnection = {
  __typename?: 'ProjectOffersConnection';
  /** A list of edges which contains the `ProjectOffer` and cursor to aid in pagination. */
  edges: Array<ProjectOffersEdge>;
  /** A list of `ProjectOffer` objects. */
  nodes: Array<ProjectOffer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectOffer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectOffer` edge in the connection. */
export type ProjectOffersEdge = {
  __typename?: 'ProjectOffersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectOffer` at the end of the edge. */
  node: ProjectOffer;
};

/** Methods to use when ordering `ProjectOffer`. */
export enum ProjectOffersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTimeLog` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: OrganizationCoachingTimeLogPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectAccessLogForProjectAccessLogProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectAccessLogForProjectAccessLogProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectAlignSurvey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectAlignSurvey` being updated. */
  patch: ProjectAlignSurveyPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectCommentForProjectCommentProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectComment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: ProjectCommentPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectCommentForProjectCommentProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectCommentForProjectCommentProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectCommunicationMessage` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
  patch: ProjectCommunicationMessagePatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectCommunicationQuestion` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectCommunicationQuestion` being updated. */
  patch: ProjectCommunicationQuestionPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectComplianceForProjectComplianceProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectCompliance` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectCompliance` being updated. */
  patch: ProjectCompliancePatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectComplianceForProjectComplianceProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectComplianceForProjectComplianceProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectConnectionForProjectConnectionProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectConnection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectConnectionForProjectConnectionProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectConnectionForProjectConnectionProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectEventForProjectEventProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectEvent` being updated. */
  patch: ProjectEventPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectEventForProjectEventProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectEventForProjectEventProjectIdFkeyPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectFolderForProjectFolderFolderIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectFolderForProjectFolderFolderIdFkeyPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectFolderForProjectFolderProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectFolderForProjectFolderProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectForProjectAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectAccountIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForProjectAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectForProjectCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectCreatedByFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForProjectCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectForProjectCustomerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `customer` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `customer` being updated. */
  patch: CustomerPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectCustomerIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForProjectCustomerIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectForProjectParentIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectForProjectParentIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectForProjectParentIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectInvitationForProjectInvitationProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectInvitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: ProjectInvitationPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectInvitationForProjectInvitationProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectInvitationForProjectInvitationProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectMembershipForProjectMembershipProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectMembership` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: ProjectMembershipPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectMembershipForProjectMembershipProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectMembershipForProjectMembershipProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectObjective` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectObjective` being updated. */
  patch: ProjectObjectivePatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectOfferForProjectOfferProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectOffer` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectOffer` being updated. */
  patch: ProjectOfferPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectOfferForProjectOfferProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectOfferForProjectOfferProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectPowerForProjectPowerProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectPower` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectPower` being updated. */
  patch: ProjectPowerPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectPowerForProjectPowerProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectPowerForProjectPowerProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectRiskForProjectRiskProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectRisk` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectRisk` being updated. */
  patch: ProjectRiskPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectRiskForProjectRiskProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectRiskForProjectRiskProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectScenarioForProjectScenarioProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectScenario` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectScenario` being updated. */
  patch: ProjectScenarioPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectScenarioForProjectScenarioProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectScenarioForProjectScenarioProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectScoreForProjectScoreProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectScore` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectScore` being updated. */
  patch: ProjectScorePatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectScoreForProjectScoreProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectScoreForProjectScoreProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectSearchForProjectSearchProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectSearch` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectSearch` being updated. */
  patch: ProjectSearchPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectSearchForProjectSearchProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectSearchForProjectSearchProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectSurveyForProjectSurveyProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectSurvey` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectSurvey` being updated. */
  patch: ProjectSurveyPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectSurveyForProjectSurveyProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectSurveyForProjectSurveyProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnProjectVariableForProjectVariableProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnProjectVariableForProjectVariableProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnProjectVariableForProjectVariableProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectOnVariableClassForVariableClassRelatedProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `variableClass` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: VariableClassPatch;
};

/** The fields on `project` to look up the row to update. */
export type ProjectOnVariableClassForVariableClassRelatedProjectIdFkeyUsingProjectPkeyUpdate = {
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: UpdateProjectOnVariableClassForVariableClassRelatedProjectIdFkeyPatch;
};

/** Input for the nested mutation of `project` in the `ProjectInput` mutation. */
export type ProjectParentIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectParentIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectForProjectParentIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectForProjectParentIdFkeyNodeIdUpdate>;
};

/** The `project` to be created by this mutation. */
export type ProjectParentIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Represents an update to a `Project`. Fields that are set will be updated. */
export type ProjectPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** Project Power State */
export type ProjectPower = Node & {
  __typename?: 'ProjectPower';
  /** JSON with all the answers of Assess the power section. */
  answers: Maybe<Scalars['JSON']>;
  /** When was this project power state created? */
  createdAt: Scalars['Datetime'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** JSON with all the answers of the Power perception shifter inputs, with keys: enhance, change, downgrade, exploit. */
  perception: Maybe<Scalars['JSON']>;
  /** Reads a single `Project` that is related to this `ProjectPower`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** JSON with scores calculated based on the answers, with keys: power_state, macro, micro. */
  score: Maybe<Scalars['JSON']>;
  /** When was this project power state last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectPower` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectPowerCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectPower` object types. All fields are combined with a logical ‘and.’ */
export type ProjectPowerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectPowerFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectPowerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectPowerFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectPowerNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectPower` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectPower` to look up the row to update. */
export type ProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyUsingProjectPowerPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectPower` being updated. */
  patch: UpdateProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectPower`. Fields that are set will be updated. */
export type ProjectPowerPatch = {
  /** JSON with all the answers of Assess the power section. */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project power state created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** JSON with all the answers of the Power perception shifter inputs, with keys: enhance, change, downgrade, exploit. */
  perception?: InputMaybe<Scalars['JSON']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectPowerProjectIdFkeyInput>;
  /** JSON with scores calculated based on the answers, with keys: power_state, macro, micro. */
  score?: InputMaybe<Scalars['JSON']>;
  /** When was this project power state last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectPowerInput` mutation. */
export type ProjectPowerProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectPowerProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectPowerForProjectPowerProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectPower` in the `ProjectInput` mutation. */
export type ProjectPowerProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectPower` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectPowerNodeIdConnect>;
  /** The primary key(s) for `projectPower` for the far side of the relationship. */
  connectByProjectId?: InputMaybe<ProjectPowerProjectPowerPkeyConnect>;
  /** The primary key(s) and patch data for `projectPower` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectPowerForProjectPowerProjectIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectPower` for the far side of the relationship. */
  updateByProjectId?: InputMaybe<ProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyUsingProjectPowerPkeyUpdate>;
};

/** The `project` to be created by this mutation. */
export type ProjectPowerProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectPower` to look up the row to connect. */
export type ProjectPowerProjectPowerPkeyConnect = {
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** A connection to a list of `ProjectPower` values. */
export type ProjectPowersConnection = {
  __typename?: 'ProjectPowersConnection';
  /** A list of edges which contains the `ProjectPower` and cursor to aid in pagination. */
  edges: Array<ProjectPowersEdge>;
  /** A list of `ProjectPower` objects. */
  nodes: Array<ProjectPower>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectPower` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectPower` edge in the connection. */
export type ProjectPowersEdge = {
  __typename?: 'ProjectPowersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectPower` at the end of the edge. */
  node: ProjectPower;
};

/** Methods to use when ordering `ProjectPower`. */
export enum ProjectPowersOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** The fields on `project` to look up the row to connect. */
export type ProjectProjectPkeyConnect = {
  /** Project / folder ID */
  id: Scalars['UUID'];
};

/** The fields on `project` to look up the row to delete. */
export type ProjectProjectPkeyDelete = {
  /** Project / folder ID */
  id: Scalars['UUID'];
};

/**
 * Project Reminders
 * Keeps history of sent reminders, so that we do not send the same reminder twice.
 */
export type ProjectReminder = Node & {
  __typename?: 'ProjectReminder';
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  projectId: Maybe<Scalars['UUID']>;
  /** The type of reminder that was sent */
  type: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  userId: Maybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `ProjectReminder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectReminderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectReminder` object types. All fields are combined with a logical ‘and.’ */
export type ProjectReminderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectReminderFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectReminderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectReminderFilter>>;
};

/** An input for mutations affecting `ProjectReminder` */
export type ProjectReminderInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  /** The type of reminder that was sent */
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** Represents an update to a `ProjectReminder`. Fields that are set will be updated. */
export type ProjectReminderPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  /** The type of reminder that was sent */
  type?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A connection to a list of `ProjectReminder` values. */
export type ProjectRemindersConnection = {
  __typename?: 'ProjectRemindersConnection';
  /** A list of edges which contains the `ProjectReminder` and cursor to aid in pagination. */
  edges: Array<ProjectRemindersEdge>;
  /** A list of `ProjectReminder` objects. */
  nodes: Array<ProjectReminder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectReminder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectReminder` edge in the connection. */
export type ProjectRemindersEdge = {
  __typename?: 'ProjectRemindersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectReminder` at the end of the edge. */
  node: ProjectReminder;
};

/** Methods to use when ordering `ProjectReminder`. */
export enum ProjectRemindersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Project Risks */
export type ProjectRisk = Node & {
  __typename?: 'ProjectRisk';
  /** When was this event created? */
  createdAt: Scalars['Datetime'];
  /** Risk description */
  description: Scalars['String'];
  /** Risk ID */
  id: Scalars['UUID'];
  /** What would be the impact if it happens? */
  impactOnBusiness: Maybe<Scalars['Int']>;
  /** Impact score for this risk (auto-calculated) */
  impactScore: Maybe<Scalars['BigFloat']>;
  /** Is this event archived? */
  isArchived: Scalars['Boolean'];
  /** Is this event deleted? */
  isDeleted: Scalars['Boolean'];
  /** What are the mitigation actions? */
  mitigationAction: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** What can we do to prevent it from happening? */
  preventiveDescription: Maybe<Scalars['String']>;
  /** Probability of happening */
  probability: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Project` that is related to this `ProjectRisk`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Risk level (auto-calculated) */
  riskLevel: Maybe<RiskLevel>;
  /** When was this event last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectRisk` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProjectRiskCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `impactScore` field. */
  impactScore?: InputMaybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `riskLevel` field. */
  riskLevel?: InputMaybe<RiskLevel>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** A filter to be used against `ProjectRisk` object types. All fields are combined with a logical ‘and.’ */
export type ProjectRiskFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectRiskFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `impactScore` field. */
  impactScore?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectRiskFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectRiskFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `riskLevel` field. */
  riskLevel?: InputMaybe<RiskLevelFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ProjectRisk` */
export type ProjectRiskInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Risk description */
  description: Scalars['String'];
  /** Risk ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** What would be the impact if it happens? */
  impactOnBusiness?: InputMaybe<Scalars['Int']>;
  /** Impact score for this risk (auto-calculated) */
  impactScore?: InputMaybe<Scalars['BigFloat']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** What are the mitigation actions? */
  mitigationAction?: InputMaybe<Scalars['String']>;
  /** What can we do to prevent it from happening? */
  preventiveDescription?: InputMaybe<Scalars['String']>;
  /** Probability of happening */
  probability?: InputMaybe<Scalars['BigFloat']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectRiskProjectIdFkeyInput>;
  /** Risk level (auto-calculated) */
  riskLevel?: InputMaybe<RiskLevel>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectRiskNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectRisk` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectRisk` to look up the row to update. */
export type ProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyUsingProjectRiskPkeyUpdate = {
  /** Risk ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectRisk` being updated. */
  patch: UpdateProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyPatch;
};

/** Represents an update to a `ProjectRisk`. Fields that are set will be updated. */
export type ProjectRiskPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Risk description */
  description?: InputMaybe<Scalars['String']>;
  /** Risk ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** What would be the impact if it happens? */
  impactOnBusiness?: InputMaybe<Scalars['Int']>;
  /** Impact score for this risk (auto-calculated) */
  impactScore?: InputMaybe<Scalars['BigFloat']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** What are the mitigation actions? */
  mitigationAction?: InputMaybe<Scalars['String']>;
  /** What can we do to prevent it from happening? */
  preventiveDescription?: InputMaybe<Scalars['String']>;
  /** Probability of happening */
  probability?: InputMaybe<Scalars['BigFloat']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectRiskProjectIdFkeyInput>;
  /** Risk level (auto-calculated) */
  riskLevel?: InputMaybe<RiskLevel>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectRiskInput` mutation. */
export type ProjectRiskProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectRiskProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectRiskForProjectRiskProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectRisk` in the `ProjectInput` mutation. */
export type ProjectRiskProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectRisk` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectRiskProjectRiskPkeyConnect>>;
  /** The primary key(s) for `projectRisk` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectRiskNodeIdConnect>>;
  /** A `ProjectRiskInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectRiskProjectIdFkeyProjectRiskCreateInput>>;
  /** The primary key(s) and patch data for `projectRisk` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyUsingProjectRiskPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectRisk` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectRiskForProjectRiskProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectRiskProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `projectRisk` to be created by this mutation. */
export type ProjectRiskProjectIdFkeyProjectRiskCreateInput = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Risk description */
  description: Scalars['String'];
  /** Risk ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** What would be the impact if it happens? */
  impactOnBusiness?: InputMaybe<Scalars['Int']>;
  /** Impact score for this risk (auto-calculated) */
  impactScore?: InputMaybe<Scalars['BigFloat']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** What are the mitigation actions? */
  mitigationAction?: InputMaybe<Scalars['String']>;
  /** What can we do to prevent it from happening? */
  preventiveDescription?: InputMaybe<Scalars['String']>;
  /** Probability of happening */
  probability?: InputMaybe<Scalars['BigFloat']>;
  projectToProjectId?: InputMaybe<ProjectRiskProjectIdFkeyInput>;
  /** Risk level (auto-calculated) */
  riskLevel?: InputMaybe<RiskLevel>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectRisk` to look up the row to connect. */
export type ProjectRiskProjectRiskPkeyConnect = {
  /** Risk ID */
  id: Scalars['UUID'];
};

/** A connection to a list of `ProjectRisk` values. */
export type ProjectRisksConnection = {
  __typename?: 'ProjectRisksConnection';
  /** A list of edges which contains the `ProjectRisk` and cursor to aid in pagination. */
  edges: Array<ProjectRisksEdge>;
  /** A list of `ProjectRisk` objects. */
  nodes: Array<ProjectRisk>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectRisk` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectRisk` edge in the connection. */
export type ProjectRisksEdge = {
  __typename?: 'ProjectRisksEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectRisk` at the end of the edge. */
  node: ProjectRisk;
};

/** Methods to use when ordering `ProjectRisk`. */
export enum ProjectRisksOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImpactScoreAsc = 'IMPACT_SCORE_ASC',
  ImpactScoreDesc = 'IMPACT_SCORE_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  RiskLevelAsc = 'RISK_LEVEL_ASC',
  RiskLevelDesc = 'RISK_LEVEL_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Project Scenarios */
export type ProjectScenario = Node & {
  __typename?: 'ProjectScenario';
  /** When was this scenario created? */
  createdAt: Scalars['Datetime'];
  /** Scenario ID */
  id: Scalars['BigInt'];
  /** Is this scenario deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectScenario`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ProjectScenarioVariable`. */
  projectScenarioVariables: ProjectScenarioVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectScenarioVariable`. */
  projectScenarioVariablesList: Array<ProjectScenarioVariable>;
  /** Scenario Title */
  title: Scalars['String'];
  /** When was this scenario last updated? */
  updatedAt: Scalars['Datetime'];
};


/** Project Scenarios */
export type ProjectScenarioProjectScenarioVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};


/** Project Scenarios */
export type ProjectScenarioProjectScenarioVariablesListArgs = {
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};

/**
 * A condition to be used against `ProjectScenario` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectScenarioCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectScenario` object types. All fields are combined with a logical ‘and.’ */
export type ProjectScenarioFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectScenarioFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectScenarioFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectScenarioFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `projectScenarioVariables` relation. */
  projectScenarioVariables?: InputMaybe<ProjectScenarioToManyProjectScenarioVariableFilter>;
  /** Some related `projectScenarioVariables` exist. */
  projectScenarioVariablesExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `ProjectScenario` */
export type ProjectScenarioInput = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title: Scalars['String'];
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectScenarioNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectScenario` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectScenario` to look up the row to update. */
export type ProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyUsingProjectScenarioPkeyUpdate = {
  /** Scenario ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectScenario` being updated. */
  patch: UpdateProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectScenarioVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
  patch: ProjectScenarioVariablePatch;
};

/** The fields on `projectScenario` to look up the row to update. */
export type ProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyUsingProjectScenarioPkeyUpdate = {
  /** Scenario ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectScenario` being updated. */
  patch: UpdateProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyPatch;
};

/** Represents an update to a `ProjectScenario`. Fields that are set will be updated. */
export type ProjectScenarioPatch = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title?: InputMaybe<Scalars['String']>;
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectScenarioInput` mutation. */
export type ProjectScenarioProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectScenarioProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectScenarioForProjectScenarioProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectScenario` in the `ProjectInput` mutation. */
export type ProjectScenarioProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectScenario` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectScenarioProjectScenarioPkeyConnect>>;
  /** The primary key(s) for `projectScenario` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectScenarioNodeIdConnect>>;
  /** A `ProjectScenarioInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectScenarioProjectIdFkeyProjectScenarioCreateInput>>;
  /** The primary key(s) and patch data for `projectScenario` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyUsingProjectScenarioPkeyUpdate>>;
  /** The primary key(s) and patch data for `projectScenario` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectScenarioForProjectScenarioProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectScenarioProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `projectScenario` to be created by this mutation. */
export type ProjectScenarioProjectIdFkeyProjectScenarioCreateInput = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title: Scalars['String'];
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The fields on `projectScenario` to look up the row to connect. */
export type ProjectScenarioProjectScenarioPkeyConnect = {
  /** Scenario ID */
  id: Scalars['BigInt'];
};

/** A filter to be used against many `ProjectScenarioVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectScenarioToManyProjectScenarioVariableFilter = {
  /** Every related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectScenarioVariableFilter>;
  /** No related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectScenarioVariableFilter>;
  /** Some related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectScenarioVariableFilter>;
};

/** Project Scenario Variables */
export type ProjectScenarioVariable = Node & {
  __typename?: 'ProjectScenarioVariable';
  /** Variable amount (value) */
  amount: Maybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id: Scalars['BigInt'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Alternative value if selected = 'other' */
  other: Maybe<Scalars['String']>;
  /** Reads a single `ProjectScenario` that is related to this `ProjectScenarioVariable`. */
  projectScenario: Maybe<ProjectScenario>;
  /** Related Project Scenario ID */
  projectScenarioId: Scalars['BigInt'];
  /** What have we selected for this variable? */
  selected: Maybe<Scalars['String']>;
  /** Reads a single `ProjectVariable` that is related to this `ProjectScenarioVariable`. */
  variable: Maybe<ProjectVariable>;
  /** Related Project Variable ID */
  variableId: Scalars['UUID'];
};

/**
 * A condition to be used against `ProjectScenarioVariable` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProjectScenarioVariableCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `projectScenarioId` field. */
  projectScenarioId?: InputMaybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `variableId` field. */
  variableId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectScenarioVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectScenarioVariableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectScenarioVariableFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectScenarioVariableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectScenarioVariableFilter>>;
  /** Filter by the object’s `projectScenario` relation. */
  projectScenario?: InputMaybe<ProjectScenarioFilter>;
  /** Filter by the object’s `projectScenarioId` field. */
  projectScenarioId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `variable` relation. */
  variable?: InputMaybe<ProjectVariableFilter>;
  /** Filter by the object’s `variableId` field. */
  variableId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ProjectScenarioVariable` */
export type ProjectScenarioVariableInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  /** Related Project Scenario ID */
  projectScenarioId?: InputMaybe<Scalars['BigInt']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectScenarioVariableNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectScenarioVariable` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectScenario` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectScenario` being updated. */
  patch: ProjectScenarioPatch;
};

/** The fields on `projectScenarioVariable` to look up the row to update. */
export type ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyUsingProjectScenarioVariablePkeyUpdate = {
  /** Scenario Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
  patch: UpdateProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** The fields on `projectScenarioVariable` to look up the row to update. */
export type ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyUsingProjectScenarioVariablePkeyUpdate = {
  /** Scenario Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
  patch: UpdateProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyPatch;
};

/** Represents an update to a `ProjectScenarioVariable`. Fields that are set will be updated. */
export type ProjectScenarioVariablePatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  /** Related Project Scenario ID */
  projectScenarioId?: InputMaybe<Scalars['BigInt']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `projectScenario` in the `ProjectScenarioVariableInput` mutation. */
export type ProjectScenarioVariableProjectScenarioIdFkeyInput = {
  /** The primary key(s) for `projectScenario` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectScenarioProjectScenarioPkeyConnect>;
  /** The primary key(s) for `projectScenario` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectScenarioNodeIdConnect>;
  /** A `ProjectScenarioInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyProjectScenarioCreateInput>;
  /** The primary key(s) and patch data for `projectScenario` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyUsingProjectScenarioPkeyUpdate>;
  /** The primary key(s) and patch data for `projectScenario` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectScenarioVariable` in the `ProjectScenarioInput` mutation. */
export type ProjectScenarioVariableProjectScenarioIdFkeyInverseInput = {
  /** The primary key(s) for `projectScenarioVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectScenarioVariableProjectScenarioVariablePkeyConnect>>;
  /** The primary key(s) for `projectScenarioVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectScenarioVariableNodeIdConnect>>;
  /** A `ProjectScenarioVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectScenarioVariableProjectScenarioIdFkeyProjectScenarioVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectScenarioVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyUsingProjectScenarioVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectScenarioVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyNodeIdUpdate>>;
};

/** The `projectScenario` to be created by this mutation. */
export type ProjectScenarioVariableProjectScenarioIdFkeyProjectScenarioCreateInput = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title: Scalars['String'];
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** The `projectScenarioVariable` to be created by this mutation. */
export type ProjectScenarioVariableProjectScenarioIdFkeyProjectScenarioVariableCreateInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
};

/** The fields on `projectScenarioVariable` to look up the row to connect. */
export type ProjectScenarioVariableProjectScenarioVariablePkeyConnect = {
  /** Scenario Variable ID */
  id: Scalars['BigInt'];
};

/** Input for the nested mutation of `projectVariable` in the `ProjectScenarioVariableInput` mutation. */
export type ProjectScenarioVariableVariableIdFkeyInput = {
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectVariableProjectVariablePkeyConnect>;
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectVariableNodeIdConnect>;
  /** A `ProjectVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectScenarioVariableVariableIdFkeyProjectVariableCreateInput>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyUsingProjectVariablePkeyUpdate>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectScenarioVariable` in the `ProjectVariableInput` mutation. */
export type ProjectScenarioVariableVariableIdFkeyInverseInput = {
  /** The primary key(s) for `projectScenarioVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectScenarioVariableProjectScenarioVariablePkeyConnect>>;
  /** The primary key(s) for `projectScenarioVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectScenarioVariableNodeIdConnect>>;
  /** A `ProjectScenarioVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectScenarioVariableVariableIdFkeyProjectScenarioVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectScenarioVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyUsingProjectScenarioVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectScenarioVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyNodeIdUpdate>>;
};

/** The `projectScenarioVariable` to be created by this mutation. */
export type ProjectScenarioVariableVariableIdFkeyProjectScenarioVariableCreateInput = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  /** Related Project Scenario ID */
  projectScenarioId?: InputMaybe<Scalars['BigInt']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
};

/** The `projectVariable` to be created by this mutation. */
export type ProjectScenarioVariableVariableIdFkeyProjectVariableCreateInput = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** A connection to a list of `ProjectScenarioVariable` values. */
export type ProjectScenarioVariablesConnection = {
  __typename?: 'ProjectScenarioVariablesConnection';
  /** A list of edges which contains the `ProjectScenarioVariable` and cursor to aid in pagination. */
  edges: Array<ProjectScenarioVariablesEdge>;
  /** A list of `ProjectScenarioVariable` objects. */
  nodes: Array<ProjectScenarioVariable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectScenarioVariable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectScenarioVariable` edge in the connection. */
export type ProjectScenarioVariablesEdge = {
  __typename?: 'ProjectScenarioVariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectScenarioVariable` at the end of the edge. */
  node: ProjectScenarioVariable;
};

/** Methods to use when ordering `ProjectScenarioVariable`. */
export enum ProjectScenarioVariablesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectScenarioIdAsc = 'PROJECT_SCENARIO_ID_ASC',
  ProjectScenarioIdDesc = 'PROJECT_SCENARIO_ID_DESC',
  VariableIdAsc = 'VARIABLE_ID_ASC',
  VariableIdDesc = 'VARIABLE_ID_DESC'
}

/** A connection to a list of `ProjectScenario` values. */
export type ProjectScenariosConnection = {
  __typename?: 'ProjectScenariosConnection';
  /** A list of edges which contains the `ProjectScenario` and cursor to aid in pagination. */
  edges: Array<ProjectScenariosEdge>;
  /** A list of `ProjectScenario` objects. */
  nodes: Array<ProjectScenario>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectScenario` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectScenario` edge in the connection. */
export type ProjectScenariosEdge = {
  __typename?: 'ProjectScenariosEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectScenario` at the end of the edge. */
  node: ProjectScenario;
};

/** Methods to use when ordering `ProjectScenario`. */
export enum ProjectScenariosOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** Project Scores */
export type ProjectScore = Node & {
  __typename?: 'ProjectScore';
  /** When this score item was completed. */
  completedAt: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  /** Score ID */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectScore`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  recordId: Maybe<Scalars['String']>;
  /** Score from 0.00 to 100.00 */
  score: Scalars['BigFloat'];
  /** Related project table */
  scoreKey: ProjectScoreKey;
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectScore` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectScoreCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `scoreKey` field. */
  scoreKey?: InputMaybe<ProjectScoreKey>;
};

/** A filter to be used against `ProjectScore` object types. All fields are combined with a logical ‘and.’ */
export type ProjectScoreFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectScoreFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectScoreFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectScoreFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `scoreKey` field. */
  scoreKey?: InputMaybe<ProjectScoreKeyFilter>;
};

export enum ProjectScoreKey {
  AlignSurvey = 'ALIGN_SURVEY',
  Compliance = 'COMPLIANCE',
  Event = 'EVENT',
  ExtMessage = 'EXT_MESSAGE',
  ExtPerson = 'EXT_PERSON',
  IntMessage = 'INT_MESSAGE',
  IntPerson = 'INT_PERSON',
  KeyMessage = 'KEY_MESSAGE',
  Objective = 'OBJECTIVE',
  Power = 'POWER',
  Question = 'QUESTION',
  Risk = 'RISK',
  ScenarioVariable = 'SCENARIO_VARIABLE',
  Variable = 'VARIABLE'
}

/** A filter to be used against ProjectScoreKey fields. All fields are combined with a logical ‘and.’ */
export type ProjectScoreKeyFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProjectScoreKey>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProjectScoreKey>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProjectScoreKey>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProjectScoreKey>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProjectScoreKey>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProjectScoreKey>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProjectScoreKey>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProjectScoreKey>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProjectScoreKey>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProjectScoreKey>>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectScoreNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectScore` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectScore` to look up the row to update. */
export type ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyUsingProjectScorePkeyUpdate = {
  /** Score ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectScore` being updated. */
  patch: UpdateProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyPatch;
};

/** The fields on `projectScore` to look up the row to update. */
export type ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyUsingUniqProjectScoreKeyUpdate = {
  /** An object where the defined keys will be set on the `projectScore` being updated. */
  patch: UpdateProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  recordId: Scalars['String'];
  /** Related project table */
  scoreKey: ProjectScoreKey;
};

/** Represents an update to a `ProjectScore`. Fields that are set will be updated. */
export type ProjectScorePatch = {
  /** When this score item was completed. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Score ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectScoreProjectIdFkeyInput>;
  recordId?: InputMaybe<Scalars['String']>;
  /** Score from 0.00 to 100.00 */
  score?: InputMaybe<Scalars['BigFloat']>;
  /** Related project table */
  scoreKey?: InputMaybe<ProjectScoreKey>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectScoreInput` mutation. */
export type ProjectScoreProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectScoreProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectScoreForProjectScoreProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectScore` in the `ProjectInput` mutation. */
export type ProjectScoreProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectScore` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectScoreProjectScorePkeyConnect>>;
  /** The primary key(s) for `projectScore` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectScoreNodeIdConnect>>;
  /** The primary key(s) for `projectScore` for the far side of the relationship. */
  connectByScoreKeyAndProjectIdAndRecordId?: InputMaybe<Array<ProjectScoreUniqProjectScoreKeyConnect>>;
  /** The primary key(s) and patch data for `projectScore` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyUsingProjectScorePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectScore` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectScoreForProjectScoreProjectIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `projectScore` for the far side of the relationship. */
  updateByScoreKeyAndProjectIdAndRecordId?: InputMaybe<Array<ProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyUsingUniqProjectScoreKeyUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectScoreProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectScore` to look up the row to connect. */
export type ProjectScoreProjectScorePkeyConnect = {
  /** Score ID */
  id: Scalars['UUID'];
};

/** A connection to a list of `ProjectScoreSummary` values. */
export type ProjectScoreSummariesConnection = {
  __typename?: 'ProjectScoreSummariesConnection';
  /** A list of edges which contains the `ProjectScoreSummary` and cursor to aid in pagination. */
  edges: Array<ProjectScoreSummariesEdge>;
  /** A list of `ProjectScoreSummary` objects. */
  nodes: Array<ProjectScoreSummary>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectScoreSummary` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectScoreSummary` edge in the connection. */
export type ProjectScoreSummariesEdge = {
  __typename?: 'ProjectScoreSummariesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectScoreSummary` at the end of the edge. */
  node: ProjectScoreSummary;
};

export type ProjectScoreSummary = {
  __typename?: 'ProjectScoreSummary';
  avgScore: Maybe<Scalars['BigFloat']>;
  lastCompleted: Maybe<Scalars['Datetime']>;
  negotiationType: Maybe<NegotiationType>;
  scoreKey: Maybe<ProjectScoreKey>;
  totalRecords: Maybe<Scalars['Int']>;
  totalScore: Maybe<Scalars['BigFloat']>;
};

/** The fields on `projectScore` to look up the row to connect. */
export type ProjectScoreUniqProjectScoreKeyConnect = {
  /** Related Project ID */
  projectId: Scalars['UUID'];
  recordId: Scalars['String'];
  /** Related project table */
  scoreKey: ProjectScoreKey;
};

/** A connection to a list of `ProjectScore` values. */
export type ProjectScoresConnection = {
  __typename?: 'ProjectScoresConnection';
  /** A list of edges which contains the `ProjectScore` and cursor to aid in pagination. */
  edges: Array<ProjectScoresEdge>;
  /** A list of `ProjectScore` objects. */
  nodes: Array<ProjectScore>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectScore` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectScore` edge in the connection. */
export type ProjectScoresEdge = {
  __typename?: 'ProjectScoresEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectScore` at the end of the edge. */
  node: ProjectScore;
};

/** Methods to use when ordering `ProjectScore`. */
export enum ProjectScoresOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  ScoreKeyAsc = 'SCORE_KEY_ASC',
  ScoreKeyDesc = 'SCORE_KEY_DESC'
}

/** Project full text search */
export type ProjectSearch = Node & {
  __typename?: 'ProjectSearch';
  /** Extended tsvector for all text fields in a project */
  fullTsv: Maybe<Scalars['FullText']>;
  /** Full-text search ranking when filtered by `fullTsv`. */
  fullTsvRank: Maybe<Scalars['Float']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectSearch`. */
  project: Maybe<Project>;
  /** Project ID */
  projectId: Scalars['UUID'];
};

/** A filter to be used against `ProjectSearch` object types. All fields are combined with a logical ‘and.’ */
export type ProjectSearchFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectSearchFilter>>;
  /** Filter by the object’s `fullTsv` field. */
  fullTsv?: InputMaybe<FullTextFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectSearchFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectSearchFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectSearchNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectSearch` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectSearch` to look up the row to update. */
export type ProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyUsingProjectSearchPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectSearch` being updated. */
  patch: UpdateProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectSearch`. Fields that are set will be updated. */
export type ProjectSearchPatch = {
  /** Extended tsvector for all text fields in a project */
  fullTsv?: InputMaybe<Scalars['FullText']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectSearchProjectIdFkeyInput>;
};

/** Input for the nested mutation of `project` in the `ProjectSearchInput` mutation. */
export type ProjectSearchProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectSearchProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectSearchForProjectSearchProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectSearch` in the `ProjectInput` mutation. */
export type ProjectSearchProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectSearch` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectSearchNodeIdConnect>;
  /** The primary key(s) for `projectSearch` for the far side of the relationship. */
  connectByProjectId?: InputMaybe<ProjectSearchProjectSearchPkeyConnect>;
  /** The primary key(s) and patch data for `projectSearch` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectSearchForProjectSearchProjectIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectSearch` for the far side of the relationship. */
  updateByProjectId?: InputMaybe<ProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyUsingProjectSearchPkeyUpdate>;
};

/** The `project` to be created by this mutation. */
export type ProjectSearchProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectSearch` to look up the row to connect. */
export type ProjectSearchProjectSearchPkeyConnect = {
  /** Project ID */
  projectId: Scalars['UUID'];
};

export enum ProjectSection {
  Align = 'ALIGN',
  Communications = 'COMMUNICATIONS',
  Dashboard = 'DASHBOARD',
  Negotiate = 'NEGOTIATE',
  Objectives = 'OBJECTIVES',
  Power = 'POWER',
  Questions = 'QUESTIONS',
  Risk = 'RISK',
  Time = 'TIME',
  Variables = 'VARIABLES'
}

/** All input for the `projectSetIsArchived` mutation. */
export type ProjectSetIsArchivedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  isArchivedForMe: Scalars['Boolean'];
  isDeletedForAll: Scalars['Boolean'];
  projectId: Scalars['UUID'];
};

/** The output of our `projectSetIsArchived` mutation. */
export type ProjectSetIsArchivedPayload = {
  __typename?: 'ProjectSetIsArchivedPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

export type ProjectSubscriptionPayload = {
  __typename?: 'ProjectSubscriptionPayload';
  event: Maybe<Scalars['String']>;
  project: Maybe<Project>;
};

/** Project Questions (survey) */
export type ProjectSurvey = Node & {
  __typename?: 'ProjectSurvey';
  /** JSON with all the answers from the initial, survey and pending states */
  answers: Maybe<Scalars['JSON']>;
  /** When was this project survey created? */
  createdAt: Scalars['Datetime'];
  /** Type of negotiaton, based on survey answers */
  negotiationType: Maybe<NegotiationType>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectSurvey`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** JSON array with scores calculated based on the answers, e.g. [3, 4] */
  score: Maybe<Scalars['JSON']>;
  /** Possible values: initial, survey, pending and completed */
  state: Scalars['String'];
  /** When was this project survey last updated? */
  updatedAt: Scalars['Datetime'];
};

/**
 * A condition to be used against `ProjectSurvey` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectSurveyCondition = {
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectSurvey` object types. All fields are combined with a logical ‘and.’ */
export type ProjectSurveyFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectSurveyFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectSurveyFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectSurveyFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectSurveyNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectSurvey` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectSurvey` to look up the row to update. */
export type ProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyUsingProjectSurveyPkeyUpdate = {
  /** An object where the defined keys will be set on the `projectSurvey` being updated. */
  patch: UpdateProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** Represents an update to a `ProjectSurvey`. Fields that are set will be updated. */
export type ProjectSurveyPatch = {
  /** JSON with all the answers from the initial, survey and pending states */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project survey created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Type of negotiaton, based on survey answers */
  negotiationType?: InputMaybe<NegotiationType>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectSurveyProjectIdFkeyInput>;
  /** JSON array with scores calculated based on the answers, e.g. [3, 4] */
  score?: InputMaybe<Scalars['JSON']>;
  /** Possible values: initial, survey, pending and completed */
  state?: InputMaybe<Scalars['String']>;
  /** When was this project survey last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** Input for the nested mutation of `project` in the `ProjectSurveyInput` mutation. */
export type ProjectSurveyProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectSurveyProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectSurveyForProjectSurveyProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectSurvey` in the `ProjectInput` mutation. */
export type ProjectSurveyProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectSurvey` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectSurveyNodeIdConnect>;
  /** The primary key(s) for `projectSurvey` for the far side of the relationship. */
  connectByProjectId?: InputMaybe<ProjectSurveyProjectSurveyPkeyConnect>;
  /** The primary key(s) and patch data for `projectSurvey` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectOnProjectSurveyForProjectSurveyProjectIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `projectSurvey` for the far side of the relationship. */
  updateByProjectId?: InputMaybe<ProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyUsingProjectSurveyPkeyUpdate>;
};

/** The `project` to be created by this mutation. */
export type ProjectSurveyProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The fields on `projectSurvey` to look up the row to connect. */
export type ProjectSurveyProjectSurveyPkeyConnect = {
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** A connection to a list of `ProjectSurvey` values. */
export type ProjectSurveysConnection = {
  __typename?: 'ProjectSurveysConnection';
  /** A list of edges which contains the `ProjectSurvey` and cursor to aid in pagination. */
  edges: Array<ProjectSurveysEdge>;
  /** A list of `ProjectSurvey` objects. */
  nodes: Array<ProjectSurvey>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectSurvey` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectSurvey` edge in the connection. */
export type ProjectSurveysEdge = {
  __typename?: 'ProjectSurveysEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectSurvey` at the end of the edge. */
  node: ProjectSurvey;
};

/** Methods to use when ordering `ProjectSurvey`. */
export enum ProjectSurveysOrderBy {
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC'
}

/** A filter to be used against many `OrganizationCoachingTimeLog` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyOrganizationCoachingTimeLogFilter = {
  /** Every related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** No related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** Some related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationCoachingTimeLogFilter>;
};

/** A filter to be used against many `ProjectAccessLog` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectAccessLogFilter = {
  /** Every related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectAccessLogFilter>;
  /** No related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectAccessLogFilter>;
  /** Some related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectAccessLogFilter>;
};

/** A filter to be used against many `ProjectComment` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectCommentFilter = {
  /** Every related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommentFilter>;
  /** No related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommentFilter>;
  /** Some related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommentFilter>;
};

/** A filter to be used against many `ProjectCommunicationMessage` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectCommunicationMessageFilter = {
  /** Every related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommunicationMessageFilter>;
  /** No related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommunicationMessageFilter>;
  /** Some related `ProjectCommunicationMessage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommunicationMessageFilter>;
};

/** A filter to be used against many `ProjectCommunicationQuestion` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectCommunicationQuestionFilter = {
  /** Every related `ProjectCommunicationQuestion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommunicationQuestionFilter>;
  /** No related `ProjectCommunicationQuestion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommunicationQuestionFilter>;
  /** Some related `ProjectCommunicationQuestion` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommunicationQuestionFilter>;
};

/** A filter to be used against many `ProjectCompliance` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectComplianceFilter = {
  /** Every related `ProjectCompliance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectComplianceFilter>;
  /** No related `ProjectCompliance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectComplianceFilter>;
  /** Some related `ProjectCompliance` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectComplianceFilter>;
};

/** A filter to be used against many `ProjectConnection` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectConnectionFilter = {
  /** Every related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectConnectionFilter>;
  /** No related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectConnectionFilter>;
  /** Some related `ProjectConnection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectConnectionFilter>;
};

/** A filter to be used against many `ProjectEvent` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectEventFilter = {
  /** Every related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectEventFilter>;
  /** No related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectEventFilter>;
  /** Some related `ProjectEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectEventFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectFolder` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectFolderFilter = {
  /** Every related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFolderFilter>;
  /** No related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFolderFilter>;
  /** Some related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFolderFilter>;
};

/** A filter to be used against many `ProjectInvitation` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectInvitationFilter = {
  /** Every related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectInvitationFilter>;
  /** No related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectInvitationFilter>;
  /** Some related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectInvitationFilter>;
};

/** A filter to be used against many `ProjectMembership` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectMembershipFilter = {
  /** Every related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectMembershipFilter>;
  /** No related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectMembershipFilter>;
  /** Some related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectMembershipFilter>;
};

/** A filter to be used against many `ProjectObjective` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectObjectiveFilter = {
  /** Every related `ProjectObjective` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectObjectiveFilter>;
  /** No related `ProjectObjective` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectObjectiveFilter>;
  /** Some related `ProjectObjective` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectObjectiveFilter>;
};

/** A filter to be used against many `ProjectOffer` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectOfferFilter = {
  /** Every related `ProjectOffer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectOfferFilter>;
  /** No related `ProjectOffer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectOfferFilter>;
  /** Some related `ProjectOffer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectOfferFilter>;
};

/** A filter to be used against many `ProjectRisk` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectRiskFilter = {
  /** Every related `ProjectRisk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectRiskFilter>;
  /** No related `ProjectRisk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectRiskFilter>;
  /** Some related `ProjectRisk` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectRiskFilter>;
};

/** A filter to be used against many `ProjectScenario` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectScenarioFilter = {
  /** Every related `ProjectScenario` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectScenarioFilter>;
  /** No related `ProjectScenario` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectScenarioFilter>;
  /** Some related `ProjectScenario` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectScenarioFilter>;
};

/** A filter to be used against many `ProjectScore` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectScoreFilter = {
  /** Every related `ProjectScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectScoreFilter>;
  /** No related `ProjectScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectScoreFilter>;
  /** Some related `ProjectScore` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectScoreFilter>;
};

/** A filter to be used against many `ProjectVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyProjectVariableFilter = {
  /** Every related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectVariableFilter>;
  /** No related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectVariableFilter>;
  /** Some related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectVariableFilter>;
};

/** A filter to be used against many `VariableClass` object types. All fields are combined with a logical ‘and.’ */
export type ProjectToManyVariableClassFilter = {
  /** Every related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VariableClassFilter>;
  /** No related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VariableClassFilter>;
  /** Some related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VariableClassFilter>;
};

/** Project Variables */
export type ProjectVariable = Node & {
  __typename?: 'ProjectVariable';
  /** What is the expected (predicted) value? */
  anticipated: Maybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt: Scalars['Datetime'];
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor: Maybe<Scalars['String']>;
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** Is this project variable deleted? */
  isDeleted: Scalars['Boolean'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `Project` that is related to this `ProjectVariable`. */
  project: Maybe<Project>;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ProjectOfferVariable`. */
  projectOfferVariablesByVariableId: ProjectOfferVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectOfferVariable`. */
  projectOfferVariablesByVariableIdList: Array<ProjectOfferVariable>;
  /** Reads and enables pagination through a set of `ProjectScenarioVariable`. */
  projectScenarioVariablesByVariableId: ProjectScenarioVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectScenarioVariable`. */
  projectScenarioVariablesByVariableIdList: Array<ProjectScenarioVariable>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank: Scalars['Int'];
  /** When is their value for the variable? */
  their: Maybe<VariableState>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type: VariableType;
  /** When was this project variable last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `VariableClass` that is related to this `ProjectVariable`. */
  variableClass: Maybe<VariableClass>;
  /** What is the variable class? */
  variableClassId: Scalars['UUID'];
  /** What is your value for the variable? */
  your: Maybe<VariableState>;
};


/** Project Variables */
export type ProjectVariableProjectOfferVariablesByVariableIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};


/** Project Variables */
export type ProjectVariableProjectOfferVariablesByVariableIdListArgs = {
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};


/** Project Variables */
export type ProjectVariableProjectScenarioVariablesByVariableIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};


/** Project Variables */
export type ProjectVariableProjectScenarioVariablesByVariableIdListArgs = {
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};

/**
 * A condition to be used against `ProjectVariable` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProjectVariableCondition = {
  /** Checks for equality with the object’s `descriptor` field. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `projectId` field. */
  projectId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `rank` field. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `variableClassId` field. */
  variableClassId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `ProjectVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectVariableFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectVariableFilter>>;
  /** Filter by the object’s `currentProjectOfferVariablesByVariableId` relation. */
  currentProjectOfferVariablesByVariableId?: InputMaybe<ProjectVariableToManyCurrentProjectOfferVariableFilter>;
  /** Some related `currentProjectOfferVariablesByVariableId` exist. */
  currentProjectOfferVariablesByVariableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `descriptor` field. */
  descriptor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectVariableFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectVariableFilter>>;
  /** Filter by the object’s `project` relation. */
  project?: InputMaybe<ProjectFilter>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `projectOfferVariablesByVariableId` relation. */
  projectOfferVariablesByVariableId?: InputMaybe<ProjectVariableToManyProjectOfferVariableFilter>;
  /** Some related `projectOfferVariablesByVariableId` exist. */
  projectOfferVariablesByVariableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectScenarioVariablesByVariableId` relation. */
  projectScenarioVariablesByVariableId?: InputMaybe<ProjectVariableToManyProjectScenarioVariableFilter>;
  /** Some related `projectScenarioVariablesByVariableId` exist. */
  projectScenarioVariablesByVariableIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `rank` field. */
  rank?: InputMaybe<IntFilter>;
  /** Filter by the object’s `variableClass` relation. */
  variableClass?: InputMaybe<VariableClassFilter>;
  /** Filter by the object’s `variableClassId` field. */
  variableClassId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `ProjectVariable` */
export type ProjectVariableInput = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProjectVariableNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `projectVariable` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectOfferVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
  patch: ProjectOfferVariablePatch;
};

/** The fields on `projectVariable` to look up the row to update. */
export type ProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyUsingProjectVariablePkeyUpdate = {
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: UpdateProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectScenarioVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
  patch: ProjectScenarioVariablePatch;
};

/** The fields on `projectVariable` to look up the row to update. */
export type ProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyUsingProjectVariablePkeyUpdate = {
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: UpdateProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `projectVariable` to look up the row to update. */
export type ProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyUsingProjectVariablePkeyUpdate = {
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: UpdateProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type ProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `variableClass` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: VariableClassPatch;
};

/** The fields on `projectVariable` to look up the row to update. */
export type ProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyUsingProjectVariablePkeyUpdate = {
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: UpdateProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyPatch;
};

/** Represents an update to a `ProjectVariable`. Fields that are set will be updated. */
export type ProjectVariablePatch = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** Input for the nested mutation of `project` in the `ProjectVariableInput` mutation. */
export type ProjectVariableProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectVariableProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnProjectVariableForProjectVariableProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectVariable` in the `ProjectInput` mutation. */
export type ProjectVariableProjectIdFkeyInverseInput = {
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectVariableProjectVariablePkeyConnect>>;
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectVariableNodeIdConnect>>;
  /** A `ProjectVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectVariableProjectIdFkeyProjectVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyUsingProjectVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnProjectVariableForProjectVariableProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type ProjectVariableProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `projectVariable` to be created by this mutation. */
export type ProjectVariableProjectIdFkeyProjectVariableCreateInput = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** The fields on `projectVariable` to look up the row to connect. */
export type ProjectVariableProjectVariablePkeyConnect = {
  /** Project Variable ID */
  id: Scalars['UUID'];
};

/** An input for mutations affecting `ProjectVariableRankUpdate` */
export type ProjectVariableRankUpdateInput = {
  id?: InputMaybe<Scalars['UUID']>;
  rank?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against many `CurrentProjectOfferVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectVariableToManyCurrentProjectOfferVariableFilter = {
  /** Every related `CurrentProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<CurrentProjectOfferVariableFilter>;
  /** No related `CurrentProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<CurrentProjectOfferVariableFilter>;
  /** Some related `CurrentProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<CurrentProjectOfferVariableFilter>;
};

/** A filter to be used against many `ProjectOfferVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectVariableToManyProjectOfferVariableFilter = {
  /** Every related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectOfferVariableFilter>;
  /** No related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectOfferVariableFilter>;
  /** Some related `ProjectOfferVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectOfferVariableFilter>;
};

/** A filter to be used against many `ProjectScenarioVariable` object types. All fields are combined with a logical ‘and.’ */
export type ProjectVariableToManyProjectScenarioVariableFilter = {
  /** Every related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectScenarioVariableFilter>;
  /** No related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectScenarioVariableFilter>;
  /** Some related `ProjectScenarioVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectScenarioVariableFilter>;
};

/** Input for the nested mutation of `variableClass` in the `ProjectVariableInput` mutation. */
export type ProjectVariableVariableClassIdFkeyInput = {
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectById?: InputMaybe<VariableClassVariablePkeyConnect>;
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<VariableClassNodeIdConnect>;
  /** A `VariableClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProjectVariableVariableClassIdFkeyVariableClassCreateInput>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateById?: InputMaybe<VariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyUsingVariablePkeyUpdate>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `projectVariable` in the `VariableClassInput` mutation. */
export type ProjectVariableVariableClassIdFkeyInverseInput = {
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ProjectVariableProjectVariablePkeyConnect>>;
  /** The primary key(s) for `projectVariable` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProjectVariableNodeIdConnect>>;
  /** A `ProjectVariableInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ProjectVariableVariableClassIdFkeyProjectVariableCreateInput>>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateById?: InputMaybe<Array<ProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyUsingProjectVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `projectVariable` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<VariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyNodeIdUpdate>>;
};

/** The `projectVariable` to be created by this mutation. */
export type ProjectVariableVariableClassIdFkeyProjectVariableCreateInput = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** The `variableClass` to be created by this mutation. */
export type ProjectVariableVariableClassIdFkeyVariableClassCreateInput = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name: Scalars['String'];
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** A connection to a list of `ProjectVariable` values. */
export type ProjectVariablesConnection = {
  __typename?: 'ProjectVariablesConnection';
  /** A list of edges which contains the `ProjectVariable` and cursor to aid in pagination. */
  edges: Array<ProjectVariablesEdge>;
  /** A list of `ProjectVariable` objects. */
  nodes: Array<ProjectVariable>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProjectVariable` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectVariable` edge in the connection. */
export type ProjectVariablesEdge = {
  __typename?: 'ProjectVariablesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectVariable` at the end of the edge. */
  node: ProjectVariable;
};

/** Methods to use when ordering `ProjectVariable`. */
export enum ProjectVariablesOrderBy {
  DescriptorAsc = 'DESCRIPTOR_ASC',
  DescriptorDesc = 'DESCRIPTOR_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  ProjectIdAsc = 'PROJECT_ID_ASC',
  ProjectIdDesc = 'PROJECT_ID_DESC',
  RankAsc = 'RANK_ASC',
  RankDesc = 'RANK_DESC',
  VariableClassIdAsc = 'VARIABLE_CLASS_ID_ASC',
  VariableClassIdDesc = 'VARIABLE_CLASS_ID_DESC'
}

/** A connection to a list of `Project` values. */
export type ProjectsConnection = {
  __typename?: 'ProjectsConnection';
  /** A list of edges which contains the `Project` and cursor to aid in pagination. */
  edges: Array<ProjectsEdge>;
  /** A list of `Project` objects. */
  nodes: Array<Project>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Project` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Project` edge in the connection. */
export type ProjectsEdge = {
  __typename?: 'ProjectsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Project` at the end of the edge. */
  node: Project;
};

/** Methods to use when ordering `Project`. */
export enum ProjectsOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  FileTypeAsc = 'FILE_TYPE_ASC',
  FileTypeDesc = 'FILE_TYPE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsNegotiationClosedAsc = 'IS_NEGOTIATION_CLOSED_ASC',
  IsNegotiationClosedDesc = 'IS_NEGOTIATION_CLOSED_DESC',
  IsNegotiationOpenAsc = 'IS_NEGOTIATION_OPEN_ASC',
  IsNegotiationOpenDesc = 'IS_NEGOTIATION_OPEN_DESC',
  IsOpenAsc = 'IS_OPEN_ASC',
  IsOpenDesc = 'IS_OPEN_DESC',
  LastModifyAsc = 'LAST_MODIFY_ASC',
  LastModifyByMeAsc = 'LAST_MODIFY_BY_ME_ASC',
  LastModifyByMeDesc = 'LAST_MODIFY_BY_ME_DESC',
  LastModifyDesc = 'LAST_MODIFY_DESC',
  LastOpenByMeAsc = 'LAST_OPEN_BY_ME_ASC',
  LastOpenByMeDesc = 'LAST_OPEN_BY_ME_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  ParentIdAsc = 'PARENT_ID_ASC',
  ParentIdDesc = 'PARENT_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleTsvAsc = 'TITLE_TSV_ASC',
  TitleTsvDesc = 'TITLE_TSV_DESC',
  TitleTsvRankAsc = 'TITLE_TSV_RANK_ASC',
  TitleTsvRankDesc = 'TITLE_TSV_RANK_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ValueAsc = 'VALUE_ASC',
  ValueDesc = 'VALUE_DESC'
}

/** A `ProjectsStatsRecord` edge in the connection. */
export type ProjectsStatEdge = {
  __typename?: 'ProjectsStatEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectsStatsRecord` at the end of the edge. */
  node: ProjectsStatsRecord;
};

/** A connection to a list of `ProjectsStatsRecord` values. */
export type ProjectsStatsConnection = {
  __typename?: 'ProjectsStatsConnection';
  /** A list of edges which contains the `ProjectsStatsRecord` and cursor to aid in pagination. */
  edges: Array<ProjectsStatEdge>;
  /** A list of `ProjectsStatsRecord` objects. */
  nodes: Array<ProjectsStatsRecord>;
  /** The count of *all* `ProjectsStatsRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A connection to a list of `ProjectsStatsGlobalAveragePowerRecord` values. */
export type ProjectsStatsGlobalAveragePowerConnection = {
  __typename?: 'ProjectsStatsGlobalAveragePowerConnection';
  /** A list of edges which contains the `ProjectsStatsGlobalAveragePowerRecord` and cursor to aid in pagination. */
  edges: Array<ProjectsStatsGlobalAveragePowerEdge>;
  /** A list of `ProjectsStatsGlobalAveragePowerRecord` objects. */
  nodes: Array<ProjectsStatsGlobalAveragePowerRecord>;
  /** The count of *all* `ProjectsStatsGlobalAveragePowerRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectsStatsGlobalAveragePowerRecord` edge in the connection. */
export type ProjectsStatsGlobalAveragePowerEdge = {
  __typename?: 'ProjectsStatsGlobalAveragePowerEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectsStatsGlobalAveragePowerRecord` at the end of the edge. */
  node: ProjectsStatsGlobalAveragePowerRecord;
};

/** The return type of our `projectsStatsGlobalAveragePower` query. */
export type ProjectsStatsGlobalAveragePowerRecord = {
  __typename?: 'ProjectsStatsGlobalAveragePowerRecord';
  macro: Maybe<Scalars['BigFloat']>;
  micro: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `ProjectsStatsGlobalAveragePowerRecord` object types. All fields are combined with a logical ‘and.’ */
export type ProjectsStatsGlobalAveragePowerRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectsStatsGlobalAveragePowerRecordFilter>>;
  /** Filter by the object’s `macro` field. */
  macro?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `micro` field. */
  micro?: InputMaybe<BigFloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectsStatsGlobalAveragePowerRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectsStatsGlobalAveragePowerRecordFilter>>;
};

/** A connection to a list of `ProjectsStatsNegotiationTypeRecord` values. */
export type ProjectsStatsNegotiationTypeConnection = {
  __typename?: 'ProjectsStatsNegotiationTypeConnection';
  /** A list of edges which contains the `ProjectsStatsNegotiationTypeRecord` and cursor to aid in pagination. */
  edges: Array<ProjectsStatsNegotiationTypeEdge>;
  /** A list of `ProjectsStatsNegotiationTypeRecord` objects. */
  nodes: Array<ProjectsStatsNegotiationTypeRecord>;
  /** The count of *all* `ProjectsStatsNegotiationTypeRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectsStatsNegotiationTypeRecord` edge in the connection. */
export type ProjectsStatsNegotiationTypeEdge = {
  __typename?: 'ProjectsStatsNegotiationTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectsStatsNegotiationTypeRecord` at the end of the edge. */
  node: ProjectsStatsNegotiationTypeRecord;
};

/** The return type of our `projectsStatsNegotiationType` query. */
export type ProjectsStatsNegotiationTypeRecord = {
  __typename?: 'ProjectsStatsNegotiationTypeRecord';
  negotiationType: Maybe<Scalars['String']>;
  projectId: Maybe<Scalars['UUID']>;
  x: Maybe<Scalars['Int']>;
  y: Maybe<Scalars['Int']>;
};

/** A filter to be used against `ProjectsStatsNegotiationTypeRecord` object types. All fields are combined with a logical ‘and.’ */
export type ProjectsStatsNegotiationTypeRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectsStatsNegotiationTypeRecordFilter>>;
  /** Filter by the object’s `negotiationType` field. */
  negotiationType?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectsStatsNegotiationTypeRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectsStatsNegotiationTypeRecordFilter>>;
  /** Filter by the object’s `projectId` field. */
  projectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `x` field. */
  x?: InputMaybe<IntFilter>;
  /** Filter by the object’s `y` field. */
  y?: InputMaybe<IntFilter>;
};

/** The return type of our `projectsStats` query. */
export type ProjectsStatsRecord = {
  __typename?: 'ProjectsStatsRecord';
  negotiationsClosed: Maybe<Scalars['BigInt']>;
  negotiationsOpen: Maybe<Scalars['BigInt']>;
  periodStart: Maybe<Scalars['Datetime']>;
  projectsCount: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against `ProjectsStatsRecord` object types. All fields are combined with a logical ‘and.’ */
export type ProjectsStatsRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectsStatsRecordFilter>>;
  /** Filter by the object’s `negotiationsClosed` field. */
  negotiationsClosed?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `negotiationsOpen` field. */
  negotiationsOpen?: InputMaybe<BigIntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectsStatsRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectsStatsRecordFilter>>;
  /** Filter by the object’s `periodStart` field. */
  periodStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `projectsCount` field. */
  projectsCount?: InputMaybe<BigIntFilter>;
};

/** A connection to a list of `ProjectsStatsValueAddedRecord` values. */
export type ProjectsStatsValueAddedConnection = {
  __typename?: 'ProjectsStatsValueAddedConnection';
  /** A list of edges which contains the `ProjectsStatsValueAddedRecord` and cursor to aid in pagination. */
  edges: Array<ProjectsStatsValueAddedEdge>;
  /** A list of `ProjectsStatsValueAddedRecord` objects. */
  nodes: Array<ProjectsStatsValueAddedRecord>;
  /** The count of *all* `ProjectsStatsValueAddedRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ProjectsStatsValueAddedRecord` edge in the connection. */
export type ProjectsStatsValueAddedEdge = {
  __typename?: 'ProjectsStatsValueAddedEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `ProjectsStatsValueAddedRecord` at the end of the edge. */
  node: ProjectsStatsValueAddedRecord;
};

/** The return type of our `projectsStatsValueAdded` query. */
export type ProjectsStatsValueAddedRecord = {
  __typename?: 'ProjectsStatsValueAddedRecord';
  periodStart: Maybe<Scalars['Datetime']>;
  totalValue: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `ProjectsStatsValueAddedRecord` object types. All fields are combined with a logical ‘and.’ */
export type ProjectsStatsValueAddedRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProjectsStatsValueAddedRecordFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<ProjectsStatsValueAddedRecordFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProjectsStatsValueAddedRecordFilter>>;
  /** Filter by the object’s `periodStart` field. */
  periodStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `totalValue` field. */
  totalValue?: InputMaybe<BigFloatFilter>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  account: Maybe<Account>;
  accountByName: Maybe<Account>;
  /** Reads a single `Account` using its globally unique `ID`. */
  accountByNodeId: Maybe<Account>;
  /** Reads and enables pagination through a set of `Account`. */
  accounts: Maybe<AccountsConnection>;
  /** Reads a set of `Account`. */
  accountsList: Maybe<Array<Account>>;
  /** Retrieve a list of active (with accounts related to them) country/region entities */
  activeCountryRegion: Maybe<ActiveCountryRegionConnection>;
  /** Retrieve a list of active (with accounts related to them) country/region entities */
  activeCountryRegionList: Maybe<Array<Maybe<ActiveCountryRegionRecord>>>;
  /** Retrieve a list of active (with accounts related to them) industries */
  activeIndustries: Maybe<ActiveIndustriesConnection>;
  /** Retrieve a list of active (with accounts related to them) industries */
  activeIndustriesList: Maybe<Array<Maybe<ActiveIndustriesRecord>>>;
  /** Reads and enables pagination through a set of `Activity`. */
  activities: Maybe<ActivitiesConnection>;
  /** Reads a set of `Activity`. */
  activitiesList: Maybe<Array<Activity>>;
  activity: Maybe<Activity>;
  /** Reads a single `Activity` using its globally unique `ID`. */
  activityByNodeId: Maybe<Activity>;
  /** Reads and enables pagination through a set of `ActivityLevel`. */
  activityLevels: Maybe<ActivityLevelsConnection>;
  /** Reads a set of `ActivityLevel`. */
  activityLevelsList: Maybe<Array<ActivityLevel>>;
  /** Retrieve an organization-specific message for users not added to our system during SSO login. */
  adminContactMessage: Maybe<Scalars['String']>;
  brief: Maybe<Brief>;
  /** Reads a single `Brief` using its globally unique `ID`. */
  briefByNodeId: Maybe<Brief>;
  briefGroup: Maybe<BriefGroup>;
  /** Reads a single `BriefGroup` using its globally unique `ID`. */
  briefGroupByNodeId: Maybe<BriefGroup>;
  briefGroupTypeMapping: Maybe<BriefGroupTypeMapping>;
  /** Reads a single `BriefGroupTypeMapping` using its globally unique `ID`. */
  briefGroupTypeMappingByNodeId: Maybe<BriefGroupTypeMapping>;
  /** Reads and enables pagination through a set of `BriefGroupTypeMapping`. */
  briefGroupTypeMappings: Maybe<BriefGroupTypeMappingsConnection>;
  /** Reads a set of `BriefGroupTypeMapping`. */
  briefGroupTypeMappingsList: Maybe<Array<BriefGroupTypeMapping>>;
  briefGroupVariable: Maybe<BriefGroupVariable>;
  /** Reads a single `BriefGroupVariable` using its globally unique `ID`. */
  briefGroupVariableByNodeId: Maybe<BriefGroupVariable>;
  briefGroupVariableOption: Maybe<BriefGroupVariableOption>;
  /** Reads a single `BriefGroupVariableOption` using its globally unique `ID`. */
  briefGroupVariableOptionByNodeId: Maybe<BriefGroupVariableOption>;
  /** Reads and enables pagination through a set of `BriefGroupVariableOption`. */
  briefGroupVariableOptions: Maybe<BriefGroupVariableOptionsConnection>;
  /** Reads a set of `BriefGroupVariableOption`. */
  briefGroupVariableOptionsList: Maybe<Array<BriefGroupVariableOption>>;
  /** Reads and enables pagination through a set of `BriefGroupVariable`. */
  briefGroupVariables: Maybe<BriefGroupVariablesConnection>;
  /** Reads a set of `BriefGroupVariable`. */
  briefGroupVariablesList: Maybe<Array<BriefGroupVariable>>;
  /** Reads and enables pagination through a set of `BriefGroup`. */
  briefGroups: Maybe<BriefGroupsConnection>;
  /** Reads a set of `BriefGroup`. */
  briefGroupsList: Maybe<Array<BriefGroup>>;
  briefPreset: Maybe<BriefPreset>;
  /** Reads a single `BriefPreset` using its globally unique `ID`. */
  briefPresetByNodeId: Maybe<BriefPreset>;
  /** Reads and enables pagination through a set of `BriefPreset`. */
  briefPresets: Maybe<BriefPresetsConnection>;
  /** Reads a set of `BriefPreset`. */
  briefPresetsList: Maybe<Array<BriefPreset>>;
  /** Reads and enables pagination through a set of `Brief`. */
  briefs: Maybe<BriefsConnection>;
  /** Reads a set of `Brief`. */
  briefsList: Maybe<Array<Brief>>;
  /** Calculate statistics based on input parameters */
  calculateStatistics: Maybe<Scalars['JSON']>;
  class: Maybe<Class>;
  /** Reads and enables pagination through a set of `ClassAssetCategory`. */
  classAssetCategories: Maybe<ClassAssetCategoriesConnection>;
  /** Reads a set of `ClassAssetCategory`. */
  classAssetCategoriesList: Maybe<Array<ClassAssetCategory>>;
  classAssetCategory: Maybe<ClassAssetCategory>;
  /** Reads a single `ClassAssetCategory` using its globally unique `ID`. */
  classAssetCategoryByNodeId: Maybe<ClassAssetCategory>;
  /** Reads a single `Class` using its globally unique `ID`. */
  classByNodeId: Maybe<Class>;
  classDocument: Maybe<ClassDocument>;
  classDocumentByClassIdAndDocumentId: Maybe<ClassDocument>;
  /** Reads a single `ClassDocument` using its globally unique `ID`. */
  classDocumentByNodeId: Maybe<ClassDocument>;
  /** Reads and enables pagination through a set of `ClassDocument`. */
  classDocuments: Maybe<ClassDocumentsConnection>;
  /** Reads a set of `ClassDocument`. */
  classDocumentsList: Maybe<Array<ClassDocument>>;
  classStar: Maybe<ClassStar>;
  classStarByClassIdAndUserId: Maybe<ClassStar>;
  /** Reads a single `ClassStar` using its globally unique `ID`. */
  classStarByNodeId: Maybe<ClassStar>;
  /** Reads and enables pagination through a set of `ClassStar`. */
  classStars: Maybe<ClassStarsConnection>;
  /** Reads a set of `ClassStar`. */
  classStarsList: Maybe<Array<ClassStar>>;
  classUser: Maybe<ClassUser>;
  classUserByClassIdAndUserId: Maybe<ClassUser>;
  /** Reads a single `ClassUser` using its globally unique `ID`. */
  classUserByNodeId: Maybe<ClassUser>;
  /** Reads and enables pagination through a set of `ClassUser`. */
  classUsers: Maybe<ClassUsersConnection>;
  /** Reads a set of `ClassUser`. */
  classUsersList: Maybe<Array<ClassUser>>;
  classVideo: Maybe<ClassVideo>;
  classVideoByClassIdAndVideoId: Maybe<ClassVideo>;
  /** Reads a single `ClassVideo` using its globally unique `ID`. */
  classVideoByNodeId: Maybe<ClassVideo>;
  classVideoUser: Maybe<ClassVideoUser>;
  classVideoUserByClassVideoIdAndUserId: Maybe<ClassVideoUser>;
  /** Reads a single `ClassVideoUser` using its globally unique `ID`. */
  classVideoUserByNodeId: Maybe<ClassVideoUser>;
  /** Reads and enables pagination through a set of `ClassVideoUser`. */
  classVideoUsers: Maybe<ClassVideoUsersConnection>;
  /** Reads a set of `ClassVideoUser`. */
  classVideoUsersList: Maybe<Array<ClassVideoUser>>;
  /** Reads and enables pagination through a set of `ClassVideo`. */
  classVideos: Maybe<ClassVideosConnection>;
  /** Reads a set of `ClassVideo`. */
  classVideosList: Maybe<Array<ClassVideo>>;
  /** Reads and enables pagination through a set of `Class`. */
  classes: Maybe<ClassesConnection>;
  /** Reads a set of `Class`. */
  classesList: Maybe<Array<Class>>;
  connection: Maybe<Connection>;
  /** Reads a single `Connection` using its globally unique `ID`. */
  connectionByNodeId: Maybe<Connection>;
  /** Reads and enables pagination through a set of `Connection`. */
  connections: Maybe<ConnectionsConnection>;
  /** Reads a set of `Connection`. */
  connectionsList: Maybe<Array<Connection>>;
  consultantOrganizationAccess: Maybe<ConsultantOrganizationAccess>;
  /** Reads a single `ConsultantOrganizationAccess` using its globally unique `ID`. */
  consultantOrganizationAccessByNodeId: Maybe<ConsultantOrganizationAccess>;
  /** Reads and enables pagination through a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccesses: Maybe<ConsultantOrganizationAccessesConnection>;
  /** Reads a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccessesList: Maybe<Array<ConsultantOrganizationAccess>>;
  countryRegion: Maybe<CountryRegion>;
  countryRegionByName: Maybe<CountryRegion>;
  /** Reads a single `CountryRegion` using its globally unique `ID`. */
  countryRegionByNodeId: Maybe<CountryRegion>;
  /** Reads and enables pagination through a set of `CountryRegion`. */
  countryRegions: Maybe<CountryRegionsConnection>;
  /** Reads a set of `CountryRegion`. */
  countryRegionsList: Maybe<Array<CountryRegion>>;
  /** The currently logged in user (or null if not logged in). */
  currentUser: Maybe<User>;
  /** The Account ID of the currently logged in user. */
  currentUserAccountId: Maybe<Scalars['UUID']>;
  /** The ID of the currently logged in user (or null if not logged in). */
  currentUserId: Maybe<Scalars['UUID']>;
  currentUserInvitedProjectIds: Maybe<CurrentUserInvitedProjectIdsConnection>;
  currentUserInvitedProjectIdsList: Maybe<Array<Maybe<Scalars['UUID']>>>;
  currentUserRootFolder: Maybe<Scalars['UUID']>;
  customer: Maybe<Customer>;
  /** Reads a single `Customer` using its globally unique `ID`. */
  customerByNodeId: Maybe<Customer>;
  /** Reads and enables pagination through a set of `Customer`. */
  customers: Maybe<CustomersConnection>;
  /** Reads a set of `Customer`. */
  customersList: Maybe<Array<Customer>>;
  division: Maybe<Division>;
  divisionByNameAndV1Id: Maybe<Division>;
  /** Reads a single `Division` using its globally unique `ID`. */
  divisionByNodeId: Maybe<Division>;
  /** Reads and enables pagination through a set of `Division`. */
  divisions: Maybe<DivisionsConnection>;
  /** Reads a set of `Division`. */
  divisionsList: Maybe<Array<Division>>;
  document: Maybe<Document>;
  /** Reads a single `Document` using its globally unique `ID`. */
  documentByNodeId: Maybe<Document>;
  documentStar: Maybe<DocumentStar>;
  documentStarByDocumentIdAndUserId: Maybe<DocumentStar>;
  /** Reads a single `DocumentStar` using its globally unique `ID`. */
  documentStarByNodeId: Maybe<DocumentStar>;
  /** Reads and enables pagination through a set of `DocumentStar`. */
  documentStars: Maybe<DocumentStarsConnection>;
  /** Reads a set of `DocumentStar`. */
  documentStarsList: Maybe<Array<DocumentStar>>;
  /** Reads and enables pagination through a set of `Document`. */
  documents: Maybe<DocumentsConnection>;
  /** Reads a set of `Document`. */
  documentsList: Maybe<Array<Document>>;
  eventType: Maybe<EventType>;
  /** Reads a single `EventType` using its globally unique `ID`. */
  eventTypeByNodeId: Maybe<EventType>;
  /** Reads and enables pagination through a set of `EventType`. */
  eventTypes: Maybe<EventTypesConnection>;
  /** Reads a set of `EventType`. */
  eventTypesList: Maybe<Array<EventType>>;
  feedbackQuestion: Maybe<FeedbackQuestion>;
  feedbackQuestionAnswer: Maybe<FeedbackQuestionAnswer>;
  /** Reads a single `FeedbackQuestionAnswer` using its globally unique `ID`. */
  feedbackQuestionAnswerByNodeId: Maybe<FeedbackQuestionAnswer>;
  /** Reads and enables pagination through a set of `FeedbackQuestionAnswer`. */
  feedbackQuestionAnswers: Maybe<FeedbackQuestionAnswersConnection>;
  /** Reads a set of `FeedbackQuestionAnswer`. */
  feedbackQuestionAnswersList: Maybe<Array<FeedbackQuestionAnswer>>;
  /** Reads a single `FeedbackQuestion` using its globally unique `ID`. */
  feedbackQuestionByNodeId: Maybe<FeedbackQuestion>;
  feedbackQuestionByNumberAndQuestion: Maybe<FeedbackQuestion>;
  /** Reads and enables pagination through a set of `FeedbackQuestion`. */
  feedbackQuestions: Maybe<FeedbackQuestionsConnection>;
  /** Reads a set of `FeedbackQuestion`. */
  feedbackQuestionsList: Maybe<Array<FeedbackQuestion>>;
  feedbackResponse: Maybe<FeedbackResponse>;
  /** Reads a single `FeedbackResponse` using its globally unique `ID`. */
  feedbackResponseByNodeId: Maybe<FeedbackResponse>;
  feedbackResponseByV1WorkshopIdAndUserId: Maybe<FeedbackResponse>;
  /** Reads and enables pagination through a set of `FeedbackResponse`. */
  feedbackResponses: Maybe<FeedbackResponsesConnection>;
  /** Reads a set of `FeedbackResponse`. */
  feedbackResponsesList: Maybe<Array<FeedbackResponse>>;
  forgottenEmailSubmission: Maybe<ForgottenEmailSubmission>;
  /** Reads a single `ForgottenEmailSubmission` using its globally unique `ID`. */
  forgottenEmailSubmissionByNodeId: Maybe<ForgottenEmailSubmission>;
  /** Reads and enables pagination through a set of `ForgottenEmailSubmission`. */
  forgottenEmailSubmissions: Maybe<ForgottenEmailSubmissionsConnection>;
  /** Reads a set of `ForgottenEmailSubmission`. */
  forgottenEmailSubmissionsList: Maybe<Array<ForgottenEmailSubmission>>;
  /** Retrieve data to construct the Peer Feedback 8 Charateristics graph, as well as all the free text answers */
  getPeerFeedbackSurveyResponses: Maybe<Scalars['JSON']>;
  /** Retrieve data to construct a graph based on the responses of the profile questionnaire */
  getProfileQuestionnaireStatistics: Maybe<Scalars['JSON']>;
  googleAccountUser: Maybe<GoogleAccountUser>;
  /** Reads a single `GoogleAccountUser` using its globally unique `ID`. */
  googleAccountUserByNodeId: Maybe<GoogleAccountUser>;
  /** Reads and enables pagination through a set of `GoogleAccountUser`. */
  googleAccountUsers: Maybe<GoogleAccountUsersConnection>;
  /** Reads a set of `GoogleAccountUser`. */
  googleAccountUsersList: Maybe<Array<GoogleAccountUser>>;
  googleCalendarEvent: Maybe<GoogleCalendarEvent>;
  /** Reads a single `GoogleCalendarEvent` using its globally unique `ID`. */
  googleCalendarEventByNodeId: Maybe<GoogleCalendarEvent>;
  /** Reads and enables pagination through a set of `GoogleCalendarEvent`. */
  googleCalendarEvents: Maybe<GoogleCalendarEventsConnection>;
  /** Reads a set of `GoogleCalendarEvent`. */
  googleCalendarEventsList: Maybe<Array<GoogleCalendarEvent>>;
  /** Reads and enables pagination through a set of `Industry`. */
  industries: Maybe<IndustriesConnection>;
  /** Reads a set of `Industry`. */
  industriesList: Maybe<Array<Industry>>;
  industry: Maybe<Industry>;
  industryByNameAndV1Id: Maybe<Industry>;
  /** Reads a single `Industry` using its globally unique `ID`. */
  industryByNodeId: Maybe<Industry>;
  lv1GameScore: Maybe<Lv1GameScore>;
  /** Reads a single `Lv1GameScore` using its globally unique `ID`. */
  lv1GameScoreByNodeId: Maybe<Lv1GameScore>;
  /** Reads and enables pagination through a set of `Lv1GameScore`. */
  lv1GameScores: Maybe<Lv1GameScoresConnection>;
  /** Reads a set of `Lv1GameScore`. */
  lv1GameScoresList: Maybe<Array<Lv1GameScore>>;
  lv1GameState: Maybe<Lv1GameState>;
  /** Reads a single `Lv1GameState` using its globally unique `ID`. */
  lv1GameStateByNodeId: Maybe<Lv1GameState>;
  /** Reads and enables pagination through a set of `Lv1GameState`. */
  lv1GameStates: Maybe<Lv1GameStatesConnection>;
  /** Reads a set of `Lv1GameState`. */
  lv1GameStatesList: Maybe<Array<Lv1GameState>>;
  lv1GameSubgroup: Maybe<Lv1GameSubgroup>;
  /** Reads a single `Lv1GameSubgroup` using its globally unique `ID`. */
  lv1GameSubgroupByNodeId: Maybe<Lv1GameSubgroup>;
  lv1GameSubgroupUser: Maybe<Lv1GameSubgroupUser>;
  /** Reads a single `Lv1GameSubgroupUser` using its globally unique `ID`. */
  lv1GameSubgroupUserByNodeId: Maybe<Lv1GameSubgroupUser>;
  /** Reads and enables pagination through a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsers: Maybe<Lv1GameSubgroupUsersConnection>;
  /** Reads a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsersList: Maybe<Array<Lv1GameSubgroupUser>>;
  /** Reads and enables pagination through a set of `Lv1GameSubgroup`. */
  lv1GameSubgroups: Maybe<Lv1GameSubgroupsConnection>;
  /** Reads a set of `Lv1GameSubgroup`. */
  lv1GameSubgroupsList: Maybe<Array<Lv1GameSubgroup>>;
  /** Retrieve data about the LV1 subgroup leaderboard in the organization of the current logged-in user */
  lv1Leaderboard: Maybe<Array<Maybe<Scalars['JSON']>>>;
  massUserUpload: Maybe<MassUserUpload>;
  /** Reads a single `MassUserUpload` using its globally unique `ID`. */
  massUserUploadByNodeId: Maybe<MassUserUpload>;
  /** Reads and enables pagination through a set of `MassUserUpload`. */
  massUserUploads: Maybe<MassUserUploadsConnection>;
  /** Reads a set of `MassUserUpload`. */
  massUserUploadsList: Maybe<Array<MassUserUpload>>;
  /** Find the next video in all user classes that is premiered and is not yet fully watched by current user */
  nextVideo: Maybe<ClassVideo>;
  /** Fetches an object given its globally unique `ID`. */
  node: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  organizationCoachingTime: Maybe<OrganizationCoachingTime>;
  /** Reads a single `OrganizationCoachingTime` using its globally unique `ID`. */
  organizationCoachingTimeByNodeId: Maybe<OrganizationCoachingTime>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistories: Maybe<OrganizationCoachingTimeHistoriesConnection>;
  /** Reads a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistoriesList: Maybe<Array<OrganizationCoachingTimeHistory>>;
  organizationCoachingTimeLog: Maybe<OrganizationCoachingTimeLog>;
  /** Reads a single `OrganizationCoachingTimeLog` using its globally unique `ID`. */
  organizationCoachingTimeLogByNodeId: Maybe<OrganizationCoachingTimeLog>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogs: Maybe<OrganizationCoachingTimeLogsConnection>;
  /** Reads a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogsList: Maybe<Array<OrganizationCoachingTimeLog>>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTime`. */
  organizationCoachingTimes: Maybe<OrganizationCoachingTimesConnection>;
  /** Reads a set of `OrganizationCoachingTime`. */
  organizationCoachingTimesList: Maybe<Array<OrganizationCoachingTime>>;
  project: Maybe<Project>;
  projectAccessLogByProjectIdAndUserId: Maybe<ProjectAccessLog>;
  /** Reads and enables pagination through a set of `ProjectAccessLog`. */
  projectAccessLogs: Maybe<ProjectAccessLogsConnection>;
  /** Reads a set of `ProjectAccessLog`. */
  projectAccessLogsList: Maybe<Array<ProjectAccessLog>>;
  projectAlignSurvey: Maybe<ProjectAlignSurvey>;
  /** Reads a single `ProjectAlignSurvey` using its globally unique `ID`. */
  projectAlignSurveyByNodeId: Maybe<ProjectAlignSurvey>;
  /** Reads and enables pagination through a set of `ProjectAlignSurvey`. */
  projectAlignSurveys: Maybe<ProjectAlignSurveysConnection>;
  /** Reads a set of `ProjectAlignSurvey`. */
  projectAlignSurveysList: Maybe<Array<ProjectAlignSurvey>>;
  /** Reads a single `Project` using its globally unique `ID`. */
  projectByNodeId: Maybe<Project>;
  projectComment: Maybe<ProjectComment>;
  /** Reads a single `ProjectComment` using its globally unique `ID`. */
  projectCommentByNodeId: Maybe<ProjectComment>;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  projectComments: Maybe<ProjectCommentsConnection>;
  /** Reads a set of `ProjectComment`. */
  projectCommentsList: Maybe<Array<ProjectComment>>;
  projectCommunicationMessage: Maybe<ProjectCommunicationMessage>;
  /** Reads a single `ProjectCommunicationMessage` using its globally unique `ID`. */
  projectCommunicationMessageByNodeId: Maybe<ProjectCommunicationMessage>;
  /** Reads and enables pagination through a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessages: Maybe<ProjectCommunicationMessagesConnection>;
  /** Reads a set of `ProjectCommunicationMessage`. */
  projectCommunicationMessagesList: Maybe<Array<ProjectCommunicationMessage>>;
  projectCommunicationQuestion: Maybe<ProjectCommunicationQuestion>;
  /** Reads a single `ProjectCommunicationQuestion` using its globally unique `ID`. */
  projectCommunicationQuestionByNodeId: Maybe<ProjectCommunicationQuestion>;
  projectCommunicationQuestionByProjectIdAndQuestionType: Maybe<ProjectCommunicationQuestion>;
  /** Reads and enables pagination through a set of `ProjectCommunicationQuestion`. */
  projectCommunicationQuestions: Maybe<ProjectCommunicationQuestionsConnection>;
  /** Reads a set of `ProjectCommunicationQuestion`. */
  projectCommunicationQuestionsList: Maybe<Array<ProjectCommunicationQuestion>>;
  projectCompliance: Maybe<ProjectCompliance>;
  /** Reads a single `ProjectCompliance` using its globally unique `ID`. */
  projectComplianceByNodeId: Maybe<ProjectCompliance>;
  /** Reads and enables pagination through a set of `ProjectCompliance`. */
  projectCompliances: Maybe<ProjectCompliancesConnection>;
  /** Reads a set of `ProjectCompliance`. */
  projectCompliancesList: Maybe<Array<ProjectCompliance>>;
  projectConnection: Maybe<ProjectConnection>;
  /** Reads a single `ProjectConnection` using its globally unique `ID`. */
  projectConnectionByNodeId: Maybe<ProjectConnection>;
  projectConnectionByProjectIdAndConnectionId: Maybe<ProjectConnection>;
  projectConnectionLink: Maybe<ProjectConnectionLink>;
  projectConnectionLinkByInternalProjectConnectionIdAndExternalProjectConnectionId: Maybe<ProjectConnectionLink>;
  /** Reads a single `ProjectConnectionLink` using its globally unique `ID`. */
  projectConnectionLinkByNodeId: Maybe<ProjectConnectionLink>;
  /** Reads and enables pagination through a set of `ProjectConnectionLink`. */
  projectConnectionLinks: Maybe<ProjectConnectionLinksConnection>;
  /** Reads a set of `ProjectConnectionLink`. */
  projectConnectionLinksList: Maybe<Array<ProjectConnectionLink>>;
  /** Reads and enables pagination through a set of `ProjectConnection`. */
  projectConnections: Maybe<ProjectConnectionsConnection>;
  /** Reads a set of `ProjectConnection`. */
  projectConnectionsList: Maybe<Array<ProjectConnection>>;
  projectEvent: Maybe<ProjectEvent>;
  /** Reads a single `ProjectEvent` using its globally unique `ID`. */
  projectEventByNodeId: Maybe<ProjectEvent>;
  /** Reads and enables pagination through a set of `ProjectEvent`. */
  projectEvents: Maybe<ProjectEventsConnection>;
  /** Reads a set of `ProjectEvent`. */
  projectEventsList: Maybe<Array<ProjectEvent>>;
  projectFolderByProjectIdAndUserId: Maybe<ProjectFolder>;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFolders: Maybe<ProjectFoldersConnection>;
  /** Reads a set of `ProjectFolder`. */
  projectFoldersList: Maybe<Array<ProjectFolder>>;
  projectInvitation: Maybe<ProjectInvitation>;
  /** Reads a single `ProjectInvitation` using its globally unique `ID`. */
  projectInvitationByNodeId: Maybe<ProjectInvitation>;
  projectInvitationByProjectIdAndUserId: Maybe<ProjectInvitation>;
  /** Reads and enables pagination through a set of `ProjectInvitation`. */
  projectInvitations: Maybe<ProjectInvitationsConnection>;
  /** Reads a set of `ProjectInvitation`. */
  projectInvitationsList: Maybe<Array<ProjectInvitation>>;
  projectMembership: Maybe<ProjectMembership>;
  /** Reads a single `ProjectMembership` using its globally unique `ID`. */
  projectMembershipByNodeId: Maybe<ProjectMembership>;
  projectMembershipByProjectIdAndUserId: Maybe<ProjectMembership>;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMemberships: Maybe<ProjectMembershipsConnection>;
  /** Reads a set of `ProjectMembership`. */
  projectMembershipsList: Maybe<Array<ProjectMembership>>;
  projectObjective: Maybe<ProjectObjective>;
  /** Reads a single `ProjectObjective` using its globally unique `ID`. */
  projectObjectiveByNodeId: Maybe<ProjectObjective>;
  /** Reads and enables pagination through a set of `ProjectObjective`. */
  projectObjectives: Maybe<ProjectObjectivesConnection>;
  /** Reads a set of `ProjectObjective`. */
  projectObjectivesList: Maybe<Array<ProjectObjective>>;
  projectOffer: Maybe<ProjectOffer>;
  /** Reads a single `ProjectOffer` using its globally unique `ID`. */
  projectOfferByNodeId: Maybe<ProjectOffer>;
  projectOfferVariable: Maybe<ProjectOfferVariable>;
  /** Reads a single `ProjectOfferVariable` using its globally unique `ID`. */
  projectOfferVariableByNodeId: Maybe<ProjectOfferVariable>;
  /** Reads and enables pagination through a set of `ProjectOfferVariable`. */
  projectOfferVariables: Maybe<ProjectOfferVariablesConnection>;
  /** Reads a set of `ProjectOfferVariable`. */
  projectOfferVariablesList: Maybe<Array<ProjectOfferVariable>>;
  /** Reads and enables pagination through a set of `ProjectOffer`. */
  projectOffers: Maybe<ProjectOffersConnection>;
  /** Reads a set of `ProjectOffer`. */
  projectOffersList: Maybe<Array<ProjectOffer>>;
  projectPower: Maybe<ProjectPower>;
  /** Reads a single `ProjectPower` using its globally unique `ID`. */
  projectPowerByNodeId: Maybe<ProjectPower>;
  /** Reads and enables pagination through a set of `ProjectPower`. */
  projectPowers: Maybe<ProjectPowersConnection>;
  /** Reads a set of `ProjectPower`. */
  projectPowersList: Maybe<Array<ProjectPower>>;
  projectReminder: Maybe<ProjectReminder>;
  /** Reads a single `ProjectReminder` using its globally unique `ID`. */
  projectReminderByNodeId: Maybe<ProjectReminder>;
  /** Reads and enables pagination through a set of `ProjectReminder`. */
  projectReminders: Maybe<ProjectRemindersConnection>;
  /** Reads a set of `ProjectReminder`. */
  projectRemindersList: Maybe<Array<ProjectReminder>>;
  projectRisk: Maybe<ProjectRisk>;
  /** Reads a single `ProjectRisk` using its globally unique `ID`. */
  projectRiskByNodeId: Maybe<ProjectRisk>;
  /** Reads and enables pagination through a set of `ProjectRisk`. */
  projectRisks: Maybe<ProjectRisksConnection>;
  /** Reads a set of `ProjectRisk`. */
  projectRisksList: Maybe<Array<ProjectRisk>>;
  projectScenario: Maybe<ProjectScenario>;
  /** Reads a single `ProjectScenario` using its globally unique `ID`. */
  projectScenarioByNodeId: Maybe<ProjectScenario>;
  projectScenarioVariable: Maybe<ProjectScenarioVariable>;
  /** Reads a single `ProjectScenarioVariable` using its globally unique `ID`. */
  projectScenarioVariableByNodeId: Maybe<ProjectScenarioVariable>;
  /** Reads and enables pagination through a set of `ProjectScenarioVariable`. */
  projectScenarioVariables: Maybe<ProjectScenarioVariablesConnection>;
  /** Reads a set of `ProjectScenarioVariable`. */
  projectScenarioVariablesList: Maybe<Array<ProjectScenarioVariable>>;
  /** Reads and enables pagination through a set of `ProjectScenario`. */
  projectScenarios: Maybe<ProjectScenariosConnection>;
  /** Reads a set of `ProjectScenario`. */
  projectScenariosList: Maybe<Array<ProjectScenario>>;
  projectScore: Maybe<ProjectScore>;
  /** Reads a single `ProjectScore` using its globally unique `ID`. */
  projectScoreByNodeId: Maybe<ProjectScore>;
  projectScoreByScoreKeyAndProjectIdAndRecordId: Maybe<ProjectScore>;
  /** Reads and enables pagination through a set of `ProjectScore`. */
  projectScores: Maybe<ProjectScoresConnection>;
  /** Reads a set of `ProjectScore`. */
  projectScoresList: Maybe<Array<ProjectScore>>;
  projectSearch: Maybe<ProjectSearch>;
  /** Reads a single `ProjectSearch` using its globally unique `ID`. */
  projectSearchByNodeId: Maybe<ProjectSearch>;
  projectSurvey: Maybe<ProjectSurvey>;
  /** Reads a single `ProjectSurvey` using its globally unique `ID`. */
  projectSurveyByNodeId: Maybe<ProjectSurvey>;
  /** Reads and enables pagination through a set of `ProjectSurvey`. */
  projectSurveys: Maybe<ProjectSurveysConnection>;
  /** Reads a set of `ProjectSurvey`. */
  projectSurveysList: Maybe<Array<ProjectSurvey>>;
  projectVariable: Maybe<ProjectVariable>;
  /** Reads a single `ProjectVariable` using its globally unique `ID`. */
  projectVariableByNodeId: Maybe<ProjectVariable>;
  /** Reads and enables pagination through a set of `ProjectVariable`. */
  projectVariables: Maybe<ProjectVariablesConnection>;
  /** Reads a set of `ProjectVariable`. */
  projectVariablesList: Maybe<Array<ProjectVariable>>;
  /** Reads and enables pagination through a set of `Project`. */
  projects: Maybe<ProjectsConnection>;
  /** Reads a set of `Project`. */
  projectsList: Maybe<Array<Project>>;
  projectsStats: Maybe<ProjectsStatsConnection>;
  /** Avg Confidence for Future Negotiations */
  projectsStatsAverageConfidence: Maybe<Scalars['BigFloat']>;
  /** Avg Power Leaving the Negotiation */
  projectsStatsAveragePower: Maybe<Scalars['BigFloat']>;
  /** Global Average Power */
  projectsStatsGlobalAveragePower: Maybe<ProjectsStatsGlobalAveragePowerConnection>;
  /** Global Average Power */
  projectsStatsGlobalAveragePowerList: Maybe<Array<Maybe<ProjectsStatsGlobalAveragePowerRecord>>>;
  projectsStatsList: Maybe<Array<Maybe<ProjectsStatsRecord>>>;
  projectsStatsNegotiationType: Maybe<ProjectsStatsNegotiationTypeConnection>;
  projectsStatsNegotiationTypeList: Maybe<Array<Maybe<ProjectsStatsNegotiationTypeRecord>>>;
  projectsStatsPowerStates: Maybe<StatsPowerState>;
  /** Top Organizational Enabler */
  projectsStatsTopEnabler: Maybe<StatsItemPercentagesConnection>;
  /** Top Organizational Enabler */
  projectsStatsTopEnablerList: Maybe<Array<StatsItemPercentage>>;
  /** Top Organizational Obstacle */
  projectsStatsTopObstacle: Maybe<StatsItemPercentagesConnection>;
  /** Top Organizational Obstacle */
  projectsStatsTopObstacleList: Maybe<Array<StatsItemPercentage>>;
  /** Top Variables */
  projectsStatsTopVariables: Maybe<StatsItemPercentagesConnection>;
  /** Top Variables */
  projectsStatsTopVariablesList: Maybe<Array<StatsItemPercentage>>;
  /** Value Added Stats */
  projectsStatsValueAdded: Maybe<ProjectsStatsValueAddedConnection>;
  /** Value Added Stats */
  projectsStatsValueAddedList: Maybe<Array<Maybe<ProjectsStatsValueAddedRecord>>>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  surveyBucket: Maybe<SurveyBucket>;
  surveyBucketByName: Maybe<SurveyBucket>;
  /** Reads a single `SurveyBucket` using its globally unique `ID`. */
  surveyBucketByNodeId: Maybe<SurveyBucket>;
  /** Reads and enables pagination through a set of `SurveyBucket`. */
  surveyBuckets: Maybe<SurveyBucketsConnection>;
  /** Reads a set of `SurveyBucket`. */
  surveyBucketsList: Maybe<Array<SurveyBucket>>;
  surveyQuestion: Maybe<SurveyQuestion>;
  surveyQuestionAnswer: Maybe<SurveyQuestionAnswer>;
  /** Reads a single `SurveyQuestionAnswer` using its globally unique `ID`. */
  surveyQuestionAnswerByNodeId: Maybe<SurveyQuestionAnswer>;
  /** Reads and enables pagination through a set of `SurveyQuestionAnswer`. */
  surveyQuestionAnswers: Maybe<SurveyQuestionAnswersConnection>;
  /** Reads a set of `SurveyQuestionAnswer`. */
  surveyQuestionAnswersList: Maybe<Array<SurveyQuestionAnswer>>;
  /** Reads a single `SurveyQuestion` using its globally unique `ID`. */
  surveyQuestionByNodeId: Maybe<SurveyQuestion>;
  surveyQuestionByQuestionAndSurveyType: Maybe<SurveyQuestion>;
  /** Reads and enables pagination through a set of `SurveyQuestion`. */
  surveyQuestions: Maybe<SurveyQuestionsConnection>;
  /** Reads a set of `SurveyQuestion`. */
  surveyQuestionsList: Maybe<Array<SurveyQuestion>>;
  surveyReminder: Maybe<SurveyReminder>;
  /** Reads a single `SurveyReminder` using its globally unique `ID`. */
  surveyReminderByNodeId: Maybe<SurveyReminder>;
  /** Reads and enables pagination through a set of `SurveyReminder`. */
  surveyReminders: Maybe<SurveyRemindersConnection>;
  /** Reads a set of `SurveyReminder`. */
  surveyRemindersList: Maybe<Array<SurveyReminder>>;
  surveyResponse: Maybe<SurveyResponse>;
  /** Reads a single `SurveyResponse` using its globally unique `ID`. */
  surveyResponseByNodeId: Maybe<SurveyResponse>;
  surveyResponseByUserIdAndRecipientIdAndSurveyType: Maybe<SurveyResponse>;
  /** Reads and enables pagination through a set of `SurveyResponse`. */
  surveyResponses: Maybe<SurveyResponsesConnection>;
  /** Reads a set of `SurveyResponse`. */
  surveyResponsesList: Maybe<Array<SurveyResponse>>;
  user: Maybe<User>;
  userByEmail: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userByNodeId: Maybe<User>;
  userLanguageByLanguageCode: Maybe<UserLanguage>;
  userLanguageByName: Maybe<UserLanguage>;
  /** Reads and enables pagination through a set of `UserLanguage`. */
  userLanguages: Maybe<UserLanguagesConnection>;
  /** Reads a set of `UserLanguage`. */
  userLanguagesList: Maybe<Array<UserLanguage>>;
  /** Returns audit log events for a given user */
  userLogEntries: Maybe<UserAuditLogEventsConnection>;
  /** Returns audit log events for a given user */
  userLogEntriesList: Maybe<Array<UserAuditLogEvent>>;
  userProfileQuestionnaire: Maybe<UserProfileQuestionnaire>;
  /** Reads a single `UserProfileQuestionnaire` using its globally unique `ID`. */
  userProfileQuestionnaireByNodeId: Maybe<UserProfileQuestionnaire>;
  userProfileQuestionnaireByUserId: Maybe<UserProfileQuestionnaire>;
  /** Reads and enables pagination through a set of `UserProfileQuestionnaire`. */
  userProfileQuestionnaires: Maybe<UserProfileQuestionnairesConnection>;
  /** Reads a set of `UserProfileQuestionnaire`. */
  userProfileQuestionnairesList: Maybe<Array<UserProfileQuestionnaire>>;
  /** Reads and enables pagination through a set of `User`. */
  users: Maybe<UsersConnection>;
  /** Reads a set of `User`. */
  usersList: Maybe<Array<User>>;
  /** Returns a list of users to whom a project can be shared. */
  usersToInvite: Maybe<UsersConnection>;
  /** Returns a list of users to whom a project can be shared. */
  usersToInviteList: Maybe<Array<User>>;
  v1Role: Maybe<V1Role>;
  v1RoleByIdAndName: Maybe<V1Role>;
  /** Reads a single `V1Role` using its globally unique `ID`. */
  v1RoleByNodeId: Maybe<V1Role>;
  /** Reads and enables pagination through a set of `V1Role`. */
  v1Roles: Maybe<V1RolesConnection>;
  /** Reads a set of `V1Role`. */
  v1RolesList: Maybe<Array<V1Role>>;
  /** Valiate if an IdP name that is passed is valid */
  validateIdpName: Maybe<Scalars['Boolean']>;
  variableClass: Maybe<VariableClass>;
  /** Reads a single `VariableClass` using its globally unique `ID`. */
  variableClassByNodeId: Maybe<VariableClass>;
  /** See intersection projects of two variable classes. */
  variableClassProjectIntersection: Maybe<ProjectsConnection>;
  /** See intersection projects of two variable classes. */
  variableClassProjectIntersectionList: Maybe<Array<Project>>;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClasses: Maybe<VariableClassesConnection>;
  /** Reads a set of `VariableClass`. */
  variableClassesList: Maybe<Array<VariableClass>>;
  video: Maybe<Video>;
  /** Reads a single `Video` using its globally unique `ID`. */
  videoByNodeId: Maybe<Video>;
  videoStar: Maybe<VideoStar>;
  /** Reads a single `VideoStar` using its globally unique `ID`. */
  videoStarByNodeId: Maybe<VideoStar>;
  videoStarByVideoIdAndUserId: Maybe<VideoStar>;
  /** Reads and enables pagination through a set of `VideoStar`. */
  videoStars: Maybe<VideoStarsConnection>;
  /** Reads a set of `VideoStar`. */
  videoStarsList: Maybe<Array<VideoStar>>;
  /** Reads and enables pagination through a set of `Video`. */
  videos: Maybe<VideosConnection>;
  /** Reads a set of `Video`. */
  videosList: Maybe<Array<Video>>;
  workshop: Maybe<Workshop>;
  /** Reads a single `Workshop` using its globally unique `ID`. */
  workshopByNodeId: Maybe<Workshop>;
  workshopPreset: Maybe<WorkshopPreset>;
  /** Reads a single `WorkshopPreset` using its globally unique `ID`. */
  workshopPresetByNodeId: Maybe<WorkshopPreset>;
  /** Reads and enables pagination through a set of `WorkshopPreset`. */
  workshopPresets: Maybe<WorkshopPresetsConnection>;
  /** Reads a set of `WorkshopPreset`. */
  workshopPresetsList: Maybe<Array<WorkshopPreset>>;
  /** Retrieve data to construct a graph based on the responses of LV2/LV3 Pre-Workshop and Peer Feedback Surveys */
  workshopSurveyStatistics: Maybe<Scalars['JSON']>;
  /** Reads and enables pagination through a set of `Workshop`. */
  workshops: Maybe<WorkshopsConnection>;
  workshopsCalendar: Maybe<WorkshopsCalendarConnection>;
  workshopsCalendarList: Maybe<Array<Maybe<WorkshopsCalendarRecord>>>;
  /** Reads a set of `Workshop`. */
  workshopsList: Maybe<Array<Workshop>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAccountsListArgs = {
  condition?: InputMaybe<AccountCondition>;
  filter?: InputMaybe<AccountFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActiveCountryRegionArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ActiveCountryRegionRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActiveCountryRegionListArgs = {
  filter?: InputMaybe<ActiveCountryRegionRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActiveIndustriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ActiveIndustriesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActiveIndustriesListArgs = {
  filter?: InputMaybe<ActiveIndustriesRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivitiesListArgs = {
  condition?: InputMaybe<ActivityCondition>;
  filter?: InputMaybe<ActivityFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityLevelsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ActivityLevelCondition>;
  filter?: InputMaybe<ActivityLevelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityLevelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryActivityLevelsListArgs = {
  condition?: InputMaybe<ActivityLevelCondition>;
  filter?: InputMaybe<ActivityLevelFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ActivityLevelsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAdminContactMessageArgs = {
  email?: InputMaybe<Scalars['Email']>;
  idpName?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupTypeMappingArgs = {
  briefGroupType: BriefGroupType;
  briefVariant: BriefVariant;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupTypeMappingByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupTypeMappingsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupTypeMappingCondition>;
  filter?: InputMaybe<BriefGroupTypeMappingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupTypeMappingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupTypeMappingsListArgs = {
  condition?: InputMaybe<BriefGroupTypeMappingCondition>;
  filter?: InputMaybe<BriefGroupTypeMappingFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupTypeMappingsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableOptionArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableOptionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableOptionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupVariableOptionCondition>;
  filter?: InputMaybe<BriefGroupVariableOptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariableOptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariableOptionsListArgs = {
  condition?: InputMaybe<BriefGroupVariableOptionCondition>;
  filter?: InputMaybe<BriefGroupVariableOptionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariableOptionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupVariableCondition>;
  filter?: InputMaybe<BriefGroupVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupVariablesListArgs = {
  condition?: InputMaybe<BriefGroupVariableCondition>;
  filter?: InputMaybe<BriefGroupVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefGroupsListArgs = {
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefPresetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefPresetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefPresetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefPresetCondition>;
  filter?: InputMaybe<BriefPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefPresetsListArgs = {
  condition?: InputMaybe<BriefPresetCondition>;
  filter?: InputMaybe<BriefPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefCondition>;
  filter?: InputMaybe<BriefFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBriefsListArgs = {
  condition?: InputMaybe<BriefCondition>;
  filter?: InputMaybe<BriefFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCalculateStatisticsArgs = {
  accountId?: InputMaybe<Scalars['UUID']>;
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  divisionId?: InputMaybe<Scalars['UUID']>;
  gender?: InputMaybe<Gender>;
  industryId?: InputMaybe<Scalars['UUID']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  statType: Scalars['String'];
  training?: InputMaybe<YesNoOption>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassAssetCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassAssetCategoryCondition>;
  filter?: InputMaybe<ClassAssetCategoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassAssetCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassAssetCategoriesListArgs = {
  condition?: InputMaybe<ClassAssetCategoryCondition>;
  filter?: InputMaybe<ClassAssetCategoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassAssetCategoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassAssetCategoryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassAssetCategoryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassDocumentArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassDocumentByClassIdAndDocumentIdArgs = {
  classId: Scalars['UUID'];
  documentId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassDocumentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassDocumentsListArgs = {
  condition?: InputMaybe<ClassDocumentCondition>;
  filter?: InputMaybe<ClassDocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassStarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassStarByClassIdAndUserIdArgs = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassStarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassStarCondition>;
  filter?: InputMaybe<ClassStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassStarsListArgs = {
  condition?: InputMaybe<ClassStarCondition>;
  filter?: InputMaybe<ClassStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassUserByClassIdAndUserIdArgs = {
  classId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassUsersListArgs = {
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoByClassIdAndVideoIdArgs = {
  classId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoUserByClassVideoIdAndUserIdArgs = {
  classVideoId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideoUsersListArgs = {
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassVideosListArgs = {
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryClassesListArgs = {
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConnectionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConnectionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConnectionsListArgs = {
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConsultantOrganizationAccessArgs = {
  accountId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConsultantOrganizationAccessByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryConsultantOrganizationAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryConsultantOrganizationAccessesListArgs = {
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryRegionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryRegionByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryRegionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryRegionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CountryRegionCondition>;
  filter?: InputMaybe<CountryRegionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCountryRegionsListArgs = {
  condition?: InputMaybe<CountryRegionCondition>;
  filter?: InputMaybe<CountryRegionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CountryRegionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentUserInvitedProjectIdsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UuidFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCurrentUserInvitedProjectIdsListArgs = {
  filter?: InputMaybe<UuidFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<CustomerCondition>;
  filter?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomersListArgs = {
  condition?: InputMaybe<CustomerCondition>;
  filter?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionByNameAndV1IdArgs = {
  name: Scalars['String'];
  v1Id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DivisionCondition>;
  filter?: InputMaybe<DivisionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDivisionsListArgs = {
  condition?: InputMaybe<DivisionCondition>;
  filter?: InputMaybe<DivisionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentStarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentStarByDocumentIdAndUserIdArgs = {
  documentId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentStarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentStarCondition>;
  filter?: InputMaybe<DocumentStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentStarsListArgs = {
  condition?: InputMaybe<DocumentStarCondition>;
  filter?: InputMaybe<DocumentStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryDocumentsListArgs = {
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventTypeArgs = {
  title: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventTypeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryEventTypesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<EventTypeCondition>;
  filter?: InputMaybe<EventTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryEventTypesListArgs = {
  condition?: InputMaybe<EventTypeCondition>;
  filter?: InputMaybe<EventTypeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionAnswerArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionAnswerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackQuestionAnswerCondition>;
  filter?: InputMaybe<FeedbackQuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackQuestionAnswersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionAnswersListArgs = {
  condition?: InputMaybe<FeedbackQuestionAnswerCondition>;
  filter?: InputMaybe<FeedbackQuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackQuestionAnswersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionByNumberAndQuestionArgs = {
  number: Scalars['Int'];
  question: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackQuestionCondition>;
  filter?: InputMaybe<FeedbackQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackQuestionsListArgs = {
  condition?: InputMaybe<FeedbackQuestionCondition>;
  filter?: InputMaybe<FeedbackQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackResponseArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackResponseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackResponseByV1WorkshopIdAndUserIdArgs = {
  userId: Scalars['UUID'];
  v1WorkshopId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackResponsesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackResponseCondition>;
  filter?: InputMaybe<FeedbackResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackResponsesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryFeedbackResponsesListArgs = {
  condition?: InputMaybe<FeedbackResponseCondition>;
  filter?: InputMaybe<FeedbackResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackResponsesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryForgottenEmailSubmissionArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryForgottenEmailSubmissionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryForgottenEmailSubmissionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ForgottenEmailSubmissionCondition>;
  filter?: InputMaybe<ForgottenEmailSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ForgottenEmailSubmissionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryForgottenEmailSubmissionsListArgs = {
  condition?: InputMaybe<ForgottenEmailSubmissionCondition>;
  filter?: InputMaybe<ForgottenEmailSubmissionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ForgottenEmailSubmissionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetPeerFeedbackSurveyResponsesArgs = {
  v1WorkshopId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetProfileQuestionnaireStatisticsArgs = {
  accountId?: InputMaybe<Scalars['UUID']>;
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  divisionId?: InputMaybe<Scalars['UUID']>;
  gender?: InputMaybe<Gender>;
  industryId?: InputMaybe<Scalars['UUID']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GoogleAccountUserCondition>;
  filter?: InputMaybe<GoogleAccountUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleAccountUsersListArgs = {
  condition?: InputMaybe<GoogleAccountUserCondition>;
  filter?: InputMaybe<GoogleAccountUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarEventArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGoogleCalendarEventsListArgs = {
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIndustriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<IndustryCondition>;
  filter?: InputMaybe<IndustryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIndustriesListArgs = {
  condition?: InputMaybe<IndustryCondition>;
  filter?: InputMaybe<IndustryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<IndustriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryIndustryArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIndustryByNameAndV1IdArgs = {
  name: Scalars['String'];
  v1Id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryIndustryByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameScoreArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameScoreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameScoreCondition>;
  filter?: InputMaybe<Lv1GameScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameScoresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameScoresListArgs = {
  condition?: InputMaybe<Lv1GameScoreCondition>;
  filter?: InputMaybe<Lv1GameScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameScoresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameStateArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameStateByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameStatesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameStateCondition>;
  filter?: InputMaybe<Lv1GameStateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameStatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameStatesListArgs = {
  condition?: InputMaybe<Lv1GameStateCondition>;
  filter?: InputMaybe<Lv1GameStateFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameStatesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupUserArgs = {
  gameLv1SubgroupId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupUsersListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryLv1GameSubgroupsListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMassUserUploadArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMassUserUploadByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMassUserUploadsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MassUserUploadCondition>;
  filter?: InputMaybe<MassUserUploadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMassUserUploadsListArgs = {
  condition?: InputMaybe<MassUserUploadCondition>;
  filter?: InputMaybe<MassUserUploadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeArgs = {
  accountId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeHistoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeHistoriesListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeLogArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeLogByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimeLogsListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryOrganizationCoachingTimesListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAccessLogByProjectIdAndUserIdArgs = {
  projectId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAccessLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAccessLogsListArgs = {
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAlignSurveyArgs = {
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAlignSurveyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAlignSurveysArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectAlignSurveyCondition>;
  filter?: InputMaybe<ProjectAlignSurveyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAlignSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectAlignSurveysListArgs = {
  condition?: InputMaybe<ProjectAlignSurveyCondition>;
  filter?: InputMaybe<ProjectAlignSurveyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAlignSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommentArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommentByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommentsListArgs = {
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationMessageArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationMessageByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationMessagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationMessagesListArgs = {
  condition?: InputMaybe<ProjectCommunicationMessageCondition>;
  filter?: InputMaybe<ProjectCommunicationMessageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationQuestionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationQuestionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationQuestionByProjectIdAndQuestionTypeArgs = {
  projectId: Scalars['UUID'];
  questionType: CommsQuestionType;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationQuestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommunicationQuestionCondition>;
  filter?: InputMaybe<ProjectCommunicationQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCommunicationQuestionsListArgs = {
  condition?: InputMaybe<ProjectCommunicationQuestionCondition>;
  filter?: InputMaybe<ProjectCommunicationQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectComplianceArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectComplianceByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCompliancesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectComplianceCondition>;
  filter?: InputMaybe<ProjectComplianceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectCompliancesListArgs = {
  condition?: InputMaybe<ProjectComplianceCondition>;
  filter?: InputMaybe<ProjectComplianceFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionByProjectIdAndConnectionIdArgs = {
  connectionId: Scalars['UUID'];
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionLinkArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionLinkByInternalProjectConnectionIdAndExternalProjectConnectionIdArgs = {
  externalProjectConnectionId: Scalars['UUID'];
  internalProjectConnectionId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionLinkByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionLinksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionLinksListArgs = {
  condition?: InputMaybe<ProjectConnectionLinkCondition>;
  filter?: InputMaybe<ProjectConnectionLinkFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionLinksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectConnectionsListArgs = {
  condition?: InputMaybe<ProjectConnectionCondition>;
  filter?: InputMaybe<ProjectConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEventArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEventByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectEventsListArgs = {
  condition?: InputMaybe<ProjectEventCondition>;
  filter?: InputMaybe<ProjectEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectFolderByProjectIdAndUserIdArgs = {
  projectId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectFoldersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectFoldersListArgs = {
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectInvitationArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectInvitationByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectInvitationByProjectIdAndUserIdArgs = {
  projectId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectInvitationsListArgs = {
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembershipArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembershipByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembershipByProjectIdAndUserIdArgs = {
  projectId: Scalars['UUID'];
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectMembershipsListArgs = {
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectObjectiveArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectObjectiveByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectObjectivesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectObjectiveCondition>;
  filter?: InputMaybe<ProjectObjectiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectObjectivesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectObjectivesListArgs = {
  condition?: InputMaybe<ProjectObjectiveCondition>;
  filter?: InputMaybe<ProjectObjectiveFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectObjectivesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferVariableArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferVariableByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOfferVariablesListArgs = {
  condition?: InputMaybe<ProjectOfferVariableCondition>;
  filter?: InputMaybe<ProjectOfferVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOffersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectOfferCondition>;
  filter?: InputMaybe<ProjectOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectOffersListArgs = {
  condition?: InputMaybe<ProjectOfferCondition>;
  filter?: InputMaybe<ProjectOfferFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPowerArgs = {
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPowerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPowersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectPowerCondition>;
  filter?: InputMaybe<ProjectPowerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectPowersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectPowersListArgs = {
  condition?: InputMaybe<ProjectPowerCondition>;
  filter?: InputMaybe<ProjectPowerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectPowersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReminderArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectReminderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRemindersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectReminderCondition>;
  filter?: InputMaybe<ProjectReminderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRemindersListArgs = {
  condition?: InputMaybe<ProjectReminderCondition>;
  filter?: InputMaybe<ProjectReminderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRiskArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRiskByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRisksArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectRiskCondition>;
  filter?: InputMaybe<ProjectRiskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectRisksListArgs = {
  condition?: InputMaybe<ProjectRiskCondition>;
  filter?: InputMaybe<ProjectRiskFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioVariableArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioVariableByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenarioVariablesListArgs = {
  condition?: InputMaybe<ProjectScenarioVariableCondition>;
  filter?: InputMaybe<ProjectScenarioVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenariosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScenarioCondition>;
  filter?: InputMaybe<ProjectScenarioFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScenariosListArgs = {
  condition?: InputMaybe<ProjectScenarioCondition>;
  filter?: InputMaybe<ProjectScenarioFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScoreArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScoreByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScoreByScoreKeyAndProjectIdAndRecordIdArgs = {
  projectId: Scalars['UUID'];
  recordId: Scalars['String'];
  scoreKey: ProjectScoreKey;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScoresArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectScoreCondition>;
  filter?: InputMaybe<ProjectScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScoresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectScoresListArgs = {
  condition?: InputMaybe<ProjectScoreCondition>;
  filter?: InputMaybe<ProjectScoreFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectScoresOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSearchArgs = {
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSearchByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSurveyArgs = {
  projectId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSurveyByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSurveysArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectSurveyCondition>;
  filter?: InputMaybe<ProjectSurveyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectSurveysListArgs = {
  condition?: InputMaybe<ProjectSurveyCondition>;
  filter?: InputMaybe<ProjectSurveyFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectSurveysOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectVariableArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectVariableByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectVariablesListArgs = {
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsListArgs = {
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  intervalName: PeriodName;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsAverageConfidenceArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsAveragePowerArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsGlobalAveragePowerArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsGlobalAveragePowerRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsGlobalAveragePowerListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsGlobalAveragePowerRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  intervalName: PeriodName;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsNegotiationTypeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsNegotiationTypeRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsNegotiationTypeListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsNegotiationTypeRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsPowerStatesArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopEnablerArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopEnablerListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopObstacleArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopObstacleListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsTopVariablesListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsValueAddedArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsValueAddedRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  intervalName: PeriodName;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryProjectsStatsValueAddedListArgs = {
  dateEnd?: InputMaybe<Scalars['Datetime']>;
  dateStart: Scalars['Datetime'];
  filter?: InputMaybe<ProjectsStatsValueAddedRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  intervalName: PeriodName;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyBucketArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyBucketByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyBucketByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyBucketsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyBucketCondition>;
  filter?: InputMaybe<SurveyBucketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyBucketsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyBucketsListArgs = {
  condition?: InputMaybe<SurveyBucketCondition>;
  filter?: InputMaybe<SurveyBucketFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyBucketsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionAnswerArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionAnswerByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionAnswersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyQuestionAnswerCondition>;
  filter?: InputMaybe<SurveyQuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyQuestionAnswersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionAnswersListArgs = {
  condition?: InputMaybe<SurveyQuestionAnswerCondition>;
  filter?: InputMaybe<SurveyQuestionAnswerFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyQuestionAnswersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionByQuestionAndSurveyTypeArgs = {
  question: Scalars['String'];
  surveyType: SurveyType;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyQuestionCondition>;
  filter?: InputMaybe<SurveyQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyQuestionsListArgs = {
  condition?: InputMaybe<SurveyQuestionCondition>;
  filter?: InputMaybe<SurveyQuestionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyQuestionsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyReminderArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyReminderByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyRemindersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyReminderCondition>;
  filter?: InputMaybe<SurveyReminderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyRemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyRemindersListArgs = {
  condition?: InputMaybe<SurveyReminderCondition>;
  filter?: InputMaybe<SurveyReminderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyRemindersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyResponseArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyResponseByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyResponseByUserIdAndRecipientIdAndSurveyTypeArgs = {
  recipientId: Scalars['UUID'];
  surveyType: SurveyType;
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyResponsesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyResponseCondition>;
  filter?: InputMaybe<SurveyResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySurveyResponsesListArgs = {
  condition?: InputMaybe<SurveyResponseCondition>;
  filter?: InputMaybe<SurveyResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByEmailArgs = {
  email: Scalars['Email'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLanguageByLanguageCodeArgs = {
  languageCode: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLanguageByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLanguagesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserLanguageCondition>;
  filter?: InputMaybe<UserLanguageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserLanguagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLanguagesListArgs = {
  condition?: InputMaybe<UserLanguageCondition>;
  filter?: InputMaybe<UserLanguageFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserLanguagesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLogEntriesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserAuditLogEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  uid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLogEntriesListArgs = {
  filter?: InputMaybe<UserAuditLogEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  uid: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileQuestionnaireArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileQuestionnaireByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileQuestionnaireByUserIdArgs = {
  userId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileQuestionnairesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserProfileQuestionnaireCondition>;
  filter?: InputMaybe<UserProfileQuestionnaireFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserProfileQuestionnairesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUserProfileQuestionnairesListArgs = {
  condition?: InputMaybe<UserProfileQuestionnaireCondition>;
  filter?: InputMaybe<UserProfileQuestionnaireFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UserProfileQuestionnairesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersListArgs = {
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersToInviteArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectAccountId?: InputMaybe<Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  projectIsOpen?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryUsersToInviteListArgs = {
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  projectAccountId?: InputMaybe<Scalars['UUID']>;
  projectId?: InputMaybe<Scalars['UUID']>;
  projectIsOpen?: InputMaybe<Scalars['Boolean']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryV1RoleArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryV1RoleByIdAndNameArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryV1RoleByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryV1RolesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<V1RoleCondition>;
  filter?: InputMaybe<V1RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<V1RolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryV1RolesListArgs = {
  condition?: InputMaybe<V1RoleCondition>;
  filter?: InputMaybe<V1RoleFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<V1RolesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryValidateIdpNameArgs = {
  idpName: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassProjectIntersectionArgs = {
  accountId: Scalars['UUID'];
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  firstId: Scalars['UUID'];
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  secondId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassProjectIntersectionListArgs = {
  accountId: Scalars['UUID'];
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  firstId: Scalars['UUID'];
  offset?: InputMaybe<Scalars['Int']>;
  secondId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVariableClassesListArgs = {
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoStarArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoStarByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoStarByVideoIdAndUserIdArgs = {
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VideoStarCondition>;
  filter?: InputMaybe<VideoStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVideoStarsListArgs = {
  condition?: InputMaybe<VideoStarCondition>;
  filter?: InputMaybe<VideoStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVideosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryVideosListArgs = {
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopPresetArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopPresetByNodeIdArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopPresetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkshopPresetCondition>;
  filter?: InputMaybe<WorkshopPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopPresetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopPresetsListArgs = {
  condition?: InputMaybe<WorkshopPresetCondition>;
  filter?: InputMaybe<WorkshopPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopPresetsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopSurveyStatisticsArgs = {
  accountId?: InputMaybe<Scalars['UUID']>;
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  divisionId?: InputMaybe<Scalars['UUID']>;
  gender?: InputMaybe<Gender>;
  industryId?: InputMaybe<Scalars['UUID']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopsCalendarArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  filter?: InputMaybe<WorkshopsCalendarRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopsCalendarListArgs = {
  filter?: InputMaybe<WorkshopsCalendarRecordFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryWorkshopsListArgs = {
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

export enum RecurringInterval {
  Annual = 'ANNUAL',
  BiAnnual = 'BI_ANNUAL',
  BiMonthly = 'BI_MONTHLY',
  BiWeekly = 'BI_WEEKLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE',
  Quarterly = 'QUARTERLY',
  SemiAnnual = 'SEMI_ANNUAL',
  Weekly = 'WEEKLY'
}

/** A filter to be used against RecurringInterval fields. All fields are combined with a logical ‘and.’ */
export type RecurringIntervalFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<RecurringInterval>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<RecurringInterval>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<RecurringInterval>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<RecurringInterval>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<RecurringInterval>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<RecurringInterval>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<RecurringInterval>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<RecurringInterval>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<RecurringInterval>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<RecurringInterval>>;
};

/** All input for the `resetPassword` mutation. */
export type ResetPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
  userId: Scalars['UUID'];
};

/** The output of our `resetPassword` mutation. */
export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

export enum RiskLevel {
  High = 'HIGH',
  Low = 'LOW',
  Moderate = 'MODERATE',
  VeryHigh = 'VERY_HIGH',
  VeryLow = 'VERY_LOW'
}

/** A filter to be used against RiskLevel fields. All fields are combined with a logical ‘and.’ */
export type RiskLevelFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<RiskLevel>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<RiskLevel>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<RiskLevel>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<RiskLevel>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<RiskLevel>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<RiskLevel>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<RiskLevel>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<RiskLevel>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<RiskLevel>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<RiskLevel>>;
};

/** All input for the `saveProjectConnection` mutation. */
export type SaveProjectConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  connectionData: ConnectionFormInput;
  connectionRole: ConnectionRole;
  decisionMaker: ConnectionDecisionMaker;
  keyDecisionMaker: Scalars['Boolean'];
  projectId: Scalars['UUID'];
};

/** The output of our `saveProjectConnection` mutation. */
export type SaveProjectConnectionPayload = {
  __typename?: 'SaveProjectConnectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Connection` that is related to this `ProjectConnection`. */
  connection: Maybe<Connection>;
  /** Reads a single `Project` that is related to this `ProjectConnection`. */
  project: Maybe<Project>;
  projectConnection: Maybe<ProjectConnection>;
  /** An edge for our `ProjectConnection`. May be used by Relay 1. */
  projectConnectionEdge: Maybe<ProjectConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our `saveProjectConnection` mutation. */
export type SaveProjectConnectionPayloadProjectConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};

/** All input for the `sendMagicLink` mutation. */
export type SendMagicLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['UUID'];
};

/** The output of our `sendMagicLink` mutation. */
export type SendMagicLinkPayload = {
  __typename?: 'SendMagicLinkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** All input for the `signin` mutation. */
export type SigninInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
  password: Scalars['String'];
};

/** The output of our `signin` mutation. */
export type SigninPayload = {
  __typename?: 'SigninPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `signinWithMagicLink` mutation. */
export type SigninWithMagicLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  uid: Scalars['UUID'];
};

/** The output of our `signinWithMagicLink` mutation. */
export type SigninWithMagicLinkPayload = {
  __typename?: 'SigninWithMagicLinkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `signinWithTempLink` mutation. */
export type SigninWithTempLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
  uid: Scalars['UUID'];
};

/** The output of our `signinWithTempLink` mutation. */
export type SigninWithTempLinkPayload = {
  __typename?: 'SigninWithTempLinkPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** All input for the `signup` mutation. */
export type SignupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
  fullname: Scalars['String'];
  password: Scalars['String'];
};

/** The output of our `signup` mutation. */
export type SignupPayload = {
  __typename?: 'SignupPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

export type StatsItemPercentage = {
  __typename?: 'StatsItemPercentage';
  itemName: Maybe<Scalars['String']>;
  occurrenceCount: Maybe<Scalars['BigInt']>;
  percentage: Maybe<Scalars['BigFloat']>;
};

/** A connection to a list of `StatsItemPercentage` values. */
export type StatsItemPercentagesConnection = {
  __typename?: 'StatsItemPercentagesConnection';
  /** A list of edges which contains the `StatsItemPercentage` and cursor to aid in pagination. */
  edges: Array<StatsItemPercentagesEdge>;
  /** A list of `StatsItemPercentage` objects. */
  nodes: Array<StatsItemPercentage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `StatsItemPercentage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `StatsItemPercentage` edge in the connection. */
export type StatsItemPercentagesEdge = {
  __typename?: 'StatsItemPercentagesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `StatsItemPercentage` at the end of the edge. */
  node: StatsItemPercentage;
};

export type StatsPowerState = {
  __typename?: 'StatsPowerState';
  lessPower: Maybe<Scalars['BigFloat']>;
  morePower: Maybe<Scalars['BigFloat']>;
  sharedPower: Maybe<Scalars['BigFloat']>;
  significantlyLessPower: Maybe<Scalars['BigFloat']>;
  significantlyMorePower: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']>;
};

/** All input for the `submitFeedbackAnswers` mutation. */
export type SubmitFeedbackAnswersInput = {
  answers: Array<InputMaybe<FeedbackResponseAnswerInput>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  v1WorkshopId: Scalars['Int'];
};

/** The output of our `submitFeedbackAnswers` mutation. */
export type SubmitFeedbackAnswersPayload = {
  __typename?: 'SubmitFeedbackAnswersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** All input for the `submitSurveyAnswers` mutation. */
export type SubmitSurveyAnswersInput = {
  answers: Array<InputMaybe<SurveyResponseAnswerInput>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  surveyType: SurveyType;
  v1WorkshopId: Scalars['Int'];
};

/** The output of our `submitSurveyAnswers` mutation. */
export type SubmitSurveyAnswersPayload = {
  __typename?: 'SubmitSurveyAnswersPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  accountUpdated: Maybe<AccountSubscriptionPayload>;
  massUserUploadUpdated: Maybe<MassUserUploadSubscriptionPayload>;
  projectCommentsUpdated: Maybe<ProjectCommentSubscriptionPayload>;
  projectUpdated: Maybe<ProjectSubscriptionPayload>;
  userUpdated: Maybe<UserSubscriptionPayload>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionAccountUpdatedArgs = {
  accountId: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionMassUserUploadUpdatedArgs = {
  id: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionProjectCommentsUpdatedArgs = {
  projectId: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionProjectUpdatedArgs = {
  projectId: Scalars['UUID'];
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionUserUpdatedArgs = {
  userId: Scalars['UUID'];
};

/** The list of all 8 buckets that survey questions belong to */
export type SurveyBucket = Node & {
  __typename?: 'SurveyBucket';
  id: Scalars['UUID'];
  /** The name of the survey bucket */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The ID of the bucket in v1.buckets table */
  v1Id: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `SurveyBucket` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SurveyBucketCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `v1Id` field. */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `SurveyBucket` object types. All fields are combined with a logical ‘and.’ */
export type SurveyBucketFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SurveyBucketFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SurveyBucketFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SurveyBucketFilter>>;
  /** Filter by the object’s `v1Id` field. */
  v1Id?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SurveyBucket` */
export type SurveyBucketInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the survey bucket */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the bucket in v1.buckets table */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `SurveyBucket`. Fields that are set will be updated. */
export type SurveyBucketPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The name of the survey bucket */
  name?: InputMaybe<Scalars['String']>;
  /** The ID of the bucket in v1.buckets table */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `SurveyBucket` values. */
export type SurveyBucketsConnection = {
  __typename?: 'SurveyBucketsConnection';
  /** A list of edges which contains the `SurveyBucket` and cursor to aid in pagination. */
  edges: Array<SurveyBucketsEdge>;
  /** A list of `SurveyBucket` objects. */
  nodes: Array<SurveyBucket>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SurveyBucket` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SurveyBucket` edge in the connection. */
export type SurveyBucketsEdge = {
  __typename?: 'SurveyBucketsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `SurveyBucket` at the end of the edge. */
  node: SurveyBucket;
};

/** Methods to use when ordering `SurveyBucket`. */
export enum SurveyBucketsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  V1IdAsc = 'V1_ID_ASC',
  V1IdDesc = 'V1_ID_DESC'
}

export type SurveyQuestion = Node & {
  __typename?: 'SurveyQuestion';
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The text of the question */
  question: Maybe<Scalars['String']>;
  /** What type of scoring is used for the particular question */
  questionType: Maybe<SurveyQuestionType>;
  /** Bucket of the Survey */
  surveyBucketId: Maybe<Scalars['UUID']>;
  /** Which survey the question belongs to */
  surveyType: Maybe<SurveyType>;
  /** The ID of the question in v1.survey_questions table */
  v1Id: Maybe<Scalars['Int']>;
};

/** Survey answers */
export type SurveyQuestionAnswer = Node & {
  __typename?: 'SurveyQuestionAnswer';
  /** The agreement level for a specific question, should not be set when free_text is used */
  answer: Maybe<SurveyQuestionAnswerValue>;
  /** This field is used only for Peer Feedback Survey where we have such questions. In these questions answer field is not used */
  freeText: Maybe<Scalars['String']>;
  /** Id of this answer */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The question this answer is for */
  surveyQuestionId: Scalars['UUID'];
  /** The response this answer is part of */
  surveyResponseId: Scalars['UUID'];
};

/**
 * A condition to be used against `SurveyQuestionAnswer` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type SurveyQuestionAnswerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SurveyQuestionAnswer` object types. All fields are combined with a logical ‘and.’ */
export type SurveyQuestionAnswerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SurveyQuestionAnswerFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SurveyQuestionAnswerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SurveyQuestionAnswerFilter>>;
};

/** An input for mutations affecting `SurveyQuestionAnswer` */
export type SurveyQuestionAnswerInput = {
  /** The agreement level for a specific question, should not be set when free_text is used */
  answer?: InputMaybe<SurveyQuestionAnswerValue>;
  /** This field is used only for Peer Feedback Survey where we have such questions. In these questions answer field is not used */
  freeText?: InputMaybe<Scalars['String']>;
  /** Id of this answer */
  id?: InputMaybe<Scalars['UUID']>;
  /** The question this answer is for */
  surveyQuestionId: Scalars['UUID'];
  /** The response this answer is part of */
  surveyResponseId: Scalars['UUID'];
};

/** Represents an update to a `SurveyQuestionAnswer`. Fields that are set will be updated. */
export type SurveyQuestionAnswerPatch = {
  /** The agreement level for a specific question, should not be set when free_text is used */
  answer?: InputMaybe<SurveyQuestionAnswerValue>;
  /** This field is used only for Peer Feedback Survey where we have such questions. In these questions answer field is not used */
  freeText?: InputMaybe<Scalars['String']>;
  /** Id of this answer */
  id?: InputMaybe<Scalars['UUID']>;
  /** The question this answer is for */
  surveyQuestionId?: InputMaybe<Scalars['UUID']>;
  /** The response this answer is part of */
  surveyResponseId?: InputMaybe<Scalars['UUID']>;
};

/** Most survey questions have 5 degrees of agreement. These are the values we use for them */
export enum SurveyQuestionAnswerValue {
  Agree = 'AGREE',
  Disagree = 'DISAGREE',
  Neutral = 'NEUTRAL',
  StronglyAgree = 'STRONGLY_AGREE',
  StronglyDisagree = 'STRONGLY_DISAGREE'
}

/** A connection to a list of `SurveyQuestionAnswer` values. */
export type SurveyQuestionAnswersConnection = {
  __typename?: 'SurveyQuestionAnswersConnection';
  /** A list of edges which contains the `SurveyQuestionAnswer` and cursor to aid in pagination. */
  edges: Array<SurveyQuestionAnswersEdge>;
  /** A list of `SurveyQuestionAnswer` objects. */
  nodes: Array<SurveyQuestionAnswer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SurveyQuestionAnswer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SurveyQuestionAnswer` edge in the connection. */
export type SurveyQuestionAnswersEdge = {
  __typename?: 'SurveyQuestionAnswersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `SurveyQuestionAnswer` at the end of the edge. */
  node: SurveyQuestionAnswer;
};

/** Methods to use when ordering `SurveyQuestionAnswer`. */
export enum SurveyQuestionAnswersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/**
 * A condition to be used against `SurveyQuestion` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SurveyQuestionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `question` field. */
  question?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `surveyType` field. */
  surveyType?: InputMaybe<SurveyType>;
  /** Checks for equality with the object’s `v1Id` field. */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `SurveyQuestion` object types. All fields are combined with a logical ‘and.’ */
export type SurveyQuestionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SurveyQuestionFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SurveyQuestionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SurveyQuestionFilter>>;
  /** Filter by the object’s `question` field. */
  question?: InputMaybe<StringFilter>;
  /** Filter by the object’s `surveyType` field. */
  surveyType?: InputMaybe<SurveyTypeFilter>;
  /** Filter by the object’s `v1Id` field. */
  v1Id?: InputMaybe<IntFilter>;
};

/** An input for mutations affecting `SurveyQuestion` */
export type SurveyQuestionInput = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The text of the question */
  question?: InputMaybe<Scalars['String']>;
  /** What type of scoring is used for the particular question */
  questionType?: InputMaybe<SurveyQuestionType>;
  /** Bucket of the Survey */
  surveyBucketId?: InputMaybe<Scalars['UUID']>;
  /** Which survey the question belongs to */
  surveyType?: InputMaybe<SurveyType>;
  /** The ID of the question in v1.survey_questions table */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** Represents an update to a `SurveyQuestion`. Fields that are set will be updated. */
export type SurveyQuestionPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  /** The text of the question */
  question?: InputMaybe<Scalars['String']>;
  /** What type of scoring is used for the particular question */
  questionType?: InputMaybe<SurveyQuestionType>;
  /** Bucket of the Survey */
  surveyBucketId?: InputMaybe<Scalars['UUID']>;
  /** Which survey the question belongs to */
  surveyType?: InputMaybe<SurveyType>;
  /** The ID of the question in v1.survey_questions table */
  v1Id?: InputMaybe<Scalars['Int']>;
};

/** Questions that have an agreement score are either positively or negatively phrased */
export enum SurveyQuestionType {
  Negative = 'NEGATIVE',
  Positive = 'POSITIVE',
  Text = 'TEXT'
}

/** A connection to a list of `SurveyQuestion` values. */
export type SurveyQuestionsConnection = {
  __typename?: 'SurveyQuestionsConnection';
  /** A list of edges which contains the `SurveyQuestion` and cursor to aid in pagination. */
  edges: Array<SurveyQuestionsEdge>;
  /** A list of `SurveyQuestion` objects. */
  nodes: Array<SurveyQuestion>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SurveyQuestion` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SurveyQuestion` edge in the connection. */
export type SurveyQuestionsEdge = {
  __typename?: 'SurveyQuestionsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `SurveyQuestion` at the end of the edge. */
  node: SurveyQuestion;
};

/** Methods to use when ordering `SurveyQuestion`. */
export enum SurveyQuestionsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  QuestionAsc = 'QUESTION_ASC',
  QuestionDesc = 'QUESTION_DESC',
  SurveyTypeAsc = 'SURVEY_TYPE_ASC',
  SurveyTypeDesc = 'SURVEY_TYPE_DESC',
  V1IdAsc = 'V1_ID_ASC',
  V1IdDesc = 'V1_ID_DESC'
}

/** Stores sent status for workshop participants to fill in pre-workshop survey */
export type SurveyReminder = Node & {
  __typename?: 'SurveyReminder';
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  reminderType: Maybe<Scalars['String']>;
  /** The type the survey we sent a response for */
  surveyType: Maybe<SurveyType>;
  updatedAt: Scalars['Datetime'];
  /** The user who the reminder was sent to */
  userId: Maybe<Scalars['UUID']>;
  /** The id of the related Workshop in v1 */
  v1WorkshopId: Scalars['Int'];
};

/**
 * A condition to be used against `SurveyReminder` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SurveyReminderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SurveyReminder` object types. All fields are combined with a logical ‘and.’ */
export type SurveyReminderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SurveyReminderFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SurveyReminderFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SurveyReminderFilter>>;
};

/** An input for mutations affecting `SurveyReminder` */
export type SurveyReminderInput = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  reminderType?: InputMaybe<Scalars['String']>;
  /** The type the survey we sent a response for */
  surveyType?: InputMaybe<SurveyType>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The user who the reminder was sent to */
  userId?: InputMaybe<Scalars['UUID']>;
  /** The id of the related Workshop in v1 */
  v1WorkshopId: Scalars['Int'];
};

/** Represents an update to a `SurveyReminder`. Fields that are set will be updated. */
export type SurveyReminderPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  reminderType?: InputMaybe<Scalars['String']>;
  /** The type the survey we sent a response for */
  surveyType?: InputMaybe<SurveyType>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The user who the reminder was sent to */
  userId?: InputMaybe<Scalars['UUID']>;
  /** The id of the related Workshop in v1 */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `SurveyReminder` values. */
export type SurveyRemindersConnection = {
  __typename?: 'SurveyRemindersConnection';
  /** A list of edges which contains the `SurveyReminder` and cursor to aid in pagination. */
  edges: Array<SurveyRemindersEdge>;
  /** A list of `SurveyReminder` objects. */
  nodes: Array<SurveyReminder>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SurveyReminder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SurveyReminder` edge in the connection. */
export type SurveyRemindersEdge = {
  __typename?: 'SurveyRemindersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `SurveyReminder` at the end of the edge. */
  node: SurveyReminder;
};

/** Methods to use when ordering `SurveyReminder`. */
export enum SurveyRemindersOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type SurveyResponse = Node & {
  __typename?: 'SurveyResponse';
  /** When was this response created */
  createdAt: Scalars['Datetime'];
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Maybe<Scalars['UUID']>;
  /** Column that is computed by the backend based on the answers of all questions in the response */
  score: Maybe<Scalars['JSON']>;
  /** Which survey type the response is for */
  surveyType: Maybe<SurveyType>;
  /** When was this response last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `SurveyResponse`. */
  user: Maybe<User>;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
  /** The corresponding id to v1 workshops. Used to validate recipients for Peer Feedback Survey */
  v1WorkshopId: Maybe<Scalars['Int']>;
};

/** An input for mutations affecting `SurveyResponseAnswer` */
export type SurveyResponseAnswerInput = {
  answer?: InputMaybe<SurveyQuestionAnswerValue>;
  freeText?: InputMaybe<Scalars['String']>;
  surveyQuestionId?: InputMaybe<Scalars['UUID']>;
};

/**
 * A condition to be used against `SurveyResponse` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type SurveyResponseCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `SurveyResponse` object types. All fields are combined with a logical ‘and.’ */
export type SurveyResponseFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SurveyResponseFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SurveyResponseFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SurveyResponseFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `SurveyResponse` */
export type SurveyResponseInput = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Column that is computed by the backend based on the answers of all questions in the response */
  score?: InputMaybe<Scalars['JSON']>;
  /** Which survey type the response is for */
  surveyType?: InputMaybe<SurveyType>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The user that submitted the response, can only be the current logged-in user */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<SurveyResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate recipients for Peer Feedback Survey */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SurveyResponseNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `surveyResponse` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type SurveyResponseNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `surveyResponse` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `surveyResponse` to look up the row to update. */
export type SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyUsingSurveyResponsePkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `surveyResponse` being updated. */
  patch: UpdateSurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyPatch;
};

/** The fields on `surveyResponse` to look up the row to update. */
export type SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyUsingUniqSurveyResponseUpdate = {
  /** An object where the defined keys will be set on the `surveyResponse` being updated. */
  patch: UpdateSurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyPatch;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Scalars['UUID'];
  /** Which survey type the response is for */
  surveyType: SurveyType;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
};

/** Represents an update to a `SurveyResponse`. Fields that are set will be updated. */
export type SurveyResponsePatch = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Column that is computed by the backend based on the answers of all questions in the response */
  score?: InputMaybe<Scalars['JSON']>;
  /** Which survey type the response is for */
  surveyType?: InputMaybe<SurveyType>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** The user that submitted the response, can only be the current logged-in user */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<SurveyResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate recipients for Peer Feedback Survey */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** The fields on `surveyResponse` to look up the row to connect. */
export type SurveyResponseSurveyResponsePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `surveyResponse` to look up the row to delete. */
export type SurveyResponseSurveyResponsePkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `surveyResponse` to look up the row to connect. */
export type SurveyResponseUniqSurveyResponseConnect = {
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Scalars['UUID'];
  /** Which survey type the response is for */
  surveyType: SurveyType;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
};

/** The fields on `surveyResponse` to look up the row to delete. */
export type SurveyResponseUniqSurveyResponseDelete = {
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Scalars['UUID'];
  /** Which survey type the response is for */
  surveyType: SurveyType;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
};

/** Input for the nested mutation of `user` in the `SurveyResponseInput` mutation. */
export type SurveyResponseUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnSurveyResponseForSurveyResponseUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnSurveyResponseForSurveyResponseUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `surveyResponse` in the `UserInput` mutation. */
export type SurveyResponseUserIdFkeyInverseInput = {
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  connectById?: InputMaybe<Array<SurveyResponseSurveyResponsePkeyConnect>>;
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<SurveyResponseNodeIdConnect>>;
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  connectByUserIdAndRecipientIdAndSurveyType?: InputMaybe<Array<SurveyResponseUniqSurveyResponseConnect>>;
  /** A `SurveyResponseInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<SurveyResponseUserIdFkeySurveyResponseCreateInput>>;
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<SurveyResponseSurveyResponsePkeyDelete>>;
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<SurveyResponseNodeIdDelete>>;
  /** The primary key(s) for `surveyResponse` for the far side of the relationship. */
  deleteByUserIdAndRecipientIdAndSurveyType?: InputMaybe<Array<SurveyResponseUniqSurveyResponseDelete>>;
  /** Flag indicating whether all other `surveyResponse` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `surveyResponse` for the far side of the relationship. */
  updateById?: InputMaybe<Array<SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyUsingSurveyResponsePkeyUpdate>>;
  /** The primary key(s) and patch data for `surveyResponse` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnSurveyResponseForSurveyResponseUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `surveyResponse` for the far side of the relationship. */
  updateByUserIdAndRecipientIdAndSurveyType?: InputMaybe<Array<SurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyUsingUniqSurveyResponseUpdate>>;
};

/** The `surveyResponse` to be created by this mutation. */
export type SurveyResponseUserIdFkeySurveyResponseCreateInput = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Column that is computed by the backend based on the answers of all questions in the response */
  score?: InputMaybe<Scalars['JSON']>;
  /** Which survey type the response is for */
  surveyType?: InputMaybe<SurveyType>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<SurveyResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate recipients for Peer Feedback Survey */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** A connection to a list of `SurveyResponse` values. */
export type SurveyResponsesConnection = {
  __typename?: 'SurveyResponsesConnection';
  /** A list of edges which contains the `SurveyResponse` and cursor to aid in pagination. */
  edges: Array<SurveyResponsesEdge>;
  /** A list of `SurveyResponse` objects. */
  nodes: Array<SurveyResponse>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `SurveyResponse` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `SurveyResponse` edge in the connection. */
export type SurveyResponsesEdge = {
  __typename?: 'SurveyResponsesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `SurveyResponse` at the end of the edge. */
  node: SurveyResponse;
};

/** Methods to use when ordering `SurveyResponse`. */
export enum SurveyResponsesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

/** List of all the types of surveys we support */
export enum SurveyType {
  Lv2PreWorkshopSurvey = 'LV2_PRE_WORKSHOP_SURVEY',
  Lv3PreWorkshopSurvey = 'LV3_PRE_WORKSHOP_SURVEY',
  PeerFeedbackSurvey = 'PEER_FEEDBACK_SURVEY'
}

/** A filter to be used against SurveyType fields. All fields are combined with a logical ‘and.’ */
export type SurveyTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SurveyType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SurveyType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SurveyType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SurveyType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SurveyType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SurveyType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SurveyType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SurveyType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SurveyType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SurveyType>>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']>>;
};

/** All input for the `updateAccountByName` mutation. */
export type UpdateAccountByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Name of the account */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Account` being updated. */
  patch: AccountPatch;
};

/** All input for the `updateAccountByNodeId` mutation. */
export type UpdateAccountByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Account` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Account` being updated. */
  patch: AccountPatch;
};

/** All input for the `updateAccount` mutation. */
export type UpdateAccountInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for this account */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Account` being updated. */
  patch: AccountPatch;
};

/** The output of our update `Account` mutation. */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  /** The `Account` that was updated by this mutation. */
  account: Maybe<Account>;
  /** An edge for our `Account`. May be used by Relay 1. */
  accountEdge: Maybe<AccountsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Account` mutation. */
export type UpdateAccountPayloadAccountEdgeArgs = {
  orderBy?: InputMaybe<Array<AccountsOrderBy>>;
};

/** All input for the `updateActivityByNodeId` mutation. */
export type UpdateActivityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Activity` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Activity` being updated. */
  patch: ActivityPatch;
};

/** All input for the `updateActivity` mutation. */
export type UpdateActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the activity */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Activity` being updated. */
  patch: ActivityPatch;
};

/** The output of our update `Activity` mutation. */
export type UpdateActivityPayload = {
  __typename?: 'UpdateActivityPayload';
  /** The `Activity` that was updated by this mutation. */
  activity: Maybe<Activity>;
  /** An edge for our `Activity`. May be used by Relay 1. */
  activityEdge: Maybe<ActivitiesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Activity` mutation. */
export type UpdateActivityPayloadActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<ActivitiesOrderBy>>;
};

/** All input for the `updateBriefByNodeId` mutation. */
export type UpdateBriefByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Brief` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Brief` being updated. */
  patch: BriefPatch;
};

/** All input for the `updateBriefGroupByNodeId` mutation. */
export type UpdateBriefGroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BriefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** All input for the `updateBriefGroup` mutation. */
export type UpdateBriefGroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief group */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BriefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** The output of our update `BriefGroup` mutation. */
export type UpdateBriefGroupPayload = {
  __typename?: 'UpdateBriefGroupPayload';
  /** Reads a single `Activity` that is related to this `BriefGroup`. */
  activity: Maybe<Activity>;
  /** The `BriefGroup` that was updated by this mutation. */
  briefGroup: Maybe<BriefGroup>;
  /** An edge for our `BriefGroup`. May be used by Relay 1. */
  briefGroupEdge: Maybe<BriefGroupsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `UserLanguage` that is related to this `BriefGroup`. */
  userLanguageByLanguageCode: Maybe<UserLanguage>;
};


/** The output of our update `BriefGroup` mutation. */
export type UpdateBriefGroupPayloadBriefGroupEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};

/** All input for the `updateBriefGroupTypeMappingByNodeId` mutation. */
export type UpdateBriefGroupTypeMappingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroupTypeMapping` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BriefGroupTypeMapping` being updated. */
  patch: BriefGroupTypeMappingPatch;
};

/** All input for the `updateBriefGroupTypeMapping` mutation. */
export type UpdateBriefGroupTypeMappingInput = {
  /** Related Brief Group Type */
  briefGroupType: BriefGroupType;
  /** Related Brief Variant */
  briefVariant: BriefVariant;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `BriefGroupTypeMapping` being updated. */
  patch: BriefGroupTypeMappingPatch;
};

/** The output of our update `BriefGroupTypeMapping` mutation. */
export type UpdateBriefGroupTypeMappingPayload = {
  __typename?: 'UpdateBriefGroupTypeMappingPayload';
  /** The `BriefGroupTypeMapping` that was updated by this mutation. */
  briefGroupTypeMapping: Maybe<BriefGroupTypeMapping>;
  /** An edge for our `BriefGroupTypeMapping`. May be used by Relay 1. */
  briefGroupTypeMappingEdge: Maybe<BriefGroupTypeMappingsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `BriefGroupTypeMapping` mutation. */
export type UpdateBriefGroupTypeMappingPayloadBriefGroupTypeMappingEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupTypeMappingsOrderBy>>;
};

/** All input for the `updateBriefGroupVariableByNodeId` mutation. */
export type UpdateBriefGroupVariableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroupVariable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BriefGroupVariable` being updated. */
  patch: BriefGroupVariablePatch;
};

/** All input for the `updateBriefGroupVariable` mutation. */
export type UpdateBriefGroupVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BriefGroupVariable` being updated. */
  patch: BriefGroupVariablePatch;
};

/** All input for the `updateBriefGroupVariableOptionByNodeId` mutation. */
export type UpdateBriefGroupVariableOptionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefGroupVariableOption` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BriefGroupVariableOption` being updated. */
  patch: BriefGroupVariableOptionPatch;
};

/** All input for the `updateBriefGroupVariableOption` mutation. */
export type UpdateBriefGroupVariableOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief group Variable */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BriefGroupVariableOption` being updated. */
  patch: BriefGroupVariableOptionPatch;
};

/** The output of our update `BriefGroupVariableOption` mutation. */
export type UpdateBriefGroupVariableOptionPayload = {
  __typename?: 'UpdateBriefGroupVariableOptionPayload';
  /** The `BriefGroupVariableOption` that was updated by this mutation. */
  briefGroupVariableOption: Maybe<BriefGroupVariableOption>;
  /** An edge for our `BriefGroupVariableOption`. May be used by Relay 1. */
  briefGroupVariableOptionEdge: Maybe<BriefGroupVariableOptionsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `BriefGroupVariable` that is related to this `BriefGroupVariableOption`. */
  variable: Maybe<BriefGroupVariable>;
};


/** The output of our update `BriefGroupVariableOption` mutation. */
export type UpdateBriefGroupVariableOptionPayloadBriefGroupVariableOptionEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupVariableOptionsOrderBy>>;
};

/** The output of our update `BriefGroupVariable` mutation. */
export type UpdateBriefGroupVariablePayload = {
  __typename?: 'UpdateBriefGroupVariablePayload';
  /** Reads a single `BriefGroup` that is related to this `BriefGroupVariable`. */
  briefGroup: Maybe<BriefGroup>;
  /** The `BriefGroupVariable` that was updated by this mutation. */
  briefGroupVariable: Maybe<BriefGroupVariable>;
  /** An edge for our `BriefGroupVariable`. May be used by Relay 1. */
  briefGroupVariableEdge: Maybe<BriefGroupVariablesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `BriefGroupVariable` mutation. */
export type UpdateBriefGroupVariablePayloadBriefGroupVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefGroupVariablesOrderBy>>;
};

/** All input for the `updateBrief` mutation. */
export type UpdateBriefInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `Brief` being updated. */
  patch: BriefPatch;
};

/** The output of our update `Brief` mutation. */
export type UpdateBriefPayload = {
  __typename?: 'UpdateBriefPayload';
  /** The `Brief` that was updated by this mutation. */
  brief: Maybe<Brief>;
  /** An edge for our `Brief`. May be used by Relay 1. */
  briefEdge: Maybe<BriefsEdge>;
  /** Reads a single `BriefGroup` that is related to this `Brief`. */
  briefGroup: Maybe<BriefGroup>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Brief` mutation. */
export type UpdateBriefPayloadBriefEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefsOrderBy>>;
};

/** All input for the `updateBriefPresetByNodeId` mutation. */
export type UpdateBriefPresetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `BriefPreset` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `BriefPreset` being updated. */
  patch: BriefPresetPatch;
};

/** All input for the `updateBriefPreset` mutation. */
export type UpdateBriefPresetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `BriefPreset` being updated. */
  patch: BriefPresetPatch;
};

/** The output of our update `BriefPreset` mutation. */
export type UpdateBriefPresetPayload = {
  __typename?: 'UpdateBriefPresetPayload';
  /** The `BriefPreset` that was updated by this mutation. */
  briefPreset: Maybe<BriefPreset>;
  /** An edge for our `BriefPreset`. May be used by Relay 1. */
  briefPresetEdge: Maybe<BriefPresetsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `WorkshopPreset` that is related to this `BriefPreset`. */
  workshopPreset: Maybe<WorkshopPreset>;
};


/** The output of our update `BriefPreset` mutation. */
export type UpdateBriefPresetPayloadBriefPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};

/** All input for the `updateClassAssetCategoryByNodeId` mutation. */
export type UpdateClassAssetCategoryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassAssetCategory` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassAssetCategory` being updated. */
  patch: ClassAssetCategoryPatch;
};

/** All input for the `updateClassAssetCategory` mutation. */
export type UpdateClassAssetCategoryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassAssetCategory` being updated. */
  patch: ClassAssetCategoryPatch;
};

/** The output of our update `ClassAssetCategory` mutation. */
export type UpdateClassAssetCategoryPayload = {
  __typename?: 'UpdateClassAssetCategoryPayload';
  /** The `ClassAssetCategory` that was updated by this mutation. */
  classAssetCategory: Maybe<ClassAssetCategory>;
  /** An edge for our `ClassAssetCategory`. May be used by Relay 1. */
  classAssetCategoryEdge: Maybe<ClassAssetCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ClassAssetCategory` mutation. */
export type UpdateClassAssetCategoryPayloadClassAssetCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassAssetCategoriesOrderBy>>;
};

/** All input for the `updateClassByNodeId` mutation. */
export type UpdateClassByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Class` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Class` being updated. */
  patch: ClassPatch;
};

/** All input for the `updateClassDocumentByClassIdAndDocumentId` mutation. */
export type UpdateClassDocumentByClassIdAndDocumentIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassDocument` being updated. */
  patch: ClassDocumentPatch;
};

/** All input for the `updateClassDocumentByNodeId` mutation. */
export type UpdateClassDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassDocument` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassDocument` being updated. */
  patch: ClassDocumentPatch;
};

/** All input for the `updateClassDocument` mutation. */
export type UpdateClassDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassDocument` being updated. */
  patch: ClassDocumentPatch;
};

/** The output of our update `ClassDocument` mutation. */
export type UpdateClassDocumentPayload = {
  __typename?: 'UpdateClassDocumentPayload';
  /** Reads a single `Class` that is related to this `ClassDocument`. */
  class: Maybe<Class>;
  /** The `ClassDocument` that was updated by this mutation. */
  classDocument: Maybe<ClassDocument>;
  /** An edge for our `ClassDocument`. May be used by Relay 1. */
  classDocumentEdge: Maybe<ClassDocumentsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Document` that is related to this `ClassDocument`. */
  document: Maybe<Document>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ClassDocument` mutation. */
export type UpdateClassDocumentPayloadClassDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassDocumentsOrderBy>>;
};

/** All input for the `updateClass` mutation. */
export type UpdateClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Class` being updated. */
  patch: ClassPatch;
};

/** The output of our update `Class` mutation. */
export type UpdateClassPayload = {
  __typename?: 'UpdateClassPayload';
  /** Reads a single `Account` that is related to this `Class`. */
  account: Maybe<Account>;
  /** The `Class` that was updated by this mutation. */
  class: Maybe<Class>;
  /** An edge for our `Class`. May be used by Relay 1. */
  classEdge: Maybe<ClassesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Class`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our update `Class` mutation. */
export type UpdateClassPayloadClassEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};

/** All input for the `updateClassStarByClassIdAndUserId` mutation. */
export type UpdateClassStarByClassIdAndUserIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ClassStar` being updated. */
  patch: ClassStarPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateClassStarByNodeId` mutation. */
export type UpdateClassStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassStar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassStar` being updated. */
  patch: ClassStarPatch;
};

/** All input for the `updateClassStar` mutation. */
export type UpdateClassStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassStar` being updated. */
  patch: ClassStarPatch;
};

/** The output of our update `ClassStar` mutation. */
export type UpdateClassStarPayload = {
  __typename?: 'UpdateClassStarPayload';
  /** Reads a single `Class` that is related to this `ClassStar`. */
  class: Maybe<Class>;
  /** The `ClassStar` that was updated by this mutation. */
  classStar: Maybe<ClassStar>;
  /** An edge for our `ClassStar`. May be used by Relay 1. */
  classStarEdge: Maybe<ClassStarsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ClassStar` mutation. */
export type UpdateClassStarPayloadClassStarEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassStarsOrderBy>>;
};

/** All input for the `updateClassUserByClassIdAndUserId` mutation. */
export type UpdateClassUserByClassIdAndUserIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ClassUser` being updated. */
  patch: ClassUserPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateClassUserByNodeId` mutation. */
export type UpdateClassUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassUser` being updated. */
  patch: ClassUserPatch;
};

/** All input for the `updateClassUser` mutation. */
export type UpdateClassUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassUser` being updated. */
  patch: ClassUserPatch;
};

/** The output of our update `ClassUser` mutation. */
export type UpdateClassUserPayload = {
  __typename?: 'UpdateClassUserPayload';
  /** Reads a single `Class` that is related to this `ClassUser`. */
  class: Maybe<Class>;
  /** The `ClassUser` that was updated by this mutation. */
  classUser: Maybe<ClassUser>;
  /** An edge for our `ClassUser`. May be used by Relay 1. */
  classUserEdge: Maybe<ClassUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassUser`. */
  user: Maybe<User>;
};


/** The output of our update `ClassUser` mutation. */
export type UpdateClassUserPayloadClassUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};

/** All input for the `updateClassVideoByClassIdAndVideoId` mutation. */
export type UpdateClassVideoByClassIdAndVideoIdInput = {
  classId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ClassVideo` being updated. */
  patch: ClassVideoPatch;
  videoId: Scalars['UUID'];
};

/** All input for the `updateClassVideoByNodeId` mutation. */
export type UpdateClassVideoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassVideo` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassVideo` being updated. */
  patch: ClassVideoPatch;
};

/** All input for the `updateClassVideo` mutation. */
export type UpdateClassVideoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassVideo` being updated. */
  patch: ClassVideoPatch;
};

/** The output of our update `ClassVideo` mutation. */
export type UpdateClassVideoPayload = {
  __typename?: 'UpdateClassVideoPayload';
  /** Reads a single `Class` that is related to this `ClassVideo`. */
  class: Maybe<Class>;
  /** The `ClassVideo` that was updated by this mutation. */
  classVideo: Maybe<ClassVideo>;
  /** An edge for our `ClassVideo`. May be used by Relay 1. */
  classVideoEdge: Maybe<ClassVideosEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `ClassVideo`. */
  video: Maybe<Video>;
};


/** The output of our update `ClassVideo` mutation. */
export type UpdateClassVideoPayloadClassVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};

/** All input for the `updateClassVideoUserByClassVideoIdAndUserId` mutation. */
export type UpdateClassVideoUserByClassVideoIdAndUserIdInput = {
  classVideoId: Scalars['UUID'];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ClassVideoUser` being updated. */
  patch: ClassVideoUserPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateClassVideoUserByNodeId` mutation. */
export type UpdateClassVideoUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ClassVideoUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ClassVideoUser` being updated. */
  patch: ClassVideoUserPatch;
};

/** All input for the `updateClassVideoUser` mutation. */
export type UpdateClassVideoUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ClassVideoUser` being updated. */
  patch: ClassVideoUserPatch;
};

/** The output of our update `ClassVideoUser` mutation. */
export type UpdateClassVideoUserPayload = {
  __typename?: 'UpdateClassVideoUserPayload';
  /** Reads a single `ClassVideo` that is related to this `ClassVideoUser`. */
  classVideo: Maybe<ClassVideo>;
  /** The `ClassVideoUser` that was updated by this mutation. */
  classVideoUser: Maybe<ClassVideoUser>;
  /** An edge for our `ClassVideoUser`. May be used by Relay 1. */
  classVideoUserEdge: Maybe<ClassVideoUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ClassVideoUser`. */
  user: Maybe<User>;
};


/** The output of our update `ClassVideoUser` mutation. */
export type UpdateClassVideoUserPayloadClassVideoUserEdgeArgs = {
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};

/** All input for the `updateConnectionByNodeId` mutation. */
export type UpdateConnectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Connection` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Connection` being updated. */
  patch: ConnectionPatch;
};

/** All input for the `updateConnection` mutation. */
export type UpdateConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Connection` being updated. */
  patch: ConnectionPatch;
};

/** The output of our update `Connection` mutation. */
export type UpdateConnectionPayload = {
  __typename?: 'UpdateConnectionPayload';
  /** Reads a single `Account` that is related to this `Connection`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Connection` that was updated by this mutation. */
  connection: Maybe<Connection>;
  /** An edge for our `Connection`. May be used by Relay 1. */
  connectionEdge: Maybe<ConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Connection`. */
  user: Maybe<User>;
};


/** The output of our update `Connection` mutation. */
export type UpdateConnectionPayloadConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};

/** All input for the `updateCountryRegionByName` mutation. */
export type UpdateCountryRegionByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the Country/Region */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `CountryRegion` being updated. */
  patch: CountryRegionPatch;
};

/** All input for the `updateCountryRegionByNodeId` mutation. */
export type UpdateCountryRegionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CountryRegion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CountryRegion` being updated. */
  patch: CountryRegionPatch;
};

/** All input for the `updateCountryRegion` mutation. */
export type UpdateCountryRegionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `CountryRegion` being updated. */
  patch: CountryRegionPatch;
};

/** The output of our update `CountryRegion` mutation. */
export type UpdateCountryRegionPayload = {
  __typename?: 'UpdateCountryRegionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `CountryRegion` that was updated by this mutation. */
  countryRegion: Maybe<CountryRegion>;
  /** An edge for our `CountryRegion`. May be used by Relay 1. */
  countryRegionEdge: Maybe<CountryRegionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `CountryRegion` mutation. */
export type UpdateCountryRegionPayloadCountryRegionEdgeArgs = {
  orderBy?: InputMaybe<Array<CountryRegionsOrderBy>>;
};

/** All input for the `updateCustomerByNodeId` mutation. */
export type UpdateCustomerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Customer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Customer` being updated. */
  patch: CustomerPatch;
};

/** All input for the `updateCustomer` mutation. */
export type UpdateCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for this customer */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Customer` being updated. */
  patch: CustomerPatch;
};

/** The output of our update `Customer` mutation. */
export type UpdateCustomerPayload = {
  __typename?: 'UpdateCustomerPayload';
  /** Reads a single `Account` that is related to this `Customer`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Customer` that was updated by this mutation. */
  customer: Maybe<Customer>;
  /** An edge for our `Customer`. May be used by Relay 1. */
  customerEdge: Maybe<CustomersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Customer` mutation. */
export type UpdateCustomerPayloadCustomerEdgeArgs = {
  orderBy?: InputMaybe<Array<CustomersOrderBy>>;
};

/** All input for the `updateDivisionByNameAndV1Id` mutation. */
export type UpdateDivisionByNameAndV1IdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the division */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Division` being updated. */
  patch: DivisionPatch;
  /** The ID of the division in v1 */
  v1Id: Scalars['Int'];
};

/** All input for the `updateDivisionByNodeId` mutation. */
export type UpdateDivisionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Division` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Division` being updated. */
  patch: DivisionPatch;
};

/** All input for the `updateDivision` mutation. */
export type UpdateDivisionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Division` being updated. */
  patch: DivisionPatch;
};

/** The output of our update `Division` mutation. */
export type UpdateDivisionPayload = {
  __typename?: 'UpdateDivisionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Division` that was updated by this mutation. */
  division: Maybe<Division>;
  /** An edge for our `Division`. May be used by Relay 1. */
  divisionEdge: Maybe<DivisionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Division` mutation. */
export type UpdateDivisionPayloadDivisionEdgeArgs = {
  orderBy?: InputMaybe<Array<DivisionsOrderBy>>;
};

/** All input for the `updateDocumentByNodeId` mutation. */
export type UpdateDocumentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Document` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Document` being updated. */
  patch: DocumentPatch;
};

/** All input for the `updateDocument` mutation. */
export type UpdateDocumentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Document` being updated. */
  patch: DocumentPatch;
};

/** The output of our update `Document` mutation. */
export type UpdateDocumentPayload = {
  __typename?: 'UpdateDocumentPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Document`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Document` that was updated by this mutation. */
  document: Maybe<Document>;
  /** An edge for our `Document`. May be used by Relay 1. */
  documentEdge: Maybe<DocumentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Document`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our update `Document` mutation. */
export type UpdateDocumentPayloadDocumentEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};

/** All input for the `updateDocumentStarByDocumentIdAndUserId` mutation. */
export type UpdateDocumentStarByDocumentIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  documentId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `DocumentStar` being updated. */
  patch: DocumentStarPatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateDocumentStarByNodeId` mutation. */
export type UpdateDocumentStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `DocumentStar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `DocumentStar` being updated. */
  patch: DocumentStarPatch;
};

/** All input for the `updateDocumentStar` mutation. */
export type UpdateDocumentStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `DocumentStar` being updated. */
  patch: DocumentStarPatch;
};

/** The output of our update `DocumentStar` mutation. */
export type UpdateDocumentStarPayload = {
  __typename?: 'UpdateDocumentStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Document` that is related to this `DocumentStar`. */
  document: Maybe<Document>;
  /** The `DocumentStar` that was updated by this mutation. */
  documentStar: Maybe<DocumentStar>;
  /** An edge for our `DocumentStar`. May be used by Relay 1. */
  documentStarEdge: Maybe<DocumentStarsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `DocumentStar` mutation. */
export type UpdateDocumentStarPayloadDocumentStarEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentStarsOrderBy>>;
};

/** All input for the `updateEventTypeByNodeId` mutation. */
export type UpdateEventTypeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `EventType` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `EventType` being updated. */
  patch: EventTypePatch;
};

/** All input for the `updateEventType` mutation. */
export type UpdateEventTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `EventType` being updated. */
  patch: EventTypePatch;
  /** Event type title */
  title: Scalars['String'];
};

/** The output of our update `EventType` mutation. */
export type UpdateEventTypePayload = {
  __typename?: 'UpdateEventTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `EventType` that was updated by this mutation. */
  eventType: Maybe<EventType>;
  /** An edge for our `EventType`. May be used by Relay 1. */
  eventTypeEdge: Maybe<EventTypesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `EventType` mutation. */
export type UpdateEventTypePayloadEventTypeEdgeArgs = {
  orderBy?: InputMaybe<Array<EventTypesOrderBy>>;
};

/** All input for the `updateFeedbackQuestionByNodeId` mutation. */
export type UpdateFeedbackQuestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `FeedbackQuestion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `FeedbackQuestion` being updated. */
  patch: FeedbackQuestionPatch;
};

/** All input for the `updateFeedbackQuestionByNumberAndQuestion` mutation. */
export type UpdateFeedbackQuestionByNumberAndQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The number of the question as it appears for the user, lower number goes first */
  number: Scalars['Int'];
  /** An object where the defined keys will be set on the `FeedbackQuestion` being updated. */
  patch: FeedbackQuestionPatch;
  /** The text of the question */
  question: Scalars['String'];
};

/** All input for the `updateFeedbackQuestion` mutation. */
export type UpdateFeedbackQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `FeedbackQuestion` being updated. */
  patch: FeedbackQuestionPatch;
};

/** The output of our update `FeedbackQuestion` mutation. */
export type UpdateFeedbackQuestionPayload = {
  __typename?: 'UpdateFeedbackQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `FeedbackQuestion` that was updated by this mutation. */
  feedbackQuestion: Maybe<FeedbackQuestion>;
  /** An edge for our `FeedbackQuestion`. May be used by Relay 1. */
  feedbackQuestionEdge: Maybe<FeedbackQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `FeedbackQuestion` mutation. */
export type UpdateFeedbackQuestionPayloadFeedbackQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<FeedbackQuestionsOrderBy>>;
};

/** All input for the `updateGoogleAccountUserByNodeId` mutation. */
export type UpdateGoogleAccountUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleAccountUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GoogleAccountUser` being updated. */
  patch: GoogleAccountUserPatch;
};

/** All input for the `updateGoogleAccountUser` mutation. */
export type UpdateGoogleAccountUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `GoogleAccountUser` being updated. */
  patch: GoogleAccountUserPatch;
};

/** The output of our update `GoogleAccountUser` mutation. */
export type UpdateGoogleAccountUserPayload = {
  __typename?: 'UpdateGoogleAccountUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `GoogleAccountUser` that was updated by this mutation. */
  googleAccountUser: Maybe<GoogleAccountUser>;
  /** An edge for our `GoogleAccountUser`. May be used by Relay 1. */
  googleAccountUserEdge: Maybe<GoogleAccountUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleAccountUser`. */
  user: Maybe<User>;
};


/** The output of our update `GoogleAccountUser` mutation. */
export type UpdateGoogleAccountUserPayloadGoogleAccountUserEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};

/** All input for the `updateGoogleCalendarEventByNodeId` mutation. */
export type UpdateGoogleCalendarEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `GoogleCalendarEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `GoogleCalendarEvent` being updated. */
  patch: GoogleCalendarEventPatch;
};

/** All input for the `updateGoogleCalendarEvent` mutation. */
export type UpdateGoogleCalendarEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `GoogleCalendarEvent` being updated. */
  patch: GoogleCalendarEventPatch;
};

/** The output of our update `GoogleCalendarEvent` mutation. */
export type UpdateGoogleCalendarEventPayload = {
  __typename?: 'UpdateGoogleCalendarEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectEvent` that is related to this `GoogleCalendarEvent`. */
  event: Maybe<ProjectEvent>;
  /** The `GoogleCalendarEvent` that was updated by this mutation. */
  googleCalendarEvent: Maybe<GoogleCalendarEvent>;
  /** An edge for our `GoogleCalendarEvent`. May be used by Relay 1. */
  googleCalendarEventEdge: Maybe<GoogleCalendarEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `GoogleCalendarEvent`. */
  user: Maybe<User>;
};


/** The output of our update `GoogleCalendarEvent` mutation. */
export type UpdateGoogleCalendarEventPayloadGoogleCalendarEventEdgeArgs = {
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};

/** All input for the `updateIndustryByNameAndV1Id` mutation. */
export type UpdateIndustryByNameAndV1IdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the Industry */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `Industry` being updated. */
  patch: IndustryPatch;
  /** The ID of the Industry in v1 */
  v1Id: Scalars['Int'];
};

/** All input for the `updateIndustryByNodeId` mutation. */
export type UpdateIndustryByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Industry` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Industry` being updated. */
  patch: IndustryPatch;
};

/** All input for the `updateIndustry` mutation. */
export type UpdateIndustryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Industry` being updated. */
  patch: IndustryPatch;
};

/** The output of our update `Industry` mutation. */
export type UpdateIndustryPayload = {
  __typename?: 'UpdateIndustryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Industry` that was updated by this mutation. */
  industry: Maybe<Industry>;
  /** An edge for our `Industry`. May be used by Relay 1. */
  industryEdge: Maybe<IndustriesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `Industry` mutation. */
export type UpdateIndustryPayloadIndustryEdgeArgs = {
  orderBy?: InputMaybe<Array<IndustriesOrderBy>>;
};

/** All input for the `updateLv1GameScoreByNodeId` mutation. */
export type UpdateLv1GameScoreByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameScore` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lv1GameScore` being updated. */
  patch: Lv1GameScorePatch;
};

/** All input for the `updateLv1GameScore` mutation. */
export type UpdateLv1GameScoreInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Lv1GameScore` being updated. */
  patch: Lv1GameScorePatch;
  /** User ID of the player */
  userId: Scalars['UUID'];
};

/** The output of our update `Lv1GameScore` mutation. */
export type UpdateLv1GameScorePayload = {
  __typename?: 'UpdateLv1GameScorePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Lv1GameScore` that was updated by this mutation. */
  lv1GameScore: Maybe<Lv1GameScore>;
  /** An edge for our `Lv1GameScore`. May be used by Relay 1. */
  lv1GameScoreEdge: Maybe<Lv1GameScoresEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameScore`. */
  user: Maybe<User>;
};


/** The output of our update `Lv1GameScore` mutation. */
export type UpdateLv1GameScorePayloadLv1GameScoreEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameScoresOrderBy>>;
};

/** All input for the `updateLv1GameSubgroupByNodeId` mutation. */
export type UpdateLv1GameSubgroupByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameSubgroup` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** All input for the `updateLv1GameSubgroup` mutation. */
export type UpdateLv1GameSubgroupInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Subgroup ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** The output of our update `Lv1GameSubgroup` mutation. */
export type UpdateLv1GameSubgroupPayload = {
  __typename?: 'UpdateLv1GameSubgroupPayload';
  /** Reads a single `Account` that is related to this `Lv1GameSubgroup`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `Lv1GameSubgroup` that was updated by this mutation. */
  lv1GameSubgroup: Maybe<Lv1GameSubgroup>;
  /** An edge for our `Lv1GameSubgroup`. May be used by Relay 1. */
  lv1GameSubgroupEdge: Maybe<Lv1GameSubgroupsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroup`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our update `Lv1GameSubgroup` mutation. */
export type UpdateLv1GameSubgroupPayloadLv1GameSubgroupEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};

/** All input for the `updateLv1GameSubgroupUserByNodeId` mutation. */
export type UpdateLv1GameSubgroupUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Lv1GameSubgroupUser` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Lv1GameSubgroupUser` being updated. */
  patch: Lv1GameSubgroupUserPatch;
};

/** All input for the `updateLv1GameSubgroupUser` mutation. */
export type UpdateLv1GameSubgroupUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Lv1GameSubgroupUser` being updated. */
  patch: Lv1GameSubgroupUserPatch;
  /** User ID */
  userId: Scalars['UUID'];
};

/** The output of our update `Lv1GameSubgroupUser` mutation. */
export type UpdateLv1GameSubgroupUserPayload = {
  __typename?: 'UpdateLv1GameSubgroupUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Lv1GameSubgroup` that is related to this `Lv1GameSubgroupUser`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** The `Lv1GameSubgroupUser` that was updated by this mutation. */
  lv1GameSubgroupUser: Maybe<Lv1GameSubgroupUser>;
  /** An edge for our `Lv1GameSubgroupUser`. May be used by Relay 1. */
  lv1GameSubgroupUserEdge: Maybe<Lv1GameSubgroupUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Lv1GameSubgroupUser`. */
  user: Maybe<User>;
};


/** The output of our update `Lv1GameSubgroupUser` mutation. */
export type UpdateLv1GameSubgroupUserPayloadLv1GameSubgroupUserEdgeArgs = {
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};

/** All input for the `updateMassUserUploadByNodeId` mutation. */
export type UpdateMassUserUploadByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `MassUserUpload` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `MassUserUpload` being updated. */
  patch: MassUserUploadPatch;
};

/** All input for the `updateMassUserUpload` mutation. */
export type UpdateMassUserUploadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `MassUserUpload` being updated. */
  patch: MassUserUploadPatch;
};

/** The output of our update `MassUserUpload` mutation. */
export type UpdateMassUserUploadPayload = {
  __typename?: 'UpdateMassUserUploadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `MassUserUpload` that was updated by this mutation. */
  massUserUpload: Maybe<MassUserUpload>;
  /** An edge for our `MassUserUpload`. May be used by Relay 1. */
  massUserUploadEdge: Maybe<MassUserUploadsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `MassUserUpload`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our update `MassUserUpload` mutation. */
export type UpdateMassUserUploadPayloadMassUserUploadEdgeArgs = {
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};

/** All input for the `updateObjectiveByProjectIdAndName` mutation. */
export type UpdateObjectiveByProjectIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  input: ProjectObjectivePatch;
  objName: Scalars['String'];
  projectId: Scalars['UUID'];
};

/** The output of our `updateObjectiveByProjectIdAndName` mutation. */
export type UpdateObjectiveByProjectIdAndNamePayload = {
  __typename?: 'UpdateObjectiveByProjectIdAndNamePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectObjective`. */
  project: Maybe<Project>;
  projectObjective: Maybe<ProjectObjective>;
  /** An edge for our `ProjectObjective`. May be used by Relay 1. */
  projectObjectiveEdge: Maybe<ProjectObjectivesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our `updateObjectiveByProjectIdAndName` mutation. */
export type UpdateObjectiveByProjectIdAndNamePayloadProjectObjectiveEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectObjectivesOrderBy>>;
};

/** All input for the `updateProjectAlignSurveyByNodeId` mutation. */
export type UpdateProjectAlignSurveyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectAlignSurvey` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectAlignSurvey` being updated. */
  patch: ProjectAlignSurveyPatch;
};

/** All input for the `updateProjectAlignSurvey` mutation. */
export type UpdateProjectAlignSurveyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectAlignSurvey` being updated. */
  patch: ProjectAlignSurveyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The output of our update `ProjectAlignSurvey` mutation. */
export type UpdateProjectAlignSurveyPayload = {
  __typename?: 'UpdateProjectAlignSurveyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectAlignSurvey`. */
  project: Maybe<Project>;
  /** The `ProjectAlignSurvey` that was updated by this mutation. */
  projectAlignSurvey: Maybe<ProjectAlignSurvey>;
  /** An edge for our `ProjectAlignSurvey`. May be used by Relay 1. */
  projectAlignSurveyEdge: Maybe<ProjectAlignSurveysEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectAlignSurvey` mutation. */
export type UpdateProjectAlignSurveyPayloadProjectAlignSurveyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectAlignSurveysOrderBy>>;
};

/** All input for the `updateProjectByNodeId` mutation. */
export type UpdateProjectByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Project` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch;
};

/** All input for the `updateProjectCommentByNodeId` mutation. */
export type UpdateProjectCommentByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectComment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectComment` being updated. */
  patch: ProjectCommentPatch;
};

/** All input for the `updateProjectComment` mutation. */
export type UpdateProjectCommentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for the record */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `ProjectComment` being updated. */
  patch: ProjectCommentPatch;
};

/** The output of our update `ProjectComment` mutation. */
export type UpdateProjectCommentPayload = {
  __typename?: 'UpdateProjectCommentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectComment` that is related to this `ProjectComment`. */
  parent: Maybe<ProjectComment>;
  /** Reads a single `Project` that is related to this `ProjectComment`. */
  project: Maybe<Project>;
  /** The `ProjectComment` that was updated by this mutation. */
  projectComment: Maybe<ProjectComment>;
  /** An edge for our `ProjectComment`. May be used by Relay 1. */
  projectCommentEdge: Maybe<ProjectCommentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectComment`. */
  user: Maybe<User>;
};


/** The output of our update `ProjectComment` mutation. */
export type UpdateProjectCommentPayloadProjectCommentEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};

/** All input for the `updateProjectCommunicationMessageByNodeId` mutation. */
export type UpdateProjectCommunicationMessageByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectCommunicationMessage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectCommunicationMessage` being updated. */
  patch: ProjectCommunicationMessagePatch;
};

/** All input for the `updateProjectCommunicationMessage` mutation. */
export type UpdateProjectCommunicationMessageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Message ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectCommunicationMessage` being updated. */
  patch: ProjectCommunicationMessagePatch;
};

/** The output of our update `ProjectCommunicationMessage` mutation. */
export type UpdateProjectCommunicationMessagePayload = {
  __typename?: 'UpdateProjectCommunicationMessagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCommunicationMessage`. */
  project: Maybe<Project>;
  /** The `ProjectCommunicationMessage` that was updated by this mutation. */
  projectCommunicationMessage: Maybe<ProjectCommunicationMessage>;
  /** An edge for our `ProjectCommunicationMessage`. May be used by Relay 1. */
  projectCommunicationMessageEdge: Maybe<ProjectCommunicationMessagesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  recipient: Maybe<ProjectConnection>;
  /** Reads a single `ProjectConnection` that is related to this `ProjectCommunicationMessage`. */
  sender: Maybe<ProjectConnection>;
};


/** The output of our update `ProjectCommunicationMessage` mutation. */
export type UpdateProjectCommunicationMessagePayloadProjectCommunicationMessageEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommunicationMessagesOrderBy>>;
};

/** All input for the `updateProjectCommunicationQuestionByNodeId` mutation. */
export type UpdateProjectCommunicationQuestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectCommunicationQuestion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectCommunicationQuestion` being updated. */
  patch: ProjectCommunicationQuestionPatch;
};

/** All input for the `updateProjectCommunicationQuestionByProjectIdAndQuestionType` mutation. */
export type UpdateProjectCommunicationQuestionByProjectIdAndQuestionTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectCommunicationQuestion` being updated. */
  patch: ProjectCommunicationQuestionPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
  /** Question type - your or their */
  questionType: CommsQuestionType;
};

/** All input for the `updateProjectCommunicationQuestion` mutation. */
export type UpdateProjectCommunicationQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Question ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectCommunicationQuestion` being updated. */
  patch: ProjectCommunicationQuestionPatch;
};

/** The output of our update `ProjectCommunicationQuestion` mutation. */
export type UpdateProjectCommunicationQuestionPayload = {
  __typename?: 'UpdateProjectCommunicationQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCommunicationQuestion`. */
  project: Maybe<Project>;
  /** The `ProjectCommunicationQuestion` that was updated by this mutation. */
  projectCommunicationQuestion: Maybe<ProjectCommunicationQuestion>;
  /** An edge for our `ProjectCommunicationQuestion`. May be used by Relay 1. */
  projectCommunicationQuestionEdge: Maybe<ProjectCommunicationQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectCommunicationQuestion` mutation. */
export type UpdateProjectCommunicationQuestionPayloadProjectCommunicationQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};

/** All input for the `updateProjectComplianceByNodeId` mutation. */
export type UpdateProjectComplianceByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectCompliance` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectCompliance` being updated. */
  patch: ProjectCompliancePatch;
};

/** All input for the `updateProjectCompliance` mutation. */
export type UpdateProjectComplianceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Compliance ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectCompliance` being updated. */
  patch: ProjectCompliancePatch;
};

/** The output of our update `ProjectCompliance` mutation. */
export type UpdateProjectCompliancePayload = {
  __typename?: 'UpdateProjectCompliancePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCompliance`. */
  project: Maybe<Project>;
  /** The `ProjectCompliance` that was updated by this mutation. */
  projectCompliance: Maybe<ProjectCompliance>;
  /** An edge for our `ProjectCompliance`. May be used by Relay 1. */
  projectComplianceEdge: Maybe<ProjectCompliancesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectCompliance` mutation. */
export type UpdateProjectCompliancePayloadProjectComplianceEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCompliancesOrderBy>>;
};

/** All input for the `updateProjectConnectionByNodeId` mutation. */
export type UpdateProjectConnectionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectConnection` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** All input for the `updateProjectConnectionByProjectIdAndConnectionId` mutation. */
export type UpdateProjectConnectionByProjectIdAndConnectionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Related Connection ID */
  connectionId: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectConnection` being updated. */
  patch: ProjectConnectionPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** All input for the `updateProjectConnection` mutation. */
export type UpdateProjectConnectionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project Connection ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectConnection` being updated. */
  patch: ProjectConnectionPatch;
};

/** The output of our update `ProjectConnection` mutation. */
export type UpdateProjectConnectionPayload = {
  __typename?: 'UpdateProjectConnectionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Connection` that is related to this `ProjectConnection`. */
  connection: Maybe<Connection>;
  /** Reads a single `Project` that is related to this `ProjectConnection`. */
  project: Maybe<Project>;
  /** The `ProjectConnection` that was updated by this mutation. */
  projectConnection: Maybe<ProjectConnection>;
  /** An edge for our `ProjectConnection`. May be used by Relay 1. */
  projectConnectionEdge: Maybe<ProjectConnectionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectConnection` mutation. */
export type UpdateProjectConnectionPayloadProjectConnectionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectConnectionsOrderBy>>;
};

/** All input for the `updateProjectEventByNodeId` mutation. */
export type UpdateProjectEventByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectEvent` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectEvent` being updated. */
  patch: ProjectEventPatch;
};

/** All input for the `updateProjectEvent` mutation. */
export type UpdateProjectEventInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Event ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectEvent` being updated. */
  patch: ProjectEventPatch;
};

/** The output of our update `ProjectEvent` mutation. */
export type UpdateProjectEventPayload = {
  __typename?: 'UpdateProjectEventPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `EventType` that is related to this `ProjectEvent`. */
  eventTypeByEventType: Maybe<EventType>;
  /** Reads a single `Project` that is related to this `ProjectEvent`. */
  project: Maybe<Project>;
  /** The `ProjectEvent` that was updated by this mutation. */
  projectEvent: Maybe<ProjectEvent>;
  /** An edge for our `ProjectEvent`. May be used by Relay 1. */
  projectEventEdge: Maybe<ProjectEventsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectEvent` mutation. */
export type UpdateProjectEventPayloadProjectEventEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectEventsOrderBy>>;
};

/** All input for the `updateProject` mutation. */
export type UpdateProjectInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Project` being updated. */
  patch: ProjectPatch;
};

/** All input for the `updateProjectMembershipByNodeId` mutation. */
export type UpdateProjectMembershipByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectMembership` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectMembership` being updated. */
  patch: ProjectMembershipPatch;
};

/** All input for the `updateProjectMembershipByProjectIdAndUserId` mutation. */
export type UpdateProjectMembershipByProjectIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectMembership` being updated. */
  patch: ProjectMembershipPatch;
  /** Project ID */
  projectId: Scalars['UUID'];
  /** User ID */
  userId: Scalars['UUID'];
};

/** All input for the `updateProjectMembership` mutation. */
export type UpdateProjectMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for the record */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectMembership` being updated. */
  patch: ProjectMembershipPatch;
};

/** The output of our update `ProjectMembership` mutation. */
export type UpdateProjectMembershipPayload = {
  __typename?: 'UpdateProjectMembershipPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  createdByUser: Maybe<User>;
  /** Reads a single `Project` that is related to this `ProjectMembership`. */
  project: Maybe<Project>;
  /** The `ProjectMembership` that was updated by this mutation. */
  projectMembership: Maybe<ProjectMembership>;
  /** An edge for our `ProjectMembership`. May be used by Relay 1. */
  projectMembershipEdge: Maybe<ProjectMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `ProjectMembership`. */
  user: Maybe<User>;
};


/** The output of our update `ProjectMembership` mutation. */
export type UpdateProjectMembershipPayloadProjectMembershipEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};

/** All input for the `updateProjectOfferByNodeId` mutation. */
export type UpdateProjectOfferByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectOffer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectOffer` being updated. */
  patch: ProjectOfferPatch;
};

/** All input for the `updateProjectOffer` mutation. */
export type UpdateProjectOfferInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Offer ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `ProjectOffer` being updated. */
  patch: ProjectOfferPatch;
};

/** The output of our update `ProjectOffer` mutation. */
export type UpdateProjectOfferPayload = {
  __typename?: 'UpdateProjectOfferPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectOffer`. */
  project: Maybe<Project>;
  /** The `ProjectOffer` that was updated by this mutation. */
  projectOffer: Maybe<ProjectOffer>;
  /** An edge for our `ProjectOffer`. May be used by Relay 1. */
  projectOfferEdge: Maybe<ProjectOffersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectOffer` mutation. */
export type UpdateProjectOfferPayloadProjectOfferEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectOffersOrderBy>>;
};

/** All input for the `updateProjectOfferVariableByNodeId` mutation. */
export type UpdateProjectOfferVariableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectOfferVariable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectOfferVariable` being updated. */
  patch: ProjectOfferVariablePatch;
};

/** All input for the `updateProjectOfferVariable` mutation. */
export type UpdateProjectOfferVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Offer Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `ProjectOfferVariable` being updated. */
  patch: ProjectOfferVariablePatch;
};

/** The output of our update `ProjectOfferVariable` mutation. */
export type UpdateProjectOfferVariablePayload = {
  __typename?: 'UpdateProjectOfferVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectOffer` that is related to this `ProjectOfferVariable`. */
  projectOffer: Maybe<ProjectOffer>;
  /** The `ProjectOfferVariable` that was updated by this mutation. */
  projectOfferVariable: Maybe<ProjectOfferVariable>;
  /** An edge for our `ProjectOfferVariable`. May be used by Relay 1. */
  projectOfferVariableEdge: Maybe<ProjectOfferVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectVariable` that is related to this `ProjectOfferVariable`. */
  variable: Maybe<ProjectVariable>;
};


/** The output of our update `ProjectOfferVariable` mutation. */
export type UpdateProjectOfferVariablePayloadProjectOfferVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectOfferVariablesOrderBy>>;
};

/** The output of our update `Project` mutation. */
export type UpdateProjectPayload = {
  __typename?: 'UpdateProjectPayload';
  /** Reads a single `Account` that is related to this `Project`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Customer` that is related to this `Project`. */
  customer: Maybe<Customer>;
  /** Reads a single `Project` that is related to this `Project`. */
  parent: Maybe<Project>;
  /** The `Project` that was updated by this mutation. */
  project: Maybe<Project>;
  /** An edge for our `Project`. May be used by Relay 1. */
  projectEdge: Maybe<ProjectsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Project`. */
  userByCreatedBy: Maybe<User>;
};


/** The output of our update `Project` mutation. */
export type UpdateProjectPayloadProjectEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};

/** All input for the `updateProjectPowerByNodeId` mutation. */
export type UpdateProjectPowerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectPower` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectPower` being updated. */
  patch: ProjectPowerPatch;
};

/** All input for the `updateProjectPower` mutation. */
export type UpdateProjectPowerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectPower` being updated. */
  patch: ProjectPowerPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The output of our update `ProjectPower` mutation. */
export type UpdateProjectPowerPayload = {
  __typename?: 'UpdateProjectPowerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectPower`. */
  project: Maybe<Project>;
  /** The `ProjectPower` that was updated by this mutation. */
  projectPower: Maybe<ProjectPower>;
  /** An edge for our `ProjectPower`. May be used by Relay 1. */
  projectPowerEdge: Maybe<ProjectPowersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectPower` mutation. */
export type UpdateProjectPowerPayloadProjectPowerEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectPowersOrderBy>>;
};

/** All input for the `updateProjectReminderByNodeId` mutation. */
export type UpdateProjectReminderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectReminder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectReminder` being updated. */
  patch: ProjectReminderPatch;
};

/** All input for the `updateProjectReminder` mutation. */
export type UpdateProjectReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectReminder` being updated. */
  patch: ProjectReminderPatch;
};

/** The output of our update `ProjectReminder` mutation. */
export type UpdateProjectReminderPayload = {
  __typename?: 'UpdateProjectReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** The `ProjectReminder` that was updated by this mutation. */
  projectReminder: Maybe<ProjectReminder>;
  /** An edge for our `ProjectReminder`. May be used by Relay 1. */
  projectReminderEdge: Maybe<ProjectRemindersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectReminder` mutation. */
export type UpdateProjectReminderPayloadProjectReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectRemindersOrderBy>>;
};

/** All input for the `updateProjectRiskByNodeId` mutation. */
export type UpdateProjectRiskByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectRisk` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectRisk` being updated. */
  patch: ProjectRiskPatch;
};

/** All input for the `updateProjectRisk` mutation. */
export type UpdateProjectRiskInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Risk ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectRisk` being updated. */
  patch: ProjectRiskPatch;
};

/** The output of our update `ProjectRisk` mutation. */
export type UpdateProjectRiskPayload = {
  __typename?: 'UpdateProjectRiskPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectRisk`. */
  project: Maybe<Project>;
  /** The `ProjectRisk` that was updated by this mutation. */
  projectRisk: Maybe<ProjectRisk>;
  /** An edge for our `ProjectRisk`. May be used by Relay 1. */
  projectRiskEdge: Maybe<ProjectRisksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectRisk` mutation. */
export type UpdateProjectRiskPayloadProjectRiskEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectRisksOrderBy>>;
};

/** All input for the `updateProjectScenarioByNodeId` mutation. */
export type UpdateProjectScenarioByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectScenario` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectScenario` being updated. */
  patch: ProjectScenarioPatch;
};

/** All input for the `updateProjectScenario` mutation. */
export type UpdateProjectScenarioInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Scenario ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `ProjectScenario` being updated. */
  patch: ProjectScenarioPatch;
};

/** The output of our update `ProjectScenario` mutation. */
export type UpdateProjectScenarioPayload = {
  __typename?: 'UpdateProjectScenarioPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectScenario`. */
  project: Maybe<Project>;
  /** The `ProjectScenario` that was updated by this mutation. */
  projectScenario: Maybe<ProjectScenario>;
  /** An edge for our `ProjectScenario`. May be used by Relay 1. */
  projectScenarioEdge: Maybe<ProjectScenariosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectScenario` mutation. */
export type UpdateProjectScenarioPayloadProjectScenarioEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectScenariosOrderBy>>;
};

/** All input for the `updateProjectScenarioVariableByNodeId` mutation. */
export type UpdateProjectScenarioVariableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectScenarioVariable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectScenarioVariable` being updated. */
  patch: ProjectScenarioVariablePatch;
};

/** All input for the `updateProjectScenarioVariable` mutation. */
export type UpdateProjectScenarioVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Scenario Variable ID */
  id: Scalars['BigInt'];
  /** An object where the defined keys will be set on the `ProjectScenarioVariable` being updated. */
  patch: ProjectScenarioVariablePatch;
};

/** The output of our update `ProjectScenarioVariable` mutation. */
export type UpdateProjectScenarioVariablePayload = {
  __typename?: 'UpdateProjectScenarioVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `ProjectScenario` that is related to this `ProjectScenarioVariable`. */
  projectScenario: Maybe<ProjectScenario>;
  /** The `ProjectScenarioVariable` that was updated by this mutation. */
  projectScenarioVariable: Maybe<ProjectScenarioVariable>;
  /** An edge for our `ProjectScenarioVariable`. May be used by Relay 1. */
  projectScenarioVariableEdge: Maybe<ProjectScenarioVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `ProjectVariable` that is related to this `ProjectScenarioVariable`. */
  variable: Maybe<ProjectVariable>;
};


/** The output of our update `ProjectScenarioVariable` mutation. */
export type UpdateProjectScenarioVariablePayloadProjectScenarioVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectScenarioVariablesOrderBy>>;
};

/** All input for the `updateProjectSurveyByNodeId` mutation. */
export type UpdateProjectSurveyByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectSurvey` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectSurvey` being updated. */
  patch: ProjectSurveyPatch;
};

/** All input for the `updateProjectSurvey` mutation. */
export type UpdateProjectSurveyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ProjectSurvey` being updated. */
  patch: ProjectSurveyPatch;
  /** Related Project ID */
  projectId: Scalars['UUID'];
};

/** The output of our update `ProjectSurvey` mutation. */
export type UpdateProjectSurveyPayload = {
  __typename?: 'UpdateProjectSurveyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectSurvey`. */
  project: Maybe<Project>;
  /** The `ProjectSurvey` that was updated by this mutation. */
  projectSurvey: Maybe<ProjectSurvey>;
  /** An edge for our `ProjectSurvey`. May be used by Relay 1. */
  projectSurveyEdge: Maybe<ProjectSurveysEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our update `ProjectSurvey` mutation. */
export type UpdateProjectSurveyPayloadProjectSurveyEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectSurveysOrderBy>>;
};

/** All input for the `updateProjectVariableByNodeId` mutation. */
export type UpdateProjectVariableByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ProjectVariable` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ProjectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** All input for the `updateProjectVariable` mutation. */
export type UpdateProjectVariableInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `ProjectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** The output of our update `ProjectVariable` mutation. */
export type UpdateProjectVariablePayload = {
  __typename?: 'UpdateProjectVariablePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectVariable`. */
  project: Maybe<Project>;
  /** The `ProjectVariable` that was updated by this mutation. */
  projectVariable: Maybe<ProjectVariable>;
  /** An edge for our `ProjectVariable`. May be used by Relay 1. */
  projectVariableEdge: Maybe<ProjectVariablesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `VariableClass` that is related to this `ProjectVariable`. */
  variableClass: Maybe<VariableClass>;
};


/** The output of our update `ProjectVariable` mutation. */
export type UpdateProjectVariablePayloadProjectVariableEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};

/** All input for the `updateProjectVariablesRank` mutation. */
export type UpdateProjectVariablesRankInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  projectVariablesRank: Array<InputMaybe<ProjectVariableRankUpdateInput>>;
};

/** The output of our `updateProjectVariablesRank` mutation. */
export type UpdateProjectVariablesRankPayload = {
  __typename?: 'UpdateProjectVariablesRankPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** All input for the `updateQuestionByProjectIdAndType` mutation. */
export type UpdateQuestionByProjectIdAndTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  input: ProjectCommunicationQuestionPatch;
  projectId: Scalars['UUID'];
  questionType: CommsQuestionType;
};

/** The output of our `updateQuestionByProjectIdAndType` mutation. */
export type UpdateQuestionByProjectIdAndTypePayload = {
  __typename?: 'UpdateQuestionByProjectIdAndTypePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Project` that is related to this `ProjectCommunicationQuestion`. */
  project: Maybe<Project>;
  projectCommunicationQuestion: Maybe<ProjectCommunicationQuestion>;
  /** An edge for our `ProjectCommunicationQuestion`. May be used by Relay 1. */
  projectCommunicationQuestionEdge: Maybe<ProjectCommunicationQuestionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};


/** The output of our `updateQuestionByProjectIdAndType` mutation. */
export type UpdateQuestionByProjectIdAndTypePayloadProjectCommunicationQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<ProjectCommunicationQuestionsOrderBy>>;
};

/** All input for the `updateSurveyBucketByName` mutation. */
export type UpdateSurveyBucketByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the survey bucket */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `SurveyBucket` being updated. */
  patch: SurveyBucketPatch;
};

/** All input for the `updateSurveyBucketByNodeId` mutation. */
export type UpdateSurveyBucketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyBucket` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SurveyBucket` being updated. */
  patch: SurveyBucketPatch;
};

/** All input for the `updateSurveyBucket` mutation. */
export type UpdateSurveyBucketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SurveyBucket` being updated. */
  patch: SurveyBucketPatch;
};

/** The output of our update `SurveyBucket` mutation. */
export type UpdateSurveyBucketPayload = {
  __typename?: 'UpdateSurveyBucketPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyBucket` that was updated by this mutation. */
  surveyBucket: Maybe<SurveyBucket>;
  /** An edge for our `SurveyBucket`. May be used by Relay 1. */
  surveyBucketEdge: Maybe<SurveyBucketsEdge>;
};


/** The output of our update `SurveyBucket` mutation. */
export type UpdateSurveyBucketPayloadSurveyBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyBucketsOrderBy>>;
};

/** All input for the `updateSurveyQuestionAnswerByNodeId` mutation. */
export type UpdateSurveyQuestionAnswerByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyQuestionAnswer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SurveyQuestionAnswer` being updated. */
  patch: SurveyQuestionAnswerPatch;
};

/** All input for the `updateSurveyQuestionAnswer` mutation. */
export type UpdateSurveyQuestionAnswerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Id of this answer */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SurveyQuestionAnswer` being updated. */
  patch: SurveyQuestionAnswerPatch;
};

/** The output of our update `SurveyQuestionAnswer` mutation. */
export type UpdateSurveyQuestionAnswerPayload = {
  __typename?: 'UpdateSurveyQuestionAnswerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestionAnswer` that was updated by this mutation. */
  surveyQuestionAnswer: Maybe<SurveyQuestionAnswer>;
  /** An edge for our `SurveyQuestionAnswer`. May be used by Relay 1. */
  surveyQuestionAnswerEdge: Maybe<SurveyQuestionAnswersEdge>;
};


/** The output of our update `SurveyQuestionAnswer` mutation. */
export type UpdateSurveyQuestionAnswerPayloadSurveyQuestionAnswerEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionAnswersOrderBy>>;
};

/** All input for the `updateSurveyQuestionByNodeId` mutation. */
export type UpdateSurveyQuestionByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyQuestion` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SurveyQuestion` being updated. */
  patch: SurveyQuestionPatch;
};

/** All input for the `updateSurveyQuestionByQuestionAndSurveyType` mutation. */
export type UpdateSurveyQuestionByQuestionAndSurveyTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SurveyQuestion` being updated. */
  patch: SurveyQuestionPatch;
  /** The text of the question */
  question: Scalars['String'];
  /** Which survey the question belongs to */
  surveyType: SurveyType;
};

/** All input for the `updateSurveyQuestion` mutation. */
export type UpdateSurveyQuestionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SurveyQuestion` being updated. */
  patch: SurveyQuestionPatch;
};

/** The output of our update `SurveyQuestion` mutation. */
export type UpdateSurveyQuestionPayload = {
  __typename?: 'UpdateSurveyQuestionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyQuestion` that was updated by this mutation. */
  surveyQuestion: Maybe<SurveyQuestion>;
  /** An edge for our `SurveyQuestion`. May be used by Relay 1. */
  surveyQuestionEdge: Maybe<SurveyQuestionsEdge>;
};


/** The output of our update `SurveyQuestion` mutation. */
export type UpdateSurveyQuestionPayloadSurveyQuestionEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyQuestionsOrderBy>>;
};

/** All input for the `updateSurveyReminderByNodeId` mutation. */
export type UpdateSurveyReminderByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyReminder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SurveyReminder` being updated. */
  patch: SurveyReminderPatch;
};

/** All input for the `updateSurveyReminder` mutation. */
export type UpdateSurveyReminderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SurveyReminder` being updated. */
  patch: SurveyReminderPatch;
};

/** The output of our update `SurveyReminder` mutation. */
export type UpdateSurveyReminderPayload = {
  __typename?: 'UpdateSurveyReminderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyReminder` that was updated by this mutation. */
  surveyReminder: Maybe<SurveyReminder>;
  /** An edge for our `SurveyReminder`. May be used by Relay 1. */
  surveyReminderEdge: Maybe<SurveyRemindersEdge>;
};


/** The output of our update `SurveyReminder` mutation. */
export type UpdateSurveyReminderPayloadSurveyReminderEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyRemindersOrderBy>>;
};

/** All input for the `updateSurveyResponseByNodeId` mutation. */
export type UpdateSurveyResponseByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `SurveyResponse` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `SurveyResponse` being updated. */
  patch: SurveyResponsePatch;
};

/** All input for the `updateSurveyResponseByUserIdAndRecipientIdAndSurveyType` mutation. */
export type UpdateSurveyResponseByUserIdAndRecipientIdAndSurveyTypeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `SurveyResponse` being updated. */
  patch: SurveyResponsePatch;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId: Scalars['UUID'];
  /** Which survey type the response is for */
  surveyType: SurveyType;
  /** The user that submitted the response, can only be the current logged-in user */
  userId: Scalars['UUID'];
};

/** All input for the `updateSurveyResponse` mutation. */
export type UpdateSurveyResponseInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `SurveyResponse` being updated. */
  patch: SurveyResponsePatch;
};

/** The output of our update `SurveyResponse` mutation. */
export type UpdateSurveyResponsePayload = {
  __typename?: 'UpdateSurveyResponsePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `SurveyResponse` that was updated by this mutation. */
  surveyResponse: Maybe<SurveyResponse>;
  /** An edge for our `SurveyResponse`. May be used by Relay 1. */
  surveyResponseEdge: Maybe<SurveyResponsesEdge>;
  /** Reads a single `User` that is related to this `SurveyResponse`. */
  user: Maybe<User>;
};


/** The output of our update `SurveyResponse` mutation. */
export type UpdateSurveyResponsePayloadSurveyResponseEdgeArgs = {
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};

/** All input for the `updateUserByEmail` mutation. */
export type UpdateUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserByNodeId` mutation. */
export type UpdateUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserLanguageByLanguageCode` mutation. */
export type UpdateUserLanguageByLanguageCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
  /** An object where the defined keys will be set on the `UserLanguage` being updated. */
  patch: UserLanguagePatch;
};

/** All input for the `updateUserLanguageByName` mutation. */
export type UpdateUserLanguageByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The name of the language in English */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `UserLanguage` being updated. */
  patch: UserLanguagePatch;
};

/** The output of our update `UserLanguage` mutation. */
export type UpdateUserLanguagePayload = {
  __typename?: 'UpdateUserLanguagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `UserLanguage` that was updated by this mutation. */
  userLanguage: Maybe<UserLanguage>;
  /** An edge for our `UserLanguage`. May be used by Relay 1. */
  userLanguageEdge: Maybe<UserLanguagesEdge>;
};


/** The output of our update `UserLanguage` mutation. */
export type UpdateUserLanguagePayloadUserLanguageEdgeArgs = {
  orderBy?: InputMaybe<Array<UserLanguagesOrderBy>>;
};

/** All input for the `updateUserPassword` mutation. */
export type UpdateUserPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  userId: Scalars['UUID'];
};

/** The output of our `updateUserPassword` mutation. */
export type UpdateUserPasswordPayload = {
  __typename?: 'UpdateUserPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  success: Maybe<Scalars['Boolean']>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** Reads a single `Account` that is related to this `User`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `Lv1GameSubgroup` that is related to this `User`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge: Maybe<UsersEdge>;
};


/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserProfileQuestionnaireByNodeId` mutation. */
export type UpdateUserProfileQuestionnaireByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserProfileQuestionnaire` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserProfileQuestionnaire` being updated. */
  patch: UserProfileQuestionnairePatch;
};

/** All input for the `updateUserProfileQuestionnaireByUserId` mutation. */
export type UpdateUserProfileQuestionnaireByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserProfileQuestionnaire` being updated. */
  patch: UserProfileQuestionnairePatch;
  userId: Scalars['UUID'];
};

/** All input for the `updateUserProfileQuestionnaire` mutation. */
export type UpdateUserProfileQuestionnaireInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `UserProfileQuestionnaire` being updated. */
  patch: UserProfileQuestionnairePatch;
};

/** The output of our update `UserProfileQuestionnaire` mutation. */
export type UpdateUserProfileQuestionnairePayload = {
  __typename?: 'UpdateUserProfileQuestionnairePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserProfileQuestionnaire`. */
  user: Maybe<User>;
  /** The `UserProfileQuestionnaire` that was updated by this mutation. */
  userProfileQuestionnaire: Maybe<UserProfileQuestionnaire>;
  /** An edge for our `UserProfileQuestionnaire`. May be used by Relay 1. */
  userProfileQuestionnaireEdge: Maybe<UserProfileQuestionnairesEdge>;
};


/** The output of our update `UserProfileQuestionnaire` mutation. */
export type UpdateUserProfileQuestionnairePayloadUserProfileQuestionnaireEdgeArgs = {
  orderBy?: InputMaybe<Array<UserProfileQuestionnairesOrderBy>>;
};

/** All input for the `updateV1RoleByIdAndName` mutation. */
export type UpdateV1RoleByIdAndNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the role in v1 */
  id: Scalars['Int'];
  /** The name of the v1 role */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `V1Role` being updated. */
  patch: V1RolePatch;
};

/** All input for the `updateV1RoleByNodeId` mutation. */
export type UpdateV1RoleByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `V1Role` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `V1Role` being updated. */
  patch: V1RolePatch;
};

/** All input for the `updateV1Role` mutation. */
export type UpdateV1RoleInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The ID of the role in v1 */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `V1Role` being updated. */
  patch: V1RolePatch;
};

/** The output of our update `V1Role` mutation. */
export type UpdateV1RolePayload = {
  __typename?: 'UpdateV1RolePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `V1Role` that was updated by this mutation. */
  v1Role: Maybe<V1Role>;
  /** An edge for our `V1Role`. May be used by Relay 1. */
  v1RoleEdge: Maybe<V1RolesEdge>;
};


/** The output of our update `V1Role` mutation. */
export type UpdateV1RolePayloadV1RoleEdgeArgs = {
  orderBy?: InputMaybe<Array<V1RolesOrderBy>>;
};

/** All input for the `updateVariableClassByNodeId` mutation. */
export type UpdateVariableClassByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VariableClass` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VariableClass` being updated. */
  patch: VariableClassPatch;
};

/** All input for the `updateVariableClass` mutation. */
export type UpdateVariableClassInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `VariableClass` being updated. */
  patch: VariableClassPatch;
};

/** The output of our update `VariableClass` mutation. */
export type UpdateVariableClassPayload = {
  __typename?: 'UpdateVariableClassPayload';
  /** Reads a single `Account` that is related to this `VariableClass`. */
  account: Maybe<Account>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Project` that is related to this `VariableClass`. */
  relatedProject: Maybe<Project>;
  /** Reads a single `User` that is related to this `VariableClass`. */
  userByCreatedBy: Maybe<User>;
  /** The `VariableClass` that was updated by this mutation. */
  variableClass: Maybe<VariableClass>;
  /** An edge for our `VariableClass`. May be used by Relay 1. */
  variableClassEdge: Maybe<VariableClassesEdge>;
};


/** The output of our update `VariableClass` mutation. */
export type UpdateVariableClassPayloadVariableClassEdgeArgs = {
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};

/** All input for the `updateVideoByNodeId` mutation. */
export type UpdateVideoByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Video` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Video` being updated. */
  patch: VideoPatch;
};

/** All input for the `updateVideo` mutation. */
export type UpdateVideoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Video` being updated. */
  patch: VideoPatch;
};

/** The output of our update `Video` mutation. */
export type UpdateVideoPayload = {
  __typename?: 'UpdateVideoPayload';
  /** Reads a single `ClassAssetCategory` that is related to this `Video`. */
  category: Maybe<ClassAssetCategory>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Video`. */
  userByCreatedBy: Maybe<User>;
  /** The `Video` that was updated by this mutation. */
  video: Maybe<Video>;
  /** An edge for our `Video`. May be used by Relay 1. */
  videoEdge: Maybe<VideosEdge>;
};


/** The output of our update `Video` mutation. */
export type UpdateVideoPayloadVideoEdgeArgs = {
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};

/** All input for the `updateVideoStarByNodeId` mutation. */
export type UpdateVideoStarByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `VideoStar` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `VideoStar` being updated. */
  patch: VideoStarPatch;
};

/** All input for the `updateVideoStarByVideoIdAndUserId` mutation. */
export type UpdateVideoStarByVideoIdAndUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `VideoStar` being updated. */
  patch: VideoStarPatch;
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** All input for the `updateVideoStar` mutation. */
export type UpdateVideoStarInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `VideoStar` being updated. */
  patch: VideoStarPatch;
};

/** The output of our update `VideoStar` mutation. */
export type UpdateVideoStarPayload = {
  __typename?: 'UpdateVideoStarPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `VideoStar`. */
  video: Maybe<Video>;
  /** The `VideoStar` that was updated by this mutation. */
  videoStar: Maybe<VideoStar>;
  /** An edge for our `VideoStar`. May be used by Relay 1. */
  videoStarEdge: Maybe<VideoStarsEdge>;
};


/** The output of our update `VideoStar` mutation. */
export type UpdateVideoStarPayloadVideoStarEdgeArgs = {
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};

/** All input for the `updateWorkshopByNodeId` mutation. */
export type UpdateWorkshopByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Workshop` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Workshop` being updated. */
  patch: WorkshopPatch;
};

/** All input for the `updateWorkshop` mutation. */
export type UpdateWorkshopInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `Workshop` being updated. */
  patch: WorkshopPatch;
};

/** The output of our update `Workshop` mutation. */
export type UpdateWorkshopPayload = {
  __typename?: 'UpdateWorkshopPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Reads a single `WorkshopPreset` that is related to this `Workshop`. */
  preset: Maybe<WorkshopPreset>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `User` that is related to this `Workshop`. */
  userByCreatedBy: Maybe<User>;
  /** The `Workshop` that was updated by this mutation. */
  workshop: Maybe<Workshop>;
  /** An edge for our `Workshop`. May be used by Relay 1. */
  workshopEdge: Maybe<WorkshopsEdge>;
};


/** The output of our update `Workshop` mutation. */
export type UpdateWorkshopPayloadWorkshopEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

/** All input for the `updateWorkshopPresetByNodeId` mutation. */
export type UpdateWorkshopPresetByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `WorkshopPreset` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `WorkshopPreset` being updated. */
  patch: WorkshopPresetPatch;
};

/** All input for the `updateWorkshopPreset` mutation. */
export type UpdateWorkshopPresetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `WorkshopPreset` being updated. */
  patch: WorkshopPresetPatch;
};

/** The output of our update `WorkshopPreset` mutation. */
export type UpdateWorkshopPresetPayload = {
  __typename?: 'UpdateWorkshopPresetPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** The `WorkshopPreset` that was updated by this mutation. */
  workshopPreset: Maybe<WorkshopPreset>;
  /** An edge for our `WorkshopPreset`. May be used by Relay 1. */
  workshopPresetEdge: Maybe<WorkshopPresetsEdge>;
};


/** The output of our update `WorkshopPreset` mutation. */
export type UpdateWorkshopPresetPayloadWorkshopPresetEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkshopPresetsOrderBy>>;
};

/** Public user profile information */
export type User = Node & {
  __typename?: 'User';
  accessNpt: Maybe<Scalars['Boolean']>;
  /** Reads a single `Account` that is related to this `User`. */
  account: Maybe<Account>;
  /** Account of the user */
  accountId: Maybe<Scalars['UUID']>;
  /** Optional avatar URL */
  avatarUrl: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ClassUser`. */
  classUsers: ClassUsersConnection;
  /** Reads and enables pagination through a set of `ClassUser`. */
  classUsersList: Array<ClassUser>;
  /** Reads and enables pagination through a set of `ClassVideoUser`. */
  classVideoUsers: ClassVideoUsersConnection;
  /** Reads and enables pagination through a set of `ClassVideoUser`. */
  classVideoUsersList: Array<ClassVideoUser>;
  /** Reads and enables pagination through a set of `Class`. */
  classesByCreatedBy: ClassesConnection;
  /** Reads and enables pagination through a set of `Class`. */
  classesByCreatedByList: Array<Class>;
  /** Reads and enables pagination through a set of `Connection`. */
  connections: ConnectionsConnection;
  /** Reads and enables pagination through a set of `Connection`. */
  connectionsList: Array<Connection>;
  /** Reads and enables pagination through a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccesses: ConsultantOrganizationAccessesConnection;
  /** Reads and enables pagination through a set of `ConsultantOrganizationAccess`. */
  consultantOrganizationAccessesList: Array<ConsultantOrganizationAccess>;
  /** When was this user created? */
  createdAt: Scalars['Datetime'];
  /** Division of the User */
  divisionId: Maybe<Scalars['UUID']>;
  /** Reads and enables pagination through a set of `Document`. */
  documentsByCreatedBy: DocumentsConnection;
  /** Reads and enables pagination through a set of `Document`. */
  documentsByCreatedByList: Array<Document>;
  /** Unique email address */
  email: Scalars['Email'];
  /** Reads and enables pagination through a set of `FeedbackResponse`. */
  feedbackResponses: FeedbackResponsesConnection;
  /** Reads and enables pagination through a set of `FeedbackResponse`. */
  feedbackResponsesList: Array<FeedbackResponse>;
  /** Full name */
  fullname: Scalars['String'];
  /** Reads a single `Lv1GameSubgroup` that is related to this `User`. */
  gameLv1Subgroup: Maybe<Lv1GameSubgroup>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId: Maybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender: Maybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `GoogleAccountUser`. */
  googleAccountUsers: GoogleAccountUsersConnection;
  /** Reads and enables pagination through a set of `GoogleAccountUser`. */
  googleAccountUsersList: Array<GoogleAccountUser>;
  /** Reads and enables pagination through a set of `GoogleCalendarEvent`. */
  googleCalendarEvents: GoogleCalendarEventsConnection;
  /** Reads and enables pagination through a set of `GoogleCalendarEvent`. */
  googleCalendarEventsList: Array<GoogleCalendarEvent>;
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** Is this user archived (suspended)? */
  isArchived: Scalars['Boolean'];
  /** Is this user deleted? */
  isDeleted: Maybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms: Scalars['Boolean'];
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1: Scalars['Boolean'];
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt: Scalars['Boolean'];
  /** Whether or not the user is using SSO for signing in */
  isSso: Maybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified: Scalars['Boolean'];
  /** User language set by admin */
  languageCode: Scalars['String'];
  /** Last time when the user signed in */
  lastSignin: Maybe<Scalars['Datetime']>;
  /** Reads a single `Lv1GameScore` that is related to this `User`. */
  lv1GameScore: Maybe<Lv1GameScore>;
  /** Reads a single `Lv1GameState` that is related to this `User`. */
  lv1GameState: Maybe<Lv1GameState>;
  /** Reads and enables pagination through a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsers: Lv1GameSubgroupUsersConnection;
  /** Reads and enables pagination through a set of `Lv1GameSubgroupUser`. */
  lv1GameSubgroupUsersList: Array<Lv1GameSubgroupUser>;
  /** Reads and enables pagination through a set of `Lv1GameSubgroup`. */
  lv1GameSubgroupsByCreatedBy: Lv1GameSubgroupsConnection;
  /** Reads and enables pagination through a set of `Lv1GameSubgroup`. */
  lv1GameSubgroupsByCreatedByList: Array<Lv1GameSubgroup>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin: Maybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `MassUserUpload`. */
  massUserUploadsByCreatedBy: MassUserUploadsConnection;
  /** Reads and enables pagination through a set of `MassUserUpload`. */
  massUserUploadsByCreatedByList: Array<MassUserUpload>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistoriesByCreatedBy: OrganizationCoachingTimeHistoriesConnection;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeHistory`. */
  organizationCoachingTimeHistoriesByCreatedByList: Array<OrganizationCoachingTimeHistory>;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogsByConsultantId: OrganizationCoachingTimeLogsConnection;
  /** Reads and enables pagination through a set of `OrganizationCoachingTimeLog`. */
  organizationCoachingTimeLogsByConsultantIdList: Array<OrganizationCoachingTimeLog>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt: Maybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell: Maybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `ProjectAccessLog`. */
  projectAccessLogs: ProjectAccessLogsConnection;
  /** Reads and enables pagination through a set of `ProjectAccessLog`. */
  projectAccessLogsList: Array<ProjectAccessLog>;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  projectComments: ProjectCommentsConnection;
  /** Reads and enables pagination through a set of `ProjectComment`. */
  projectCommentsList: Array<ProjectComment>;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFolders: ProjectFoldersConnection;
  /** Reads and enables pagination through a set of `ProjectFolder`. */
  projectFoldersList: Array<ProjectFolder>;
  /** Reads and enables pagination through a set of `ProjectInvitation`. */
  projectInvitations: ProjectInvitationsConnection;
  /** Reads and enables pagination through a set of `ProjectInvitation`. */
  projectInvitationsList: Array<ProjectInvitation>;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMemberships: ProjectMembershipsConnection;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMembershipsByCreatedByUserId: ProjectMembershipsConnection;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMembershipsByCreatedByUserIdList: Array<ProjectMembership>;
  /** Reads and enables pagination through a set of `ProjectMembership`. */
  projectMembershipsList: Array<ProjectMembership>;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedBy: ProjectsConnection;
  /** Reads and enables pagination through a set of `Project`. */
  projectsByCreatedByList: Array<Project>;
  /** Role in the system */
  role: Scalars['String'];
  /** Reads and enables pagination through a set of `SurveyResponse`. */
  surveyResponses: SurveyResponsesConnection;
  /** Reads and enables pagination through a set of `SurveyResponse`. */
  surveyResponsesList: Array<SurveyResponse>;
  /** Title of the person */
  title: Maybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `UserProfileQuestionnaire` that is related to this `User`. */
  userProfileQuestionnaire: Maybe<UserProfileQuestionnaire>;
  /** Indicates if the user needs to see a direct link to the v1 Dashboard */
  v1Link: Maybe<Scalars['Boolean']>;
  /** The Role of the User in v1 */
  v1RoleId: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClassesByCreatedBy: VariableClassesConnection;
  /** Reads and enables pagination through a set of `VariableClass`. */
  variableClassesByCreatedByList: Array<VariableClass>;
  /** Reads and enables pagination through a set of `Video`. */
  videosByCreatedBy: VideosConnection;
  /** Reads and enables pagination through a set of `Video`. */
  videosByCreatedByList: Array<Video>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `Workshop`. */
  workshopsByCreatedBy: WorkshopsConnection;
  /** Reads and enables pagination through a set of `Workshop`. */
  workshopsByCreatedByList: Array<Workshop>;
  /** Workshop count for the user taken from v1 data */
  workshopsCount: Maybe<Scalars['Int']>;
  /** A json array taken from v1 with details about the most important workshop a user is enrolled in */
  wsData: Maybe<Scalars['JSON']>;
  wsFullData: Maybe<Scalars['JSON']>;
  wsParticipant: Maybe<Scalars['Boolean']>;
};


/** Public user profile information */
export type UserClassUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


/** Public user profile information */
export type UserClassUsersListArgs = {
  condition?: InputMaybe<ClassUserCondition>;
  filter?: InputMaybe<ClassUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassUsersOrderBy>>;
};


/** Public user profile information */
export type UserClassVideoUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};


/** Public user profile information */
export type UserClassVideoUsersListArgs = {
  condition?: InputMaybe<ClassVideoUserCondition>;
  filter?: InputMaybe<ClassVideoUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideoUsersOrderBy>>;
};


/** Public user profile information */
export type UserClassesByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** Public user profile information */
export type UserClassesByCreatedByListArgs = {
  condition?: InputMaybe<ClassCondition>;
  filter?: InputMaybe<ClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassesOrderBy>>;
};


/** Public user profile information */
export type UserConnectionsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** Public user profile information */
export type UserConnectionsListArgs = {
  condition?: InputMaybe<ConnectionCondition>;
  filter?: InputMaybe<ConnectionFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConnectionsOrderBy>>;
};


/** Public user profile information */
export type UserConsultantOrganizationAccessesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** Public user profile information */
export type UserConsultantOrganizationAccessesListArgs = {
  condition?: InputMaybe<ConsultantOrganizationAccessCondition>;
  filter?: InputMaybe<ConsultantOrganizationAccessFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ConsultantOrganizationAccessesOrderBy>>;
};


/** Public user profile information */
export type UserDocumentsByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


/** Public user profile information */
export type UserDocumentsByCreatedByListArgs = {
  condition?: InputMaybe<DocumentCondition>;
  filter?: InputMaybe<DocumentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<DocumentsOrderBy>>;
};


/** Public user profile information */
export type UserFeedbackResponsesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<FeedbackResponseCondition>;
  filter?: InputMaybe<FeedbackResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackResponsesOrderBy>>;
};


/** Public user profile information */
export type UserFeedbackResponsesListArgs = {
  condition?: InputMaybe<FeedbackResponseCondition>;
  filter?: InputMaybe<FeedbackResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<FeedbackResponsesOrderBy>>;
};


/** Public user profile information */
export type UserGoogleAccountUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GoogleAccountUserCondition>;
  filter?: InputMaybe<GoogleAccountUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};


/** Public user profile information */
export type UserGoogleAccountUsersListArgs = {
  condition?: InputMaybe<GoogleAccountUserCondition>;
  filter?: InputMaybe<GoogleAccountUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleAccountUsersOrderBy>>;
};


/** Public user profile information */
export type UserGoogleCalendarEventsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};


/** Public user profile information */
export type UserGoogleCalendarEventsListArgs = {
  condition?: InputMaybe<GoogleCalendarEventCondition>;
  filter?: InputMaybe<GoogleCalendarEventFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<GoogleCalendarEventsOrderBy>>;
};


/** Public user profile information */
export type UserLv1GameSubgroupUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** Public user profile information */
export type UserLv1GameSubgroupUsersListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupUserCondition>;
  filter?: InputMaybe<Lv1GameSubgroupUserFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupUsersOrderBy>>;
};


/** Public user profile information */
export type UserLv1GameSubgroupsByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** Public user profile information */
export type UserLv1GameSubgroupsByCreatedByListArgs = {
  condition?: InputMaybe<Lv1GameSubgroupCondition>;
  filter?: InputMaybe<Lv1GameSubgroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<Lv1GameSubgroupsOrderBy>>;
};


/** Public user profile information */
export type UserMassUserUploadsByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<MassUserUploadCondition>;
  filter?: InputMaybe<MassUserUploadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};


/** Public user profile information */
export type UserMassUserUploadsByCreatedByListArgs = {
  condition?: InputMaybe<MassUserUploadCondition>;
  filter?: InputMaybe<MassUserUploadFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MassUserUploadsOrderBy>>;
};


/** Public user profile information */
export type UserOrganizationCoachingTimeHistoriesByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** Public user profile information */
export type UserOrganizationCoachingTimeHistoriesByCreatedByListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeHistoryCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeHistoriesOrderBy>>;
};


/** Public user profile information */
export type UserOrganizationCoachingTimeLogsByConsultantIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Public user profile information */
export type UserOrganizationCoachingTimeLogsByConsultantIdListArgs = {
  condition?: InputMaybe<OrganizationCoachingTimeLogCondition>;
  filter?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<OrganizationCoachingTimeLogsOrderBy>>;
};


/** Public user profile information */
export type UserProjectAccessLogsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** Public user profile information */
export type UserProjectAccessLogsListArgs = {
  condition?: InputMaybe<ProjectAccessLogCondition>;
  filter?: InputMaybe<ProjectAccessLogFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectAccessLogsOrderBy>>;
};


/** Public user profile information */
export type UserProjectCommentsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** Public user profile information */
export type UserProjectCommentsListArgs = {
  condition?: InputMaybe<ProjectCommentCondition>;
  filter?: InputMaybe<ProjectCommentFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectCommentsOrderBy>>;
};


/** Public user profile information */
export type UserProjectFoldersArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Public user profile information */
export type UserProjectFoldersListArgs = {
  condition?: InputMaybe<ProjectFolderCondition>;
  filter?: InputMaybe<ProjectFolderFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectFoldersOrderBy>>;
};


/** Public user profile information */
export type UserProjectInvitationsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** Public user profile information */
export type UserProjectInvitationsListArgs = {
  condition?: InputMaybe<ProjectInvitationCondition>;
  filter?: InputMaybe<ProjectInvitationFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectInvitationsOrderBy>>;
};


/** Public user profile information */
export type UserProjectMembershipsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Public user profile information */
export type UserProjectMembershipsByCreatedByUserIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Public user profile information */
export type UserProjectMembershipsByCreatedByUserIdListArgs = {
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Public user profile information */
export type UserProjectMembershipsListArgs = {
  condition?: InputMaybe<ProjectMembershipCondition>;
  filter?: InputMaybe<ProjectMembershipFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectMembershipsOrderBy>>;
};


/** Public user profile information */
export type UserProjectsByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Public user profile information */
export type UserProjectsByCreatedByListArgs = {
  condition?: InputMaybe<ProjectCondition>;
  filter?: InputMaybe<ProjectFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectsOrderBy>>;
};


/** Public user profile information */
export type UserSurveyResponsesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<SurveyResponseCondition>;
  filter?: InputMaybe<SurveyResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};


/** Public user profile information */
export type UserSurveyResponsesListArgs = {
  condition?: InputMaybe<SurveyResponseCondition>;
  filter?: InputMaybe<SurveyResponseFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<SurveyResponsesOrderBy>>;
};


/** Public user profile information */
export type UserVariableClassesByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** Public user profile information */
export type UserVariableClassesByCreatedByListArgs = {
  condition?: InputMaybe<VariableClassCondition>;
  filter?: InputMaybe<VariableClassFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<IncludeArchivedOption>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VariableClassesOrderBy>>;
};


/** Public user profile information */
export type UserVideosByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};


/** Public user profile information */
export type UserVideosByCreatedByListArgs = {
  condition?: InputMaybe<VideoCondition>;
  filter?: InputMaybe<VideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideosOrderBy>>;
};


/** Public user profile information */
export type UserWorkshopsByCreatedByArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};


/** Public user profile information */
export type UserWorkshopsByCreatedByListArgs = {
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

/** The `account` to be created by this mutation. */
export type UserAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `UserInput` mutation. */
export type UserAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnUserForUserAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnUserForUserAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnUserForUserAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `user` in the `AccountInput` mutation. */
export type UserAccountIdFkeyInverseInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<Array<UserUserEmailKeyConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserUserPkeyConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<UserNodeIdConnect>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<Array<UserOnUserForUserAccountIdFkeyUsingUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<Array<UserOnUserForUserAccountIdFkeyUsingUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnUserForUserAccountIdFkeyNodeIdUpdate>>;
};

/** An audit log where we store all the events we are interested in */
export type UserAuditLogEvent = {
  __typename?: 'UserAuditLogEvent';
  /** When was the event recorded? */
  createdAt: Scalars['Datetime'];
  /** Data related to the event */
  eventData: Scalars['JSON'];
  /** What type of event it was? */
  eventType: Scalars['String'];
  /** Primary ID for the audit log event */
  id: Scalars['UUID'];
  /** User related to the event */
  userId: Scalars['UUID'];
};

/** A filter to be used against `UserAuditLogEvent` object types. All fields are combined with a logical ‘and.’ */
export type UserAuditLogEventFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAuditLogEventFilter>>;
  /** Filter by the object’s `eventType` field. */
  eventType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserAuditLogEventFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAuditLogEventFilter>>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** A connection to a list of `UserAuditLogEvent` values. */
export type UserAuditLogEventsConnection = {
  __typename?: 'UserAuditLogEventsConnection';
  /** A list of edges which contains the `UserAuditLogEvent` and cursor to aid in pagination. */
  edges: Array<UserAuditLogEventsEdge>;
  /** A list of `UserAuditLogEvent` objects. */
  nodes: Array<UserAuditLogEvent>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAuditLogEvent` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserAuditLogEvent` edge in the connection. */
export type UserAuditLogEventsEdge = {
  __typename?: 'UserAuditLogEventsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `UserAuditLogEvent` at the end of the edge. */
  node: UserAuditLogEvent;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['Email']>;
  /** Checks for equality with the object’s `fullname` field. */
  fullname?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `gameLv1SubgroupId` field. */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isLms` field. */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isLv1` field. */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isNpt` field. */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lv1SubgroupAdmin` field. */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `role` field. */
  role?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `workshopsCount` field. */
  workshopsCount?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `accessNpt` field. */
  accessNpt?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `classUsers` relation. */
  classUsers?: InputMaybe<UserToManyClassUserFilter>;
  /** Some related `classUsers` exist. */
  classUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classVideoUsers` relation. */
  classVideoUsers?: InputMaybe<UserToManyClassVideoUserFilter>;
  /** Some related `classVideoUsers` exist. */
  classVideoUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classesByCreatedBy` relation. */
  classesByCreatedBy?: InputMaybe<UserToManyClassFilter>;
  /** Some related `classesByCreatedBy` exist. */
  classesByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `connections` relation. */
  connections?: InputMaybe<UserToManyConnectionFilter>;
  /** Some related `connections` exist. */
  connectionsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `consultantOrganizationAccesses` relation. */
  consultantOrganizationAccesses?: InputMaybe<UserToManyConsultantOrganizationAccessFilter>;
  /** Some related `consultantOrganizationAccesses` exist. */
  consultantOrganizationAccessesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `documentsByCreatedBy` relation. */
  documentsByCreatedBy?: InputMaybe<UserToManyDocumentFilter>;
  /** Some related `documentsByCreatedBy` exist. */
  documentsByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<EmailFilter>;
  /** Filter by the object’s `feedbackResponses` relation. */
  feedbackResponses?: InputMaybe<UserToManyFeedbackResponseFilter>;
  /** Some related `feedbackResponses` exist. */
  feedbackResponsesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `fullname` field. */
  fullname?: InputMaybe<StringFilter>;
  /** Filter by the object’s `gameLv1Subgroup` relation. */
  gameLv1Subgroup?: InputMaybe<Lv1GameSubgroupFilter>;
  /** A related `gameLv1Subgroup` exists. */
  gameLv1SubgroupExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `gameLv1SubgroupId` field. */
  gameLv1SubgroupId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `googleAccountUsers` relation. */
  googleAccountUsers?: InputMaybe<UserToManyGoogleAccountUserFilter>;
  /** Some related `googleAccountUsers` exist. */
  googleAccountUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `googleCalendarEvents` relation. */
  googleCalendarEvents?: InputMaybe<UserToManyGoogleCalendarEventFilter>;
  /** Some related `googleCalendarEvents` exist. */
  googleCalendarEventsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isLms` field. */
  isLms?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isLv1` field. */
  isLv1?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNpt` field. */
  isNpt?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSso` field. */
  isSso?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastSignin` field. */
  lastSignin?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lv1GameScore` relation. */
  lv1GameScore?: InputMaybe<Lv1GameScoreFilter>;
  /** A related `lv1GameScore` exists. */
  lv1GameScoreExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `lv1GameState` relation. */
  lv1GameState?: InputMaybe<Lv1GameStateFilter>;
  /** A related `lv1GameState` exists. */
  lv1GameStateExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `lv1GameSubgroupUsers` relation. */
  lv1GameSubgroupUsers?: InputMaybe<UserToManyLv1GameSubgroupUserFilter>;
  /** Some related `lv1GameSubgroupUsers` exist. */
  lv1GameSubgroupUsersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `lv1GameSubgroupsByCreatedBy` relation. */
  lv1GameSubgroupsByCreatedBy?: InputMaybe<UserToManyLv1GameSubgroupFilter>;
  /** Some related `lv1GameSubgroupsByCreatedBy` exist. */
  lv1GameSubgroupsByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `lv1SubgroupAdmin` field. */
  lv1SubgroupAdmin?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `massUserUploadsByCreatedBy` relation. */
  massUserUploadsByCreatedBy?: InputMaybe<UserToManyMassUserUploadFilter>;
  /** Some related `massUserUploadsByCreatedBy` exist. */
  massUserUploadsByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `organizationCoachingTimeHistoriesByCreatedBy` relation. */
  organizationCoachingTimeHistoriesByCreatedBy?: InputMaybe<UserToManyOrganizationCoachingTimeHistoryFilter>;
  /** Some related `organizationCoachingTimeHistoriesByCreatedBy` exist. */
  organizationCoachingTimeHistoriesByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `organizationCoachingTimeLogsByConsultantId` relation. */
  organizationCoachingTimeLogsByConsultantId?: InputMaybe<UserToManyOrganizationCoachingTimeLogFilter>;
  /** Some related `organizationCoachingTimeLogsByConsultantId` exist. */
  organizationCoachingTimeLogsByConsultantIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectAccessLogs` relation. */
  projectAccessLogs?: InputMaybe<UserToManyProjectAccessLogFilter>;
  /** Some related `projectAccessLogs` exist. */
  projectAccessLogsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectComments` relation. */
  projectComments?: InputMaybe<UserToManyProjectCommentFilter>;
  /** Some related `projectComments` exist. */
  projectCommentsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectFolders` relation. */
  projectFolders?: InputMaybe<UserToManyProjectFolderFilter>;
  /** Some related `projectFolders` exist. */
  projectFoldersExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectInvitations` relation. */
  projectInvitations?: InputMaybe<UserToManyProjectInvitationFilter>;
  /** Some related `projectInvitations` exist. */
  projectInvitationsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectMemberships` relation. */
  projectMemberships?: InputMaybe<UserToManyProjectMembershipFilter>;
  /** Filter by the object’s `projectMembershipsByCreatedByUserId` relation. */
  projectMembershipsByCreatedByUserId?: InputMaybe<UserToManyProjectMembershipFilter>;
  /** Some related `projectMembershipsByCreatedByUserId` exist. */
  projectMembershipsByCreatedByUserIdExist?: InputMaybe<Scalars['Boolean']>;
  /** Some related `projectMemberships` exist. */
  projectMembershipsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `projectsByCreatedBy` relation. */
  projectsByCreatedBy?: InputMaybe<UserToManyProjectFilter>;
  /** Some related `projectsByCreatedBy` exist. */
  projectsByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `role` field. */
  role?: InputMaybe<StringFilter>;
  /** Filter by the object’s `surveyResponses` relation. */
  surveyResponses?: InputMaybe<UserToManySurveyResponseFilter>;
  /** Some related `surveyResponses` exist. */
  surveyResponsesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `userProfileQuestionnaire` relation. */
  userProfileQuestionnaire?: InputMaybe<UserProfileQuestionnaireFilter>;
  /** A related `userProfileQuestionnaire` exists. */
  userProfileQuestionnaireExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `v1Link` field. */
  v1Link?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `variableClassesByCreatedBy` relation. */
  variableClassesByCreatedBy?: InputMaybe<UserToManyVariableClassFilter>;
  /** Some related `variableClassesByCreatedBy` exist. */
  variableClassesByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `videosByCreatedBy` relation. */
  videosByCreatedBy?: InputMaybe<UserToManyVideoFilter>;
  /** Some related `videosByCreatedBy` exist. */
  videosByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `workshopsByCreatedBy` relation. */
  workshopsByCreatedBy?: InputMaybe<UserToManyWorkshopFilter>;
  /** Some related `workshopsByCreatedBy` exist. */
  workshopsByCreatedByExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `workshopsCount` field. */
  workshopsCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `wsParticipant` field. */
  wsParticipant?: InputMaybe<BooleanFilter>;
};

/** Input for the nested mutation of `lv1GameSubgroup` in the `Lv1GameSubgroupUserInput` mutation. */
export type UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput = {
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectById?: InputMaybe<Lv1GameSubgroupGameLv1SubgroupPkeyConnect>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Lv1GameSubgroupNodeIdConnect>;
  /** A `Lv1GameSubgroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyLv1GameSubgroupCreateInput>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Lv1GameSubgroupGameLv1SubgroupPkeyDelete>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Lv1GameSubgroupNodeIdDelete>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateById?: InputMaybe<Lv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyUsingGameLv1SubgroupPkeyUpdate>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameSubgroupUser` in the `Lv1GameSubgroupInput` mutation. */
export type UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput = {
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<Lv1GameSubgroupUserNodeIdConnect>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  connectByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserUniqUserGameLv1SubgroupConnect>>;
  /** A `Lv1GameSubgroupUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<UserGameLv1SubgroupGameLv1SubgroupIdFkeyLv1GameSubgroupUserCreateInput>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<Lv1GameSubgroupUserNodeIdDelete>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  deleteByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserUniqUserGameLv1SubgroupDelete>>;
  /** Flag indicating whether all other `lv1GameSubgroupUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameSubgroupUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<Lv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `lv1GameSubgroupUser` for the far side of the relationship. */
  updateByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyUsingUniqUserGameLv1SubgroupUpdate>>;
};

/** The `lv1GameSubgroup` to be created by this mutation. */
export type UserGameLv1SubgroupGameLv1SubgroupIdFkeyLv1GameSubgroupCreateInput = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** The `lv1GameSubgroupUser` to be created by this mutation. */
export type UserGameLv1SubgroupGameLv1SubgroupIdFkeyLv1GameSubgroupUserCreateInput = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** Input for the nested mutation of `lv1GameSubgroup` in the `UserInput` mutation. */
export type UserGameLv1SubgroupIdFkeyInput = {
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectById?: InputMaybe<Lv1GameSubgroupGameLv1SubgroupPkeyConnect>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Lv1GameSubgroupNodeIdConnect>;
  /** A `Lv1GameSubgroupInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserGameLv1SubgroupIdFkeyLv1GameSubgroupCreateInput>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteById?: InputMaybe<Lv1GameSubgroupGameLv1SubgroupPkeyDelete>;
  /** The primary key(s) for `lv1GameSubgroup` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Lv1GameSubgroupNodeIdDelete>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateById?: InputMaybe<Lv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyUsingGameLv1SubgroupPkeyUpdate>;
  /** The primary key(s) and patch data for `lv1GameSubgroup` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnUserForUserGameLv1SubgroupIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `user` in the `Lv1GameSubgroupInput` mutation. */
export type UserGameLv1SubgroupIdFkeyInverseInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<Array<UserUserEmailKeyConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserUserPkeyConnect>>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<UserNodeIdConnect>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<Array<UserOnUserForUserGameLv1SubgroupIdFkeyUsingUserEmailKeyUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<Array<UserOnUserForUserGameLv1SubgroupIdFkeyUsingUserPkeyUpdate>>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<Lv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyNodeIdUpdate>>;
};

/** The `lv1GameSubgroup` to be created by this mutation. */
export type UserGameLv1SubgroupIdFkeyLv1GameSubgroupCreateInput = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `Lv1GameSubgroupUserInput` mutation. */
export type UserGameLv1SubgroupUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lv1GameSubgroupUser` in the `UserInput` mutation. */
export type UserGameLv1SubgroupUserIdFkeyInverseInput = {
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<Lv1GameSubgroupUserNodeIdConnect>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  connectByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserUniqUserGameLv1SubgroupConnect>>;
  /** A `Lv1GameSubgroupUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<UserGameLv1SubgroupUserIdFkeyLv1GameSubgroupUserCreateInput>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<Lv1GameSubgroupUserNodeIdDelete>>;
  /** The primary key(s) for `lv1GameSubgroupUser` for the far side of the relationship. */
  deleteByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserUniqUserGameLv1SubgroupDelete>>;
  /** Flag indicating whether all other `lv1GameSubgroupUser` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `lv1GameSubgroupUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `lv1GameSubgroupUser` for the far side of the relationship. */
  updateByUserIdAndGameLv1SubgroupId?: InputMaybe<Array<Lv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUniqUserGameLv1SubgroupUpdate>>;
};

/** The `lv1GameSubgroupUser` to be created by this mutation. */
export type UserGameLv1SubgroupUserIdFkeyLv1GameSubgroupUserCreateInput = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** All of the possible languages a user can select for our system */
export type UserLanguage = {
  __typename?: 'UserLanguage';
  /** Reads and enables pagination through a set of `BriefGroup`. */
  briefGroupsByLanguageCode: BriefGroupsConnection;
  /** Reads and enables pagination through a set of `BriefGroup`. */
  briefGroupsByLanguageCodeList: Array<BriefGroup>;
  /** The name of the language in the corresponding languge, customer facing */
  displayName: Maybe<Scalars['String']>;
  /** ISO 639-1 Language Code, can be used with Weglot and similar services */
  isoLangCode: Maybe<Scalars['String']>;
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
  /** The name of the language in English */
  name: Maybe<Scalars['String']>;
};


/** All of the possible languages a user can select for our system */
export type UserLanguageBriefGroupsByLanguageCodeArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};


/** All of the possible languages a user can select for our system */
export type UserLanguageBriefGroupsByLanguageCodeListArgs = {
  condition?: InputMaybe<BriefGroupCondition>;
  filter?: InputMaybe<BriefGroupFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefGroupsOrderBy>>;
};

/**
 * A condition to be used against `UserLanguage` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserLanguageCondition = {
  /** Checks for equality with the object’s `languageCode` field. */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against `UserLanguage` object types. All fields are combined with a logical ‘and.’ */
export type UserLanguageFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserLanguageFilter>>;
  /** Filter by the object’s `briefGroupsByLanguageCode` relation. */
  briefGroupsByLanguageCode?: InputMaybe<UserLanguageToManyBriefGroupFilter>;
  /** Some related `briefGroupsByLanguageCode` exist. */
  briefGroupsByLanguageCodeExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `languageCode` field. */
  languageCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserLanguageFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserLanguageFilter>>;
};

/** An input for mutations affecting `UserLanguage` */
export type UserLanguageInput = {
  briefGroupsUsingLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInverseInput>;
  /** The name of the language in the corresponding languge, customer facing */
  displayName?: InputMaybe<Scalars['String']>;
  /** ISO 639-1 Language Code, can be used with Weglot and similar services */
  isoLangCode?: InputMaybe<Scalars['String']>;
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
  /** The name of the language in English */
  name?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to update. */
export type UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefGroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefGroup` being updated. */
  patch: BriefGroupPatch;
};

/** The fields on `userLanguage` to look up the row to update. */
export type UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyUsingUniqLanguageCodeUpdate = {
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
  /** An object where the defined keys will be set on the `userLanguage` being updated. */
  patch: UpdateUserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyPatch;
};

/** The fields on `userLanguage` to look up the row to update. */
export type UserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyUsingUniqUserLanguageUpdate = {
  /** The name of the language in English */
  name: Scalars['String'];
  /** An object where the defined keys will be set on the `userLanguage` being updated. */
  patch: UpdateUserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyPatch;
};

/** Represents an update to a `UserLanguage`. Fields that are set will be updated. */
export type UserLanguagePatch = {
  briefGroupsUsingLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInverseInput>;
  /** The name of the language in the corresponding languge, customer facing */
  displayName?: InputMaybe<Scalars['String']>;
  /** ISO 639-1 Language Code, can be used with Weglot and similar services */
  isoLangCode?: InputMaybe<Scalars['String']>;
  /** Full language code as used accross computer systems */
  languageCode?: InputMaybe<Scalars['String']>;
  /** The name of the language in English */
  name?: InputMaybe<Scalars['String']>;
};

/** A filter to be used against many `BriefGroup` object types. All fields are combined with a logical ‘and.’ */
export type UserLanguageToManyBriefGroupFilter = {
  /** Every related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefGroupFilter>;
  /** No related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefGroupFilter>;
  /** Some related `BriefGroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefGroupFilter>;
};

/** The fields on `userLanguage` to look up the row to connect. */
export type UserLanguageUniqLanguageCodeConnect = {
  /** Full language code as used accross computer systems */
  languageCode: Scalars['String'];
};

/** The fields on `userLanguage` to look up the row to connect. */
export type UserLanguageUniqUserLanguageConnect = {
  /** The name of the language in English */
  name: Scalars['String'];
};

/** A connection to a list of `UserLanguage` values. */
export type UserLanguagesConnection = {
  __typename?: 'UserLanguagesConnection';
  /** A list of edges which contains the `UserLanguage` and cursor to aid in pagination. */
  edges: Array<UserLanguagesEdge>;
  /** A list of `UserLanguage` objects. */
  nodes: Array<UserLanguage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserLanguage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserLanguage` edge in the connection. */
export type UserLanguagesEdge = {
  __typename?: 'UserLanguagesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `UserLanguage` at the end of the edge. */
  node: UserLanguage;
};

/** Methods to use when ordering `UserLanguage`. */
export enum UserLanguagesOrderBy {
  LanguageCodeAsc = 'LANGUAGE_CODE_ASC',
  LanguageCodeDesc = 'LANGUAGE_CODE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL'
}

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnClassForClassCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `class` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `class` being updated. */
  patch: ClassPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassForClassCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassForClassCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassForClassCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassForClassCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnClassUserForClassUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classUser` being updated. */
  patch: ClassUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassUserForClassUserUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassUserForClassUserUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassUserForClassUserUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassUserForClassUserUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnClassVideoUserForClassVideoUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classVideoUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classVideoUser` being updated. */
  patch: ClassVideoUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnClassVideoUserForClassVideoUserUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnConnectionForConnectionUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `connection` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `connection` being updated. */
  patch: ConnectionPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnConnectionForConnectionUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnConnectionForConnectionUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnConnectionForConnectionUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnConnectionForConnectionUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `consultantOrganizationAccess` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
  patch: ConsultantOrganizationAccessPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnDocumentForDocumentCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `document` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `document` being updated. */
  patch: DocumentPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnDocumentForDocumentCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnDocumentForDocumentCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnDocumentForDocumentCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnDocumentForDocumentCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnFeedbackResponseForFeedbackResponseUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `feedbackResponse` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `feedbackResponse` being updated. */
  patch: FeedbackResponsePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnFeedbackResponseForFeedbackResponseUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleAccountUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleAccountUser` being updated. */
  patch: GoogleAccountUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `googleCalendarEvent` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
  patch: GoogleCalendarEventPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameScore` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameScore` being updated. */
  patch: Lv1GameScorePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameScoreForGameLv1ScoreUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameScoreForGameLv1ScoreUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameScoreForGameLv1ScoreUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnLv1GameStateForGameLv1StateUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameState` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameState` being updated. */
  patch: Lv1GameStatePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameStateForGameLv1StateUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameStateForGameLv1StateUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameStateForGameLv1StateUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameStateForGameLv1StateUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroupUser` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
  patch: Lv1GameSubgroupUserPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnMassUserUploadForMassUserUploadCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `massUserUpload` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `massUserUpload` being updated. */
  patch: MassUserUploadPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnMassUserUploadForMassUserUploadCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnMassUserUploadForMassUserUploadCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnMassUserUploadForMassUserUploadCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnMassUserUploadForMassUserUploadCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organizationCoachingTimeLog` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
  patch: OrganizationCoachingTimeLogPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectAccessLogForProjectAccessLogUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectAccessLogForProjectAccessLogUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectAccessLogForProjectAccessLogUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnProjectCommentForProjectCommentUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectComment` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectComment` being updated. */
  patch: ProjectCommentPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectCommentForProjectCommentUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectCommentForProjectCommentUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectCommentForProjectCommentUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectCommentForProjectCommentUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectFolderForProjectFolderUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectFolderForProjectFolderUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectFolderForProjectFolderUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectFolderForProjectFolderUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnProjectForProjectCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectForProjectCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectForProjectCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectForProjectCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectForProjectCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnProjectInvitationForProjectInvitationUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectInvitation` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectInvitation` being updated. */
  patch: ProjectInvitationPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectInvitationForProjectInvitationUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectInvitationForProjectInvitationUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectInvitationForProjectInvitationUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectInvitationForProjectInvitationUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectMembership` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: ProjectMembershipPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnProjectMembershipForProjectMembershipUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectMembership` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectMembership` being updated. */
  patch: ProjectMembershipPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectMembershipForProjectMembershipUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectMembershipForProjectMembershipUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnProjectMembershipForProjectMembershipUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnProjectMembershipForProjectMembershipUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnSurveyResponseForSurveyResponseUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `surveyResponse` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `surveyResponse` being updated. */
  patch: SurveyResponsePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnSurveyResponseForSurveyResponseUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnSurveyResponseForSurveyResponseUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnSurveyResponseForSurveyResponseUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnSurveyResponseForSurveyResponseUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserForUserAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForUserAccountIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForUserAccountIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForUserAccountIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForUserAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserForUserGameLv1SubgroupIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lv1GameSubgroup` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
  patch: Lv1GameSubgroupPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForUserGameLv1SubgroupIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForUserGameLv1SubgroupIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserForUserGameLv1SubgroupIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserForUserGameLv1SubgroupIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userProfileQuestionnaire` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `userProfileQuestionnaire` being updated. */
  patch: UserProfileQuestionnairePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnVariableClassForVariableClassCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `variableClass` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: VariableClassPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnVariableClassForVariableClassCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnVariableClassForVariableClassCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnVariableClassForVariableClassCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnVariableClassForVariableClassCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnVideoForVideoCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `video` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: VideoPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnVideoForVideoCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnVideoForVideoCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnVideoForVideoCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnVideoForVideoCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnWorkshopForWorkshopCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `workshop` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `workshop` being updated. */
  patch: WorkshopPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnWorkshopForWorkshopCreatedByFkeyUsingUserEmailKeyUpdate = {
  /** Unique email address */
  email: Scalars['Email'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnWorkshopForWorkshopCreatedByFkeyPatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnWorkshopForWorkshopCreatedByFkeyUsingUserPkeyUpdate = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UpdateUserOnWorkshopForWorkshopCreatedByFkeyPatch;
};

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

export type UserProfileQuestionnaire = Node & {
  __typename?: 'UserProfileQuestionnaire';
  biggestEnablers: Maybe<Scalars['String']>;
  biggestObstacles: Maybe<Scalars['String']>;
  confidenceLevel: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  effectiveNegotiator: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  negotiateWith: Maybe<Scalars['String']>;
  negotiation: Maybe<YesNoOption>;
  negotiatorStyle: Maybe<NegotiatorStyle>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  training: Maybe<YesNoOption>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserProfileQuestionnaire`. */
  user: Maybe<User>;
  userId: Scalars['UUID'];
  variables: Maybe<Scalars['String']>;
  whenTraining: Maybe<Scalars['String']>;
  withWho: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `UserProfileQuestionnaire` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UserProfileQuestionnaireCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `UserProfileQuestionnaire` object types. All fields are combined with a logical ‘and.’ */
export type UserProfileQuestionnaireFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserProfileQuestionnaireFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserProfileQuestionnaireFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserProfileQuestionnaireFilter>>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `UserProfileQuestionnaire` */
export type UserProfileQuestionnaireInput = {
  biggestEnablers?: InputMaybe<Scalars['String']>;
  biggestObstacles?: InputMaybe<Scalars['String']>;
  confidenceLevel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  effectiveNegotiator?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  negotiateWith?: InputMaybe<Scalars['String']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInput>;
  variables?: InputMaybe<Scalars['String']>;
  whenTraining?: InputMaybe<Scalars['String']>;
  withWho?: InputMaybe<Scalars['String']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserProfileQuestionnaireNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userProfileQuestionnaire` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserProfileQuestionnaireNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userProfileQuestionnaire` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `userProfileQuestionnaire` to look up the row to update. */
export type UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserProfileQuestionnairePkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `userProfileQuestionnaire` being updated. */
  patch: UpdateUserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch;
};

/** The fields on `userProfileQuestionnaire` to look up the row to update. */
export type UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserProfileQuestionnaireUserIdKeyUpdate = {
  /** An object where the defined keys will be set on the `userProfileQuestionnaire` being updated. */
  patch: UpdateUserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch;
  userId: Scalars['UUID'];
};

/** Represents an update to a `UserProfileQuestionnaire`. Fields that are set will be updated. */
export type UserProfileQuestionnairePatch = {
  biggestEnablers?: InputMaybe<Scalars['String']>;
  biggestObstacles?: InputMaybe<Scalars['String']>;
  confidenceLevel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  effectiveNegotiator?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  negotiateWith?: InputMaybe<Scalars['String']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInput>;
  variables?: InputMaybe<Scalars['String']>;
  whenTraining?: InputMaybe<Scalars['String']>;
  withWho?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `user` in the `UserProfileQuestionnaireInput` mutation. */
export type UserProfileQuestionnaireUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `userProfileQuestionnaire` in the `UserInput` mutation. */
export type UserProfileQuestionnaireUserIdFkeyInverseInput = {
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  connectById?: InputMaybe<UserProfileQuestionnaireUserProfileQuestionnairePkeyConnect>;
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserProfileQuestionnaireNodeIdConnect>;
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserProfileQuestionnaireUserProfileQuestionnaireUserIdKeyConnect>;
  /** A `UserProfileQuestionnaireInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<UserProfileQuestionnaireUserIdFkeyUserProfileQuestionnaireCreateInput>>;
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  deleteById?: InputMaybe<UserProfileQuestionnaireUserProfileQuestionnairePkeyDelete>;
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<UserProfileQuestionnaireNodeIdDelete>;
  /** The primary key(s) for `userProfileQuestionnaire` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserProfileQuestionnaireUserProfileQuestionnaireUserIdKeyDelete>;
  /** Flag indicating whether all other `userProfileQuestionnaire` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `userProfileQuestionnaire` for the far side of the relationship. */
  updateById?: InputMaybe<UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserProfileQuestionnairePkeyUpdate>;
  /** The primary key(s) and patch data for `userProfileQuestionnaire` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `userProfileQuestionnaire` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyUsingUserProfileQuestionnaireUserIdKeyUpdate>;
};

/** The `userProfileQuestionnaire` to be created by this mutation. */
export type UserProfileQuestionnaireUserIdFkeyUserProfileQuestionnaireCreateInput = {
  biggestEnablers?: InputMaybe<Scalars['String']>;
  biggestObstacles?: InputMaybe<Scalars['String']>;
  confidenceLevel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  effectiveNegotiator?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  negotiateWith?: InputMaybe<Scalars['String']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInput>;
  variables?: InputMaybe<Scalars['String']>;
  whenTraining?: InputMaybe<Scalars['String']>;
  withWho?: InputMaybe<Scalars['String']>;
};

/** The fields on `userProfileQuestionnaire` to look up the row to connect. */
export type UserProfileQuestionnaireUserProfileQuestionnairePkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `userProfileQuestionnaire` to look up the row to delete. */
export type UserProfileQuestionnaireUserProfileQuestionnairePkeyDelete = {
  id: Scalars['UUID'];
};

/** The fields on `userProfileQuestionnaire` to look up the row to connect. */
export type UserProfileQuestionnaireUserProfileQuestionnaireUserIdKeyConnect = {
  userId: Scalars['UUID'];
};

/** The fields on `userProfileQuestionnaire` to look up the row to delete. */
export type UserProfileQuestionnaireUserProfileQuestionnaireUserIdKeyDelete = {
  userId: Scalars['UUID'];
};

/** A connection to a list of `UserProfileQuestionnaire` values. */
export type UserProfileQuestionnairesConnection = {
  __typename?: 'UserProfileQuestionnairesConnection';
  /** A list of edges which contains the `UserProfileQuestionnaire` and cursor to aid in pagination. */
  edges: Array<UserProfileQuestionnairesEdge>;
  /** A list of `UserProfileQuestionnaire` objects. */
  nodes: Array<UserProfileQuestionnaire>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserProfileQuestionnaire` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserProfileQuestionnaire` edge in the connection. */
export type UserProfileQuestionnairesEdge = {
  __typename?: 'UserProfileQuestionnairesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `UserProfileQuestionnaire` at the end of the edge. */
  node: UserProfileQuestionnaire;
};

/** Methods to use when ordering `UserProfileQuestionnaire`. */
export enum UserProfileQuestionnairesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC'
}

export type UserSubscriptionPayload = {
  __typename?: 'UserSubscriptionPayload';
  event: Maybe<Scalars['String']>;
  user: Maybe<User>;
};

/** A filter to be used against many `Class` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyClassFilter = {
  /** Every related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassFilter>;
  /** No related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassFilter>;
  /** Some related `Class` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassFilter>;
};

/** A filter to be used against many `ClassUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyClassUserFilter = {
  /** Every related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassUserFilter>;
  /** No related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassUserFilter>;
  /** Some related `ClassUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassUserFilter>;
};

/** A filter to be used against many `ClassVideoUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyClassVideoUserFilter = {
  /** Every related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassVideoUserFilter>;
  /** No related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassVideoUserFilter>;
  /** Some related `ClassVideoUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassVideoUserFilter>;
};

/** A filter to be used against many `Connection` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyConnectionFilter = {
  /** Every related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConnectionFilter>;
  /** No related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConnectionFilter>;
  /** Some related `Connection` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConnectionFilter>;
};

/** A filter to be used against many `ConsultantOrganizationAccess` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyConsultantOrganizationAccessFilter = {
  /** Every related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConsultantOrganizationAccessFilter>;
  /** No related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConsultantOrganizationAccessFilter>;
  /** Some related `ConsultantOrganizationAccess` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConsultantOrganizationAccessFilter>;
};

/** A filter to be used against many `Document` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyDocumentFilter = {
  /** Every related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentFilter>;
  /** No related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentFilter>;
  /** Some related `Document` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentFilter>;
};

/** A filter to be used against many `FeedbackResponse` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyFeedbackResponseFilter = {
  /** Every related `FeedbackResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<FeedbackResponseFilter>;
  /** No related `FeedbackResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<FeedbackResponseFilter>;
  /** Some related `FeedbackResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<FeedbackResponseFilter>;
};

/** A filter to be used against many `GoogleAccountUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyGoogleAccountUserFilter = {
  /** Every related `GoogleAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GoogleAccountUserFilter>;
  /** No related `GoogleAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GoogleAccountUserFilter>;
  /** Some related `GoogleAccountUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GoogleAccountUserFilter>;
};

/** A filter to be used against many `GoogleCalendarEvent` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyGoogleCalendarEventFilter = {
  /** Every related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<GoogleCalendarEventFilter>;
  /** No related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<GoogleCalendarEventFilter>;
  /** Some related `GoogleCalendarEvent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<GoogleCalendarEventFilter>;
};

/** A filter to be used against many `Lv1GameSubgroup` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLv1GameSubgroupFilter = {
  /** Every related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<Lv1GameSubgroupFilter>;
  /** No related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<Lv1GameSubgroupFilter>;
  /** Some related `Lv1GameSubgroup` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<Lv1GameSubgroupFilter>;
};

/** A filter to be used against many `Lv1GameSubgroupUser` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLv1GameSubgroupUserFilter = {
  /** Every related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<Lv1GameSubgroupUserFilter>;
  /** No related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<Lv1GameSubgroupUserFilter>;
  /** Some related `Lv1GameSubgroupUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<Lv1GameSubgroupUserFilter>;
};

/** A filter to be used against many `MassUserUpload` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyMassUserUploadFilter = {
  /** Every related `MassUserUpload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MassUserUploadFilter>;
  /** No related `MassUserUpload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MassUserUploadFilter>;
  /** Some related `MassUserUpload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MassUserUploadFilter>;
};

/** A filter to be used against many `OrganizationCoachingTimeHistory` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyOrganizationCoachingTimeHistoryFilter = {
  /** Every related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  /** No related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
  /** Some related `OrganizationCoachingTimeHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationCoachingTimeHistoryFilter>;
};

/** A filter to be used against many `OrganizationCoachingTimeLog` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyOrganizationCoachingTimeLogFilter = {
  /** Every related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** No related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationCoachingTimeLogFilter>;
  /** Some related `OrganizationCoachingTimeLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationCoachingTimeLogFilter>;
};

/** A filter to be used against many `ProjectAccessLog` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectAccessLogFilter = {
  /** Every related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectAccessLogFilter>;
  /** No related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectAccessLogFilter>;
  /** Some related `ProjectAccessLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectAccessLogFilter>;
};

/** A filter to be used against many `ProjectComment` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectCommentFilter = {
  /** Every related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectCommentFilter>;
  /** No related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectCommentFilter>;
  /** Some related `ProjectComment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectCommentFilter>;
};

/** A filter to be used against many `Project` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectFilter = {
  /** Every related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFilter>;
  /** No related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFilter>;
  /** Some related `Project` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFilter>;
};

/** A filter to be used against many `ProjectFolder` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectFolderFilter = {
  /** Every related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectFolderFilter>;
  /** No related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectFolderFilter>;
  /** Some related `ProjectFolder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectFolderFilter>;
};

/** A filter to be used against many `ProjectInvitation` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectInvitationFilter = {
  /** Every related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectInvitationFilter>;
  /** No related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectInvitationFilter>;
  /** Some related `ProjectInvitation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectInvitationFilter>;
};

/** A filter to be used against many `ProjectMembership` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyProjectMembershipFilter = {
  /** Every related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectMembershipFilter>;
  /** No related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectMembershipFilter>;
  /** Some related `ProjectMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectMembershipFilter>;
};

/** A filter to be used against many `SurveyResponse` object types. All fields are combined with a logical ‘and.’ */
export type UserToManySurveyResponseFilter = {
  /** Every related `SurveyResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SurveyResponseFilter>;
  /** No related `SurveyResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SurveyResponseFilter>;
  /** Some related `SurveyResponse` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SurveyResponseFilter>;
};

/** A filter to be used against many `VariableClass` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyVariableClassFilter = {
  /** Every related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VariableClassFilter>;
  /** No related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VariableClassFilter>;
  /** Some related `VariableClass` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VariableClassFilter>;
};

/** A filter to be used against many `Video` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyVideoFilter = {
  /** Every related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VideoFilter>;
  /** No related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VideoFilter>;
  /** Some related `Video` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VideoFilter>;
};

/** A filter to be used against many `Workshop` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyWorkshopFilter = {
  /** Every related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkshopFilter>;
  /** No related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkshopFilter>;
  /** Some related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkshopFilter>;
};

/** The fields on `user` to look up the row to connect. */
export type UserUserEmailKeyConnect = {
  /** Unique email address */
  email: Scalars['Email'];
};

/** The fields on `user` to look up the row to connect. */
export type UserUserPkeyConnect = {
  /** Unique ID for the user */
  id: Scalars['UUID'];
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  AccountNameAsc = 'ACCOUNT_NAME_ASC',
  AccountNameDesc = 'ACCOUNT_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  FullnameAsc = 'FULLNAME_ASC',
  FullnameDesc = 'FULLNAME_DESC',
  GameLv1SubgroupIdAsc = 'GAME_LV1_SUBGROUP_ID_ASC',
  GameLv1SubgroupIdDesc = 'GAME_LV1_SUBGROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsLmsAsc = 'IS_LMS_ASC',
  IsLmsDesc = 'IS_LMS_DESC',
  IsLv1Asc = 'IS_LV1_ASC',
  IsLv1Desc = 'IS_LV1_DESC',
  IsNptAsc = 'IS_NPT_ASC',
  IsNptDesc = 'IS_NPT_DESC',
  LastSigninAsc = 'LAST_SIGNIN_ASC',
  LastSigninDesc = 'LAST_SIGNIN_DESC',
  Lv1SubgroupAdminAsc = 'LV1_SUBGROUP_ADMIN_ASC',
  Lv1SubgroupAdminDesc = 'LV1_SUBGROUP_ADMIN_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC'
}

export type V1Role = Node & {
  __typename?: 'V1Role';
  /** The ID of the role in v1 */
  id: Scalars['Int'];
  /** The name of the v1 role */
  name: Maybe<Scalars['String']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** A condition to be used against `V1Role` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type V1RoleCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
};

/** A filter to be used against `V1Role` object types. All fields are combined with a logical ‘and.’ */
export type V1RoleFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<V1RoleFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<V1RoleFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<V1RoleFilter>>;
};

/** An input for mutations affecting `V1Role` */
export type V1RoleInput = {
  /** The ID of the role in v1 */
  id: Scalars['Int'];
  /** The name of the v1 role */
  name?: InputMaybe<Scalars['String']>;
};

/** Represents an update to a `V1Role`. Fields that are set will be updated. */
export type V1RolePatch = {
  /** The ID of the role in v1 */
  id?: InputMaybe<Scalars['Int']>;
  /** The name of the v1 role */
  name?: InputMaybe<Scalars['String']>;
};

/** A connection to a list of `V1Role` values. */
export type V1RolesConnection = {
  __typename?: 'V1RolesConnection';
  /** A list of edges which contains the `V1Role` and cursor to aid in pagination. */
  edges: Array<V1RolesEdge>;
  /** A list of `V1Role` objects. */
  nodes: Array<V1Role>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `V1Role` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `V1Role` edge in the connection. */
export type V1RolesEdge = {
  __typename?: 'V1RolesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `V1Role` at the end of the edge. */
  node: V1Role;
};

/** Methods to use when ordering `V1Role`. */
export enum V1RolesOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `v1SsoSignin` mutation. */
export type V1SsoSigninInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['Email'];
};

/** The output of our `v1SsoSignin` mutation. */
export type V1SsoSigninPayload = {
  __typename?: 'V1SsoSigninPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  jwt: Maybe<Scalars['Jwt']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The `account` to be created by this mutation. */
export type VariableAccountIdFkeyAccountCreateInput = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `account` in the `VariableClassInput` mutation. */
export type VariableAccountIdFkeyInput = {
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectById?: InputMaybe<AccountAccountPkeyConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByName?: InputMaybe<AccountUniqAccountNameConnect>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AccountNodeIdConnect>;
  /** A `AccountInput` object that will be created and connected to this object. */
  create?: InputMaybe<VariableAccountIdFkeyAccountCreateInput>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteById?: InputMaybe<AccountAccountPkeyDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByName?: InputMaybe<AccountUniqAccountNameDelete>;
  /** The primary key(s) for `account` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AccountNodeIdDelete>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateById?: InputMaybe<AccountOnVariableClassForVariableAccountIdFkeyUsingAccountPkeyUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByName?: InputMaybe<AccountOnVariableClassForVariableAccountIdFkeyUsingUniqAccountNameUpdate>;
  /** The primary key(s) and patch data for `account` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VariableClassOnVariableClassForVariableAccountIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `variableClass` in the `AccountInput` mutation. */
export type VariableAccountIdFkeyInverseInput = {
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VariableClassVariablePkeyConnect>>;
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VariableClassNodeIdConnect>>;
  /** A `VariableClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VariableAccountIdFkeyVariableClassCreateInput>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VariableClassOnVariableClassForVariableAccountIdFkeyUsingVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<AccountOnVariableClassForVariableAccountIdFkeyNodeIdUpdate>>;
};

/** The `variableClass` to be created by this mutation. */
export type VariableAccountIdFkeyVariableClassCreateInput = {
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name: Scalars['String'];
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** Variables - per account */
export type VariableClass = Node & {
  __typename?: 'VariableClass';
  /** Reads a single `Account` that is related to this `VariableClass`. */
  account: Maybe<Account>;
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId: Maybe<Scalars['UUID']>;
  /** When was this variable creatd? */
  createdAt: Scalars['Datetime'];
  /** Creator of the variable class, related to the users model */
  createdBy: Maybe<Scalars['UUID']>;
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** Is this variable class archived? */
  isArchived: Scalars['Boolean'];
  /** Is this variable deleted? */
  isDeleted: Scalars['Boolean'];
  /** Is this variable class global for the account? */
  isGlobal: Scalars['Boolean'];
  /** Variable Class name */
  name: Scalars['String'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads and enables pagination through a set of `ProjectVariable`. */
  projectVariables: ProjectVariablesConnection;
  /** Reads and enables pagination through a set of `ProjectVariable`. */
  projectVariablesList: Array<ProjectVariable>;
  /** Reads a single `Project` that is related to this `VariableClass`. */
  relatedProject: Maybe<Project>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId: Maybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `VariableClass`. */
  userByCreatedBy: Maybe<User>;
};


/** Variables - per account */
export type VariableClassProjectVariablesArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};


/** Variables - per account */
export type VariableClassProjectVariablesListArgs = {
  condition?: InputMaybe<ProjectVariableCondition>;
  filter?: InputMaybe<ProjectVariableFilter>;
  first?: InputMaybe<Scalars['Int']>;
  includeDeleted?: InputMaybe<IncludeDeletedOption>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ProjectVariablesOrderBy>>;
};

/**
 * A condition to be used against `VariableClass` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type VariableClassCondition = {
  /** Checks for equality with the object’s `accountId` field. */
  accountId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isGlobal` field. */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']>;
  /** Checks for equality with the object’s `relatedProjectId` field. */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `user` in the `VariableClassInput` mutation. */
export type VariableClassCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnVariableClassForVariableClassCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnVariableClassForVariableClassCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VariableClassOnVariableClassForVariableClassCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `variableClass` in the `UserInput` mutation. */
export type VariableClassCreatedByFkeyInverseInput = {
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VariableClassVariablePkeyConnect>>;
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VariableClassNodeIdConnect>>;
  /** A `VariableClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VariableClassCreatedByFkeyVariableClassCreateInput>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VariableClassOnVariableClassForVariableClassCreatedByFkeyUsingVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnVariableClassForVariableClassCreatedByFkeyNodeIdUpdate>>;
};

/** The `variableClass` to be created by this mutation. */
export type VariableClassCreatedByFkeyVariableClassCreateInput = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name: Scalars['String'];
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** A filter to be used against `VariableClass` object types. All fields are combined with a logical ‘and.’ */
export type VariableClassFilter = {
  /** Filter by the object’s `account` relation. */
  account?: InputMaybe<AccountFilter>;
  /** A related `account` exists. */
  accountExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `accountId` field. */
  accountId?: InputMaybe<UuidFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VariableClassFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isGlobal` field. */
  isGlobal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VariableClassFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VariableClassFilter>>;
  /** Filter by the object’s `projectVariables` relation. */
  projectVariables?: InputMaybe<VariableClassToManyProjectVariableFilter>;
  /** Some related `projectVariables` exist. */
  projectVariablesExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `relatedProject` relation. */
  relatedProject?: InputMaybe<ProjectFilter>;
  /** A related `relatedProject` exists. */
  relatedProjectExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `relatedProjectId` field. */
  relatedProjectId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `VariableClass` */
export type VariableClassInput = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name: Scalars['String'];
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** All input for the `variableClassMergeVariables` mutation. */
export type VariableClassMergeVariablesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  keepVariableClassId: Scalars['UUID'];
  purgeVariableClassId: Scalars['UUID'];
};

/** The output of our `variableClassMergeVariables` mutation. */
export type VariableClassMergeVariablesPayload = {
  __typename?: 'VariableClassMergeVariablesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VariableClassNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `variableClass` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `projectVariable` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `projectVariable` being updated. */
  patch: ProjectVariablePatch;
};

/** The fields on `variableClass` to look up the row to update. */
export type VariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyUsingVariablePkeyUpdate = {
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: UpdateVariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VariableClassOnVariableClassForVariableAccountIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `account` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `account` being updated. */
  patch: AccountPatch;
};

/** The fields on `variableClass` to look up the row to update. */
export type VariableClassOnVariableClassForVariableAccountIdFkeyUsingVariablePkeyUpdate = {
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: UpdateVariableClassOnVariableClassForVariableAccountIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VariableClassOnVariableClassForVariableClassCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `variableClass` to look up the row to update. */
export type VariableClassOnVariableClassForVariableClassCreatedByFkeyUsingVariablePkeyUpdate = {
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: UpdateVariableClassOnVariableClassForVariableClassCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `project` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `project` being updated. */
  patch: ProjectPatch;
};

/** The fields on `variableClass` to look up the row to update. */
export type VariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyUsingVariablePkeyUpdate = {
  /** Variable Class ID */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `variableClass` being updated. */
  patch: UpdateVariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyPatch;
};

/** Represents an update to a `VariableClass`. Fields that are set will be updated. */
export type VariableClassPatch = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name?: InputMaybe<Scalars['String']>;
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** Input for the nested mutation of `project` in the `VariableClassInput` mutation. */
export type VariableClassRelatedProjectIdFkeyInput = {
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectById?: InputMaybe<ProjectProjectPkeyConnect>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProjectNodeIdConnect>;
  /** A `ProjectInput` object that will be created and connected to this object. */
  create?: InputMaybe<VariableClassRelatedProjectIdFkeyProjectCreateInput>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteById?: InputMaybe<ProjectProjectPkeyDelete>;
  /** The primary key(s) for `project` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ProjectNodeIdDelete>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateById?: InputMaybe<ProjectOnVariableClassForVariableClassRelatedProjectIdFkeyUsingProjectPkeyUpdate>;
  /** The primary key(s) and patch data for `project` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `variableClass` in the `ProjectInput` mutation. */
export type VariableClassRelatedProjectIdFkeyInverseInput = {
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VariableClassVariablePkeyConnect>>;
  /** The primary key(s) for `variableClass` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VariableClassNodeIdConnect>>;
  /** A `VariableClassInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VariableClassRelatedProjectIdFkeyVariableClassCreateInput>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyUsingVariablePkeyUpdate>>;
  /** The primary key(s) and patch data for `variableClass` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ProjectOnVariableClassForVariableClassRelatedProjectIdFkeyNodeIdUpdate>>;
};

/** The `project` to be created by this mutation. */
export type VariableClassRelatedProjectIdFkeyProjectCreateInput = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name: Scalars['String'];
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** The `variableClass` to be created by this mutation. */
export type VariableClassRelatedProjectIdFkeyVariableClassCreateInput = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name: Scalars['String'];
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** A filter to be used against many `ProjectVariable` object types. All fields are combined with a logical ‘and.’ */
export type VariableClassToManyProjectVariableFilter = {
  /** Every related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProjectVariableFilter>;
  /** No related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProjectVariableFilter>;
  /** Some related `ProjectVariable` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProjectVariableFilter>;
};

/** The fields on `variableClass` to look up the row to connect. */
export type VariableClassVariablePkeyConnect = {
  /** Variable Class ID */
  id: Scalars['UUID'];
};

/** A connection to a list of `VariableClass` values. */
export type VariableClassesConnection = {
  __typename?: 'VariableClassesConnection';
  /** A list of edges which contains the `VariableClass` and cursor to aid in pagination. */
  edges: Array<VariableClassesEdge>;
  /** A list of `VariableClass` objects. */
  nodes: Array<VariableClass>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VariableClass` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `VariableClass` edge in the connection. */
export type VariableClassesEdge = {
  __typename?: 'VariableClassesEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `VariableClass` at the end of the edge. */
  node: VariableClass;
};

/** Methods to use when ordering `VariableClass`. */
export enum VariableClassesOrderBy {
  AccountIdAsc = 'ACCOUNT_ID_ASC',
  AccountIdDesc = 'ACCOUNT_ID_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  IsArchivedAsc = 'IS_ARCHIVED_ASC',
  IsArchivedDesc = 'IS_ARCHIVED_DESC',
  IsGlobalAsc = 'IS_GLOBAL_ASC',
  IsGlobalDesc = 'IS_GLOBAL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  RelatedProjectIdAsc = 'RELATED_PROJECT_ID_ASC',
  RelatedProjectIdDesc = 'RELATED_PROJECT_ID_DESC'
}

export enum VariableCost {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  None = 'NONE'
}

export type VariableState = {
  __typename?: 'VariableState';
  cost: Maybe<VariableCost>;
  value: Maybe<VariableCost>;
  walkaway: Maybe<Scalars['String']>;
};

/** An input for mutations affecting `VariableState` */
export type VariableStateInput = {
  cost?: InputMaybe<VariableCost>;
  value?: InputMaybe<VariableCost>;
  walkaway?: InputMaybe<Scalars['String']>;
};

export enum VariableType {
  Get = 'GET',
  GetGive = 'GET_GIVE',
  Give = 'GIVE',
  None = 'NONE'
}

export type Video = Node & {
  __typename?: 'Video';
  /** Reads a single `ClassAssetCategory` that is related to this `Video`. */
  category: Maybe<ClassAssetCategory>;
  categoryId: Scalars['UUID'];
  /** Reads and enables pagination through a set of `ClassVideo`. */
  classVideos: ClassVideosConnection;
  /** Reads and enables pagination through a set of `ClassVideo`. */
  classVideosList: Array<ClassVideo>;
  classes: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  /** User id that created the video */
  createdBy: Scalars['UUID'];
  description: Maybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration: Maybe<Scalars['Int']>;
  emailBlurb: Maybe<Scalars['String']>;
  emailSubject: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  isDeleted: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isStarred: Maybe<Scalars['Boolean']>;
  /** Calculate if video is fully watched by current user */
  isWatched: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Is the video already processed by Vimeo or not */
  processingStatus: VideoProcessingStatus;
  thumbnailUrl: Maybe<Scalars['String']>;
  title: Maybe<Scalars['String']>;
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Video`. */
  userByCreatedBy: Maybe<User>;
  /** Reads and enables pagination through a set of `VideoStar`. */
  videoStars: VideoStarsConnection;
  /** Reads and enables pagination through a set of `VideoStar`. */
  videoStarsList: Array<VideoStar>;
  vimeoId: Maybe<Scalars['BigInt']>;
};


export type VideoClassVideosArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};


export type VideoClassVideosListArgs = {
  condition?: InputMaybe<ClassVideoCondition>;
  filter?: InputMaybe<ClassVideoFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ClassVideosOrderBy>>;
};


export type VideoVideoStarsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<VideoStarCondition>;
  filter?: InputMaybe<VideoStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};


export type VideoVideoStarsListArgs = {
  condition?: InputMaybe<VideoStarCondition>;
  filter?: InputMaybe<VideoStarFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};

/** The `classAssetCategory` to be created by this mutation. */
export type VideoCategoryIdFkeyClassAssetCategoryCreateInput = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** Input for the nested mutation of `classAssetCategory` in the `VideoInput` mutation. */
export type VideoCategoryIdFkeyInput = {
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  connectById?: InputMaybe<ClassAssetCategoryClassAssetCategoryPkeyConnect>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ClassAssetCategoryNodeIdConnect>;
  /** A `ClassAssetCategoryInput` object that will be created and connected to this object. */
  create?: InputMaybe<VideoCategoryIdFkeyClassAssetCategoryCreateInput>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  deleteById?: InputMaybe<ClassAssetCategoryClassAssetCategoryPkeyDelete>;
  /** The primary key(s) for `classAssetCategory` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<ClassAssetCategoryNodeIdDelete>;
  /** The primary key(s) and patch data for `classAssetCategory` for the far side of the relationship. */
  updateById?: InputMaybe<ClassAssetCategoryOnVideoForVideoCategoryIdFkeyUsingClassAssetCategoryPkeyUpdate>;
  /** The primary key(s) and patch data for `classAssetCategory` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VideoOnVideoForVideoCategoryIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `video` in the `ClassAssetCategoryInput` mutation. */
export type VideoCategoryIdFkeyInverseInput = {
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VideoVideoPkeyConnect>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VideoNodeIdConnect>>;
  /** A `VideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VideoCategoryIdFkeyVideoCreateInput>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<VideoVideoPkeyDelete>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<VideoNodeIdDelete>>;
  /** Flag indicating whether all other `video` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VideoOnVideoForVideoCategoryIdFkeyUsingVideoPkeyUpdate>>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<ClassAssetCategoryOnVideoForVideoCategoryIdFkeyNodeIdUpdate>>;
};

/** The `video` to be created by this mutation. */
export type VideoCategoryIdFkeyVideoCreateInput = {
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** A condition to be used against `Video` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type VideoCondition = {
  /** Checks for equality with the object’s `categoryId` field. */
  categoryId?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
};

/** Input for the nested mutation of `user` in the `VideoInput` mutation. */
export type VideoCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnVideoForVideoCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnVideoForVideoCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VideoOnVideoForVideoCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `video` in the `UserInput` mutation. */
export type VideoCreatedByFkeyInverseInput = {
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VideoVideoPkeyConnect>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VideoNodeIdConnect>>;
  /** A `VideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VideoCreatedByFkeyVideoCreateInput>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<VideoVideoPkeyDelete>>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<VideoNodeIdDelete>>;
  /** Flag indicating whether all other `video` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VideoOnVideoForVideoCreatedByFkeyUsingVideoPkeyUpdate>>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnVideoForVideoCreatedByFkeyNodeIdUpdate>>;
};

/** The `video` to be created by this mutation. */
export type VideoCreatedByFkeyVideoCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** A filter to be used against `Video` object types. All fields are combined with a logical ‘and.’ */
export type VideoFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VideoFilter>>;
  /** Filter by the object’s `category` relation. */
  category?: InputMaybe<ClassAssetCategoryFilter>;
  /** Filter by the object’s `categoryId` field. */
  categoryId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `classVideos` relation. */
  classVideos?: InputMaybe<VideoToManyClassVideoFilter>;
  /** Some related `classVideos` exist. */
  classVideosExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `classes` field. */
  classes?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isStarred` field. */
  isStarred?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isWatched` field. */
  isWatched?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VideoFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VideoFilter>>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
  /** Filter by the object’s `videoStars` relation. */
  videoStars?: InputMaybe<VideoToManyVideoStarFilter>;
  /** Some related `videoStars` exist. */
  videoStarsExist?: InputMaybe<Scalars['Boolean']>;
};

/** An input for mutations affecting `Video` */
export type VideoInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VideoNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `video` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VideoNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `video` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VideoOnClassVideoForClassVideoVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classVideo` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classVideo` being updated. */
  patch: ClassVideoPatch;
};

/** The fields on `video` to look up the row to update. */
export type VideoOnClassVideoForClassVideoVideoIdFkeyUsingVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: UpdateVideoOnClassVideoForClassVideoVideoIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VideoOnVideoForVideoCategoryIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `classAssetCategory` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `classAssetCategory` being updated. */
  patch: ClassAssetCategoryPatch;
};

/** The fields on `video` to look up the row to update. */
export type VideoOnVideoForVideoCategoryIdFkeyUsingVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: UpdateVideoOnVideoForVideoCategoryIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VideoOnVideoForVideoCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `video` to look up the row to update. */
export type VideoOnVideoForVideoCreatedByFkeyUsingVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: UpdateVideoOnVideoForVideoCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type VideoOnVideoStarForVideoStarVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `videoStar` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `videoStar` being updated. */
  patch: VideoStarPatch;
};

/** The fields on `video` to look up the row to update. */
export type VideoOnVideoStarForVideoStarVideoIdFkeyUsingVideoPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: UpdateVideoOnVideoStarForVideoStarVideoIdFkeyPatch;
};

/** Represents an update to a `Video`. Fields that are set will be updated. */
export type VideoPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** Vimeo needs to process videos before they are available so we need to know which are "ready" */
export enum VideoProcessingStatus {
  Processing = 'PROCESSING',
  Ready = 'READY',
  Transcoding = 'TRANSCODING',
  Uploading = 'UPLOADING'
}

/** All input for the `videoSetIsStarred` mutation. */
export type VideoSetIsStarredInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']>;
  isStarred: Scalars['Boolean'];
  videoId: Scalars['UUID'];
};

/** The output of our `videoSetIsStarred` mutation. */
export type VideoSetIsStarredPayload = {
  __typename?: 'VideoSetIsStarredPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query: Maybe<Query>;
  /** Reads a single `Video` that is related to this `VideoStar`. */
  video: Maybe<Video>;
  videoStar: Maybe<VideoStar>;
  /** An edge for our `VideoStar`. May be used by Relay 1. */
  videoStarEdge: Maybe<VideoStarsEdge>;
};


/** The output of our `videoSetIsStarred` mutation. */
export type VideoSetIsStarredPayloadVideoStarEdgeArgs = {
  orderBy?: InputMaybe<Array<VideoStarsOrderBy>>;
};

/** Used for the admin starring functionality on videos */
export type VideoStar = Node & {
  __typename?: 'VideoStar';
  id: Scalars['UUID'];
  isStarred: Maybe<Scalars['Boolean']>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['UUID'];
  /** Reads a single `Video` that is related to this `VideoStar`. */
  video: Maybe<Video>;
  videoId: Scalars['UUID'];
};

/**
 * A condition to be used against `VideoStar` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type VideoStarCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `videoId` field. */
  videoId?: InputMaybe<Scalars['UUID']>;
};

/** A filter to be used against `VideoStar` object types. All fields are combined with a logical ‘and.’ */
export type VideoStarFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<VideoStarFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<VideoStarFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<VideoStarFilter>>;
  /** Filter by the object’s `video` relation. */
  video?: InputMaybe<VideoFilter>;
  /** Filter by the object’s `videoId` field. */
  videoId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `VideoStar` */
export type VideoStarInput = {
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<VideoStarVideoIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type VideoStarNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `videoStar` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type VideoStarNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `videoStar` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type VideoStarOnVideoStarForVideoStarVideoIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `video` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `video` being updated. */
  patch: VideoPatch;
};

/** The fields on `videoStar` to look up the row to update. */
export type VideoStarOnVideoStarForVideoStarVideoIdFkeyUsingUniqVideoStarUpdate = {
  /** An object where the defined keys will be set on the `videoStar` being updated. */
  patch: UpdateVideoStarOnVideoStarForVideoStarVideoIdFkeyPatch;
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** The fields on `videoStar` to look up the row to update. */
export type VideoStarOnVideoStarForVideoStarVideoIdFkeyUsingVideoStarPkeyUpdate = {
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `videoStar` being updated. */
  patch: UpdateVideoStarOnVideoStarForVideoStarVideoIdFkeyPatch;
};

/** Represents an update to a `VideoStar`. Fields that are set will be updated. */
export type VideoStarPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<VideoStarVideoIdFkeyInput>;
};

/** The fields on `videoStar` to look up the row to connect. */
export type VideoStarUniqVideoStarConnect = {
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** The fields on `videoStar` to look up the row to delete. */
export type VideoStarUniqVideoStarDelete = {
  userId: Scalars['UUID'];
  videoId: Scalars['UUID'];
};

/** Input for the nested mutation of `video` in the `VideoStarInput` mutation. */
export type VideoStarVideoIdFkeyInput = {
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectById?: InputMaybe<VideoVideoPkeyConnect>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<VideoNodeIdConnect>;
  /** A `VideoInput` object that will be created and connected to this object. */
  create?: InputMaybe<VideoStarVideoIdFkeyVideoCreateInput>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteById?: InputMaybe<VideoVideoPkeyDelete>;
  /** The primary key(s) for `video` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<VideoNodeIdDelete>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateById?: InputMaybe<VideoOnVideoStarForVideoStarVideoIdFkeyUsingVideoPkeyUpdate>;
  /** The primary key(s) and patch data for `video` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<VideoStarOnVideoStarForVideoStarVideoIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `videoStar` in the `VideoInput` mutation. */
export type VideoStarVideoIdFkeyInverseInput = {
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  connectById?: InputMaybe<Array<VideoStarVideoStarPkeyConnect>>;
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<VideoStarNodeIdConnect>>;
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  connectByVideoIdAndUserId?: InputMaybe<Array<VideoStarUniqVideoStarConnect>>;
  /** A `VideoStarInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<VideoStarVideoIdFkeyVideoStarCreateInput>>;
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<VideoStarVideoStarPkeyDelete>>;
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<VideoStarNodeIdDelete>>;
  /** The primary key(s) for `videoStar` for the far side of the relationship. */
  deleteByVideoIdAndUserId?: InputMaybe<Array<VideoStarUniqVideoStarDelete>>;
  /** Flag indicating whether all other `videoStar` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `videoStar` for the far side of the relationship. */
  updateById?: InputMaybe<Array<VideoStarOnVideoStarForVideoStarVideoIdFkeyUsingVideoStarPkeyUpdate>>;
  /** The primary key(s) and patch data for `videoStar` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<VideoOnVideoStarForVideoStarVideoIdFkeyNodeIdUpdate>>;
  /** The primary key(s) and patch data for `videoStar` for the far side of the relationship. */
  updateByVideoIdAndUserId?: InputMaybe<Array<VideoStarOnVideoStarForVideoStarVideoIdFkeyUsingUniqVideoStarUpdate>>;
};

/** The `video` to be created by this mutation. */
export type VideoStarVideoIdFkeyVideoCreateInput = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** The `videoStar` to be created by this mutation. */
export type VideoStarVideoIdFkeyVideoStarCreateInput = {
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<VideoStarVideoIdFkeyInput>;
};

/** The fields on `videoStar` to look up the row to connect. */
export type VideoStarVideoStarPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `videoStar` to look up the row to delete. */
export type VideoStarVideoStarPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `VideoStar` values. */
export type VideoStarsConnection = {
  __typename?: 'VideoStarsConnection';
  /** A list of edges which contains the `VideoStar` and cursor to aid in pagination. */
  edges: Array<VideoStarsEdge>;
  /** A list of `VideoStar` objects. */
  nodes: Array<VideoStar>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `VideoStar` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `VideoStar` edge in the connection. */
export type VideoStarsEdge = {
  __typename?: 'VideoStarsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `VideoStar` at the end of the edge. */
  node: VideoStar;
};

/** Methods to use when ordering `VideoStar`. */
export enum VideoStarsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  VideoIdAsc = 'VIDEO_ID_ASC',
  VideoIdDesc = 'VIDEO_ID_DESC'
}

/** A filter to be used against many `ClassVideo` object types. All fields are combined with a logical ‘and.’ */
export type VideoToManyClassVideoFilter = {
  /** Every related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClassVideoFilter>;
  /** No related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClassVideoFilter>;
  /** Some related `ClassVideo` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClassVideoFilter>;
};

/** A filter to be used against many `VideoStar` object types. All fields are combined with a logical ‘and.’ */
export type VideoToManyVideoStarFilter = {
  /** Every related `VideoStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<VideoStarFilter>;
  /** No related `VideoStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<VideoStarFilter>;
  /** Some related `VideoStar` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<VideoStarFilter>;
};

/** The fields on `video` to look up the row to connect. */
export type VideoVideoPkeyConnect = {
  id: Scalars['UUID'];
};

/** The fields on `video` to look up the row to delete. */
export type VideoVideoPkeyDelete = {
  id: Scalars['UUID'];
};

/** A connection to a list of `Video` values. */
export type VideosConnection = {
  __typename?: 'VideosConnection';
  /** A list of edges which contains the `Video` and cursor to aid in pagination. */
  edges: Array<VideosEdge>;
  /** A list of `Video` objects. */
  nodes: Array<Video>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Video` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Video` edge in the connection. */
export type VideosEdge = {
  __typename?: 'VideosEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Video` at the end of the edge. */
  node: Video;
};

/** Methods to use when ordering `Video`. */
export enum VideosOrderBy {
  CategoryIdAsc = 'CATEGORY_ID_ASC',
  CategoryIdDesc = 'CATEGORY_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Workshop details */
export type Workshop = Node & {
  __typename?: 'Workshop';
  /** When was the workshop created */
  createdAt: Scalars['Datetime'];
  /** User who created the workshop */
  createdBy: Scalars['UUID'];
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Reads a single `WorkshopPreset` that is related to this `Workshop`. */
  preset: Maybe<WorkshopPreset>;
  /** Which preset is used for this workshop */
  presetId: Maybe<Scalars['Int']>;
  /** When does the workshop start */
  startsAt: Scalars['Datetime'];
  /** Title of the workshop */
  title: Scalars['String'];
  /** When was the workshop last updated */
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `Workshop`. */
  userByCreatedBy: Maybe<User>;
};

/**
 * A condition to be used against `Workshop` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type WorkshopCondition = {
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `presetId` field. */
  presetId?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars['String']>;
};

/** Input for the nested mutation of `user` in the `WorkshopInput` mutation. */
export type WorkshopCreatedByFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserUserPkeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnWorkshopForWorkshopCreatedByFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnWorkshopForWorkshopCreatedByFkeyUsingUserPkeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<WorkshopOnWorkshopForWorkshopCreatedByFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `workshop` in the `UserInput` mutation. */
export type WorkshopCreatedByFkeyInverseInput = {
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkshopWorkshopPkeyConnect>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<WorkshopNodeIdConnect>>;
  /** A `WorkshopInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<WorkshopCreatedByFkeyWorkshopCreateInput>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkshopWorkshopPkeyDelete>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<WorkshopNodeIdDelete>>;
  /** Flag indicating whether all other `workshop` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `workshop` for the far side of the relationship. */
  updateById?: InputMaybe<Array<WorkshopOnWorkshopForWorkshopCreatedByFkeyUsingWorkshopPkeyUpdate>>;
  /** The primary key(s) and patch data for `workshop` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<UserOnWorkshopForWorkshopCreatedByFkeyNodeIdUpdate>>;
};

/** The `workshop` to be created by this mutation. */
export type WorkshopCreatedByFkeyWorkshopCreateInput = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** Which preset is used for this workshop */
  presetId?: InputMaybe<Scalars['Int']>;
  /** When does the workshop start */
  startsAt: Scalars['Datetime'];
  /** Title of the workshop */
  title: Scalars['String'];
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

/** A filter to be used against `Workshop` object types. All fields are combined with a logical ‘and.’ */
export type WorkshopFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkshopFilter>>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkshopFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkshopFilter>>;
  /** Filter by the object’s `preset` relation. */
  preset?: InputMaybe<WorkshopPresetFilter>;
  /** A related `preset` exists. */
  presetExists?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `presetId` field. */
  presetId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: InputMaybe<UserFilter>;
};

/** An input for mutations affecting `Workshop` */
export type WorkshopInput = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created the workshop */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** Which preset is used for this workshop */
  presetId?: InputMaybe<Scalars['Int']>;
  /** When does the workshop start */
  startsAt: Scalars['Datetime'];
  /** Title of the workshop */
  title: Scalars['String'];
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

export enum WorkshopLevel {
  Lv2 = 'LV2',
  Lv3 = 'LV3'
}

/** A filter to be used against WorkshopLevel fields. All fields are combined with a logical ‘and.’ */
export type WorkshopLevelFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<WorkshopLevel>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<WorkshopLevel>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<WorkshopLevel>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<WorkshopLevel>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<WorkshopLevel>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<WorkshopLevel>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<WorkshopLevel>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<WorkshopLevel>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<WorkshopLevel>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<WorkshopLevel>>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WorkshopNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `workshop` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WorkshopNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `workshop` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WorkshopOnWorkshopForWorkshopCreatedByFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `user` being updated. */
  patch: UserPatch;
};

/** The fields on `workshop` to look up the row to update. */
export type WorkshopOnWorkshopForWorkshopCreatedByFkeyUsingWorkshopPkeyUpdate = {
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `workshop` being updated. */
  patch: UpdateWorkshopOnWorkshopForWorkshopCreatedByFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type WorkshopOnWorkshopForWorkshopPresetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `workshopPreset` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `workshopPreset` being updated. */
  patch: WorkshopPresetPatch;
};

/** The fields on `workshop` to look up the row to update. */
export type WorkshopOnWorkshopForWorkshopPresetIdFkeyUsingWorkshopPkeyUpdate = {
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
  /** An object where the defined keys will be set on the `workshop` being updated. */
  patch: UpdateWorkshopOnWorkshopForWorkshopPresetIdFkeyPatch;
};

/** Represents an update to a `Workshop`. Fields that are set will be updated. */
export type WorkshopPatch = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created the workshop */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** Which preset is used for this workshop */
  presetId?: InputMaybe<Scalars['Int']>;
  /** When does the workshop start */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Title of the workshop */
  title?: InputMaybe<Scalars['String']>;
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

/** Workshop Preset details */
export type WorkshopPreset = Node & {
  __typename?: 'WorkshopPreset';
  /** An ordered array of activities, where each element is a FK to an activity */
  activities: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Reads and enables pagination through a set of `BriefPreset`. */
  briefPresets: BriefPresetsConnection;
  /** Reads and enables pagination through a set of `BriefPreset`. */
  briefPresetsList: Array<BriefPreset>;
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
  /** Foreign key to language, e.g. "en-US" */
  languageCode: Scalars['String'];
  /** Workshop Level */
  level: WorkshopLevel;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Title of the workshop preset */
  title: Scalars['String'];
  /** Reads and enables pagination through a set of `Workshop`. */
  workshopsByPresetId: WorkshopsConnection;
  /** Reads and enables pagination through a set of `Workshop`. */
  workshopsByPresetIdList: Array<Workshop>;
};


/** Workshop Preset details */
export type WorkshopPresetBriefPresetsArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<BriefPresetCondition>;
  filter?: InputMaybe<BriefPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};


/** Workshop Preset details */
export type WorkshopPresetBriefPresetsListArgs = {
  condition?: InputMaybe<BriefPresetCondition>;
  filter?: InputMaybe<BriefPresetFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<BriefPresetsOrderBy>>;
};


/** Workshop Preset details */
export type WorkshopPresetWorkshopsByPresetIdArgs = {
  after?: InputMaybe<Scalars['Cursor']>;
  before?: InputMaybe<Scalars['Cursor']>;
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};


/** Workshop Preset details */
export type WorkshopPresetWorkshopsByPresetIdListArgs = {
  condition?: InputMaybe<WorkshopCondition>;
  filter?: InputMaybe<WorkshopFilter>;
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<WorkshopsOrderBy>>;
};

/**
 * A condition to be used against `WorkshopPreset` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkshopPresetCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['Int']>;
  /** Checks for equality with the object’s `level` field. */
  level?: InputMaybe<WorkshopLevel>;
};

/** A filter to be used against `WorkshopPreset` object types. All fields are combined with a logical ‘and.’ */
export type WorkshopPresetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkshopPresetFilter>>;
  /** Filter by the object’s `briefPresets` relation. */
  briefPresets?: InputMaybe<WorkshopPresetToManyBriefPresetFilter>;
  /** Some related `briefPresets` exist. */
  briefPresetsExist?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<IntFilter>;
  /** Filter by the object’s `level` field. */
  level?: InputMaybe<WorkshopLevelFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkshopPresetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkshopPresetFilter>>;
  /** Filter by the object’s `workshopsByPresetId` relation. */
  workshopsByPresetId?: InputMaybe<WorkshopPresetToManyWorkshopFilter>;
  /** Some related `workshopsByPresetId` exist. */
  workshopsByPresetIdExist?: InputMaybe<Scalars['Boolean']>;
};

/** Input for the nested mutation of `workshopPreset` in the `WorkshopInput` mutation. */
export type WorkshopPresetIdFkeyInput = {
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  connectById?: InputMaybe<WorkshopPresetWorkshopPresetPkeyConnect>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<WorkshopPresetNodeIdConnect>;
  /** A `WorkshopPresetInput` object that will be created and connected to this object. */
  create?: InputMaybe<WorkshopPresetIdFkeyWorkshopPresetCreateInput>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  deleteById?: InputMaybe<WorkshopPresetWorkshopPresetPkeyDelete>;
  /** The primary key(s) for `workshopPreset` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<WorkshopPresetNodeIdDelete>;
  /** The primary key(s) and patch data for `workshopPreset` for the far side of the relationship. */
  updateById?: InputMaybe<WorkshopPresetOnWorkshopForWorkshopPresetIdFkeyUsingWorkshopPresetPkeyUpdate>;
  /** The primary key(s) and patch data for `workshopPreset` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<WorkshopOnWorkshopForWorkshopPresetIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `workshop` in the `WorkshopPresetInput` mutation. */
export type WorkshopPresetIdFkeyInverseInput = {
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  connectById?: InputMaybe<Array<WorkshopWorkshopPkeyConnect>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<WorkshopNodeIdConnect>>;
  /** A `WorkshopInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<WorkshopPresetIdFkeyWorkshopCreateInput>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<WorkshopWorkshopPkeyDelete>>;
  /** The primary key(s) for `workshop` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<WorkshopNodeIdDelete>>;
  /** Flag indicating whether all other `workshop` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars['Boolean']>;
  /** The primary key(s) and patch data for `workshop` for the far side of the relationship. */
  updateById?: InputMaybe<Array<WorkshopOnWorkshopForWorkshopPresetIdFkeyUsingWorkshopPkeyUpdate>>;
  /** The primary key(s) and patch data for `workshop` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<Array<WorkshopPresetOnWorkshopForWorkshopPresetIdFkeyNodeIdUpdate>>;
};

/** The `workshop` to be created by this mutation. */
export type WorkshopPresetIdFkeyWorkshopCreateInput = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created the workshop */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** When does the workshop start */
  startsAt: Scalars['Datetime'];
  /** Title of the workshop */
  title: Scalars['String'];
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

/** The `workshopPreset` to be created by this mutation. */
export type WorkshopPresetIdFkeyWorkshopPresetCreateInput = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode: Scalars['String'];
  /** Workshop Level */
  level: WorkshopLevel;
  /** Title of the workshop preset */
  title: Scalars['String'];
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};

/** An input for mutations affecting `WorkshopPreset` */
export type WorkshopPresetInput = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode: Scalars['String'];
  /** Workshop Level */
  level: WorkshopLevel;
  /** Title of the workshop preset */
  title: Scalars['String'];
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WorkshopPresetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `workshopPreset` to be connected. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to delete. */
export type WorkshopPresetNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `workshopPreset` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The globally unique `ID` look up for the row to update. */
export type WorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `briefPreset` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `briefPreset` being updated. */
  patch: BriefPresetPatch;
};

/** The fields on `workshopPreset` to look up the row to update. */
export type WorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyUsingWorkshopPresetPkeyUpdate = {
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `workshopPreset` being updated. */
  patch: UpdateWorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type WorkshopPresetOnWorkshopForWorkshopPresetIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `workshop` to be connected. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `workshop` being updated. */
  patch: WorkshopPatch;
};

/** The fields on `workshopPreset` to look up the row to update. */
export type WorkshopPresetOnWorkshopForWorkshopPresetIdFkeyUsingWorkshopPresetPkeyUpdate = {
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
  /** An object where the defined keys will be set on the `workshopPreset` being updated. */
  patch: UpdateWorkshopPresetOnWorkshopForWorkshopPresetIdFkeyPatch;
};

/** Represents an update to a `WorkshopPreset`. Fields that are set will be updated. */
export type WorkshopPresetPatch = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Workshop Level */
  level?: InputMaybe<WorkshopLevel>;
  /** Title of the workshop preset */
  title?: InputMaybe<Scalars['String']>;
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};

/** A filter to be used against many `BriefPreset` object types. All fields are combined with a logical ‘and.’ */
export type WorkshopPresetToManyBriefPresetFilter = {
  /** Every related `BriefPreset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BriefPresetFilter>;
  /** No related `BriefPreset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BriefPresetFilter>;
  /** Some related `BriefPreset` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BriefPresetFilter>;
};

/** A filter to be used against many `Workshop` object types. All fields are combined with a logical ‘and.’ */
export type WorkshopPresetToManyWorkshopFilter = {
  /** Every related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkshopFilter>;
  /** No related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkshopFilter>;
  /** Some related `Workshop` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkshopFilter>;
};

/** The fields on `workshopPreset` to look up the row to connect. */
export type WorkshopPresetWorkshopPresetPkeyConnect = {
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
};

/** The fields on `workshopPreset` to look up the row to delete. */
export type WorkshopPresetWorkshopPresetPkeyDelete = {
  /** Primary ID of the workshop preset */
  id: Scalars['Int'];
};

/** A connection to a list of `WorkshopPreset` values. */
export type WorkshopPresetsConnection = {
  __typename?: 'WorkshopPresetsConnection';
  /** A list of edges which contains the `WorkshopPreset` and cursor to aid in pagination. */
  edges: Array<WorkshopPresetsEdge>;
  /** A list of `WorkshopPreset` objects. */
  nodes: Array<WorkshopPreset>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkshopPreset` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `WorkshopPreset` edge in the connection. */
export type WorkshopPresetsEdge = {
  __typename?: 'WorkshopPresetsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `WorkshopPreset` at the end of the edge. */
  node: WorkshopPreset;
};

/** Methods to use when ordering `WorkshopPreset`. */
export enum WorkshopPresetsOrderBy {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LevelAsc = 'LEVEL_ASC',
  LevelDesc = 'LEVEL_DESC',
  Natural = 'NATURAL',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The fields on `workshop` to look up the row to connect. */
export type WorkshopWorkshopPkeyConnect = {
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
};

/** The fields on `workshop` to look up the row to delete. */
export type WorkshopWorkshopPkeyDelete = {
  /** Primary ID of the workshop */
  id: Scalars['UUID'];
};

/** A connection to a list of `WorkshopsCalendarRecord` values. */
export type WorkshopsCalendarConnection = {
  __typename?: 'WorkshopsCalendarConnection';
  /** A list of edges which contains the `WorkshopsCalendarRecord` and cursor to aid in pagination. */
  edges: Array<WorkshopsCalendarEdge>;
  /** A list of `WorkshopsCalendarRecord` objects. */
  nodes: Array<WorkshopsCalendarRecord>;
  /** The count of *all* `WorkshopsCalendarRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `WorkshopsCalendarRecord` edge in the connection. */
export type WorkshopsCalendarEdge = {
  __typename?: 'WorkshopsCalendarEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `WorkshopsCalendarRecord` at the end of the edge. */
  node: WorkshopsCalendarRecord;
};

/** The return type of our `workshopsCalendar` query. */
export type WorkshopsCalendarRecord = {
  __typename?: 'WorkshopsCalendarRecord';
  companyId: Maybe<Scalars['Int']>;
  consultantName: Maybe<Scalars['String']>;
  id: Maybe<Scalars['BigInt']>;
  isDeleted: Maybe<Scalars['Int']>;
  level: Maybe<Scalars['String']>;
  numberOfParticipants: Maybe<Scalars['Int']>;
  participants: Maybe<Scalars['String']>;
  startTime: Maybe<Scalars['String']>;
  type: Maybe<Scalars['String']>;
  workshopDate: Maybe<Scalars['String']>;
  workshopDateEnd: Maybe<Scalars['String']>;
  workshopName: Maybe<Scalars['String']>;
};

/** A filter to be used against `WorkshopsCalendarRecord` object types. All fields are combined with a logical ‘and.’ */
export type WorkshopsCalendarRecordFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkshopsCalendarRecordFilter>>;
  /** Filter by the object’s `companyId` field. */
  companyId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `consultantName` field. */
  consultantName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<IntFilter>;
  /** Filter by the object’s `level` field. */
  level?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkshopsCalendarRecordFilter>;
  /** Filter by the object’s `numberOfParticipants` field. */
  numberOfParticipants?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkshopsCalendarRecordFilter>>;
  /** Filter by the object’s `participants` field. */
  participants?: InputMaybe<StringFilter>;
  /** Filter by the object’s `startTime` field. */
  startTime?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workshopDate` field. */
  workshopDate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workshopDateEnd` field. */
  workshopDateEnd?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workshopName` field. */
  workshopName?: InputMaybe<StringFilter>;
};

/** A connection to a list of `Workshop` values. */
export type WorkshopsConnection = {
  __typename?: 'WorkshopsConnection';
  /** A list of edges which contains the `Workshop` and cursor to aid in pagination. */
  edges: Array<WorkshopsEdge>;
  /** A list of `Workshop` objects. */
  nodes: Array<Workshop>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Workshop` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Workshop` edge in the connection. */
export type WorkshopsEdge = {
  __typename?: 'WorkshopsEdge';
  /** A cursor for use in pagination. */
  cursor: Maybe<Scalars['Cursor']>;
  /** The `Workshop` at the end of the edge. */
  node: Workshop;
};

/** Methods to use when ordering `Workshop`. */
export enum WorkshopsOrderBy {
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  Natural = 'NATURAL',
  PresetIdAsc = 'PRESET_ID_ASC',
  PresetIdDesc = 'PRESET_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  TitleAsc = 'TITLE_ASC',
  TitleDesc = 'TITLE_DESC'
}

export enum YesNoOption {
  No = 'NO',
  Yes = 'YES'
}

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnClassForClassAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnConnectionForConnectionAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnCustomerForCustomerAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnProjectForProjectAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnUserForUserAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `account` being updated. */
export type UpdateAccountOnVariableClassForVariableAccountIdFkeyPatch = {
  /** Address line 1 */
  address1?: InputMaybe<Scalars['String']>;
  /** Address line 2 */
  address2?: InputMaybe<Scalars['String']>;
  /** A custom message containing an admin email address that users from this org see when they do not have access to our system. */
  adminContactMessage?: InputMaybe<Scalars['String']>;
  /** City */
  city?: InputMaybe<Scalars['String']>;
  classesUsingId?: InputMaybe<ClassAccountIdFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionAccountIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInverseInput>;
  /** Contact Email */
  contactEmail?: InputMaybe<Scalars['String']>;
  /** Contact Name */
  contactName?: InputMaybe<Scalars['String']>;
  /** Contact Title */
  contactTitle?: InputMaybe<Scalars['String']>;
  /** Country/Region of the Organization */
  countryRegionId?: InputMaybe<Scalars['UUID']>;
  /** When was this account created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  customersUsingId?: InputMaybe<CustomerAccountIdFkeyInverseInput>;
  /** Indicates if the organization has an active SSO integration set in v1 */
  hasSso?: InputMaybe<Scalars['Boolean']>;
  /** Unique ID for this account */
  id?: InputMaybe<Scalars['UUID']>;
  /** The IdP Name used for SSO config of an organization. Set in v1, added here for comparison and URL validation */
  idpName?: InputMaybe<Scalars['String']>;
  /** Industry of the Organization */
  industryId?: InputMaybe<Scalars['UUID']>;
  /** Is this account archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access Learning Videos */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access LV1 Game */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the account users can access NPT functionality */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInverseInput>;
  /** Name of the account */
  name?: InputMaybe<Scalars['String']>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInverseInput>;
  organizationCoachingTimeUsingId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInverseInput>;
  /** Account phone number */
  phone?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectAccountIdFkeyInverseInput>;
  /**
   * This column should be used when companies are switching from password
   * authentication to SSO login, if this is TRUE, then they will sill be
   * allowed to login with their password while has_sso is true
   * and they are testing the SSO integration
   */
  ssoIntegrationTest?: InputMaybe<Scalars['Boolean']>;
  /** State */
  state?: InputMaybe<Scalars['String']>;
  /** Account time zone, e.g. Europe/Sofia */
  timezone?: InputMaybe<Scalars['String']>;
  /** When was this account last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  usersUsingId?: InputMaybe<UserAccountIdFkeyInverseInput>;
  variableClassesUsingId?: InputMaybe<VariableAccountIdFkeyInverseInput>;
  /** Website URL */
  websiteUrl?: InputMaybe<Scalars['String']>;
  /** Zip code */
  zipCode?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `activity` being updated. */
export type UpdateActivityOnActivityLevelForActivityLevelActivityIdFkeyPatch = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `activity` being updated. */
export type UpdateActivityOnBriefGroupForBriefGroupActivityIdFkeyPatch = {
  activityLevelsUsingId?: InputMaybe<ActivityLevelActivityIdFkeyInverseInput>;
  /** Brief Group Type */
  briefGroupType?: InputMaybe<BriefGroupType>;
  briefGroupsUsingId?: InputMaybe<BriefGroupActivityIdFkeyInverseInput>;
  /** Does this activity use a NPT Project Template */
  hasNptTemplate?: InputMaybe<Scalars['Boolean']>;
  /** Does this activity use Variables */
  hasVariables?: InputMaybe<Scalars['Boolean']>;
  /** Primary ID of the activity */
  id?: InputMaybe<Scalars['Int']>;
  /** Intro text of the activity */
  intro?: InputMaybe<Scalars['String']>;
  /** Activity rank (order) */
  rank?: InputMaybe<Scalars['Int']>;
  /** Title of the activity */
  title?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `briefGroup` being updated. */
export type UpdateBriefGroupOnBriefForBriefBriefGroupIdFkeyPatch = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name?: InputMaybe<Scalars['String']>;
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** An object where the defined keys will be set on the `briefGroup` being updated. */
export type UpdateBriefGroupOnBriefGroupForBriefGroupActivityIdFkeyPatch = {
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name?: InputMaybe<Scalars['String']>;
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** An object where the defined keys will be set on the `briefGroup` being updated. */
export type UpdateBriefGroupOnBriefGroupForBriefGroupLanguageCodeFkeyPatch = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group */
  name?: InputMaybe<Scalars['String']>;
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** An object where the defined keys will be set on the `briefGroup` being updated. */
export type UpdateBriefGroupOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyPatch = {
  /** Foreign key to Activity where this brief group belongs */
  activityId?: InputMaybe<Scalars['Int']>;
  activityToActivityId?: InputMaybe<BriefGroupActivityIdFkeyInput>;
  briefGroupVariablesUsingId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInverseInput>;
  briefsUsingId?: InputMaybe<BriefBriefGroupIdFkeyInverseInput>;
  /** When was the brief group created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the brief group */
  id?: InputMaybe<Scalars['Int']>;
  /** Language of the Brief Group */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Name of the brief group */
  name?: InputMaybe<Scalars['String']>;
  /** Number of players (how many different briefs there are in this group) */
  numberOfPlayers?: InputMaybe<Scalars['Int']>;
  /** When was the brief group last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userLanguageToLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInput>;
};

/** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
export type UpdateBriefGroupVariableOnBriefGroupVariableForBriefGroupVariableBriefGroupIdFkeyPatch = {
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `briefGroupVariable` being updated. */
export type UpdateBriefGroupVariableOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyPatch = {
  /** Foreign key to Brief Group where this variable belongs */
  briefGroupId?: InputMaybe<Scalars['Int']>;
  briefGroupToBriefGroupId?: InputMaybe<BriefGroupVariableBriefGroupIdFkeyInput>;
  briefGroupVariableOptionsUsingId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInverseInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief group Variable */
  name?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `briefGroupVariableOption` being updated. */
export type UpdateBriefGroupVariableOptionOnBriefGroupVariableOptionForBriefGroupVariableOptionVariableIdFkeyPatch = {
  briefGroupVariableToVariableId?: InputMaybe<BriefGroupVariableOptionVariableIdFkeyInput>;
  /** Primary ID of the brief group Variable */
  id?: InputMaybe<Scalars['Int']>;
  /** Variable Option Label */
  label?: InputMaybe<Scalars['String']>;
  /** Points for Buyers */
  pointsBuyers?: InputMaybe<Scalars['Int']>;
  /** Points for Sellers */
  pointsSellers?: InputMaybe<Scalars['Int']>;
  /** Used for sorting (order) of the Variable Option */
  rank?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `brief` being updated. */
export type UpdateBriefOnBriefForBriefBriefGroupIdFkeyPatch = {
  briefGroupToBriefGroupId?: InputMaybe<BriefBriefGroupIdFkeyInput>;
  /** Variant of the brief, e.g. Buyer or Player 1 */
  briefVariant?: InputMaybe<BriefVariant>;
  /** Instructions for the person getting the brief. If present, should be shown in activities instead of the PDF. */
  content?: InputMaybe<Scalars['String']>;
  /** When was the brief created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Name of the brief, e.g. School */
  name?: InputMaybe<Scalars['String']>;
  /** The URL of an uploaded PDF document. If it exists, it should always be used when printing/downloading */
  pathUrl?: InputMaybe<Scalars['String']>;
  /** When was the brief last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `briefPreset` being updated. */
export type UpdateBriefPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyPatch = {
  /** An ordered array of foreign keys to Brief Groups related to the corresponding Workshop Preset Activity */
  briefGroups?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  /** Primary ID of the brief */
  id?: InputMaybe<Scalars['Int']>;
  /** Title of the brief preset */
  title?: InputMaybe<Scalars['String']>;
  workshopPresetToWorkshopPresetId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classAssetCategory` being updated. */
export type UpdateClassAssetCategoryOnDocumentForDocumentCategoryIdFkeyPatch = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `classAssetCategory` being updated. */
export type UpdateClassAssetCategoryOnVideoForVideoCategoryIdFkeyPatch = {
  documentsUsingId?: InputMaybe<DocumentCategoryIdFkeyInverseInput>;
  id?: InputMaybe<Scalars['UUID']>;
  name?: InputMaybe<Scalars['String']>;
  videosUsingId?: InputMaybe<VideoCategoryIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `classDocument` being updated. */
export type UpdateClassDocumentOnClassDocumentForClassDocumentClassIdFkeyPatch = {
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentId?: InputMaybe<Scalars['UUID']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `classDocument` being updated. */
export type UpdateClassDocumentOnClassDocumentForClassDocumentDocumentIdFkeyPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassDocumentClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentToDocumentId?: InputMaybe<ClassDocumentDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassDocumentForClassDocumentClassIdFkeyPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassForClassAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassForClassCreatedByFkeyPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassStarForClassStarClassIdFkeyPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassUserForClassUserClassIdFkeyPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `class` being updated. */
export type UpdateClassOnClassVideoForClassVideoClassIdFkeyPatch = {
  /** Company that the class is created for */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ClassAccountIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentClassIdFkeyInverseInput>;
  classStarsUsingId?: InputMaybe<ClassStarClassIdFkeyInverseInput>;
  classUsersUsingId?: InputMaybe<ClassUserClassIdFkeyInverseInput>;
  classVideosUsingId?: InputMaybe<ClassVideoClassIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** How often are videos released in the class relative to the start date */
  frequency?: InputMaybe<ClassFrequency>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Once a class is published this sets "is_locked" on all of the videos in it and their "rank" and "premiere_at" can no longer be edited. */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  /** Start date of the class */
  startAt?: InputMaybe<Scalars['Date']>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `classStar` being updated. */
export type UpdateClassStarOnClassStarForClassStarClassIdFkeyPatch = {
  classToClassId?: InputMaybe<ClassStarClassIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** An object where the defined keys will be set on the `classUser` being updated. */
export type UpdateClassUserOnClassUserForClassUserClassIdFkeyPatch = {
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classUser` being updated. */
export type UpdateClassUserOnClassUserForClassUserUserIdFkeyPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassUserClassIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  lastViewedAt?: InputMaybe<Scalars['Datetime']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ClassUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classVideo` being updated. */
export type UpdateClassVideoOnClassVideoForClassVideoClassIdFkeyPatch = {
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classVideo` being updated. */
export type UpdateClassVideoOnClassVideoForClassVideoVideoIdFkeyPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classVideo` being updated. */
export type UpdateClassVideoOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch = {
  classId?: InputMaybe<Scalars['UUID']>;
  classToClassId?: InputMaybe<ClassVideoClassIdFkeyInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When true we disallow editing of its rank and premiere_at fields as well as deleting and disabling these. Controlled from class is_published value */
  isLocked?: InputMaybe<Scalars['Boolean']>;
  /** The date on which the video becomes available to watch for users */
  premiereAt?: InputMaybe<Scalars['Date']>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  videoId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<ClassVideoVideoIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classVideoUser` being updated. */
export type UpdateClassVideoUserOnClassVideoUserForClassVideoUserClassVideoIdFkeyPatch = {
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `classVideoUser` being updated. */
export type UpdateClassVideoUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch = {
  classVideoId?: InputMaybe<Scalars['UUID']>;
  classVideoToClassVideoId?: InputMaybe<ClassVideoUserClassVideoIdFkeyInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The duration watched in seconds */
  durationWatched?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['UUID']>;
  notifiedAt?: InputMaybe<Scalars['Datetime']>;
  premiereAt?: InputMaybe<Scalars['Date']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ClassVideoUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `connection` being updated. */
export type UpdateConnectionOnConnectionForConnectionAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType?: InputMaybe<ConnectionType>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `connection` being updated. */
export type UpdateConnectionOnConnectionForConnectionUserIdFkeyPatch = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType?: InputMaybe<ConnectionType>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `connection` being updated. */
export type UpdateConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyPatch = {
  /** Related Account ID */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConnectionAccountIdFkeyInput>;
  /** Avatar image URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  /** Company name */
  companyName?: InputMaybe<Scalars['String']>;
  /** Connection type */
  connectionType?: InputMaybe<ConnectionType>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  email?: InputMaybe<Scalars['Email']>;
  /** Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** LinkedIn URL */
  linkedinUrl?: InputMaybe<Scalars['String']>;
  /** Full name of the person */
  name?: InputMaybe<Scalars['String']>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionConnectionIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** TKI Type of the person */
  tkiType?: InputMaybe<ConnectionTkiType>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConnectionUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
export type UpdateConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  /** User ID - which consultant it is */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `consultantOrganizationAccess` being updated. */
export type UpdateConsultantOrganizationAccessOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyPatch = {
  /** Account ID - which organization they have access to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ConsultantOrganizationAccessAccountIdFkeyInput>;
  userToUserId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `customer` being updated. */
export type UpdateCustomerOnCustomerForCustomerAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `customer` being updated. */
export type UpdateCustomerOnProjectForProjectCustomerIdFkeyPatch = {
  /** The account this customer belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<CustomerAccountIdFkeyInput>;
  /** When was this customer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for this customer */
  id?: InputMaybe<Scalars['UUID']>;
  /** Name of the customer */
  name?: InputMaybe<Scalars['String']>;
  projectsUsingId?: InputMaybe<ProjectCustomerIdFkeyInverseInput>;
  /** When was this customer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `document` being updated. */
export type UpdateDocumentOnClassDocumentForClassDocumentDocumentIdFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType?: InputMaybe<DocumentFileType>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `document` being updated. */
export type UpdateDocumentOnDocumentForDocumentCategoryIdFkeyPatch = {
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType?: InputMaybe<DocumentFileType>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `document` being updated. */
export type UpdateDocumentOnDocumentForDocumentCreatedByFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType?: InputMaybe<DocumentFileType>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `document` being updated. */
export type UpdateDocumentOnDocumentStarForDocumentStarDocumentIdFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<DocumentCategoryIdFkeyInput>;
  classDocumentsUsingId?: InputMaybe<ClassDocumentDocumentIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the document, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  documentStarsUsingId?: InputMaybe<DocumentStarDocumentIdFkeyInverseInput>;
  /** Textual representation of the file size, passed by the frontend */
  fileSize?: InputMaybe<Scalars['String']>;
  /** One of: PPT, PPTX, PDF */
  fileType?: InputMaybe<DocumentFileType>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  pathUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<DocumentCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `documentStar` being updated. */
export type UpdateDocumentStarOnDocumentStarForDocumentStarDocumentIdFkeyPatch = {
  documentToDocumentId?: InputMaybe<DocumentStarDocumentIdFkeyInput>;
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
};

/** An object where the defined keys will be set on the `eventType` being updated. */
export type UpdateEventTypeOnProjectEventForProjectEventEventTypeFkeyPatch = {
  projectEventsUsingTitle?: InputMaybe<ProjectEventEventTypeFkeyInverseInput>;
  /** Event type title */
  title?: InputMaybe<Scalars['String']>;
  /** Event type weight */
  weight?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `feedbackResponse` being updated. */
export type UpdateFeedbackResponseOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<FeedbackResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate workshop participation */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `googleAccountUser` being updated. */
export type UpdateGoogleAccountUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyPatch = {
  /** Access token is a short-lived per user credential issued by Google that is used to securely call Google APIs and access user data. */
  accessToken?: InputMaybe<Scalars['String']>;
  /** The timestamp for the expiration of the access token. */
  accessTokenExpiryDateUnixTimestamp?: InputMaybe<Scalars['BigInt']>;
  /** Auth code is a temporary code issued by Google to securely identify individual users who sign-in to their Google Account from a browser. Your backend platform exchanges this code for access and refresh tokens. */
  authCode?: InputMaybe<Scalars['String']>;
  /** The primary Google email of the user account */
  email?: InputMaybe<Scalars['String']>;
  /** Google Account User ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** JSON Web Tokens (JWTs) that conform to the OpenID Connect (OIDC) specification. */
  idToken?: InputMaybe<Scalars['String']>;
  /** Refresh token is a long-lived per user credential issued by Google that is securely stored on your platform and can be used to obtain a new, valid access token even when the user is not present. */
  refreshToken?: InputMaybe<Scalars['String']>;
  /** String with all the OAuth scopes. Scopes express the permissions you request users to authorize for your app and allow your project to access specific types of private user data from their Google Account. */
  scope?: InputMaybe<Scalars['String']>;
  /** String with the type of token. Usually "Bearer". */
  tokenType?: InputMaybe<Scalars['String']>;
  userToUserId?: InputMaybe<GoogleAccountUserUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
export type UpdateGoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyPatch = {
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  /** Related User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `googleCalendarEvent` being updated. */
export type UpdateGoogleCalendarEventOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyPatch = {
  /** Related npt.project_event ID */
  eventId?: InputMaybe<Scalars['UUID']>;
  /** The Google ID of the Google Calendar where the event is */
  googleCalendarId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Notification Channel. Used for listening for updates from Google. */
  googleChannelId?: InputMaybe<Scalars['String']>;
  /** The Google ID of the Google Calendar Event */
  googleEventId?: InputMaybe<Scalars['String']>;
  /** Link to the Google Calendar Event */
  htmlLink?: InputMaybe<Scalars['String']>;
  /** Google Calendar Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectEventToEventId?: InputMaybe<GoogleCalendarEventEventIdFkeyInput>;
  userToUserId?: InputMaybe<GoogleCalendarEventUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lv1GameScore` being updated. */
export type UpdateLv1GameScoreOnLv1GameScoreForGameLv1ScoreUserIdFkeyPatch = {
  /** When was this score added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The scores payload (module, scene, question, type, etc.) */
  payload?: InputMaybe<Scalars['JSON']>;
  /** When was this score updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<GameLv1ScoreUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lv1GameState` being updated. */
export type UpdateLv1GameStateOnLv1GameStateForGameLv1StateUserIdFkeyPatch = {
  /** When was the state added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The game state payload */
  state?: InputMaybe<Scalars['JSON']>;
  /** When was the state last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<GameLv1StateUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
export type UpdateLv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
export type UpdateLv1GameSubgroupOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyPatch = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
export type UpdateLv1GameSubgroupOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyPatch = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroup` being updated. */
export type UpdateLv1GameSubgroupOnUserForUserGameLv1SubgroupIdFkeyPatch = {
  /** Account this subgroup belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<GameLv1SubgroupAccountIdFkeyInput>;
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Who created this subgroup */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Subgroup ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this subgroup deleted */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInverseInput>;
  /** Subgroup name */
  name?: InputMaybe<Scalars['String']>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<GameLv1SubgroupCreatedByFkeyInput>;
  usersUsingId?: InputMaybe<UserGameLv1SubgroupIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
export type UpdateLv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupGameLv1SubgroupIdFkeyPatch = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `lv1GameSubgroupUser` being updated. */
export type UpdateLv1GameSubgroupUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyPatch = {
  /** When was this record created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** LV1 game subgroup ID */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupGameLv1SubgroupIdFkeyInput>;
  /** When was this record last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `massUserUpload` being updated. */
export type UpdateMassUserUploadOnMassUserUploadForMassUserUploadCreatedByFkeyPatch = {
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** The name of the file as input by the user, used by the FE only */
  filename?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** When the job has been processed this will be set to true */
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  log?: InputMaybe<Scalars['String']>;
  /** Path to a CSV file with user data */
  pathUrl?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<MassUserUploadCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
export type UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInput>;
  /** Who was the coaching consultant */
  consultantId?: InputMaybe<Scalars['UUID']>;
  /** When was this entry added */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** How long did it take (in minutes) */
  duration?: InputMaybe<Scalars['Int']>;
  /** Date when the coaching happened */
  happenedAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID for the log */
  id?: InputMaybe<Scalars['Int']>;
  /** Any notes that were added */
  notes?: InputMaybe<Scalars['String']>;
  /** Project related to this coaching time log */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInput>;
  /** Who were the participating team members */
  teamMembers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userToConsultantId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInput>;
};

/** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
export type UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyPatch = {
  /** Which organization it belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInput>;
  /** When was this entry added */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** How long did it take (in minutes) */
  duration?: InputMaybe<Scalars['Int']>;
  /** Date when the coaching happened */
  happenedAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID for the log */
  id?: InputMaybe<Scalars['Int']>;
  /** Any notes that were added */
  notes?: InputMaybe<Scalars['String']>;
  /** Project related to this coaching time log */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInput>;
  /** Who were the participating team members */
  teamMembers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userToConsultantId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInput>;
};

/** An object where the defined keys will be set on the `organizationCoachingTimeLog` being updated. */
export type UpdateOrganizationCoachingTimeLogOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyPatch = {
  /** Which organization it belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeLogAccountIdFkeyInput>;
  /** Who was the coaching consultant */
  consultantId?: InputMaybe<Scalars['UUID']>;
  /** When was this entry added */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** How long did it take (in minutes) */
  duration?: InputMaybe<Scalars['Int']>;
  /** Date when the coaching happened */
  happenedAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID for the log */
  id?: InputMaybe<Scalars['Int']>;
  /** Any notes that were added */
  notes?: InputMaybe<Scalars['String']>;
  projectToProjectId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInput>;
  /** Who were the participating team members */
  teamMembers?: InputMaybe<Array<InputMaybe<Scalars['UUID']>>>;
  userToConsultantId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInput>;
};

/** An object where the defined keys will be set on the `organizationCoachingTime` being updated. */
export type UpdateOrganizationCoachingTimeOnOrganizationCoachingTimeForOrganizationCoachingTimeAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<OrganizationCoachingTimeAccountIdFkeyInput>;
  /** How much time is remaining (in minutes) */
  remaining?: InputMaybe<Scalars['Int']>;
  /** How much time is assigned (in minutes) */
  total?: InputMaybe<Scalars['Int']>;
  /** How much time is already used total (in minutes) */
  used?: InputMaybe<Scalars['Int']>;
  /** How much time has been used in the current month (in minutes) */
  usedMonth?: InputMaybe<Scalars['Int']>;
  /** How much time has been used in the current year (in minutes) */
  usedYear?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `projectAccessLog` being updated. */
export type UpdateProjectAccessLogOnProjectAccessLogForProjectAccessLogProjectIdFkeyPatch = {
  /** When was the project opened? */
  accessAt?: InputMaybe<Scalars['Datetime']>;
  /** When was the project modified by this user? */
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  projectToProjectId?: InputMaybe<ProjectAccessLogProjectIdFkeyInput>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectAccessLogUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectAccessLog` being updated. */
export type UpdateProjectAccessLogOnProjectAccessLogForProjectAccessLogUserIdFkeyPatch = {
  /** When was the project opened? */
  accessAt?: InputMaybe<Scalars['Datetime']>;
  /** When was the project modified by this user? */
  modifiedAt?: InputMaybe<Scalars['Datetime']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectAccessLogProjectIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectAccessLogUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectAlignSurvey` being updated. */
export type UpdateProjectAlignSurveyOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyPatch = {
  /** JSON with all the answers */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project align survey created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  projectToProjectId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInput>;
  /** When was this project align survey last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectComment` being updated. */
export type UpdateProjectCommentOnProjectCommentForProjectCommentParentIdFkeyPatch = {
  /** Comment body content */
  content?: InputMaybe<Scalars['JSON']>;
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section?: InputMaybe<ProjectSection>;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectComment` being updated. */
export type UpdateProjectCommentOnProjectCommentForProjectCommentProjectIdFkeyPatch = {
  /** Comment body content */
  content?: InputMaybe<Scalars['JSON']>;
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section?: InputMaybe<ProjectSection>;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** User ID of the comment author */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectComment` being updated. */
export type UpdateProjectCommentOnProjectCommentForProjectCommentUserIdFkeyPatch = {
  /** Comment body content */
  content?: InputMaybe<Scalars['JSON']>;
  /** When was this comment added? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this comment resolved (archived)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this comment deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** If this is a reply, we store the parent comment id */
  parentId?: InputMaybe<Scalars['BigInt']>;
  projectCommentToParentId?: InputMaybe<ProjectCommentParentIdFkeyInput>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommentProjectIdFkeyInput>;
  /** Project section - variables, negotiate, etc. */
  section?: InputMaybe<ProjectSection>;
  /** When was this comment updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<ProjectCommentUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
export type UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyPatch = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description?: InputMaybe<Scalars['String']>;
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType?: InputMaybe<CommsMessageType>;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
export type UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1Patch = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description?: InputMaybe<Scalars['String']>;
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType?: InputMaybe<CommsMessageType>;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  senderId?: InputMaybe<Scalars['UUID']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectCommunicationMessage` being updated. */
export type UpdateProjectCommunicationMessageOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1Patch = {
  /** Channel for delivery */
  channel?: InputMaybe<CommsMessageChannel>;
  /** When was this project message created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Description of the message */
  description?: InputMaybe<Scalars['String']>;
  /** Message ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project message deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Message type - key, internal or external */
  messageType?: InputMaybe<CommsMessageType>;
  projectConnectionToRecipientId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1Input>;
  projectConnectionToSenderId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1Input>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInput>;
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Time when this message needs to be delivered */
  sendAt?: InputMaybe<Scalars['Datetime']>;
  /** When was this project message last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectCommunicationQuestion` being updated. */
export type UpdateProjectCommunicationQuestionOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyPatch = {
  /** When was this project question created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Question ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project question deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectToProjectId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInput>;
  /** Question type - your or their */
  questionType?: InputMaybe<CommsQuestionType>;
  /** When was this project question last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Question fields */
  value?: InputMaybe<Scalars['JSON']>;
};

/** An object where the defined keys will be set on the `projectCompliance` being updated. */
export type UpdateProjectComplianceOnProjectComplianceForProjectComplianceProjectIdFkeyPatch = {
  /** When was this compliance created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Date of the event */
  eventAt?: InputMaybe<Scalars['Datetime']>;
  /** Compliance ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this compliance archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this compliance deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this event recurring and how often? */
  occurrence?: InputMaybe<RecurringInterval>;
  projectToProjectId?: InputMaybe<ProjectComplianceProjectIdFkeyInput>;
  /** What is the compliance status? */
  status?: InputMaybe<ComplianceStatus>;
  /** When was this compliance last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What needs to be completed? */
  what?: InputMaybe<Scalars['String']>;
  /** Who is the contact? */
  who?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
export type UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch = {
  /** Link ID - needed by Apollo */
  id?: InputMaybe<Scalars['UUID']>;
  /** Internal Project Connection ID */
  internalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Primary or secondary link between the two persons */
  linkType?: InputMaybe<ConnectionLinkType>;
  projectConnectionToExternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInput>;
  projectConnectionToInternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInput>;
  /** Trust status between the two persons */
  trustStatus?: InputMaybe<ConnectionTrustStatus>;
};

/** An object where the defined keys will be set on the `projectConnectionLink` being updated. */
export type UpdateProjectConnectionLinkOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch = {
  /** External Project Connection ID */
  externalProjectConnectionId?: InputMaybe<Scalars['UUID']>;
  /** Link ID - needed by Apollo */
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Primary or secondary link between the two persons */
  linkType?: InputMaybe<ConnectionLinkType>;
  projectConnectionToExternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInput>;
  projectConnectionToInternalProjectConnectionId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInput>;
  /** Trust status between the two persons */
  trustStatus?: InputMaybe<ConnectionTrustStatus>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageRecipientIdFkey1Patch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectCommunicationMessageForProjectCommunicationMessageSenderIdFkey1Patch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectConnectionForProjectConnectionConnectionIdFkeyPatch = {
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectConnectionForProjectConnectionProjectIdFkeyPatch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkExternalProjectConnectionIdFkeyPatch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectConnection` being updated. */
export type UpdateProjectConnectionOnProjectConnectionLinkForProjectConnectionLinkInternalProjectConnectionIdFkeyPatch = {
  /** Related Connection ID */
  connectionId?: InputMaybe<Scalars['UUID']>;
  /** What is the role of this connection? */
  connectionRole?: InputMaybe<ConnectionRole>;
  connectionToConnectionId?: InputMaybe<ProjectConnectionConnectionIdFkeyInput>;
  /** When was this project connection created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the decision maker type of this connection? */
  decisionMaker?: InputMaybe<ConnectionDecisionMaker>;
  /** Project Connection ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project connection deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this a key decision maker? */
  keyDecisionMaker?: InputMaybe<Scalars['Boolean']>;
  projectCommunicationMessagesToRecipientIdUsingId?: InputMaybe<ProjectCommunicationMessageRecipientIdFkey1InverseInput>;
  projectCommunicationMessagesToSenderIdUsingId?: InputMaybe<ProjectCommunicationMessageSenderIdFkey1InverseInput>;
  projectConnectionLinksToExternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkExternalProjectConnectionIdFkeyInverseInput>;
  projectConnectionLinksToInternalProjectConnectionIdUsingId?: InputMaybe<ProjectConnectionLinkInternalProjectConnectionIdFkeyInverseInput>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectConnectionProjectIdFkeyInput>;
  /** When was this project connection last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectEvent` being updated. */
export type UpdateProjectEventOnGoogleCalendarEventForGoogleCalendarEventEventIdFkeyPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectEvent` being updated. */
export type UpdateProjectEventOnProjectEventForProjectEventEventTypeFkeyPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectEvent` being updated. */
export type UpdateProjectEventOnProjectEventForProjectEventProjectIdFkeyPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Event description */
  description?: InputMaybe<Scalars['String']>;
  /** The date and time when the event ends */
  endTime?: InputMaybe<Scalars['Datetime']>;
  /** What type of event it is */
  eventType?: InputMaybe<Scalars['String']>;
  /** If event type = 'Other' this is set to whatever the event is */
  eventTypeCustom?: InputMaybe<Scalars['String']>;
  eventTypeToEventType?: InputMaybe<ProjectEventEventTypeFkeyInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventEventIdFkeyInverseInput>;
  /** Event ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectToProjectId?: InputMaybe<ProjectEventProjectIdFkeyInput>;
  /** Date and time of the event */
  startTime?: InputMaybe<Scalars['Datetime']>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectFolder` being updated. */
export type UpdateProjectFolderOnProjectFolderForProjectFolderFolderIdFkeyPatch = {
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToFolderId?: InputMaybe<ProjectFolderFolderIdFkeyInput>;
  projectToProjectId?: InputMaybe<ProjectFolderProjectIdFkeyInput>;
  /** User */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectFolderUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectFolder` being updated. */
export type UpdateProjectFolderOnProjectFolderForProjectFolderProjectIdFkeyPatch = {
  /** Folder ID */
  folderId?: InputMaybe<Scalars['UUID']>;
  projectToFolderId?: InputMaybe<ProjectFolderFolderIdFkeyInput>;
  projectToProjectId?: InputMaybe<ProjectFolderProjectIdFkeyInput>;
  /** User */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectFolderUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectFolder` being updated. */
export type UpdateProjectFolderOnProjectFolderForProjectFolderUserIdFkeyPatch = {
  /** Folder ID */
  folderId?: InputMaybe<Scalars['UUID']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToFolderId?: InputMaybe<ProjectFolderFolderIdFkeyInput>;
  projectToProjectId?: InputMaybe<ProjectFolderProjectIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectFolderUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectInvitation` being updated. */
export type UpdateProjectInvitationOnProjectInvitationForProjectInvitationProjectIdFkeyPatch = {
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Are we giving them viewer, editor or owner access to the project? */
  membershipType?: InputMaybe<ProjectMembershipType>;
  /** Invitation message (optional) */
  message?: InputMaybe<Scalars['String']>;
  projectToProjectId?: InputMaybe<ProjectInvitationProjectIdFkeyInput>;
  /** Should we notify the user about this project being shared with them? */
  sendNotification?: InputMaybe<Scalars['Boolean']>;
  /** User ID to invite */
  userId?: InputMaybe<Scalars['UUID']>;
  userToUserId?: InputMaybe<ProjectInvitationUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectInvitation` being updated. */
export type UpdateProjectInvitationOnProjectInvitationForProjectInvitationUserIdFkeyPatch = {
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Are we giving them viewer, editor or owner access to the project? */
  membershipType?: InputMaybe<ProjectMembershipType>;
  /** Invitation message (optional) */
  message?: InputMaybe<Scalars['String']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectInvitationProjectIdFkeyInput>;
  /** Should we notify the user about this project being shared with them? */
  sendNotification?: InputMaybe<Scalars['Boolean']>;
  userToUserId?: InputMaybe<ProjectInvitationUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectMembership` being updated. */
export type UpdateProjectMembershipOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch = {
  /** When was this member added to the project? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project archived for this particular user? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this person an editor for the project? */
  isEditor?: InputMaybe<Scalars['Boolean']>;
  /** Is this person a project owner (can share)? */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectMembershipProjectIdFkeyInput>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToCreatedByUserId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectMembershipUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectMembership` being updated. */
export type UpdateProjectMembershipOnProjectMembershipForProjectMembershipProjectIdFkeyPatch = {
  /** When was this member added to the project? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who invited this member */
  createdByUserId?: InputMaybe<Scalars['UUID']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project archived for this particular user? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this person an editor for the project? */
  isEditor?: InputMaybe<Scalars['Boolean']>;
  /** Is this person a project owner (can share)? */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  projectToProjectId?: InputMaybe<ProjectMembershipProjectIdFkeyInput>;
  /** User ID */
  userId?: InputMaybe<Scalars['UUID']>;
  userToCreatedByUserId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectMembershipUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectMembership` being updated. */
export type UpdateProjectMembershipOnProjectMembershipForProjectMembershipUserIdFkeyPatch = {
  /** When was this member added to the project? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who invited this member */
  createdByUserId?: InputMaybe<Scalars['UUID']>;
  /** Unique ID for the record */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project archived for this particular user? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this person an editor for the project? */
  isEditor?: InputMaybe<Scalars['Boolean']>;
  /** Is this person a project owner (can share)? */
  isOwner?: InputMaybe<Scalars['Boolean']>;
  /** Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectMembershipProjectIdFkeyInput>;
  userToCreatedByUserId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInput>;
  userToUserId?: InputMaybe<ProjectMembershipUserIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectObjective` being updated. */
export type UpdateProjectObjectiveOnProjectObjectiveForProjectObjectiveProjectIdFkeyPatch = {
  /** Objective ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this objective deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Possible values: opportunity, dynamics, smart, their, restrictions_int, restrictions_ext */
  name?: InputMaybe<Scalars['String']>;
  projectToProjectId?: InputMaybe<ProjectObjectiveProjectIdFkeyInput>;
  /** Value is JSON with answers provided by users. */
  value?: InputMaybe<Scalars['JSON']>;
};

/** An object where the defined keys will be set on the `projectOffer` being updated. */
export type UpdateProjectOfferOnProjectOfferForProjectOfferProjectIdFkeyPatch = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectOffer` being updated. */
export type UpdateProjectOfferOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyPatch = {
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this project offer deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectOfferProjectIdFkeyInput>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
export type UpdateProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableProjectOfferIdFkeyPatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `projectOfferVariable` being updated. */
export type UpdateProjectOfferVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyPatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** When was this project offer created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Offer Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Are we aligned with the other side? */
  isAligned?: InputMaybe<Scalars['Boolean']>;
  /** None, gets or gives */
  list?: InputMaybe<OfferVariableList>;
  /** Value selected by us */
  ourSelected?: InputMaybe<Scalars['String']>;
  /** Related Project Offer ID */
  projectOfferId?: InputMaybe<Scalars['BigInt']>;
  projectOfferToProjectOfferId?: InputMaybe<ProjectOfferVariableProjectOfferIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInput>;
  /** Value selected by them */
  theirSelected?: InputMaybe<Scalars['String']>;
  /** When was this project offer last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** How to order this variable? */
  weight?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectAccessLogForProjectAccessLogProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectAlignSurveyForProjectAlignSurveyProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectCommentForProjectCommentProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectCommunicationMessageForProjectCommunicationMessageProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectCommunicationQuestionForProjectCommunicationQuestionProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectComplianceForProjectComplianceProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectConnectionForProjectConnectionProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectEventForProjectEventProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectFolderForProjectFolderFolderIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectFolderForProjectFolderProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectCreatedByFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectCustomerIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectForProjectParentIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectInvitationForProjectInvitationProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectMembershipForProjectMembershipProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectObjectiveForProjectObjectiveProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectOfferForProjectOfferProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectPowerForProjectPowerProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectRiskForProjectRiskProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectScenarioForProjectScenarioProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectScoreForProjectScoreProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectSearchForProjectSearchProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectSurveyForProjectSurveyProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnProjectVariableForProjectVariableProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `project` being updated. */
export type UpdateProjectOnVariableClassForVariableClassRelatedProjectIdFkeyPatch = {
  /** Account ID - which organization this project belongs to */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<ProjectAccountIdFkeyInput>;
  /** When was this folder creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created this folder */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Customer related to the project */
  customerId?: InputMaybe<Scalars['UUID']>;
  customerToCustomerId?: InputMaybe<ProjectCustomerIdFkeyInput>;
  /** File type: p for project or f for folder */
  fileType?: InputMaybe<Scalars['String']>;
  /** Project / folder ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this folder deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Has the negotiation been closed already? */
  isNegotiationClosed?: InputMaybe<Scalars['Boolean']>;
  /** Is the negotiation open yet? */
  isNegotiationOpen?: InputMaybe<Scalars['Boolean']>;
  /** Is it Open - if so, it can be shared with people from different organizations */
  isOpen?: InputMaybe<Scalars['Boolean']>;
  /** File (project or folder) name */
  name?: InputMaybe<Scalars['String']>;
  /** Determines what type of objectives form we will display */
  objectivesType?: InputMaybe<ObjectivesType>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogProjectIdFkeyInverseInput>;
  /**
   * Parent folder ID. Use this field when creating a project or a folder.
   * For reading, Folder ID should be used instead.
   */
  parentId?: InputMaybe<Scalars['UUID']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogProjectIdFkeyInverseInput>;
  projectAlignSurveyUsingId?: InputMaybe<ProjectAlignSurveyProjectIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentProjectIdFkeyInverseInput>;
  projectCommunicationMessagesUsingId?: InputMaybe<ProjectCommunicationMessageProjectIdFkeyInverseInput>;
  projectCommunicationQuestionsUsingId?: InputMaybe<ProjectCommunicationQuestionProjectIdFkeyInverseInput>;
  projectCompliancesUsingId?: InputMaybe<ProjectComplianceProjectIdFkeyInverseInput>;
  projectConnectionsUsingId?: InputMaybe<ProjectConnectionProjectIdFkeyInverseInput>;
  projectEventsUsingId?: InputMaybe<ProjectEventProjectIdFkeyInverseInput>;
  projectFoldersToFolderIdUsingId?: InputMaybe<ProjectFolderFolderIdFkeyInverseInput>;
  projectFoldersToProjectIdUsingId?: InputMaybe<ProjectFolderProjectIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationProjectIdFkeyInverseInput>;
  projectMembershipsUsingId?: InputMaybe<ProjectMembershipProjectIdFkeyInverseInput>;
  projectObjectivesUsingId?: InputMaybe<ProjectObjectiveProjectIdFkeyInverseInput>;
  projectOffersUsingId?: InputMaybe<ProjectOfferProjectIdFkeyInverseInput>;
  projectPowerUsingId?: InputMaybe<ProjectPowerProjectIdFkeyInverseInput>;
  projectRisksUsingId?: InputMaybe<ProjectRiskProjectIdFkeyInverseInput>;
  projectScenariosUsingId?: InputMaybe<ProjectScenarioProjectIdFkeyInverseInput>;
  projectScoresUsingId?: InputMaybe<ProjectScoreProjectIdFkeyInverseInput>;
  projectSearchUsingId?: InputMaybe<ProjectSearchProjectIdFkeyInverseInput>;
  projectSurveyUsingId?: InputMaybe<ProjectSurveyProjectIdFkeyInverseInput>;
  projectToParentId?: InputMaybe<ProjectParentIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableProjectIdFkeyInverseInput>;
  /** Text search vector for the project title */
  titleTsv?: InputMaybe<Scalars['FullText']>;
  /** When was this folder last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<ProjectCreatedByFkeyInput>;
  /** Project value */
  value?: InputMaybe<Scalars['String']>;
  variableClassesUsingId?: InputMaybe<VariableClassRelatedProjectIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `projectPower` being updated. */
export type UpdateProjectPowerOnProjectPowerForProjectPowerProjectIdFkeyPatch = {
  /** JSON with all the answers of Assess the power section. */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project power state created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** JSON with all the answers of the Power perception shifter inputs, with keys: enhance, change, downgrade, exploit. */
  perception?: InputMaybe<Scalars['JSON']>;
  projectToProjectId?: InputMaybe<ProjectPowerProjectIdFkeyInput>;
  /** JSON with scores calculated based on the answers, with keys: power_state, macro, micro. */
  score?: InputMaybe<Scalars['JSON']>;
  /** When was this project power state last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectRisk` being updated. */
export type UpdateProjectRiskOnProjectRiskForProjectRiskProjectIdFkeyPatch = {
  /** When was this event created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Risk description */
  description?: InputMaybe<Scalars['String']>;
  /** Risk ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** What would be the impact if it happens? */
  impactOnBusiness?: InputMaybe<Scalars['Int']>;
  /** Impact score for this risk (auto-calculated) */
  impactScore?: InputMaybe<Scalars['BigFloat']>;
  /** Is this event archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this event deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** What are the mitigation actions? */
  mitigationAction?: InputMaybe<Scalars['String']>;
  /** What can we do to prevent it from happening? */
  preventiveDescription?: InputMaybe<Scalars['String']>;
  /** Probability of happening */
  probability?: InputMaybe<Scalars['BigFloat']>;
  projectToProjectId?: InputMaybe<ProjectRiskProjectIdFkeyInput>;
  /** Risk level (auto-calculated) */
  riskLevel?: InputMaybe<RiskLevel>;
  /** When was this event last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectScenario` being updated. */
export type UpdateProjectScenarioOnProjectScenarioForProjectScenarioProjectIdFkeyPatch = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title?: InputMaybe<Scalars['String']>;
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectScenario` being updated. */
export type UpdateProjectScenarioOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyPatch = {
  /** When was this scenario created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Scenario ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Is this scenario deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectScenarioProjectIdFkeyInput>;
  /** Scenario Title */
  title?: InputMaybe<Scalars['String']>;
  /** When was this scenario last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
export type UpdateProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableProjectScenarioIdFkeyPatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
  /** Related Project Variable ID */
  variableId?: InputMaybe<Scalars['UUID']>;
};

/** An object where the defined keys will be set on the `projectScenarioVariable` being updated. */
export type UpdateProjectScenarioVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyPatch = {
  /** Variable amount (value) */
  amount?: InputMaybe<Scalars['BigFloat']>;
  /** Scenario Variable ID */
  id?: InputMaybe<Scalars['BigInt']>;
  /** Alternative value if selected = 'other' */
  other?: InputMaybe<Scalars['String']>;
  /** Related Project Scenario ID */
  projectScenarioId?: InputMaybe<Scalars['BigInt']>;
  projectScenarioToProjectScenarioId?: InputMaybe<ProjectScenarioVariableProjectScenarioIdFkeyInput>;
  projectVariableToVariableId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInput>;
  /** What have we selected for this variable? */
  selected?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `projectScore` being updated. */
export type UpdateProjectScoreOnProjectScoreForProjectScoreProjectIdFkeyPatch = {
  /** When this score item was completed. */
  completedAt?: InputMaybe<Scalars['Datetime']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Score ID */
  id?: InputMaybe<Scalars['UUID']>;
  projectToProjectId?: InputMaybe<ProjectScoreProjectIdFkeyInput>;
  recordId?: InputMaybe<Scalars['String']>;
  /** Score from 0.00 to 100.00 */
  score?: InputMaybe<Scalars['BigFloat']>;
  /** Related project table */
  scoreKey?: InputMaybe<ProjectScoreKey>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectSearch` being updated. */
export type UpdateProjectSearchOnProjectSearchForProjectSearchProjectIdFkeyPatch = {
  /** Extended tsvector for all text fields in a project */
  fullTsv?: InputMaybe<Scalars['FullText']>;
  projectToProjectId?: InputMaybe<ProjectSearchProjectIdFkeyInput>;
};

/** An object where the defined keys will be set on the `projectSurvey` being updated. */
export type UpdateProjectSurveyOnProjectSurveyForProjectSurveyProjectIdFkeyPatch = {
  /** JSON with all the answers from the initial, survey and pending states */
  answers?: InputMaybe<Scalars['JSON']>;
  /** When was this project survey created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Type of negotiaton, based on survey answers */
  negotiationType?: InputMaybe<NegotiationType>;
  projectToProjectId?: InputMaybe<ProjectSurveyProjectIdFkeyInput>;
  /** JSON array with scores calculated based on the answers, e.g. [3, 4] */
  score?: InputMaybe<Scalars['JSON']>;
  /** Possible values: initial, survey, pending and completed */
  state?: InputMaybe<Scalars['String']>;
  /** When was this project survey last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
};

/** An object where the defined keys will be set on the `projectVariable` being updated. */
export type UpdateProjectVariableOnProjectOfferVariableForProjectOfferVariableVariableIdFkeyPatch = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** An object where the defined keys will be set on the `projectVariable` being updated. */
export type UpdateProjectVariableOnProjectScenarioVariableForProjectScenarioVariableVariableIdFkeyPatch = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** An object where the defined keys will be set on the `projectVariable` being updated. */
export type UpdateProjectVariableOnProjectVariableForProjectVariableProjectIdFkeyPatch = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  /** What is the variable class? */
  variableClassId?: InputMaybe<Scalars['UUID']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** An object where the defined keys will be set on the `projectVariable` being updated. */
export type UpdateProjectVariableOnProjectVariableForProjectVariableVariableClassIdFkeyPatch = {
  /** What is the expected (predicted) value? */
  anticipated?: InputMaybe<Scalars['String']>;
  /** When was this project variable created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  currentProjectOfferVariablesUsingId?: InputMaybe<FakeNptCurrentProjectOfferVariableForeignKey0InverseInput>;
  /** Variable descriptor (name), can be empty. Unique per project and variable class. */
  descriptor?: InputMaybe<Scalars['String']>;
  /** Project Variable ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this project variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Related Project ID */
  projectId?: InputMaybe<Scalars['UUID']>;
  projectOfferVariablesUsingId?: InputMaybe<ProjectOfferVariableVariableIdFkeyInverseInput>;
  projectScenarioVariablesUsingId?: InputMaybe<ProjectScenarioVariableVariableIdFkeyInverseInput>;
  projectToProjectId?: InputMaybe<ProjectVariableProjectIdFkeyInput>;
  /** Where in the list should it appear? Used as weight - low at the top, high at the bottom. */
  rank?: InputMaybe<Scalars['Int']>;
  /** When is their value for the variable? */
  their?: InputMaybe<VariableStateInput>;
  /** When type of variable is this? Get, Get/Give or Give? */
  type?: InputMaybe<VariableType>;
  /** When was this project variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  variableClassToVariableClassId?: InputMaybe<ProjectVariableVariableClassIdFkeyInput>;
  /** What is your value for the variable? */
  your?: InputMaybe<VariableStateInput>;
};

/** An object where the defined keys will be set on the `surveyResponse` being updated. */
export type UpdateSurveyResponseOnSurveyResponseForSurveyResponseUserIdFkeyPatch = {
  /** When was this response created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  id?: InputMaybe<Scalars['UUID']>;
  /** The user that the response is for, used for Peer Feedback Survey only! */
  recipientId?: InputMaybe<Scalars['UUID']>;
  /** Column that is computed by the backend based on the answers of all questions in the response */
  score?: InputMaybe<Scalars['JSON']>;
  /** Which survey type the response is for */
  surveyType?: InputMaybe<SurveyType>;
  /** When was this response last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<SurveyResponseUserIdFkeyInput>;
  /** The corresponding id to v1 workshops. Used to validate recipients for Peer Feedback Survey */
  v1WorkshopId?: InputMaybe<Scalars['Int']>;
};

/** An object where the defined keys will be set on the `userLanguage` being updated. */
export type UpdateUserLanguageOnBriefGroupForBriefGroupLanguageCodeFkeyPatch = {
  briefGroupsUsingLanguageCode?: InputMaybe<BriefGroupLanguageCodeFkeyInverseInput>;
  /** The name of the language in the corresponding languge, customer facing */
  displayName?: InputMaybe<Scalars['String']>;
  /** ISO 639-1 Language Code, can be used with Weglot and similar services */
  isoLangCode?: InputMaybe<Scalars['String']>;
  /** The name of the language in English */
  name?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnClassForClassCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnClassUserForClassUserUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnClassVideoUserForClassVideoUserUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnConnectionForConnectionUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnConsultantOrganizationAccessForConsultantOrganizationAccessUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnDocumentForDocumentCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnFeedbackResponseForFeedbackResponseUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnGoogleAccountUserForGoogleAccountUserUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnGoogleCalendarEventForGoogleCalendarEventUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnLv1GameScoreForGameLv1ScoreUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnLv1GameStateForGameLv1StateUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnLv1GameSubgroupForGameLv1SubgroupCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnLv1GameSubgroupUserForUserGameLv1SubgroupUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnMassUserUploadForMassUserUploadCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnOrganizationCoachingTimeLogForOrganizationCoachingTimeLogConsultantIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectAccessLogForProjectAccessLogUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectCommentForProjectCommentUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectFolderForProjectFolderUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectForProjectCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectInvitationForProjectInvitationUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectMembershipForProjectMembershipCreatedByUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnProjectMembershipForProjectMembershipUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnSurveyResponseForSurveyResponseUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserForUserAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserForUserGameLv1SubgroupIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnVariableClassForVariableClassCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnVideoForVideoCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnWorkshopForWorkshopCreatedByFkeyPatch = {
  /** Account of the user */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<UserAccountIdFkeyInput>;
  /** Optional avatar URL */
  avatarUrl?: InputMaybe<Scalars['String']>;
  classUsersUsingId?: InputMaybe<ClassUserUserIdFkeyInverseInput>;
  classVideoUsersUsingId?: InputMaybe<ClassVideoUserUserIdFkeyInverseInput>;
  classesUsingId?: InputMaybe<ClassCreatedByFkeyInverseInput>;
  connectionsUsingId?: InputMaybe<ConnectionUserIdFkeyInverseInput>;
  consultantOrganizationAccessesUsingId?: InputMaybe<ConsultantOrganizationAccessUserIdFkeyInverseInput>;
  /** When was this user created? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Division of the User */
  divisionId?: InputMaybe<Scalars['UUID']>;
  documentsUsingId?: InputMaybe<DocumentCreatedByFkeyInverseInput>;
  /** Unique email address */
  email?: InputMaybe<Scalars['Email']>;
  feedbackResponsesUsingId?: InputMaybe<FeedbackResponseUserIdFkeyInverseInput>;
  /** Full name */
  fullname?: InputMaybe<Scalars['String']>;
  /** Which LV1 game subgroup this user belongs to */
  gameLv1SubgroupId?: InputMaybe<Scalars['UUID']>;
  /** The supossed gender, not shown to user via the FE */
  gender?: InputMaybe<Scalars['String']>;
  /** Checked performed at gender-api.com via the worker */
  genderChecked?: InputMaybe<Scalars['Boolean']>;
  googleAccountUsersUsingId?: InputMaybe<GoogleAccountUserUserIdFkeyInverseInput>;
  googleCalendarEventsUsingId?: InputMaybe<GoogleCalendarEventUserIdFkeyInverseInput>;
  /** Unique ID for the user */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this user archived (suspended)? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this user deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access Learning Videos from the dashboard or is directed to landing page */
  isLms?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access LV1 Game from the dashboard or is directed to landing page */
  isLv1?: InputMaybe<Scalars['Boolean']>;
  /** Indicates if the user can access NPT functionality from the dashboard or is directed to a landing page */
  isNpt?: InputMaybe<Scalars['Boolean']>;
  /** Is this user verified? */
  isVerified?: InputMaybe<Scalars['Boolean']>;
  /** User language set by admin */
  languageCode?: InputMaybe<Scalars['String']>;
  lv1GameScoreUsingId?: InputMaybe<GameLv1ScoreUserIdFkeyInverseInput>;
  lv1GameStateUsingId?: InputMaybe<GameLv1StateUserIdFkeyInverseInput>;
  lv1GameSubgroupToGameLv1SubgroupId?: InputMaybe<UserGameLv1SubgroupIdFkeyInput>;
  lv1GameSubgroupUsersUsingId?: InputMaybe<UserGameLv1SubgroupUserIdFkeyInverseInput>;
  lv1GameSubgroupsUsingId?: InputMaybe<GameLv1SubgroupCreatedByFkeyInverseInput>;
  /** Is this user a LV1 game subgroup admin */
  lv1SubgroupAdmin?: InputMaybe<Scalars['Boolean']>;
  /** Manager Name, used for Workshops */
  managerName?: InputMaybe<Scalars['String']>;
  massUserUploadsUsingId?: InputMaybe<MassUserUploadCreatedByFkeyInverseInput>;
  organizationCoachingTimeLogsUsingId?: InputMaybe<OrganizationCoachingTimeLogConsultantIdFkeyInverseInput>;
  /** Has the user updated their password and when was the last update */
  passwordUpdatedAt?: InputMaybe<Scalars['Datetime']>;
  /** Cell Phone of the person */
  phoneCell?: InputMaybe<Scalars['String']>;
  /** Work Phone of the person */
  phoneWork?: InputMaybe<Scalars['String']>;
  projectAccessLogsUsingId?: InputMaybe<ProjectAccessLogUserIdFkeyInverseInput>;
  projectCommentsUsingId?: InputMaybe<ProjectCommentUserIdFkeyInverseInput>;
  projectFoldersUsingId?: InputMaybe<ProjectFolderUserIdFkeyInverseInput>;
  projectInvitationsUsingId?: InputMaybe<ProjectInvitationUserIdFkeyInverseInput>;
  projectMembershipsToCreatedByUserIdUsingId?: InputMaybe<ProjectMembershipCreatedByUserIdFkeyInverseInput>;
  projectMembershipsToUserIdUsingId?: InputMaybe<ProjectMembershipUserIdFkeyInverseInput>;
  projectsUsingId?: InputMaybe<ProjectCreatedByFkeyInverseInput>;
  /** Role in the system */
  role?: InputMaybe<Scalars['String']>;
  surveyResponsesUsingId?: InputMaybe<SurveyResponseUserIdFkeyInverseInput>;
  /** Title of the person */
  title?: InputMaybe<Scalars['String']>;
  /** When was this user last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userProfileQuestionnaireUsingId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInverseInput>;
  /** The Role of the User in v1 */
  v1RoleId?: InputMaybe<Scalars['Int']>;
  variableClassesUsingId?: InputMaybe<VariableClassCreatedByFkeyInverseInput>;
  videosUsingId?: InputMaybe<VideoCreatedByFkeyInverseInput>;
  /** If true regular v1 User is allowed to view Workshop Feedback Reports which is generally accessible only for Admins and Super Admins */
  workshopFeedbackReportsPermission?: InputMaybe<Scalars['Boolean']>;
  workshopsUsingId?: InputMaybe<WorkshopCreatedByFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `userProfileQuestionnaire` being updated. */
export type UpdateUserProfileQuestionnaireOnUserProfileQuestionnaireForUserProfileQuestionnaireUserIdFkeyPatch = {
  biggestEnablers?: InputMaybe<Scalars['String']>;
  biggestObstacles?: InputMaybe<Scalars['String']>;
  confidenceLevel?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  effectiveNegotiator?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  negotiateWith?: InputMaybe<Scalars['String']>;
  negotiation?: InputMaybe<YesNoOption>;
  negotiatorStyle?: InputMaybe<NegotiatorStyle>;
  training?: InputMaybe<YesNoOption>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToUserId?: InputMaybe<UserProfileQuestionnaireUserIdFkeyInput>;
  variables?: InputMaybe<Scalars['String']>;
  whenTraining?: InputMaybe<Scalars['String']>;
  withWho?: InputMaybe<Scalars['String']>;
};

/** An object where the defined keys will be set on the `variableClass` being updated. */
export type UpdateVariableClassOnProjectVariableForProjectVariableVariableClassIdFkeyPatch = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name?: InputMaybe<Scalars['String']>;
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `variableClass` being updated. */
export type UpdateVariableClassOnVariableClassForVariableAccountIdFkeyPatch = {
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name?: InputMaybe<Scalars['String']>;
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `variableClass` being updated. */
export type UpdateVariableClassOnVariableClassForVariableClassCreatedByFkeyPatch = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name?: InputMaybe<Scalars['String']>;
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** Project where this Variable Class has been used initially */
  relatedProjectId?: InputMaybe<Scalars['UUID']>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `variableClass` being updated. */
export type UpdateVariableClassOnVariableClassForVariableClassRelatedProjectIdFkeyPatch = {
  /** Account where this Variable Class belongs. Always NULL for Open Projects. */
  accountId?: InputMaybe<Scalars['UUID']>;
  accountToAccountId?: InputMaybe<VariableAccountIdFkeyInput>;
  /** When was this variable creatd? */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Creator of the variable class, related to the users model */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Variable Class ID */
  id?: InputMaybe<Scalars['UUID']>;
  /** Is this variable class archived? */
  isArchived?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable deleted? */
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  /** Is this variable class global for the account? */
  isGlobal?: InputMaybe<Scalars['Boolean']>;
  /** Variable Class name */
  name?: InputMaybe<Scalars['String']>;
  projectToRelatedProjectId?: InputMaybe<VariableClassRelatedProjectIdFkeyInput>;
  projectVariablesUsingId?: InputMaybe<ProjectVariableVariableClassIdFkeyInverseInput>;
  /** When was this variable last updated? */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VariableClassCreatedByFkeyInput>;
};

/** An object where the defined keys will be set on the `video` being updated. */
export type UpdateVideoOnClassVideoForClassVideoVideoIdFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** An object where the defined keys will be set on the `video` being updated. */
export type UpdateVideoOnVideoForVideoCategoryIdFkeyPatch = {
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** An object where the defined keys will be set on the `video` being updated. */
export type UpdateVideoOnVideoForVideoCreatedByFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** An object where the defined keys will be set on the `video` being updated. */
export type UpdateVideoOnVideoStarForVideoStarVideoIdFkeyPatch = {
  categoryId?: InputMaybe<Scalars['UUID']>;
  classAssetCategoryToCategoryId?: InputMaybe<VideoCategoryIdFkeyInput>;
  classVideosUsingId?: InputMaybe<ClassVideoVideoIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User id that created the video */
  createdBy?: InputMaybe<Scalars['UUID']>;
  description?: InputMaybe<Scalars['String']>;
  /** Duration of the video in seconds */
  duration?: InputMaybe<Scalars['Int']>;
  emailBlurb?: InputMaybe<Scalars['String']>;
  emailSubject?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['UUID']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Is the video already processed by Vimeo or not */
  processingStatus?: InputMaybe<VideoProcessingStatus>;
  thumbnailUrl?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<VideoCreatedByFkeyInput>;
  videoStarsUsingId?: InputMaybe<VideoStarVideoIdFkeyInverseInput>;
  vimeoId?: InputMaybe<Scalars['BigInt']>;
};

/** An object where the defined keys will be set on the `videoStar` being updated. */
export type UpdateVideoStarOnVideoStarForVideoStarVideoIdFkeyPatch = {
  id?: InputMaybe<Scalars['UUID']>;
  isStarred?: InputMaybe<Scalars['Boolean']>;
  userId?: InputMaybe<Scalars['UUID']>;
  videoToVideoId?: InputMaybe<VideoStarVideoIdFkeyInput>;
};

/** An object where the defined keys will be set on the `workshop` being updated. */
export type UpdateWorkshopOnWorkshopForWorkshopCreatedByFkeyPatch = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** Which preset is used for this workshop */
  presetId?: InputMaybe<Scalars['Int']>;
  /** When does the workshop start */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Title of the workshop */
  title?: InputMaybe<Scalars['String']>;
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

/** An object where the defined keys will be set on the `workshop` being updated. */
export type UpdateWorkshopOnWorkshopForWorkshopPresetIdFkeyPatch = {
  /** When was the workshop created */
  createdAt?: InputMaybe<Scalars['Datetime']>;
  /** User who created the workshop */
  createdBy?: InputMaybe<Scalars['UUID']>;
  /** Primary ID of the workshop */
  id?: InputMaybe<Scalars['UUID']>;
  /** When does the workshop start */
  startsAt?: InputMaybe<Scalars['Datetime']>;
  /** Title of the workshop */
  title?: InputMaybe<Scalars['String']>;
  /** When was the workshop last updated */
  updatedAt?: InputMaybe<Scalars['Datetime']>;
  userToCreatedBy?: InputMaybe<WorkshopCreatedByFkeyInput>;
  workshopPresetToPresetId?: InputMaybe<WorkshopPresetIdFkeyInput>;
};

/** An object where the defined keys will be set on the `workshopPreset` being updated. */
export type UpdateWorkshopPresetOnBriefPresetForBriefPresetWorkshopPresetIdFkeyPatch = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Workshop Level */
  level?: InputMaybe<WorkshopLevel>;
  /** Title of the workshop preset */
  title?: InputMaybe<Scalars['String']>;
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `workshopPreset` being updated. */
export type UpdateWorkshopPresetOnWorkshopForWorkshopPresetIdFkeyPatch = {
  /** An ordered array of activities, where each element is a FK to an activity */
  activities?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  briefPresetsUsingId?: InputMaybe<BriefPresetWorkshopPresetIdFkeyInverseInput>;
  /** Primary ID of the workshop preset */
  id?: InputMaybe<Scalars['Int']>;
  /** Foreign key to language, e.g. "en-US" */
  languageCode?: InputMaybe<Scalars['String']>;
  /** Workshop Level */
  level?: InputMaybe<WorkshopLevel>;
  /** Title of the workshop preset */
  title?: InputMaybe<Scalars['String']>;
  workshopsUsingId?: InputMaybe<WorkshopPresetIdFkeyInverseInput>;
};
