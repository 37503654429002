import { useLDClient } from 'launchdarkly-react-client-sdk'
import { useSession } from 'next-auth/react'
import { createContext, ReactNode, useEffect } from 'react'

export type LDIdentityProviderType = { children?: ReactNode }

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LDIdentityContext = createContext<ReturnType<typeof useInit>>(null as any)

const useInit = () => {
  const session = useSession()
  const ldClient = useLDClient()
  useEffect(() => {
    if (session.data?.user.id) {
      ldClient?.identify({
        key: session.data.user.id,
        email: session.data.user.email,
        accountId: session.data.user.accountId,
      })
    }
  }, [ldClient, session])
  return {}
}

export const LDIdentityProvider = ({ children }: LDIdentityProviderType) => {
  return <LDIdentityContext.Provider value={useInit()}>{children}</LDIdentityContext.Provider>
}
