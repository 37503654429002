import { NextAuthOptions } from 'next-auth'

import { ProjectSection } from 'graphql/types'

export enum AppMode {
  NPT = 'npt',
  Lv1 = 'lv1',
  Learning = 'learning',
  Workshop = 'workshop',
}

export enum ProjectTabs {
  dashboard = 'dashboard',
  questions = 'questions',
  objectives = 'objectives',
  power = 'power',
  variables = 'variables',
  communications = 'communications',
  time = 'time',
  risk = 'risk',
  negotiate = 'negotiate',
  align = 'align',
}

export type PrintableProjectTabs = Exclude<ProjectTabs, ProjectTabs.dashboard>

export const printableProjectTabs = Object.values(ProjectTabs).filter(
  (tab) => tab !== ProjectTabs.dashboard,
) as PrintableProjectTabs[]

export enum ProjectTabsSubcategories {
  CommunicationConnections = 'communicationconnections',
  CommunicationKeyMessages = 'communicationkeymessages',
  CommunicationExternalMessages = 'communicationexternalmessages',
  CommunicationInternalMessages = 'communicationinternalmessages',
  CommunicationQuestions = 'communicationquestions',
  NegotiateScenarios = 'negotiatescenarios',
  NegotiateDealTracker = 'negotiatedealtracker',
}

export const projectTabsSubcategories = Object.values(ProjectTabsSubcategories)

export enum SettingsTabs {
  users = 'users',
  bulkImports = 'bulk-imports',
  organizations = 'organizations',
}
export enum OrganizationTabs {
  users = 'users',
  settings = 'settings',
}
export enum LearningManageTabs {
  videos = 'videos',
  documents = 'documents',
  classes = 'classes',
}
export enum LearningManageClassTabs {
  details = 'details',
  users = 'users',
}

export type Tabs = LearningManageClassTabs | LearningManageTabs

export const baseProjectsRoute = `/${AppMode.NPT}/projects/[slug]`
export const baseFoldersRoute = `/${AppMode.NPT}/projects/folder/[id]`
export const routes = {
  root: '/',
  login: '/login',
  loginWithSso: '/login-with-sso',
  ssoError: '/sso-error',
  logout: '/logout',
  register: '/register',
  loginLink: '/login-link',
  tempLink: '/temp-link',
  forgotEmail: '/forgot-email',
  resetPassword: '/reset-password',
  forgotPassword: '/forgot-password',
  workshopsCalendar: '/workshops-calendar',
  changePassword: '/change-password',
  learning: {
    index: `/${AppMode.Learning}/`,
    manage: {
      index: `/${AppMode.Learning}/`,
      tabs: (tab: LearningManageTabs) => {
        return `/${AppMode.Learning}/manage/${tab}`
      },
      class: {
        create: {
          new: (tab?: LearningManageClassTabs) => {
            const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

            return `${routes.learning.manage.tabs(LearningManageTabs.classes)}/add${tabPath}`
          },
          fromClassId: (classId: string, tab?: LearningManageClassTabs) => {
            const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

            return {
              pathname: `${routes.learning.manage.tabs(
                LearningManageTabs.classes,
              )}/add/[fromClassId]${tabPath}`,
              query: { fromClassId: classId },
            }
          },
        },
        byId: (classId: string, tab?: LearningManageClassTabs) => {
          const tabPath = resolveTabPath(LearningManageClassTabs.details, tab)

          return {
            pathname: `${routes.learning.manage.tabs(
              LearningManageTabs.classes,
            )}/[classId]${tabPath}`,
            query: { classId: classId },
          }
        },
      },
      videoById: (videoId: string) =>
        `${routes.learning.manage.tabs(LearningManageTabs.videos)}/${videoId}`,
      documentById: (documentId: string) =>
        `${routes.learning.manage.tabs(LearningManageTabs.documents)}/${documentId}`,
    },
    classes: `/${AppMode.Learning}/classes`,
    classById: (
      classId: string,
      classVideoId?: string,
      query?: { [key: string]: string | boolean | number },
    ) => {
      let url = `/${AppMode.Learning}/classes/${classId}`
      if (classVideoId) {
        url += `/videos/${classVideoId}`
      }
      if (query) {
        url += '?'
        Object.keys(query).forEach((key) => {
          url += `${key}=${query[key]}`
        })
      }
      return url
    },
  },
  lv1: {
    index: `/${AppMode.Lv1}/`,
    game: `/${AppMode.Lv1}/game`,
    dashboard: `/${AppMode.Lv1}/dashboard`,
    subgroups: `/${AppMode.Lv1}/subgroups`,
    mySubgroups: `/${AppMode.Lv1}/my-subgroups`,
    leaderboard: (accountId: string, subgroupId?: string) => {
      const accountUrl = `/${AppMode.Lv1}/${accountId}`
      if (subgroupId) {
        return `${accountUrl}/group/${subgroupId}`
      }
      return accountUrl
    },
    playerProfile: (accountId: string, userId: string) => `/${AppMode.Lv1}/${accountId}/${userId}`,
  },
  workshop: {
    manage: {
      briefs: `/${AppMode.Workshop}/manage/briefs`,
    },
    preWorkshop: {
      profileQuestionnaire: `/${AppMode.Workshop}/pre-workshop/profile-questionnaire`,
      preWorkshopSurvey: `/${AppMode.Workshop}/pre-workshop/pre-workshop-survey`,
    },
    index: `/${AppMode.Workshop}/`,
    dashboard: `/${AppMode.Workshop}/dashboard`,
    peerFeedbackSurvey: `/${AppMode.Workshop}/peer-feedback-survey`,
    workshopFeedback: `/${AppMode.Workshop}/workshop-feedback`,
    profile: `/${AppMode.Workshop}/profile`,
    workshop: `/${AppMode.Workshop}/live`, // @TODO: readjust when workshop is migrated
    print: `/${AppMode.Workshop}/print`,
  },
  npt: {
    index: `/${AppMode.NPT}/`,
    dashboard: `/${AppMode.NPT}/dashboard`,
    variablesBank: `/${AppMode.NPT}/variables-bank`,
    projects: `/${AppMode.NPT}/projects`,
    archivedProjects: `/${AppMode.NPT}/archived-projects`,
    archivedFolderById: (projectId: string) =>
      `/${AppMode.NPT}/archived-projects/folder/${projectId}`,
    folderById: (projectId: string) => `/${AppMode.NPT}/projects/folder/${projectId}`,
    projectById: (projectId: string, tab?: ProjectTabs) => {
      const tabPath = resolveTabPath(ProjectTabs.dashboard, tab)
      return {
        pathname: `${baseProjectsRoute}${tabPath}`,
        query: { slug: projectId },
      }
    },
    projectLinkById: (projectId: string, tab?: ProjectTabs | 'print') => {
      const tabPath = resolveTabPath(ProjectTabs.dashboard, tab)

      return `/${AppMode.NPT}/projects/${projectId}${tabPath}`
    },
  },
  coaching: '/coaching',
  organizationStatistics: {
    index: '/organization-statistics',
    obstaclesEnablers: '/organization-statistics/obstacles-enablers',
    workshopSurvey: '/organization-statistics/workshop-survey',
  },
  // settings: '/settings',
  settings: {
    index: '/settings',
    tabs: (tab: SettingsTabs) => {
      return `/settings/${tab}`
    },
    organizationById: (accountId: string, tab?: OrganizationTabs) => {
      const tabPath = resolveTabPath(OrganizationTabs.users, tab)
      return {
        pathname: `${routes.settings.tabs(SettingsTabs.organizations)}/[accountId]${tabPath}`,
        query: { accountId: accountId },
      }
    },
    userById: (userId: string) => `${routes.settings.tabs(SettingsTabs.users)}/${userId}`,
  },
  profile: '/profile',
  privacyPolicy: '/privacy-policy',
  api: {
    vimeo: {
      uploadLink: `/api/vimeo/videos/upload-link`,
      videos: (vimeoId: string | number) => `/api/vimeo/videos/${vimeoId}`,
    },
    media: {
      uploadLink: `/api/media/upload-link`,
      view: (s3Key: string) => `/api/media/${s3Key}`,
      remove: (s3Key: string) => `/api/media/${s3Key}`,
    },
    google: {
      retrieveTokens: `/api/google/retrieve-tokens`,
      connectUser: `/api/google/connect-user`,
      disconnectUser: `/api/google/disconnect-user`,
    },
    learnworlds: {
      learnworldsSso: `/api/learnworlds/redirect-user`,
    },
    auth: {
      generateSecret: `/api/auth/generate-secret`,
    },
    responseLimit: '8mb',
  },
} as const

export const pages: NextAuthOptions['pages'] = {
  error: routes.login,
  signIn: routes.login,
  signOut: routes.logout,
  verifyRequest: routes.root,
}

export const publicRoutes: string[] = [
  routes.root,
  routes.login,
  routes.loginWithSso,
  routes.ssoError,
  routes.loginLink,
  routes.tempLink,
  routes.logout,
  routes.register,
  routes.resetPassword,
  routes.forgotPassword,
  routes.forgotEmail,
  routes.privacyPolicy,
]

export const tabToSection = (tab: ProjectTabs): ProjectSection => {
  return tab.toUpperCase() as ProjectSection
}
export const sectionToTab = (section: ProjectSection): ProjectTabs => {
  return section.toLowerCase() as ProjectTabs
}
export const routeToSection = (route: string) => {
  const tab = route.replace(baseProjectsRoute, '').replace('/', '')
  if (tab) {
    return tab.toUpperCase() as ProjectSection
  }
  return ProjectSection.Dashboard
}

const resolveTabPath = (rootTab: string, tab?: string) => {
  return !tab || tab === rootTab ? '' : `/${tab}`
}
