import { useFeatureFlag } from 'hooks/useFeatureFlags'
import getConfig from 'next/config'
import Script from 'next/script'
import React, { ReactNode, createContext, useState } from 'react'

// zendesk TS definitions taken from: https://gist.github.com/vsaarinen/b0e2dcaf4f7c522d4ff1f672eb996f73
type ZendeskChatContextType = {
  zendeskChatLoaded: boolean
}

interface ZendeskWidget {
  (type: string, command: string, payload?: unknown): void
  (type: string, command: string, payload: ZendeskSettings): void
}

interface ZendeskUIString {
  [locale: string]: string
}

interface ZendeskField {
  id: string | number
  prefill: ZendeskUIString
}

interface ZendeskSettings {
  analytics?: boolean
  cookies?: boolean
  errorReporting?: boolean
  webWidget?: {
    answerBot?: {
      avatar?: {
        url: string
        name: ZendeskUIString
      }
      suppress?: boolean
      title?: ZendeskUIString
      contactOnlyAfterQuery?: boolean
      search?: {
        labels: string[]
      }
    }
    authenticate?: {
      chat?: {
        jwtFn: (callback: (jwtToken: string) => void) => void
      }
      jwtFn?: (callback: (jwtToken: string) => void) => void
    }
    contactForm?: {
      attachments?: boolean
      fields?: ZendeskField[]
      selectTicketForm?: ZendeskUIString
      subject?: boolean
      suppress?: boolean
      title?: ZendeskUIString
      ticketForms?: Array<{ id: number; fields?: ZendeskField[] }>
    }
    contactOptions?: {
      enabled?: boolean
      contactButton?: ZendeskUIString
      contactFormLabel?: ZendeskUIString
      chatLabelOnline?: ZendeskUIString
      chatLabelOffline?: ZendeskUIString
    }
    chat?: {
      concierge?: {
        avatarPath: string
        name: string
        title: ZendeskUIString
      }
      departments?: {
        enabled: string[]
        select?: string
      }
      hideWhenOffline?: boolean
      menuOptions?: {
        emailTranscript?: boolean
      }
      notifications?: {
        mobile?: {
          disable?: boolean
        }
      }
      offlineForm?: {
        greeting?: ZendeskUIString
      }
      prechatForm?: {
        greeting?: ZendeskUIString
        departmentLabel?: ZendeskUIString
      }
      profileCard?: {
        avatar?: boolean
        rating?: boolean
        title?: boolean
      }
      suppress?: boolean
      tags?: string
      title?: ZendeskUIString
    }
    color?: {
      theme?: string
      launcher?: string
      launcherText?: string
      button?: string
      resultLists?: string
      header?: string
      articleLinks?: string
    }
    helpCenter?: {
      messageButton?: ZendeskUIString
      originalArticleButton?: boolean
      searchPlaceholder?: ZendeskUIString
      suppress?: boolean
      title?: ZendeskUIString
      chatButton?: ZendeskUIString
      filter?: {
        category?: string
        section?: string
        label_names?: string
      }
    }
    launcher?: {
      label?: ZendeskUIString
      mobile?: {
        labelVisible?: boolean
      }
      badge?: {
        label?: ZendeskUIString
        image?: string
        layout?: 'image_right' | 'image_left' | 'image_only' | 'text_only'
      }
      chatLabel?: ZendeskUIString
    }
    navigation?: {
      popoutButton?: {
        enabled?: boolean
      }
    }
    offset?: {
      horizontal?: string
      vertical?: string
      mobile?: {
        horizontal?: string
        vertical?: string
      }
    }
    position?: {
      horizontal: 'left' | 'right'
      vertical: 'top' | 'bottom'
    }
    talk?: {
      nickname?: string
      suppress?: boolean
      title?: ZendeskUIString
    }
    zIndex?: number
  }
}

declare global {
  interface Window {
    zESettings?: ZendeskSettings
    zE?: ZendeskWidget
  }
}

const ZendeskChatContext = createContext<ZendeskChatContextType | undefined>(undefined)

export const ZendeskChatProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [errorLoading, setErrorLoading] = useState<boolean | null>(null)
  const chatEnabled = useFeatureFlag('zendeskChatbot')

  const {
    publicRuntimeConfig: { zendeskToken },
  } = getConfig()

  return (
    <>
      {zendeskToken && chatEnabled && (
        <Script
          id="ze-snippet"
          src={`https://static.zdassets.com/ekr/snippet.js?key=${zendeskToken}`}
          strategy="afterInteractive"
          onError={() => {
            setErrorLoading(true)
            console.warn('Error loading Zendesk chat widget. No chat support available')
          }}
          onLoad={() => {
            setErrorLoading(false)
            console.log('Zendesk Chat Loaded')

            const isPuppeteer = /HeadlessChrome/.test(navigator.userAgent)
            if (isPuppeteer) {
              window?.zE?.('messenger', 'hide')
              console.log('Zendesk Chat Hidden')
            }
          }}
        />
      )}
      <ZendeskChatContext.Provider value={{ zendeskChatLoaded: !errorLoading }}>
        {children}
      </ZendeskChatContext.Provider>
    </>
  )
}
